import { map, tap, filter } from 'rxjs/operators';
/**
 * Service for synchronizing datatable scrollers.
 *
 */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DatatableScrollerSyncService {
    event = new Subject<{ scope: string; scrollEvent: MouseEvent }>();
    scrollHistory: { [key: string]: { timeStamp: number } } = {};

    scrollUpdate(scope, scrollEvent: MouseEvent) {
        this.event.next({ scope, scrollEvent });
    }

    scope(scope): Observable<MouseEvent> {
        this.scrollHistory[scope] = this.scrollHistory[scope] || {
            timeStamp: 0
        };
        return this.event.pipe(
            filter(ev => ev.scope !== scope),
            filter(e => e.scrollEvent.timeStamp > this.scrollHistory[scope].timeStamp),
            tap(e => (this.scrollHistory[scope].timeStamp = e.scrollEvent.timeStamp)),
            map(e => e.scrollEvent)
        );
    }
}
