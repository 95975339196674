import { Injectable } from '@angular/core';
import { ChartElementService } from 'insightui.export/powerpoint/services/chart-element.service';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { PowerpointExport } from 'insightui.export/types';
import { PowerpointExportSheets } from 'insightui.export/types';
import { Observable, of as observableOf } from 'rxjs';

@Injectable()
export class FixedSizeChart implements PowerpointExport {
    constructor(
        private chartElementService: ChartElementService,
        private chartToSheetService: ChartToSheetService
    ) {}

    /**
     * Return the filled Html block of the authoring chart
     *
     * @returns {ExportablePowerpointHtml}
     * @memberof FixedSizeAuthoringChartHtml
     */
    getPowerpointExportSheets(): Observable<PowerpointExportSheets> {
        let chart = this.chartElementService.getCleanedUpChartElement();

        return observableOf([this.chartToSheetService.getSheet(chart)]);
    }
}
