import { UpgradeComponent } from '@angular/upgrade/static';
import { Component, Directive, ElementRef, Injector, Input } from '@angular/core';
import { Select } from '@ngxs/store';
import { FavouriteManagerState } from 'insightui.favouritemanager/state/favourite-manager.state';
import { Observable } from 'rxjs';

@Directive({
    selector: '[gfkFavouriteSelectByReportAction]',
    providers: [
        // Need to provide a new scope, because UpgradeComponent does not provide one
        {
            provide: '$scope',
            useFactory: i => i.get('$rootScope').$new(),
            deps: ['$injector']
        }
    ]
})
export class FavoriteSelectByReportDirective extends UpgradeComponent {
    @Input() hasError;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('favouriteSelectByReportAction', elementRef, injector);
    }
}

@Component({
    selector: 'gfk-favourite-select-by-report-component',
    templateUrl: './favourite-select-by-report.component.html',
    providers: [
        // Need to provide a new scope, because UpgradeComponent does not provide one
        {
            provide: '$scope',
            useFactory: i => i.get('$rootScope').$new(),
            deps: ['$injector']
        }
    ]
})
export class FavouriteSelectByReportComponent {
    @Select(FavouriteManagerState.loadError)
    public hasError$: Observable<boolean>;

    constructor() {}
}
