import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
/**
 * Factory for retrieving the ng1 ReportContextNotificationService.
 */
@Injectable()
export class ReportContextNotificationService {
    private ng1ReportContextNotificationService;

    constructor(injector: AngularInjectorResolver) {
        injector.get().subscribe($injector => {
            this.ng1ReportContextNotificationService = $injector.get(
                'ReportContextNotificationService'
            );
        });
    }

    public notify() {
        return this.ng1ReportContextNotificationService.notify.call(
            this,
            'ReportContextChanged'
        );
    }
}
