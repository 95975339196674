import { Injectable } from '@angular/core';

const CHART_VIEWPORT_SELECTOR = '.chart-viewport, .chart-viewport-outer';

const CHART_CONTROLS_SELECTOR = '.chart-controls';

@Injectable()
export class ChartCleanupService {
    cleanupElementForExport(authoringChartElement: HTMLElement): HTMLElement {
        const clone = authoringChartElement.cloneNode(true) as HTMLElement;

        const viewportElements = clone.querySelectorAll(CHART_VIEWPORT_SELECTOR);
        Array.from(viewportElements || []).forEach((element: HTMLElement) => {
            element.style.height = '100%';
        });

        const chartControlsElements = clone.querySelectorAll(CHART_CONTROLS_SELECTOR);
        Array.from(chartControlsElements || []).forEach((element: HTMLElement) => {
            element.parentNode.removeChild(element);
        });

        return clone;
    }
}
