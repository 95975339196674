import {
    BaseTablePluginOptions,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Injector } from '@angular/core';
import { TooltipUtilService } from 'insightui.table/components/datatable/shared/services/tooltip-util.service';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

const KPI_MATCHER = /^KPI/i;

const MARGIN_REMOVE_ICON = 20;
const MARGIN_SORT_ICON = 12;

type OpenTooltip = {
    readonly element: HTMLElement;
    readonly kpi: string;
};

export class KpiTooltipPlugin extends TableHeaderPlugin {
    private static openTooltip: OpenTooltip = null;

    name = 'KpiTooltipPlugin';

    pageDefinitionService: PageDefinitionService;
    tooltipUtilService: TooltipUtilService;

    static removeTooltipByKpi(kpiPredicate: (kpi: string) => boolean) {
        if (this.openTooltip && kpiPredicate(this.openTooltip.kpi)) {
            const element = this.openTooltip.element;
            if (element.parentNode) {
                element.parentNode.removeChild(element);
            }
            this.openTooltip = null;
        }
    }

    constructor(injector: Injector, configuration: BaseTablePluginOptions) {
        super(injector, configuration);
        this.pageDefinitionService = this.injector.get(PageDefinitionService);
        this.tooltipUtilService = this.injector.get(TooltipUtilService);
    }

    select(cell: DatatableHeaderCellComponent | Cell): boolean {
        if (cell instanceof DatatableHeaderCellComponent) {
            return (
                cell.columnHeader.id === cell.columnHeader.kpi &&
                KPI_MATCHER.test(cell.kpi)
            );
        }
        return false;
    }

    protected doEnable(cell: DatatableHeaderCellComponent) {
        const rootNode = cell.element.nativeElement;
        const siblingCell: HTMLElement = rootNode.querySelector(
            '.datatable-header-label'
        ) as HTMLElement;
        if (!rootNode || !siblingCell || this.isActive(rootNode)) {
            return;
        }

        const iconInfoNode = this.createTooltipIcon(siblingCell);
        rootNode.mouseClickListener = event => {
            this.showTooltip(event, cell);
            event.stopPropagation();
        };
        iconInfoNode.addEventListener('click', rootNode.mouseClickListener);

        rootNode.mouseCloseListener = () => this.removeOwnToolTip(cell);
        document.body.addEventListener('click', rootNode.mouseCloseListener);

        rootNode.mouseOverListener = () => this.repositionTooltipIcon(rootNode);
        rootNode.addEventListener('mouseenter', rootNode.mouseOverListener);

        rootNode.mouseOutListener = () => this.resetTooltipIcon(rootNode);
        rootNode.addEventListener('mouseleave', rootNode.mouseOutListener);

        this.markAsActive(rootNode);
    }

    protected doDisable(cell: Cell) {
        const rootNode = cell.element.nativeElement;
        if (!this.isActive(rootNode)) {
            return;
        }
        const iconInfoNode = rootNode.querySelector('.plugin-icon-info');
        if (iconInfoNode) {
            iconInfoNode.classList.add('hidden');
            iconInfoNode.removeEventListener('click', rootNode.mouseClickListener);
        }
        rootNode.removeEventListener('mouseenter', rootNode.mouseOverListener);
        rootNode.removeEventListener('mouseleave', rootNode.mouseOutListener);
        document.body.removeEventListener('click', rootNode.mouseCloseListener);

        this.markAsInactive(rootNode);
    }

    private showTooltip(event, cell: DatatableHeaderCellComponent) {
        if (this.isTooltipVisible(cell)) {
            return;
        }

        this.removeAnyOtherTooltip(cell);

        const kpi = this.pageDefinitionService.getCurrentPage().getKpi(cell.kpi);
        const content = kpi.tooltip.enhancedContent;
        const width = this.tooltipUtilService.getKpiTooltipWidth(content);
        const contentHtml = this.tooltipUtilService.getKpiTooltip(
            kpi.title,
            content,
            width
        );
        document.body.appendChild(contentHtml);

        const position = this.tooltipUtilService.getTooltipAnchor(
            event,
            width,
            contentHtml.clientHeight,
            0
        );
        contentHtml.style.top = position.top - 5 + 'px';
        contentHtml.style.left = position.left - 5 + 'px';

        KpiTooltipPlugin.openTooltip = {
            element: contentHtml,
            kpi: cell.kpi
        };
    }

    private removeOwnToolTip(cell: DatatableHeaderCellComponent) {
        KpiTooltipPlugin.removeTooltipByKpi(kpi => kpi === cell.kpi);
    }

    private removeAnyOtherTooltip(cell: DatatableHeaderCellComponent) {
        KpiTooltipPlugin.removeTooltipByKpi(kpi => kpi !== cell.kpi);
    }

    private isTooltipVisible(cell: DatatableHeaderCellComponent): boolean {
        return (
            !!KpiTooltipPlugin.openTooltip &&
            KpiTooltipPlugin.openTooltip.kpi === cell.kpi
        );
    }

    private repositionTooltipIcon(cell: HTMLElement) {
        this.setIconVisible(cell);
    }

    private resetTooltipIcon(cell: HTMLElement) {
        this.setIconHidden(cell);
    }

    private setIconVisible(cell: HTMLElement) {
        const iconElement: HTMLElement = cell.querySelector(
            '.plugin-icon-info'
        ) as HTMLElement;
        iconElement.classList.add('fade-header-icons');
        iconElement.style.opacity = '1';
        const minMarginRight = this.getMarginRight(cell);
        iconElement.style.right = minMarginRight + 'px';
    }

    private setIconHidden(cell: HTMLElement) {
        const iconElement: HTMLElement = cell.querySelector(
            '.plugin-icon-info'
        ) as HTMLElement;
        iconElement.style.right = null;
        iconElement.classList.remove('fade-header-icons');
        iconElement.style.opacity = '0';
    }

    private createTooltipIcon(siblingCell: HTMLElement) {
        const iconInfoNode: HTMLElement = document.createElement('i');
        iconInfoNode.classList.add('fa');
        iconInfoNode.classList.add('fa-info-circle');
        iconInfoNode.classList.add('plugin-icon-info');
        iconInfoNode.style.position = 'absolute';
        iconInfoNode.style.top = '6px';
        siblingCell.parentNode.insertBefore(iconInfoNode, siblingCell.nextSibling);

        return iconInfoNode;
    }

    private getMarginRight(cell: HTMLElement): number {
        let margin = 3;
        margin = this.isRemoveable(cell) ? margin + MARGIN_REMOVE_ICON : margin;
        margin = this.isSortable(cell) ? margin + MARGIN_SORT_ICON : margin;
        return margin;
    }

    private isRemoveable(cell: HTMLElement): boolean {
        return !!cell.querySelector('.plugin-removable-header');
    }

    private isSortable(cell: HTMLElement): boolean {
        return !!cell.querySelector('.plugin-sortable-wrapper');
    }

    extendExport(exportData) {
        return exportData;
    }
}
