import { Injectable } from '@angular/core';
import { PeriodUtilityService } from 'insightui.period/period-utility.service';
import { formatDate } from '@angular/common';
import { Period, Periodicity } from 'insightui.period/types';

const LOCALE = 'en-US';

@Injectable()
export class PeriodTitleService {
    constructor(private periodUtilityService: PeriodUtilityService) {}

    getFormattedPeriodTitle(period: Period, periodicity: Periodicity): string {
        switch (periodicity) {
            case 'monthly':
                return this.formatPeriodToMonthly(period);
            case 'three_rolling_months':
                return this.formatPeriodToThreeRollingMonths(period);
            case 'mat':
                return this.formatPeriodToMAT(period);
            case 'ytd':
                return this.formatPeriodToYTD(period);
            case 'fyr':
                return this.formatPeriodToFYR(period);
        }
    }

    /**
     * To get the period label for last 3 months
     *
     * Eg : May - July is considered as 3 months
     * The offsetMonths should be set as 2 as the starting month is 5
     * to include starting month into consideration.
     * Hence, 5 + 2 = 7 -> July
     * @param period
     * @private
     */
    private formatPeriodToThreeRollingMonths(period: Period): string {
        const dateStartPlusThreeMonth = this.periodUtilityService.getDateFromPeriod(
            this.periodUtilityService.getPreviousPeriodWithOffset(period, -2)
        );

        return '3M to ' + formatDate(dateStartPlusThreeMonth, 'MMM yy', LOCALE);
    }

    private formatPeriodToMonthly(period: Period): string {
        const dateStart = this.periodUtilityService.getDateFromPeriod(period);

        return formatDate(dateStart, 'MMM yy', LOCALE);
    }

    private formatPeriodToMAT(period: Period): string {
        const nextYearDateStart = this.periodUtilityService.getDateFromPeriod(
            this.periodUtilityService.getNextYearPeriod(period)
        );

        return 'MAT ' + formatDate(nextYearDateStart, 'yy', LOCALE);
    }

    private formatPeriodToYTD(period: Period): string {
        const dateStart = this.periodUtilityService.getDateFromPeriod(period);

        return 'YTD ' + formatDate(dateStart, 'yy', LOCALE);
    }

    private formatPeriodToFYR(period: Period): string {
        const dateStart = this.periodUtilityService.getDateFromPeriod(period);

        return formatDate(dateStart, 'yyyy', LOCALE);
    }
}
