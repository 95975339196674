<div>
    <div class="load-status uitest-Div-LP-StatusContainer">
        <div class="load-status-container uitest-Div-LP-HeaderContainer">
            <div class="country-select-box uitest-Div-LP-CountrySelect">
                <div (click)="countryToggle()" class="countryFilter header-container-toggle"
                    [ngClass]="{'disabled': countries.length < 2 }">
                    {{ selectedCountryName }}
                </div>

                <div *ngIf="dropDownCountryVisible" class="header-container-dropdown" ng-show="visible"
                    style="display:block" (blur)="countryOnBlur()">
                    <div class="header-container-filter-directives">
                        <div class="uitest-Div-PF-Container dropdown-content" ng-transclude>
                            <div class="header-container-filter-element">
                                <div class="header-container-filter-element-title">
                                    Country
                                </div>
                                <div class="country-filter-segment-list radio ng-scope" *ngFor="let country of countries">
                                    <input type="radio" class="uitest-Rad-CF-SelectSegment uitest-Rad-CF-SelectSegment-{{ country.countryCode }}"
                                           id="rb_setting_name_{{ country.countryCode }}" name="rb_countrySelection"
                                           [(ngModel)]="selectedCountry" (click)="selectCountry(country)"
                                           [ngClass]="{'uiTest-state-selected' : country.countryCode == selectedCountry}"
                                           value="{{ country.countryCode }}" />
                                    <label for="rb_setting_name_{{ country.countryCode }}">{{ country.countryName }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="header-container-apply uitest-Div-HC-Apply-periodFilter"
                        enable-key-nav="enter"
                        [ngClass]="{'uiTest-state-deactivated': applyDisabled, 'header-container-apply-disabled': applyDisabled}"
                        (click)="applyDisabled || countryOnApply()"
                    >
                        APPLY SELECTION
                    </div>
                </div>
            </div>

            <div class="period-status-box uitest-Div-LP-PeriodStatus" *ngIf="periods.length > 0">
                <div *ngFor="let rev of periods; let i = index">
                    <div class="_{{ i }} period-status-box-period"
                        [ngClass]="{'selected' : loadStatusUpdates.length > 1 && rev.periodId === period, 'active' : loadStatusUpdates.length > 1 && rev.periodId !== period }"
                        (click)="selectPeriod(rev)">
                        {{ rev.periodStr }}
                    </div>
                    <gfk-load-status class="_{{ getTotalUpdates(loadStatusUpdates[i]).toString().length }}"
                        *ngIf="getTotalUpdates(loadStatusUpdates[i]) > 0"
                        [newlyLoadedUpdates]="loadStatusUpdates[i]"
                    >

                    </gfk-load-status>
                </div>
            </div>

            <div class="load-status-box uitest-Div-LP-LoadStatus"></div>
        </div>
    </div>
    <div data-error-handler="NoDeliveryHandler" data-error-scope="landingpage-nodelivery"
        class="uitest-Div-LP-Container">
        <div class="report-overview">
            <div class="report-container">
                <div class="col-md-12">
                    <gfk-report-overview class="overview col-md-6" *ngFor="let ovw of overviews" [overview]="ovw" [docId]="docId"
                                         [questionMinCount]="minQuestions" [favourites]="favourites">
                    </gfk-report-overview>
                </div>
            </div>
        </div>
        <div class="report-groups">
            <gfk-report-accordion class="groups-container uitest-Div-LP-Accordion-{{ group.id }}"
                *ngFor="let group of groups"
                [docId] ="docId"
                [group]="group"
                [favourites]="favourites">
            </gfk-report-accordion>
        </div>
    </div>
</div>
