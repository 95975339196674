import { Injectable } from '@angular/core';
import { ReportingDataSet } from 'insightui.data/query/queryservice/query-service-request.interface';
import { CrossTabMapper } from 'insightui.data/operator/crosstab-mapper.service';
import {
    ResponseSorter,
    SortDirection
} from 'insightui.data/operator/response-sorter.service';
import { AuxiliaryRowMapper } from 'insightui.data/operator/auxiliary-row-mapper.service';
import { ConditionalTotalRemover } from 'insightui.data/operator/conditional-total-remover.service';
import { MissingTotalMapper } from 'insightui.data/operator/missingtotal-mapper.service';
import { RemoveRowMapper } from 'insightui.data/operator/remove-row-mapper.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';

export interface ResponseMapper {
    map(reportingData: ReportingDataSet): ReportingDataSet;
}

/**
 * Convenience class for grouping mappers.
 */
@Injectable()
export class ResponseMapperService {
    constructor(
        private crossTabMapper: CrossTabMapper,
        private responseSorter: ResponseSorter,
        private auxiliaryRowMapper: AuxiliaryRowMapper,
        private removeRowMapper: RemoveRowMapper,
        private conditionalTotalRemover: ConditionalTotalRemover,
        private missingTotalMapper: MissingTotalMapper
    ) {}

    /**
     * Return a crossTab mapping function that transforms a cell based result into a top/sideheader format using the given parameters.
     * If no KPI is given, the kpi is appended as the last topHeader.
     *
     * @param sideHeader    The attributes that should reside in the side header (e.g. PF, PG, etc.)
     * @param topHeader     The attributes that should reside in the top header (e.g. channel, kpi, posType)
     * @returns A mapping function for ReportingDataRowSets
     */
    public crossTab(
        sideHeader: string[],
        topHeader: string[]
    ): (reportingData: ReportingDataSet) => ReportingDataSet {
        this.crossTabMapper.configure(sideHeader, topHeader);
        return this.crossTabMapper.map.bind(this.crossTabMapper);
    }

    /**
     * Return a mapping function that sorts the result with the given keys in the sortDirection.
     *
     * @param sortBy        One or multiple kpis to sort by
     * @param sortDirection The direction so sort, denoted by the SortDirection Enum
     * @returns A mapping function for ReportingRowDataSets
     */
    public sort(
        sortBy?: string | string[],
        sortDirection?: SortDirection
    ): (reportingData: ReportingDataSet) => ReportingDataSet {
        this.responseSorter.configure(sortBy, sortDirection);
        return this.responseSorter.map.bind(this.responseSorter);
    }

    /**
     * Remove tagged elements from the ReportingDataSet and move them into the additional section (if not ignored
     * @param ignore An optional array of tags to ignore
     * @returns      A mapping function for ReportingRowDataSets
     */
    public extractTags(
        ignore: string[] = []
    ): (reportingData: ReportingDataSet) => ReportingDataSet {
        this.auxiliaryRowMapper.configure({ ignore });
        return this.auxiliaryRowMapper.map.bind(this.auxiliaryRowMapper);
    }

    /**
     * Remove rows from the dataset which, e.g. are not available in the result set
     */
    public removeRow(
        metadataSelectors: MetadataSelectors,
        visualizationContext: VisualizationContextService
    ) {
        this.removeRowMapper.configure(metadataSelectors, visualizationContext);
        return this.removeRowMapper.map.bind(this.removeRowMapper);
    }

    /**
     * Remove total
     * @param ignoreRemove determine whether total to be removed or not
     * @param fieldInfoMapper use to define element and title to be removed
     * @returns A mapping function for ReportingRowDataSets
     */
    public removeTotal(
        ignoreRemove: boolean,
        fieldInfoMapper
    ): (reportingData: ReportingDataSet) => ReportingDataSet {
        this.conditionalTotalRemover.configure(ignoreRemove, fieldInfoMapper);
        return this.conditionalTotalRemover.map.bind(this.conditionalTotalRemover);
    }

    /**
     * In case of all table items have incomplete data, the total line element is missing. This part
     * adds a default total item row
     */
    public addEmptyTotal(): (reportingData: ReportingDataSet) => ReportingDataSet {
        return this.missingTotalMapper.map.bind(this.missingTotalMapper);
    }
}
