import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
/**
 * Factory for retrieving the ng1 DrillLevelSettingService.
 */
@Injectable()
export class DrillLevelSettingService {
    private ng1DrillLevelSettingService;

    constructor(injector: AngularInjectorResolver) {
        injector.get().subscribe($injector => {
            this.ng1DrillLevelSettingService = $injector.get('DrillLevelSettingService');
        });
    }

    public getSplitByKey(level, key) {
        return this.ng1DrillLevelSettingService.getSplitByKey(level, key);
    }

    public removeSplitByElement(
        drillLevel,
        vcFeatureFilterList,
        vcDrillLevel,
        vcPriceClass
    ) {
        this.ng1DrillLevelSettingService.removeSplitByElement(
            drillLevel,
            vcFeatureFilterList,
            vcDrillLevel,
            vcPriceClass
        );
    }
}
