import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gfkClamp'
})
export class ClampPipe implements PipeTransform {
    transform(value: any, min: number, max: number): any {
        if (value == null || typeof value !== 'number') {
            return value;
        }

        return Math.max(Math.min(value, max), min);
    }
}
