<div class="rf-dropdown">
    <div class="rf-dropdown__toggle">
        <a
            href="#"
            class="addToCollectionsButton__link"
            [class.addToCollectionsButton__link--disabled]="isAddToCollectionsNotAvailable$ | async"
            [attr.title]="(isAddToCollectionsNotAvailable$ | async)? 'Collections are currently not available': '' "
            (click)="toggleDropdown()"
            title="Add to Collection"
        >
            <div class="[ icon-add-collections ]">
                <i
                    class="[ icon-add-collections__stack ]  [ fa-stack-overflow ]"
                    aria-hidden="true"
                ></i>
                <i
                    class="[ icon-add-collections__circle ]  [ fa-circle-o ]"
                    aria-hidden="true"
                ></i>
                <i
                    class="[ icon-add-collections__plus ]  [ fa-plus ]"
                    aria-hidden="true"
                ></i>
            </div>
        </a>
    </div>
    <div
        class="rf-dropdown__content rf-dropdown__content--arrow-right"
        [ngClass]="{ 'rf-dropdown__content--hidden': !isDropdownVisible }"
    >
        <div>
            <strong>Add to collection</strong>
        </div>
        <ul class="[ rf-dropdown-list ]  [ list list--border ]">
            <li
                *ngIf="temporaryCollection$ | async as tempCollection"
                class="rf-dropdown-list__item"
            >
                <collection-list-item
                    (onClicked)="collectionListItemCallback($event)"
                    [progress]="lastProgress$"
                    [collection]="tempCollection"
                >
                </collection-list-item>
            </li>
            <li
                class="rf-dropdown-list__item"
                *ngFor="
                    let collection of collections$ | async;
                    trackBy: collectionTrackByFn
                "
            >
                <collection-list-item
                    (onClicked)="collectionListItemCallback($event)"
                    [progress]="lastProgress$"
                    [collection]="collection"
                ></collection-list-item>
            </li>
        </ul>
        <div>
            <a class="[ link-secondary ]" href="#" (click)="showModal()">
                <i aria-hidden="true" class="fa fa-plus"></i>
                Add to new collection
            </a>
        </div>
    </div>
</div>
