<div
    class="favourite-select uitest-Div-FSelect-{{ favouriteResolved }}"
    (click)="click()"
    [ngClass]="{ active: chapter.show }"
>
    <em
        class="[ c-icon  c-icon--favourite-flyout ]  [ u-text-h3 ]  [ u-color-text ]  [ u-hover-primary ]"
    ></em>
    <div
        style="position: absolute;margin-top:-21px;cursor: default"
        (click)="$event.stopPropagation()"
    >
        <div *ngIf="chapter.show" class="header-container-dropdown" style="display:block">
            <div class="header-container-filter-directives">
                <div
                    class="uitest-Div-PF-Container dropdown-content"
                    style="max-height: 448px;"
                    ng-transclude
                >
                    <ul>
                        <li
                            *ngFor="let fav of favourites"
                            class="uitest-Li-FSelect-{{ fav.id }}"
                        >
                            <a
                                href="{{ getLink(docId, fav.id) }}"
                                target="_blank"
                                id="favouriteLink-{{ fav.id }}"
                                class="uitest-LINK-{{ fav.id }}"
                            >
                                {{ fav.name }}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
