import { Component, OnInit } from '@angular/core';
import { DrillingService, DrillPath } from 'insightui.data/shared/drilling.service';
import { VisualizationContextService } from "insightui.data/shared/visualization-context.service";
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import {
    MultiNotificationSubscription,
    NotificationEvent
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { Observable } from 'rxjs';

interface DrillingOption {
    path: DrillPath;
    active: boolean;
    name: string;
}

/**
 * Component for consistent positioning of the Drill Switch
 */
@Component({
    selector: 'drill-switch',
    templateUrl: 'drill-switch-toggle.component.html'
})
export class DrillSwitchToggleComponent implements OnInit {
    @MultiNotificationSubscription([
        NotificationNamespace.Drilling,
        NotificationNamespace.Filter
    ])
    onDrillTargetChange$: Observable<NotificationEvent>;

    drillingOptions: DrillingOption[] = [];
    visible: boolean = false;

    constructor(private drillingService: DrillingService,
                private visualizationContext: VisualizationContextService) {
        this.onDrillTargetChange$.subscribe(() => this.update());
    }

    ngOnInit(): void {
        this.update();
    }

    update(): void {
        let currentDrillLevel = this.drillingService.getDrillLevel();
        this.drillingOptions = this.drillingService
            .getAllDrillPaths()
            .filter(
                path =>
                    path.target === currentDrillLevel.id ||
                    path.source === currentDrillLevel.id
            )
            .filter(path => path.drillType === 'switch')
            .map(path => {
                // Return item only when not drilling up from brand filter in fair-share report price class share
                if (!this.visualizationContext.get('isDrillingUpFromBrandFilterInPriceClass')) {
                    return {
                        path: path,
                        active: path.target === currentDrillLevel.id,
                        name: this.drillingService.getLevelName(path.target)
                    };
                }
            });

        /**
         * Set visible for drill switch toggle when NOT drilling up from brand level in fair share
         * AND has drilling options defined.
         * Otherwise, hide it when drilling up from brand level in fair share
         */
        this.visible = !this.visualizationContext.get('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare')
            && this.drillingOptions.length > 0;
    }

    toggleTo(drillingOption: DrillingOption) {
        if (drillingOption.active) {
            return;
        }
        this.drillingService.switchTo(drillingOption.path);
    }
}
