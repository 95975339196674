import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'gfkClampWithPrefix'
})
export class ClampWithPrefixPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(
        value: any,
        min: number,
        max: number,
        minPrefix: string = '<',
        maxPrefix: string = '>'
    ): string {
        if (value == null) {
            return '-';
        } else if (value > max) {
            return maxPrefix + formatNumber(max, this.locale, '1.0-1');
        } else if (value < min) {
            return minPrefix + formatNumber(min, this.locale, '1.0-1');
        }

        return formatNumber(value, this.locale, '1.0-1');
    }
}
