<div class="accordion-group">
    <div class="accordion-toggle">
        <p (click)="toggle()">
            <span> {{ group.title }} </span>
            <em [ngClass]="open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></em>
        </p>
    </div>
    <div class="tile-container" [ngClass]="{ open: open, closed: !open }" ng-transclude>
        <div class="lpTiles row">
            <gfk-report-type-selector data-type="tile" class="lpTile col-md-3" *ngFor="let chapter of group.chapters"
                [docId]="docId" [chapter]="chapter" [favourites]="favourites">
            </gfk-report-type-selector>
        </div>
    </div>
</div>
