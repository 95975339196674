import { Component, Output, EventEmitter, Input } from '@angular/core';
/**
 * Component for Report Settings Extensions
 */
@Component({
    selector: 'report-setting-extension-area',
    styles: [
        `
            accordion >>> .panel {
                background-color: unset;
                height: inherit;
            }
        `
    ],
    template: `
        <performance-indicator-settings
            *ngIf="visible"
            (applyClicked)="closePanel()"
        ></performance-indicator-settings>
    `
})
export class ReportSettingExtensionAreaComponent {
    @Output()
    apply: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    visible = true;

    closePanel() {
        this.apply.emit();
    }
}
