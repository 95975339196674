import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'gfk-tooltip',
    templateUrl: './new-tooltip.component.html',
    styleUrls: ['./new-tooltip.component.scss']
})
export class NewTooltipComponent implements OnChanges {

    @ViewChild('divElement', { static: false })
    divElement: ElementRef;

    @Input()
    isOpen: any;

    @Input()
    tooltip: any;

    @Input()
    animation: any;

    @Input()
    positionX: number;

    @Input()
    positionY: number;

    constructor() {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.divElement) {
            this.divElement.nativeElement.style.left = (this.positionX + 10) + 'px';
        }
    }
}
