import { ElementRef, Injectable } from '@angular/core';
import {
    Column,
    DataRow
} from 'insightui.table/components/datatable/shared/models/datatable.models';
import { RoundEvenService } from 'insightui.core/services/round-even.service';
import { ColumnUtilService } from 'insightui.table/components/datatable/shared/services/column-util.service';
import { Indicator } from 'insightui.table/components/datatable/body/performanceIndicator/performance-indicator.component';
import { PerformanceIndicatorMapperService } from 'insightui.data/operator/performance-indicator-mapper.service';

const IS_TAG_VALUE = /^<.*>$/;

export interface Cell {
    row?: DataRow;
    column?: Column;
    element: ElementRef | null;
    value: string;
    indicator?: Indicator;
}
/**
 * Service for handling cell contents.
 */
@Injectable()
export class CellCacheService {
    cache: Map<DataRow, { [key: string]: Cell }> = new Map();

    constructor(
        private columnUtilService: ColumnUtilService,
        private roundEvenService: RoundEvenService,
        private performanceIndicatorMapperService: PerformanceIndicatorMapperService
    ) {}

    public push(row: DataRow, ...columns: Column[]) {
        if (!row) {
            return;
        }
        if (this.cache.get(row) === undefined) {
            this.cache.set(row, {});
        }
        const rowEntry = this.cache.get(row);
        columns.forEach(column => {
            if (!column.id) {
                column.id = this.columnUtilService.columnId();
            }
            rowEntry[column.id] = this.createCell(row, column);
        });
    }

    public clear(row: DataRow) {
        this.cache.delete(row);
    }

    public evict() {
        this.cache.clear();
    }

    public getRow(row: DataRow) {
        return this.cache.get(row) || {};
    }

    public all() {
        return this.cache;
    }

    private createCell(row: DataRow, column: Column): Cell {
        const value = this.calculateValue(row, column);
        return {
            column,
            row,
            value,
            element: null,
            indicator: this.performanceIndicatorMapperService.map(row, column)
        };
    }

    private calculateValue(row: DataRow, column: Column) {
        if (!row || !column || !column.field) {
            return '';
        }
        const value = row[column.field];
        if (value === undefined || value === null || IS_TAG_VALUE.test(String(value))) {
            return '';
        }
        if (column.dataType === 'numerical' || column.dataType === 'percentage') {
            if (!column.$pipe) {
                this.createPipe(column);
            }
            return column.$pipe(value);
        }
        return value.toString();
    }

    private createPipe(column: Column) {
        column.$pipe = val =>
            this.roundEvenService.transform(val, column.fractionalDigits);
    }
}
