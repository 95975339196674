import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { LoadStatusInfo, LoadState, PeriodInfo } from 'insightui.metadata/metadata.types';
import { LoadStateForDialog } from '../load-status.types';

@Component({
    selector: 'gfk-load-status-dialog',
    templateUrl: './load-status-dialog.component.html',
    styleUrls: ['./load-status-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadStatusDialogComponent implements OnInit {
    searchText = '';

    displayPeriod = '';
    period: PeriodInfo;
    dimensionsTree: LoadStateForDialog;

    constructor(
        private dialogRef: MatDialogRef<LoadStatusDialogComponent>,
        store: Store
    ) {
        store
            .select(state => state.metadata.loadStatusInfos)
            .subscribe((lsi: LoadStatusInfo[]) => {
                const loadStatusInfo = lsi.find(ls => ls.active);

                this.period = loadStatusInfo.period;
                this.displayPeriod = loadStatusInfo.periodStr;

                const dimensionsRoot = loadStatusInfo.loadState[0];
                this.dimensionsTree = this.mapToLoadStateForDialog(dimensionsRoot);
            });
    }

    ngOnInit() {
        // TODO: use correct docId (from route?)
    }

    private mapToLoadStateForDialog(loadState: LoadState): LoadStateForDialog {
        let nodes: LoadStateForDialog[];

        if (loadState.nodes && loadState.nodes.length > 0) {
            nodes = loadState.nodes.map(node => this.mapToLoadStateForDialog(node));
        }

        return {
            labelTitle: loadState.labelTitle,
            loadStatus: loadState.loadStatus,
            isLeaf: !nodes || nodes.length === 0,
            isVisible: true,
            isCollapsed: true,
            nodes
        };
    }

    onSearchTextChanged(searchText) {
        this.searchText = searchText;
        this.dimensionsTree.nodes.forEach(n => this.setVisibility(n));
    }

    private setVisibility(node: LoadStateForDialog): boolean {
        let visibility = false;

        if (node.nodes && node.nodes.length > 0) {
            node.nodes.forEach(n => {
                if (this.setVisibility(n)) {
                    visibility = true;
                }
            });
        } else {
            visibility = this.textMatchesSearch(node.labelTitle);
        }

        node.isVisible = visibility;
        node.isCollapsed = !visibility || !this.searchText;

        return visibility;
    }

    private textMatchesSearch(text: string): boolean {
        const searchString = this.searchText.toLowerCase();
        return searchString.length === 0 || text.toLowerCase().search(searchString) > -1;
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
