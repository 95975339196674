/**
 * Remote Service for accessing reporting data.
 *
 * Intentionally not called QueryRemoteService as this may call External Services or the BI Server later.
 */
import { Injectable, Inject } from '@angular/core';
import { KPI } from 'insightui.chart/types';
import { ResponseMapperService } from 'insightui.data/operator/response-mapper.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { ComparisonPeriod } from 'insightui.period/types';
import { Observable, of as observableOf } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { QueryResponseParserService } from 'insightui.data/query/query-response-parser.service';
import {
    ContextFilter,
    ContextFilterDTO,
    HttpHeaderType,
    QueryServiceRequest,
    QueryServiceResponse,
    ReportingRawDataSet
} from 'insightui.data/query/queryservice/query-service-request.interface';
import { KPIDefinition } from 'insightui.data/shared/reporting-data';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { INSIGHT_CONFIG, InsightConfig } from 'insightui.global/global.module';
import { HttpClient } from '@angular/common/http';

type PeriodType = 'series' | 'comparison';

@Injectable()
export class ReportDataRemoteService {
    private remoteEndpoint: string;
    private attributes = [
        { key: HttpHeaderType.UNCUT_TOTAL_COUNT, value: 'X-GfK-TotalCount' }
    ];

    constructor(
        private httpClient: HttpClient,
        private visualizationContext: VisualizationContextService,
        private queryResponseParser: QueryResponseParserService,
        private drillingService: DrillingService,
        private metadataService: MetadataService,
        private metadataSelector: MetadataSelectors,
        public responseMapper: ResponseMapperService,
        @Inject(INSIGHT_CONFIG) config: InsightConfig
    ) {
        this.remoteEndpoint = config.services.QUERY_SERVICE.url;
    }

    public buildRequest(kpis: KPIDefinition[]): QueryServiceRequest {
        return this.buildRequestForDelivery(this.metadataService.getDocRevId(), kpis);
    }

    public buildRequestForDeliveryWithPeriodType(
        delivery: number,
        kpis: KPI[],
        periodType: PeriodType
    ): QueryServiceRequest {
        const request = {} as QueryServiceRequest;
        this.determineChannelList(request);
        this.determineReportingLevel(request);
        this.determineSourceReport(request, delivery);
        this.determinePeriod(request, periodType);
        this.determineFilter(request);
        this.determinePosType(request);
        this.determinePriceClassList(request);
        this.setKpiAttributes(request);

        request.kpiList = kpis;
        request.showPriceClasses = this.visualizationContext.get('showPriceClasses');
        request.showFeatures = this.visualizationContext.get('showFeatures');

        request.useSpecialBase = request.drillLevel === 'itemInBrand';
        request.rebaseDimension = 'element';

        request.calculateOthers =
            this.visualizationContext.get('calculateOthers') &&
            (['itemInPg', 'itemInBrand'].indexOf(request.drillLevel) >= 0 ||
                (['brandInPg', 'brandInCat'].indexOf(request.drillLevel) >= 0 &&
                    request.contextFilter.filter === 'all'));
        request.calculateSegmentedOthers = false;
        request.showFirstActivities = this.visualizationContext.get(
            'showFirstActivities'
        );

        if (this.isFairShareReport() && request.showPriceClasses) {
            this.determinedFairShareRequest(request);
        }
        return request;
    }

    /**
     * Create a service request that can be submitted to the backend using the provided KPI values and level.
     *
     * @param delivery  The delivery ID to query for
     * @param kpiDefinitions      An array of KPIDefinition to query
     *
     * @returns A QueryServiceRequest that can be executed using in ReportDataRemoteService:executeRequest().
     */
    public buildRequestForDelivery(
        delivery: number,
        kpiDefinitions: KPIDefinition[]
    ): QueryServiceRequest {
        const kpis = kpiDefinitions.map(({ id }) => id);
        const periodType = this.getPeriodTypeFromKpiDefinitions(kpiDefinitions);

        return this.buildRequestForDeliveryWithPeriodType(delivery, kpis, periodType);
    }

    /**
     * Execute the given request and return an Observable with the ReportingDataSet.
     *
     * @param request                          The request to execute
     * @returns A ReportingDataSet without any additional data.
     */
    public executeRequest(request: QueryServiceRequest): Observable<ReportingRawDataSet> {
        if (!this.drillingService.isSupportedDimensionInHierarchy()) {
            console.warn('Hierarchy Dimension does not exist, returning empty data');
            return observableOf({ headers: [], data: [] });
        }

        return this.httpClient
            .post<QueryServiceResponse>(`${this.remoteEndpoint}api/query`, request, {
                observe: 'response'
            })
            .pipe(
                map(result => {
                    return {
                        headers: this.mapHttpHeaders(result.headers),
                        data: this.queryResponseParser
                            .parseResponse(result.body)
                            .filter(this.filterNonRequested(request)),
                        info: result.body.info
                    };
                })
            );
    }

    private isFairShareReport() {
        return this.visualizationContext.get('reportId') === 'fair-share';
    }

    private isProductGroupSelected(nodeType: string) {
        return nodeType === 'productGroup';
    }

    private isBrandLevelFilterSelected() {
        return !_.isNil(this.visualizationContext.get('itemInBrand'));
    }

    private isDrillingUpFromBrandLevelFilterInPriceClassForFairShare() {
        return !_.isNil(this.visualizationContext.get('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare'))
        && this.visualizationContext.get('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare') === true;
    }

    private determinedFairShareRequest(request: QueryServiceRequest) {
        this.metadataSelector
            .getHierarchyDimension$(request.dimensionKey)
            .subscribe(node => {
                /* *
                 * To ensure the request is always consistent
                 * if drilling up from brand level in price class share
                 * - set elementList with Product Group ID
                 * - set drillLevel and level with value = productGroup
                 * - set dimensionKey with Category parent ID
                 */
                if (this.isDrillingUpFromBrandLevelFilterInPriceClassForFairShare()) {
                    request.elementList = [this.drillingService.vcDrillTarget.dimensionId];
                    request.drillLevel = 'productGroup';
                    request.level = 'productGroup';
                    request.dimensionKey = node.parentId;
                } else if (node && this.isProductGroupSelected(node.dim)) {
                    if (!this.isBrandLevelFilterSelected()) {
                        /**
                         * For NO brand level filter selected
                         * - set elementList with Product Group ID
                         * - set drillLevel and level with value = productGroup
                         * - set dimensionKey with Category parent ID
                         */
                        request.elementList = [this.drillingService.vcDrillTarget.dimensionId];
                        request.drillLevel = node.dim;
                        request.level = node.dim;
                        request.dimensionKey = node.parentId;
                    } else {
                        /**
                         * For brand level filter selected
                         * - set elementList with selected Brand
                         * - set drillLevel and level with value = brandInPg
                         * - set dimensionKey with ProductGroup ID
                         */
                        request.elementList = this.visualizationContext.get('itemInBrand') ? [this.visualizationContext.get('itemInBrand')]
                            : [this.drillingService.vcDrillTarget.dimensionId];
                        request.drillLevel = this.visualizationContext.get('drillTarget.drillLevel');
                        request.level = this.visualizationContext.get('drillTarget.drillLevel');
                        request.dimensionKey = node.id;
                    }
                }
            });
    }

    private determineReportingLevel(request: QueryServiceRequest) {
        request.dimensionKey = this.drillingService.vcDrillTarget.dimensionId;
        request.drillLevel = this.drillingService.vcDrillTarget.drillLevel;
        request.level = this.drillingService.getDrillLevel().dataLevel;
    }

    private determineChannelList(request: QueryServiceRequest) {
        request.channelList = this.visualizationContext.get('channelList') || [];
        const contextFilter = this.visualizationContext.get(
            'contextFilter',
            {}
        ) as ContextFilter;
        const baseChannel = contextFilter.base;
        if (request.channelList.indexOf(baseChannel) < 0) {
            request.channelList.push(baseChannel);
        }
    }

    private determineFilter(request: QueryServiceRequest) {
        request.contextFilter = this.mapToContextFilterDTO(
            this.visualizationContext.get('contextFilter')
        );

        request.featureMap = {};
        const featureFilterList =
            this.visualizationContext.get('featureFilterList') || [];
        featureFilterList.forEach(
            entry => (request.featureMap[entry.type] = entry.value)
        );

        request.priceClass = this.visualizationContext.get('priceClass');

        if (this.visualizationContext.get('itemInBrand')) {
            request.elementList = [this.visualizationContext.get('itemInBrand')];
        } else if (this.visualizationContext.has('elementList')) {
            request.elementList = this.visualizationContext.get('elementList');
        } else {
            request.elementList = [];
        }
    }

    private determineSourceReport(request: QueryServiceRequest, delivery: number) {
        request.pageId = this.visualizationContext.get('page.id');
        request.reportId = this.visualizationContext.get('reportId');
        request.docRevId = delivery;
    }

    private getPeriodTypeFromKpiDefinitions(kpi: KPIDefinition[]): PeriodType {
        const comparisonPeriods: ComparisonPeriod = this.visualizationContext.get(
            'comparisonPeriod'
        );
        if (!comparisonPeriods) {
            throw new Error(
                'Could not define period type: Missing value in visualiaztionContext'
            );
        }
        const current = comparisonPeriods.current;

        if (current.periodicity === 'running_13_month') {
            return 'series';
        } else {
            const hasPeriod = kpi.some(
                kpiDef => kpiDef.dimensionContext.indexOf('period') >= 0
            );
            return hasPeriod ? 'series' : 'comparison';
        }
    }

    private determinePeriod(request: QueryServiceRequest, periodType: PeriodType) {
        const comparisonPeriods: ComparisonPeriod = this.visualizationContext.get(
            'comparisonPeriod'
        );
        if (!comparisonPeriods) {
            throw new Error(
                'Could not define comparison period: Missing value in visualiaztionContext'
            );
        }
        const current = comparisonPeriods.current;

        request.period = {
            start: current.start,
            end: current.end,
            type: periodType
        };
    }

    private determinePosType(request: QueryServiceRequest) {
        request.posTypeList = this.visualizationContext.get('posTypeList');
    }

    private mapToContextFilterDTO(contextFilter: ContextFilter): ContextFilterDTO {
        let posType = contextFilter.posType ? contextFilter.posType : 'PT-1';
        if (
            this.visualizationContext.get('posTypeList', []).length === 1 &&
            this.visualizationContext.get('posTypeList', []).indexOf(posType) === -1
        ) {
            posType = this.visualizationContext.get('posTypeList', [])[0];
        }
        // default base value 'offline', if posType split is set
        if (
            this.visualizationContext.get('posTypeList', []).length > 1 &&
            this.visualizationContext.get('posTypeList', []).indexOf(posType) === -1
        ) {
            posType = this.visualizationContext.get('posTypeList', [])[1];
        }
        return {
            base: contextFilter.base,
            filter: contextFilter.filter,
            kpi: contextFilter.kpi,
            number: contextFilter.number,
            value: contextFilter.value,
            posType
        };
    }

    private determinePriceClassList(request: QueryServiceRequest) {
        if (this.visualizationContext.get('showPriceClasses')) {
            request.priceClass = this.visualizationContext.get('priceClass') || 'PC-1';
        }
    }

    private setKpiAttributes(request: QueryServiceRequest) {
        if (this.visualizationContext.has('kpiProperties')) {
            request.kpiAttributes = Object.assign(
                {},
                this.visualizationContext.get('kpiProperties', {})
            );
        }
    }

    private mapHttpHeaders(headers) {
        return this.attributes
            .map(header => {
                return {
                    key: header.key,
                    value: headers.get(header.value)
                };
            })
            .filter(header => header.value);
    }

    private filterNonRequested(request: QueryServiceRequest) {
        return dataCell => {
            if ('posType' in dataCell) {
                return request.posTypeList.indexOf(String(dataCell['posType'])) >= 0;
            }
            return true;
        };
    }
}
