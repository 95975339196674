import actionCreatorFactory from 'typescript-fsa';
import { ActionCreator } from 'typescript-fsa/src/index';
import { ReleaseNotes } from 'insightui.announcements/announcements.types';

const factory = actionCreatorFactory('ANNOUNCEMENTS');

export const SHOW_FORCED_RELEASE_NOTES_IF_NEEDED: ActionCreator<void> = factory(
    'SHOW_FORCED_RELEASE_NOTES_IF_NEEDED'
);
export const SHOW_RELEASE_NOTES_VIA_BELL: ActionCreator<void> = factory(
    'SHOW_RELEASE_NOTES_VIA_BELL'
);
export const OPEN_MODAL: ActionCreator<ReleaseNotes> = factory('OPEN_MODAL');
export const BEGIN_CLOSE_MODAL: ActionCreator<void> = factory('BEGIN_CLOSE_MODAL');
export const FINISH_CLOSE_MODAL: ActionCreator<void> = factory('FINISH_CLOSE_MODAL');
export const ACTIVATE_RELEASE_NOTE_BY_IDX: ActionCreator<number> = factory(
    'ACTIVATE_RELEASE_NOTE_BY_IDX'
);
export const ERROR_FETCHING_RELEASE_NOTES: ActionCreator<void> = factory(
    'ERROR_FETCHING_RELEASE_NOTES'
);
