import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AnalyticsService } from 'insightui.core/services/analytics.service';

@Component({
    selector: 'gfk-page-not-found-view',
    templateUrl: './page-not-found-view.component.html',
    styleUrls: ['./page-not-found-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageNotFoundViewComponent {
    constructor(matomoTrackerService: AnalyticsService) {
        matomoTrackerService.pageTrack();
    }
}
