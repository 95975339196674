import { AppStateEpic } from 'projects/insightui/src/app/state/state.types';
import { Epic, combineEpics } from 'redux-observable';
import { debounceTime, filter, map, withLatestFrom } from 'rxjs/operators';
import { FILTER_CHANGED } from 'insightui.report-filter/report-filter.actions';
import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { Observable } from 'rxjs';
import { AnyAction } from 'typescript-fsa';

/**
 * Translates the old filter notifications to a FILTER_CHANGED action.
 */
@Injectable({
    providedIn: 'root'
})
export class Ng1NotificationServiceAdapterEpic implements AppStateEpic<never> {
    key: never;

    getEpic(): Epic {
        const filterChangedEpic = ($action: Observable<AnyAction>) =>
            $action.pipe(
                filter(({ type }) => {
                    return this.isFilterType(type) || this.isDrillingType(type);
                }),
                withLatestFrom(AngularInjectorResolver.waitForInjector()),
                debounceTime(100),
                map(([{ type }, $injector]) => {
                    const visualizationContextService = $injector.get(
                        'VisualizationContextService'
                    );
                    return FILTER_CHANGED(
                        visualizationContextService.getVisualizationContext()
                    );
                })
            );

        return combineEpics(filterChangedEpic);
    }

    private isFilterType(type: string): boolean {
        return type.startsWith('DEPRECATED_NOTIFICATION_SERVICE/FILTER_SETTINGS');
    }

    private isDrillingType(type: string): boolean {
        return type.startsWith('DEPRECATED_NOTIFICATION_SERVICE/DRILLING');
    }
}
