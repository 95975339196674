<div class="legend-start">
    <div class="legend-number">
        {{ minValue | number: '1.0-1' | gfkWithSuffix: '%' }}
    </div>
</div>

<div class="legend-amount" [style.left.%]="lineOffset()">
    {{ lineValue | number: '1.0-1' | gfkWithSuffix: '%' }}
</div>

<div class="legend-end">
    <div class="legend-number">
        {{ maxValue | number: '1.0-1' | gfkWithSuffix: '%' }}
    </div>
</div>
