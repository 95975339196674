import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from 'insightui.global/global.module';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClientInfo } from 'insightui.client-switch/client-switch.types';
import { ClientSwitchState } from 'insightui.client-switch/state/client-switch.state';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'gfk-client-info',
    templateUrl: './client-info.component.html',
    styleUrls: ['./client-info.component.scss'],
    providers: []
})
export class ClientInfoComponent implements OnInit {
    clients$: Observable<ClientInfo[]>;
    clientName: string;
    clientId: string;
    httpParams: HttpParams;

    constructor(@Inject(WINDOW) private window: Window, store: Store) {
        this.clients$ = store.select(ClientSwitchState.clients);
        this.httpParams = new HttpParams({
            fromString: window.location.search.substring(1)
        });
    }

    ngOnInit(): void {
        if (!('URLSearchParams' in this.window)) {
            console.warn(
                `Your browser does not support the URLSearchParams feature. This is required for switching the clientId. Please use another browser.`
            );
            return;
        }

        this.clients$.subscribe(clients => {
            if (clients && clients.length > 0) {
                const clientIdFromUrl = new URLSearchParams(
                    this.window.location.search
                ).get('clientId');
                if (clientIdFromUrl) {
                    const client = clients.find(c => c.id === clientIdFromUrl);
                    if (client) {
                        this.clientId = client.id;
                        this.clientName = client.name;
                        return;
                    } else {
                        const params = new HttpParams({
                            fromString: this.window.location.search.substring(1)
                        }).delete('clientId');
                        this.window.location.search = params.toString();
                    }
                }
            }

            this.clientId = '';
            this.clientName = '';
        });
    }

    goHome() {
        this.window.location.href = '/';
    }
}
