import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { DEFAULT_LOCALE, DATE_FORMAT } from 'insightui.export/powerpoint/constants';
import { PowerpointHeadline } from 'insightui.export/types';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import * as _ from 'lodash';
import { VisualizationContextState } from 'insightui.vc/visualization-context.state';
import { Store } from '@ngxs/store';

@Injectable()
export class ExportHeadlineService {
    constructor(
        private store: Store,
        private pageDefinitionService: PageDefinitionService
    ) {}

    public getReportHeadline(): PowerpointHeadline {
        const favorite = this.store.selectSnapshot(VisualizationContextState.favorite);
        const moduleName = this.getModuleName();
        if (!!favorite) {
            return favorite.name || moduleName;
        } else {
            return moduleName;
        }
    }

    public getReportSubheadline(): PowerpointHeadline {
        const favorite = this.store.selectSnapshot(VisualizationContextState.favorite);
        const businessQuestion = this.getBusinessQuestion();
        if (!!favorite) {
            const moduleName = this.getModuleName();
            return `${moduleName} - ${businessQuestion}`;
        } else {
            return businessQuestion;
        }
    }

    public getReportExportFilename(): string {
        const exportTitle = this.getReportHeadline();
        return this.appendDate(exportTitle);
    }

    public getCollectionExportFilename(collectionName: string) {
        return this.appendDate(collectionName);
    }

    private appendDate(value: string): string {
        const date: string = formatDate(new Date(), DATE_FORMAT, DEFAULT_LOCALE);
        return `${value}_${date}`;
    }

    private getBusinessQuestion(): string {
        const businessQuestion = this.store.selectSnapshot(
            VisualizationContextState.businessQuestion
        );
        if (!businessQuestion) {
            throw new Error(
                'ExportHeadlineService: business question not found in visualization context state'
            );
        }
        return businessQuestion;
    }

    private getModuleName(): string {
        const currentPage = this.pageDefinitionService.getCurrentPage();
        if (!currentPage) {
            throw new Error('ExportHeadlineService: current page not found');
        }

        return this.pageDefinitionService.getModuleTitleForPage(currentPage.id);
    }
}
