import {EventEmitter, Injectable} from '@angular/core';
import * as _ from 'lodash';

import { ReportFavouriteGroupingService } from 'insightui.core/services/reportFavouriteGrouping.service';
import { FavouriteResourceManagerService } from 'insightui.core/services/favouriteResourceManager.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFavouriteModelService {

    reportFavourites: any;
    compactMode = true;
    favouritesLoaded: EventEmitter<any> = new EventEmitter();
    favouritesOpened: EventEmitter<boolean> = new EventEmitter();

    constructor(private reportFavouriteGroupingService: ReportFavouriteGroupingService,
                private favouriteResourceManagerService: FavouriteResourceManagerService) {

        this.reportFavourites = [];

        this.favouriteResourceManagerService.getAll(this.compactMode)
            .then(
                (data) => {
                    /*if (angular.isArray(favourites)) {
                        favourites.reverse();
                    }*/

                    _.forEach(data, (fav) => {
                        this.reportFavourites.push(fav);
                    });

                    this.favouritesLoaded.emit(true);
                },
                (error) =>{
                    console.warn(error);
                }
            );
    }

    groupByPageId() {
        return this.reportFavouriteGroupingService.groupByPageId(this.reportFavourites)
    }
}
