import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gfkWithSuffix'
})
export class WithSuffixPipe implements PipeTransform {
    transform(value: any, suffix: string): any {
        if (value == null || value === '-') {
            return value;
        }

        return '' + value + suffix;
    }
}
