import { AppStateMiddleware } from 'projects/insightui/src/app/state/state.types';
import { Middleware } from 'redux';
import { DEPRECATED_FAIR_SHARE_DRILLING_PROPAGATE_TO_VISUALIZATION_CONTEXT } from 'insightui.fair-share/deprecated/deprecated.actions';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { DrillTarget } from 'insightui.data/query/queryservice/query-service-request.interface';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DeprecatedDrillingMiddleware implements AppStateMiddleware {
    constructor(private visualizationContextService: VisualizationContextService) {}

    getMiddleware(): Middleware<{}> {
        return api => next => action => {
            if (
                DEPRECATED_FAIR_SHARE_DRILLING_PROPAGATE_TO_VISUALIZATION_CONTEXT.match(
                    action
                )
            ) {
                this.visualizationContextDrilling(
                    action.payload.hierarchyName,
                    action.payload.drillTarget
                );
            }

            return next(action);
        };
    }

    private visualizationContextDrilling(
        displayName: string,
        drillTarget: DrillTarget
    ): void {
        this.visualizationContextService.set('selectedDimensionTitle', displayName);
        this.visualizationContextService.set('drillTarget', { ...drillTarget });
        AngularInjectorResolver.waitForInjector().then($injector => {
            $injector.get('DrillingNotificationService').notify('DrillTargetChanged');
        });
    }
}
