// In general this new version of KpiSettings works the same as the old one (which was very problematic...),
// the code was just (hopefully) "cleaned up".

// Regular workflow:
// - The checkboxes state is maintained in kpiGroups
// - This state is also kept up-to-date in the SettingsContainer component, via updateContainer()
// - When "Apply Selection" is clicked, SettingsContainer applies the current state (changes the visualizationContext, etc.)
// via the SettingsContainer.applySelection() method
// - This all means the data usually flows in the direction KpiSettings -> SettingsContainer.
// However onShow always loads data in the opposite direction (SettingsContainer -> KpiSettings).
// Reason for that: if the user discard changes in the form (by clicking outside it) we have to load the
// current checkboxes state from SettingsContainer.

// Notifications workflow:
// - Here the visualization context is changed *directly* by the subscriber methods (this.visualizationContext.set(...)),
// not through SettingsContainer. Afterwards the SettingsContainer is "notified" about the changes (updateContainer(...)),
// so that its settings are updated accordingly.

// Other remarks:

// - updateContainer() updates 2 things in SettingsContainer: container *settings* and container *context*
// Container context seems redundant. The method updateContainer() always generate it from the container settings,
// to make this redundancy explicit. SettingsContainer needs the container context, so I couldn't remove it.

// - there are some utility methods which convert the data structures used in this class:
// - kpiGroupsToContainerSettings and its counterpart containerSettingsToKpiGroups
// - containerSettingsToContainerContext and selectedKpisToContainerSettings, which can also be considered counterparts of each other

// - it seems the notifications are always triggered by the SettingsContainer, then the subscribers in this class receive them
// and make the corresponding changes *in SettingsContainer*. This circular workflow has been inherited from the old version and
// doesn't make a lot of sense. See also related comments in subscribeToNotifications method.

// - refactoring all Settings related components into a Redux based implementation could make the code more simple

import {
    Component,
    DoCheck,
    ElementRef,
    Inject,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from '@angular/core';
import { HeaderRemovedService } from '../../services/header-removed.service';
import * as _ from 'lodash';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { PageDefinitionModel } from 'insightui.page-definition/page-definition.model';
import { ILogger, LogService } from '../../services/logging/log.service';

interface KpiValue {
    readonly id: string;
    readonly title: string;
    readonly tooltipText: string;
    readonly isGap: boolean;
    readonly disabled: boolean;
    readonly checked: boolean;
}

interface KpiGroup {
    readonly title: string;
    readonly values: KpiValue[];
}

interface ContainerSetting {
    id: string;
    // It should be called "applied" instead of "checked" but if renamed,
    // (as instances of this type are set in SettingsContainer)
    // it could break other code which makes use of SettingsContainer
    checked: boolean;
    toBeApplied: boolean;
}

type SelectedKpis = string[];

type KpiChecker = (oldValue: KpiValue) => boolean;

@Component({
    selector: 'kpi-settings',
    templateUrl: './kpiSettings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KpiSettingsComponent implements OnInit, DoCheck {
    static readonly GAP: string = 'gap';
    static readonly CLASS_FOR_VISIBLE: string = 'settings-container-directives-visible';

    @Input('containerCtrl')
    container: any;

    kpiGroups: KpiGroup[];
    readonly settingsTitle: string = 'KPIs';

    private hostElement: ElementRef;
    private vcProperty: any;
    private visualizationContextService: any;
    private drillingService: any;
    private drillLevelSettingService: any;
    private visible: boolean;
    private settingsNotificationService: any;
    private headerRemovedNotificationService: any;
    private filterNotificationService: any;

    private logger: ILogger;

    constructor(
        element: ElementRef,
        private readonly headerRemovedService: HeaderRemovedService,
        private readonly pageDefinitionService: PageDefinitionService,
        @Inject('$injector') $injector,
        private cdr: ChangeDetectorRef,
        logService: LogService
    ) {
        this.logger = logService.getLogger('KpiSettingsComponent');
        this.logger.debug('ctor');
        this.hostElement = element;
        this.vcProperty = $injector.get('VC_PROPERTY');
        this.visualizationContextService = $injector.get('VisualizationContextService');

        this.drillingService = $injector.get('DrillingService');
        this.drillLevelSettingService = $injector.get('DrillLevelSettingService');

        this.settingsNotificationService = $injector.get('SettingsNotificationService');
        this.headerRemovedNotificationService = $injector.get(
            'HeaderRemovedNotificationService'
        );
        this.filterNotificationService = $injector.get('FilterNotificationService');
    }

    private static isSourceKpiGap(kpi: string): boolean {
        return kpi === '' || kpi.includes(KpiSettingsComponent.GAP);
    }

    private static buildGapKpiValue(kpi: string): KpiValue {
        return {
            id: (kpi || 0) as string,
            title: kpi,
            tooltipText: null,
            isGap: true,
            disabled: false,
            checked: false
        };
    }

    private static containerSettingsToContainerContext(
        containerSettings: ContainerSetting[]
    ): string[] {
        return containerSettings
            .filter((setting: ContainerSetting) => setting.toBeApplied)
            .map((setting: ContainerSetting) => setting.id);
    }

    private static buildInitialContainerSetting(kpiValue: KpiValue): ContainerSetting {
        return {
            id: kpiValue.id,
            // We assume: if a kpi is initially checked in this component (kpiValue.checked)
            // then it is also initially applied (checked property in this object) in the container.
            checked: kpiValue.checked,
            toBeApplied: kpiValue.checked
        };
    }

    ngOnInit(): void {
        this.setHidden();
        this.initKpis(false);

        // all this once in the beginning so invalid KPIs are removed from kpiList in VC
        this.applyPeriodFilterChanged();

        this.subscribeToNotifications();
    }

    ngDoCheck(): void {
        // This hook is called *a lot*. It would be better to check this condition
        // using ngOnChanges, which is only called when an @Input property is changed.
        // But it seems @Input('class') triggers ngOnChanges only once...
        if (
            this.hostElement.nativeElement.classList.contains(
                KpiSettingsComponent.CLASS_FOR_VISIBLE
            )
        ) {
            if (!this.isVisible()) {
                this.onShow();
                this.setVisible();
            }
        } else {
            this.setHidden();
        }
    }

    onHide(): void {
        this.container.hideApply();
        this.hostElement.nativeElement.classList.add(
            'settings-container-directives-hidden'
        );
        this.hostElement.nativeElement.classList.remove(
            'settings-container-directives-visible'
        );
        this.setHidden();
    }

    onKpiChanged(kpi: string): void {
        const toggleKpi: KpiChecker = (oldValue: KpiValue) =>
            oldValue.id === kpi ? !oldValue.checked : oldValue.checked;

        this.kpiGroups = this.buildUpdatedKpiGroups(toggleKpi);
        this.updateContainer(this.kpiGroupsToContainerSettings());
    }

    private buildUpdatedKpiGroups(kpiChecker: KpiChecker): KpiGroup[] {
        return this.kpiGroups.map((group: KpiGroup) => {
            const newValues = group.values.map((value: KpiValue) => {
                const checked = kpiChecker(value);
                return { ...value, checked } as KpiValue;
            });
            return { ...group, ...{ values: newValues } } as KpiGroup;
        });
    }

    onBackToDefault(): void {
        this.initKpis(true);
    }

    private onShow(): void {
        this.loadSettingsFromContainer();
        this.cdr.markForCheck();
    }

    private setVisible() {
        this.visible = true;
    }

    private setHidden() {
        this.visible = false;
    }

    private isVisible() {
        return this.visible;
    }

    private initKpis(useDefaults = false): void {
        this.kpiGroups = this.buildKpiGroups(
            useDefaults ? this.getDefaultKpis() : this.getSelectedKpis(),
            this.getSourceKpiGroups()
        );
        this.updateContainer(this.kpiGroupsToContainerSettings());
    }

    private kpiGroupsToContainerSettings(): ContainerSetting[] {
        const existingSettings = this.getContainerSettings();

        return this.flattenKpiValues(this.kpiGroups).map((kpiValue: KpiValue) => {
            const settingToUpdate: ContainerSetting = existingSettings.find(
                setting => setting.id === kpiValue.id
            );
            if (settingToUpdate) {
                // settingToUpdate.checked is preserved,
                // to retrieve it, for example, in case we close and open the KpiSettings panel again
                return { ...settingToUpdate, toBeApplied: kpiValue.checked };
            } else {
                return KpiSettingsComponent.buildInitialContainerSetting(kpiValue);
            }
        });
    }

    private containerSettingsToKpiGroups(
        containerSettings: ContainerSetting[]
    ): KpiGroup[] {
        const loadKpiFromContainer: KpiChecker = (oldValue: KpiValue) =>
            this.isAppliedInContainer(oldValue.id, containerSettings);

        return this.buildUpdatedKpiGroups(loadKpiFromContainer);
    }

    private getDefaultKpis(): SelectedKpis {
        return this.getCurrentPage().getKpiDefinitionsForLevel(
            this.getLevel(),
            'default'
        );
    }

    private getSelectedKpis(): SelectedKpis {
        return this.visualizationContextService.get(this.vcProperty.KpiList);
    }

    private getLevel(): string {
        const drillLevel = this.drillingService.getDrillLevel();
        return drillLevel.level === 'brand' ? drillLevel.dataLevel : drillLevel.level;
    }

    private getCurrentPage(): PageDefinitionModel {
        return this.pageDefinitionService.getCurrentPage();
    }

    private buildKpiGroups(
        selectedKpis: SelectedKpis,
        sourceKpiGroups: any[]
    ): KpiGroup[] {
        return Object.entries(sourceKpiGroups).map(([key, sourceKpiGroup]) =>
            this.buildKpiGroup(sourceKpiGroup, selectedKpis)
        );
    }

    private buildKpiGroup(sourceKpiGroup: any, selectedKpis: SelectedKpis): KpiGroup {
        const values = sourceKpiGroup.kpis.map(kpi => {
            return KpiSettingsComponent.isSourceKpiGap(kpi)
                ? KpiSettingsComponent.buildGapKpiValue(kpi)
                : this.buildKpiValue(kpi, selectedKpis);
        });

        return {
            title: sourceKpiGroup.title,
            values
        };
    }

    private buildKpiValue(kpi: string, selectedKpis: SelectedKpis): KpiValue {
        return {
            id: kpi,
            title: this.getCurrentPage().getKpi(kpi).title as string,
            tooltipText: this.getTooltipText(kpi),
            isGap: false,
            disabled: this.isKpiDisabled(kpi),
            checked: selectedKpis.includes(kpi)
        };
    }

    private isKpiDisabled(kpi: string): boolean {
        return (
            !this.getAvailableKpis().includes(kpi) ||
            this.getDisabledKpis().includes(kpi) ||
            !this.isKpiToShowInPeriod(kpi)
        );
    }

    private getAvailableKpis(): string[] {
        return this.getCurrentPage().getKpiDefinitionsForLevel(
            this.getLevel(),
            'available'
        );
    }

    private getDisabledKpis(): string[] {
        return this.visualizationContextService.getVisualizationContext().report
            .kpiDefinition.disabled;
    }

    private isKpiToShowInPeriod(kpi: string): boolean {
        const kpiObj = this.getCurrentPage().getKpi(kpi);
        if (kpiObj.attributes) {
            const shownOnlyInPeriodicities = kpiObj.attributes.find(
                attr => attr.name === 'shownOnlyInPeriodicities'
            );
            if (shownOnlyInPeriodicities) {
                const periodicities = _.get(shownOnlyInPeriodicities, 'value', '').split(
                    ','
                );

                // kpis that are allowed monthly are also available in 13 running months
                if (periodicities.includes('monthly')) {
                    periodicities.push('running_13_month');
                }

                if (!periodicities.includes(this.getCurrentPeriodicity())) {
                    return false;
                }
            }
        }
        return true;
    }

    private getCurrentPeriodicity(): any {
        const vc = this.visualizationContextService.getVisualizationContext();
        return vc.periodicity ? vc.periodicity : vc.page.defaultPeriodicity;
    }

    private getSourceKpiGroups(): any {
        const allKpis: string[] = this.getAllKpis();
        return this.getCurrentPage().getKpiGroupsWithKpis(allKpis);
    }

    private getAllKpis(): string[] {
        return _.map(
            this.visualizationContextService.getVisualizationContext().report
                .kpiDefinition.kpi,
            'id'
        );
    }

    /**
     * This method doesn't effectively change anything in the
     * filter settings, context, etc. which are currently active in the UI.
     * This just keeps the data up-to-date in the container, so that SettingsContainer.applySelection()
     * can carry out the effective change.
     */
    private updateContainer(containerSettings: ContainerSetting[]): void {
        this.container.setSettings('kpiSelection', { kpis: containerSettings });

        const containerContext: string[] = KpiSettingsComponent.containerSettingsToContainerContext(
            containerSettings
        );
        this.container.changeContext(this.vcProperty.KpiList, containerContext);
    }

    private flattenKpiValues(kpiGroups: KpiGroup[]): KpiValue[] {
        const values = [];
        kpiGroups.forEach((kpiGroup: KpiGroup) => {
            kpiGroup.values.forEach((kpiValue: KpiValue) => {
                values.push(kpiValue);
            });
        });

        return values;
    }

    private flattenKpis(kpiGroups: KpiGroup[]): string[] {
        const kpis = [];
        kpiGroups.forEach((group: KpiGroup) => {
            group.values.forEach((kpiValue: KpiValue) => {
                kpis.push(kpiValue.id);
            });
        });

        return kpis;
    }

    private loadSettingsFromContainer(): void {
        this.kpiGroups = this.containerSettingsToKpiGroups(this.getContainerSettings());
    }

    private getContainerSettings(): ContainerSetting[] {
        const settings = _.get(this.container.getSettings('kpiSelection'), 'kpis');
        return (settings || []) as ContainerSetting[];
    }

    private isAppliedInContainer(
        kpi: string,
        containerSettings: ContainerSetting[]
    ): boolean {
        return !!containerSettings.find(
            (setting: ContainerSetting) => setting.id === kpi && setting.checked === true
        );
    }

    //  If I understand correctly, the current notification mechanism for the Settings components
    //  works like this (example):

    //  KpiRank form is changed -> user clicks on applySelection ->
    //  -> SettingsContainer apply the rank changes (modify VisualizationContext, etc.)
    //  and triggers a notification via SettingsNotificationService ->
    //  -> KpiSettings gets notified (via subscription), updates all what is related to "kpiList"

    //  Instead of that job delegation to KpiSettings, it would probably be better
    //  to have SettingsContainer do the kpiList related updates directly
    //  (especially because some of these changes mean updating the VC directly, which is something general,
    //  not specific to KpiSettings at all), this way eliminating the notification/subscription altogether.
    //  Afterwards, KpiSettings will notice the changes via its onShow method, when its form is opened again.

    private subscribeToNotifications(): void {
        this.subscribeToSettingsNotification();
        this.subscribeToHeaderRemovedNotification();
        this.subscribeToFilterNotifications();
        this.subscribeToDrillingNotification();
    }

    private applyRankSelectionToKpiList(): void {
        const rankingOptions = this.getCurrentPage().report.rankingOptions;
        if (rankingOptions) {
            // get() -> clone -> set() is used on purpose here for cleaner and testable code
            // (instead of just calling get() and doing an "in-place" processing of the returned array)
            const newKpis = this.visualizationContextService
                .get(this.vcProperty.KpiList)
                .slice();
            rankingOptions.attributes.forEach(option => {
                _.pull(newKpis, option.name);
            });
            const selectedRankKpi = this.visualizationContextService.get(
                this.vcProperty.RankKpi
            );
            newKpis.push(selectedRankKpi);

            this.visualizationContextService.set(this.vcProperty.KpiList, newKpis);
            this.updateContainer(this.selectedKpisToContainerSettings(newKpis));
        }
    }

    private applyHeaderRemoved(): void {
        const newKpis = this.headerRemovedService.removeHeaderFromVisualizationContext();
        this.resetKpisCache(newKpis);
        this.updateContainer(this.selectedKpisToContainerSettings(newKpis));
    }

    private applyPeriodFilterChanged(): void {
        const kpisToShow = this.getKpisForThisLevelAndPeriod();
        this.visualizationContextService.set(this.vcProperty.KpiList, kpisToShow);
        this.updateContainer(this.selectedKpisToContainerSettings(kpisToShow));
        this.kpiGroups = this.buildKpiGroups(kpisToShow, this.getSourceKpiGroups());
    }

    private applyDrillingNotification(): void {
        if (this.visualizationContextService.get(this.vcProperty.RankKpi)) {
            return;
        }

        const selectedKpis = this.getKpisForThisLevelAndPeriod();
        const kpis = _.intersection(selectedKpis, this.getAvailableKpis());
        this.visualizationContextService.set(this.vcProperty.KpiList, kpis);
        this.updateContainer(this.selectedKpisToContainerSettings(kpis));

        this.kpiGroups = this.buildKpiGroups(kpis, this.getSourceKpiGroups());
    }

    private selectedKpisToContainerSettings(selectedKpis: string[]): ContainerSetting[] {
        return this.flattenKpis(this.kpiGroups).map((kpi: string) => {
            const isKpiSelected: boolean = selectedKpis.includes(kpi);
            return {
                id: kpi,
                checked: isKpiSelected,
                toBeApplied: isKpiSelected
            };
        });
    }

    private subscribeToSettingsNotification(): void {
        this.settingsNotificationService.subscribeService(
            this.vcProperty.RankKpi,
            this.applyRankSelectionToKpiList.bind(this)
        );
    }

    private subscribeToHeaderRemovedNotification(): void {
        this.headerRemovedNotificationService.subscribeService(
            'kpiRemovableHeader',
            this.applyHeaderRemoved.bind(this)
        );
    }

    private subscribeToFilterNotifications(): void {
        this.filterNotificationService.subscribeService(
            'PeriodFilterModel',
            this.applyPeriodFilterChanged.bind(this)
        );
        this.filterNotificationService.subscribeService(
            'ProductModel',
            this.applyDrillingNotification.bind(this)
        );
    }

    private subscribeToDrillingNotification(): void {
        this.drillingService.onChange.subscribeService(
            'DrillTargetChanged',
            this.applyDrillingNotification.bind(this)
        );
    }

    private resetKpisCache(newKpis: string[]): void {
        this.drillLevelSettingService.setKpis(newKpis);
    }

    private getKpisForThisLevelAndPeriod(): string[] {
        const kpiList: string[] = this.visualizationContextService.get(
            this.vcProperty.KpiList
        );

        const levelList: string[] = this.drillLevelSettingService.getKpis();
        let kpisForThisLevel = _.union(kpiList, levelList);

        const availableKpis = this.getAvailableKpis();
        const currentPage = this.getCurrentPage();
        _.forEach(_.get(currentPage, 'report.rankingOptions.attributes', []), attr => {
            availableKpis.push(_.get(attr, 'name'));
        });

        kpisForThisLevel = _.intersection(kpisForThisLevel, availableKpis);

        return kpisForThisLevel.filter(kpi => this.isKpiToShowInPeriod(kpi));
    }

    private getTooltipText(kpi: string) {
        const kpiObj = this.getCurrentPage().getKpi(kpi);
        return kpiObj.tooltip.shortTitle ? kpiObj.tooltip.shortTitle : kpiObj.title;
    }
}
