import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { StaticVisualizationContextService } from 'insightui.data/shared/static-visualization-context.service';
import { Observable, ReplaySubject, Subject, timer as observableTimer } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';

interface AngularInjector {
    get<T = any>(name: string): T;
    has(name: string): boolean;
    instantiate<T>(type: any): T;
}

/**
 * Resolver that waits until the angular 1 $injector is ready.
 */
@Injectable()
export class AngularInjectorResolver implements Resolve<AngularInjector> {
    private static $injector: Subject<AngularInjector> = new ReplaySubject<
        AngularInjector
    >(1);
    private static ng1Module: UpgradeModule;

    constructor(ng1Module: UpgradeModule) {
        if (ng1Module) {
            AngularInjectorResolver.ng1Module = ng1Module;
            AngularInjectorResolver.waitForInjector();
        }
    }

    /**
     * Just for setting up Unittests correctly without the need to fully bootstrap a Hybrid AngularJS/Angular setup.
     */
    public static __unitTestSetupInjector(testInjector: AngularInjector): void {
        AngularInjectorResolver.ng1Module = { $injector: testInjector } as any;
        AngularInjectorResolver.$injector.next(testInjector);
    }

    public static __unitTestReset(): void {
        AngularInjectorResolver.ng1Module = undefined;
    }

    public static getRootScope() {
        return AngularInjectorResolver.$injector.pipe(
            map(inject => inject.get('$rootScope')),
            distinctUntilChanged()
        );
    }

    public static waitForInjector(): Promise<AngularInjector> {
        return new Promise<AngularInjector>((resolve, reject) => {
            if (
                AngularInjectorResolver.ng1Module &&
                AngularInjectorResolver.ng1Module.$injector
            ) {
                const $injector = AngularInjectorResolver.ng1Module.$injector;
                this.resolveInjector($injector, resolve);
                return;
            }
            const timer = observableTimer(1, 100);
            const subscription = timer.subscribe(iteration => {
                const $injector = AngularInjectorResolver.ng1Module.$injector;
                if ($injector) {
                    subscription.unsubscribe();
                    this.resolveInjector($injector, resolve);
                } else {
                    if (iteration % 10 === 0) {
                        console.warn(
                            'AngularInjectorResolver.waitForInjector(): still not available in iteration ' +
                                iteration
                        );
                    }
                }
            });
        });
    }

    private static resolveInjector($injector: any, resolve) {
        const visContextService = $injector.get('VisualizationContextService');
        if (visContextService) {
            StaticVisualizationContextService.setInstance(visContextService);
        }
        AngularInjectorResolver.$injector.next($injector);
        resolve($injector);
    }

    get(): Observable<AngularInjector> {
        return AngularInjectorResolver.$injector;
    }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<AngularInjector> {
        return AngularInjectorResolver.waitForInjector();
    }
}
