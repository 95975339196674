/**
 * UserProfileService API Documentation
 * User Profile Service stores Favorites, Collections, Announcements and user-events.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportSnapshotDto } from './report-snapshot-dto';

export interface CollectionDto {
    collectionType: CollectionDtoCollectionType;
    createdTime: Date;
    daysToDeletion: number;
    deletable: boolean;
    id: string;
    lastAccessTime: Date;
    modifiedTime: Date;
    name: string;
    reportSnapshots: Array<ReportSnapshotDto>;
    status: CollectionDtoStatus;
}
export enum CollectionDtoCollectionType {
    FAVORITES = 'FAVORITES',
    TEMPORARY = 'TEMPORARY',
    USER = 'USER',
    SHARED = 'SHARED'
}
export enum CollectionDtoStatus {
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    ALLSTATUS = 'ALL_STATUS'
}
