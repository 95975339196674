import { distinctUntilChanged, map } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ChartConfiguration, ChartModel, UnsubscribeFn } from '../types';
import { Observable, Subscriber } from 'rxjs';
import * as _ from 'lodash';
import { DimensionName } from 'insightui.data/types';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

@Injectable()
export class ActiveChartModel {
    chartModel(configuration: ChartConfiguration): Observable<ChartModel> {
        const source = _.get(configuration.getCurrentLegend(), 'source');
        const chart = configuration.getChart(source);
        const model: ChartModel = _.get(chart, 'model');

        return new Observable((subscriber: Subscriber<ChartModel>) => {
            let unsubscribeRecalculate: UnsubscribeFn = _.noop;

            const onRecalculate = () => {
                const newModel = chart.model;

                subscriber.next(newModel);

                unsubscribeRecalculate();
                unsubscribeRecalculate = newModel.onRecalculate(null, onRecalculate);
            };

            let unsubscribeDataChanged = _.noop;
            AngularInjectorResolver.waitForInjector().then(injector => {
                const chartDataNotificationService = injector.get(
                    'ChartDataNotificationService'
                );
                unsubscribeDataChanged = chartDataNotificationService.subscribeService(
                    'ChartDataChanged',
                    onRecalculate
                );
            });

            unsubscribeRecalculate = model.onRecalculate(null, onRecalculate);

            subscriber.next(model);
            return () => {
                unsubscribeRecalculate();
                unsubscribeDataChanged();
            };
        });
    }

    lastDimension(configuration: ChartConfiguration): Observable<DimensionName> {
        return this.chartModel(configuration).pipe(
            map(model => {
                const dimensions: DimensionName[] = model.getDimensions();

                return _.last(dimensions);
            }),
            distinctUntilChanged()
        );
    }
}
