import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnDestroy,
    HostListener,
    Inject,
    AfterViewChecked
} from '@angular/core';
import {
    FairShareDrill,
    FairShareDrillDirection,
    FairShareHover,
    FairShareReportRow,
    FairShareReportRows,
    FairShareRowHover,
    FairShareRowId,
    ValueGrowthRangeBounds,
    ValueGrowthRangeBoundsStatus
} from 'insightui.fair-share/fair-share.types';
import { ExcelExportService } from 'insightui.export/excel/excel-export.service';
import {
    FairShareReportExcelExportDataSelector,
    FairShareReportExcelExportDataSelectorExtraInfo
} from './fair-share-report-excel-export-data-selector';
import { LogService } from 'insightui.core/services/logging/log.service';
import { ExportExcelData } from 'insightui.export/excel/excel-export.types';
import { ExportExtraInfoService } from 'insightui.export/services/export-extra-info.service';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { WINDOW, DOCUMENT } from 'insightui.global/global.module';

export const COMPONENT_SELECTOR = 'gfk-fair-share-report';

@Component({
    selector: COMPONENT_SELECTOR,
    templateUrl: './fair-share-report.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./fair-share-report.component.scss'],
    viewProviders: [ExportExtraInfoService]
})
export class FairShareReportTableComponent
    implements OnInit, OnDestroy, AfterViewChecked {
    @Input()
    maxFairShareValue: number;

    @Input()
    shareLineValue: number;

    @Input()
    rows: FairShareReportRows;

    @Input()
    totalRow: FairShareReportRow;

    @Input()
    drillTargetName: string;

    @Input()
    valueGrowthRangeBounds: ValueGrowthRangeBounds;

    @Input()
    hasReachedValueGrowthRangeBounds: ValueGrowthRangeBoundsStatus;

    @Output()
    fairShareGapValueHoverRow: EventEmitter<FairShareRowHover> = new EventEmitter();

    @Output()
    growthGapValueHoverRow: EventEmitter<FairShareRowHover> = new EventEmitter();

    @Output()
    drill: EventEmitter<FairShareDrill> = new EventEmitter();

    private excelExportDataSelector: FairShareReportExcelExportDataSelector;

    public readonly preferredInfoboxPosition: ConnectionPositionPair[];

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private excelExportService: ExcelExportService,
        private extraInfoService: ExportExtraInfoService,
        logService: LogService
    ) {
        this.excelExportDataSelector = new FairShareReportExcelExportDataSelector(
            logService
        );

        this.preferredInfoboxPosition = [
            new ConnectionPositionPair(
                { originX: 'end', originY: 'bottom' },
                { overlayX: 'end', overlayY: 'top' }
            )
        ];
    }

    ngOnInit() {
        this.excelExportService.registerExcelExportDataGeneratorCallback(
            this.selectExcelExportData
        );
    }

    ngOnDestroy() {
        this.excelExportService.unregisterExcelExportDataGeneratorCallback();
    }

    ngAfterViewChecked() {
        this.resizeContent();
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.resizeContent();
    }

    trackByRow(idx: number, row: FairShareReportRow): number {
        return row.$rowId;
    }

    handleFairShareGapValueHovering(
        row: FairShareReportRow,
        hover: FairShareHover
    ): void {
        if (row.marketSizeGapValue) {
            this.fairShareGapValueHoverRow.emit({ row, hover });
        }
    }

    handleGrowthGapValueHovering(row: FairShareReportRow, hover: FairShareHover): void {
        if (row.valueGrowthGapValue) {
            this.growthGapValueHoverRow.emit({ row, hover });
        }
    }

    handleDrillUp(): void {
        this.drill.emit({ direction: FairShareDrillDirection.Up });
    }

    handleDrillDown(rowId: FairShareRowId): void {
        this.drill.emit({ direction: FairShareDrillDirection.Down, rowId });
    }

    public resizeContent() {
        this.resizeTableHeight();
        this.resizeTableHeader();
    }

    private resizeTableHeight() {
        const tableContainer: HTMLElement = this.document.querySelector(
            '.table-container'
        );
        const tableContainerInner: HTMLElement = this.document.querySelector(
            '.table-container-inner'
        );

        if (tableContainer && tableContainerInner) {
            const FOOTER_PADDING = 35;
            const height =
                this.window.innerHeight -
                FOOTER_PADDING -
                tableContainer.offsetTop -
                tableContainerInner.offsetTop;
            tableContainer.style.height = height + 'px';
        }
    }

    private resizeTableHeader() {
        const header: HTMLTableRowElement = this.document.querySelector(
            '.table-header-fixed thead tr.report-table__header'
        );
        const subheader: HTMLTableRowElement = this.document.querySelector(
            '.table-header-fixed thead tr.report-table__subheader'
        );
        const tableRow: HTMLTableRowElement = this.document.querySelector(
            '.table-header-fixed tbody tr'
        );

        if (tableRow && (header || subheader)) {
            for (let i = 0; i < tableRow.cells.length; i++) {
                const cell = tableRow.cells[i];
                const scrollbar = i === tableRow.cells.length - 1 ? 20 : 0;
                if (header) {
                    header.cells[i].style.width = cell.clientWidth + scrollbar + 'px';
                }
                if (subheader) {
                    subheader.cells[i].style.width = cell.clientWidth + scrollbar + 'px';
                }
            }
        }
    }

    private selectExcelExportData: () => ExportExcelData = () => {
        const extras: FairShareReportExcelExportDataSelectorExtraInfo = {
            country: this.extraInfoService.getCountry(),
            currency: this.extraInfoService.getCurrency(),
            distributionUnit: this.extraInfoService.getDistributionUnit(),
            optionBar: this.extraInfoService.getOptionBar(),
            filterBy: this.extraInfoService.getFilterBy(),
            period: this.extraInfoService.getPeriod(),
            productName: this.extraInfoService.getProductName(),
            products: this.extraInfoService.getProducts(),
            title: this.extraInfoService.getBusinessQuestion(),
            drillLevel: this.extraInfoService.getDrillLevelDisplayName()
        };
        return this.excelExportDataSelector.getExportDataSelector(
            extras,
            this.totalRow,
            this.rows
        );
    };
}
