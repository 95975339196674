import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'gfk-fair-share-legend',
    templateUrl: './fair-share-legend.component.html',
    styleUrls: ['./fair-share-legend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareLegendComponent {
    @Input()
    lineValue: number;

    @Input()
    maxValue: number;

    @Input()
    minValue: number = 0;

    lineOffset(): number {
        return ((this.lineValue - this.minValue) / (this.maxValue - this.minValue)) * 100;
    }
}
