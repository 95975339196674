import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseModel } from './base.model';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import {
    MultiNotificationSubscription,
    NotificationEmitter,
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { Column } from 'insightui.table/components/datatable/shared/models/datatable.models';

export enum SortDirection {
    DESC,
    ASC
}

export interface SortSetting {
    fields: string[];
    direction: SortDirection;
    reset?: boolean;
    defaultSortingDimensions?: string[];
    restoreFields?: string[];
    columnId?: string;
    columnTitle?: string;
    columnKey?: string;
}

const RESET_ON = [
    'KpiSelectionModel',
    'TopBottomModel',
    'BaseChannelFilterModel',
    'DistributionUnitModel',
    'ProductModel',
    'PosTypeModel'
];

interface DefaultDimensions {
    orderedDimensionNames: string[];
    orderedDimensionValues: string[];
}

/**
 * Model representing the current sorting state.
 */
@Injectable()
export class SortingModel extends BaseModel<SortSetting> {
    // For keeping the same order as in DatatableConfigurationService.determineHeaderField() (and maybe other places).
    // Notice that this doesn't match the vertical (top to bottom) ordering of dimensions in the datatable's header
    // (which is channel, kpi, period, posType)
    private static ORDERED_SORT_DIMENSIONS = ['channel', 'period', 'posType', 'kpi'];

    name = SortingModel;

    @NotificationSubscription(NotificationNamespace.Sorting)
    filterNotification$;

    @MultiNotificationSubscription([
        NotificationNamespace.SplitBy,
        NotificationNamespace.Drilling,
        NotificationNamespace.Filter,
        NotificationNamespace.HeaderRemoved,
        NotificationNamespace.Settings
    ])
    resetNotification$: Observable<NotificationEvent>;

    @VisualizationContextBinding('sorting')
    sortingStore;

    @VisualizationContextBinding('headerRemoved')
    headerRemoved;

    @NotificationEmitter(NotificationNamespace.Sorting)
    sortingChanged$: Subject<void>;

    baseChannelModel;
    kpiSelectionModel;
    periodFilterModel;

    private KEY_BLACKLIST = ['sortAsc', 'sortDesc'];
    private periodUtility: any;

    constructor(private angularResolver: AngularInjectorResolver) {
        super();

        this.resetNotification$
            .pipe(
                filter(event => {
                    if (event && event.eventName === 'FILTER_SETTINGS') {
                        return event.data
                            .map(eventName =>
                                typeof eventName === 'string' ? eventName : eventName[0]
                            )
                            .some(eventName => RESET_ON.indexOf(eventName) >= 0);
                    }
                    return true;
                })
            )
            .subscribe(() => {
                const sortingStoreCloned = this.getSorting().fields.concat([]);
                this.reset();
                this.saveAndNotifyWithoutReload();
                // restoreFields should be set after saved
                this.setRestoreFields(sortingStoreCloned);
            });
    }

    applyChanges(promise: { resolve; reject }): BaseModel<SortSetting> {
        if (this.parent.isRoot()) {
            this.saveToVisualizationContext();
        }
        if (
            this.data[0].reset ||
            this.hash(this.data[0]) !== this.hash(this.parent.data[0])
        ) {
            this.data[0].reset = false;
            promise.resolve(this);
        } else {
            promise.reject(this);
        }
        return this;
    }

    onSave(promise: { resolve; reject }): BaseModel<SortSetting> {
        return this;
    }

    onRestore(items: SortSetting[]): BaseModel<SortSetting> {
        return null;
    }

    onReadData(items?: SortSetting[]): BaseModel<SortSetting> {
        if (items && items.length) {
            this.data = items;
            return this;
        }
        if (this.data && this.data.length) {
            return this;
        }
        const sortInfo = { fields: [], direction: SortDirection.DESC };
        this.data = [sortInfo];
        this.resetSorting();

        return this;
    }

    rewriteDefaultSortingAndFields() {
        const currentSorting = this.getSorting();
        const newDefaultSorting = this.baseChannelModel
            .getSelected()
            .getBaseChannelDimensions();
        const newDefaultSortingKeys = Object.keys(newDefaultSorting);
        const lastDefaultSortingKeys = currentSorting.defaultSortingDimensions || [];
        newDefaultSortingKeys.push('kpi');

        if (newDefaultSortingKeys.length === lastDefaultSortingKeys.length) {
            return;
        }

        if (this.sortingStore && this.sortingStore.restoreFields) {
            currentSorting.fields = this.sortingStore.restoreFields.concat([]);
            this.setRestoreFields(null);
        }

        currentSorting.defaultSortingDimensions = newDefaultSortingKeys;
        // Add new dimensions
        newDefaultSortingKeys.forEach((key, index) => {
            if (lastDefaultSortingKeys.indexOf(key) < 0 && this.filterDimensions(key)) {
                currentSorting.fields.splice(index, 0, newDefaultSorting[key]);
            }
        });
        // Remove existing dimensions
        lastDefaultSortingKeys.forEach((key, index) => {
            if (newDefaultSortingKeys.indexOf(key) < 0 && this.filterDimensions(key)) {
                currentSorting.fields.splice(index, 1);
            }
        });
        this.saveToVisualizationContext();
    }

    orderDimensions(unorderedDimensions: string[]): string[] {
        if (
            !unorderedDimensions.every(unorderedDimension =>
                SortingModel.ORDERED_SORT_DIMENSIONS.includes(unorderedDimension)
            )
        ) {
            throw new Error('Unknown sorting dimension');
        }
        return SortingModel.ORDERED_SORT_DIMENSIONS.filter(orderedDimension =>
            unorderedDimensions.includes(orderedDimension)
        );
    }

    getSorting(): SortSetting {
        return this.getSortingAll()[0];
    }

    getSortingAll(): SortSetting[] {
        if (!this.data || this.data.length === 0) {
            this.readData();
        }
        return this.data.slice();
    }

    sortBy(fields: string[], direction?: SortDirection) {
        const sortData = this.getSorting();
        sortData.fields = fields;
        sortData.direction = direction;
    }

    reset() {
        this.resetSorting();
        this.getSorting().reset = true;
    }

    saveAndNotifyWithoutReload(customSort: boolean = false) {
        if (!customSort) {
            const firstItem = this.data[0];
            firstItem.restoreFields = null;
            firstItem.columnId = null;
            firstItem.columnTitle = null;
            this.data = [firstItem];
        }
        this.saveToVisualizationContext();

        // Only Non-Parent model will be checked and copied
        if (
            !this.isRoot() &&
            (this.data[0].reset ||
                this.hash(this.data[0]) !== this.hash(this.parent.data[0]))
        ) {
            this.data[0].reset = false;
            this.parent.copy(this);
        }
        this.sortingChanged$.next();
    }

    isTheOnlySortedColumn(column: Column) {
        return this.isOnlyOneColumnSorted() && this.isColumnSorted(column);
    }

    resetSorting() {
        this.angularResolver.get().subscribe(injector => {
            this.baseChannelModel = injector.get('BaseChannelModel');
            this.baseChannelModel.readData();

            this.kpiSelectionModel = injector.get('KpiSelectionModel');
            this.kpiSelectionModel.readData();

            this.periodFilterModel = injector.get('PeriodFilterModel');
            this.periodFilterModel.readData();

            this.periodUtility = injector.get('PeriodUtilityService');
        });
        if (!this.data) {
            return;
        }

        const defaultDimensions = this.getDefaultDimensions();
        if (!defaultDimensions) {
            return;
        }
        const defaultSorting: SortSetting = {} as SortSetting;
        defaultSorting.defaultSortingDimensions = defaultDimensions.orderedDimensionNames;
        defaultSorting.fields = defaultDimensions.orderedDimensionValues;
        defaultSorting.direction = SortDirection.DESC;
        defaultSorting.columnId = null;
        defaultSorting.columnTitle = null;

        this.data = [{ ...this.data[0], ...defaultSorting }];
    }

    private setRestoreFields(value) {
        this.getSorting().restoreFields = value;
        this.sortingStore.restoreFields = value;
    }
    private getDefaultDimensions(): DefaultDimensions {
        const baseChannelSorting = this.baseChannelModel
            .getSelected()
            .getBaseChannelDimensions();
        if (!baseChannelSorting) {
            return null;
        }
        const kpiSorting = {
            kpi: this.kpiSelectionModel.findOne('selected', true).id
        };
        const periodSorting = { period: this.getFirstPeriod() };
        const sortingParams = {
            ...baseChannelSorting,
            ...kpiSorting,
            ...periodSorting
        };

        const dimensionNames = Object.keys(sortingParams);
        const orderedDimensionNames = this.orderDimensions(dimensionNames).filter(
            this.filterDimensions.bind(this)
        );
        const orderedDimensionValues = orderedDimensionNames.map(
            dimensionName => sortingParams[dimensionName]
        );

        return {
            orderedDimensionNames,
            orderedDimensionValues
        };
    }

    private filterDimensions(key) {
        return key[0] !== '$' && this.KEY_BLACKLIST.indexOf(key) < 0;
    }

    private hash(data: SortSetting) {
        return (
            data.fields
                .concat([])
                .sort()
                .join('.') + data.direction
        );
    }

    private saveToVisualizationContext() {
        this.sortingStore = this.data.map(item => {
            return {
                fields: item.fields.concat([]),
                direction: item.direction,
                restoreFields: item.restoreFields,
                columnId: item.columnId,
                columnTitle: item.columnTitle
            } as SortSetting;
        });
    }

    private isColumnSorted(column: Column) {
        return this.getSorting().fields.every(sortField => {
            return column.field.includes(sortField.replace('-', ''));
        });
    }

    private isOnlyOneColumnSorted() {
        return this.getSortingAll().length === 1;
    }

    private getFirstPeriod(): string {
        return this.periodUtility.getPeriodFromDate(
            this.periodFilterModel
                .selectedPeriodicity()
                .values.find(value => value.checked).start
        );
    }
}
