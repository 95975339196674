import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AngularInjectorResolver } from './angular-injector.resolver';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { FILTER_INIT } from 'insightui.report-filter/report-filter.actions';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import * as _ from 'lodash';
import { LoadStartupDataService } from 'insightui.report-filter/LoadStartupDataService';
import {
    LOADING_STARTED,
    LOADING_COMPLETED
} from 'insightui.loading-indicator/loading-indicator.actions';
import { Store } from '@ngxs/store';
import { CsLoadClientInfos } from 'insightui.client-switch/state/client-switch.actions';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { PageDefinitionLoaderService } from 'insightui.page-definition/services/page-definition-loader.service';

/**
 * Bootstrap the interactive header in a router. Should be used when using the interactive-header directive.
 */
@Injectable({
    providedIn: 'root'
})
export class LandingPageResolver implements Resolve<void> {
    private logger: ILogger;

    constructor(
        private ngRedux: NgRedux<AppRootState>,
        private revisionService: RevisionService,
        private pageDefinitionLoaderService: PageDefinitionLoaderService,
        private title: Title,
        private store: Store,
        logService: LogService
    ) {
        this.logger = logService.getLogger('LandingPageResolver');
    }

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<void> {
        this.ngRedux.dispatch(LOADING_STARTED());

        const ng1Injector = await AngularInjectorResolver.waitForInjector();

        this.logger.info(route);

        // TODO: merge with same calls in GeneralPageResolver
        this.store.dispatch(new CsLoadClientInfos());

        this.revisionService.loadRevisions();
        this.pageDefinitionLoaderService.loadKpis();
        this.pageDefinitionLoaderService.loadLabels();
        this.pageDefinitionLoaderService.loadIndex();

        const loadStartupDataService = ng1Injector.instantiate<LoadStartupDataService>(
            LoadStartupDataService
        );
        const countryId = route.queryParamMap.get('country');
        const periodId = _.parseInt(route.queryParamMap.get('period'));
        const visContext = await loadStartupDataService.loadLandingPageData(
            countryId,
            periodId
        );

        this.title.setTitle(route.data.title);

        this.logger.debug('dispatch FILTER_INIT', visContext);
        this.ngRedux.dispatch(FILTER_INIT(visContext));

        this.ngRedux.dispatch(LOADING_COMPLETED());
    }
}
