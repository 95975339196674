import actionCreatorFactory, { ActionCreator } from 'typescript-fsa';
import {
    FairShareRawReportData,
    FairShareRowId
} from 'insightui.fair-share/fair-share.types';

const factory = actionCreatorFactory('FAIR_SHARE');

export const FAIR_SHARE_DEACTIVATED: ActionCreator<void> = factory(
    'FAIR_SHARE_DEACTIVATED'
);
export const FAIR_SHARE_GAP_VALUE_ENTER: ActionCreator<FairShareRowId> = factory(
    'FAIR_SHARE_GAP_VALUE_ENTER'
);
export const FAIR_SHARE_GAP_VALUE_LEAVE: ActionCreator<FairShareRowId> = factory(
    'FAIR_SHARE_GAP_VALUE_LEAVE'
);
export const FAIR_SHARE_GROWTH_VALUE_ENTER: ActionCreator<FairShareRowId> = factory(
    'FAIR_SHARE_GROWTH_VALUE_ENTER'
);
export const FAIR_SHARE_GROWTH_VALUE_LEAVE: ActionCreator<FairShareRowId> = factory(
    'FAIR_SHARE_GROWTH_VALUE_LEAVE'
);
export const FAIR_SHARE_UPDATE_REPORT: ActionCreator<
    ReadonlyArray<FairShareRawReportData>
> = factory('FAIR_SHARE_UPDATE_REPORT');
export const FAIR_SHARE_DRILL_DOWN: ActionCreator<FairShareRowId> = factory(
    'FAIR_SHARE_DRILL_DOWN'
);
export const FAIR_SHARE_DRILL_UP: ActionCreator<void> = factory('FAIR_SHARE_DRILL_UP');
