import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import * as _ from 'lodash';
import { VCSetKeyAction } from './visualization-context.actions';
import {
    VcChart,
    VcCurrency,
    VcFavorite,
    VcRankContext
} from './visualization-context.types';

export interface VisualizationContextStateModel {
    currency?: VcCurrency;
    countryCode?: string;
    countryTitle?: string;

    channelList?: string[];

    businessQuestion?: string;
    favourite?: VcFavorite;

    chart?: VcChart;
    segmentFilterTitle?: string;

    splitByOnlineOffline?: boolean;

    showPriceClasses?: boolean;

    showFeatures?: boolean;

    rankContext?: VcRankContext;
}

@Injectable()
@State<VisualizationContextStateModel>({
    name: 'visualizationContext',
    defaults: {
        chart: { focusDimension: '', segmentationDimension: '' }
    }
})
export class VisualizationContextState {
    private logger: ILogger;
    constructor(logService: LogService) {
        this.logger = logService.getLogger('VisualizationContextState');
    }

    @Selector()
    public static focusDimension(state: VisualizationContextStateModel) {
        return state.chart.focusDimension;
    }

    @Selector()
    public static segmentationDimension(state: VisualizationContextStateModel) {
        return state.chart.segmentationDimension;
    }

    @Selector()
    public static channelList(state: VisualizationContextStateModel) {
        return state.channelList;
    }

    @Selector()
    public static rankComparison(state: VisualizationContextStateModel) {
        return state.rankContext?.rankComparison;
    }

    @Selector()
    public static favoriteName(
        state: VisualizationContextStateModel
    ): string | undefined {
        return state.favourite?.name;
    }

    @Selector()
    public static favorite(
        state: VisualizationContextStateModel
    ): VcFavorite | undefined {
        return state.favourite;
    }

    @Selector()
    public static businessQuestion(
        state: VisualizationContextStateModel
    ): string | undefined {
        return state.businessQuestion;
    }

    @Action(VCSetKeyAction)
    public setKey(
        ctx: StateContext<VisualizationContextStateModel>,
        { key, value }: VCSetKeyAction
    ) {
        const stateModel = { ...ctx.getState() };

        // clone anything that is put to the store to avoid that a reference type
        // can be changed afterwards from outside the store
        const valueClone = _.cloneDeep(value);

        if (typeof key === 'string' && key.includes('.')) {
            const paths = key.split('.');
            if (paths.length !== 2) {
                this.logger.debug(
                    `path '${key}' contains more than one dot. This is not supported. Ignoring`,
                    valueClone
                );
                return;
            }
            stateModel[paths[0]] = { ...stateModel[paths[0]], [paths[1]]: valueClone };
        } else {
            (stateModel[key] as any) = valueClone;
        }

        this.logger.debug(`set key '${String(key)}' to new value`, valueClone);
        ctx.setState(stateModel);
    }
}
