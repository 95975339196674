import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'gfk-report-accordion',
    templateUrl: './report-accordion.component.html',
    styleUrls: ['./report-accordion.component.scss']
})

export class ReportAccordionComponent implements OnInit {

    @Input()
    group: any;

    @Input()
    favourites: any;

    @Input()
    docId: any;

    open = true;

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.open = !this.open;
    }
}
