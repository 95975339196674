import { Component, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { ExcelExportService } from './excel-export.service';

@Component({
    selector: 'excel-export-button',
    templateUrl: 'excel-export-button.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .icon-excel {
                font-size: 22px;
                margin-left: 10px;
            }
        `
    ]
})
export class ExcelExportButtonComponent {
    constructor(private excelExportService: ExcelExportService) {}

    @HostListener('click')
    exportCurrentReport() {
        this.excelExportService.triggerExport();
    }
}
