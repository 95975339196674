import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { TablePluginRegistry } from 'insightui.table/services/table-plugin-registry.service';
import { animationFrameScheduler, from as observableFrom } from 'rxjs';
import { subscribeOn, delay } from 'rxjs/operators';

@Directive({
    selector: '[enablePlugins]'
})
export class EnablePluginsDirective implements OnInit, OnDestroy {
    @Input()
    enablePlugins: Cell;

    constructor(
        private tablePluginRegistry: TablePluginRegistry,
        private element: ElementRef
    ) {}

    ngOnInit(): void {
        this.enablePlugins.element = this.element;
        observableFrom(this.tablePluginRegistry.getRegistered())
            .pipe(delay(0))
            .subscribe(plugin => {
                plugin.enable(this.enablePlugins);
            });
    }

    ngOnDestroy(): void {
        this.tablePluginRegistry.getRegistered().forEach(plugin => {
            plugin.disable(this.enablePlugins);
        });
        this.enablePlugins.element = null;
    }
}
