import { map, debounceTime } from 'rxjs/operators';
import { Component, Input, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';
import { Observable } from 'rxjs';
import { EmptySearchService } from 'insightui.table/components/datatable/body/empty-search.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'empty-search',
    template: `
        <div class="datatable-empty-search" [ngClass]="{ active: isActive$ | async }">
            There are no items matching your search string.
        </div>
    `
})
export class EmptySearchComponent implements OnInit, OnDestroy {
    @Input() rows: number;

    @NotificationSubscription(NotificationType.TableSearch)
    onTableSearch$: Observable<NotificationEvent>;

    isActive$: Observable<boolean>;

    constructor(private emptySearchService: EmptySearchService) {}

    ngOnInit(): void {
        // Note: debounceTime is needed to react on debounced search text input

        this.isActive$ = this.onTableSearch$.pipe(
            debounceTime(50),
            map(notification => this.rows === 0 && notification.data.length > 0)
        );
        this.isActive$.subscribe(result => {
            this.emptySearchService.setIsActive(result);
        });
    }

    ngOnDestroy(): void {
        // implement this method so that NotificationSubscription works with AOT
    }
}
