import { Injectable, ErrorHandler } from '@angular/core';
import { ILogger, LogService } from '.';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class LoggingErrorHandler extends ErrorHandler {
    private readonly logger: ILogger;
    constructor(logService: LogService) {
        super();
        this.logger = logService.getLogger(`LoggingErrorHandler`);
    }

    handleError(err: any) {
        super.handleError(err);

        const message = err.message || 'Unknown error occurred';
        StackTrace.fromError(err)
            .then((sf: StackTrace.StackFrame[]) => this.stackSuccess(message, sf))
            .catch((fail: any) => {
                this.logger.error(message, err, fail);
            });
    }

    private stackSuccess(message: string, stackframes: StackTrace.StackFrame[]) {
        const stringifiedStack = stackframes
            .map(sf => {
                return sf.toString();
            })
            .join('\n');
        this.logger.error(message, stringifiedStack);
    }
}
