import { NgModule } from '@angular/core';
import { LoadingIndicatorReducer } from 'insightui.loading-indicator/loading-indicator.reducer';
import { STATE_REDUCER } from 'projects/insightui/src/app/state/state.module';
import { LoadingIndicatorService } from 'insightui.loading-indicator/loading-indicator.service';
import { CommonModule } from '@angular/common';
import { NgRedux } from '@angular-redux/store';
import {
    LOADING_COMPLETED,
    LOADING_ERROR,
    LOADING_STARTED
} from 'insightui.loading-indicator/loading-indicator.actions';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { LoadingSpinnerComponent } from 'insightui.loading-indicator/components/loading-spinner.component';
import { LoadingIndicatorComponent } from 'insightui.loading-indicator/components/loading-indicator.component';

@NgModule({
    imports: [CommonModule],
    declarations: [LoadingSpinnerComponent, LoadingIndicatorComponent],
    exports: [LoadingIndicatorComponent],
    providers: [
        LoadingIndicatorService,
        {
            provide: STATE_REDUCER,
            useClass: LoadingIndicatorReducer,
            multi: true
        }
    ]
})
export class LoadingIndicatorModule {
    constructor(ngRedux: NgRedux<never>) {
        // Translate the old angular1 loading notifications to redux
        AngularInjectorResolver.getRootScope().subscribe($rootScope => {
            $rootScope.$on('loadingStarted', () => ngRedux.dispatch(LOADING_STARTED()));
            $rootScope.$on('loadingComplete', () =>
                ngRedux.dispatch(LOADING_COMPLETED())
            );
            $rootScope.$on('loadingError', () => ngRedux.dispatch(LOADING_ERROR()));
        });
    }
}
