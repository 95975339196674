import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import {
    Column,
    ColumnFreezeMode,
    ColumnHeader,
    ColumnType
} from 'insightui.table/components/datatable/shared/models/datatable.models';
import { Inject, Injectable } from '@angular/core';
import { DatatableConfig, HeaderSettings } from 'insightui.table/services/table.data';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { KPIDefinition } from 'insightui.data/shared/reporting-data';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { UUID } from 'insightui.global/global.module';
import { SortingModel } from 'insightui.core/models/sorting.model';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';
import {ToastrService} from "ngx-toastr";

const KPI_DIMENSION = 'kpi';

const headerDateFormat = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'short'
});

@Injectable()
export class DatatableConfigurationService {
    @VisualizationContextBinding('parameterSettings.colOrderHeaders')
    private colOrderHeaders;

    private sideHeaderNameMap = Object.freeze({
        SG: 'Sector',
        CY: 'Category',
        PG: 'Product Group',
        BR: 'Brand',
        IT: 'Item',
        PF: '',
        FA: 'First Activity'
    });

    private topHeaderNameMap = Object.freeze({
        kpi: 'KPIs',
        channel: 'Distribution Unit',
        posType: 'POS Type',
        period: 'Period'
    });

    private logger: ILogger;

    constructor(
        private visualizationContext: VisualizationContextService,
        private pageDefinitionService: PageDefinitionService,
        private drillingService: DrillingService,
        private metadataService: MetadataService,
        @Inject(UUID) private uuid: () => string,
        private sortingModel: SortingModel,
        logService: LogService,
        private toastr: ToastrService
    ) {
        this.logger = logService.getLogger('DatatableConfigurationService');
    }

    getHeaderSettings(datatableConfig: DatatableConfig): HeaderSettings {
        const headerSettings: HeaderSettings = {
            topHeaderHorizontalDimensions: [],
            topHeaderVerticalDimensions: []
        };

        headerSettings.topHeaderHorizontalDimensions = this.getKpiList();
        headerSettings.topHeaderVerticalDimensions = datatableConfig.dimensionContext.map(
            dim => (dim === 'element' ? KPI_DIMENSION : dim)
        );

        // create column header from visualizationcontext
        if (this.colOrderHeaders) {
            const reorderHeaders = this.colOrderHeaders
                .split(',')
                .map(el => el)
                .filter(
                    header =>
                        headerSettings.topHeaderVerticalDimensions.indexOf(header) >= 0
                );
            headerSettings.topHeaderVerticalDimensions = headerSettings.topHeaderVerticalDimensions.filter(
                header => reorderHeaders.indexOf(header) === -1
            );
            headerSettings.topHeaderVerticalDimensions = reorderHeaders.concat(
                headerSettings.topHeaderVerticalDimensions
            );
        }

        this.setReorderHerdersInVisualizationContext(headerSettings);

        return headerSettings;
    }

    getKpiList(): string[] {
        return this.visualizationContext.get('kpiList');
    }

    getColumnHeaderConfiguration(
        datatableConfig: DatatableConfig,
        topHeaderVerticalDimensions: string[],
        userDefinedDimensions?: string[][]
    ): ColumnHeader[] {
        const kpiIndex = topHeaderVerticalDimensions.indexOf(KPI_DIMENSION);
        if (this.getRankKpi() && kpiIndex && userDefinedDimensions) {
            const rankKpiIndex = userDefinedDimensions[kpiIndex].indexOf(
                this.getRankKpi()
            );
            userDefinedDimensions[kpiIndex].splice(rankKpiIndex, 1);
        }
        const headerConfiguration: ColumnHeader[] = this.determineSideHeaders(
            datatableConfig,
            topHeaderVerticalDimensions
        );
        const headers = headerConfiguration.concat(
            this.determineTopHeaders(
                datatableConfig,
                topHeaderVerticalDimensions,
                {},
                userDefinedDimensions
            )
        );
        headers.forEach(currentHeader => this.collectKpi(currentHeader));

        return headers;
    }

    getColumnConfiguration(columnHeader: ColumnHeader[]): Column[] {
        const columns: Column[] = [];

        this.getColumns(columnHeader, columns);

        return columns;
    }

    getSideHeaders(): string[] {
        let sideHeaders: string[] = [];
        sideHeaders = sideHeaders.concat(this.getSideHeadersFromDrillLevel());

        if (
            this.visualizationContext.get('showFeatures') ||
            this.visualizationContext.get('showPriceClasses') ||
            this.visualizationContext.get('splitByFeatureList', []).length > 0 ||
            this.visualizationContext.get('splitByPriceClassList', []).length > 0
        ) {
            sideHeaders.push('PF');
        }

        if (this.visualizationContext.get('featureShowList', []).length > 0) {
            sideHeaders = sideHeaders.concat(
                this.visualizationContext.get('featureShowList')
            );
        }

        if (this.visualizationContext.get('showFirstActivities')) {
            sideHeaders.push('FA');
        }

        return sideHeaders;
    }

    getKpiDefinitions(datatableConfig: DatatableConfig): KPIDefinition[] {
        const currentlySupported = [
            'feature',
            'priceClass',
            'period',
            'kpi',
            'posType',
            'channel',
            'element'
        ];
        const dimensionContext = this.getDimensionContext(datatableConfig)
            .concat(this.getSideHeaders())
            .filter(dimension => currentlySupported.indexOf(dimension) >= 0);
        const kpiList = this.getKpiList();
        const kpis: KPIDefinition[] = [];

        kpiList.forEach(kpi => {
            kpis.push({ id: kpi, dimensionContext });
        });

        return kpis;
    }

    getDimensionContext(datatableConfig: DatatableConfig) {
        const page = this.pageDefinitionService.getCurrentPage();
        let dimensionContext: string[] = [];
        dimensionContext = dimensionContext.concat(datatableConfig.dimensionContext);
        if (page.splitByFeatures && !dimensionContext.find(e => e === 'feature')) {
            dimensionContext.push('feature');
        } else if (
            page.splitByPriceClasses &&
            !dimensionContext.find(e => e === 'priceClass')
        ) {
            dimensionContext.push('priceClass');
        } else {
            // sonar
        }

        if (this.drillingService.getDrillLevel().topHeaders) {
            dimensionContext = [
                ...dimensionContext,
                ...this.drillingService
                    .getDrillLevel()
                    .topHeaders.filter(e => dimensionContext.indexOf(e) < 0)
            ];
        }

        return dimensionContext;
    }

    isShowFeatureField(fieldKey: string): boolean {
        return /^FT.*$/.test(fieldKey);
    }

    getTableConfiguration() {
        const definition = this.pageDefinitionService.getCurrentPage().report.table;
        // If you need to test something locally, just modify the definition here
        return definition;
    }

    private setReorderHerdersInVisualizationContext(headerSettings: HeaderSettings) {
        const headers = [];
        let order = 1;
        headerSettings.topHeaderVerticalDimensions.forEach(dim => {
            headers.push({
                title: this.topHeaderNameMap[dim],
                dim,
                __order: order
            });
            order++;
        });
        this.visualizationContext.set('filterSettings.headerReorderSetting', {
            headers,
            applyNewValueOnly: true
        });
    }

    private getSideHeadersFromDrillLevel(): string[] {
        const drillLevel = this.drillingService.getDrillLevel();
        return drillLevel ? drillLevel.sideHeaders.slice() : [];
    }

    private getColumns(columnHeader: ColumnHeader[], columns: Column[]) {
        columnHeader.forEach(header => {
            if (header.children) {
                const width = this.getWidth(header);
                const newWidth = Math.floor(width / header.children.length);
                const widthRemaining = width % header.children.length;
                header.children.forEach((child, index) => {
                    child.width =
                        index < header.children.length - 1
                            ? newWidth
                            : newWidth + widthRemaining;
                });
                this.getColumns(header.children, columns);
            } else {
                header.field = header.field.replace('-', '');
                const column: Column = {
                    flexWidth: header.flexWidth,
                    minWidth: null, // Will be calculated by autosize service based on column content
                    field: header.field,
                    freezeMode:
                        header.type === ColumnType.SIDE_HEADER
                            ? ColumnFreezeMode.SIDE
                            : ColumnFreezeMode.MAIN,
                    cssClasses:
                        (header.kpi ? 'align-right' : 'align-left') + ' ' + header.field,
                    width: this.getWidth(header)
                };

                if (header.dimension) {
                    const kpi = this.getKpi(header.field);
                    column.dataType = kpi.dataType;
                    column.fractionalDigits = kpi.fractionalDigits;
                }

                columns.push(column);
            }
        });
    }

    private getWidth(header) {
        const width =
            header.width ||
            header.flexWidth
                .split(' ')
                .pop()
                .replace('px', '');
        return Number(width);
    }

    private getKpi(field: string) {
        const kpi = field.substr(field.lastIndexOf('_') + 1);
        return this.pageDefinitionService.getCurrentPage().getKpi(kpi);
    }

    private determineSideHeaders(
        datatableConfig: DatatableConfig,
        topHeaderDimensionIds
    ): ColumnHeader[] {
        const sideHeaders: ColumnHeader[] = [];

        const rankKpi = this.getRankKpi();
        if (rankKpi !== null) {
            const shTopHeaderVerticalDimensions = datatableConfig.dimensionContext.map(
                dim => {
                    return dim === 'element' ? KPI_DIMENSION : dim;
                }
            );

            const minWidth = this.determineHeaderGroupMinWidth(
                datatableConfig,
                shTopHeaderVerticalDimensions
            );
            sideHeaders.push(
                this.getColumnHeader(
                    datatableConfig,
                    rankKpi,
                    shTopHeaderVerticalDimensions,
                    topHeaderDimensionIds,
                    0,
                    minWidth,
                    [],
                    ColumnType.SIDE_HEADER
                )
            );
        }

        const sideHeaderKeys = this.getSideHeaders();
        sideHeaderKeys.forEach(sideHeader => {
            let name = this.sideHeaderNameMap[sideHeader];
            if (this.isShowFeatureField(sideHeader)) {
                let feature = this.metadataService.getFeatureById(sideHeader)
                if(feature === null){
                    this.toastr.error(
                        'The selected feature in the collection is no longer valid for the Product Group. Please update your collection with the correct filters.',
                        'Feature unavailable'
                    );
                }
                // null indicates outdated feature, should be corrected by user
                name = feature.title;
            }
            sideHeaders.push({
                uuid: this.uuid(),
                field: sideHeader,
                name,
                flexWidth:
                    '1 0 ' + datatableConfig.sideHeaderMinWidth.toLocaleString() + 'px',
                width: datatableConfig.sideHeaderMinWidth,
                type: ColumnType.SIDE_HEADER
            });
        });

        return sideHeaders;
    }

    private determineTopHeaders(
        datatableConfig: DatatableConfig,
        topHeaderVerticalDimensions: string[],
        topHeaderDimensionIds,
        userDefinedDimensions: string[][]
    ): ColumnHeader[] {
        const topHeaders: ColumnHeader[] = [];
        const level = 0;
        const minWidth = this.determineHeaderGroupMinWidth(
            datatableConfig,
            topHeaderVerticalDimensions
        );
        userDefinedDimensions = userDefinedDimensions || [];
        const dimensions =
            userDefinedDimensions[level] ||
            this.getDimensionsForTopHeaderLevel(topHeaderVerticalDimensions, level);

        const rankKpi = this.getRankKpi();

        dimensions.forEach(dimensionId => {
            if (dimensionId !== rankKpi) {
                topHeaders.push(
                    this.getColumnHeader(
                        datatableConfig,
                        dimensionId,
                        topHeaderVerticalDimensions,
                        topHeaderDimensionIds,
                        level,
                        minWidth,
                        userDefinedDimensions,
                        ColumnType.TOP_HEADER
                    )
                );
            }
        });

        return topHeaders;
    }

    private determineHeaderGroupMinWidth(
        datatableConfig: DatatableConfig,
        topHeaderVerticalDimensions: string[]
    ) {
        let minWidth = datatableConfig.columnMinWidth;
        let level = 1;

        topHeaderVerticalDimensions.slice(1).forEach(dimension => {
            minWidth *= (
                this.getDimensionsForTopHeaderLevel(
                    topHeaderVerticalDimensions,
                    level++
                ) || []
            ).length;
        });

        return minWidth;
    }

    private getColumnHeader(
        datatableConfig: DatatableConfig,
        dimensionId: string,
        topHeaderVerticalDimensions: string[],
        topHeaderDimensionIds,
        level: number,
        minWidth: number,
        userDefinedDimensions: string[][],
        columnType: ColumnType
    ): ColumnHeader {
        let headerName: string;
        let dimensionType: string;
        const topHeaderLevelDimension = topHeaderVerticalDimensions[level];
        const isKpiHeader = topHeaderLevelDimension === KPI_DIMENSION;

        topHeaderDimensionIds[topHeaderLevelDimension] = dimensionId;
        // TODO: Work with fully resolved data, the table shouldn't care about getting titles
        if (isKpiHeader) {
            headerName = this.pageDefinitionService
                .getCurrentPage()
                .getKpi(dimensionId, 'title');
            dimensionType = dimensionId;
        } else if (topHeaderLevelDimension === 'period') {
            headerName = this.periodToDate(dimensionId);
            dimensionType = dimensionId;
        } else {
            const dimension = this.metadataService.getDimensionById(dimensionId);
            headerName = dimension.title;
            dimensionType = dimension.type;
        }

        // uniqueId: we need a way to uniquely identify a header, otherwise the column resizing won't work
        // if more than 2 header levels exist (NEO-2605).
        const columnHeader: ColumnHeader = {
            uuid: this.uuid(),
            id: dimensionId,
            level,
            name: headerName,
            dimension: topHeaderLevelDimension,
            dimensionType,
            type: columnType
        };

        if (isKpiHeader) {
            columnHeader.kpi = dimensionId;
        }

        if (level < topHeaderVerticalDimensions.length - 1) {
            columnHeader.children = [];
            userDefinedDimensions = userDefinedDimensions || [];
            let dimensions =
                userDefinedDimensions[level + 1] ||
                this.getDimensionsForTopHeaderLevel(
                    topHeaderVerticalDimensions,
                    level + 1
                );
            if (columnType === ColumnType.TOP_HEADER) {
                const rankKpi = this.getRankKpi();
                dimensions = dimensions.filter(dim => dim !== rankKpi);
            }
            dimensions.forEach(dimensionIdValue => {
                columnHeader.children.push(
                    this.getColumnHeader(
                        datatableConfig,
                        dimensionIdValue,
                        topHeaderVerticalDimensions,
                        topHeaderDimensionIds,
                        level + 1,
                        minWidth / dimensions.length,
                        userDefinedDimensions,
                        columnType
                    )
                );
            });
        } else {
            columnHeader.field = this.determineHeaderField(
                datatableConfig,
                topHeaderDimensionIds
            );
        }

        columnHeader.width = minWidth;
        columnHeader.flexWidth = '1 0 auto';

        return columnHeader;
    }

    private determineHeaderField(
        datatableConfig: DatatableConfig,
        topHeaderDimensionIds
    ): string {
        let dimensions: string[] = [];
        datatableConfig.dimensionContext
            .filter(dim => dim !== 'element')
            .forEach(dimension => {
                dimensions.push(dimension);
            });
        dimensions.push(KPI_DIMENSION);
        dimensions = this.sortingModel.orderDimensions(dimensions);
        const field = dimensions
            .map(dimension => topHeaderDimensionIds[dimension])
            .join('_');

        return field;
    }

    private periodToDate(dimensionId: string) {
        // TODO: migrate the period service to Angular and resolve in data layer
        const year = parseInt(dimensionId.substring(0, 4), 10);
        const month = parseInt(dimensionId.substring(4, 6), 10) - 1;

        return headerDateFormat.format(new Date(year, month));
    }

    private getDimensionsForTopHeaderLevel(
        topHeaderVerticalDimensions: string[],
        level: number
    ) {
        const dimensionName = topHeaderVerticalDimensions[level];
        const dimensionValues = this.visualizationContext.get(dimensionName + 'List');

        let columnOrderByDimensionValue = this.visualizationContext.get(
            'parameterSettings.colOrderByDim.' + dimensionName,
            {}
        );
        const dimensionValuesFromColumnOrder: string[] = Object.keys(
            columnOrderByDimensionValue
        );
        if (dimensionValues.length !== dimensionValuesFromColumnOrder.length) {
            columnOrderByDimensionValue = {};
            this.visualizationContext.set(
                'parameterSettings.colOrderByDim.' + dimensionName,
                columnOrderByDimensionValue
            );
        }

        dimensionValuesFromColumnOrder.forEach(dimValue => {
            if (dimensionValues.indexOf(dimValue) >= 0) {
                dimensionValues.splice(dimensionValues.indexOf(dimValue), 1);
                dimensionValues.splice(
                    columnOrderByDimensionValue[dimValue],
                    0,
                    dimValue
                );
            }
        });

        return dimensionValues;
    }

    private getRankKpi() {
        const kpiList = this.getKpiList();
        let rankKpi = null;

        if (kpiList) {
            kpiList.forEach(key => {
                const kpi = this.getKpi(key);
                if (kpi.hasOwnProperty('calculation')) {
                    if (kpi.calculation.method === 'rank') {
                        rankKpi = key;
                    }
                }
            });
        }

        return rankKpi;
    }

    private collectKpi(header: ColumnHeader, kpi?) {
        if (kpi && !header.kpi) {
            header.kpi = kpi;
        } else if (!kpi && header.kpi) {
            kpi = header.kpi;
        }

        if (header.children && header.children.length) {
            header.children.forEach(child => this.collectKpi(child, kpi));
        }
    }
}
