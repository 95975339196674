import * as _ from 'lodash';
import { LogService } from 'insightui.core/services/logging/log.service';

const logger = new LogService().getLogger('LoadStartupDataService');

export interface LoadStartupDataService {
    loadLandingPageData(countryId: string, periodId: number): Promise<any>;
    loadReportData(docId: string, pageId: string): Promise<any>;
    loadReportDataWithFavorite(docId: string, reportSnapshotId: string): Promise<any>;
    loadReportDataWithContext(
        docId: string,
        pageId: string,
        serializedVC: any
    ): Promise<any>;
}

LoadStartupDataService.$inject = [
    'VisualizationContextService',
    'HierarchyDimensionService',
    'LoadStatusService',
    'PageDefinitionLoaderService',
    'PageDefinitionService',
    'MetadataService',
    'MetadataService2',
    '$injector',
    'ReportRestoreService'
];

/**
 *  Contains all the side effects necessary to bootstrap the interactive header. This was pulled out from the angular1 app.js file.
 */
export function LoadStartupDataService(
    // tslint:disable: variable-name
    VisualizationContextService, // AngularJS
    HierarchyDimensionService, // AngularJS
    LoadStatusService, // AngularJS
    PageDefinitionLoaderService, // downgraded
    PageDefinitionService, // downgraded
    MetadataService, // AngularJS
    MetadataService2, // downgraded
    $injector, // AngularJS
    ReportRestoreService // AngularJS
    // tslint:enable: variable-name
) {
    const self = this;
    /**
     * Initialization of all non-report pages (landing page, collections, favorite manager)
     */
    self.loadLandingPageData = async (countryId, periodId) => {
        logger.debug(`loadLandingPageData()`);

        await MetadataService.loadMetadata(countryId, periodId);

        // Load the hierarchies (for the drilling entry-point and of course some models rely on this somehow)
        await HierarchyDimensionService.init();
        await LoadStatusService.init();

        return VisualizationContextService.getVisualizationContext();
    };

    self.loadReportData = async (docId, pageId) => {
        logger.debug(`loadReportData (${docId}, ${pageId})`);

        // Load the meta data first
        // every other service in this chain does rely on the existence of some metadata,
        // therefore never try to run this in parallel with e.g. HierarchyDimensionService
        await MetadataService.loadMetadataWithId(docId);

        // Load the hierarchies (for the drilling entry-point and of course some models rely on this somehow)
        await HierarchyDimensionService.init();
        await LoadStatusService.init();
        await PageDefinitionLoaderService.loadPage(pageId);

        // use getCurrentPage, because that delivers the same page
        // wrapped inside a model with some more functionality
        const page = PageDefinitionService.getCurrentPage();

        logger.debug('set current page', page);

        // Inform the context that we have a page (some models listen to this and will start to load some other data)
        // Set the default values for the context (like elementList, kpiList etc. some models rely on this)
        VisualizationContextService.changePage(page);

        // Finally set the currency
        // TODO: some components get curreny from metadata, others from VC -> simplify
        VisualizationContextService.set(
            'currency',
            _.get(MetadataService2.getDeliveryInfo(), 'content.currency')
        );

        // somewhere deeply hidden inside this model the contextFilter gets manipulated therefore call it here
        const elementSelectionModel = $injector.get('ElementSelectionModel');
        elementSelectionModel.reset();

        // necessary hidden variables get set by initializing the ReportContext,
        // even though the ReportContext is only used for favourites....
        $injector.get('ReportContext');

        logger.debug('finished loadReportData');

        return VisualizationContextService.getVisualizationContext();
    };

    self.loadReportDataWithFavorite = async (docId, reportSnapshotId) => {
        logger.debug(`loadReportDataWithFavorite (${docId}, ${reportSnapshotId})`);

        const favorite = await ReportRestoreService.loadFavorite(reportSnapshotId);

        // load report data with pageId from favorite
        return loadReportDataWithFullContext(
            docId,
            favorite.reportSettings.visualizationContext.page.id,
            favorite
        );
    };

    self.loadReportDataWithContext = (docId, pageId, serializedVC) => {
        logger.debug(
            `LoadStartupDataService.loadReportDataWithContext (${docId}, ${pageId}, ${serializedVC})`
        );

        const restoreFullVC = _.get(serializedVC, 'restoreFullVC', false);

        delete serializedVC.restoreFullVC;

        if (restoreFullVC) {
            const restoreContext = {
                id: 'restoreFromUrl',
                reportSettings: { visualizationContext: serializedVC }
            };
            return loadReportDataWithFullContext(docId, pageId, restoreContext);
        } else {
            return loadReportDataWithSerializedContext(docId, pageId, serializedVC);
        }
    };

    async function loadReportDataWithFullContext(docId, pageId, restoreContext) {
        logger.debug(
            `LoadStartupDataService.loadReportDataWithFullContext (${docId}, ${pageId}, ${restoreContext})`
        );

        await self.loadReportData(docId, pageId);

        try {
            // this service magically merges the current visualization context with the given one
            const result = await ReportRestoreService.executeRestore(
                restoreContext,
                docId
            );

            logger.debug('Restore went fine', result);

            if (result.newDocId && result.newDocId > 0) {
                return Promise.reject(result);
            }

            return VisualizationContextService.getVisualizationContext();
        } catch (e) {
            logger.debug('Restore went wrong.', e);
        }
    }

    async function loadReportDataWithSerializedContext(docId, pageId, serializedVC) {
        logger.debug(
            `LoadStartupDataService.loadReportDataWithSerializedContext (${docId}, ${pageId}, ${serializedVC})`
        );

        await self.loadReportData(docId, pageId);

        const visualizationContext = VisualizationContextService.getVisualizationContext();
        _.assign(visualizationContext, serializedVC);

        return visualizationContext;
    }
}
