import { NgModule } from '@angular/core';
import { TablePluginRegistry } from 'insightui.table/services/table-plugin-registry.service';
import { TablePluginFactory } from 'insightui.table/components/plugins/table-plugin-factory.service';
import { PerformanceIndicatorHeaderComponent } from 'insightui.table/components/plugins/performanceIndicator/performance-indicator-header.component';
import { CommonModule } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

/**
 * Feature Module for the table plugins.
 */
@NgModule({
    imports: [CommonModule, BsDropdownModule.forRoot()],
    providers: [TablePluginRegistry, TablePluginFactory],
    declarations: [PerformanceIndicatorHeaderComponent]
})
export class TablePluginModule {}
