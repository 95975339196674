<div class="search-box">
    <input
        type="text"
        class="search-input"
        [(ngModel)]="searchText"
        (ngModelChange)="searchTextChanged.emit(searchText)"
        [placeholder]="placeholder"
        maxlength="100"
        data-uitest="search-text"
        (click)="$event.stopPropagation()"
    />
</div>
