import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
    name: 'gfkNumberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private localeId) {}

    transform(value: number): string | undefined {
        if (value == null) {
            return value as undefined;
        }

        const absValue = Math.abs(value);

        if (absValue < Math.pow(10, 3)) {
            return '' + this.roundExponential(value, 0);
        }
        if (absValue < Math.pow(10, 6)) {
            return this.roundExponential(value, 3) + 'k';
        }
        if (absValue < Math.pow(10, 9)) {
            return this.roundExponential(value, 6) + 'M';
        }

        return this.roundExponential(value, 9) + 'B';
    }

    private roundExponential(value: number, exp: number) {
        return formatNumber(value / Math.pow(10, exp), this.localeId, '1.0-1');
    }
}
