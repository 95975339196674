import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from 'insightui.global/global.module';
import { HtmlElementUnionService } from 'insightui.export/powerpoint/services/html-element-union.service';
import { PowerpointExportSheet } from 'insightui.export/types';
import { HtmlString } from 'insightui.export/types';
import { HeadElementRetrieverService } from 'insightui.export/powerpoint/services/head-element-retriever.service';
import { ExportHeadlineService } from 'insightui.export/services/export-headline.service';
import * as _ from 'lodash';
import { PowerpointInfoRowService } from './powerpoint-info-row.service';

const EXPORT_RECT_SELECTOR = '.js-export-rect';
@Injectable()
export class ChartToSheetService {
    constructor(
        private htmlElementUnionService: HtmlElementUnionService,
        private headElementRetrieverService: HeadElementRetrieverService,
        private exportHeadlineService: ExportHeadlineService,
        private powerpointInfoRowService: PowerpointInfoRowService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    getSheet(chartElement: HTMLElement): PowerpointExportSheet {
        const boundingBox = this.getBoundingBox();
        return {
            html: this.getHtml(chartElement),
            headline: this.exportHeadlineService.getReportHeadline(),
            subheadline: this.exportHeadlineService.getReportSubheadline(),
            width: boundingBox.width,
            height:
                boundingBox.height +
                this.powerpointInfoRowService.getFooterHeight(chartElement)
        };
    }

    getFixedSizeSheet(
        chartElement: HTMLElement,
        width: number,
        height: number
    ): PowerpointExportSheet {
        return {
            html: this.getHtml(chartElement),
            headline: this.exportHeadlineService.getReportHeadline(),
            subheadline: this.exportHeadlineService.getReportSubheadline(),
            width,
            height: height + this.powerpointInfoRowService.getFooterHeight(chartElement)
        };
    }

    getFixedWidthSheet(chartElement: HTMLElement, width: number): PowerpointExportSheet {
        return this.getFixedSizeSheet(chartElement, width, this.getBoundingBox().height);
    }

    getFixedHeightSheet(
        chartElement: HTMLElement,
        height: number
    ): PowerpointExportSheet {
        return this.getFixedSizeSheet(chartElement, this.getBoundingBox().width, height);
    }

    private getHtml(chartElement: HTMLElement): HtmlString {
        const head = this.headElementRetrieverService.getHeadElement();

        return `<html class="export-ppt">
                    <head>${head.innerHTML}</head>
                    <body>${chartElement.outerHTML}</body>
                </html>`;
    }

    private getBoundingBox(): ClientRect {
        const exportRectElements = this.document.querySelectorAll(EXPORT_RECT_SELECTOR);
        return this.htmlElementUnionService.boundingBox(
            Array.from(exportRectElements || [])
        );
    }
}
