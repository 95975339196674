import actionCreatorFactory, { ActionCreator } from 'typescript-fsa';
import {
    ErrorInIFrame,
    ReportSheetsFromIFrame
} from 'insightui.export-collection/export-collection.types';
import { AnyAction } from 'redux';
import { PowerpointExportSheets } from 'insightui.export/types';
import { ListSelection } from 'insightui.collectionmanager/models/list-selection';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';

const factory = actionCreatorFactory('EXPORT_COLLECTION');

export const EXPORT_COLLECTION_START: ActionCreator<{
    reports: ListSelection<ReportSnapshotDto>;
    name: string;
}> = factory('EXPORT_COLLECTION_START');
export const EXPORT_COLLECTION_CONFIRM: ActionCreator<void> = factory(
    'EXPORT_COLLECTION_CONFIRM'
);
export const EXPORT_COLLECTION_CANCEL: ActionCreator<void> = factory(
    'EXPORT_COLLECTION_CANCEL'
);
export const EXPORT_COLLECTION_ERROR: ActionCreator<void> = factory(
    'EXPORT_COLLECTION_ERROR'
);
export const EXPORT_COLLECTION_RETRY: ActionCreator<void> = factory(
    'EXPORT_COLLECTION_RETRY'
);
export const EXPORT_COLLECTION_DONE: ActionCreator<PowerpointExportSheets> = factory(
    'EXPORT_COLLECTION_DONE'
);
export const EXPORT_COLLECTION_PPT_FILE_CREATED: ActionCreator<void> = factory(
    'EXPORT_COLLECTION_PPT_FILE_CREATED'
);
export const EXPORT_COLLECTION_SHEETS_FROM_IFRAME: ActionCreator<
    ReportSheetsFromIFrame
> = factory('EXPORT_COLLECTION_SHEETS_FROM_IFRAME');
export const EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR: ActionCreator<
    ErrorInIFrame
> = factory('EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR');
export const EXPORT_COLLECTION_POST_TO_PARENT: ActionCreator<AnyAction> = factory(
    'EXPORT_COLLECTION_POST_TO_PARENT'
);
