import {
    AfterViewInit,
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    KeyValueDiffers,
    OnInit,
    Optional,
    QueryList,
    ViewChildren
} from '@angular/core';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { DatatableHeaderComponent } from 'insightui.table/components/datatable/header/header.component';
import { FilteredTotalService } from 'insightui.table/services/filtered-total.service';
import { fromEvent as observableFromEvent } from 'rxjs';
import {
    Column,
    ColumnHeader,
    ColumnPane,
    ColumnPaneWidths,
    DataRow,
    ScrollPos
} from './shared/models/datatable.models';
import { IDictionary } from './shared/models/generic.models';
import { AutosizeUtilService } from './shared/services/autosize-util.service';
import { ColumnUtilService } from './shared/services/column-util.service';
import { HeaderUtilService } from './shared/services/header-util.service';
import { VisualizationContextParameterSettingsModel } from './shared/services/visualizationContext/parameter-settings.model';

const SCROLLER_HEIGHT = 20;

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datatable',
    templateUrl: 'datatable.component.html'
})
export class DatatableComponent implements OnInit, AfterViewInit, DoCheck {
    element: HTMLElement;
    columnsByPane: ColumnPane[];
    headerHeight = 0;
    bodyHeight: number;
    bodyHeightDataBody: number;
    rowsDiffer;
    columnsDiffer;
    headersOrder: IDictionary<number>;
    offsetX: { [key: string]: number } = {
        side: 0,
        main: 0
    };
    mainWidth: number;
    columnPaneWidths: ColumnPaneWidths;
    viewportHeight: number;

    @Input() rows: DataRow[];
    @Input() totalRow: DataRow;
    @Input() subTotalRow: DataRow;
    @Input() columns: Column[];
    @Input() headers: ColumnHeader[];

    /**
     * The row height; which is necessary to calculate the height for the lazy rendering.
     */
    @Input() rowHeight = 30;

    /**
     * The minimum header row height in pixels.
     * Pass a 0 for no header
     */
    @Input() headerRowHeight = 30;

    /**
     * The minimum footer height in pixels.
     * Pass 0 for no footer
     */
    @Input() footerHeight = 0;

    /**
     * Show the linear loading bar.
     * Default value: `false`
     */
    @Input() loadingIndicator = false;

    /**
     * Reference to the header components for manually invoking functions on the header.
     */
    @ViewChildren(DatatableHeaderComponent)
    headerComponents: QueryList<DatatableHeaderComponent>;

    private window: Window;
    private mainHeaderComponent: DatatableHeaderComponent;

    /**
     * CSS class applied if the header height if fixed height.
     */
    @HostBinding('class.fixed-header')
    get isFixedHeader(): boolean {
        const headerHeight: number | string = this.headerRowHeight;
        return typeof headerHeight === 'string'
            ? (headerHeight as string) !== 'auto'
            : true;
    }

    constructor(
        private columnUtilService: ColumnUtilService,
        private headerUtilService: HeaderUtilService,
        element: ElementRef,
        differs: KeyValueDiffers,
        private autoSize: AutosizeUtilService,
        protected table: AuthoringDatatableComponent,
        private filteredTotalService: FilteredTotalService,
        @Optional() @Inject('window') theWindow: Window
    ) {
        this.window = theWindow || window;
        // get ref to elm for measuring
        this.element = element.nativeElement;
        this.rowsDiffer = differs.find({}).create();
        this.columnsDiffer = differs.find({}).create();
    }

    trackByColumnsPaneFn(index: number, pane: ColumnPane) {
        // Note: construct a key which is unique. Maybe there's a simpler variant...
        return pane.pos + pane.columns.map(col => `${col.id}_${col.field}`).join(';');
    }

    ngOnInit(): void {
        // need to call this immediatly to size
        // if the table is hidden the visibility
        // listener will invoke this itself upon show
        observableFromEvent(window, 'resize').subscribe(e => {
            this.recalculate();
        });
        this.recalculate();
    }

    ngAfterViewInit(): void {
        // this has to be done to prevent the change detection
        // tree from freaking out because we are readjusting
        setTimeout(() => {
            this.mainHeaderComponent = this.headerComponents.find(header => {
                return header.pane.pos === 'main';
            });

            this.recalculate();
        });
    }

    /**
     * Lifecycle hook that is called when Angular dirty checks a directive.
     */
    ngDoCheck(): void {
        if (this.rowsDiffer.diff(this.rows)) {
            this.recalculate();
        }

        if (this.columnsDiffer.diff(this.columns)) {
            this.updateColumns();
        }
    }

    /**
     * Recalc's the sizes of the grid.
     * Updated automatically on changes to:
     *  - Columns
     *  - Rows
     *  - Paging related
     *
     * Also can be manually invoked or upon window resize.
     */
    recalculate(): void {
        const width = this.element.getBoundingClientRect().width;
        const sideWidth = this.columnPaneWidths ? this.columnPaneWidths.side : 0;

        this.mainWidth = Math.floor(width - sideWidth);
        let availableHeight =
            this.window.innerHeight -
            this.element.getBoundingClientRect().top -
            SCROLLER_HEIGHT;
        let viewportHeight = availableHeight;

        if (this.mainHeaderComponent) {
            availableHeight = availableHeight - this.headerHeight;
            viewportHeight = viewportHeight - this.headerHeight;
        }
        if (this.totalRow) {
            availableHeight = availableHeight - this.rowHeight;
        }
        if (this.subTotalRow) {
            availableHeight = availableHeight - this.rowHeight;
        }
        if (this.footerHeight) {
            availableHeight = availableHeight - this.footerHeight;
            viewportHeight = viewportHeight - this.footerHeight;
        }
        // filter bar height: 40px
        // totalLine additional height when showing values in brackets: 20px
        if (this.filteredTotalService.showFilteredTotal) {
            availableHeight = availableHeight - 20;
        }

        this.bodyHeight = Math.min(availableHeight, this.rows.length * this.rowHeight);
        this.bodyHeightDataBody = availableHeight;

        this.viewportHeight = viewportHeight + SCROLLER_HEIGHT;
    }

    /**
     * The body triggered a scroll event.
     */
    onBodyScroll(event: ScrollPos): void {
        this.offsetX[event.pane.pos] = event.offsetX;
    }

    public stylesByPane(pane: ColumnPane): object {
        const offsetX = this.offsetX[pane.pos] || 0;

        const styles = {
            marginLeft: '0px'
        };

        if (pane.pos === 'main') {
            styles.marginLeft = -1 * offsetX + 'px';
        }

        return styles;
    }

    onHeaderReordered(newHeadersSetting: any) {
        this.headersOrder = this.headerUtilService.getHeadersOrderBy(
            newHeadersSetting.columnHeaderByPane,
            this.columns.map(col => col.field)
        );
        this.headerUtilService.reorderHeaderBy(
            newHeadersSetting.newHeaders,
            newHeadersSetting.columnHeaderByPane,
            newHeadersSetting.pane.pos
        );

        if (newHeadersSetting.overwriteVC) {
            VisualizationContextParameterSettingsModel.colOrder = this.headerUtilService.getHeaderFirstLevelOrder(
                newHeadersSetting.newHeaders
            );
            VisualizationContextParameterSettingsModel.colOrderByDim = this.headerUtilService.getHeaderOrderByDimension(
                newHeadersSetting.newHeaders
            );

            const reorderedColumns = [];
            this.reorderColumns(newHeadersSetting.newHeaders, reorderedColumns);
            this.columns = reorderedColumns;
        }
        this.headers = newHeadersSetting.newHeaders;
        this.onColumnResizeEnd();
    }

    onHeaderChanged(event) {
        if (this.headerHeight !== event) {
            this.headerHeight = event;
            this.recalculate();
        }
    }

    /**
     * Triggered by header.component on header resize finish
     */
    onColumnResized(event) {
        this.columnPaneWidths = this.headerUtilService.headerPaneWidths(this.headers);
    }

    /**
     *  Does resize the table to a minimum size
     */
    doAutoResize() {
        this.autoSize.autoResizeColumns(this.columns, this.rows.concat(this.totalRow));
        this.headerUtilService.updateHeaderWidth(this.headers, this.columns);
        this.recalculate();
    }

    resizeColumnWidthOfSizePanelWhenOutfit() {
        let totalWidth = this.headerUtilService.getTotalWidthByColumns(
            this.columns,
            'side'
        );
        const availableWindowWidth = window.innerWidth - 180;
        if (totalWidth > availableWindowWidth) {
            let reduceBy = 1.4;
            do {
                this.columns.forEach(col => {
                    if (col.freezeMode === 'side') {
                        col.width = Math.ceil(col.width / reduceBy);
                    }
                });
                totalWidth = this.headerUtilService.getTotalWidthByColumns(
                    this.columns,
                    'side'
                );
                reduceBy += 0.1;
            } while (totalWidth > availableWindowWidth);
            this.headerUtilService.updateHeaderWidth(this.headers, this.columns);
        }
    }

    /**
     * Updates column width on resize start
     */
    onColumnResizeStart() {}

    /**
     * Updates column width on resize end
     */
    onColumnResizeEnd() {
        // this.resizeColumnWidthOfSizePanelWhenOutfit();
        this.columnPaneWidths = this.headerUtilService.headerPaneWidths(this.headers);
        this.columnUtilService.resizeColumnsToHeader(this.headers, this.columns);
        // this.headerUtilService.updateHeaderWidth(this.headers, this.columns);
        this.recalculate();

        // used to trigger scrollbar update
        // TODO: With proper state handling this needn't to be necessary
        setTimeout(() => {
            const evt = document.createEvent('UIEvents');
            evt.initEvent('resize', true, false);
            this.element.dispatchEvent(evt);
        }, 100);
    }

    private reorderColumns(columnHeaders, reorderedColumns) {
        columnHeaders.forEach(columnHeader => {
            columnHeader.field = columnHeader.field
                ? columnHeader.field.replace('-', '')
                : columnHeader.field;
            if (columnHeader.children) {
                this.reorderColumns(columnHeader.children, reorderedColumns);
            } else {
                reorderedColumns.push(
                    this.columns.find(column => column.field === columnHeader.field)
                );
            }
        });
    }

    private updateColumns() {
        this.columnUtilService.setColumnDefaults(this.columns);
        this.columnsByPane = this.columnUtilService.columnPaneList(this.columns);
        this.columnPaneWidths = this.columnUtilService.columnPaneWidths(this.columns);

        this.doAutoResize();
    }
}
