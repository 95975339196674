import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PowerpointExport, PowerpointExportSheets } from 'insightui.export/types';
import { TableToSheetService } from 'insightui.export/powerpoint/tables/table-to-sheet.service';
import { TableElementService } from 'insightui.export/powerpoint/tables/table-element.service';

@Injectable()
export class TableReport implements PowerpointExport {
    constructor(
        private tableToSheetService: TableToSheetService,
        private tableElementService: TableElementService
    ) {}

    getPowerpointExportSheets(): Observable<PowerpointExportSheets> {
        let tableReportElement = this.tableElementService.getCleanedUpTableElement();
        return of([this.tableToSheetService.getSheet(tableReportElement)]);
    }
}
