import { takeWhile } from 'rxjs/operators';
import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { EventEmitter, Injector } from '@angular/core';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

const ROW_HOVER_HIGHLIGHT = 'row-hover-highlight';

function setClassByAction(action, className, elements) {
    elements.forEach(function(element) {
        if (element) {
            element.classList[action](className);
        }
    });
}

export class RowHoverPlugin extends TableBodyPlugin {
    name = 'RowHoverPlugin';

    onRowHoverEnabled$ = new EventEmitter<Object>();

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions
    ) {
        super(injector, configuration);
    }

    select(cell): boolean {
        return (
            cell.row &&
            (!cell.row.TG || cell.row.TG === 'Others' || cell.row.TG === 'missing')
        );
    }

    protected doEnable(cell: Cell) {
        const rootEl = cell.element ? cell.element.nativeElement : null
        const rootParent = this.getRow(cell);
        if (!rootParent || this.isActive(rootParent)) {
            return;
        }

        rootEl.rowHoverSubscription = this.onRowHoverEnabled$
            .pipe(takeWhile(() => document.body.contains(rootEl)))
            .subscribe((relatedRow: any) => {
                // Set relatedRowElement when elements (from main and side) have the cell.row
                if (
                    relatedRow.cell.column.freezeMode !== cell.column.freezeMode &&
                    cell.row === relatedRow.cell.row
                ) {
                    rootEl.relatedRowElement = relatedRow.parent;
                    relatedRow.element.relatedRowElement = rootParent;
                    rootEl.rowHoverSubscription.unsubscribe();
                    relatedRow.element.rowHoverSubscription.unsubscribe();
                }
            });

        rootParent.mouseOverListener = () => {
            if (!rootEl.relatedRowElement) {
                this.onRowHoverEnabled$.emit({
                    element: rootEl,
                    parent: rootParent,
                    cell: cell
                });
            }
            setClassByAction('add', ROW_HOVER_HIGHLIGHT, [
                rootParent,
                rootEl.relatedRowElement
            ]);
        };
        rootParent.mouseOutListener = function() {
            setClassByAction('remove', ROW_HOVER_HIGHLIGHT, [
                rootParent,
                rootEl.relatedRowElement
            ]);
        };
        rootParent.addEventListener('mouseenter', rootParent.mouseOverListener);
        rootParent.addEventListener('mouseleave', rootParent.mouseOutListener);

        this.markAsActive(rootParent);
    }

    protected doDisable(cell: Cell) {
        // nothing to do
    }

    extendExport(exportData) {
        return exportData;
    }
}
