export enum NotificationType {
    ALL,
    HeaderOrderChanged,
    DrillTargetChanged,
    ProductModel,
    PriceClassModel,
    FeatureModel,
    PosTypeModel,
    AutosizeAllColumns,
    ResizeColumns,
    SplitByChanged,
    DistributionUnitModel,
    TopBottomModel,
    SettingsFilterChanged,
    ReportContextChanged,
    SortingChanged,
    LayoutUpdate,
    TableSearch
}

export const NotificationNamespace = {
    HeaderReorder: 'HeaderReorderNotificationService',
    Filter: 'FilterNotificationService',
    Drilling: 'DrillingNotificationService',
    SplitBy: 'SplitByNotificationService',
    Settings: 'SettingsNotificationService',
    AutosizeAll: 'AutosizeAllNotificationService',
    Sorting: 'SortingResult',
    HeaderRemoved: 'HeaderRemovedNotificationService',
    DistributionUnit: 'DistributionUnitNotificationService'
};

export const Ng1NotificationServicesMap = {
    HeaderReorderNotificationService: [NotificationType.HeaderOrderChanged],
    FilterNotificationService: [
        NotificationType.ProductModel,
        NotificationType.PriceClassModel,
        NotificationType.FeatureModel,
        NotificationType.PosTypeModel,
        NotificationType.DistributionUnitModel,
        NotificationType.TopBottomModel
    ],
    ReportContextNotificationService: [NotificationType.ReportContextChanged],
    DrillingNotificationService: [NotificationType.DrillTargetChanged],
    AutosizeAllNotificationService: [
        NotificationType.AutosizeAllColumns,
        NotificationType.ResizeColumns
    ],
    SplitByNotificationService: [NotificationType.SplitByChanged],
    SettingsNotificationService: [NotificationType.SettingsFilterChanged]
};
