<gfk-modal-header [title]="headline"> </gfk-modal-header>
<form [formGroup]="nameForm" (ngSubmit)="submitDialog()">
    <div>
        <div class="form-group collection-edit">
            <label class="control-label" for="newReportName">Report name:</label>
            <input
                type="text"
                id="newReportName"
                formControlName="reportName"
                #reportNameRef
                class="form-control collection-edit__input"
                placeholder="Please enter a name for the report"
                [maxlength]="maxCollectionReportNameLength"
                [ngClass]="{
                    'custom-input__has-error': getErrorMessage('reportName')
                }"
            />
            <small id="reportNameErrorMessage" class="collection-edit__error-message">
                {{ getErrorMessage('reportName') }}</small
            >
            <label class="control-label" for="newCollectionName">Collection name:</label>
            <input
                type="text"
                id="newCollectionName"
                formControlName="collectionName"
                class="form-control collection-edit__input"
                placeholder="Please enter a name for the collection"
                [maxlength]="maxCollectionNameLength"
                [attr.disabled]="collectionNameReadonly ? true : null"
                [ngClass]="{
                    'custom-input__has-error': getErrorMessage('collectionName')
                }"
            />
            <small id="collectionNameErrorMessage" class="collection-edit__error-message">
                {{ getErrorMessage('collectionName') }}</small
            >
        </div>
    </div>
    <gfk-modal-footer>
        <gfk-modal-button
            id="__testCaseCancelButton"
            buttonType="CANCEL"
            (click)="cancelDialog()"
        >
            Cancel
        </gfk-modal-button>
        <gfk-modal-button
            id="__testCaseSubmitButton"
            buttonType="SUBMIT"
            [disabled]="nameForm.invalid"
        >
            Save
        </gfk-modal-button>
    </gfk-modal-footer>
</form>
