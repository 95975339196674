import { AppStateMiddleware } from 'projects/insightui/src/app/state/state.types';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { Middleware } from 'redux';
import { EXPORT_COLLECTION_POST_TO_PARENT } from 'insightui.export-collection/export-collection.actions';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'insightui.global/global.module';

@Injectable({
    providedIn: 'root'
})
export class ReportInIFramePostMessageMiddleware implements AppStateMiddleware {
    constructor(@Inject(WINDOW) private window: Window) {}

    getMiddleware(): Middleware<{}, AppRootState> {
        return api => next => action => {
            if (EXPORT_COLLECTION_POST_TO_PARENT.match(action)) {
                api.dispatch(action.payload);
                this.window.parent.postMessage(
                    action.payload,
                    this.window.location.origin
                );
            } else {
                return next(action);
            }
        };
    }
}
