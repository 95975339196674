import { Component, ElementRef, HostListener, Inject, Input } from '@angular/core';

import { WINDOW } from 'insightui.global/global.module';

export interface Indicator {
    isNeutral: boolean;
    isPositive: boolean;
    targetChannel: string;
    baseChannel: string;
    rowLabel: string;
    gap: string;
}

@Component({
    selector: 'gfk-performance-indicator',
    templateUrl: './performance-indicator.component.html',
    styleUrls: ['./performance-indicator.component.scss']
})
export class PerformanceIndicatorComponent {
    @Input()
    performanceIndicator: Indicator;

    invertToolTipLeftPosition = false;
    invertToolTipTopPosition = false;

    constructor(@Inject(WINDOW) private window: Window, private element: ElementRef) {}

    private isToolTipOutSideOfViewPortWidth() {
        const domRect: DOMRect = this.element.nativeElement.getBoundingClientRect();

        return this.window.innerWidth <= domRect.x + 15 + 350;
    }

    private isToolTipOutSideOfViewPortHeight() {
        const domRect: DOMRect = this.element.nativeElement.getBoundingClientRect();

        return this.window.innerHeight <= domRect.y + 15 + 150;
    }

    @HostListener('mouseenter')
    onMouseEnter() {
        this.invertToolTipLeftPosition = this.isToolTipOutSideOfViewPortWidth();
        this.invertToolTipTopPosition = this.isToolTipOutSideOfViewPortHeight();
    }
}
