import { Injectable } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Can provide path and query parameters from all urls as observables (and also sync access),
 * regardless of which component is currently displayed.
 *
 */
@Injectable({ providedIn: 'root' })
export class NavigationService {
    private paramState: { [key: string]: BehaviorSubject<any> } = {};

    constructor(private router: Router) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => {
                let route = this.router.routerState.snapshot.root;
                do {
                    const params = route.params;

                    const keys = Object.keys(params);
                    if (keys.length > 0) {
                        keys.forEach(key => {
                            const val = params[key];
                            if (this.paramState[key]) {
                                this.paramState[key].next(val);
                            } else {
                                this.paramState[key] = new BehaviorSubject(val);
                            }
                        });
                    }
                    route = route.firstChild;
                } while (route);
            });
    }

    getParamAsObservable(key: string): Observable<any> {
        if (!this.paramState[key]) {
            this.paramState[key] = new BehaviorSubject(undefined);
        }
        return this.paramState[key];
    }

    getParam(key: string): any {
        if (!this.paramState[key]) {
            this.paramState[key] = new BehaviorSubject(undefined);
        }
        return this.paramState[key].value;
    }

    currentRouteStartsWith(path: string): boolean {
        const url = this.router.routerState.snapshot.url;
        return url.startsWith(path);
    }

    getQueryParam(paramName: string): any {
        return this.router.routerState.snapshot.root.queryParams[paramName];
    }

    getQueryParamAsObservable(paramName: string): Observable<any> {
        return this.router.routerState.root.queryParams.pipe(map(p => p[paramName]));
    }
}
