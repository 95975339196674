import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedNotificationDialogComponent } from '../feed-notification-dialog/feed-notification-dialog.component';

@Component({
    selector: 'gfk-feed-notification-button',
    templateUrl: './feed-notification-button.component.html',
    styleUrls: ['./feed-notification-button.component.scss']
})
export class FeedNotificationButtonComponent {
    private readonly MODAL_DIALOG_WIDTH = '600px';

    constructor(private matDialog: MatDialog) {}

    showModal() {
        const dialogRef = this.matDialog.open(FeedNotificationDialogComponent, {
            data: {},
            width: this.MODAL_DIALOG_WIDTH,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe();
    }
}
