import { Injectable } from '@angular/core';
import { DrillTarget } from 'insightui.data/query/queryservice/query-service-request.interface';
import { FairShareHierarchies } from 'insightui.fair-share/fair-share.types';
import { ReportingDimensionHierarchy } from 'insightui.report-filter/report-filter.types';
import { HumanReadableLabel } from 'projects/insightui/src/app/state/state.types';

@Injectable({
    providedIn: 'root'
})
export class FairShareHierarchySelector {
    getHierarchy(
        drillTarget: DrillTarget,
        hierarchies: FairShareHierarchies
    ): ReportingDimensionHierarchy {
        if (!drillTarget || !hierarchies) {
            return null;
        }
        return hierarchies[drillTarget.dimensionId];
    }

    getParentHierarchy(
        drillTarget: DrillTarget,
        hierarchies: FairShareHierarchies
    ): ReportingDimensionHierarchy {
        const hierarchy = this.getHierarchy(drillTarget, hierarchies);
        return hierarchies[hierarchy.parentId];
    }

    hierarchyName(hierarchy: ReportingDimensionHierarchy): HumanReadableLabel | null {
        return hierarchy ? hierarchy.title : null;
    }
}
