<div class="spinner">
    <input
        type="text"
        onpaste="return false;"
        class="ui-setting-textbox-number uitest-spinner-input"
        [value]="displayRawValue ? (displayValue | number: '1.2-2') : displayValue"
        (focus)="displayRawValue = true"
        (change)="onValueEntered($event)"
        (blur)="displayRawValue = false"
        [min]="min"
        [max]="max"
    />
    <div class="add-on">
        <button (mousedown)="startIncrease()">
            <em class="icon-spinner icon-sort-up uitest-spinner-up"></em>
        </button>
        <button (mousedown)="startDecrease()">
            <em class="icon-spinner icon-sort-down uitest-spinner-down"></em>
        </button>
    </div>
</div>
