import { Inject, Injectable } from '@angular/core';
import {
    DOCUMENT,
    INSIGHT_CONFIG,
    InsightConfig
} from 'insightui.global/global.module';

@Injectable()
export class HeadElementRetrieverService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig
    ) {}

    getHeadElement(): HTMLHeadElement {
        const head = this.document
            .querySelector('head')
            .cloneNode(true) as HTMLHeadElement;
        const baseHref = this.insightConfig.services.INSIGHTUI.url;
        const existingBase = head.querySelector('base');

        if (existingBase) {
            existingBase.href = baseHref;
        } else {
            const baseElement = this.document.createElement('base');
            baseElement.href = baseHref;
            head.insertBefore(baseElement, head.firstChild);
        }

        Array.from(head.querySelectorAll('script')).forEach(script =>
            script.parentNode.removeChild(script)
        );

        return head;
    }
}
