import { Injectable } from '@angular/core';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CollectionReportService {
    constructor(private revisionService: RevisionService, private router: Router) {}

    getReportLink(report: ReportSnapshotDto): Observable<string> {
        const currentQueryParams = this.router.routerState.snapshot.root.queryParams;

        return this.revisionService.getDocIdOfCurrentPeriod$(report.countryCode).pipe(
            map(docId =>
                this.router.createUrlTree(['/favorite', report.id], {
                    queryParams: {
                        ...currentQueryParams,
                        collectionId: report.parentCollectionId,
                        docId
                    }
                })
            ),
            map(urlTree => this.router.serializeUrl(urlTree))
        );
    }
}
