import { NgModule } from '@angular/core';
import { FairShareViewComponent } from 'insightui.fair-share/fair-share-view/fair-share-view.component';
import {
    STATE_EPIC,
    STATE_MIDDLEWARE,
    STATE_REDUCER
} from 'projects/insightui/src/app/state/state.module';
import { FairShareReducer } from 'insightui.fair-share/fair-share.reducer';
import { FairShareEpic } from 'insightui.fair-share/fair-share.epic';
import { CommonModule } from '@angular/common';
import { FairShareDataService } from 'insightui.fair-share/fair-share-data.service';
import { FairShareLegendComponent } from 'insightui.fair-share/fair-share-legend/fair-share-legend.component';
import { FairShareBarChartComponent } from 'insightui.fair-share/fair-share-bar-chart/fair-share-bar-chart.component';
import { FairShareReportTableComponent } from 'insightui.fair-share/fair-share-report/fair-share-report.component';
import { FairShareSelector } from 'insightui.fair-share/fair-share.selector';
import { NumberFormatModule } from 'insightui.number-format/number-format.module';
import { ErrorModule } from 'insightui.error/error.module';
import { FairShareComparisonBarChartComponent } from 'insightui.fair-share/fair-share-comparison-bar-chart/fair-share-comparison-bar-chart.component';
import { FairShareGrowthValueComponent } from 'insightui.fair-share/fair-share-growth-value/fair-share-growth-value.component';
import { FairShareGapValueHoverComponent } from 'insightui.fair-share/fair-share-gap-value-hover/fair-share-gap-value-hover.component';
import { FairShareGrowthRateHoverComponent } from 'insightui.fair-share/fair-share-gap-value-hover/fair-share-growth-rate-hover.component';
import { FairShareDrillButtonComponent } from 'insightui.fair-share/fair-share-drill-button/fair-share-drill-button.component';
import { DeprecatedDrillingEpic } from 'insightui.fair-share/deprecated/deprecated-drilling.epic';
import { DeprecatedDrillingMiddleware } from 'insightui.fair-share/deprecated/deprecated-drilling.middleware';
import { DropDownModule } from 'insightui.common-components/drop-down';
import { FairShareInfoboxComponent } from './fair-share-infobox/fair-share-infobox.component';

@NgModule({
    imports: [CommonModule, NumberFormatModule, ErrorModule, DropDownModule],
    declarations: [
        FairShareViewComponent,
        FairShareLegendComponent,
        FairShareBarChartComponent,
        FairShareReportTableComponent,
        FairShareComparisonBarChartComponent,
        FairShareGrowthValueComponent,
        FairShareGapValueHoverComponent,
        FairShareGrowthRateHoverComponent,
        FairShareDrillButtonComponent,
        FairShareInfoboxComponent
    ],
    exports: [FairShareViewComponent],
    providers: [
        FairShareDataService,
        FairShareSelector,
        { provide: STATE_REDUCER, useClass: FairShareReducer, multi: true },
        { provide: STATE_EPIC, useClass: FairShareEpic, multi: true },
        { provide: STATE_EPIC, useClass: DeprecatedDrillingEpic, multi: true },
        {
            provide: STATE_MIDDLEWARE,
            useClass: DeprecatedDrillingMiddleware,
            multi: true
        }
    ]
})
export class FairShareModule {}
