import actionCreatorFactory, { ActionCreator } from 'typescript-fsa';
import { DeliveryId } from 'projects/insightui/src/app/state/state.types';
import { FairShareHierarchies } from 'insightui.fair-share/fair-share.types';

const factory = actionCreatorFactory('REPORT_FILTER');

export const FILTER_INIT: ActionCreator<any> = factory('FILTER_INIT');
export const FILTER_CHANGED: ActionCreator<any> = factory('FILTER_CHANGED');
export const FILTER_METADATA_LOADED: ActionCreator<any> = factory(
    'FILTER_METADATA_LOADED'
);
export const FILTER_HIERARCHY_DIMENSIONS_LOADED: ActionCreator<
    FairShareHierarchies
> = factory('FILTER_HIERARCHY_DIMENSIONS_LOADED');
export const FILTER_REPORT_ACTIVATED: ActionCreator<{
    reportName: string;
    deliveryId: DeliveryId;
}> = factory('FILTER_REPORT_ACTIVATED');
