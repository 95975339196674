import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { CollectionManagerHandler } from './state/collection-manager.handler';
import { CollectionManagerState } from './state/collection-manager.state';
import { CollectionManagerShortcutModule } from './collection-manager-shortcut/collection-manager-shortcut.module';
import { CollectionInsightNavigationModule } from './collectionInsightNavigation/collectionInsightNavigation.module';
import { AddToCollectionButtonModule } from './addToCollectionsButton/addToCollectionsButton.module';

@NgModule({
    declarations: [],
    imports: [NgxsModule.forFeature([CollectionManagerState])],
    exports: [
        CollectionManagerShortcutModule,
        CollectionInsightNavigationModule,
        AddToCollectionButtonModule
    ],
    providers: [CollectionManagerHandler]
})
export class CollectionManagerRootModule {
    // Note: if you would remove the collectionManagerHandler it will not
    // get instantiated and therefore not be able to handle the actions
    // in the background...
    constructor(private collectionManagerHandler: CollectionManagerHandler) {}
}
