import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'gfk-fair-share-growth-value',
    templateUrl: './fair-share-growth-value.component.html',
    styleUrls: ['./fair-share-growth-value.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareGrowthValueComponent {
    @HostBinding('class.positive-growth') get hasPositiveGrowth() {
        return this.growth > 0;
    }

    @HostBinding('class.negative-growth') get hasNegativeGrowth() {
        return this.growth < 0;
    }

    @Input()
    @HostBinding('class.bold')
    bold = false;

    @Input()
    growth: number;
}
