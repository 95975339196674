import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Angulartics2Module } from 'angulartics2';
import { AnnouncementsBellComponent } from 'insightui.announcements/announcements-bell.component';
import { AnnouncementsContainerComponent } from 'insightui.announcements/announcements-container.component';
import { AnnouncementsModalComponent } from 'insightui.announcements/announcements-modal.component';
import { AnnouncementsEpic } from 'insightui.announcements/announcements.epic';
import { AnnouncementsReducer } from 'insightui.announcements/announcements.reducer';
import { AnnouncementsService } from 'insightui.announcements/announcements.service';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';
import { NumberFormatModule } from 'insightui.number-format/number-format.module';
import { STATE_EPIC, STATE_REDUCER } from 'projects/insightui/src/app/state/state.module';

@NgModule({
    declarations: [
        AnnouncementsModalComponent,
        AnnouncementsBellComponent,
        AnnouncementsContainerComponent
    ],
    providers: [
        AnnouncementsService,
        { provide: STATE_EPIC, useClass: AnnouncementsEpic, multi: true },
        { provide: STATE_REDUCER, useClass: AnnouncementsReducer, multi: true },
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?last-seen\/?/,
            multi: true
        },
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?release-notes\/?/,
            multi: true
        }
    ],
    imports: [CommonModule, NumberFormatModule, Angulartics2Module],
    exports: [AnnouncementsContainerComponent]
})
export class AnnouncementsModule {}
