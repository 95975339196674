import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ILogger, log, LogService } from 'insightui.core/services/logging';
import { WINDOW } from 'insightui.global/global.module';
import { SessionUserDetails } from 'insightui.session/session.types';
import { fromEvent, interval, merge, Observable, of, Subject } from 'rxjs';
import {
    catchError,
    filter,
    map,
    mapTo,
    retry,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';

const SESSION_PULSE_RATE = 60000;
const KEY_LOGOUT_NOTIFIER = '__killSession';
export const KEY_TOKEN = 'authToken';
export const KEY_USERDETAILS = 'authUser';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private token: string;
    private userDetails: SessionUserDetails;

    private readonly killAll$$ = new Subject<void>();

    private readonly logger: ILogger;

    constructor(
        private httpClient: HttpClient,
        @Inject(WINDOW) private window: Window,
        logService: LogService
    ) {
        this.logger = logService.getLogger('SessionService');

        this.token = this.window.sessionStorage.getItem(KEY_TOKEN);

        this.userDetails = JSON.parse(
            this.window.sessionStorage.getItem(KEY_USERDETAILS)
        );

        this.pingInternal();
    }

    currentToken(): Observable<string> {
        if (!this.token) {
            this.logger.error(`Cannot get current token. Forcing logout.`);
            this.logout();
            return;
        }
        return of(this.token);
    }

    currentUserDetails(): Observable<SessionUserDetails> {
        if (!this.currentUserDetails) {
            this.logger.error(`Cannot get current user details. Forcing logout.`);
            this.logout();
            return;
        }
        return of(this.userDetails);
    }

    logout() {
        this.killAll$$.next();

        this.httpClient
            .get<{ redirectUrl: string }>('/login/signOut')
            .pipe(
                retry(2),
                take(1),
                map(data => data.redirectUrl),
                tap(redirUrl => {
                    this.token = null;
                    this.userDetails = null;
                    this.window.sessionStorage.removeItem(KEY_TOKEN);
                    this.window.sessionStorage.removeItem(KEY_USERDETAILS);
                    this.notifyLogoutForOtherTabs();
                    this.window.location.href = redirUrl;
                })
            )
            .subscribe();
    }

    private notifyLogoutForOtherTabs(): void {
        this.window.localStorage.setItem(
            KEY_LOGOUT_NOTIFIER,
            new Date().getTime().toString()
        );
    }

    private pingInternal() {
        const regularInterval$ = interval(SESSION_PULSE_RATE).pipe(mapTo('heartbeat'));
        const windowFocusEvents$ = fromEvent(this.window, 'focus').pipe(
            mapTo('windowFocus')
        );

        merge(regularInterval$, windowFocusEvents$)
            .pipe(
                takeUntil(this.killAll$$),
                tap(why => {
                    this.logger.debug(`validate token (${why})`);
                }),
                switchMap(() =>
                    this.httpClient
                        .get<{
                            status: 'OK' | 'INVALID' | 'UNKNOWN';
                        }>('/login/ping')
                        .pipe(
                            retry(2),
                            catchError(err => {
                                this.logger.error(`could not validate token`, err);
                                return of({ status: 'UNKNOWN' });
                            })
                        )
                ),
                tap(data => {
                    if (data.status === 'INVALID') {
                        this.logger.warn(`token is invalid, logging out!`);
                        this.killAll$$.next();
                        this.logout();
                    } else if (data.status === 'UNKNOWN') {
                        this.logger.warn(`token status is unknown - ignoring!`);
                    }
                })
            )
            .subscribe();

        fromEvent(window, 'storage')
            .pipe(
                takeUntil(this.killAll$$),
                filter((evt: StorageEvent) => evt.key === KEY_LOGOUT_NOTIFIER),
                log('LogoutNotifier fired', this.logger),
                tap(() => {
                    this.logger.warn(
                        `token was removed from sessionStorage. Forcing logout!`
                    );
                    this.killAll$$.next();
                    this.logout();
                })
            )
            .subscribe();
    }
}
