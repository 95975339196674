import { TopBottomFilterStatus } from 'insightui.table/ng1services/topbottom-model.service';

export type QueryServiceResponseRow = (number | string)[];

export interface PeriodDefinition {
    start: string;
    end: string;
    type: string;
}

export interface ContextFilter {
    kpi: string;
    base: string;
    posType: string;
    filter: string;
    number: string;
    value: string | number;

    element?: { id: string };
    brand?: string;
}

export interface ContextFilterDTO {
    kpi: string;
    base: string;
    posType: string;
    filter: string;
    number: string;
    value: string | number;
}

export interface QueryServiceRequest {
    docRevId: number;
    level: string;
    dimensionKey: string;
    period: PeriodDefinition;
    kpiList: string[];
    channelList: string[];
    elementList?: string[];
    featureMap: {};
    priceClass: string;
    showFeatures: boolean;
    showFirstActivities: boolean;
    showPriceClasses: boolean;
    calculateOthers: boolean;
    calculateSegmentedOthers: boolean;
    contextFilter: ContextFilterDTO;
    reportId: string;
    pageId: string;
    posTypeList: string[];
    drillLevel: string;
    rebaseDimension: string;
    useSpecialBase: boolean;
    kpiAttributes: { [kpi: string]: { [attribute: string]: string } };
}

export interface QueryServiceResponseHeader {
    dim?: string;
    key: string;
    nestedCols?: QueryServiceResponseHeader[];
    type: string;
}

export interface AdditionalInfo {
    contextFilter?: TopBottomFilterStatus;
}

export interface QueryServiceResponse {
    data: QueryServiceResponseRow[];
    header: QueryServiceResponseHeader[];
    info: AdditionalInfo;
}

export enum HeaderType {
    LABEL,
    KPI,
    FEATURE_OR_PRICECLASS
}

export enum HttpHeaderType {
    UNCUT_TOTAL_COUNT
}

export interface ReportingRawDataSet {
    headers?: { key: HttpHeaderType; value: string }[];
    data: ReportingDataEntry[];
    info?: AdditionalInfo;
}

export interface ReportingDataSet extends Array<ReportingDataEntry> {
    additional?: { [key: string]: ReportingDataEntry[] };
}

export interface ReportingDataEntry {
    [dimension: string]: number | string;
}
export interface DrillTarget {
    dimensionId: string;
    drillLevel: string;
}
export interface DrillLevel {
    context: string;
    dataLevel: string;
    enableFeaturesFilter: boolean;
    enableFeatzuresSplit: boolean;
    enablePriceClassFilter: boolean;
    enablePriceClassSplit: boolean;
    id: string;
    level: string;
    sideHeaders: string[];
    topHeaders: string[];
}
