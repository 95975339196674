import {
    AfterViewInit,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2
} from '@angular/core';
import { merge, Observable, Observer, Subject } from 'rxjs';
import {
    debounceTime,
    filter,
    flatMap,
    map,
    pairwise,
    share,
    take,
    takeUntil,
    throttleTime
} from 'rxjs/operators';
import { DatatableComponent } from '../datatable.component';
import { BoundingRectangle } from './interfaces/boundingRectangle.interface';
import { Edges } from './interfaces/edges.interface';

interface PointerEventCoordinate {
    clientX: number;
    clientY: number;
    event: MouseEvent;
}

interface Coordinate {
    x: number;
    y: number;
}

function isNumberCloseTo(value1: number, value2: number, precision: number = 3): boolean {
    const diff: number = Math.abs(value1 - value2);
    return diff < precision;
}

function getNewBoundingRectangle(
    startingRect: BoundingRectangle,
    edges: Edges,
    clientX: number,
    clientY: number
): BoundingRectangle {
    const newBoundingRect: BoundingRectangle = {
        top: startingRect.top,
        bottom: startingRect.bottom,
        left: startingRect.left,
        right: startingRect.right
    };

    if (edges.top) {
        newBoundingRect.top += clientY;
    }
    if (edges.bottom) {
        newBoundingRect.bottom += clientY;
    }
    if (edges.left) {
        newBoundingRect.left += clientX;
    }
    if (edges.right) {
        newBoundingRect.right += clientX;
    }
    newBoundingRect.height = newBoundingRect.bottom - newBoundingRect.top;
    newBoundingRect.width = newBoundingRect.right - newBoundingRect.left;

    return newBoundingRect;
}

function getElementRect(element: ElementRef): BoundingRectangle {
    const boundingRect: BoundingRectangle = element.nativeElement.getBoundingClientRect();
    return {
        height: boundingRect.height,
        width: boundingRect.width,
        top: boundingRect.top,
        bottom: boundingRect.bottom,
        left: boundingRect.left,
        right: boundingRect.right
    };
}

function isWithinBoundingY({
    clientY,
    rect
}: {
    clientY: number;
    rect: ClientRect;
}): boolean {
    return clientY >= rect.top && clientY <= rect.bottom;
}

function getResizeEdges({
    clientX,
    clientY,
    elm,
    allowedEdges,
    cursorPrecision
}: {
    clientX: number;
    clientY: number;
    elm: ElementRef;
    allowedEdges: Edges;
    cursorPrecision: number;
}): Edges {
    const elmPosition: ClientRect = elm.nativeElement.getBoundingClientRect();
    const edges: Edges = {};

    if (
        allowedEdges.left &&
        isNumberCloseTo(clientX, elmPosition.left, cursorPrecision) &&
        isWithinBoundingY({ clientY, rect: elmPosition })
    ) {
        edges.left = true;
    }

    if (
        allowedEdges.right &&
        isNumberCloseTo(clientX, elmPosition.right, cursorPrecision) &&
        isWithinBoundingY({ clientY, rect: elmPosition })
    ) {
        edges.right = true;
    }

    return edges;
}

export interface ResizeCursors {
    topLeft: string;
    topRight: string;
    bottomLeft: string;
    bottomRight: string;
    leftOrRight: string;
    topOrBottom: string;
}

const DEFAULT_RESIZE_CURSORS: ResizeCursors = Object.freeze({
    topLeft: 'nw-resize',
    topRight: 'ne-resize',
    bottomLeft: 'sw-resize',
    bottomRight: 'se-resize',
    leftOrRight: 'ew-resize',
    topOrBottom: 'ns-resize'
});

function getResizeCursor(edges: Edges, cursors: ResizeCursors): string {
    if (edges.left || edges.right) {
        return cursors.leftOrRight;
    } else {
        return null;
    }
}

function getEdgesDiff({
    edges,
    initialRectangle,
    newRectangle
}: {
    edges: Edges;
    initialRectangle: BoundingRectangle;
    newRectangle: BoundingRectangle;
}): Edges {
    const edgesDiff: Edges = {};
    Object.keys(edges).forEach((edge: string) => {
        edgesDiff[edge] = newRectangle[edge] - initialRectangle[edge];
    });
    return edgesDiff;
}

const RESIZE_ACTIVE_CLASS = 'resize-active';
const RESIZE_LEFT_HOVER_CLASS = 'resize-left-hover';
const RESIZE_RIGHT_HOVER_CLASS = 'resize-right-hover';

export const MOUSE_MOVE_THROTTLE_MS = 50;

class PointerEventListeners {
    public pointerDown: Observable<PointerEventCoordinate>;
    public pointerMove: Observable<PointerEventCoordinate>;
    public pointerUp: Observable<PointerEventCoordinate>;

    private static instance: PointerEventListeners; // tslint:disable-line

    constructor(renderer: Renderer2, zone: NgZone, datatable?: DatatableComponent) {
        zone.runOutsideAngular(() => {
            // if we have a datatable element, we only listen on that element for
            // mouse movements, otherwise we listen on the whole document
            const listenTarget = datatable ? datatable.element : 'document';
            this.pointerDown = new Observable(
                (observer: Observer<PointerEventCoordinate>) => {
                    const unsubscribeMouseDown = renderer.listen(
                        listenTarget,
                        'mousedown',
                        (event: MouseEvent) => {
                            observer.next({
                                clientX: event.clientX,
                                clientY: event.clientY,
                                event
                            });
                        }
                    );

                    return () => {
                        unsubscribeMouseDown();
                    };
                }
            ).pipe(share());

            this.pointerMove = new Observable(
                (observer: Observer<PointerEventCoordinate>) => {
                    const unsubscribeMouseMove = renderer.listen(
                        listenTarget,
                        'mousemove',
                        (event: MouseEvent) => {
                            observer.next({
                                clientX: event.clientX,
                                clientY: event.clientY,
                                event
                            });
                        }
                    );

                    return () => {
                        unsubscribeMouseMove();
                    };
                }
            ).pipe(
                debounceTime(16),
                share()
            );

            this.pointerUp = new Observable(
                (observer: Observer<PointerEventCoordinate>) => {
                    const unsubscribeMouseUp = renderer.listen(
                        listenTarget,
                        'mouseup',
                        (event: MouseEvent) => {
                            observer.next({
                                clientX: event.clientX,
                                clientY: event.clientY,
                                event
                            });
                        }
                    );

                    return () => {
                        unsubscribeMouseUp();
                    };
                }
            ).pipe(share());
        });
    }

    public static getInstance(
        renderer: Renderer2,
        zone: NgZone,
        datatable?: DatatableComponent
    ): PointerEventListeners {
        if (!PointerEventListeners.instance) {
            PointerEventListeners.instance = new PointerEventListeners(
                renderer,
                zone,
                datatable
            );
        }
        return PointerEventListeners.instance;
    }
}

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[mwlResizable]'
})
export class ColumnResizableDirective implements OnInit, OnDestroy, AfterViewInit {
    /**
     * The edges that an element can be resized from. Pass an object like `{top: true, bottom: false}`. By default no edges can be resized.
     */
    @Input() resizeEdges: Edges = {};

    /**
     * The mouse cursors that will be set on the resize edges
     */
    @Input() resizeCursors: ResizeCursors = DEFAULT_RESIZE_CURSORS;

    /**
     * Mouse over thickness to active cursor.
     */
    @Input() resizeCursorPrecision = 2;

    /**
     * Called when the mouse is pressed and a resize event is about to begin. `$event` is a `ResizeEvent` object.
     */
    @Output() resizeStart: EventEmitter<object> = new EventEmitter();

    /**
     * Called as the mouse is dragged after a resize event has begun. `$event` is a `ResizeEvent` object.
     */
    @Output() resizing: EventEmitter<object> = new EventEmitter();

    /**
     * Called after the mouse is released after a resize event. `$event` is a `ResizeEvent` object.
     */
    @Output() resizeEnd: EventEmitter<object> = new EventEmitter();

    /**
     * @hidden
     */
    public mouseup: Subject<any> = new Subject(); // tslint:disable-line

    /**
     * @hidden
     */
    public mousedown: Subject<any> = new Subject(); // tslint:disable-line

    /**
     * @hidden
     */
    public mousemove: Subject<any> = new Subject(); // tslint:disable-line

    private pointerEventListeners: PointerEventListeners;
    private pointerEventListenerSubscriptions: any = {}; // tslint:disable-line

    /**
     * @hidden
     */
    constructor(
        @Optional() datatable: DatatableComponent,
        private renderer: Renderer2,
        public elm: ElementRef,
        private zone: NgZone
    ) {
        this.pointerEventListeners = PointerEventListeners.getInstance(
            renderer,
            zone,
            datatable
        );
    }

    /**
     * @hidden
     */
    ngOnInit(): void {
        this.zone.runOutsideAngular(() => {
            // TODO - use some fancy Observable.merge's for this
            this.pointerEventListenerSubscriptions.pointerDown = this.pointerEventListeners.pointerDown.subscribe(
                ({ clientX, clientY }) => {
                    this.mousedown.next({ clientX, clientY });
                }
            );

            this.pointerEventListenerSubscriptions.pointerMove = this.pointerEventListeners.pointerMove.subscribe(
                ({ clientX, clientY, event }) => {
                    this.mousemove.next({ clientX, clientY, event });
                }
            );

            this.pointerEventListenerSubscriptions.pointerUp = this.pointerEventListeners.pointerUp.subscribe(
                ({ clientX, clientY }) => {
                    this.mouseup.next({ clientX, clientY });
                }
            );

            let currentResize: {
                edges: Edges;
                startingRect: BoundingRectangle;
                currentRect: BoundingRectangle;
                clonedNode?: HTMLElement;
            };

            const removeGhostElement = (): void => {
                if (currentResize.clonedNode) {
                    this.elm.nativeElement.parentElement.removeChild(
                        currentResize.clonedNode
                    );
                    this.renderer.setStyle(
                        this.elm.nativeElement,
                        'visibility',
                        'inherit'
                    );
                }
            };

            const mouseMove: Observable<any> = this.mousemove.pipe(share());

            mouseMove.pipe(filter(() => !!currentResize)).subscribe(({ event }) => {
                event.preventDefault();
            });

            mouseMove
                .pipe(throttleTime(MOUSE_MOVE_THROTTLE_MS))
                .subscribe(({ clientX, clientY }) => {
                    const resizeEdges: Edges = getResizeEdges({
                        clientX,
                        clientY,
                        elm: this.elm,
                        allowedEdges: this.resizeEdges,
                        cursorPrecision: this.resizeCursorPrecision
                    });
                    const resizeCursors: ResizeCursors = Object.assign(
                        {},
                        DEFAULT_RESIZE_CURSORS,
                        this.resizeCursors
                    );
                    const cursor: string = currentResize
                        ? null
                        : getResizeCursor(resizeEdges, resizeCursors);

                    this.renderer.setStyle(this.elm.nativeElement, 'cursor', cursor);
                    this.setElementClass(this.elm, RESIZE_ACTIVE_CLASS, !!currentResize);
                    this.setElementClass(
                        this.elm,
                        RESIZE_LEFT_HOVER_CLASS,
                        resizeEdges.left === true
                    );
                    this.setElementClass(
                        this.elm,
                        RESIZE_RIGHT_HOVER_CLASS,
                        resizeEdges.right === true
                    );
                });

            const mousedrag: Observable<any> = this.mousedown.pipe(
                flatMap(startCoords => {
                    const getDiff = (moveCoords: MouseEvent) => {
                        return {
                            clientX: moveCoords.clientX - startCoords.clientX,
                            clientY: moveCoords.clientY - startCoords.clientY
                        };
                    };

                    const defaultSnapGrid = { x: 1, y: 1 };

                    const getGrid = (coords, snapGrid) => {
                        return {
                            x: Math.ceil(coords.clientX / snapGrid.x),
                            y: Math.ceil(coords.clientY / snapGrid.y)
                        };
                    };

                    return merge(
                        mouseMove.pipe(
                            take(1),
                            map(coords => [, coords])
                        ),
                        mouseMove.pipe(pairwise())
                    ).pipe(
                        map(([previousCoords, newCoords]) => {
                            return [
                                previousCoords ? getDiff(previousCoords) : previousCoords,
                                getDiff(newCoords)
                            ];
                        }),
                        filter(([previousCoords, newCoords]) => {
                            if (!previousCoords) {
                                return true;
                            }
                            const snapGrid: Coordinate = defaultSnapGrid;
                            const previousGrid: Coordinate = getGrid(
                                previousCoords,
                                snapGrid
                            );
                            const newGrid: Coordinate = getGrid(newCoords, snapGrid);

                            return (
                                previousGrid.x !== newGrid.x ||
                                previousGrid.y !== newGrid.y
                            );
                        }),
                        map(([, newCoords]) => {
                            const snapGrid: Coordinate = defaultSnapGrid;

                            return {
                                clientX:
                                    Math.round(newCoords.clientX / snapGrid.x) *
                                    snapGrid.x,
                                clientY:
                                    Math.round(newCoords.clientY / snapGrid.y) *
                                    snapGrid.y
                            };
                        }),
                        takeUntil(merge(this.mouseup, this.mousedown))
                    );
                }),
                filter(() => !!currentResize)
            );

            mousedrag
                .pipe(
                    map(({ clientX, clientY }) => {
                        return getNewBoundingRectangle(
                            currentResize.startingRect,
                            currentResize.edges,
                            clientX,
                            clientY
                        );
                    }),
                    filter((newBoundingRect: BoundingRectangle) => {
                        return newBoundingRect.height > 0 && newBoundingRect.width > 0;
                    })
                )
                .subscribe((newBoundingRect: BoundingRectangle) => {
                    if (currentResize.clonedNode) {
                        this.renderer.setStyle(
                            currentResize.clonedNode,
                            'height',
                            `${newBoundingRect.height}px`
                        );
                        this.renderer.setStyle(
                            currentResize.clonedNode,
                            'width',
                            `${newBoundingRect.width}px`
                        );
                        this.renderer.setStyle(
                            currentResize.clonedNode,
                            'top',
                            `${newBoundingRect.top}px`
                        );
                        this.renderer.setStyle(
                            currentResize.clonedNode,
                            'left',
                            `${newBoundingRect.left}px`
                        );
                    }

                    this.zone.run(() => {
                        this.resizing.emit({
                            edges: getEdgesDiff({
                                edges: currentResize.edges,
                                initialRectangle: currentResize.startingRect,
                                newRectangle: newBoundingRect
                            }),
                            rectangle: newBoundingRect
                        });
                    });

                    currentResize.currentRect = newBoundingRect;
                });

            this.mousedown
                .pipe(
                    map(({ clientX, clientY, edges }) => {
                        return (
                            edges ||
                            getResizeEdges({
                                clientX,
                                clientY,
                                elm: this.elm,
                                allowedEdges: this.resizeEdges,
                                cursorPrecision: this.resizeCursorPrecision
                            })
                        );
                    }),
                    filter((edges: Edges) => {
                        return Object.keys(edges).length > 0;
                    })
                )
                .subscribe((edges: Edges) => {
                    if (currentResize) {
                        removeGhostElement();
                    }

                    const startingRect: BoundingRectangle = getElementRect(this.elm);
                    currentResize = {
                        edges,
                        startingRect,
                        currentRect: startingRect
                    };

                    this.zone.run(() => {
                        this.resizeStart.emit({
                            edges: getEdgesDiff({
                                edges,
                                initialRectangle: startingRect,
                                newRectangle: startingRect
                            }),
                            rectangle: getNewBoundingRectangle(startingRect, {}, 0, 0)
                        });
                    });
                });

            this.mouseup.subscribe(() => {
                if (currentResize) {
                    this.renderer.removeClass(
                        this.elm.nativeElement,
                        RESIZE_ACTIVE_CLASS
                    );
                    this.zone.run(() => {
                        this.resizeEnd.emit({
                            edges: getEdgesDiff({
                                edges: currentResize.edges,
                                initialRectangle: currentResize.startingRect,
                                newRectangle: currentResize.currentRect
                            }),
                            rectangle: currentResize.currentRect
                        });
                    });
                    removeGhostElement();
                    currentResize = null;
                }
            });
        });
    }

    /**
     * @hidden
     */
    ngAfterViewInit(): void {}

    /**
     * @hidden
     */
    ngOnDestroy(): void {
        this.mousedown.complete();
        this.mouseup.complete();
        this.mousemove.complete();
        this.pointerEventListenerSubscriptions.pointerDown.unsubscribe();
        this.pointerEventListenerSubscriptions.pointerMove.unsubscribe();
        this.pointerEventListenerSubscriptions.pointerUp.unsubscribe();
    }

    private setElementClass(elm: ElementRef, name: string, add: boolean): void {
        if (add) {
            this.renderer.addClass(elm.nativeElement, name);
        } else {
            this.renderer.removeClass(elm.nativeElement, name);
        }
    }
}
