import { Observable, Subject, of } from 'rxjs';
import { map, debounceTime, tap, mergeMap, catchError } from 'rxjs/operators';
import { DataRow } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { ReportDataRemoteService } from 'insightui.data/remote/report-data-remote.service';
import { DatatableConfig } from 'insightui.table/services/table.data';
import { Injectable } from '@angular/core';
import {
    ReportingDataSet,
    AdditionalInfo
} from 'insightui.data/query/queryservice/query-service-request.interface';
import { PeriodUtilityService } from 'insightui.period/period-utility.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { LoadingIndicatorService } from 'insightui.loading-indicator/loading-indicator.service';
import { NgRedux } from '@angular-redux/store';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';
import * as _ from 'lodash';

const TAG = 'TG';
const showFirstActivitiesField = 'FA';

export interface TableData {
    data: DataRow[];
    filteredTotal: DataRow;
    others: DataRow;
    subTotal: DataRow;
    tradebrandAndExclusive: DataRow;
    total: DataRow;
    attributes?: { key: string; value: string }[];
    info: AdditionalInfo;
}

/**
 * Service for fetching data from the data module and handling notifications that
 * cause data changes to take effect.
 */
@Injectable()
export class TableDataService {
    private logger: ILogger;
    private reloadData$: Subject<void> = new Subject<void>();

    constructor(
        private reportDataRemoteService: ReportDataRemoteService,
        private datatableConfigurationService: DatatableConfigurationService,
        private metadataService: MetadataService,
        private loadingIndicator: LoadingIndicatorService,
        private periodUtilityService: PeriodUtilityService,
        private ngRedux: NgRedux<undefined>,
        private metadataSelectors: MetadataSelectors,
        private visualizationContext: VisualizationContextService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('TableDataService');
    }

    public forceReload() {
        this.reloadData$.next();
    }

    public onReload(): Observable<void> {
        return this.reloadData$;
    }

    public onDataAvailable(config: DatatableConfig): Observable<any> {
        return this.reloadData$.pipe(
            debounceTime(10),
            tap(() => this.loadingStarted()),
            mergeMap(() => this.loadData(config)),
            catchError(err => {
                this.logger.warn(`caught and ignored error in reloadData$`, err);
                return of(void 0);
            }),
            tap((data: TableData) => this.loadingFinished(this.hasAnyData(data)))
        );
    }

    private hasAnyData(tableData: TableData): boolean {
        return (
            (tableData !== null &&
                tableData.data.some(entry => entry.TG !== 'missing')) ||
            !!tableData.tradebrandAndExclusive
        );
    }

    private loadData(config: DatatableConfig): Observable<TableData> {
        const sideHeaders = this.datatableConfigurationService.getSideHeaders();
        const request = this.reportDataRemoteService.buildRequest(
            this.datatableConfigurationService.getKpiDefinitions(config)
        );

        let attributes = [];
        let info: AdditionalInfo;
        const topHeaders = config.dimensionContext.filter(dim => dim !== 'element');

        return this.reportDataRemoteService.executeRequest(request).pipe(
            tap(result => {
                attributes = result.headers;
                info = result.info;
            }),
            map(result => result.data),

            // Convert to row format as currently defined in the table
            map(
                this.reportDataRemoteService.responseMapper.crossTab(
                    [TAG].concat(sideHeaders),
                    topHeaders
                )
            ),

            // Remove rows which do not meet a certain condition, e.g. not available in hierarchy tree
            map(
                this.reportDataRemoteService.responseMapper.removeRow(
                    this.metadataSelectors,
                    this.visualizationContext
                )
            ),

            // Remove tagged rows, so they can be handled specially and don't occur in the normal result list
            map(this.reportDataRemoteService.responseMapper.extractTags()),

            // Always add a total line, even if none is given
            map(this.reportDataRemoteService.responseMapper.addEmptyTotal()),

            map(dataSet => {
                const result: TableData = {
                    data: [],
                    filteredTotal: null,
                    others: null,
                    subTotal: null,
                    tradebrandAndExclusive: null,
                    total: null,
                    attributes,
                    info: null
                };

                result.data = dataSet.map(dataRow =>
                    this.mapToDataRow(dataRow, sideHeaders, config)
                );

                result.others = this.getTotalItem(
                    dataSet,
                    'others',
                    request.dimensionKey,
                    config
                );
                if (result.others) {
                    this.datatableConfigurationService
                        .getSideHeaders()
                        .forEach(
                            sideHeader => (result.others[sideHeader] = result.others.TG)
                        );
                }

                result.tradebrandAndExclusive = this.getTotalItem(
                    dataSet,
                    'tradebrand & exclusive',
                    request.dimensionKey,
                    config
                );
                if (result.tradebrandAndExclusive) {
                    this.datatableConfigurationService
                        .getSideHeaders()
                        .forEach(
                            sideHeader =>
                                (result.tradebrandAndExclusive[sideHeader] =
                                    result.tradebrandAndExclusive.TG)
                        );
                } else if (request.drillLevel === 'brandInPg') {
                    result.tradebrandAndExclusive = result.data.find(
                        resultElement => resultElement.BR === 'Tradebrand & Exclusive'
                    );
                    _.remove(
                        result.data,
                        resultElement => resultElement.BR === 'Tradebrand & Exclusive'
                    );
                }

                result.total = this.getTotalItem(
                    dataSet,
                    'total',
                    request.dimensionKey,
                    config
                );
                result.subTotal = this.getTotalItem(
                    dataSet,
                    'subtotal',
                    request.dimensionKey,
                    config
                );
                result.filteredTotal = this.getTotalItem(
                    dataSet,
                    'totalfilteredagainstmarket',
                    request.dimensionKey,
                    config
                );

                result.info = info;

                return result;
            })
        );
    }

    private getTotalItem(
        reportingData: ReportingDataSet,
        totalKey: string,
        totalDimension: string,
        config: DatatableConfig
    ) {
        if (
            !reportingData.additional ||
            !reportingData.additional.hasOwnProperty(totalKey)
        ) {
            return;
        }
        const dataRow = reportingData.additional[totalKey][0];
        const sideHeaders = this.datatableConfigurationService.getSideHeaders();
        return this.mapToDataRow(dataRow, sideHeaders, config, totalDimension, totalKey);
    }

    private getFeatureValueTitle(element: string) {
        const separator = element.indexOf('=');
        if (separator >= 0) {
            const featureId = element.substr(0, separator);
            const featureValueId = element.substr(separator + 1);
            const featureValue = this.metadataService.getFeatureById(featureId).values[
                featureValueId
            ];

            return featureValue ? featureValue.title : null;
        }
        return '';
    }

    // TODO: Remove hardcoded references to dimensions and values
    private getFeatureValueOrPriceClassValueTitle(element) {
        const featureValueTitle = this.getFeatureValueTitle(element);
        if (featureValueTitle) {
            return featureValueTitle;
        } else if (element.indexOf('PC') === 0) {
            return this.metadataService.getPriceClassById(element).title;
        } else {
            let notAvailableText;
            if (this.visualizationContext.get('showPriceClasses')) {
                notAvailableText = 'Price Classes';
            }
            if (this.visualizationContext.get('showFeatures')) {
                notAvailableText = 'Features';
            }
            return `Total (No ${notAvailableText}')`;
        }
    }

    private getDate(value) {
        const date = this.periodUtilityService.getDateFromPeriod(value);
        if (!isNaN(date.getFullYear())) {
            return date.getFullYear() + '-' + (date.getMonth() + 1);
        }
        return '';
    }

    // TODO: This is a specific CCR Mapping and must be generalized
    // this might include fixes on the server side, but also the introduction of a resolver service that allows
    // us to resolve ids to names without going through all possible cases
    private mapToDataRow(
        dataRow,
        sideHeaders: string[],
        config: DatatableConfig,
        useKey?: string,
        totalKey?: string
    ) {
        const dataItem: DataRow = {};
        const currentBrandName = this.visualizationContext.get('brandName');

        Object.keys(dataRow).forEach(key => {
            if (totalKey) {
                if (sideHeaders.indexOf(key) === 0) {
                    if (totalKey === 'total') {
                        if (currentBrandName) {
                            dataItem[key] = currentBrandName;
                            return;
                        }

                        this.metadataSelectors
                            .getHierarchyDimension$(useKey)
                            .subscribe(dim => {
                                if (dim) {
                                    dataItem[key] = dim.title;
                                } else {
                                    dataItem[key] = useKey;
                                }
                            });
                    } else if (totalKey === 'subtotal') {
                        dataItem[key] = 'Subtotal';
                    }
                } else if (key !== config.priceClassFeature) {
                    dataItem[key] = dataRow[key];
                }
            } else {
                // Show Features
                if (this.datatableConfigurationService.isShowFeatureField(key)) {
                    if (!dataRow[key]) {
                        return;
                    }
                    dataItem[key] = this.getFeatureValueTitle(dataRow[key].toString());
                } else if (
                    sideHeaders.indexOf(key) >= 0 &&
                    config.noDimensionLookup.indexOf(key) < 0
                ) {
                    this.metadataSelectors
                        .getHierarchyDimension$(useKey || dataRow[key])
                        .subscribe(dim => {
                            if (dim) {
                                dataItem[key] = dim.title;
                            } else {
                                dataItem[key] = dataRow[key];
                            }
                        });
                } else if (key === config.priceClassFeature) {
                    dataItem[key] = this.getFeatureValueOrPriceClassValueTitle(
                        dataRow[key].toString()
                    );
                } else if (key === showFirstActivitiesField) {
                    if (!dataRow[key]) {
                        dataItem[key] = '';
                    } else {
                        dataItem[key] = this.getDate(
                            dataRow[key].toString().replace(showFirstActivitiesField, '')
                        );
                    }
                } else {
                    dataItem[key] = dataRow[key];
                }
            }
        });
        return dataItem;
    }

    private loadingStarted() {
        this.ngRedux.dispatch({
            type: 'DEPRECATED_TABLE_DATA_SERVICE/RELOAD_START',
            payload: null
        });
        this.loadingIndicator.toggleOn();
    }

    private loadingFinished(withData: boolean) {
        if (withData) {
            this.ngRedux.dispatch({
                type: 'DEPRECATED_TABLE_DATA_SERVICE/RELOAD_DONE',
                payload: null
            });
        } else {
            this.ngRedux.dispatch({
                type: 'DEPRECATED_TABLE_DATA_SERVICE/RELOAD_DONE_NO_DATA',
                payload: 'missing data'
            });
        }

        this.loadingIndicator.toggleOff();
    }
}
