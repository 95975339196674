<datatable-body-row
    *ngIf="totalRow !== undefined"
    [innerWidth]="totalWidth"
    [columns]="columns"
    [offsetX]="offsetX"
    [rowHeight]="rowHeight"
    [visibilityState]="visibilityState"
    [row]="totalRow"
>
</datatable-body-row>

<datatable-scroller
    [innerWidth]="innerWidth"
    [scrollHeight]="bodyHeightDataBody"
    [pane]="pane"
    (scrollChanged)="onYScroll()"
>
    <div
        class="filler-top"
        [ngStyle]="{ 'height.px': this.visibilityState.topFiller, order: 0 }"
    ></div>

    <datatable-body-row
        *ngFor="let row of visibleRows; let i = index; trackBy: rowTrackByFn"
        [innerWidth]="totalWidth"
        [offsetX]="offsetX"
        [columns]="columns"
        [rowHeight]="rowHeight"
        [row]="row"
        [visibilityState]="visibilityState"
    >
    </datatable-body-row>

    <div
        class="filler-bottom"
        [ngStyle]="{ 'height.px': this.visibilityState.bottomFiller, order: 0 }"
    ></div>
</datatable-scroller>

<datatable-body-row
    *ngIf="subTotalRow !== undefined"
    [innerWidth]="totalWidth"
    [offsetX]="offsetX"
    [columns]="columns"
    [rowHeight]="rowHeight"
    [visibilityState]="visibilityState"
    [row]="subTotalRow"
>
</datatable-body-row>

<empty-search [rows]="rows.length"></empty-search>
