import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { PowerpointInfoRowService } from 'insightui.export/powerpoint/services/powerpoint-info-row.service';
import { DOCUMENT } from 'insightui.global/global.module';
import { PowerpointExport, PowerpointExportSheets } from 'insightui.export/types';
import { COMPONENT_SELECTOR } from 'insightui.fair-share/fair-share-report/fair-share-report.component';

const REPORT_CONTAINER_SELECTOR = '.table-container';
const REPORT_CONTAINER_INNER_SELECTOR = '.table-container-inner';
// rows of thead are absolutely positioned so we need rows and not just thead
const TABLE_HEADER_SELECTOR = '.report-table thead tr';
const TABLE_BODY_SELECTOR = '.report-table tbody';

@Injectable()
export class FairShare implements PowerpointExport {
    constructor(
        private chartToSheetService: ChartToSheetService,
        private powerpointInfoRowService: PowerpointInfoRowService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    getPowerpointExportSheets(): Observable<PowerpointExportSheets> {
        const fairShareElement = this.getFairShareReportElementFromDOM();
        if (!fairShareElement) {
            return throwError(
                new Error('The current page does not have a fair share report.')
            );
        }

        const width = this.getSheetWidth(fairShareElement);
        const height = this.getSheetHeight(fairShareElement);

        const clonedNode = fairShareElement.cloneNode(true) as HTMLElement;

        this.resizeToRemoveScrollbar(clonedNode, width);
        this.powerpointInfoRowService.appendFilterInfoToElement(clonedNode);

        const fixedSizeSheet = this.chartToSheetService.getFixedSizeSheet(
            clonedNode,
            width,
            height
        );

        return observableOf([fixedSizeSheet]);
    }

    private getFairShareReportElementFromDOM(): HTMLElement {
        return this.document.querySelector(COMPONENT_SELECTOR);
    }

    private resizeToRemoveScrollbar(reportClone: HTMLElement, reportWidth: number) {
        const reportContent: HTMLElement = reportClone.querySelector(
            REPORT_CONTAINER_INNER_SELECTOR
        );
        const reportContainerClone: HTMLElement = reportClone.querySelector(
            REPORT_CONTAINER_SELECTOR
        );

        reportContainerClone.style.height = 'initial';
        reportContainerClone.style.width = reportWidth + 'px';
        reportContent.style.height = 'initial';
    }

    private getSheetWidth(reportElement: HTMLElement) {
        const reportContent = reportElement.querySelector(
            REPORT_CONTAINER_INNER_SELECTOR
        );

        return reportContent.scrollWidth;
    }

    private getSheetHeight(reportElement: HTMLElement) {
        const header = reportElement.querySelectorAll<HTMLElement>(TABLE_HEADER_SELECTOR);
        const body = reportElement.querySelectorAll<HTMLElement>(TABLE_BODY_SELECTOR);

        const allContent = Array.from(header || []).concat(Array.from(body || []));
        const commonHeight = allContent.reduce((a, b) => a + b.offsetHeight, 0);

        return commonHeight;
    }
}
