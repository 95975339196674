<div class="custom-sort">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Custom Sort</h4>

        <button
            type="button"
            class="btn button-custom-sort button-custom-sort-add pull-right"
            (click)="addLevel(); $event.stopPropagation()"
            [disabled]="
                sortingColumns.length >= 5 ||
                sortingColumns.length >= allAvailableColumns.length
            "
        >
            <span>Add Level</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <div class="sorting-table-header">
                <div class="custom-sort-col1">Column</div>
                <div class="custom-sort-col2">Order</div>
                <div class="custom-sort-col3"></div>
            </div>
            <div cdkDropList (cdkDropListDropped)="onDropped($event)">
                <div
                    class="custom-sort-table-row"
                    cdkDrag
                    cdkDragBoundary="div[cdkDropList]"
                    cdkDragLockAxis="y"
                    [attr.data-uitest]="'sortingSetting-' + column.columnKey"
                    *ngFor="let column of sortingColumns; let row = index"
                >
                    <div
                        style="height: 40px; border: 1px solid var(--color-border-gray)"
                        *cdkDragPlaceholder
                    ></div>

                    <div class="custom-sort-table-row" *cdkDragPreview>
                        <i class="fa fa-ellipsis-v custom-sort-drag-handle"></i>
                        <div class="custom-sort-col1">
                            <span *ngIf="row === 0">Sort by:</span>
                            <span *ngIf="row > 0">Then by:</span>
                            <span style="width: initial">{{
                                sortingColumns[row].columnTitle
                            }}</span>
                        </div>
                        <div class="custom-sort-col2">
                            <div>
                                {{ sortingDirection[sortingColumns[row].direction] }}
                            </div>
                        </div>
                        <div class="custom-sort-col3">
                            <i class="fa fa-times"></i>
                        </div>
                    </div>

                    <i class="fa fa-ellipsis-v custom-sort-drag-handle" cdkDragHandle></i>
                    <div class="custom-sort-col1">
                        <span *ngIf="row === 0" cdkDragHandle>Sort by:</span>
                        <span *ngIf="row > 0" cdkDragHandle>Then by:</span>

                        <select
                            class="custom-sort-select-column"
                            (change)="onChangeColumn($event.target.value, row)"
                        >
                            <option
                                *ngFor="let option of sortingSelectableColumns[row]"
                                [value]="option.columnId"
                                [selected]="
                                    option.columnId === sortingColumns[row].columnId
                                "
                                [attr.data-uitest]="'sortingColumn-' + option.columnKey"
                                >{{ option.columnTitle }}
                            </option>
                        </select>
                    </div>
                    <div class="custom-sort-col2">
                        <select
                            class="custom-sort-select-direction"
                            (change)="onChangeDirection($event.target.value, row)"
                        >
                            <option
                                *ngFor="let dirItem of sortingDirection; let idx = index"
                                [value]="idx"
                                [selected]="sortingColumns[row].direction == idx"
                                [attr.data-uitest]="'sortingDirection-' + dirItem"
                                >{{ dirItem }}
                            </option>
                        </select>
                    </div>
                    <div class="custom-sort-col3">
                        <i
                            (click)="removeColumn(row); $event.stopPropagation()"
                            class="fa fa-times"
                            data-uitest="sortingSetting-remove"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn button-custom-sort button-custom-sort-cancel"
            (click)="hideModal(); $event.stopPropagation()"
        >
            <span>Cancel</span>
        </button>
        <button
            type="button"
            class="btn button-custom-sort button-custom-sort-apply"
            (click)="apply()"
            angulartics2On
            angularticsCategory="DataTable"
            angularticsAction="CustomSort"
        >
            <span>Apply</span>
        </button>
    </div>
</div>
