import {
    PageDefinition,
    ReportDefinition,
    VizSettings,
    DrillLevelSetting
} from 'insightui.data/types';
import * as _ from 'lodash';

export class PageDefinitionModel implements PageDefinition {
    get id(): string {
        return this.internalAnyPageDefinition.id;
    }

    set id(value: string) {
        if (value !== this.internalAnyPageDefinition.id) {
            throw new Error('Trying to overwrite page id');
        }
    }

    get title(): string {
        return this.internalAnyPageDefinition.title;
    }

    get drillTarget(): any {
        return this.internalAnyPageDefinition.drillTarget;
    }

    get report(): ReportDefinition {
        return this.internalAnyPageDefinition.report;
    }

    get channelComparison(): any {
        return this.internalAnyPageDefinition.channelComparison;
    }

    get chart(): any {
        return this.internalAnyPageDefinition.chart;
    }

    get showComparisonPeriod(): boolean {
        return this.internalAnyPageDefinition.showComparisonPeriod;
    }

    get defaultSorting(): any {
        return this.internalAnyPageDefinition.defaultSorting;
    }

    get defaultPeriodicity(): 'monthly' | 'daily' | 'weekly' | 'yearly' {
        return this.internalAnyPageDefinition.defaultPeriodicity;
    }

    get visualizationType(): 'datatable' | 'barChart' {
        return this.internalAnyPageDefinition.visualizationType;
    }

    get useDistributedCalculation(): boolean {
        return !!this.internalAnyPageDefinition.useDistributedCalculation;
    }

    get showFirstActivities(): boolean {
        return !!this.internalAnyPageDefinition.showFirstActivities;
    }

    get splitByFeatures(): boolean {
        return !!this.internalAnyPageDefinition.splitByFeatures;
    }

    get splitByPriceClasses(): boolean {
        return !!this.internalAnyPageDefinition.splitByPriceClasses;
    }

    get splitByOnlineOffline(): boolean {
        return !!this.internalAnyPageDefinition.splitByOnlineOffline;
    }

    get restrictions(): string[] {
        return this.internalAnyPageDefinition.restrictions || [];
    }

    private readonly internalAnyPageDefinition: PageDefinition;

    constructor(pageDefinition: PageDefinition) {
        this.internalAnyPageDefinition = pageDefinition;
    }

    getDrillLevel(id: string): DrillLevelSetting | {} {
        if (
            this.internalAnyPageDefinition.report &&
            this.internalAnyPageDefinition.report.drillLevel
        ) {
            const drillLevelSetting = this.internalAnyPageDefinition.report.drillLevel[
                id
            ];
            return drillLevelSetting || {};
        }
        return {};
    }

    getTopHeaderElement(id: string, attribute: { name: string; value: string }[]): any {
        const topHeaderElement =
            _.find(
                _.get(
                    this.internalAnyPageDefinition,
                    'report.interactiveHeader.topElements'
                ),
                ['name', id]
            ) || {};
        if (topHeaderElement && attribute) {
            return _.get(
                _.find(topHeaderElement.attributes, ['name', attribute]),
                'value',
                null
            );
        } else {
            return topHeaderElement;
        }
    }

    getSegmentationHeaderElement(
        id: string,
        attribute: { name: string; value: string }[]
    ) {
        const segmentationElement =
            _.find(
                _.get(
                    this.internalAnyPageDefinition,
                    'report.interactiveHeader.topSubElements'
                ),
                ['name', id]
            ) || {};
        if (segmentationElement && attribute) {
            return _.get(
                _.find(segmentationElement.attributes, ['name', attribute]),
                'value',
                null
            );
        } else {
            return segmentationElement;
        }
    }

    getKpi(kpiId, attribute?: any) {
        const kpi = _.find(
            _.get(this.internalAnyPageDefinition, 'report.kpiDefinition.kpi'),
            ['id', kpiId]
        );
        if (kpi && attribute) {
            return _.get(kpi, attribute);
        }
        return kpi || {};
    }

    findKpi(...predicate: (() => boolean)[]) {
        let chain: any = _(
            _.get(this.internalAnyPageDefinition, 'report.kpiDefinition.kpi')
        );
        _.forEach(predicate, filter => {
            chain = chain.filter(filter);
        });
        return chain.value();
    }

    getKpiGroupsWithKpis(kpiList): { [key: string]: { title: string; kpis: any[] } } {
        const result = {};
        kpiList = kpiList.concat(['gap']);
        _.forEach(
            _.get(this.internalAnyPageDefinition, 'report.kpiDefinition.kpiGroup', {}),
            value => {
                const title = value.title;

                result[title] = {
                    title,
                    kpis: _.filter(
                        value.kpis,
                        kpi => kpiList.indexOf(kpi) >= 0 || kpi.indexOf('gap') >= 0
                    )
                };
            }
        );

        return result;
    }

    getAllKpisSorted(): any[] {
        const groups = _.get(
            this.internalAnyPageDefinition,
            'report.kpiDefinition.kpiGroup'
        );
        return _.flatten(_.map(groups, group => _.get(group, 'kpis')));
    }

    /**
     * Returns kpis in same order as defined in kpiGroup definition
     */
    getKpiDefinitionsForLevel(level, attribute) {
        const kpis =
            _.get(
                this.internalAnyPageDefinition,
                ['report', 'kpiDefinition', 'levels', level],
                {}
            )[attribute] || {};
        const allSortedKpis = this.getAllKpisSorted();
        const diff = _.difference(kpis, allSortedKpis); // store rank kpis
        return _.get(this.internalAnyPageDefinition, 'report.kpiDefinition.kpiGroup')
            ? _.filter(allSortedKpis, kpi => _.indexOf(kpis, kpi) > -1).concat(diff)
            : kpis;
    }

    getBaseKPI(kpiId: () => boolean): any {
        const kpi = this.findKpi(kpiId);
        if (
            kpi.hasOwnProperty('calculation') &&
            kpi.hasOwnProperty('filterType') &&
            _.includes(kpi.filterType, 'contextPercent')
        ) {
            return _.result(
                _.find(kpi.calculation.attributes, ['name', 'kpiBase']),
                'value'
            );
        }
        return kpiId;
    }

    getHeaderAttributeValue(listName, attributeName) {
        const elements = _.get(
            this.internalAnyPageDefinition.report.interactiveHeader,
            listName,
            []
        );
        let returnValue = null;
        elements.forEach(element => {
            const attributes = _.get(element, 'attributes', []);
            attributes.forEach(attr => {
                if (_.get(attr, 'name', '') === attributeName) {
                    returnValue = attr.value;
                }
            });
        });
        return returnValue;
    }

    getMaxElementsForReport(): number {
        let result = (
            _.find(this.internalAnyPageDefinition.report.attributes, [
                'name',
                'maxElements'
            ]) || {
                value: 10
            }
        ).value;
        if (Array.isArray(result)) {
            throw new Error(
                `Expected report.attributes.name[maxElements] to be an integer, but it was an array\n${JSON.stringify(
                    this.internalAnyPageDefinition,
                    null,
                    2
                )}`
            );
        }
        if (typeof result !== 'number') {
            result = Number.parseInt(result, 10);
        }
        return result;
    }

    getDefaultDimensions(drillLevel): VizSettings {
        const report = this.internalAnyPageDefinition.report;

        const levelKeys = Object.keys(
            _.get(
                this.internalAnyPageDefinition,
                ['report', 'kpiDefinition', 'levels'],
                {}
            )
        );
        const key =
            levelKeys && levelKeys.indexOf(drillLevel.level) > -1
                ? drillLevel.level
                : drillLevel.dataLevel;

        const vizSettings: VizSettings = {
            kpis: this.getKpiDefinitionsForLevel(key, 'default'),
            channels: report.channels.default,
            channelsAll: report.channels.all,
            posTypes: report.posTypes.default
        };

        return vizSettings;
    }
}
