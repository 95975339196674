import {
    ChangeDetectionStrategy,
    Component,
    Output,
    EventEmitter,
    HostListener
} from '@angular/core';

@Component({
    selector: 'gfk-fair-share-infobox',
    templateUrl: './fair-share-infobox.component.html',
    styleUrls: ['./fair-share-infobox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareInfoboxComponent {
    @Output() close = new EventEmitter<void>();

    @HostListener('click', ['$event'])
    public ignoreInsideClick(evt: MouseEvent) {
        this.close.emit();
        evt.stopPropagation();
    }
}
