import { Component, OnInit, Input } from '@angular/core';

import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { ReportFavouriteModelService } from 'insightui.core/services/reportFavouriteModel.service';

@Component({
    selector: 'gfk-select-favourite',
    templateUrl: './select-favourite.component.html',
    styleUrls: ['./select-favourite.component.scss']
})
export class SelectFavouriteComponent implements OnInit {

    @Input()
    favourites: any[];

    @Input()
    favouriteResolved: any;

    @Input()
    chapter: any;

    @Input()
    docId: any;

    constructor(private matomoTrackerService: AnalyticsService,
        private reportFavouriteModelService: ReportFavouriteModelService) {
    }

    ngOnInit() {
    }

    click() {
        this.chapter.show = !this.chapter.show;
        this.reportFavouriteModelService.favouritesOpened.emit(this.chapter);
    }

    open() {
        try {
            this.matomoTrackerService.trackEvent(
                'Navigation',
                'OpenReportFromLandingPageFavorite',
                this.favouriteResolved
            );
        } catch (e) {

        }

        this.chapter.show = false;
    };

    getLink(docId, favId) {
        return preserveClientId(`favorite/${favId}?docId=${docId}`);
    };
}
