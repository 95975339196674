<div class="kpi-settings uitest-Div-KS-Container">
    <div
        class="report-settings-container-elements-title  uitest-Div-KS-HideKSContainer"
        (click)="onHide()"
    >
        <span class="report-settings-container-elements-title-icon"></span>
        <span class="report-settings-container-elements-title-text">
            {{ settingsTitle }}
        </span>
    </div>
    <div class="report-settings-container-settings-element">
        <div *ngFor="let kpiGroup of kpiGroups">
            <div class="report-settings-menu-list-title">
                {{ kpiGroup.title }}
            </div>
            <div
                *ngFor="let kpi of kpiGroup.values"
                [ngClass]="{ 'disabled-div': kpi.disabled }"
                class="report-settings-menu-list checkbox"
            >
                <div *ngIf="kpi.isGap" style="height: 16px;"></div>
                <input
                    *ngIf="!kpi.isGap"
                    class="uitest-Chb-KS-CheckKpi-{{ kpi.title | noSpace }}"
                    type="checkbox"
                    name="{{ kpi.id }}"
                    id="{{ kpi.id }}"
                    style="vertical-align:middle;margin-right:10px;"
                    [ngClass]="{
                        'uitest-Chb-KS-KpiSelected': kpi.checked,
                        'uiTest-state-selected': kpi.checked,
                        'uiTest-state-disabled': kpi.disabled
                    }"
                    [disabled]="kpi.disabled"
                    [checked]="kpi.checked"
                    (click)="onKpiChanged(kpi.id); $event.stopPropagation()"
                />
                <label *ngIf="!kpi.isGap" for="{{ kpi.id }}" class="kpi-title">
                    {{ kpi.title }}
                </label>
                <div *ngIf="!kpi.isGap" class="tooltip kpi-tooltip">
                    {{ kpi.tooltipText }}
                </div>
            </div>
            <!--
                This is a hack to avoid a "jumping" menu when you scrolled to the end and the tooltip appears.
                The tooltip extends the report-settings-container-settins-element container to an unknown amount
                of pixels, to that under some circumstances the container will scroll further downward and the
                mouse leaves the div, the tooltip disappears, the container will shrink, the mouse will enter
                the div, the tooltip will appear, the container will extend, and so on...

                Adding whitespace at the and of the container avoids this issue, but is only a hack...
            -->
            <div style="height: 16px;"></div>
        </div>
    </div>
    <div
        (click)="onBackToDefault()"
        class="report-settings-menu-backtodefault uitest-Div-KS-BackToDefault"
    >
        Back to default
    </div>
</div>
