import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { tap, finalize } from 'rxjs/operators';
import { LogService, ILogger } from 'insightui.core/services/logging';

@Injectable()
export class TraceIdInterceptor implements HttpInterceptor {
    private logger: ILogger;

    constructor(logService: LogService) {
        this.logger = logService.getLogger('TraceIdInterceptor');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // create B3-conform ids (https://github.com/openzipkin/b3-propagation)
        const traceId = uuidv4().replace(/-/g, '');
        const spanId = uuidv4()
            .replace(/-/g, '')
            .substring(0, 16);

        request = request.clone({
            setHeaders: {
                // we have to set both headers here (for Spring Cloud Sleuth to work)
                'X-B3-TraceId': traceId,
                'X-B3-SpanId': spanId
            }
        });

        this.logger.debug('Starting request', traceId);

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        this.logger.debug('Successfully finished request', traceId);
                    }
                },
                (event: HttpErrorResponse) => {
                    if (event instanceof HttpErrorResponse) {
                        this.logger.error('Finished this request with error', traceId);
                    }
                }
            ),
            finalize(() => {
                this.logger.debug('The request ended', traceId);
            })
        );
    }
}
