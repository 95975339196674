// Function defined for Bug Fix for NEO-18981
/**
 * Custom sort by title for ranged values, else sort by id
 *
 * @param item1 item within feature value array
 * @param item2 subsequent item within the feature value array
 * @returns {number|number} return whether to move the value 1 step to higher position (1)
 * or lower position (-1) (for numeric range)
 * or return 0 if it's not numeric data type
 */
export function rangeValueSorting(item1, item2): number {
    if(!isValuesId(item1, item2)){
        return sortRangedValues(item1.title, item2.title);
    }
    // If a predicate cannot determine the relative order of two items, the next predicate is used as a tie-breaker
    return 0;
}

/**
 * Non ranged items have type of number following the actual title.
 *
 * For example item1:
 * {"value":"FA FRONT","type":"string","index":1}
 * {"value":1,"type":"number","index":1}
 *
 * @param item1 title value for item 1
 * @param item2 title value for item 2
 * @returns {boolean} when both item data type are numeric, return true, else false
 */
function isValuesId(item1, item2): boolean {
    return item1.type === "number" && item2.type === "number";
}

/**
 * Sorting Logic via symbol (occurs when both numeric in item value is the same)
 * @param item1Val value for item
 * @param item2Val value for the subsequent item
 * @returns {number} return whether to move the value 1 step to higher position or lower position
 */
function sortBySymbol(item1Val, item2Val): number {
    for(const symbol of rangedValueDeterminants){
        if(item1Val.substring(0, 2) === symbol){
            return -1;
        }
        if(item2Val.substring(0, 2) === symbol){
            return 1;
        }
    }
}

/**
 * Defining an ordered way for the range values symbols for sorting algorithm
 * @type {string[]} array containing the default sorting order standard for the range symbols
 */
const rangedValueDeterminants: string[] = ['< ', '<=', '>=', '> '];

/**
 * Sorting logic for the ranged values
 * 1. Sorting logic when the value of the item is TOTAL/OTHERS
 * 2. Sorting logic when the value of the item is range numeric
 * @param item1Val value of item within the feature value array
 * @param item2Val value of the subsequent item within the feature value array
 * @returns {number|number} return whether to move the value 1 step to higher position or lower position
 */
function sortRangedValues(item1Val, item2Val): number {
    // added checking for handling undefined value
    if (typeof item1Val == "undefined" && typeof item2Val == "undefined") return 0;
    // Sorting Logic for range numeric
    const firstNumberInTitle1Str = Number(item1Val.match(/\d+/));
    const firstNumberInTitle2Str = Number(item2Val.match(/\d+/));
    // When the numeric range is the same, sort via the range symbol order eg. [<, <=, >=, >]
    if(firstNumberInTitle1Str === firstNumberInTitle2Str) {
        return sortBySymbol(item1Val, item2Val);
    }
    return firstNumberInTitle1Str < firstNumberInTitle2Str ? -1 : 1;
}
