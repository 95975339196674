import { HttpErrorResponse } from '@angular/common/http';
import { FavouriteDto } from '../models/favourite-dto';

export class FmLoadFavourites {
    static readonly type = '[FM] LoadFavourites';
}

export class FmLoadFavouritesSuccess {
    static readonly type = '[FM] LoadFavouritesSuccess';
    constructor(public readonly favourites: FavouriteDto[]) {}
}

export class FmLoadFavouritesFailure {
    static readonly type = '[FM] LoadFavouritesFailure';

    constructor(public readonly error: HttpErrorResponse) {}
}

export class FmUpdateFavouritesSuccess {
    static readonly type = '[FM] UpdateFavouritesSuccess';
}

export class FmUpdateFavouritesFailure {
    static readonly type = '[FM] UpdateFavouritesFailure';

    constructor(public readonly error: HttpErrorResponse) {}
}
