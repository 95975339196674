import {
    ColumnType,
    DataRow
} from 'insightui.table/components/datatable/shared/models/datatable.models';
import { DatatableSearchResult } from 'insightui.table/services/table.data';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SearchDatatableService {
    private reportingDataSet: DataRow[] = [];
    private reportingSubTotalData: DataRow;
    private reportingDataSetCache: DataRow[] = [];
    private reportingSubTotalDataCache: DataRow;
    private searchStr = '';

    onDatatableSearched$ = new EventEmitter<string>();

    private static escapeRegExp(str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    }

    public setSearchData(pReportingDataSet, pReportingSubTotalData) {
        this.reportingDataSet = pReportingDataSet;
        this.reportingSubTotalData = pReportingSubTotalData;

        this.reportingDataSetCache = pReportingDataSet.slice();
        this.reportingSubTotalDataCache = pReportingSubTotalData;
    }

    public searchData(pSearchStr, pHeaderConfig): DatatableSearchResult {
        let datatableSearchResult: DatatableSearchResult = {
            reportingDataSet: [],
            reportingSubTotalData: {}
        };

        this.searchStr = pSearchStr;

        if (pSearchStr === '') {
            datatableSearchResult.reportingDataSet = this.reportingDataSetCache.slice();
            datatableSearchResult.reportingSubTotalData = this.reportingSubTotalDataCache;
            return datatableSearchResult;
        }
        let searchSideHeaders = [];
        let searchedReportingDataSet: DataRow[] = [];
        pHeaderConfig.forEach(columnHeader => {
            if (columnHeader.type === ColumnType.SIDE_HEADER) {
                searchSideHeaders.push(columnHeader.field);
            }
        });

        const pattern = SearchDatatableService.escapeRegExp(pSearchStr);
        this.reportingDataSetCache.forEach(dataRow => {
            searchSideHeaders.forEach(searchElement => {
                if (String(dataRow[searchElement]).match(new RegExp(pattern, 'i'))) {
                    if (!searchedReportingDataSet.some(x => x === dataRow)) {
                        searchedReportingDataSet.push(dataRow);
                    }
                }
            });
        });

        datatableSearchResult.reportingDataSet = searchedReportingDataSet;
        datatableSearchResult.reportingSubTotalData = undefined;

        this.onDatatableSearched$.emit(pattern);

        return datatableSearchResult;
    }

    getSearchString(): string {
        return this.searchStr;
    }
}
