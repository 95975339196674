import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'gfk-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent {
    @Input()
    message: string;
}
