import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { ModalButtonComponent } from './modal-button/modal-button.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { ModalCloserComponent } from './modal-closer/modal-closer.component';
import { DropDownModule } from '..';

@NgModule({
    declarations: [
        ModalHeaderComponent,
        ModalButtonComponent,
        ModalFooterComponent,
        ModalCloserComponent
    ],
    imports: [CommonModule, DropDownModule],
    exports: [
        ModalButtonComponent,
        ModalHeaderComponent,
        ModalFooterComponent,
        ModalCloserComponent
    ]
})
export class ModalDialogModule {}
