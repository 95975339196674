import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { CsLoadClientInfos } from 'insightui.client-switch/state/client-switch.actions';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { PageDefinitionLoaderService } from 'insightui.page-definition/services/page-definition-loader.service';

@Injectable({
    providedIn: 'root'
})
export class GeneralPageResolver implements Resolve<void> {
    constructor(
        private revisionService: RevisionService,
        private pageDefinitionLoaderService: PageDefinitionLoaderService,
        private store: Store
    ) {}

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<void> {
        // TODO: merge with same call in LandingPageResolver
        this.store.dispatch(new CsLoadClientInfos());

        this.revisionService.loadRevisions();
        this.pageDefinitionLoaderService.loadKpis();
        this.pageDefinitionLoaderService.loadLabels();
        this.pageDefinitionLoaderService.loadIndex();
    }
}
