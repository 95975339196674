import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { STATE_REDUCER } from 'projects/insightui/src/app/state/state.module';
import { PageIndexReducer } from './page-index.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [{ provide: STATE_REDUCER, useClass: PageIndexReducer, multi: true }]
})
export class PageDefinitionModule {}
