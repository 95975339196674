import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { CollectionListItemComponent } from './collectionListItem/collectionListItem.component';
import { FavouriteResourceService } from './favouriteResourceService/favouriteResource.service';
import { AddToCollectionsButtonComponent } from './addToCollectionsButton.component';
import { AddToCollectionDialogComponent } from './add-to-collection-dialog/add-to-collection-dialog.component';
import { ModalDialogModule } from 'insightui.common-components/modal-dialog/modal-dialog.module';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
    declarations: [
        AddToCollectionsButtonComponent,
        CollectionListItemComponent,
        AddToCollectionDialogComponent
    ],
    imports: [
        CommonModule,
        ModalDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        ToastrModule
    ],
    providers: [FavouriteResourceService],
    exports: [AddToCollectionsButtonComponent]
})
export class AddToCollectionButtonModule {}
