import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

@Injectable()
export class FavouriteResourceService {
    private ng1FavouriteResource;

    constructor(injector: AngularInjectorResolver) {
        injector
            .get()
            .subscribe(
                $injector =>
                    (this.ng1FavouriteResource = $injector.get('FavouriteResource'))
            );
    }

    /**
     * getNewFavouriteResource will call the AngularJS service
     * to get the current settings of a report.
     *
     * @returns {Object}
     * @memberof FavouriteResourceService
     */
    getNewFavouriteResource(): Object {
        return new this.ng1FavouriteResource().loadNewFavorite();
    }

    /**
     * getFlattenedReportSettings is returning a flat representation of
     * the current report settings.
     *
     * @returns {{ [s: string]: string }}
     * @memberof FavouriteResourceService
     */
    getFlattenedReportSettings(): { [s: string]: string } {
        const favorite = new this.ng1FavouriteResource().loadNewFavorite();
        return this.flatten(favorite.reportSettings);
    }

    /**
     * Flatten Method to transform an object into a
     * single depth object, composed of string key
     * value pairs.
     *
     * Inspired by {@link https://gist.github.com/penguinboy/762197#gistcomment-2083577 GitHub}
     *
     * @param {Object} object a regular object
     * @param {string} [separator='.'] seperator
     * @param {number} [maxLevel=0] the maximum depth of flattening
     * @returns {{ [s: string]: string }}
     * @memberof FavouriteResourceService
     */
    flatten(
        object: Object,
        separator: string = '.',
        maxLevel: number = 0
    ): { [s: string]: string } {
        return Object.assign(
            {},
            ...(function _flatten(child, path = [], iterator = 0) {
                return [].concat(
                    ...Object.keys(child).map(key => {
                        if (typeof child[key] === 'object' && iterator <= maxLevel) {
                            const newIterator = iterator + 1;
                            return _flatten(child[key], path.concat([key]), newIterator);
                        } else {
                            if (
                                typeof child[key] === 'string' ||
                                typeof child[key] === 'boolean'
                            ) {
                                return {
                                    [path.concat([key]).join(separator)]: child[key]
                                };
                            }
                            return {
                                [path.concat([key]).join(separator)]: JSON.stringify(
                                    child[key]
                                )
                            };
                        }
                    })
                );
            })(object)
        );
    }
}
