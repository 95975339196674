import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownDirective, WrapperComponent } from './drop-down.directive';
import { ArrowPanelModule } from '../arrow-panel/arrow-panel.module';

@NgModule({
    declarations: [DropDownDirective, WrapperComponent],
    imports: [CommonModule, ArrowPanelModule],
    exports: [DropDownDirective]
})
export class DropDownModule {}
