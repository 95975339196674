<div class="table-container">
    <div class="table-container-inner">
        <table class="report-table table-header-fixed" aria-hidden="true">
            <thead>
                <tr class="report-table__header">
                    <th scope="col">
                        <span>
                            <gfk-fair-share-drill-button
                                *ngIf="!!totalRow && totalRow.isDrillable"
                                [isActive]="totalRow.isDrillable"
                                (drill)="handleDrillUp()"
                            >
                                <span class="fa fa-level-up fa-flip-horizontal"></span>
                                {{ drillTargetName }}
                            </gfk-fair-share-drill-button>
                        </span>
                    </th>
                    <th scope="col">
                        <span>Sales (Value) </span>
                    </th>
                    <th scope="col">
                        <span>Fair Share Value</span>
                    </th>
                    <th scope="col"><span></span></th>
                    <th scope="col" class="hover-trigger">
                        <span>
                            Gap Value
                            <i
                                data-uitest="iconTooltipValue"
                                [gfkDropDown]="tooltipValue"
                                [gfkDropDownPosition]="preferredInfoboxPosition"
                                #infoboxValue="gfkDropDown"
                                class="c-icon c-icon--info-circle"
                            ></i
                        ></span>
                    </th>
                    <th scope="col" *ngIf="hasReachedValueGrowthRangeBounds.min">
                        <span></span>
                    </th>
                    <th scope="col">
                        <span class="cell--no-padding">
                            Value Growth Rate (vs. Value)</span
                        >
                    </th>
                    <th scope="col" *ngIf="hasReachedValueGrowthRangeBounds.max">
                        <span></span>
                    </th>
                    <th scope="col"><span></span></th>
                    <th scope="col"><span></span></th>
                    <th scope="col" class="hover-trigger">
                        <span>
                            Gap Value
                            <i
                                data-uitest="iconTooltipGrowth"
                                [gfkDropDown]="tooltipGrowth"
                                [gfkDropDownPosition]="preferredInfoboxPosition"
                                #infoboxGrowth="gfkDropDown"
                                class="c-icon c-icon--info-circle"
                            ></i>
                        </span>
                    </th>
                </tr>
                <tr class="report-table__subheader">
                    <th scope="col"><span></span></th>
                    <th scope="col"><span></span></th>
                    <th scope="col">
                        <span>
                            <gfk-fair-share-legend
                                [lineValue]="shareLineValue"
                                [minValue]="0"
                                [maxValue]="maxFairShareValue"
                            ></gfk-fair-share-legend
                        ></span>
                    </th>
                    <th scope="col"><span></span></th>
                    <th scope="col"><span></span></th>
                    <th scope="col" *ngIf="hasReachedValueGrowthRangeBounds.min">
                        <span></span>
                    </th>
                    <th scope="col">
                        <span class="cell--no-padding">
                            <gfk-fair-share-legend
                                [lineValue]="0"
                                [minValue]="valueGrowthRangeBounds.min"
                                [maxValue]="valueGrowthRangeBounds.max"
                            ></gfk-fair-share-legend>
                        </span>
                    </th>
                    <th scope="col" *ngIf="hasReachedValueGrowthRangeBounds.max">
                        <span></span>
                    </th>
                    <th scope="col">
                        <span>
                            <div
                                class="report-table__legend report-table__legend--retailer"
                            >
                                Retailer
                            </div></span
                        >
                    </th>
                    <th scope="col">
                        <span>
                            <div class="report-table__legend">
                                Market
                            </div>
                        </span>
                    </th>
                    <th scope="col"><span></span></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="!!totalRow" class="report-table__total">
                    <td>
                        <gfk-fair-share-drill-button
                            [isActive]="totalRow.isDrillable"
                            (drill)="handleDrillUp()"
                            [bold]="true"
                        >
                            {{ totalRow.title }}
                        </gfk-fair-share-drill-button>
                    </td>
                    <td class="cell--align-right cell--bold">
                        {{ totalRow.marketSizeValue | number: '1.0-0' | gfkDashIfEmpty }}
                    </td>
                    <td class="cell--position-relative">
                        <gfk-fair-share-bar-chart
                            [currentValuePercent]="totalRow.fairShareValue"
                            [maxValuePercent]="maxFairShareValue"
                            [lineValuePercent]="shareLineValue"
                            [showGapValueHover]="false"
                            [useDarkColor]="true"
                        >
                        </gfk-fair-share-bar-chart>
                    </td>
                    <td class="cell--bold">
                        {{
                            totalRow.fairShareValue
                                | number: '1.0-1'
                                | gfkWithSuffix: '%'
                                | gfkDashIfEmpty
                        }}
                    </td>
                    <td
                        class="cell--align-right cell--bold"
                        [class.cell--padding-right]="
                            !hasReachedValueGrowthRangeBounds.min
                        "
                    >
                        -
                    </td>
                    <td *ngIf="hasReachedValueGrowthRangeBounds.min">
                        <div
                            class="comparison-bar-chart-extension--left"
                            [class.comparison-bar-chart-extension--negative]="
                                totalRow.valueGrowthRangeBoundsStatus.min
                            "
                            [class.comparison-bar-chart-extension--negative-dark]="
                                totalRow.valueGrowthRangeBoundsStatus.min
                            "
                        ></div>
                    </td>
                    <td class="cell--no-padding cell--position-relative">
                        <gfk-fair-share-comparison-bar-chart
                            [gapValuePercent]="totalRow.valueGrowthValueRetailer"
                            [comparisonPercent]="totalRow.valueGrowthValuePanelMarket"
                            [minValuePercent]="valueGrowthRangeBounds.min"
                            [maxValuePercent]="valueGrowthRangeBounds.max"
                            [showGapValueHover]="totalRow.isGrowthValueHovering"
                            [gapValue]="totalRow.valueGrowthGapValue"
                            [useDarkColor]="true"
                            (gapValueHover)="
                                handleGrowthGapValueHovering(totalRow, $event)
                            "
                        >
                        </gfk-fair-share-comparison-bar-chart>
                    </td>
                    <td
                        *ngIf="hasReachedValueGrowthRangeBounds.max"
                        class="cell--no-padding"
                    >
                        <div
                            class="comparison-bar-chart-extension--right"
                            [class.comparison-bar-chart-extension--positive]="
                                totalRow.valueGrowthRangeBoundsStatus.max
                            "
                            [class.comparison-bar-chart-extension--positive-dark]="
                                totalRow.valueGrowthRangeBoundsStatus.max
                            "
                        ></div>
                    </td>
                    <td>
                        <gfk-fair-share-growth-value
                            [growth]="totalRow.valueGrowthValueRetailer"
                            [bold]="true"
                        ></gfk-fair-share-growth-value>
                    </td>
                    <td>
                        <gfk-fair-share-growth-value
                            [growth]="totalRow.valueGrowthValuePanelMarket"
                            [bold]="true"
                        ></gfk-fair-share-growth-value>
                    </td>
                    <td
                        class="cell--align-right cell--bold"
                        (mouseenter)="handleGrowthGapValueHovering(totalRow, 'enter')"
                        (mouseleave)="handleGrowthGapValueHovering(totalRow, 'leave')"
                    >
                        {{
                            totalRow.valueGrowthGapValue
                                | number: '1.0-0'
                                | gfkDashIfEmpty
                        }}
                    </td>
                </tr>
                <tr *ngFor="let row of rows; trackBy: trackByRow">
                    <td>
                        <gfk-fair-share-drill-button
                            [isActive]="row.isDrillable"
                            (drill)="handleDrillDown(row.$rowId)"
                        >
                            {{ row.title }}
                        </gfk-fair-share-drill-button>
                    </td>
                    <td class="cell--align-right" style="min-width:7.5em">
                        {{ row.marketSizeValue | number: '1.0-0' | gfkDashIfEmpty }}
                    </td>
                    <td class="cell--full-width cell--position-relative">
                        <gfk-fair-share-bar-chart
                            [currentValuePercent]="row.fairShareValue"
                            [maxValuePercent]="maxFairShareValue"
                            [lineValuePercent]="shareLineValue"
                            (gapValueHover)="handleFairShareGapValueHovering(row, $event)"
                            [showGapValueHover]="row.isGapValueHovering"
                            [gapValue]="row.marketSizeGapValue"
                        >
                        </gfk-fair-share-bar-chart>
                    </td>
                    <td>
                        {{
                            row.fairShareValue
                                | number: '1.0-1'
                                | gfkWithSuffix: '%'
                                | gfkDashIfEmpty
                        }}
                    </td>
                    <td
                        class="cell--align-right"
                        [class.cell--padding-right]="
                            !hasReachedValueGrowthRangeBounds.min
                        "
                        [class.cell--bold]="row.marketSizeGapValue > 0"
                        (mouseenter)="handleFairShareGapValueHovering(row, 'enter')"
                        (mouseleave)="handleFairShareGapValueHovering(row, 'leave')"
                        style="min-width:7.5em"
                    >
                        {{ row.marketSizeGapValue | number: '1.0-0' | gfkDashIfEmpty }}
                    </td>
                    <td *ngIf="hasReachedValueGrowthRangeBounds.min">
                        <!-- growth rate minus -->
                        <div
                            class="comparison-bar-chart-extension--left"
                            [class.comparison-bar-chart-extension--negative]="
                                row.valueGrowthRangeBoundsStatus.min
                            "
                        ></div>
                    </td>
                    <td class="cell--full-width cell--no-padding cell--position-relative">
                        <gfk-fair-share-comparison-bar-chart
                            [gapValuePercent]="row.valueGrowthValueRetailer"
                            [comparisonPercent]="row.valueGrowthValuePanelMarket"
                            [minValuePercent]="valueGrowthRangeBounds.min"
                            [maxValuePercent]="valueGrowthRangeBounds.max"
                            [showGapValueHover]="row.isGrowthValueHovering"
                            [gapValue]="row.valueGrowthGapValue"
                            (gapValueHover)="handleGrowthGapValueHovering(row, $event)"
                        >
                        </gfk-fair-share-comparison-bar-chart>
                    </td>
                    <td
                        *ngIf="hasReachedValueGrowthRangeBounds.max"
                        class="cell--no-padding"
                    >
                        <!-- growth rate plus -->
                        <div
                            class="comparison-bar-chart-extension--right"
                            [class.comparison-bar-chart-extension--positive]="
                                row.valueGrowthRangeBoundsStatus.max
                            "
                        ></div>
                    </td>
                    <td>
                        <gfk-fair-share-growth-value
                            [growth]="row.valueGrowthValueRetailer"
                        ></gfk-fair-share-growth-value>
                    </td>

                    <td>
                        <gfk-fair-share-growth-value
                            [growth]="row.valueGrowthValuePanelMarket"
                        ></gfk-fair-share-growth-value>
                    </td>
                    <td
                        class="cell--align-right"
                        (mouseenter)="handleGrowthGapValueHovering(row, 'enter')"
                        (mouseleave)="handleGrowthGapValueHovering(row, 'leave')"
                        [class.cell--bold]="row.valueGrowthGapValue > 0"
                        style="min-width:8.5em"
                    >
                        {{ row.valueGrowthGapValue | number: '1.0-0' | gfkDashIfEmpty }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #tooltipValue>
    <gfk-fair-share-infobox data-uitest="infoboxValue" (close)="infoboxValue.close()">
        <h3>
            Gap Value of Fair Share Value
        </h3>
        <img src="assets/images/fair-share_illustration.png" alt="Fair Share Average" />
        <p>
            This KPI shows the absolute value of the gap to the category average share.
        </p>
        <p>
            Expressed as: Local Currency (LC), Euro or USD amount. Expressed as an
            absolute number.
        </p>
        <p>
            E.g.: Retailer A has a market share in Smart-/Mobile Phones + Phablets of 10%.
            Within brand Samsung, Retailer A has a market share of 4%. The Gap Value of
            Fair Share Value is thereby 6% expressed in total Euros. So it answers: how
            much more/less would Retailer A have sold, if they'd have sold Samsung on
            their market average.
        </p>
    </gfk-fair-share-infobox>
</ng-template>

<ng-template #tooltipGrowth>
    <gfk-fair-share-infobox data-uitest="infoboxGrowth" (close)="infoboxGrowth.close()">
        <h3>
            Fair Share Gap Value (Growth)
        </h3>
        <img src="assets/images/fair-share_illustration2.png" alt="Fair Share Average" />
        <p>
            This KPI shows the absolute value of the gap between the relative growth of
            the retailer in relation to the market overall.
        </p>
        <p>
            Expressed as: An absolute number in Local Currency (LC), Euro or USD amount.
        </p>
        <p>
            E.g.: Brand A within the market is in growth by 5% year on year. Sales of
            Brand A by the retailer are 2% year on year. The Gap Value represents in
            absolute terms the additional value which would have been generated if the
            retailer had matched market performance and grown by an additional 3%.
        </p>
    </gfk-fair-share-infobox>
</ng-template>
