import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { CountryFilterService } from 'insightui.core/services/countryFilter.service';

@Injectable({
  providedIn: 'root'
})
export class ReportFavouriteGroupingService {

    RS_VC_COUNTRY_CODE = 'reportSettings.visualizationContext.countryCode';

    constructor(private countryFilterService: CountryFilterService) {}

    groupByPageId(reportFavourites) {
        const countryCode = this.countryFilterService.getSelected();

        const favo = _.groupBy(
            _.filter(reportFavourites, (fav) => {
                return _.get(fav, this.RS_VC_COUNTRY_CODE) === countryCode && fav.isActive();
            }),
            (fav) => {
                return _.get(fav, 'reportSettings.visualizationContext.page.id');
            }
        );

        return favo;
    };
}
