<div
    *ngIf="supported"
    [ngStyle]="{ height: collapsed ? 'inherit' : '100%' }"
    style="font-size: 13px"
    #outerContainer
>
    <div class="panel-heading no-padding">
        <div
            class="settings-container-elements-title uitest-Div-SC-RetailerBenchmarkComparison"
            (click)="onCollapse()"
        >
            <span
                [ngClass]="{
                    'settings-container-elements-title-icon-back': !collapsed,
                    'settings-container-elements-title-icon': collapsed
                }"
            ></span>
            <span class="settings-container-elements-title-text">
                Retailer Benchmark Comparison
            </span>
        </div>
    </div>
    <div
        class="panel-body"
        [style.height.px]="getPanelHeight()"
        style="overflow-y:auto"
        *ngIf="!collapsed"
    >
        <div class="ui-setting-container-row uitest-Div-US-container-row1">
            <div class="ui-setting-container-row-title ng-binding">
                Set Threshold against Market / Channel:
            </div>

            <div *ngFor="let option of comparisonOptions?.data" class="radio">
                <input
                    type="radio"
                    id="rb_setting_name_{{ option.id }}"
                    name="rb_setting_name"
                    class="uiTest-comparison-channel"
                    (change)="selectChannel(option)"
                    [checked]="option.selected"
                    [disabled]="option.disabled"
                />
                <label
                    [ngClass]="{ 'disabled-div': option.disabled }"
                    for="rb_setting_name_{{ option.id }}"
                    >{{ option.title }}
                </label>
            </div>
        </div>

        <div style="margin-bottom: 0px; margin-right: 15px">
            <span class="ui-setting-images-mode-good uitest-Img-US-mode-good">
                <input-spinner-v2
                    [step]="0.1"
                    (onUpdate)="positiveThreshold.value = $event"
                    [value]="positiveThreshold.value"
                    [max]="positiveThreshold.max"
                    [min]="positiveThreshold.min"
                    [displayFormat]="performanceHigherFormat"
                ></input-spinner-v2>
            </span>
            <div class="ui-setting-slider ui-setting-row-slider">
                <slider-component
                    [(value)]="positiveThreshold.value"
                    step="0.1"
                    scaleStep="1"
                    color="#238B45"
                    [range]="[positiveThreshold.min, positiveThreshold.max]"
                >
                </slider-component>
            </div>
        </div>
        <div style="margin-bottom: 0px; margin-right: 15px">
            <span class="ui-setting-images-mode-bad uitest-Img-US-mode-bad">
                <input-spinner-v2
                    [step]="0.1"
                    (onUpdate)="negativeThreshold.value = $event"
                    [value]="negativeThreshold.value"
                    [min]="negativeThreshold.min"
                    [max]="negativeThreshold.max"
                    [displayFormat]="performanceLowerFormat"
                ></input-spinner-v2>
                <div class="ui-setting-slider ui-setting-row-slider">
                    <slider-component
                        [(value)]="negativeThreshold.value"
                        step="0.1"
                        scaleStep="1"
                        color="#767E94"
                        [range]="[negativeThreshold.min, negativeThreshold.max]"
                    >
                    </slider-component>
                </div>
            </span>
        </div>

        <div
            style="position:absolute; bottom:20px; left:20px; right: 20px;"
            #footerContainer
        >
            <div
                (click)="reset()"
                class="report-settings-menu-backtodefault threshold-settings-back-to-default"
            >
                Back to default
            </div>
            <div style="width:100%">
                <div
                    class="header-container-apply settings-container-apply uitest-Div-HC-Apply-"
                    (click)="apply()"
                >
                    APPLY SELECTION
                </div>
            </div>
        </div>
    </div>
</div>
