import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { PageDefinitionModel } from 'insightui.page-definition/page-definition.model';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { Page } from 'insightui.page-definition/page-definition.types';
import { VisualizationContextState } from 'insightui.vc/visualization-context.state';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, withLatestFrom, tap } from 'rxjs/operators';
import { BusinessQuestionSubEntry } from '../business-question/business-question.component';
import { LegacyNotificationService } from './legacy-notification.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { VcFavorite } from 'insightui.vc/visualization-context.types';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { AccessControlService } from 'insightui.metadata/services/access-control.service';

/**
 * This is basically the BusinessQuestionMenuDirective.js ported to an Angular Service.
 */
@Injectable()
export class BusinessQuestionService {
    reportName$!: Observable<string | undefined>;

    @Select(VisualizationContextState.favorite)
    favorite$!: Observable<VcFavorite | undefined>;

    businessQuestion$: Observable<string | undefined>;

    subEntries$: Observable<BusinessQuestionSubEntry[]>;

    @Select(VisualizationContextState.focusDimension)
    public focusDimension$!: Observable<string | undefined>;

    @Select(VisualizationContextState.segmentationDimension)
    public segmentationDimension$!: Observable<string | undefined>;

    private logger: ILogger;

    private readonly DATATABLE_BUSINESS_QUESTION_TEMPLATE =
        'How does my {{focus}} compare to {{segment}}?';

    constructor(
        logService: LogService,
        private store: Store,
        private pageDefinitionService: PageDefinitionService,
        legacyNotifications: LegacyNotificationService,
        visualizationContextService: VisualizationContextService,
        metadataService: MetadataService,
        accessControlService: AccessControlService
    ) {
        this.logger = logService.getLogger('BusinessQuestionService');
        this.logger.debug('ctor');

        this.subEntries$ = pageDefinitionService.currentPage$.pipe(
            map(currentPage => {
                let chapter = pageDefinitionService.getModuleForPage(currentPage.id);
                // if it's no datatable, simply throw away all pageIds, because
                // for everything except datatable, no subEntries should be shown
                if (currentPage.visualizationType !== 'datatable') {
                    chapter = { ...chapter, pageIds: [] };
                }
                // remove all pageIds which are marked as 'hidden' in the chapter
                const hiddenChapterPages = chapter.hidden || [];
                // remove all pageIds which are not accessible
                const chapterPages = pageDefinitionService.getPages(chapter.pageIds);
                const deliveryInfo = metadataService.getDeliveryInfo();
                const noAccessPages = chapterPages
                    .filter(p => !accessControlService.hasPageAccess(deliveryInfo, p))
                    .map(p => p.id);
                return chapter.pageIds.filter(
                    pId =>
                        !hiddenChapterPages.includes(pId) && !noAccessPages.includes(pId)
                );
            }),
            map(pageIds => pageDefinitionService.getPages(pageIds)),
            // if there is only one element in the array, remove this as well,
            // because this element is the current page.
            map(pages => {
                return pages.length > 1 ? pages : [];
            }),
            map(pages => pages.map(p => ({ pageId: p.id, businessQuestion: p.title })))
        );

        this.businessQuestion$ = combineLatest([
            pageDefinitionService.currentPage$,
            legacyNotifications.somethingChanged$.pipe(startWith(void 0))
        ]).pipe(
            map(x => x[0]), // map the 'combineLatest' back to only the currentPage
            withLatestFrom(this.focusDimension$, this.segmentationDimension$),
            map(([currentPage, focusDimension, segmentationDimension]) => {
                this.logger.debug(` values:`, {
                    currentPage,
                    focusDimension,
                    segmentationDimension
                });
                let result: string;
                if (currentPage.visualizationType === 'datatable') {
                    result = this.generateTitleForDatatable(currentPage);
                } else if (!currentPage.report.businessQuestionTemplate) {
                    result = currentPage.title;
                } else {
                    result = this.generateTitle(
                        currentPage.report.businessQuestionTemplate,
                        'brand mix',
                        focusDimension,
                        segmentationDimension
                    );
                }
                return result;
            }),
            tap(bq => {
                visualizationContextService.set('businessQuestion', bq);
            })
        );

        this.reportName$ = combineLatest([
            pageDefinitionService.currentPage$,
            this.favorite$
        ]).pipe(
            map(([currentPage, favorite]) => {
                if (!!favorite) {
                    return (
                        favorite.name ||
                        pageDefinitionService.getModuleTitleForPage(currentPage.id)
                    );
                } else {
                    return undefined;
                }
            })
        );
    }

    private rememberSplit: string | null = null;
    private previousSplit: string[] | null = null;

    private getActivePageId(pages: Page[], pageIds: string[]): string {
        const splitByList = this.getSplitSettings();
        let splitByPageId = null;
        ['splitByPriceClasses', 'splitByFeatures', 'splitByOnlineOffline'].forEach(
            split => {
                if (splitByList.indexOf(split) > -1) {
                    splitByPageId = this.getPageIdBySplit(pages, split);
                }
            }
        );

        const result = splitByPageId || pageIds[0];
        this.logger.debug(`getActivePageId NEW`, result);
        return result;
    }

    private getPageIdBySplit(pages: Page[], split) {
        const page: Page | undefined = _.find(pages, p => _.get(p, split, false)) as
            | Page
            | undefined;
        return page ? page.id : null;
    }

    private generateTitleForDatatable(page: PageDefinitionModel): string {
        const chapter = this.pageDefinitionService.getModuleForPage(page.id);
        const pageIds = _.reject(chapter.pageIds, pageId => {
            return _.includes(chapter.hidden || [], pageId);
        });
        const pages = this.pageDefinitionService.getPages(pageIds);
        const activePageId = this.getActivePageId(pages, pageIds);

        const splitBy = this.getSplitSettings();
        if (
            splitBy.indexOf('splitByPriceClasses') < 0 ||
            splitBy.indexOf('splitByFeatures') < 0
        ) {
            this.rememberSplit = null;
        }
        if (splitBy.length > 1) {
            let obj = 'default';
            let subj = '';
            if (splitBy.indexOf('splitByOnlineOffline') > -1) {
                obj = 'onlineOffline';
            }
            if (splitBy.indexOf('splitByPriceClasses') > -1) {
                subj = 'priceClass';
            }
            if (splitBy.indexOf('splitByFeatures') > -1) {
                subj = 'feature';
            }

            if (
                splitBy.indexOf('splitByPriceClasses') > -1 &&
                splitBy.indexOf('splitByFeatures') > -1
            ) {
                const previous = this.previousSplit;
                const diff = _.get(_.xor(splitBy, previous), 0);

                subj = diff === 'splitByPriceClasses' ? 'priceClass' : 'feature';

                if (diff !== 'splitByOnlineOffline') {
                    this.rememberSplit = subj;
                }
            }

            if (this.rememberSplit) {
                subj = this.rememberSplit;
            }

            this.previousSplit = splitBy;
            return this.generateTitle(
                this.DATATABLE_BUSINESS_QUESTION_TEMPLATE,
                'assortment mix',
                subj,
                obj
            );
        }

        this.previousSplit = splitBy;
        const activePage = pages.find(x => x.id === activePageId);
        if (!activePage) {
            this.logger.error(
                `Could not find the correct active page, using business question of current page`,
                { pages, activePageId, splitBy }
            );
        }
        return activePage ? activePage.title : page.title;
    }

    private getSplitSettings() {
        const splits = [];
        const vc = _.pick(
            this.store.selectSnapshot(state => state.visualizationContext),
            ['showFeatures', 'showPriceClasses', 'splitByOnlineOffline']
        );

        _.forOwn(vc, (value, key) => {
            if (value && key === 'showFeatures') {
                splits.push('splitByFeatures');
            }
            if (value && key === 'showPriceClasses') {
                splits.push('splitByPriceClasses');
            }
            if (value && key === 'splitByOnlineOffline') {
                splits.push(key);
            }
        });

        return splits;
    }

    private generateTitle(
        template: string,
        subj: string,
        focus: string,
        segment: string
    ) {
        this.logger.debug(`generateTitle`, { subj, focus, segment });
        subj =
            {
                priceClass: 'price mix',
                feature: 'product feature mix'
            }[focus] || subj;

        const obj =
            {
                item: 'the brands',
                priceClass: 'the price classes',
                feature: 'the product features',
                onlineOffline: 'online/offline channels',
                posType: 'POS Type'
            }[segment] || 'the market';

        return this.generateQuestion(template, subj, obj);
    }

    private generateQuestion(
        template?: string,
        focus?: string,
        segment?: string
    ): string | undefined {
        if (!template) {
            return template;
        }

        let result = template;
        if (focus) {
            result = result.replace('{{focus}}', focus);
        }
        if (segment) {
            result = result.replace('{{segment}}', segment);
        }
        return result;
    }
}
