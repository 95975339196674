import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from 'insightui.global/global.module';
import { HtmlElementUnionService } from 'insightui.export/powerpoint/services/html-element-union.service';
import { PowerpointExportSheet } from 'insightui.export/types';
import { HtmlString } from 'insightui.export/types';
import { HeadElementRetrieverService } from 'insightui.export/powerpoint/services/head-element-retriever.service';
import { ExportHeadlineService } from 'insightui.export/services/export-headline.service';
import * as _ from 'lodash';
import { PowerpointInfoRowService } from '../services/powerpoint-info-row.service';

const EXPORT_RECT_SELECTOR = '#datatableWrapper';

@Injectable()
export class TableToSheetService {
    constructor(
        private htmlElementUnionService: HtmlElementUnionService,
        private headElementRetrieverService: HeadElementRetrieverService,
        private exportHeadlineService: ExportHeadlineService,
        private powerpointInfoRowService: PowerpointInfoRowService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    getSheet(tableElement: HTMLElement): PowerpointExportSheet {
        const boundingBox = this.getBoundingBox();

        return {
            html: this.getHtml(tableElement),
            headline: this.exportHeadlineService.getReportHeadline(),
            subheadline: this.exportHeadlineService.getReportSubheadline(),
            width: boundingBox.width,
            height:
                boundingBox.height +
                this.powerpointInfoRowService.getFooterHeight(tableElement)
        };
    }

    private getHtml(tableElement: HTMLElement): HtmlString {
        const head = this.headElementRetrieverService.getHeadElement();

        return `<html class="export-ppt">
                    <head>${head.innerHTML}</head>
                    <body>${tableElement.outerHTML}</body>
                </html>`;
    }

    private getBoundingBox(): ClientRect {
        const exportRectElements = this.document.querySelectorAll(EXPORT_RECT_SELECTOR);
        return this.htmlElementUnionService.boundingBox(
            Array.from(exportRectElements || [])
        );
    }
}
