import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCase',
    pure: false
})
export class CamelCasePipe implements PipeTransform {
    transform(value: string): string {
        // Replace special characters with a space
        value = value.replace(/[^a-zA-Z0-9 ]/g, ' ');

        // put a space before an uppercase letter
        value = value.replace(/([a-z](?=[A-Z]))/g, '$1 ');

        // Lower case first character and some other stuff
        value = value
            .replace(/([^a-zA-Z0-9 ])|^[0-9]+/g, '')
            .trim()
            .toLowerCase();

        // uppercase characters preceded by a space or number
        value = value.replace(/([ 0-9]+)([a-zA-Z])/g, function(a, b, c) {
            return b.trim() + c.toUpperCase();
        });

        return value;
    }
}
