import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CSSColor } from '../../types';

@Component({
    selector: 'legend-label',
    templateUrl: './legendLabel.html'
})
export class LegendLabelComponent {
    /**
     * The color of the box next to the label
     */
    @Input()
    color: CSSColor;

    /**
     * Whether to display the label as active
     */
    @Input()
    active: boolean = true;

    /**
     * The string to display as the label
     */
    @Input()
    label: string;

    @Output()
    onLabelClick: EventEmitter<MouseEvent> = new EventEmitter();

    handleLabelClick(evt: MouseEvent): void {
        this.onLabelClick.emit(evt);
    }
}
