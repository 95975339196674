import { takeWhile } from 'rxjs/operators';
import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { ExcelExport } from 'insightui.table/components/datatable/shared/services/table-export.service';
import { EventEmitter, Injector } from '@angular/core';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

/**
 * Plugin for displaying and selecting rows in the table.
 * TODO: Support Row Plugins
 */
export class RowSelectionTablePlugin extends TableBodyPlugin {
    name = 'RowSelectionTablePlugin';

    selectRow$: EventEmitter<string> = new EventEmitter();

    selection = [];
    hasFocus = null;

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions,
        protected table?: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        document.body.addEventListener('keydown', this.handleKeyEvent.bind(this));
        document.body.addEventListener('click', () => {
            this.hasFocus = null;
        });
        document.body.addEventListener('blur', () => {
            this.hasFocus = null;
        });
    }

    protected doEnable(cell: Cell) {
        const rootNode = this.getRow(cell);
        if (!rootNode || this.isActive(rootNode)) {
            return;
        }

        rootNode.rowSelectionListener = (event: MouseEvent) => {
            if (event.shiftKey && this.selection.length) {
                this.fillSelection(cell.row.$id);
            } else if (event.ctrlKey) {
                this.toggleCell(cell);
            } else {
                this.selection = [];
                this.selection.push(cell.row.$id);
            }
            this.selectRow$.emit(cell.row.$id);

            this.hasFocus = cell.row.$id;
            return false;
        };

        rootNode.selectionSubscription = this.selectRow$
            .pipe(
                takeWhile(() => {
                    return document.body.contains(rootNode);
                })
            )
            .subscribe(() => {
                if (this.selection.indexOf(cell.row.$id) >= 0) {
                    rootNode.classList.add('row-selected');
                } else {
                    rootNode.classList.remove('row-selected');
                }
            });

        if (this.selection.indexOf(cell.row.$id) >= 0) {
            rootNode.classList.add('row-selected');
        }

        rootNode.addEventListener('click', rootNode.rowSelectionListener);
        this.markAsActive(rootNode);
    }

    private toggleCell(cell: Cell) {
        if (this.selection.indexOf(cell.row.$id) > -1) {
            this.selection = this.selection.filter(el => el !== cell.row.$id);
        } else {
            this.selection.push(cell.row.$id);
        }
    }

    private handleKeyEvent(event: KeyboardEvent) {
        if (!this.hasFocus) {
            return;
        }
        switch (event.keyCode) {
            case 40:
                this.extendSelection(1, event.shiftKey);
                break;
            case 38:
                this.extendSelection(-1, event.shiftKey);
                break;
            default:
                if (!event.shiftKey) {
                    this.hasFocus = null;
                }
                return;
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();

        this.selectRow$.emit('');
    }

    protected doDisable(cell: Cell) {
        // nothing to do
    }

    extendSelection(step: -1 | 1, keepCurrentSelection: boolean = false) {
        if (this.selection.length === 0) {
            return;
        }

        const last = this.selection[this.selection.length - 1];
        const reportingData = this.table.getReportingDataSet();
        const currentIndex = reportingData.findIndex(row => row.$id === last);
        const focusIndex = reportingData.findIndex(row => this.hasFocus === row.$id);
        if (
            currentIndex === focusIndex ||
            !(reportingData.length > currentIndex + step && currentIndex - step >= 0)
        ) {
            return;
        }
        const nextSelectedId = reportingData[currentIndex + step].$id;
        if (!keepCurrentSelection) {
            this.selection = [nextSelectedId];
            this.hasFocus = nextSelectedId;
            return;
        }
        if (step * currentIndex < focusIndex) {
            this.selection.pop();
        } else {
            this.selection.push(nextSelectedId);
        }
    }

    extendExport(exportData: ExcelExport) {
        exportData.value.forEach(row => {
            if (this.selection.indexOf(String(row.data.$id)) >= 0) {
                row.type = 'selectedHighlight';
            }
        });

        return exportData;
    }

    private fillSelection($id: string) {
        const first = this.selection[0];
        let inRange = false;
        this.selection = this.table
            .getReportingDataSet()
            .filter(row => {
                if (row.$id === $id || row.$id === first) {
                    inRange = !inRange;
                }
                return inRange;
            })
            .map(row => row.$id);
    }
}
