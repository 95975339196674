import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Column, DataRow } from '../shared/models/datatable.models';
import { Subscription } from 'rxjs';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import {
    Cell,
    CellCacheService
} from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { FilteredTotalService } from 'insightui.table/services/filtered-total.service';

const TAG = 'TG';

@Component({
    selector: 'datatable-body-row',
    templateUrl: 'body-row.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'datatable-body-row'
    }
})
export class DatatableBodyRowComponent implements OnChanges, OnDestroy, OnInit {
    @Input()
    readonly columns: Column[];

    @Input()
    @HostBinding('style.width.px')
    readonly innerWidth: number;

    @Input()
    readonly row: DataRow;

    @Input()
    readonly rowHeight: number;

    @Input()
    readonly offsetX: number;

    @HostBinding('style.height.px')
    height: number;

    @HostBinding('class.active')
    @Input()
    isSelected: boolean;

    @ViewChild('rowContainer', { static: true })
    element: ElementRef;

    @Input()
    state = 0;

    @Input()
    visibilityState = {
        leftFiller: 0,
        rightFiller: 0,
        columnsPerRow: 0,
        skipColumns: 0
    };

    @Input()
    showEndBorder: boolean;

    isEvenRow: boolean;
    isOddRow: boolean;
    isTotalRow: boolean;
    styles: any;

    visibleColumns: Column[];
    @Output()
    activate: EventEmitter<object> = new EventEmitter();

    subscription: Subscription;
    cells: Cell[];

    constructor(
        protected table: AuthoringDatatableComponent,
        protected cellCache: CellCacheService,
        private filteredTotalService: FilteredTotalService
    ) {
    }

    cellColumnTrackByFn(index: number, cell: Cell) {
        if (cell.column) {
            return cell.column.id;
        }
        return cell;
    }

    ngOnInit(): void {
        this.recalculateColumns();
        this.height =
            this.rowHeight +
            this.filteredTotalService.getHeightByRow(this.row, this.table);
        this.isEvenRow =
            !(this.isTotal() || this.isSubTotal()) &&
            ((this.row.$$index && this.row.$$index % 2 === 0) || this.row.$$index === 0);
        this.isOddRow =
            !(this.isTotal() || this.isSubTotal()) &&
            (this.row.$$index && this.row.$$index % 2 !== 0);
        this.isTotalRow = this.isTotal() || this.isSubTotal();
        if (this.isTotal()) {
            this.filteredTotalService.showFilteredTotal = this.height > this.rowHeight;
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private isTotal(): boolean {
        const tag = this.row[TAG];
        return tag === 'Total';
    }

    private isSubTotal(): boolean {
        const tag = this.row[TAG];
        return tag === 'SubTotal';
    }

    public onActivate(event, index: number) {
        event.cellIndex = index;
        event.rowElement = this.element;
        this.activate.emit(event);
    }

    private recalculateColumns(): void {
        this.visibleColumns = (this.columns || []).slice(
            this.visibilityState.skipColumns,
            this.visibilityState.skipColumns + this.visibilityState.columnsPerRow
        );
        const cells = this.cellCache.getRow(this.row);
        this.cells = this.visibleColumns.map(
            column => cells[column.id] || { value: null, element: null }
        );
    }

    ngOnChanges(changes: { [property: string]: SimpleChange }) {
        let requireRedraw = false;
        if (changes.hasOwnProperty('visibilityState')) {
            const visibilityState = changes.visibilityState;
            const previousValue = visibilityState.previousValue;
            const currentValue = visibilityState.currentValue;
            if (typeof previousValue !== 'undefined') {
                if (previousValue.columnsPerRow !== currentValue.columnsPerRow) {
                    requireRedraw = true;
                }
            }

        }
        if (changes.hasOwnProperty('innerWidth') || changes.hasOwnProperty('offsetX')) {
            requireRedraw = true;
        } else if (changes.hasOwnProperty('columns')) {
            const columnChanges = changes as { columns: SimpleChange };
            const prev = columnChanges.columns.previousValue;
            const current = columnChanges.columns.currentValue;
            if (
                !prev.every ||
                !current.every ||
                !prev.every((col, idx) => col.id === current[idx].id)
            ) {
                requireRedraw = true;
            }
        }
        if (requireRedraw) {
            this.recalculateColumns();
        }
    }
}
