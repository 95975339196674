import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { LoggingErrorHandler } from 'insightui.core/services/logging/logging-error-handler';
import { ErrorService } from 'insightui.error/error.service';
import { HttpErrorInterceptor } from 'insightui.error/http-error.interceptor';
import { NoDataComponent } from 'insightui.error/no-data/no-data.component';

@NgModule({
    declarations: [NoDataComponent],
    exports: [NoDataComponent],
    providers: []
})
export class ErrorModule {
    static forRoot(): ModuleWithProviders<ErrorModule> {
        return {
            ngModule: ErrorModule,
            providers: [
                ErrorService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HttpErrorInterceptor,
                    multi: true
                },
                { provide: ErrorHandler, useClass: LoggingErrorHandler }
            ]
        };
    }
}
