import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartOrderService } from './services/chartOrder.service';
import { ChartLegendBarChartService } from './services/chartLegendBarChart.service';
import { ChartLegendBarChartComponent } from './components/chartLegendBarChart/chartLegendBarChart.component';
import { ChartLegendCellItemForRowDirective } from './shared/chartLegendCellItemForRow.directive';
import { LegendOrderByDropDownComponent } from './components/legendOrderByDropDown/legendOrderByDropDown.component';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { SortingOptionsService } from './services/sortingOptions.service';
import { ActiveChartModel } from './services/activeChartModel.service';
import { ActiveChartSorting } from './services/activeChartSorting.service';
import { KpiTitleService } from './services/kpiTitle.service';
import { LegendLabelComponent } from './components/legendLabel/legendLabel.component';
import { TooltipKpiTableComponent } from './components/tooltipKpiTable/tooltipKpiTable.component';
import { LoadDataParamsFactory } from './services/report/loadDataParamsFactory.service';
import { ChartDataService } from './services/report/chartData.service';
import { ChartDataMergeService } from './services/report/chartDataMerge.service';
import { TooltipKpiRowComponent } from './components/tooltipKpiRow/tooltipKpiRow.component';
import { RoundEvenService } from 'insightui.core/services/round-even.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    providers: [
        ChartOrderService,
        ChartLegendBarChartService,
        VisualizationContextService,
        SortingOptionsService,
        ActiveChartModel,
        ActiveChartSorting,
        KpiTitleService,
        LoadDataParamsFactory,
        ChartDataService,
        ChartDataMergeService,
        RoundEvenService
    ],
    declarations: [
        ChartLegendBarChartComponent,
        ChartLegendCellItemForRowDirective,
        LegendOrderByDropDownComponent,
        LegendLabelComponent,
        TooltipKpiRowComponent,
        TooltipKpiTableComponent
    ],
    imports: [CommonModule, DragDropModule]
})
export class ChartModule {}
