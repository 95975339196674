export enum DragulaConfiguration {
    DEFAULT,
    CHILDREN,
    CUSTOM
}

export const DefaultDragulaServiceConfiguration = {
    revertOnSpill: false,
    moves: (el, source, handle, sibling) => {
        let headerGroup = handle.closest('#header-main .datatable-header-group');
        return (
            !el.classList.contains('fixed-header') &&
            headerGroup &&
            headerGroup.parentNode.parentNode.parentNode.parentNode.classList.contains(
                'datatable-header'
            )
        );
    },
    accepts: (el, target, source, sibling) => {
        return sibling === null || !sibling.classList.contains('fixed-header');
    },
    direction: 'horizontal'
};

export const ChildrenDragulaServiceConfiguration = {
    revertOnSpill: false,
    moves: (el, source, handle, sibling) => {
        return (
            !el.classList.contains('fixed-header') &&
            !el.classList.contains('datatable-header-group')
        );
    },
    accepts: (el, target, source, sibling) => {
        return (
            (target === el.parentNode && sibling === null) ||
            (sibling !== null &&
                sibling.parentNode === el.parentNode &&
                !sibling.classList.contains('fixed-header'))
        );
    },
    direction: 'horizontal'
};

const WINDOW_ELEMENT_NAME = 'Element';
if (window[WINDOW_ELEMENT_NAME] && !Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i,
            el = this;
        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) {
                // meet sonar
            }
        } while (i < 0 && (el = el.parentElement));
        return el;
    };
}
