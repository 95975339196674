import { StaticVisualizationContextService } from './static-visualization-context.service';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { Injectable } from '@angular/core';
/**
 * Factory for retrieving the ng1 visualizationContextService.
 *
 * @TODO: Migrate the actual service to ng2 and downgrade it for core.
 */
@Injectable()
export class VisualizationContextService {
    private ng1VisualizationContextService;

    constructor(injector: AngularInjectorResolver) {
        injector.get().subscribe($injector => {
            this.ng1VisualizationContextService = $injector.get(
                'VisualizationContextService'
            );
            StaticVisualizationContextService.setInstance(
                this.ng1VisualizationContextService
            );
        });
    }

    public getVisualizationContext() {
        return this.ng1VisualizationContextService.getVisualizationContext.apply(this);
    }

    public get(key, defaultValue?) {
        return this.ng1VisualizationContextService.get.apply(this, arguments);
    }

    public set(key, value) {
        return this.ng1VisualizationContextService.set.apply(this, arguments);
    }

    public has(key) {
        return this.ng1VisualizationContextService.has.apply(this, arguments);
    }

    public update(context) {
        return this.ng1VisualizationContextService.update.apply(this, arguments);
    }
}
