import { AppStateEpic } from 'projects/insightui/src/app/state/state.types';
import { Epic } from 'redux-observable';
import { Observable, race } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'insightui.global/global.module';
import { debounceTime, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { PowerpointExportActiveReportService } from 'insightui.export/powerpoint/services/powerpoint-export-active-report.service';
import {
    EXPORT_COLLECTION_POST_TO_PARENT,
    EXPORT_COLLECTION_SHEETS_FROM_IFRAME,
    EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR
} from 'insightui.export-collection/export-collection.actions';
import { AnyAction } from 'redux';
import { REPORT_ERROR } from 'insightui.error/error.actions';
import { log } from 'insightui.core/services/logging/log-utils';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { NavigationService } from 'insightui.core/services/navigation.service';

const REPORT_RENDER_SETTLE_TIME = 3000;

@Injectable({
    providedIn: 'root'
})
export class ExportReportInIframeEpic implements AppStateEpic<undefined> {
    key: undefined = undefined;
    private readonly logger: ILogger;

    constructor(
        @Inject(WINDOW) private window: Window,
        private powerpointExportActiveReportService: PowerpointExportActiveReportService,
        private navigationService: NavigationService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('ExportReportInIframeEpic');
    }

    getEpic(): Epic<AnyAction> {
        return (action$: Observable<AnyAction>) => {
            const actionInIFrame$ = action$.pipe(
                filter(
                    () =>
                        this.window.location.pathname.indexOf('/export') === 0 &&
                        !!this.window.parent
                )
            );

            const reportId$ = this.navigationService
                .getParamAsObservable('reportSnapshotId')
                .pipe(
                    filter(repSnapshotId => !!repSnapshotId),
                    log('reportId', this.logger)
                );

            /*
             REPORT_RENDER_SETTLE_TIME is used to wait until the report
             has been completely rendered in the iframe.
             For table reports we don't have a reliable way to detect
             this rendering is done (DEPRECATED_TABLE_DATA_SERVICE/RELOAD_DONE
             tells us nothing about the rendering itself).
             The usual Angular hooks (ngAfterViewInit, etc.) are not useful for our purpose,
             because the data table is dynamically composed.
            */
            const exportReportEpic: Observable<AnyAction> = actionInIFrame$.pipe(
                filter(action => this.isActionIndicatingARenderedReport(action)),
                debounceTime(REPORT_RENDER_SETTLE_TIME),
                withLatestFrom(reportId$),
                log('exportReportEpic', this.logger),
                switchMap(([action, reportId]) => {
                    const sheets$ = this.powerpointExportActiveReportService.sheetsOfCurrentReport();
                    return sheets$.pipe(
                        map(sheets => {
                            const sheetsFromIFrameAction = EXPORT_COLLECTION_SHEETS_FROM_IFRAME(
                                {
                                    sheets,
                                    reportId
                                }
                            );

                            return EXPORT_COLLECTION_POST_TO_PARENT(
                                sheetsFromIFrameAction
                            );
                        })
                    );
                })
            );

            // TODO: currently we treat 'no data' like an error.
            // In the future we want to provide a better handling for such cases:
            // - either display a message to the user, stating the reports with missing data or
            // - provide 'empty pages' inside the PPT export file
            // In any case, each type of report (table, chart, fair-share) has to dispatch a corresponding
            // action - at the moment only tables send it
            const exportNoDataEpic: Observable<AnyAction> = actionInIFrame$.pipe(
                filter(action => this.isActionIndicatingIncompleteData(action)),
                withLatestFrom(reportId$),
                log('exportNoDatatEpic', this.logger),
                map(([action, reportId]) => {
                    const message = action.payload;
                    const errorInIFrameAction = EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR({
                        message,
                        reportId
                    });

                    return EXPORT_COLLECTION_POST_TO_PARENT(errorInIFrameAction);
                })
            );

            const exportErrorEpic: Observable<AnyAction> = actionInIFrame$.pipe(
                filter(REPORT_ERROR.match),
                withLatestFrom(reportId$),
                log('exportErrorEpic', this.logger),
                map(([reportError, reportId]) => {
                    const message = reportError.payload;
                    const errorInIFrameAction = EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR({
                        message,
                        reportId
                    });

                    return EXPORT_COLLECTION_POST_TO_PARENT(errorInIFrameAction);
                })
            );

            return race(exportReportEpic, exportNoDataEpic, exportErrorEpic);
        };
    }

    private isActionIndicatingARenderedReport(action: AnyAction): boolean {
        return (
            action.type === 'DEPRECATED_CHART_FACTORY_UI_SERVICE/CHART_RENDERED' ||
            action.type === 'DEPRECATED_TABLE_DATA_SERVICE/RELOAD_DONE' ||
            action.type === 'FAIR_SHARE/FAIR_SHARE_UPDATE_REPORT'
        );
    }

    private isActionIndicatingIncompleteData(action: AnyAction): boolean {
        // TODO: provide similar actions for chart and fair share
        return action.type === 'DEPRECATED_TABLE_DATA_SERVICE/RELOAD_DONE_NO_DATA';
    }
}
