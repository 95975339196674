import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { InsightConfig, INSIGHT_CONFIG } from 'insightui.global/global.module';
import { ACCEPTED_MEDIA_TYPES } from 'insightui.export/powerpoint/constants';
import {
    PowerpointExportCollection,
    PowerpointExportSheets
} from 'insightui.export/types';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { AnalyticsService } from 'insightui.core/services/analytics.service';

/**
 * The powerpoint export service exports html to powerpoint.
 */
@Injectable({
    providedIn: 'root'
})
export class PowerpointExportService {
    constructor(
        private httpClient: HttpClient,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig,
        private toastr: ToastrService,
        private visualizationContextService: VisualizationContextService,
        private analyticsService: AnalyticsService
    ) {}

    /**
     * Exports HTML to powerpoint by using the backend.
     */
    exportReportWith(powerpointExportSheets: PowerpointExportSheets): Promise<Blob> {
        const url = `${this.insightConfig.services.FILE_EXPORT_SERVICE.url}api/v1/powerpoint/report`;

        return this.export(url, powerpointExportSheets);
    }

    /**
     * Exports HTML to powerpoint by using the backend.
     */
    exportCollectionWith(
        powerpointExportSheets: PowerpointExportSheets,
        title: string,
        subtitle: string
    ): Promise<Blob> {
        const url = `${this.insightConfig.services.FILE_EXPORT_SERVICE.url}api/v1/powerpoint/collection`;

        const collectionToExport: PowerpointExportCollection = {
            title,
            subtitle,
            sheets: powerpointExportSheets
        };

        return this.export(url, collectionToExport);
    }

    /**
     * Check if the export service is reachable.
     */
    checkServiceAvailable(): Observable<void> {
        const url = `${this.insightConfig.services.FILE_EXPORT_SERVICE.url}api/v1/status`;
        return this.httpClient.get(url, { responseType: 'text' }).pipe(mapTo(void 0));
    }

    private async export(url: string, exportBody: any): Promise<Blob> {
        const acceptHeaders = new HttpHeaders().set('Accept', ACCEPTED_MEDIA_TYPES);
        const response = this.httpClient.post(url, exportBody, {
            headers: acceptHeaders,
            observe: 'response',
            responseType: 'blob'
        });

        const promise: HttpResponse<Blob> = await response
            .toPromise()
            .catch(() => {
                this.toastr.error(
                    'There was a problem while exporting to PowerPoint. Please try again later.',
                    'Service unavailable'
                );
                return Promise.reject();
            })
            .then(value => {
                this.analyticsService.trackEvent(
                    'Export',
                    'Powerpoint',
                    this.visualizationContextService.get('reportId')
                );
                return value;
            });

        return promise.body;
    }
}
