import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ChartFilterItem, ChartModel } from '../types';
import { DropEvent } from 'insightui.dragndrop/types';
import { DimensionId, DimensionName, Sorting } from 'insightui.data/types';
import { rangeValueSorting } from "insightui.chart/services/util/chartUtils";

export const DEFAULT_SORTING: Sorting = { target: 'ById', direction: 'ASC' };

@Injectable()
export class ChartOrderService {
    defaultOrderForChart(model: ChartModel): DimensionId[] {
        return this.orderForChart(model, DEFAULT_SORTING);
    }

    orderForChart(model: ChartModel, sorting: Sorting): DimensionId[] {
        const dimensions: DimensionName[] = model.getDimensions();
        const lastDimension: DimensionName = _.last(dimensions);
        const field: ChartFilterItem[] | DimensionName[] = _.get(
            model.getFilterState(),
            lastDimension
        );
        let orderedItems: ChartFilterItem[] | DimensionName[] = [];

        if (sorting.target === 'ById') {
            if (lastDimension === 'priceClass' || lastDimension === 'feature') {
                orderedItems = this.getOrderByDimensionKeys(model);
            } else {
                orderedItems = field;
            }
        } else if (sorting.target === 'Alphabetically') {
            orderedItems = _.sortBy(field, item => _.get(item, 'title', item)) as any;
        }

        const [others, sorted] = _.partition(orderedItems, item => {
            const id = _.get(item, 'id', item);
            return id === 'FV_Others' || id === 'Others';
        });

        if (sorting.direction === 'DESC') {
            sorted.reverse();
        }
        const othersLast = sorted.concat(others);

        return othersLast.map(item => _.get(item, 'id', item));
    }

    manualOrderForChart(
        model: ChartModel,
        event: DropEvent<DimensionId, DimensionId>
    ): DimensionId[] {
        const currentOrder: DimensionId[] = model.getOrder();

        if (event.dropped === event.onto) {
            return currentOrder;
        }

        return _.flatMap(currentOrder, (dimensionId: DimensionId) => {
            let insertion = [];
            if (dimensionId === event.onto) {
                if (event.edge === 'Top') {
                    insertion = [event.dropped, event.onto];
                } else {
                    insertion = [event.onto, event.dropped];
                }
            } else if (dimensionId !== event.dropped) {
                insertion = [dimensionId];
            }

            return insertion;
        });
    }

    /**
     * Return the order by the actual keys found in the dimensions.
     *
     * This is required for priceclasses and features, as previous periods may contain keys
     * that don't exist in the current period
     */
    private getOrderByDimensionKeys(model: ChartModel): ChartFilterItem[] {
        const groupedDimension = model.groupLastDimension();
        return _(groupedDimension)
            .map(dimension => dimension.key)
            .reject((key: ChartFilterItem) => key.id === 'none' || key.id.startsWith('<'))
            .sort(rangeValueSorting)
            .value();
    }
}
