/**
 * UserProfileService API Documentation
 * User Profile Service stores Favorites, Collections, Announcements and user-events.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class CollectionInfoControllerService {
    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Gets the newly shared collection ids
     * @param countryCodes countryCodes
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNewlySharedCollectionIds(
        countryCodes: Array<string>,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<Array<string>>;
    public getNewlySharedCollectionIds(
        countryCodes: Array<string>,
        clientId?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<string>>>;
    public getNewlySharedCollectionIds(
        countryCodes: Array<string>,
        clientId?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<string>>>;
    public getNewlySharedCollectionIds(
        countryCodes: Array<string>,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (countryCodes === null || countryCodes === undefined) {
            throw new Error(
                'Required parameter countryCodes was null or undefined when calling getNewlySharedCollectionIds.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', <any>clientId);
        }
        if (countryCodes) {
            countryCodes.forEach(element => {
                queryParameters = queryParameters.append('countryCodes', <any>element);
            });
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<string>>(
            `${this.configuration.basePath}/api/v2/collection-info/new-shared`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
