import { NumberOfChartRowsPerPage } from 'insightui.export/types';

/**
 * Number of Chart Rows" x "Number of Legend Items"- Matrix
 * @example
 * EXPORT_MATRIX[5][3] = number of Chart Rows per page for a vertical report with 5 Chart Rows and 3 Legend Items
 */
export const EXPORT_MATRIX: NumberOfChartRowsPerPage[][] = [
    [
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1],
        [1]
    ],
    [
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2],
        [2]
    ],
    [
        [3],
        [3],
        [3],
        [3],
        [3],
        [3],
        [3],
        [3],
        [3],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1],
        [2, 1]
    ],
    [
        [4],
        [4],
        [4],
        [4],
        [4],
        [4],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2],
        [2, 2]
    ],
    [
        [5],
        [5],
        [5],
        [3, 2],
        [3, 2],
        [3, 2],
        [3, 2],
        [3, 2],
        [3, 2],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1],
        [2, 2, 1]
    ],
    [
        [6],
        [6],
        [6],
        [3, 3],
        [3, 3],
        [3, 3],
        [3, 3],
        [3, 3],
        [3, 3],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2],
        [2, 2, 2]
    ],
    [
        [4, 3],
        [4, 3],
        [4, 3],
        [4, 3],
        [4, 3],
        [4, 3],
        [3, 2, 2],
        [3, 2, 2],
        [3, 2, 2],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1],
        [2, 2, 2, 1]
    ],
    [
        [4, 4],
        [4, 4],
        [4, 4],
        [4, 4],
        [4, 4],
        [4, 4],
        [3, 3, 2],
        [3, 3, 2],
        [3, 3, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2],
        [2, 2, 2, 2]
    ],
    [
        [5, 4],
        [5, 4],
        [5, 4],
        [3, 3, 3],
        [3, 3, 3],
        [3, 3, 3],
        [3, 3, 3],
        [3, 3, 3],
        [3, 3, 3],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1],
        [2, 2, 2, 2, 1]
    ],
    [
        [5, 5],
        [5, 5],
        [5, 5],
        [4, 3, 3],
        [4, 3, 3],
        [4, 3, 3],
        [3, 3, 2, 2],
        [3, 3, 2, 2],
        [3, 3, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2],
        [2, 2, 2, 2, 2]
    ],
    [
        [6, 5],
        [6, 5],
        [6, 5],
        [4, 4, 3],
        [4, 4, 3],
        [4, 4, 3],
        [3, 3, 3, 2],
        [3, 3, 3, 2],
        [3, 3, 3, 2],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1],
        [2, 2, 2, 2, 2, 1]
    ]
];
