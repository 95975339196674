import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { PageDefinitionState, Page, Chapter, Label } from '../page-definition.types';
import { PageDefinitionModel } from '../page-definition.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KpiProperties } from 'insightui.data/types';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

export interface IPageDefinitionService {
    readonly currentPage$: Observable<PageDefinitionModel>;
    getProductName(): string;

    getIndex(): PageDefinitionState;

    getLabels(): { [key: string]: ReadonlyArray<Label> };

    getCurrentPage(): PageDefinitionModel;

    getPages(ids: ReadonlyArray<string>): Array<Page> | undefined;

    getModuleTitleForPage(pageId: any): string;

    getModuleForPage(pageId: any): Chapter;

    getKpiProperties(): {
        [key: string]: KpiProperties;
    };
}

@Injectable({ providedIn: 'root' })
export class PageDefinitionService implements IPageDefinitionService {
    private logger: ILogger;

    constructor(private ngRedux: NgRedux<AppRootState>, logService: LogService) {
        this.logger = logService.getLogger('PageDefinitionService');
        this.logger.debug('ctor');
    }

    public getProductName(): string {
        return 'GfK Retail Scout';
    }

    public getIndex(): PageDefinitionState {
        return this.ngRedux.getState().pageDefinition;
    }

    public getLabels(): { [key: string]: ReadonlyArray<Label> } {
        return this.ngRedux.getState().pageDefinition.labels;
    }

    public get currentPage$(): Observable<PageDefinitionModel> {
        return this.ngRedux
            .select(state => state.pageDefinition.currentPage)
            .pipe(
                map(page => {
                    return page ? new PageDefinitionModel(page) : null;
                })
            );
    }

    public getCurrentPage(): PageDefinitionModel {
        const page = this.ngRedux.getState().pageDefinition.currentPage;
        if (!page) {
            throw new Error('currentPage is not available');
        }

        return new PageDefinitionModel(page);
    }

    public getPages(ids: ReadonlyArray<string>): Array<Page> | undefined {
        const pages = this.ngRedux.getState().pageDefinition.pages;
        const result: Array<Page> = [];

        for (const id of ids) {
            const page = pages[id];
            if (page) {
                result.push(page);
            }
        }
        return result;
    }

    public getModuleTitleForPage(pageId: any): string {
        const module = this.getModuleForPage(pageId);
        return module ? module.title : '';
    }

    public getModuleForPage(pageId: any): Chapter {
        if (!pageId) {
            return null;
        }
        return this.getIndex()
            .pageIndices.map(index => index.chapters)
            .reduce((a, b) => a.concat(b))
            .find(c => c.pageIds.includes(pageId));
    }

    public getKpiProperties(): {
        [key: string]: KpiProperties;
    } {
        const result = {};
        const kpis = this.getCurrentPage().report.kpiDefinition.kpi;
        kpis.forEach(kpi => {
            result[kpi.id] = {
                id: kpi.id,
                title: kpi.title,
                dataType: kpi.dataType,
                sortable: kpi.sortable || false,
                filterable: kpi.filterable || false,
                filterType: kpi.filterType || []
            };
        });
        return result;
    }
}
