import { Injectable } from '@angular/core';
import {
    FairShareHierarchies,
    FairShareRawReportData,
    FairShareReportDataByChannelId,
    FairShareReportRow,
    FairShareReportRows,
    FairShareState,
    hasLoaded,
    ValueGrowthRangeBounds,
    ValueGrowthRangeBoundsStatus
} from 'insightui.fair-share/fair-share.types';
import { ReportingDimensionHierarchy } from 'insightui.report-filter/report-filter.types';
import { FairShareHierarchySelector } from 'insightui.fair-share/fair-share-hierarchy.selector';
import { HumanReadableLabel } from 'projects/insightui/src/app/state/state.types';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

const DRILL_LEVEL_TO_READABLE_NAME = {
    sector: 'Sector',
    category: 'Category',
    productGroup: 'Product Group',
    brandInPg: 'Brand',
    brandInCat: 'Brand',
    itemInPg: 'Item',
    priceClass: 'Price Class'
};

/**
 * The selector transforms the data in the store to presentable data.
 */
@Injectable({
    providedIn: 'root'
})
export class FairShareSelector {
    constructor(private fairShareHierarchySelector: FairShareHierarchySelector,
                private metadataService: MetadataService,
                private visualizationContext: VisualizationContextService) { }

    maxFairShareValue(state: FairShareState): number {
        return this.reportData(state).reduce(
            (value, row) => Math.max(value, row[state.channels.retailer].KPIC11 || 0),
            0
        );
    }

    totalFairShareValue(state: FairShareState): number {
        const rows = this.reportData(state).filter(row => row[state.channels.retailer].TG === null);
        const total = rows.length > 0 && rows[0][state.channels.retailer].PF ? 'SubTotal' : 'Total';
        const totalRow = this.reportData(state).find(
            row => row[state.channels.retailer].TG === total
        );
        return totalRow ? totalRow[state.channels.retailer].KPIC11 || 0 : 0;
    }

    reportRows(state: FairShareState): FairShareReportRows {
        const bounds = this.valueGrowthRangeBounds(state);
        const rows = this.reportData(state)
            .filter(row => row[state.channels.retailer].TG === null)
            .map(row => {
                const title = this.titleForRow(
                    state.hierarchy,
                    row[state.channels.retailer]
                );

                if (state.viewedTarget.drillLevel === 'productGroup') {
                    return this.convertToReportRow(state, row, title, bounds, true);
                }
                const isDrilling = this.hasRowChildren(
                    row[state.channels.retailer],
                    state.hierarchy
                );

                return this.convertToReportRow(state, row, title, bounds, isDrilling);
            });

        const reportData = this.reportData(state).filter(row => row[state.channels.retailer].TG === null);
        return reportData.length > 0 && reportData[0][state.channels.retailer].PF ? this.sortbyTitle(rows)
            : rows.sort((a, b) => b.marketSizeValue - a.marketSizeValue);
    }

    sortbyTitle(rows: FairShareReportRows): FairShareReportRows {
        return rows.filter(item => item.title).sort((firstItem, secondItem) => {

            if (firstItem.title.length === 0 || firstItem.title === 'OTHERS' && secondItem.title.length !== 0) {
                return -1;
            }

            if (secondItem.title.length === 0 || secondItem.title === 'OTHERS' && firstItem.title.length !== 0) {
                return 1;
            }

            const firstRange = firstItem.title.match(/\d+/);
            const secondRange = secondItem.title.match(/\d+/);

            if (firstRange && secondRange) {
                const firstNum = parseInt(firstRange[0], 10);
                const secondNum = parseInt(secondRange[0], 10);
                if (firstNum > secondNum) {
                    return 1;
                }
                else if (firstNum <= secondNum) {
                    return -1;
                }
                else {
                    return 0
                }
            }
        });
    }

    totalRow(state: FairShareState): FairShareReportRow | null {
        const bounds = this.valueGrowthRangeBounds(state);
        const rows = this.reportData(state).filter(row => row[state.channels.retailer].TG === null);
        const total = rows.length > 0 && rows[0][state.channels.retailer].PF ? 'SubTotal' : 'Total';
        const totalRow: FairShareReportDataByChannelId = this.reportData(state).find(
            row => row[state.channels.retailer].TG === total
        );
        const viewedHierarchy: ReportingDimensionHierarchy = this.fairShareHierarchySelector.getHierarchy(
            state.viewedTarget,
            state.hierarchy
        );
        if (!totalRow || !viewedHierarchy) {
            return null;
        }
        const isDrilling = viewedHierarchy.level !== 0;
        const title = this.titleForTotalRow(viewedHierarchy, state);

        return this.convertToReportRow(
            state,
            totalRow,
            title,
            bounds,
            isDrilling
        );
    }

    drillTargetName(state: FairShareState): HumanReadableLabel | null {
        if (!state.viewedTarget) {
            return null;
        }

        // Check if current page is showing price class, then display its label
        const showPriceClasses = this.visualizationContext.get('showPriceClasses');
        const drillLevel = showPriceClasses ? 'priceClass' : state.viewedTarget.drillLevel;

        return DRILL_LEVEL_TO_READABLE_NAME[drillLevel];
    }

    hasNoDataLoaded(state: FairShareState): boolean {
        if (hasLoaded(state.reportData)) {
            return state.reportData.length === 0;
        }

        return false;
    }

    valueGrowthRangeBounds(state: FairShareState): ValueGrowthRangeBounds {
        const values = this.reportData(state)
            .map(row => {
                return Math.abs(row[state.channels.retailer].KPIC12 || 0);
            })
            .filter(growthValue => {
                return growthValue < 100;
            });
        const average = Math.round(this.calculateAverage(values));
        const squareDiffs = values.map(value => {
            const diff = value - average;
            return Math.pow(diff, 2);
        });
        const averageSquareDiffs = this.calculateAverage(squareDiffs);
        const standardDeviation = Math.round(Math.sqrt(averageSquareDiffs));

        return {
            min: -(average + standardDeviation),
            max: average + standardDeviation
        };
    }

    valueGrowthRangeBoundsStatus(state: FairShareState): ValueGrowthRangeBoundsStatus {
        const bounds = this.valueGrowthRangeBounds(state);
        const rows = this.reportData(state);
        const max = rows.some(row => {
            return (
                (row[state.channels.retailer].KPIC12 || Number.NEGATIVE_INFINITY) >=
                bounds.max
            );
        });
        const min = rows.some(row => {
            return (
                (row[state.channels.retailer].KPIC12 || Number.POSITIVE_INFINITY) <=
                bounds.min
            );
        });

        return {
            max,
            min
        };
    }

    getProductId(rawReportData: FairShareRawReportData) {
        const twoLetterId = this.getTwoLetterId(rawReportData);
        return rawReportData[twoLetterId];
    }

    getPriceClassId(rawReportData: FairShareRawReportData) {
        const twoLetterId = 'PF';
        return rawReportData[twoLetterId];
    }
    getTwoLetterId(rawReportData: FairShareRawReportData): string {
        return ['PG', 'CY', 'SG', 'AR', 'PF'].find(twoLetters => {
            return !!rawReportData[twoLetters] && rawReportData[twoLetters] !== 'CY-1';
        });
    }

    private hasRowChildren(
        row: FairShareRawReportData,
        hierarchies: FairShareHierarchies
    ): boolean {
        const productId = this.getProductId(row);
        if (!productId) {
            return false;
        }
        return Object.keys(hierarchies).some(key => key === productId);
    }

    private calculateAverage(data: number[]) {
        const sum = data.reduce((prev, current) => {
            return prev + current;
        }, 0);
        const avg = sum / data.length;

        return avg;
    }

    private reportData(
        state: FairShareState
    ): ReadonlyArray<FairShareReportDataByChannelId> {
        if (hasLoaded(state.reportData)) {
            return state.reportData;
        }

        return [];
    }

    private titleForRow(
        hierarchy: FairShareHierarchies,
        rawReportData: FairShareRawReportData
    ): HumanReadableLabel {
        if (!!rawReportData.BR && !rawReportData.PF) {
            return rawReportData.BR;
        }
        if (!hierarchy) {
            return null;
        }

        const priceClassId = this.getPriceClassId(rawReportData);
        if (priceClassId) {
            return this.metadataService.getPriceClassById(priceClassId).title;
        }

        const productId = this.getProductId(rawReportData);
        const currentHierarchy = hierarchy[productId];

        return this.fairShareHierarchySelector.hierarchyName(currentHierarchy);
    }

    /**
     * To get title for row with Total value
     * - Check if the row is in Price Class Share focus + has any brand name selected
     * - if yes, return the selected brand name as the title
     * - otherwise, return the current viewed hierarchy filter as the title
     *
     * @param viewedHierarchy
     * @param state
     * @private
     */
    private titleForTotalRow(
        viewedHierarchy: ReportingDimensionHierarchy,
        state: FairShareState
    ): HumanReadableLabel {
        const childRow = this.reportData(state)
            .find(row => row[state.channels.retailer].TG === null);
        if (this.isRowHasBrandFilterSelectedInPriceClassShare(childRow, state)) {
            return childRow[state.channels.retailer].BR;
        } else {
            return this.fairShareHierarchySelector.hierarchyName(viewedHierarchy);
        }
    }

    private isRowHasBrandFilterSelectedInPriceClassShare(row, state) {
        return row && row[state.channels.retailer]
            && row[state.channels.retailer].PF && row[state.channels.retailer].BR;
    }

    private convertToReportRow(
        state: FairShareState,
        row: FairShareReportDataByChannelId,
        title: HumanReadableLabel,
        bounds: ValueGrowthRangeBounds,
        isDrilling: boolean
    ): FairShareReportRow {
        const channels = state.channels;
        const $rowId = row[channels.retailer].$rowId;

        return {
            $rowId,
            isGapValueHovering: state.showGapValueForRowId === $rowId,
            isGrowthValueHovering: state.showGrowthValueForRowId === $rowId,
            title,
            marketSizeGapValue: row[channels.retailer].PF ? row[channels.retailer].KPIC91_REBASE :
                 row[channels.retailer].KPIC91,
            fairShareValue: row[channels.retailer].KPIC11,
            marketSizeValue: row[channels.panelMarket].KPI21176,
            valueGrowthGapValue: row[channels.retailer].KPIC92,
            valueGrowthValueRetailer: row[channels.retailer].KPIC12,
            valueGrowthValuePanelMarket: row[channels.panelMarket].KPIC12,
            valueGrowthRangeBoundsStatus: {
                max:
                    (row[channels.retailer].KPIC12 || Number.NEGATIVE_INFINITY) >=
                    bounds.max,
                min:
                    (row[channels.retailer].KPIC12 || Number.POSITIVE_INFINITY) <=
                    bounds.min
            },
            isDrillable: isDrilling
        };
    }
}
