import { MetadataService } from 'insightui.metadata/services/metadata.service';

/**
 * Adds query parameters 'country' and 'period' to a given url.
 */
export function addMetadataParams(url: string, metadataService: MetadataService): string {
    const urlParams = [];

    const country = metadataService.getCurrentCountryCode();
    if (country) {
        urlParams.push('country=' + country);
    }

    const periodIndex = metadataService.getCurrentPeriodIndex();
    if (periodIndex !== null && periodIndex >= 0) {
        urlParams.push('period=' + periodIndex);
    }

    if (urlParams.length > 0) {
        url += '?' + urlParams.reduce((a, b) => a + '&' + b);
    }

    return url;
}
