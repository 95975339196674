import { AppStateReducer } from 'projects/insightui/src/app/state/state.types';
import {
    ACTIVATE_RELEASE_NOTE_BY_IDX,
    ERROR_FETCHING_RELEASE_NOTES,
    FINISH_CLOSE_MODAL,
    OPEN_MODAL
} from 'insightui.announcements/announcements.actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { AnnouncementState } from 'insightui.announcements/announcements.types';
import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';

export const initState: AnnouncementState = {
    modalIsOpen: false,
    releaseNotes: [],
    activeReleaseNoteId: null,
    hasError: false
};

@Injectable()
export class AnnouncementsReducer implements AppStateReducer<'announcements', AnyAction> {
    key: 'announcements' = 'announcements';
    initState = initState;

    getReducer() {
        return reducerWithInitialState(initState)
            .case(FINISH_CLOSE_MODAL, state => ({
                ...state,
                modalIsOpen: false
            }))
            .case(OPEN_MODAL, (state, releaseNotes) => ({
                ...state,
                modalIsOpen: true,
                hasError: false,
                releaseNotes,
                activeReleaseNoteId: releaseNotes[0].pageId
            }))
            .case(ACTIVATE_RELEASE_NOTE_BY_IDX, (state, releaseNoteIdx) => {
                const activeReleaseNote = state.releaseNotes[releaseNoteIdx];

                if (!activeReleaseNote) {
                    return state;
                }
                return {
                    ...state,
                    activeReleaseNoteId: activeReleaseNote.pageId
                };
            })
            .case(ERROR_FETCHING_RELEASE_NOTES, state => ({
                ...state,
                hasError: true
            }))
            .build();
    }
}
