import { take, map, distinctUntilChanged, combineLatest } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { ChartConfiguration, ChartModel, ChartOrder } from '../types';
import { Observable } from 'rxjs';
import { ActiveChartModel } from './activeChartModel.service';
import { DimensionId, Sorting, SortingOption } from 'insightui.data/types';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { ChartOrderService, DEFAULT_SORTING } from './chartOrder.service';
import { MANUAL_SORTING, SortingOptionsService } from './sortingOptions.service';
import * as _ from 'lodash';

@Injectable()
export class ActiveChartSorting {
    private chartDataNotificationService;

    constructor(
        @Inject('$injector') $injector,
        private activeChartModel: ActiveChartModel,
        private visualizationContext: VisualizationContextService,
        private sortingOptionsService: SortingOptionsService,
        private chartOrderService: ChartOrderService
    ) {
        this.chartDataNotificationService = $injector.get('ChartDataNotificationService');
    }

    currentSorting(configuration: ChartConfiguration): Observable<Sorting> {
        return this.activeChartModel.chartModel(configuration).pipe(
            map(() => this.getCurrentSorting()),
            distinctUntilChanged()
        );
    }

    isSelectable(configuration: ChartConfiguration): Observable<boolean> {
        return this.sortingOptionsService
            .sortingOptionsForConfiguration(configuration)
            .pipe(map(options => options.filter(option => option.selectable).length > 0));
    }

    getSelectedSortingOptions(
        configuration: ChartConfiguration
    ): Observable<SortingOption[]> {
        const currentSorting$ = this.currentSorting(configuration);

        return this.sortingOptionsService
            .sortingOptionsForConfiguration(configuration)
            .pipe(
                combineLatest(currentSorting$, (sortingOptions, sorting: Sorting) => {
                    return sortingOptions.map((option: SortingOption) => ({
                        ...option,
                        selected: this.isSortingEqual(option.sorting, sorting)
                    }));
                })
            );
    }

    changeSorting(configuration: ChartConfiguration, sorting: Sorting): void {
        this.propagateNewChartOrder(configuration, (model: ChartModel) => {
            const newOrder = this.chartOrderService.orderForChart(model, sorting);

            return { order: newOrder, sorting };
        });
    }

    changeSortingManually(
        configuration: ChartConfiguration,
        newOrder: DimensionId[]
    ): void {
        this.propagateNewChartOrder(configuration, () => {
            return { order: newOrder, sorting: MANUAL_SORTING };
        });
    }

    private getCurrentSorting(): Sorting {
        const existing: ChartOrder = this.visualizationContext.get('chartOrder');
        return _.get(existing, 'sorting', DEFAULT_SORTING);
    }

    private isSortingEqual(a: Sorting, b: Sorting): boolean {
        return a.direction === b.direction && a.target === b.target;
    }

    private propagateNewChartOrder(
        configuration: ChartConfiguration,
        newOrderFromModel: (model: ChartModel) => ChartOrder
    ): void {
        this.activeChartModel
            .chartModel(configuration)
            .pipe(
                map(newOrderFromModel),
                take(1)
            )
            .subscribe(newOrder => {
                this.visualizationContext.set('chartOrder', newOrder);
                this.chartDataNotificationService.notify('ChartDataChanged');
            });
    }
}
