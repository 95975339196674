import { State, Selector, Action, StateContext, createSelector } from '@ngxs/store';
import {
    Revision,
    CountryInfo,
    DeliveryInfo,
    LoadStatusInfo,
    HierarchyTree
} from '../metadata.types';
import {
    MsLoadRevisionsSuccess,
    MsCountriesLoaded,
    MsCurrentCountryChanged,
    MsCurrentPeriodIdChanged,
    MsCurrentRevisionIdChanged,
    MsDeliveryInfosOfCurrentCountryChanged,
    MsDeliveryInfoChanged,
    MsHierarchyChanged,
    MsLoadStatusChanged
} from './metadata.actions';
import { isNumber } from 'util';
import { Injectable } from '@angular/core';

export interface MetadataStateModel {
    readonly currentDocumentRevisionId: number;
    readonly currentPeriodId: number;
    readonly currentCountry: string;
    readonly revisions: Revision[];
    readonly countries: CountryInfo[];
    readonly deliveryInfo: DeliveryInfo;
    readonly deliveryInfosOfCurrentCountry: DeliveryInfo[];
    readonly loadStatusInfos: LoadStatusInfo[];
    readonly hierarchyTrees: HierarchyTree[];
}

@Injectable()
@State<MetadataStateModel>({
    name: 'metadata',
    defaults: {
        currentDocumentRevisionId: null,
        currentPeriodId: null,
        currentCountry: null,
        revisions: [],
        countries: [],
        deliveryInfo: null,
        deliveryInfosOfCurrentCountry: null,
        loadStatusInfos: null,
        hierarchyTrees: []
    }
})
export class MetadataState {
    @Selector()
    public static metadata(state: MetadataStateModel) {
        return state;
    }

    @Selector()
    public static revisions(state: MetadataStateModel) {
        return state.revisions;
    }

    @Selector()
    static deliveryInfo(state: MetadataStateModel) {
        return state.deliveryInfo;
    }

    @Selector()
    static deliveryInfosOfCurrentCountry(state: MetadataStateModel) {
        return state.deliveryInfosOfCurrentCountry;
    }

    // Do not use @Selector() decorator on this! Won't work.
    // see: https://www.ngxs.io/concepts/select#the-order-of-interacting-selectors
    // could work with the right parameter though
    static hierarchyDimensions(id: string) {
        return createSelector(
            [MetadataState.deliveryInfo],
            (di: DeliveryInfo) => {
                return di && di.hierarchyDimensions
                    ? di.hierarchyDimensions[id]
                    : undefined;
            }
        );
    }

    // Do not use @Selector() decorator on this! Won't work.
    // see: https://www.ngxs.io/concepts/select#the-order-of-interacting-selectors
    // could work with the right parameter though
    static allHierarchyDimensions() {
        return createSelector(
            [MetadataState.deliveryInfo],
            (di: DeliveryInfo) => {
                return di ? Object.values(di.hierarchyDimensions) : undefined;
            }
        );
    }

    static hierarchyChildDimensions(parentId: string) {
        return createSelector(
            [MetadataState.deliveryInfo],
            (di: DeliveryInfo) => {
                return di && di.hierarchyDimensions
                    ? Object.values(di.hierarchyDimensions).filter(
                          dim => dim.parentId === parentId
                      )
                    : undefined;
            }
        );
    }

    static hierarchyDimensionsInAllPeriods(id: string) {
        return createSelector(
            [MetadataState.deliveryInfosOfCurrentCountry],
            (deliveryInfos: DeliveryInfo[]) => {
                if (!deliveryInfos) {
                    return undefined;
                }

                const mapped = deliveryInfos.map(di => ({
                    period: di.content.period,
                    dimension: di.hierarchyDimensions[id]
                }));

                return mapped;
            }
        );
    }

    @Action(MsLoadRevisionsSuccess)
    revisionsLoaded(
        ctx: StateContext<MetadataStateModel>,
        action: MsLoadRevisionsSuccess
    ) {
        ctx.patchState({
            revisions: action.revisions
        });
    }

    @Action(MsCountriesLoaded)
    countriesLoaded(ctx: StateContext<MetadataStateModel>, action: MsCountriesLoaded) {
        ctx.patchState({
            countries: action.countries
        });
    }

    @Action(MsCurrentCountryChanged)
    currentCountryChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsCurrentCountryChanged
    ) {
        ctx.patchState({
            currentCountry: action.country
        });
    }

    @Action(MsCurrentPeriodIdChanged)
    currentPeriodIdChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsCurrentPeriodIdChanged
    ) {
        ctx.patchState({
            // even if SONAR complains, TSLint throws an error without these casts
            currentPeriodId: isNumber(action.periodId)
                ? (action.periodId as number)
                : parseInt(action.periodId as string, 10)
        });
    }

    @Action(MsCurrentRevisionIdChanged)
    currentRevisionIdChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsCurrentRevisionIdChanged
    ) {
        ctx.patchState({
            currentDocumentRevisionId: action.revisionId
        });
    }

    @Action(MsDeliveryInfosOfCurrentCountryChanged)
    deliveryInfosOfCurrentCountryChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsDeliveryInfosOfCurrentCountryChanged
    ) {
        ctx.patchState({
            deliveryInfosOfCurrentCountry: action.deliveryInfos
        });
    }

    @Action(MsDeliveryInfoChanged)
    deliveryInfoChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsDeliveryInfoChanged
    ) {
        ctx.patchState({
            deliveryInfo: action.deliveryInfo
        });
    }

    @Action(MsLoadStatusChanged)
    loadStatusInfoChanged(
        ctx: StateContext<MetadataStateModel>,
        action: MsLoadStatusChanged
    ) {
        ctx.patchState({
            loadStatusInfos: action.loadStatusInfos
        });
    }

    @Action(MsHierarchyChanged)
    hierarchyChanged(ctx: StateContext<MetadataStateModel>, action: MsHierarchyChanged) {
        ctx.patchState({
            hierarchyTrees: action.hierarchyTrees
        });
    }
}
