import {
    fromEvent as observableFromEvent,
    timer as observableTimer,
    Observable
} from 'rxjs';

import { takeUntil, timeInterval, take } from 'rxjs/operators';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DOCUMENT } from 'insightui.global/global.module';

/**
 * Component for a range constrained input spinner with a custom display format on blur.
 *
 * Increases/decreases the value as long as the buttons are pressed and
 * allows direct text input.
 */
@Component({
    selector: 'input-spinner-v2',
    templateUrl: './input-spinner.component.html'
})
export class InputSpinnerComponent {
    readonly INTERVAL = 250;

    /**
     * The step size for the spinner buttons (default 0.5).
     * @type {number}
     */
    @Input()
    step: number = 0.5;

    /**
     * The current value of the input
     * @return {number}
     */
    @Input()
    get value() {
        return this.rawValue;
    }

    set value(val) {
        if (val < this.min) {
            val = this.min;
        } else if (val > this.max) {
            val = this.max;
        }
        this.rawValue = val;
        this.onUpdate.emit(val);
    }

    /**
     * The minimal value the input can reach.
     * Lower values will be saturated to this value.
     */
    @Input()
    min: number = Number.NEGATIVE_INFINITY;

    /**
     * The maximum value the input can reach.
     * Higher values will be saturated to this value.
     */
    @Input()
    max: number = Number.POSITIVE_INFINITY;

    /**
     * A custom pipe for formatting the input,
     */
    @Input()
    displayFormat?: { transform: { (v: string | number): string } };

    /**
     * Triggered when the value updates and emits a number with the new value
     * @type {EventEmitter}
     */
    @Output()
    onUpdate: EventEmitter<number> = new EventEmitter();

    /**
     * Internal state variable for holding the non-formatted numeric value.
     */
    private rawValue = 0;

    /**
     * Internal flag to determine whether the {@link displayFormat} pipe should be used
     * for rendering the actual number or not.
     */
    displayRawValue = false;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    /**
     * The transformed display string visible in the textbox when no focus is given.
     */
    get displayValue(): string {
        if (!this.displayRawValue && this.displayFormat) {
            return this.displayFormat.transform(this.value);
        }
        return String(this.value);
    }

    /**
     * Increase the number value until the mouse is released.
     */
    startIncrease() {
        this.doUntilMouseUp(() => {
            this.value += this.step;
        });
    }

    /**
     * Update the value with the value from the text input.
     *
     * As the text input will be formatted using the displayFormat transformer, the
     * transient element value is directly accessed and used for updating.
     * @param $event    A change Event.
     */
    onValueEntered($event) {
        if (!Number.isFinite(Number($event.target.value))) {
            return;
        }
        this.value = $event.target.value;
        $event.target.blur();
    }

    /**
     * Decrease the number value until the mouse is released.
     */
    startDecrease() {
        this.doUntilMouseUp(() => {
            this.value -= this.step;
        });
    }

    private doUntilMouseUp(fn: () => void) {
        fn();
        observableTimer(this.INTERVAL, this.INTERVAL)
            .pipe(
                timeInterval(),
                takeUntil(observableFromEvent(this.document, 'blur').pipe(take(1))),
                takeUntil(observableFromEvent(this.document, 'mouseup').pipe(take(1)))
            )
            .subscribe(fn.bind(this));
    }
}
