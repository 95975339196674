import { Injectable } from '@angular/core';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CrossWindowNotification {
    private readonly KEY_COLLECTIONMANAGER_CHANGE =
        'notification.collectionManager.change';
    private readonly KEY_COLLECTIONMANAGER_CLOSE = 'notification.collectionManager.close';
    private logger: ILogger;

    private somethingInCollectionsChanged$$ = new Subject<void>();

    private forceCollectionManagerClose$$ = new Subject<void>();

    get somethingInCollectionsChanged$(): Observable<void> {
        return this.somethingInCollectionsChanged$$.asObservable();
    }

    get forceCollectionManagerClose$(): Observable<void> {
        return this.forceCollectionManagerClose$$.asObservable();
    }

    constructor(logService: LogService) {
        this.logger = logService.getLogger('CrossWindowNotification');
        window.addEventListener('storage', this.onStorageChanged.bind(this));
    }

    notifyCollectionManagerChange() {
        this.emitStorageEvent(
            this.KEY_COLLECTIONMANAGER_CHANGE,
            new Date().toISOString()
        );
    }

    notifyCollectionManagerClose(): void {
        this.emitStorageEvent(this.KEY_COLLECTIONMANAGER_CLOSE, new Date().toISOString());
    }

    private emitStorageEvent(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }
    private onStorageChanged(evt: StorageEvent) {
        this.logger.debug(`Received CrossWindowNotification with key '${evt.key}'`, evt);
        if (evt.key === this.KEY_COLLECTIONMANAGER_CHANGE) {
            this.somethingInCollectionsChanged$$.next();
        } else if (evt.key === this.KEY_COLLECTIONMANAGER_CLOSE) {
            this.forceCollectionManagerClose$$.next();
        }
    }
}
