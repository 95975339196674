import { Component, Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { AnalyticsService } from '../../services/analytics.service';

@Directive({
    selector: 'gfk-landing-page'
})
export class LandingPageDirective extends UpgradeComponent {
    @Input() notifications: string[] = ['hola'];

    constructor(
        elementRef: ElementRef,
        injector: Injector,
        matomoTrackerService: AnalyticsService
    ) {
        // We must pass the name of the directive as used by AngularJS to super
        super('landingPage', elementRef, injector);
        matomoTrackerService.pageTrack();
    }
}

@Component({
    template: `
        <gfk-landing-page></gfk-landing-page>
    `,
    providers: [
        // Need to provide a new scope, because UpgradeComponent does not provide one
        {
            provide: '$scope',
            useFactory: i => i.get('$rootScope').$new(),
            deps: ['$injector']
        }
    ]
})
export class LandingPageComponent {
    constructor() {}
}
