import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ColumnType } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { BrandHighlightService } from 'insightui.table/services/brand-highlighting.service';
import { TablePluginRegistry } from 'insightui.table/services/table-plugin-registry.service';
import { SearchDatatableService } from 'insightui.table/components/menu/search-datatable.service';
import { EmptySearchService } from 'insightui.table/components/datatable/body/empty-search.service';
import { Cell } from './cell-cache.service';
export interface NestedHeader {
    format?: { [key: string]: number | string };
    key: string;
    label: string;
    nestedCols?: NestedHeader[];
}
export interface ExportDataRow {
    data;
    type?: string;
}
export interface ExcelExport {
    sideHeader: NestedHeader[];
    topHeader: NestedHeader[];
    value: ExportDataRow[];
    metadata;
}

export interface ExportableTable {
    getHeaderConfig();
    getColumnConfig();
    getReportingTotalData();
    getReportingDataSet();
    getReportingSubTotalData();
    getCellCache();
}

/**
 * Service for extracting data from the datatable to export it to excel.
 */
@Injectable()
export class TableExportService {
    readonly BLACK_LIST_VALUES = ['<Total>', '<SubTotal>'];
    private exportableTable: ExportableTable;

    constructor(
        private brandHighlightService: BrandHighlightService,
        private pluginRegistry: TablePluginRegistry,
        private searchDatatableService: SearchDatatableService,
        private emptySearchService: EmptySearchService
    ) {}

    registerTable(exportableTable: ExportableTable) {
        this.exportableTable = exportableTable;
    }

    unregisterTable() {
        this.exportableTable = null;
    }

    getSearchString(): string {
        return this.searchDatatableService.getSearchString();
    }

    exportExcel(): ExcelExport {
        const headerConfig = this.exportableTable.getHeaderConfig();
        const columnConfig = this.exportableTable.getColumnConfig();

        const lastIndexSideHeader = _.findLastIndex(headerConfig, (item: any) => {
            return item.type === ColumnType.SIDE_HEADER;
        });

        // for request to FilExportServices we must NOT include column ids
        const sideHeader = this.buildSideHeader(
            lastIndexSideHeader,
            headerConfig,
            columnConfig
        );
        const topHeader = this.buildTopHeader(
            lastIndexSideHeader,
            headerConfig,
            columnConfig
        );

        // if we want to reference cells in the cell cache (which we need for performance
        // indicators), we have to add the column ids - therefore we create the haeders
        // again - with 'includeColumnIds' flag set to true
        const sideHeaderIncludingId = this.buildSideHeader(
            lastIndexSideHeader,
            headerConfig,
            columnConfig,
            true
        );
        const topHeaderIncludingId = this.buildTopHeader(
            lastIndexSideHeader,
            headerConfig,
            columnConfig,
            true
        );

        const headerIdsAndKeys = this.flattenHeader(sideHeaderIncludingId).concat(
            this.flattenHeader(topHeaderIncludingId)
        );

        const data = this.exportableTable.getReportingDataSet();
        const totalValues = [];
        if (this.exportableTable.getReportingTotalData()) {
            totalValues.push(this.exportableTable.getReportingTotalData());
        }
        const subTotalValues = [];
        if (this.exportableTable.getReportingSubTotalData()) {
            subTotalValues.push(this.exportableTable.getReportingSubTotalData());
        }

        const emptyData = [];
        if (this.emptySearchService.getIsActive()) {
            const jsonVariable = {};
            jsonVariable[headerConfig[0].field] =
                'There are no items matching your search string.';
            emptyData.push(jsonVariable);
        }

        // metadata
        const metadata = [];
        const exportData: ExcelExport = {
            sideHeader,
            topHeader,
            value: this.buildValues(totalValues, headerIdsAndKeys, 'totals')
                .concat(
                    this.buildValues(
                        emptyData.length > 0 ? emptyData : data,
                        headerIdsAndKeys,
                        null
                    )
                )
                .concat(
                    this.buildValues(subTotalValues, headerIdsAndKeys, 'subtotals')
                ) as any,
            metadata
        };
        this.pluginRegistry
            .getRegistered()
            .forEach(plugin => plugin.extendExport(exportData as ExcelExport));

        return exportData;
    }

    private buildSideHeader(
        lastIndexSideHeader,
        headerConfig,
        columnConfig,
        includeColumnId?
    ) {
        const sideHeaderRaw: any = _.slice(headerConfig, 0, lastIndexSideHeader + 1);
        const sideHeader = [];
        for (const shr of sideHeaderRaw) {
            if (shr.dimension && shr.dimension === 'kpi') {
                sideHeader.push(this.buildHeader(shr, columnConfig, includeColumnId));
            } else {
                let sideHeaderSH = sideHeader.find(sh => {
                    return sh.key === 'SH';
                });
                if (!sideHeaderSH) {
                    sideHeaderSH = {
                        key: 'SH',
                        label: null,
                        nestedCols: []
                    };
                    sideHeader.push(sideHeaderSH);
                }
                sideHeaderSH.nestedCols.push(
                    this.buildHeader(shr, columnConfig, includeColumnId)
                );
            }
        }
        return sideHeader;
    }

    private buildTopHeader(
        lastIndexSideHeader,
        headerConfig,
        columnConfig,
        includeColumnId?
    ) {
        const topHeaderRaw = _.slice(headerConfig, lastIndexSideHeader + 1);
        const topHeader = topHeaderRaw.map(thr =>
            this.buildHeader(thr, columnConfig, includeColumnId)
        );
        return topHeader;
    }

    private buildHeader(topHeaderObject, columnConfig, includeColumnId?) {
        const cols = _.map(_.get(topHeaderObject, 'children', []), child => {
            return this.buildHeader(child, columnConfig, includeColumnId);
        });

        const columnDef = columnConfig.filter(
            item => item.field === topHeaderObject.field
        )[0];

        const key =
            _.get(topHeaderObject, 'field', null) !== null
                ? _.get(topHeaderObject, 'field', null)
                : _.get(topHeaderObject, 'id', null);
        const label = _.get(topHeaderObject, 'name', null);

        const res = {
            key,
            label,
            nestedCols: cols
        };

        // the id of the column definition (used as key in cell cache)
        if (includeColumnId) {
            const id = _.get(columnDef, 'id', null);
            if (id) {
                res['id'] = id;
            }
        }

        if (
            _.get(columnDef, 'dataType', null) !== null ||
            _.get(columnDef, 'fractionalDigits', null) !== null
        ) {
            _.set(res, 'format', {
                type: _.get(columnDef, 'dataType', null)
                    ? _.get(columnDef, 'dataType')
                    : 'alphanumeric',
                digits: _.get(columnDef, 'fractionalDigits', 0)
            });
        } else {
            _.set(res, 'format', {
                type: 'alphanumeric',
                digits: 0
            });
        }
        return res;
    }

    private flattenHeader(header): { id: string; key: string }[] {
        const aggregator: { id: string; key: string }[] = [];
        this.flatten(header, aggregator, []);
        return aggregator;
    }

    private flatten(header, aggregator, keys) {
        _.forEach(header, headerItem => {
            const levelKeys = _.cloneDeep(keys);
            const key = _.get(headerItem, 'key', null);
            if (
                _.startsWith(key, 'KPI') ||
                _.startsWith(key, 'CH') ||
                _.startsWith(key, 'RT') ||
                _.startsWith(key, 'PT')
            ) {
                keys.push(key);
            }
            if (_.get(headerItem, 'nestedCols', []).length > 0) {
                this.flatten(_.get(headerItem, 'nestedCols'), aggregator, keys);
            } else {
                aggregator.push({
                    id: _.get(headerItem, 'id'),
                    key: _.get(headerItem, 'key')
                });
            }
            keys = levelKeys;
        });
    }

    private buildValues(values, headerIdsAndKeys: { id: string; key: string }[], type) {
        const highlightBrandName = this.brandHighlightService.brandName;
        const cellCache = this.exportableTable.getCellCache();

        return _.compact(
            _.map(values, value => {
                // console.log('buildValues - value', value);

                // filter row
                if (
                    type === 'totals' &&
                    _.indexOf(
                        _.values(_.get(value, 'data')),
                        'TotalFilteredAgainstMarket'
                    ) > -1
                ) {
                    return null;
                }

                const rowFromCache = cellCache.getRow(value);
                // console.log('buildValues - rowFromCache', rowFromCache);

                const data: any = _.map(headerIdsAndKeys, idAndKey => {
                    // console.log('buildValues - header', idAndKey);

                    const headerKey = idAndKey.key;

                    let val = null;
                    if (_.has(value, headerKey)) {
                        val = _.get(value, headerKey);
                    } else if (_.has(value, ['data', headerKey])) {
                        val = _.get(value, ['data', headerKey]);
                    }

                    const res = {
                        value: _.indexOf(this.BLACK_LIST_VALUES, val) < 0 ? val : null
                    };

                    if (
                        !_.startsWith(headerKey, 'KPI') &&
                        _.get(value, 'disabledDrillDown', false)
                    ) {
                        _.set(res, 'class', 'notAvailable');
                    }

                    if (rowFromCache) {
                        const cellFromCache: Cell = rowFromCache[idAndKey.id];
                        // console.log('buildValues - cellFromCache', cellFromCache);

                        if (
                            cellFromCache &&
                            cellFromCache.indicator &&
                            !cellFromCache.indicator.isNeutral
                        ) {
                            res['class'] = cellFromCache.indicator.isPositive
                                ? 'good'
                                : 'bad';
                        }
                    }

                    return res;
                });

                data.$id = value.$id;

                const result = {};
                if (type) {
                    _.set(result, 'type', type);
                } else {
                    if (!!highlightBrandName) {
                        this.setBrandHighlight(
                            data,
                            headerIdsAndKeys.map(hik => hik.key),
                            highlightBrandName
                        );
                    }
                }

                return _.set(result, 'data', data);
            })
        );
    }

    private setBrandHighlight(data, flatHeader, highlightBrandName) {
        const brandIndex = _.indexOf(flatHeader, 'BR');
        const brand = _.get(data, [brandIndex, 'value'], '');
        if (highlightBrandName.toLowerCase() !== brand.toLowerCase()) {
            return;
        }
        _.forEach(flatHeader, (header, index) => {
            const valueObj = _.get(data, index);
            _.set(valueObj, 'class', 'brandHighlight');
        });
    }
}
