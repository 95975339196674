import { Injectable } from '@angular/core';
import { ActiveChartModel } from './activeChartModel.service';
import { KpiTitleService } from './kpiTitle.service';
import { ChartConfiguration } from '../types';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import {
    DimensionId,
    DimensionName,
    Sorting,
    SortingDefinition,
    SortingOption
} from 'insightui.data/types';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { defaultIfEmpty, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

export const MANUAL_SORTING: Sorting = { target: 'User', direction: null };

const NULL_SORTING_CONFIG: SortingDefinition = {
    dimension: '',
    notSortable: [],
    options: []
};

@Injectable()
export class SortingOptionsService {
    private logger: ILogger;

    constructor(
        private pageDefinitionService: PageDefinitionService,
        private activeChartModel: ActiveChartModel,
        private kpiTitleService: KpiTitleService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('SortingOptionsService');
    }

    sortingOptionsForConfiguration(
        configuration: ChartConfiguration
    ): Observable<ReadonlyArray<SortingOption>> {
        return this.getSortingConfiguration(configuration).pipe(
            switchMap(sortingConfig => {
                return this.replaceKpiTitlePlaceholder(sortingConfig.options);
            }),
            defaultIfEmpty([])
        );
    }

    notSortableDimensionsForConfiguration(
        configuration: ChartConfiguration
    ): Observable<ReadonlyArray<DimensionId>> {
        return this.getSortingConfiguration(configuration).pipe(
            map(sortingConfig => sortingConfig.notSortable),
            defaultIfEmpty([])
        );
    }

    canSortManually(configuration: ChartConfiguration): Observable<boolean> {
        return this.getSortingConfiguration(configuration).pipe(
            map(sortingConfig =>
                sortingConfig.options.some(option => this.isManualSortingOption(option))
            ),
            defaultIfEmpty(false),
            distinctUntilChanged()
        );
    }

    private isManualSortingOption(sortingOption: SortingOption): boolean {
        const sorting = sortingOption.sorting;

        return sorting.target === 'User';
    }

    private getSortingConfiguration(
        configuration: ChartConfiguration
    ): Observable<SortingDefinition> {
        return this.pageDefinitionService.currentPage$.pipe(
            switchMap(pageDefinition => {
                this.logger.debug('getSortingConfiguration', pageDefinition);

                const sortingDefs =
                    pageDefinition && pageDefinition.report
                        ? pageDefinition.report.sortingOptions
                        : null;
                if (sortingDefs === null) {
                    return EMPTY;
                }

                return this.activeChartModel.lastDimension(configuration).pipe(
                    map((dimension: DimensionName) => {
                        const configForDimension: SortingDefinition = _.find(
                            sortingDefs,
                            (sortingDef: SortingDefinition) =>
                                sortingDef.dimension === dimension
                        );

                        return !configForDimension
                            ? NULL_SORTING_CONFIG
                            : configForDimension;
                    })
                );
            })
        );
    }

    private replaceKpiTitlePlaceholder(
        sortingOptions: ReadonlyArray<SortingOption>
    ): Observable<ReadonlyArray<SortingOption>> {
        return this.kpiTitleService.titleForSelectedKpi().pipe(
            map(kpiTitle => {
                return sortingOptions.map(option => ({
                    ...option,
                    title: option.title.replace(/{{kpiTitle}}/g, kpiTitle)
                }));
            })
        );
    }
}
