import { NgModule, ModuleWithProviders } from '@angular/core';
import { BASE_PATH as USERPROFILE_BASE_PATH } from './userprofile';
import { BASE_PATH as NOTIFICATION_BASE_PATH } from './notification';
import { INSIGHT_CONFIG, InsightConfig } from 'insightui.global/global.module';
import { TokenInterceptor } from './token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ClientIdInterceptor } from './client-id.interceptor';
import { TraceIdInterceptor } from './trace-id.interceptor';

export const getUserProfileBasePath = (config: InsightConfig) => {
    return config.services.USERPROFILE_SERVICE.url;
};

export const getNotificationBasePath = (config: InsightConfig) => {
    return config.services.NOTIFICATION_SERVICE.url;
};

/**
 * This module configures the base urls for the generated OpenAPI client services
 * depending on the global InsightUI config.
 * It also registers an HttpInterceptor which adds the Authorization header to each
 * outgoing request and another HttpInterceptor which adds the queryParam "clientId"
 * to each outgoing request if it exists.
 */
@NgModule()
export class BaseUrlModule {
    static forRoot(): ModuleWithProviders<BaseUrlModule> {
        return {
            ngModule: BaseUrlModule,
            providers: [
                {
                    provide: USERPROFILE_BASE_PATH,
                    useFactory: getUserProfileBasePath,
                    deps: [INSIGHT_CONFIG]
                },
                {
                    provide: NOTIFICATION_BASE_PATH,
                    useFactory: getNotificationBasePath,
                    deps: [INSIGHT_CONFIG]
                },
                { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ClientIdInterceptor,
                    multi: true
                },
                { provide: HTTP_INTERCEPTORS, useClass: TraceIdInterceptor, multi: true }
            ]
        };
    }
}
