import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gfkDashIfEmpty'
})
export class DashIfEmptyPipe implements PipeTransform {
    transform(value: any): any {
        if (value == null) {
            return '-';
        }

        return value;
    }
}
