import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Inject,
    Optional,
    AfterViewChecked
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';
import { ClientInfo } from '../client-switch.types';
import { ClientSwitchState } from '../state/client-switch.state';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Component({
    selector: 'gfk-client-switch-dialog',
    templateUrl: './client-switch-dialog.component.html',
    styleUrls: ['./client-switch-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSwitchDialogComponent implements OnInit, AfterViewChecked {
    private logger: ILogger;

    selectedClientId: string;
    searchText: string;
    clients$: Observable<ClientInfo[]>;
    clients: ClientInfo[];
    scrollToSelectedClientId: string;
    sortByField = 'name';
    sortAscending = true;

    constructor(
        private dialogRef: MatDialogRef<ClientSwitchDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        initialValue: {
            clientId: string;
        },
        store: Store,
        logService: LogService
    ) {
        this.logger = logService.getLogger('ClientSwitchDialogComponent');

        if (initialValue) {
            this.selectedClientId = initialValue.clientId;
            this.scrollToSelectedClientId = this.selectedClientId;
        }
        this.searchText = '';

        this.clients$ = store.select(ClientSwitchState.clients).pipe(
            map(clients => {
                const filtered = clients.filter(c => this.clientMatchesSearch(c));
                filtered.sort((a, b) => this.sortClients(a, b));
                return filtered;
            })
        );
    }

    ngOnInit() {
        this.updateFilteredClients();
    }

    ngAfterViewChecked(): void {
        if (this.scrollToSelectedClientId) {
            this.scrollClientIntoView(this.scrollToSelectedClientId);
            this.scrollToSelectedClientId = undefined;
        }
    }

    scrollClientIntoView(clientId: string) {
        const element = document.querySelector('#client-' + clientId);
        if (element) {
            element.scrollIntoView({
                block: 'nearest'
            });
        }
    }

    updateFilteredClients() {
        this.clients$.pipe(take(1)).subscribe(clients => (this.clients = clients));
    }

    sortClients(a: ClientInfo, b: ClientInfo): number {
        let result = 0;

        try {
            if (this.sortByField === 'id') {
                result = parseInt(a.id, 10) > parseInt(b.id, 10) ? 1 : -1;
            } else if (this.sortByField === 'name') {
                result = a.name.localeCompare(b.name);
            }

            if (!this.sortAscending) {
                result = -result;
            }
        } catch {}

        return result;
    }

    clientMatchesSearch(client: ClientInfo): boolean {
        // never display GfK (TODO: find a better solution in backend)
        if (client.id === '1001') {
            return false;
        }

        const searchString = this.searchText.toLowerCase();

        return (
            searchString.length === 0 ||
            // keep client in the list if it is currently selected...
            client.id === this.selectedClientId ||
            // ...or client id matches...
            client.id.toLowerCase().search(searchString) > -1 ||
            // ...or client name matches
            client.name.toLowerCase().search(searchString) > -1
        );
    }

    selectClient(id: string) {
        // enables select/unselect
        this.selectedClientId = this.selectedClientId === id ? '' : id;
    }

    doubleClicked(id: string) {
        this.selectClient(id);
        this.submitDialog();
    }

    sortBy(sortByField: string) {
        if (sortByField === this.sortByField) {
            this.sortAscending = !this.sortAscending;
        } else {
            this.sortByField = sortByField;
            this.sortAscending = true;
        }

        this.onSearchTextChanged(this.searchText);
    }

    onSearchTextChanged(searchText: string) {
        this.searchText = searchText;
        this.updateFilteredClients();

        // keep selected client in view
        if (this.selectedClientId) {
            this.scrollToSelectedClientId = this.selectedClientId;
        }
    }

    submitDialog() {
        this.dialogRef.close({
            clientId: this.selectedClientId
        });
    }

    exitClientView() {
        this.selectedClientId = '';
        this.submitDialog();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}
