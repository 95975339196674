import {
    BaseTablePluginOptions,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Injector } from '@angular/core';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';

const MARGIN_REMOVE_ICON = 20;
const MARGIN_SORT_ICON = 12;
const MARGIN_INFO_ICON = 17;

export class HeaderLabelPlugin extends TableHeaderPlugin {
    name = 'HeaderLabelPlugin';

    constructor(injector: Injector, configuration: BaseTablePluginOptions) {
        super(injector, configuration);
    }

    select(cell: DatatableHeaderCellComponent | Cell): boolean {
        if (cell instanceof DatatableHeaderCellComponent) {
            return true;
        }
        return false;
    }

    protected doEnable(cell: Cell) {
        let rootNode = cell.element.nativeElement;
        if (!rootNode || this.isActive(rootNode)) {
            return;
        }

        rootNode.mouseClickListener = event => this.hideIcons(rootNode);
        rootNode.addEventListener('click', rootNode.mouseClickListener);

        rootNode.mouseEnterListener = () => this.abbreviateText(rootNode);
        rootNode.addEventListener('mouseenter', rootNode.mouseEnterListener);

        rootNode.mouseLeaveListener = () => this.resetText(rootNode);
        rootNode.addEventListener('mouseleave', rootNode.mouseLeaveListener);

        this.markAsActive(rootNode);
    }

    protected doDisable(cell: Cell) {
        let rootNode = cell.element.nativeElement;
        if (!this.isActive(rootNode)) {
            return;
        }
        rootNode.removeEventListener('click', rootNode.mouseClickListener);
        rootNode.removeEventListener('mouseenter', rootNode.mouseEnterListener);
        rootNode.removeEventListener('mouseleave', rootNode.mouseLeaveListener);

        this.markAsInactive(rootNode);
    }

    abbreviateText(cell) {
        var parentMaxWidth = this.getCellWidth(cell.parentElement);
        var labelCell = cell.querySelector('.datatable-header-label');
        var textWidth = this.getTextWidth(labelCell.firstElementChild);
        var minMarginRight = this.getMarginRight(cell);

        if (
            parentMaxWidth &&
            textWidth &&
            parentMaxWidth / 2 - textWidth / 2 < minMarginRight
        ) {
            var labelMaxWidth = this.getCellWidth(labelCell);
            labelCell.style.width =
                (labelMaxWidth ? labelMaxWidth : parentMaxWidth) + 'px';
            labelCell.style.textAlign = 'left';
            labelCell.style.marginLeft = '2px';
            this.setLabelTextWidth(labelCell, parentMaxWidth - minMarginRight - 5);
        }
    }

    resetText(cell) {
        var labelCell = cell.querySelector('.datatable-header-label');
        labelCell.style.textAlign = 'center';

        if (!cell.parentElement.classList.contains('sortable-active')) {
            labelCell.style.width = null;
            var labelTextCell = cell.querySelector('.datatable-header-label-text');
            labelTextCell.style.width = null;
        } else {
            this.hideIcons(cell);
        }
    }

    hideIcons(cell) {
        var removeElement = cell.querySelector('.plugin-removable-header');
        if (removeElement) {
            removeElement.classList.remove('fade-header-icons');
            removeElement.style.opacity = 0;
        }
        var infoIconElement = cell.querySelector('.plugin-icon-info');
        if (infoIconElement) {
            infoIconElement.classList.remove('fade-header-icons');
            infoIconElement.style.opacity = 0;
        }

        var parentMaxWidth = this.getCellWidth(cell.parentElement);
        var labelCell = cell.querySelector('.datatable-header-label');
        var textWidth = this.getTextWidth(labelCell.firstElementChild);
        var minMarginRight = this.getMarginRight(cell);

        if (
            parentMaxWidth &&
            textWidth &&
            parentMaxWidth / 2 - textWidth / 2 < minMarginRight
        ) {
            var labelCell2 = cell.querySelector('.datatable-header-label');
            var parentMaxWidth2 = this.getCellWidth(cell.parentElement);
            this.setLabelTextWidth(labelCell2, parentMaxWidth2 - MARGIN_INFO_ICON - 5);
        }
    }

    private getMarginRight(cell) {
        let margin = 3;
        margin = this.isRemoveable(cell) ? margin + MARGIN_REMOVE_ICON : margin;
        margin = this.isSortable(cell) ? margin + MARGIN_SORT_ICON : margin;
        margin = this.hasInfo(cell) ? margin + MARGIN_INFO_ICON : margin;
        return margin;
    }

    private hasInfo(cell) {
        return cell.querySelector('.plugin-icon-info') ? true : false;
    }

    private isRemoveable(cell) {
        return cell.querySelector('.plugin-removable-header') ? true : false;
    }

    private isSortable(cell) {
        return cell.querySelector('.plugin-sortable-wrapper') ? true : false;
    }

    private setLabelTextWidth(cell, width) {
        var labelTextCell = cell.querySelector('.datatable-header-label-text');
        labelTextCell.style.width = width + 'px';
    }

    private getCellWidth(cell) {
        var style = cell.style;
        if (style && style.maxWidth) {
            return Number(style.maxWidth.replace('px', ''));
        }
        if (style && style.minWidth) {
            return Number(style.minWidth.replace('px', ''));
        }
        return null;
    }

    private getTextWidth(cell) {
        let element = document.createElement('canvas');
        let context = element.getContext('2d');
        context.font = '14px arial';
        return Math.ceil(context.measureText(cell.textContent).width);
    }

    extendExport(exportData) {
        return exportData;
    }
}
