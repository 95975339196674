import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Injector } from '@angular/core';
import { TooltipUtilService } from 'insightui.table/components/datatable/shared/services/tooltip-util.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

const FEATURE_MATCHER = /^FT/i;

export class FeatureTooltipPlugin extends TableBodyPlugin {
    name = 'FeatureTooltipPlugin';
    metadataService: MetadataService;
    tooltipUtilService: TooltipUtilService;
    mouseOverListener;
    mouseOutListener;
    timeout;

    POPUP_DELAY = 250;
    BLACKLIST = ['Total', 'SubTotal', 'Others', 'Tradebrand & Exclusive'];

    constructor(injector: Injector, configuration: BaseTablePluginOptions) {
        super(injector, configuration);
        this.metadataService = this.injector.get(MetadataService);
        this.tooltipUtilService = this.injector.get(TooltipUtilService);
    }

    select(cell): boolean {
        return (
            super.select(cell) &&
            cell.row &&
            this.hasFeatures(cell.row) &&
            this.BLACKLIST.indexOf(cell.row.TG) < 0
        );
    }

    protected doEnable(cell: Cell) {
        const rootNode: HTMLElement = cell.element.nativeElement;

        this.mouseOverListener = event => {
            this.timeout = setTimeout(() => {
                this.showTooltip(event, cell);
            }, this.POPUP_DELAY);
        };

        this.mouseOutListener = () => {
            clearTimeout(this.timeout);
            this.removeTooltip();
        };

        rootNode.addEventListener('mouseover', this.mouseOverListener);
        rootNode.addEventListener('mouseout', this.mouseOutListener);
    }

    protected doDisable(cell: Cell) {
        const rootNode: HTMLElement = cell.element.nativeElement;
        rootNode.removeEventListener('mouseover', this.mouseOverListener);
        rootNode.removeEventListener('mouseout', this.mouseOutListener);
    }

    private getTooltip() {
        return Array.from(document.body.children).find(c =>
            c.classList.contains('tooltip')
        );
    }

    private showTooltip(event, cell) {
        const tooltip = this.getTooltip();
        if (!tooltip) {
            const content = this.featureContentData(cell);
            const contentHtml = this.tooltipUtilService.getFeatureTooltip(
                cell.row.IT,
                content
            );
            document.body.appendChild(contentHtml);

            // reposition item
            const position = this.tooltipUtilService.getTooltipAnchor(
                event,
                contentHtml.clientWidth,
                contentHtml.clientHeight,
                5
            );
            contentHtml.style.top = position.top + 'px';
            contentHtml.style.left = position.left + 'px';
        }
    }

    private removeTooltip() {
        const tooltip = this.getTooltip();
        if (tooltip) {
            document.body.removeChild(tooltip);
        }
    }

    private featureContentData(cell) {
        const featureIds = Object.keys(cell.row)
            .filter(key => FEATURE_MATCHER.test(key) && key !== cell.row[key])
            .sort(); // sort asc
        return featureIds.map(featureId => {
            const feature = this.metadataService.getFeatureById(featureId);
            return {
                key: feature.title,
                value: cell.row[featureId]
            };
        });
    }

    private hasFeatures(row) {
        let hasFeature = false;
        Object.keys(row).forEach(key => {
            if (!hasFeature) {
                hasFeature = FEATURE_MATCHER.test(key);
            }
        });
        return hasFeature;
    }

    extendExport(exportData) {
        return exportData;
    }
}
