import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { ReportingDataSet } from 'insightui.data/query/queryservice/query-service-request.interface';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { Injectable } from '@angular/core';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';

/**
 * Service for removing rows on certain conditions
 */
@Injectable()
export class RemoveRowMapper implements ResponseMapper {
    private metadataSelectors: MetadataSelectors;
    private visualizationContext: VisualizationContextService;

    configure(
        metadataSelectors: MetadataSelectors,
        visualizationContext: VisualizationContextService
    ) {
        this.metadataSelectors = metadataSelectors;
        this.visualizationContext = visualizationContext;
    }

    map(reportingData: ReportingDataSet): ReportingDataSet {
        const featureList = this.visualizationContext.get('splitByFeatureList') || [];
        return reportingData.filter(row => {
            const id = String(row['$id'] || '').split('_')[0];

            const node = this.metadataSelectors.getHierarchyDimension(id);

            // return false, if dimension is notAvailable
            if (node && !node.hasAvailableData && !node.isAvailable) {
                return false;
            }

            if (featureList.length > 0) {
                return (
                    (row.PF && featureList.indexOf(String(row.PF).split('=')[0]) > -1) ||
                    String(row.PF).startsWith('PC') ||
                    String(row.$id).indexOf('GTwithFeature') > -1
                );
            }
            return true;
        });
    }
}
