<div class="[ report-type-overview ]"
    [ngClass]="{ 'report-type-overview--disabled': getPageId() === '207a' || getPageId() === '210a' || getPageId() === '102a' || getPageId() === '102b' }">
    <h1 (mousemove)="onMouseMove($event)" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
        {{ chapter.title }}
    </h1>
    <gfk-tooltip *ngIf="tooltipOpen" [isOpen]="tooltipOpen" [animation]="true" [positionX]="positionX"
        [positionY]="positionY" [tooltip]="chapter.tooltip">
    </gfk-tooltip>
    <gfk-select-favourite class="favourite-select-action uitest-Div-FSelect-Action" [chapter]="chapter"
        *ngIf="hasFavourite()" [favourites]="getFavourites()" [docId]="docId" [favouriteResolved]="favouriteResolved">
    </gfk-select-favourite>
    <div>
        <a #link href="{{ getLink(docId) }}" (click)="onReportClickFromLandingPage(getPageId())" target="_blank"
            class="lpBusinessQuestion uitest-Lnk-LP-OpenReport-Tile-{{ getPageId() }}">{{ chapter.question }}</a>
    </div>
    <a href="{{ getLink(docId) }}" (click)="onReportClickFromLandingPage(getPageId())" target="_blank"
        class="[ report-type-overview__image ]  [ uitest-Lnk-LP-OpenReport-{{ getPageId() }} ]">
        <img (mouseover)="setHighlight()" (mouseleave)="clearHighlight()" src="assets/images/{{ chapter.image }}"
            alt="{{ chapter.question }}" />
    </a>
</div>