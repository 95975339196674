import { Injectable } from '@angular/core';

const foldWithProp = <T, P extends keyof T>(
    f: (init: T[P], elem: T[P]) => T[P]
): ((init: T[P]) => (elems: T[], prop: P) => T[P]) => {
    return init => (elems, prop) => elems.reduce((acc, el) => f(acc, el[prop]), init);
};
const minimum = foldWithProp<ClientRect, 'left' | 'top'>(Math.min)(Infinity);
const maximum = foldWithProp<ClientRect, 'right' | 'bottom'>(Math.max)(-Infinity);

@Injectable()
export class HtmlElementUnionService {
    /**
     * Calculates the bounding box of the union of the element rects.
     * @param {Element[]} elements - the elements to get the bounding box for.
     * @returns {ClientRect} - the bounding box encompassing all elements.
     */
    boundingBox(elements: Element[]): ClientRect {
        const boundingRects = elements
            .map(element => {
                const rect = element.getBoundingClientRect();
                const { scrollTop, scrollLeft } = this.absoluteScrollOffset(element);

                return {
                    top: rect.top + scrollTop,
                    bottom: rect.bottom + scrollTop,
                    left: rect.left + scrollLeft,
                    right: rect.right + scrollLeft,
                    width: rect.width,
                    height: rect.height
                };
            })
            .filter(rect => rect.height > 0 && rect.width > 0);

        const left = minimum(boundingRects, 'left');
        const right = maximum(boundingRects, 'right');
        const top = minimum(boundingRects, 'top');
        const bottom = maximum(boundingRects, 'bottom');
        const width = right - left;
        const height = bottom - top;

        return { width, height, top, bottom, left, right };
    }

    private absoluteScrollOffset(
        element: Element
    ): { scrollLeft: number; scrollTop: number } {
        let scrollTop = 0;
        let scrollLeft = 0;
        let parent = element.parentElement;

        while (parent !== null) {
            scrollTop += parent.scrollTop;
            scrollLeft += parent.scrollLeft;
            parent = parent.parentElement;
        }

        return { scrollTop, scrollLeft };
    }
}
