import { NgRedux } from '@angular-redux/store';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { Observable } from 'rxjs';

/**
 * This component is the entrypoint for the application
 */
@Component({
    // need no selector because this will be triggered by AngularJS
    // where it gets it's selector during downgradeComponent.
    template: `
        <gfk-top-meta-bar></gfk-top-meta-bar>
        <router-outlet></router-outlet>
        <page-footer></page-footer>
        <div *ngIf="shouldDisplayLoadingIndicator$ | async" class="loadingIndicator">
            <div class="background-shader"></div>
            <gfk-loading-indicator
                style="z-index: 1"
                [message]="loadingMessage$ | async"
            ></gfk-loading-indicator>
        </div>
    `,
    styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {
    public shouldDisplayLoadingIndicator$: Observable<boolean>;
    public loadingMessage$: Observable<string>;

    constructor(private router: Router, private ngRedux: NgRedux<AppRootState>) {}
    ngOnInit(): void {
        this.shouldDisplayLoadingIndicator$ = this.ngRedux.select(
            state => state.loadingIndicator.pendingCount > 0
        );
        this.loadingMessage$ = this.ngRedux.select(
            state => state.loadingIndicator.message
        );

        // Note: this is the first Angular component which is started by AngularJS. Because it is
        // started by the UpgradeModule, the Angular Router does not trigger the initial navigation by default.
        // We trigger it manually here once we're ready.
        this.router.initialNavigation();
    }
}
