import { State, Selector, Action, StateContext } from '@ngxs/store';
import { ClientInfo } from '../client-switch.types';
import { CsLoadClientInfosSuccess } from './client-switch.actions';
import { Injectable } from '@angular/core';

export interface ClientSwitchStateModel {
    clients: ClientInfo[];
}

@Injectable()
@State<ClientSwitchStateModel>({
    name: 'clientSwitch',
    defaults: {
        clients: [
            // { id: '123', name: 'Abraham' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '34', name: 'Waterloo' },
            // { id: '223', name: 'Zodiac' },
            // { id: '4556', name: 'Birmingham' }
        ]
    }
})
export class ClientSwitchState {
    @Selector()
    public static clients(state: ClientSwitchStateModel) {
        return state.clients;
    }

    @Action(CsLoadClientInfosSuccess)
    loadCollections(
        ctx: StateContext<ClientSwitchStateModel>,
        action: CsLoadClientInfosSuccess
    ) {
        const clients = action.clientInfos.map(ci => ({
            id: ci.clientId,
            name: ci.clientName
        }));

        ctx.patchState({
            clients
        });
    }
}
