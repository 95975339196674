import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    DrillLevel,
    DrillTarget
} from 'insightui.data/query/queryservice/query-service-request.interface';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';
import { HierarchyInfo } from 'insightui.metadata/metadata.types';

/**
 * Drilling Service for navigation through drilling paths.
 */
export interface DrillPath {
    source: string;
    target: string;
    drillType: 'down' | 'up' | 'switch';
}

@Injectable()
export class DrillingService {
    @VisualizationContextBinding('drillTarget')
    vcDrillTarget: DrillTarget;
    @VisualizationContextBinding('selectedDimension')
    vcSelectedDimension: string;
    @VisualizationContextBinding('selectedDimensionTitle')
    vcSelectedDimensionTitle: string;
    @VisualizationContextBinding('itemInBrand')
    vcItemInBrand: string;
    @VisualizationContextBinding('brandName')
    vcBrandName: string;

    // TODO: Move hardcoded strings to Author Service
    private drillLevelNameMap = Object.freeze({
        sector: 'Sector',
        category: 'Category',
        productGroup: 'Product Group',
        brandInPg: 'Brand',
        brandInCat: 'Brand',
        itemInPg: 'Item'
    });

    private $injector: Promise<any>;

    constructor(
        private pageDefinitionService: PageDefinitionService,
        private metadataSelectors: MetadataSelectors
    ) {
        this.$injector = AngularInjectorResolver.waitForInjector();
    }

    public getAllDrillPaths(): DrillPath[] {
        return this.pageDefinitionService.getCurrentPage().report.drillPath;
    }

    public getCurrentDrillPaths(): DrillPath[] {
        return this.getAllDrillPaths()
            .filter(level => level.source === this.vcDrillTarget.drillLevel)
            .filter(level => this.reportSupportsLevel(level));
    }

    public drillDown(parentId: string, title: string) {
        const drillingOption = this.getCurrentDrillPaths().find(
            option => option.drillType === 'down'
        );

        if (drillingOption) {
            this.drillTo(parentId, title, drillingOption);
        } else {
            console.warn('No Drill Target matches this drilling operation');
        }
    }

    public drillUp() {
        const drillingOption = this.getCurrentDrillPaths().find(
            option => option.drillType === 'up'
        );
        if (typeof drillingOption !== 'object') {
            console.warn('No Drill Target matches this drilling operation');
        }

        this.metadataSelectors
            .getHierarchyDimension$(this.vcDrillTarget.dimensionId)
            .pipe(
                concatMap(currentDimension =>
                    this.vcItemInBrand
                        ? of(currentDimension)
                        : this.getParentDimension$(currentDimension)
                )
            )
            .subscribe(parentElement => {
                this.drillTo(parentElement.id, parentElement.title, drillingOption);
            });
    }

    public drillTo(parentId: string, title: string, drillPath: DrillPath) {
        const target = this.pageDefinitionService.getCurrentPage().report.drillLevel[
            drillPath.target
        ];
        if (target) {
            this.updateDrillTarget(parentId, title, target);
        } else {
            console.warn('Drilling ignored, target not defined', drillPath);
        }
    }

    public switchTo(drillPath: DrillPath) {
        if (drillPath.drillType !== 'switch') {
            return;
        }
        this.drillTo(
            this.vcDrillTarget.dimensionId,
            this.vcSelectedDimensionTitle,
            drillPath
        );
    }

    public getDrillLevel(): DrillLevel {
        if (!this.vcDrillTarget) {
            console.warn('No Drill Target in VisualizationContext');
            return null;
        }
        const drillLevelName = this.vcDrillTarget.drillLevel;
        const drillLevels =
            this.pageDefinitionService.getCurrentPage().report.drillLevel || {};

        return drillLevels[drillLevelName] || {};
    }

    public supports(direction: 'up' | 'down' | 'switch'): boolean {
        return (
            this.getCurrentDrillPaths().find(path => path.drillType === direction) !==
            undefined
        );
    }

    getLevelName(level: string) {
        return this.drillLevelNameMap[level];
    }

    isSupportedDimensionInHierarchy(): boolean {
        return (
            this.metadataSelectors.getHierarchyDimension(
                this.vcDrillTarget.dimensionId
            ) !== null
        );
    }

    private getParentDimension$(element: HierarchyInfo): Observable<HierarchyInfo> {
        if (!element.parentId) {
            throw new Error(
                'No Drill Target matches this drilling operation (no parent exists)'
            );
        }
        return this.metadataSelectors.getHierarchyDimension$(element.parentId);
    }

    private updateDrillTarget(parentId: string, title: string, drillTarget: DrillLevel) {
        // TODO: this level is inconsistent
        const requiresItemInBrandHack = drillTarget.id === 'itemInBrand';
        this.vcDrillTarget = {
            dimensionId: requiresItemInBrandHack
                ? this.vcDrillTarget.dimensionId
                : parentId,
            drillLevel: drillTarget.id
        };
        if (requiresItemInBrandHack) {
            this.vcItemInBrand = parentId;
            this.vcBrandName = title;
        } else {
            this.vcItemInBrand = null;
            this.vcBrandName = null;
            this.vcSelectedDimensionTitle = title;
        }

        this.vcSelectedDimension = drillTarget.id;
        this.$injector.then($injector => {
            $injector.get('DrillingNotificationService').notify('DrillTargetChanged');
        });
    }

    private reportSupportsLevel(level: DrillPath): boolean {
        const report = this.pageDefinitionService.getCurrentPage().report;

        // extra resolving for name, itemInBrand is item for example
        if (
            !this.pageDefinitionService.getCurrentPage().report.drillLevel[level.target]
        ) {
            return false;
        }
        const target = this.pageDefinitionService.getCurrentPage().report.drillLevel[
            level.target
        ].level;
        if (report && report.kpiDefinition && report.kpiDefinition.levels) {
            return typeof report.kpiDefinition.levels[target] === 'object';
        }
        return false;
    }
}
