import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Angulartics2Segment } from 'angulartics2/segment';
import { WINDOW } from 'insightui.global/global.module';
import { DeliveryInfo } from 'insightui.metadata/metadata.types';
import { SessionService } from 'insightui.session/session.service';
import { SessionUserDetails } from 'insightui.session/session.types';
import { combineLatest, Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { ILogger, LogService } from './logging/log.service';

const UNIDENTIFIED_USER = 'unidentifiedUser';

export type TrackingCategory =
    | 'Export'
    | 'CollectionManager'
    | 'FavoriteManager'
    | 'Navigation'
    | 'Settings'
    | 'Feedback'
    | 'ClientSwitch';

/**
 * Extend this class, if tracking is necessary on
 * other places in the application
 */
@Injectable({ providedIn: 'root' })
export class AnalyticsService {
    private readonly logger: ILogger;
    userName: string;
    retailerDataFor: string;

    constructor(
        private angulartics2Segment: Angulartics2Segment,
        @Inject(WINDOW) private window: Window,
        private sessionService: SessionService,
        store: Store,
        logService: LogService
    ) {
        this.logger = logService.getLogger(`AnalyticsService`);
        const deliveryInfo$: Observable<DeliveryInfo> = store
            .select(state => state.metadata.deliveryInfo)
            .pipe(
                filter(di => !!di),
                take(1)
            );

        const userInfo$: Observable<
            SessionUserDetails
        > = this.sessionService.currentUserDetails();

        const userToken$: Observable<string> = this.sessionService.currentToken();

        combineLatest([deliveryInfo$, userInfo$, userToken$])
            .pipe(
                filter(
                    ([deliveryInfo, userInfo, token]) =>
                        !!(deliveryInfo && userInfo && token)
                ),
                tap(([deliveryInfo, userInfo, token]) => {
                    this.retailerDataFor = this.getRetailerName(deliveryInfo);
                    this.trackUserAndClient(userInfo, deliveryInfo, token);
                })
            )
            .subscribe();
    }

    /**
     * Submits a page track to matomo.
     * Make sure to NOT call this method twice.
     */
    pageTrack() {
        const location = this.window.location.pathname + this.window.location.search;
        this.angulartics2Segment.pageTrack(location);
    }

    // Tracking KPI event with Newron tracking properties

    trackKpiEvent(
        locationId: string,
        optionChosen: string,
        pageName: string,
        subsection: string
    ) {
        const filterApply = "Filter Applied";
        const filterApplyDetail = "Filter Applied Details";
        const categoryChosen = "KPIs";
        const categoryCombination = "KPI";
        const access = "Retail Scout";
        const section = access;
        const filterName = "Report Settings";
        const filterType = "Global Filter";
        let retailerDataFor = this.retailerDataFor;
        const prop = {
            access,
            locationId,
            categoryCombination,
            filterName,
            filterType,
            pageName,
            retailerDataFor,
            section,
            subsection
        }
        const propsDetail= {
            access,
            locationId,
            categoryChosen,
            categoryCombination,
            filterName,
            filterType,
            optionChosen,
            pageName,
            section,
            subsection,
            retailerDataFor,
        };
        this.angulartics2Segment.eventTrack(filterApply, prop);
        this.angulartics2Segment.eventTrack(filterApplyDetail, propsDetail);
    }

    /**
     * Track a custom event.
     * See https://matomo.org/docs/event-tracking/ for more information.
     */
    trackEvent(
        category: TrackingCategory,
        action: string,
        label?: string,
        value?: number
    ) {
        const props = {
            category,
            label,
            value
        };
        this.angulartics2Segment.eventTrack(action, props);
    }

    private trackUserAndClient(
        userData: SessionUserDetails,
        deliveryInfo,
        token: string
    ) {
        const userDataObj = userData;

        const retailerId = this.getRetailerId(deliveryInfo);
        const retailerName = this.getRetailerName(deliveryInfo);

        const clientId = deliveryInfo.client.id;
        const clientName = deliveryInfo.client.name;
        const originalClientId = this.getClientIdFromToken(token);

        const userId = userDataObj.userId || userDataObj.userName; // no id on localhost
        this.userName = userDataObj.userName;
        let userHash = Md5.hashStr(userId + userDataObj.userName) as string;

        // to be able to filter out testuser from "official" reports
        if (this.isTestUser()) {
            userHash = 'GFK_TEST_' + userHash;
        }

        const userCustomProps = {
            dimension1: this.concatElements(clientName, retailerId),
            dimension2: originalClientId,
            dimension3: this.concatElements(clientName, retailerId),
            dimension4: deliveryInfo.country,
            dimension5: originalClientId,
            dimension6: this.concatElements(clientName, clientId),
            dimension7: this.concatElements(clientName, clientId),
            dimension8: this.concatElements(retailerName, retailerId),
            dimension9: this.concatElements(retailerName, retailerId)
        };
        this.angulartics2Segment.setUserProperties({
            userId: userHash,
            ...userCustomProps,
            clientId,
            clientName,
            originalClientId,
            retailerId,
            retailerName,
            deliveryCountryCode: deliveryInfo.country
        });
    }

    private concatElements(first: string, second: string): string {
        const concatedElements = [first, second]
            .filter(element => element != null)
            .join(' - ');

        return concatedElements === '' ? UNIDENTIFIED_USER : concatedElements;
    }

    /**
     * This is a temporary workaround to identify test user accounts. The
     * solution might not be a 100% accurate.
     *
     * @returns boolean
     */
    private isTestUser() {
        return (
            this.userName.toLowerCase().indexOf('.test') > -1 ||
            this.userName.toLowerCase().indexOf('nue.') > -1 ||
            this.userName.indexOf('ccr.end2endtest') > -1 ||
            this.userName.indexOf('uptrends.access') > -1
        );
    }

    /**
     *  Returns the Retailer ID from metadata channel object.
     *  Channels might be missing in some special cases
     *
     * @param deliveryInfo obj
     * @returns string | null
     */
    private getRetailerId(deliveryInfo) {
        let retailerId = null;
        if (deliveryInfo.dimensions && deliveryInfo.dimensions.channel) {
            const channelObj = deliveryInfo.dimensions.channel;
            const channelKeys = Object.keys(channelObj);
            channelKeys.forEach(key => {
                if (channelObj[key] && channelObj[key].type === 'retailer') {
                    retailerId = key.split('CH')[0];
                }
            });
        }

        return retailerId;
    }

    /**
     * Returns the retailer name
     */
    private getRetailerName(deliveryInfo) {
        let retailerName = null;
        if (deliveryInfo.dimensions && deliveryInfo.dimensions.channel) {
            const channelObj = deliveryInfo.dimensions.channel;
            const channelKeys = Object.keys(channelObj);
            channelKeys.forEach(key => {
                if (channelObj[key] && channelObj[key].type === 'retailer') {
                    retailerName = channelObj[key].title;
                }
            });
        }

        return retailerName;
    }

    private getClientIdFromToken(token: string): number | undefined {
        try {
            return this._internalGetClientIdFromToken(token);
        } catch (err) {
            this.logger.warn(`Could not get clientId from token.`, err);
            return undefined;
        }
    }

    private _internalGetClientIdFromToken(token: string): number | undefined {
        let result: number | undefined;
        if (token) {
            // we just take the middle part of the token (between the two dots)
            const [, dataPartOfToken] = token.split('.');
            if (dataPartOfToken) {
                // the middle part of the token is just a base64 encoded JSON object,
                // let's decode and parse it
                const tokenPayload = JSON.parse(atob(dataPartOfToken));
                if (tokenPayload && tokenPayload.clientId !== undefined) {
                    result = tokenPayload.clientId;
                }
            }
        }

        return result;
    }
}
