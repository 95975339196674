import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash';

import { Ng1ReduxService } from 'insightui.core/services/ng1-redux.service';

class Country {
    title: any;
    countryCode: any;
    docIds: any;
    selected: any;
}

@Injectable({
  providedIn: 'root'
})
export class CountryFilterService {

    countries: Country[];

    constructor(private ngRedux: Ng1ReduxService) {
        this.countries = [];

        const countriesFromStoreSnapshot =  this.ngRedux.ngxsStore.selectSnapshot(
            state => state.metadata.countries
        );

        if (countriesFromStoreSnapshot && countriesFromStoreSnapshot.length > 0) {
            this.readData(countriesFromStoreSnapshot);
        } else {
            const countriesFromStore = this.ngRedux.ngxsStore
                .select(state => state.metadata.countries)
                .pipe(
                    filter(countries => countries && countries.length > 0),
                    take(1)
                );

            countriesFromStore.subscribe(countries => {
                this.readData(countriesFromStore);
            });
        }
    }

    getSelected() {
        return _.get(
            _.find(this.countries, (item) => {
                return item.selected;
            }),
            'countryCode'
        );
    };

    private readData(countriesFromStore) {
        let self = this;
        _.forEach(countriesFromStore, function(elem) {
            const country = new Country();
            country.title = elem.countryName;
            country.countryCode = elem.countryCode;
            country.docIds = elem.docIds;
            country.selected = elem.isDefault;

            self.countries.push(country);
        });
    }

}
