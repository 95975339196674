<gfk-modal-header
    [title]="'Feed Notifications'"
    [iconClass]="'fa fa-rss-square'"
    data-uitest="feed-notification-dialog-header"
>
</gfk-modal-header>
<form
    *ngIf="!hasServerError; else sadCloud"
    [formGroup]="feedNotificationForm"
    data-uitest="feed-notification-form"
>
    <p class="feed-settings-title">
        Please note that these settings will apply to all of your countries.
    </p>
    <div class="feed-settings-input-group">
        <div class="custom-checkbox">
            <input
                type="checkbox"
                id="firstPartOfDeliveryInput"
                formControlName="firstPartOfDelivery"
                data-uitest="notify-first-part-input"
            />
            <label
                for="firstPartOfDeliveryInput"
                data-uitest="notify-first-part-inputLabel"
            ></label>
        </div>
        <div>
            <label for="firstPartOfDeliveryInput">First part of delivery</label>
            <p>Get a notification when the first part of your delivery is available.</p>
        </div>
    </div>
    <div class="feed-settings-input-group">
        <div class="custom-checkbox">
            <input
                type="checkbox"
                id="fullDeliveryInput"
                formControlName="fullDelivery"
                data-uitest="notify-full-delivery-input"
            />
            <label
                for="fullDeliveryInput"
                data-uitest="notify-full-delivery-inputLabel"
            ></label>
        </div>
        <div>
            <label for="fullDeliveryInput">Full delivery</label>
            <p>
                Get a notification as soon as the full dataset of your delivery is
                available.
            </p>
        </div>
    </div>
    <gfk-modal-footer class="feed-notification-footer form-group">
        <div class="feed-url-input-group">
            <input
                readonly
                type="text"
                #feedUrl
                formControlName="feedUrl"
                class="feed-url-input form-control"
                (click)="$event.target.select()"
                data-uitest="feed-url"
            />

            <div class="feed-url-copy-icon">
                <i
                    class="fa fa-clipboard"
                    title="Copy to clipboard"
                    (click)="copyToClipboard(feedUrl)"
                    [gfkDropDown]="copiedTooltip"
                    [gfkDropDownPosition]="copiedTooltipPosition"
                    [gfkDropDownCloseTimeout]="500"
                    data-uitest="copy-to-clipboard-icon"
                ></i>
                <ng-template #copiedTooltip>
                    <div class="copied-tooltip">Copied</div>
                </ng-template>
            </div>
        </div>
        <gfk-modal-button
            class="delete-button"
            buttonType="CANCEL"
            (click)="deleteFeed()"
            [disabled]="!hasFeedUrl"
            data-uitest="delete-feed-button"
            angulartics2On
            angularticsCategory="Notifications"
            angularticsAction="DeleteFeed"
        >
            Delete feed
        </gfk-modal-button>

        <a
            href="#"
            class="help-link link-primary"
            (click)="openHelpPage()"
            data-uitest="help-link"
            >Help me!</a
        >

        <gfk-modal-button
            buttonType="BUTTON"
            (click)="resetFeed()"
            [disabled]="!hasFeedUrl || !hasValidFeedSettings"
            data-uitest="reset-feed-button"
            angulartics2On
            angularticsCategory="Notifications"
            angularticsAction="ResetFeed"
            title="Save settings and generate a new feed url. The previous url will be invalidated."
        >
            Reset feed
        </gfk-modal-button>
        <gfk-modal-button
            buttonType="BUTTON"
            (click)="saveFeed()"
            [disabled]="!hasValidFeedSettings"
            data-uitest="save-feed-button"
            angulartics2On
            angularticsCategory="Notifications"
            angularticsAction="SaveFeed"
        >
            {{ !!feedNotificationForm.value.feedUrl ? 'Update feed' : 'Generate feed' }}
        </gfk-modal-button>
    </gfk-modal-footer>
</form>
<gfk-modal-closer
    (click)="closeDialog()"
    data-uitest="close-dialog-icon"
></gfk-modal-closer>

<ng-template #sadCloud>
    <gfk-sorry>
        <p>We currently have trouble reaching the server for notifications.</p>
        <p>Please try again later.</p>
    </gfk-sorry>
    <gfk-modal-footer>
        <gfk-modal-button
            (click)="closeDialog()"
            data-uitest="sad-cloud-close-dialog-button"
            >Close</gfk-modal-button
        >
    </gfk-modal-footer>
</ng-template>
