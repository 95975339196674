/**
 * Main Module for the table implementation.
 **/
import { NgModule } from '@angular/core';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { DatatableModule } from './components/datatable/datatable.module';
import { Ng1Module } from './ng1services/ng1.module';
import { TablePluginModule } from 'insightui.table/components/plugins/table-plugin.module';
import { TableDataService } from 'insightui.table/components/datatable/shared/services/table-data.service';
import { DrillSwitchToggleComponent } from 'insightui.table/components/menu/drill-switch-toggle.component';
import { AutosizeUtilService } from 'insightui.table/components/datatable/shared/services/autosize-util.service';
import { SplitByStateRestoreService } from 'insightui.table/components/datatable/shared/services/splitby-state-restore.service';
import { TableExportService } from 'insightui.table/components/datatable/shared/services/table-export.service';
import { BrandHighlightService } from 'insightui.table/services/brand-highlighting.service';
import { ErrorService } from 'insightui.error/error.service';
import { CellCacheService } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { FilteredTotalService } from 'insightui.table/services/filtered-total.service';
import { UpgradeModule } from '@angular/upgrade/static';
import { CommonModule } from '@angular/common';
import { SearchDataTableComponent } from 'insightui.table/components/menu/search-datatable.component';
import { FormsModule } from '@angular/forms';
import { CustomSortComponent } from 'insightui.table/components/modal/custom-sort.component';
import { CustomSortIconComponent } from 'insightui.table/components/menu/custom-sort-icon.component';
import { SearchDatatableService } from 'insightui.table/components/menu/search-datatable.service';
import { EmptySearchService } from 'insightui.table/components/datatable/body/empty-search.service';
import { ColumnWidthUtilService } from 'insightui.table/components/datatable/shared/services/column-width-util.service';
import { ErrorModule } from 'insightui.error/error.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Angulartics2Module } from 'angulartics2';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
    declarations: [
        AuthoringDatatableComponent,
        DrillSwitchToggleComponent,
        SearchDataTableComponent,
        CustomSortComponent,
        CustomSortIconComponent
    ],
    imports: [
        CommonModule,
        DragulaModule,
        DragDropModule,
        DatatableModule,
        TablePluginModule,
        Ng1Module,
        UpgradeModule,
        FormsModule,
        ErrorModule,
        Angulartics2Module
    ],
    providers: [
        DatatableConfigurationService,
        AutosizeUtilService,
        TableDataService,
        SplitByStateRestoreService,
        TableExportService,
        BrandHighlightService,
        CellCacheService,
        ErrorService,
        FilteredTotalService,
        SearchDatatableService,
        EmptySearchService,
        ColumnWidthUtilService
    ]
})
export class TableModule {}
