import { Inject, Injectable } from '@angular/core';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { DOCUMENT } from 'insightui.global/global.module';
import * as _ from 'lodash';

const PRICECLASS_FILTER_TEXT_START = 'Price Class: ';
const FEATURE_FILTER_TEXT_START = 'Filtered by: ';
const POS_TYPE_FILTER_TEXT_START = 'POS Type: ';
const FOCUS_FILTER_TEXT_START = 'Focus: ';
const SEGMENTATION_FILTER_TEXT_START = 'Segmentation: ';
const CURRENCY_FILTER_TITLE_ELEMENT = 'currency';
const HEADER_FILTER_TITLE_ELEMENTS = [
    'countryTitle',
    'selectedDimensionTitle',
    'segmentFilterTitle',
    'periodFilterTitle',
    CURRENCY_FILTER_TITLE_ELEMENT
];

const TOTAL_PRICE_CLASS_ID = 'PC-1';
const FONT_SIZE = 18; // px
const LINE_HEIGHT = 25;
const DEFAULT_FOOTER_HEIGHT = 50; // 2 lines
const INFO_ROW_CLASS_NAME = 'info-row-footer';

/**
 * Retrieves information about the current filter selection of the report to attach to the PPT slide.
 */
@Injectable()
export class PowerpointInfoRowService {
    private posTypeFilterModel;

    constructor(
        @Inject('$injector') $injector,
        @Inject(DOCUMENT) private document: Document,
        private visualizationContextService: VisualizationContextService,
        private metadataService: MetadataService
    ) {
        this.posTypeFilterModel = $injector.get('PosTypeFilterModel');
    }

    appendFilterInfoToElement(
        reportElement: HTMLElement,
        filterPlacementElementSelector?: string
    ) {
        const filterTitles = this.getFilterTitlesForExport();
        const filterText = filterTitles.reduce((a, b) => a.concat('<br>', b));
        const filterTitlesElement = this.document.createElement('footer');

        filterTitlesElement.style.fontSize = FONT_SIZE + 'px';
        filterTitlesElement.style.lineHeight = LINE_HEIGHT + 'px';
        filterTitlesElement.style.height = filterTitles.length * LINE_HEIGHT + 'px';
        filterTitlesElement.classList.add(INFO_ROW_CLASS_NAME);

        const filterPlacementElement = !!filterPlacementElementSelector
            ? reportElement.querySelector(filterPlacementElementSelector)
            : reportElement;

        if (!!filterPlacementElement) {
            filterPlacementElement.appendChild(filterTitlesElement);
            filterTitlesElement.innerHTML = filterText;
        }
    }

    getFilterTitlesForExport(): string[] {
        const filterTitles = this.getGlobalFilterTitleLine();
        const optionFilterTitles = this.getOptionFilterTitleLine();
        const filterSubtitles = this.getGlobalFilterSubtitleLine();
        return [filterTitles, optionFilterTitles, filterSubtitles].filter(
            titles => !!titles && titles.length > 0
        );
    }

    getFooterHeight(chartElement: HTMLElement): number {
        const footer = chartElement.querySelector<HTMLElement>(`.${INFO_ROW_CLASS_NAME}`);

        if (!!footer) {
            const footerHeight = Number(footer.style.height.replace('px', ''));
            if (footerHeight > 0 && footerHeight < 100) {
                // use it if it makes sence
                return footerHeight;
            }
        }

        return DEFAULT_FOOTER_HEIGHT;
    }

    private getGlobalFilterTitleLine(): string {
        const filterTitleLine: Array<string> = [];
        for (const headerFilterTitleElement of HEADER_FILTER_TITLE_ELEMENTS) {
            const filterTitleElement = this.getGlobalFilterTitleForElement(
                headerFilterTitleElement
            );
            if (filterTitleElement !== '') {
                filterTitleLine.push(filterTitleElement);
            }
        }
        return filterTitleLine.join(', ');
    }

    private getGlobalFilterSubtitleLine(): string {
        const focusFilterTitle: string = this.getFocusTitle();
        const segmentationFilterTitle: string = this.getSegmentationTitle();

        return [focusFilterTitle, segmentationFilterTitle]
            .filter(str => str.length > 0)
            .join(', ');
    }

    private getGlobalFilterTitleForElement(headerFilterTitleElement: string): string {
        let filterTitle = '';
        if (this.visualizationContextService.has(headerFilterTitleElement)) {
            if (headerFilterTitleElement === CURRENCY_FILTER_TITLE_ELEMENT) {
                filterTitle +=
                    this.visualizationContextService.get(CURRENCY_FILTER_TITLE_ELEMENT)
                        .code +
                    ' (' +
                    this.visualizationContextService.get(CURRENCY_FILTER_TITLE_ELEMENT)
                        .symbol +
                    ')';
            } else {
                filterTitle += this.visualizationContextService.get(
                    headerFilterTitleElement
                );
            }
        }
        return filterTitle;
    }

    private getOptionFilterTitleLine(): string {
        const featureFilterTitle: string = this.getFeatureFilterTitle();
        const priceClassFilterTitle: string = this.getPriceClassFilterTitle();
        const selectedPosTypeTitle: string = this.getPosTypeFilterTitle();

        return [featureFilterTitle, priceClassFilterTitle, selectedPosTypeTitle]
            .filter(str => str.length > 0)
            .join(', ');
    }

    private getFeatureFilterTitle(): string {
        const featureFilterTitle: Array<string> = [];
        if (this.visualizationContextService.has('featureFilterList')) {
            const featureFilterList: Array<any> = this.visualizationContextService
                .get('featureFilterList')
                .filter(feature => feature.value !== 'FV-1');
            if (featureFilterList.length > 0) {
                for (const featureFilterElement of featureFilterList) {
                    const feature = this.metadataService.getFeatureById(
                        featureFilterElement.type
                    );
                    featureFilterTitle.push(
                        feature.title.concat(
                            ': ',
                            feature.values[featureFilterElement.value].title
                        )
                    );
                }
                return FEATURE_FILTER_TEXT_START + featureFilterTitle.join(', ');
            }
        }
        return '';
    }

    private getPriceClassFilterTitle(): string {
        if (this.visualizationContextService.has('priceClass')) {
            const priceClassFilterId = this.visualizationContextService.get('priceClass');
            if (priceClassFilterId !== TOTAL_PRICE_CLASS_ID) {
                const priceClass = this.metadataService.getPriceClassById(
                    priceClassFilterId
                );
                return PRICECLASS_FILTER_TEXT_START.concat(priceClass.title);
            }
        }
        return '';
    }

    private getPosTypeFilterTitle(): string {
        const selectedPosType = this.posTypeFilterModel.getSelected();
        if (!selectedPosType || selectedPosType.default) {
            return '';
        }

        return POS_TYPE_FILTER_TEXT_START + selectedPosType.title;
    }

    private getFocusTitle(): string {
        let focus = this.visualizationContextService.get('focusTitle');
        if (!!focus && focus === 'Feature Share') {
            const featureTitle = this.getFeatureTitle();
            focus = `${focus} - ${featureTitle}`;
        }

        if (!focus) {
            return '';
        }

        return FOCUS_FILTER_TEXT_START + focus;
    }

    private getSegmentationTitle(): string {
        let segmentation = this.visualizationContextService.get('segmentationTitle');
        if (!!segmentation && segmentation === 'Feature') {
            const featureTitle = this.getFeatureTitle();
            segmentation = `${segmentation} - ${featureTitle}`;
        }

        if (!segmentation) {
            return '';
        }

        return SEGMENTATION_FILTER_TEXT_START + segmentation;
    }

    private getFeatureTitle(): string {
        const featureId = this.visualizationContextService.get('splitByFeatureList[0]');
        return _.get(this.metadataService.getFeatureById(featureId), 'title');
    }
}
