/**
 * Module that contains interfaces and services for the
 * application state.
 *
 * Sets up redux and the required middlewares.
 */
import { Inject, InjectionToken, NgModule, Optional } from '@angular/core';
import { DevToolsExtension, NgRedux, NgReduxModule } from '@angular-redux/store';
import { AppRootState } from './app-root.state.interface';
import { StateEpic, StateMiddleware, StateReducer } from './state.types';
import { AnyAction, combineReducers, ReducersMapObject } from 'redux';
import {
    createEpicMiddleware,
    Epic,
    EpicMiddleware,
    StateObservable
} from 'redux-observable';
import { map, mergeAll } from 'rxjs/operators';
import { EMPTY, from as observableFrom, Observable } from 'rxjs';

type AnyStateReducer = StateReducer<any, any, AnyAction>;
type AnyStateEpic = StateEpic<any, any, AnyAction>;
type AnyStateMiddleware = StateMiddleware<any>;

export const STATE_REDUCER = new InjectionToken<AnyStateReducer>('STATE_REDUCER');

export const STATE_EPIC = new InjectionToken<AnyStateEpic>('STATE_EPIC');

export const STATE_MIDDLEWARE = new InjectionToken<AnyStateMiddleware>(
    'STATE_MIDDLEWARE'
);

@NgModule({
    imports: [NgReduxModule],
    providers: []
})
export class StateModule<STATE extends object = AppRootState> {
    constructor(
        ngRedux: NgRedux<STATE>,
        devTools: DevToolsExtension,
        @Optional()
        @Inject(STATE_REDUCER)
        stateReducers: StateReducer<STATE, any, AnyAction>[],
        @Optional()
        @Inject(STATE_MIDDLEWARE)
        stateMiddleware: StateMiddleware<STATE>[],
        @Optional() @Inject(STATE_EPIC) stateEpics: StateEpic<STATE, any>[]
    ) {
        if (!stateReducers) {
            return;
        }

        let enhancers = [];

        if (devTools.isEnabled()) {
            enhancers = [...enhancers, devTools.enhancer({ trace: false })];
        }
        const combinableReducers: ReducersMapObject = stateReducers.reduce(
            (obj, stateReducer) => {
                return {
                    ...obj,
                    [stateReducer.key]: stateReducer.getReducer()
                };
            },
            {}
        );

        const initState: STATE = stateReducers.reduce(
            (state, stateReducer) => {
                return Object.assign({}, state, {
                    [stateReducer.key]: stateReducer.initState
                });
            },
            {} as STATE
        );

        const rootReducer = combineReducers(combinableReducers);

        const providedMiddlewares = (stateMiddleware || []).reduce(
            (provide, middleware) => provide.concat(middleware.getMiddleware()),
            []
        );
        const epicMiddleware: EpicMiddleware<
            AnyAction,
            AnyAction,
            STATE
        > = createEpicMiddleware();

        ngRedux.configureStore(
            rootReducer,
            initState,
            providedMiddlewares.concat(epicMiddleware),
            enhancers
        );

        if (stateEpics) {
            const rootEpic: Epic<AnyAction, AnyAction, STATE> = (
                action$,
                state$,
                deps
            ) => {
                const actions = stateEpics.map(stateEpic => {
                    const epic = stateEpic.getEpic();
                    let stateForEpic$: Observable<any>;
                    if (stateEpic.key) {
                        stateForEpic$ = state$.pipe(map(state => state[stateEpic.key]));
                    } else {
                        stateForEpic$ = EMPTY;
                    }
                    return epic(action$, stateForEpic$ as StateObservable<any>, deps);
                });

                return observableFrom(actions).pipe(mergeAll());
            };
            epicMiddleware.run(rootEpic);
        }
    }
}
