import { Injectable } from '@angular/core';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { ChartElementService } from 'insightui.export/powerpoint/services/chart-element.service';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { BarChartStrategyComparisonPeriod } from 'insightui.export/powerpoint/charts/bar-chart/bar-chart-strategy-comparison-period';
import { BarChartStrategyWithoutPeriod } from 'insightui.export/powerpoint/charts/bar-chart/bar-chart-strategy-without-period';
import { PowerpointExport, PowerpointExportSheets } from 'insightui.export/types';
import { BarChartStrategy } from 'insightui.export/powerpoint/charts/bar-chart/bar-chart-strategy';
import { Observable, of as observableOf } from 'rxjs';

@Injectable()
export class BarChart implements PowerpointExport {
    constructor(
        private chartElementService: ChartElementService,
        private chartToSheetService: ChartToSheetService,
        private visualizationContextService: VisualizationContextService
    ) {}

    /**
     * Return the filled Html block of the authoring chart
     *
     * @returns {PowerpointExportSheets}
     * @memberof FixedSizeAuthoringChartHtml
     */
    getPowerpointExportSheets(): Observable<PowerpointExportSheets> {
        let strategy: BarChartStrategy;
        if (this.isComparisonPeriod()) {
            strategy = new BarChartStrategyComparisonPeriod(
                this.chartElementService.getCleanedUpChartElement(),
                this.chartToSheetService
            );
        } else {
            strategy = new BarChartStrategyWithoutPeriod(
                this.chartElementService.getCleanedUpChartElement(),
                this.chartToSheetService
            );
        }

        return observableOf(strategy.getSheets());
    }

    private isComparisonPeriod(): boolean {
        return this.visualizationContextService.get('periodFilterComparisonPeriod');
    }
}
