import { Component, HostListener, OnInit } from '@angular/core';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { PowerpointExportActiveReportService } from 'insightui.export/powerpoint/services/powerpoint-export-active-report.service';

@Component({
    selector: 'powerpoint-export-button',
    templateUrl: 'powerpoint-export-button.component.html'
})
export class PowerpointExportButtonComponent implements OnInit {
    isVisible: boolean = false;

    constructor(
        private powerpointExportActiveReportService: PowerpointExportActiveReportService,
        private visualizationContextService: VisualizationContextService
    ) {}

    ngOnInit(): void {
        if (this.visualizationContextService.has('report.enablePowerpointExport')) {
            this.isVisible = this.visualizationContextService.get(
                'report.enablePowerpointExport'
            );
        }
    }

    @HostListener('click')
    exportCurrentReport(): Promise<void> {
        return this.powerpointExportActiveReportService.exportCurrentReport();
    }
}
