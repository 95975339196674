import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { preserveClientId } from 'insightui.core/services/navigation-utils';

@Component({
    selector: 'gfk-report-type-selector',
    templateUrl: './report-type-selector.component.html',
    styleUrls: ['./report-type-selector.component.scss']
})
export class ReportTypeSelectorComponent implements OnInit {

    @Input()
    chapter: any;

    @Input()
    favourites: any;

    @Input()
    docId: any;

    @ViewChild('link')
    anchor;

    favouriteResolved: any;
    positionX: any;
    positionY: any;
    reportId: string;
    tooltipOpen = false;

    constructor(
        private route: ActivatedRoute,
        private matomoTrackerService: AnalyticsService,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.reportId = params['reportId'];
        });
    }

    getLink(docId) {
        return preserveClientId(`report/${this.getPageId()}?docId=${docId}`);
    };

    getPageId() {
        return _.get(this.chapter, ['pageIds', 0]);
    };

    setHighlight() {
        this.renderer.addClass(this.anchor.nativeElement, 'highlight');
    };

    clearHighlight() {
        this.renderer.removeClass(this.anchor.nativeElement, 'highlight');
    };

    hasFavourite() {
        if (this.favouriteResolved !== undefined) {
            return this.favouriteResolved !== '';
        }

        if (this.favourites) {
            const keys = Object.keys(this.favourites);
            this.favouriteResolved = '';

            for (let i = 0; i < keys.length; i++) {
                if (this.chapter.pageIds.indexOf(keys[i]) >= 0) {
                    this.favouriteResolved = keys[i];
                    return true;
                }
            }

            return false;
        }

        return undefined;
    };

    getFavourites() {
        if (this.favourites) {
            const self = this;
            return _(this.favourites)
                .filter((fav, key) => {
                    return self.chapter.pageIds.indexOf(key) >= 0;
                })
                .flatten()
                .sortBy('created')
                .value()
                .reverse();
        }

        return [];
    };

    onReportClickFromLandingPage(reportId) {
        this.trackOpenReport(this.matomoTrackerService, reportId);
    }

    onMouseOver() {
        this.tooltipOpen = true;
    }

    onMouseOut() {
        this.tooltipOpen = false;
    }

    onMouseMove(event) {
        this.positionX = event.layerX;
        this.positionY = event.layerY;
    }

    private trackOpenReport(matomoTrackerService, reportId) {
        try {
            matomoTrackerService.trackEvent(
                'Navigation',
                'OpenReportFromLandingPage',
                reportId
            );
        } catch (e) {

        }
    }


}
