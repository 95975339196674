import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { ReleaseNote } from 'insightui.announcements/announcements.types';

@Component({
    selector: 'gfk-announcements-modal',
    templateUrl: './announcements-modal.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsModalComponent {
    @Input()
    releaseNote: ReleaseNote;

    @Input()
    currentPageNumber: number;

    @Input()
    numberOfPages: number;

    @Output()
    onClose: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    onGotoPage: EventEmitter<number> = new EventEmitter<number>();

    hasPagination(): boolean {
        return this.numberOfPages > 1;
    }

    gotoPage(pageNumber: number): void {
        this.onGotoPage.emit(pageNumber);
    }

    goToPreviousPage(): void {
        this.onGotoPage.emit(this.currentPageNumber - 1);
    }

    goToNextPage(): void {
        this.onGotoPage.emit(this.currentPageNumber + 1);
    }

    closeModal(): void {
        this.onClose.emit();
    }
}
