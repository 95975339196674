import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'gfk-fair-share-gap-value-hover',
    templateUrl: './fair-share-gap-value-hover.component.html',
    styleUrls: ['./fair-share-gap-value-hover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareGapValueHoverComponent {
    @Input()
    maxValuePercent: number;

    @Input()
    lineValuePercent: number;

    @Input()
    gapValuePercent: number;

    @Input()
    gapValue: number;

    @HostBinding('style.width.%') get width() {
        return this.getWidth();
    }

    @HostBinding('style.left.%') get left() {
        return this.getLeftOffset();
    }

    getWidth() {
        return Math.abs(
            ((this.gapValuePercent - this.lineValuePercent) / this.maxValuePercent) * 100
        );
    }

    getLeftOffset() {
        if (this.isNegative()) {
            return (this.lineValuePercent / this.maxValuePercent) * 100;
        } else {
            return (this.gapValuePercent / this.maxValuePercent) * 100;
        }
    }

    isNegative(): boolean {
        return this.gapValuePercent >= this.lineValuePercent;
    }
}
