import {
    interval as observableInterval,
    fromEvent as observableFromEvent,
    Observable,
    Subscriber
} from 'rxjs';
import { InjectionToken, NgModule, NgZone } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { animationFrameScheduler } from 'rxjs';
import { saveAs } from 'file-saver';

export interface ServiceConfig {
    displayName: string;
    url: string;
    statusUrl: string | null;
}

export interface InsightConfig {
    services: {
        QUERY_SERVICE: ServiceConfig;
        FILE_EXPORT_SERVICE: ServiceConfig;
        TICKET_SERVICE: ServiceConfig;
        USERPROFILE_SERVICE: ServiceConfig;
        FEDERATION_SERVICE: ServiceConfig;
        SECURITY_SERVICE: ServiceConfig;
        INSIGHTUI: ServiceConfig;
        CONFLUENCE_RELEASE_NOTES: ServiceConfig;
        NOTIFICATION_SERVICE: ServiceConfig;
    };
    JIRA_PROJECT_KEY: string;
    releaseNotes: {
        count: number;
    };
    app: {
        name: string;
        version: string;
        authenticationWhitelist: string[];
        compositeChart: string;
        httpSessionCache: string[];
    };
}

/**
 * Provides the current window
 */
export const WINDOW = new InjectionToken<Window>('window');

/**
 * Provides the current App Build Version
 */
export const APP_BUILD_VERSION = new InjectionToken<string>('APP_BUILD_VERSION');

/**
 * Provides the current document
 */
export const DOCUMENT = new InjectionToken<Document>('document');

/**
 * Provides a mousemove Observable attached to the document which doesn't fire for each event the change detection
 */
export const DOCUMENT_MOUSE_MOVE = new InjectionToken('document.mousemove');

/**
 * Provides an Observable for each animation frame
 */
export const ANIMATION_FRAME = new InjectionToken('animationframe');

export const WINDOW_RESIZE = new InjectionToken('window.resize');

export const UUID = new InjectionToken<Function>('uuid');

/**
 * The configuration for the application.
 */
export const INSIGHT_CONFIG = new InjectionToken<InsightConfig>('insight.config');

export type SaveAsFn = (blob: Blob, name: string) => void;

/**
 * The file-saver library
 */
export const SAVE_AS = new InjectionToken<SaveAsFn>('saveAs');

/**
 * The storage event when some other window/tab changes a value inside the session storage
 */
export const STORAGE_EVENT = new InjectionToken<Observable<StorageEvent>>(
    'STORAGE_EVENT'
);

/**
 * The event when the window gets the focus.
 */
export const WINDOW_FOCUS_EVENT = new InjectionToken<Observable<FocusEvent>>(
    'WINDOW_FOCUS_EVENT'
);

export function configProvider(): InsightConfig {
    // Note: getting the global configuration via window object is not cool, but it is the
    // simplest solution which ensures that the same instance of the config object is available
    // in both legacy AngularJS and typescript Angular part before both frameworks are bootstrapped.
    // Replace this with a (static) ConfigService as soon as we get rid of AngularJS.
    const insightConfigFromWindow: InsightConfig = window['__insightuiConfig'];
    if (!insightConfigFromWindow) {
        throw new Error('Config not yet loaded');
    }

    const immutableConfig = Object.freeze({
        ...insightConfigFromWindow
    });

    return immutableConfig;
}

/**
 * A module providing access to the global variables of the browser
 */
@NgModule({
    providers: [
        { provide: WINDOW, useValue: window },
        {
            provide: APP_BUILD_VERSION,
            deps: [WINDOW],
            useFactory: (window: Window) => {
                return window.hasOwnProperty('APP_BUILD_VERSION')
                    ? window['APP_BUILD_VERSION']
                    : 'unknown';
            }
        },
        {
            provide: WINDOW_RESIZE,
            deps: [WINDOW],
            useFactory: (window: Window) => {
                return observableFromEvent(window, 'resize');
            }
        },
        { provide: DOCUMENT, useValue: document },
        {
            provide: DOCUMENT_MOUSE_MOVE,
            deps: [DOCUMENT, NgZone],
            useFactory: (document: Document, ngZone) => {
                return new Observable((subscriber: Subscriber<MouseEvent>) => {
                    const listener = (evt: MouseEvent) => subscriber.next(evt);
                    ngZone.runOutsideAngular(() =>
                        document.addEventListener('mousemove', listener)
                    );

                    return () => document.removeEventListener('mousemove', listener);
                });
            }
        },
        {
            provide: ANIMATION_FRAME,
            useValue: observableInterval(0, animationFrameScheduler)
        },
        { provide: UUID, useValue: uuid },
        {
            provide: INSIGHT_CONFIG,
            useFactory: configProvider
        },
        { provide: UUID, useValue: uuid },
        { provide: SAVE_AS, useValue: saveAs },
        {
            provide: STORAGE_EVENT,
            deps: [WINDOW],
            useFactory: (window: Window) => observableFromEvent(window, 'storage')
        },
        {
            provide: WINDOW_FOCUS_EVENT,
            deps: [WINDOW],
            useFactory: (window: Window) => observableFromEvent(window, 'focus')
        }
    ]
})
export class GlobalModule {}
