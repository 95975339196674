<gfk-announcements-bell
    (bellClick)="showReleaseNotesViaBell()"
    [hasError]="hasError$ | async"
    angulartics2On
    angularticsCategory="Feedback"
    angularticsAction="OpenReleaseNotes"
></gfk-announcements-bell>
<gfk-announcements-modal
    [releaseNote]="activeReleaseNote$ | async"
    (onClose)="closeModal()"
    *ngIf="modalIsOpen$ | async"
    [currentPageNumber]="currentPageNumber$ | async"
    [numberOfPages]="numberOfPages$ | async"
    (onGotoPage)="gotoPage($event)"
>
</gfk-announcements-modal>
