import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowPanelComponent } from './arrow-panel.component';

@NgModule({
    declarations: [ArrowPanelComponent],
    imports: [CommonModule],
    exports: [ArrowPanelComponent]
})
export class ArrowPanelModule {}
