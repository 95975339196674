import {
    ColumnPane,
    Column,
    ColumnPaneWidths,
    ColumnHeader,
    ColumnPosition
} from '../models/datatable.models';
import { Injectable } from '@angular/core';

@Injectable()
export class ColumnUtilService {
    /**
     * Sets the headerColumn defaults
     *
     * @param {Column[]} columns
     */
    public setColumnDefaults(columns: Column[]): void {
        if (!columns) {
            return;
        }

        for (let column of columns) {
            if (!column.id) {
                column.id = this.columnId();
            }

            // set default width
            // TODO: make this configurable
            if (column.width === 0) {
                column.width = 110;
            }
        }
    }

    /**
     * Returns an array of ColumnPanes.
     *
     * @param {Column[]} columns
     */
    public columnPaneList(columns: Column[]): ColumnPane[] {
        let columnsByFreezeMode = this.groupBy(columns, 'freezeMode');
        let paneList: ColumnPane[] = [];
        for (let freezeMode of Object.keys(columnsByFreezeMode)) {
            paneList.push(
                new ColumnPane(
                    freezeMode as ColumnPosition,
                    columnsByFreezeMode[freezeMode]
                )
            );
        }
        return paneList;
    }

    public columnPaneWidths(columns: Column[]): ColumnPaneWidths {
        let columnsByFreezeMode = this.groupBy(columns, 'freezeMode');

        let paneWidths = new ColumnPaneWidths();
        for (let freezeMode of Object.keys(columnsByFreezeMode)) {
            paneWidths[freezeMode] = this.paneWidth(columnsByFreezeMode[freezeMode]);
            paneWidths.total += paneWidths[freezeMode];
        }

        return paneWidths;
    }

    /**
     * Adjusts the headerColumn widths.
     * @param {Column[]} columns
     * @param {number} expectedWidth
     */
    public adjustColumnWidths(columns: Column[], expectedWidth: number) {
        let columnsWidth = this.columnPaneWidths(columns).total;

        if (columnsWidth !== expectedWidth) {
            this.scaleColumns(columns, expectedWidth);
        }
    }

    /**
     * REsize column width to header width
     * @param headers
     * @param columns
     * @returns {Column[]}
     */
    public resizeColumnsToHeader(headers: ColumnHeader[], columns: Column[]) {
        let flatHeader = [];
        this.flattenHeader(headers, flatHeader);
        columns.forEach(column => {
            let header = flatHeader.find(h => {
                h.field = h.field.replace('-', '');
                return h.field === column.field;
            });

            column.width = header ? header.width : column.minWidth;
        });
    }

    /**
     * Forces the width of the columns to
     * distribute equally but overflowing when nesc.
     *
     * Rules:
     *
     *  - If combined withs are less than the total width of the grid,
     *    proporation the widths given the min / max / noraml widths to fill the width.
     *
     *  - If the combined widths, exceed the total width of the grid,
     *    use the standard widths.
     *
     *  - If a headerColumn is resized, it should always use that width
     *
     *  - The proporational widths should never fall below min size if specified.
     *
     *  - If the grid starts off small but then becomes greater than the size ( + / - )
     *    the width should use the orginial width; not the newly proporatied widths.
     *
     * @param {array} allColumns
     * @param {number} expectedWidth
     * @param {number} startIdx
     * @param {number} allowBleed
     * @param {number} defaultColWidth
     */
    public forceFillColumnWidths(
        allColumns: Column[],
        expectedWidth: number,
        startIdx: number,
        allowBleed: boolean,
        defaultColWidth: number = 300
    ) {
        let columnsToResize = allColumns
            .slice(startIdx + 1, allColumns.length)
            .filter(c => {
                return c.autoResize !== false;
            });

        for (let column of columnsToResize) {
            if (!column.oldWidth) {
                column.oldWidth = column.width;
            }
        }

        let additionWidthPerColumn = 0;
        let exceedsWindow = false;
        let contentWidth = this.getContentWidth(allColumns, defaultColWidth);
        let remainingWidth = expectedWidth - contentWidth;
        let columnsProcessed: Column[] = [];

        // This loop takes care of the
        do {
            additionWidthPerColumn = remainingWidth / columnsToResize.length;
            exceedsWindow = contentWidth >= expectedWidth;

            for (let column of columnsToResize) {
                if (exceedsWindow && allowBleed) {
                    column.width = column.oldWidth || column.width || defaultColWidth;
                } else {
                    const newSize =
                        (column.width || defaultColWidth) + additionWidthPerColumn;

                    if (column.minWidth && newSize < column.minWidth) {
                        column.width = column.minWidth;
                        columnsProcessed.push(column);
                    } else if (column.maxWidth && newSize > column.maxWidth) {
                        column.width = column.maxWidth;
                        columnsProcessed.push(column);
                    } else {
                        column.width = newSize;
                    }
                }

                column.width = Math.max(0, column.width);
            }

            contentWidth = this.getContentWidth(allColumns);
            remainingWidth = expectedWidth - contentWidth;
            this.removeProcessedColumns(columnsToResize, columnsProcessed);
        } while (remainingWidth > 0 && columnsToResize.length !== 0);
    }

    public getDepth(headers: ColumnHeader[]) {
        let level = 0;
        for (let colHeader of headers) {
            if (colHeader.children) {
                let childLevel = this.getDepth(colHeader.children);
                level = Math.max(level, childLevel);
            }
        }

        return 1 + level;
    }

    columnId(): string {
        let id = '';
        for (let i = 0; i < 8; i++) {
            id += Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return id;
    }

    private removeProcessedColumns(
        columnsToResize: Column[],
        columnsProcessed: Column[]
    ) {
        for (let column of columnsProcessed) {
            if (columnsToResize) {
                const index = columnsToResize.indexOf(column);
                columnsToResize.splice(index, 1);
            }
        }
    }

    private getContentWidth(allColumns: Column[], defaultColWidth: number = 300): number {
        let contentWidth = 0;

        for (let column of allColumns) {
            contentWidth += column.width || defaultColWidth;
        }

        return contentWidth;
    }

    private flattenHeader(headers, aggregate) {
        headers.forEach(header => {
            if (header.children) {
                this.flattenHeader(header.children, aggregate);
            }
            if (!header.children || header.children.length === 0) {
                aggregate.push(header);
            }
        });
    }

    private getTotalFlexGrow(columns: Column[]) {
        let totalFlexGrow = 0;

        for (let column of columns) {
            totalFlexGrow += column.flexGrow || 0;
        }

        return totalFlexGrow;
    }

    private scaleColumns(columns: Column[], maxWidth: number) {
        let totalFlexGrow = this.getTotalFlexGrow(columns);

        for (let column of columns) {
            if (!column.autoResize) {
                maxWidth -= column.width;
                totalFlexGrow -= column.flexGrow;
            } else {
                column.width = 0;
            }
        }

        let hasMinWidth = {};
        let remainingWidth = maxWidth;

        do {
            let widthPerFlexPoint = remainingWidth / totalFlexGrow;
            remainingWidth = 0;

            for (let column of columns) {
                // if the headerColumn can be resized and it hasn't reached its minimum width yet
                if (column.autoResize && !hasMinWidth[column.field]) {
                    let newWidth = column.width + column.flexGrow * widthPerFlexPoint;
                    if (column.minWidth !== undefined && newWidth < column.minWidth) {
                        remainingWidth += newWidth - column.minWidth;
                        column.width = column.minWidth;
                        hasMinWidth[column.field] = true;
                    } else {
                        column.width = newWidth;
                    }
                }
            }
        } while (remainingWidth !== 0);
    }

    private paneWidth(columns: Column[]): number {
        let width = 0;

        if (columns) {
            for (let column of columns) {
                width += column.width;
            }
        }

        return width;
    }

    private groupBy(columns: Column[], field: string) {
        return columns.reduce((group, column) => {
            (group[column[field]] = group[column[field]] || []).push(column);
            return group;
        }, {});
    }
}
