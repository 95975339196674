import { Injectable, OnDestroy } from '@angular/core';
import { Store, Actions, ofActionDispatched } from '@ngxs/store';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';
import { Subject, Observer, Observable, EMPTY } from 'rxjs';
import { takeUntil, tap, catchError, concatMap } from 'rxjs/operators';
import {
    MsLoadRevisions,
    MsLoadDeliveryInfo,
    MsLoadDeliveryInfoSuccess
} from './metadata.actions';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { DeliveryInfoContainer } from 'insightui.metadata/metadata.types';

@Injectable()
export class MetadataHandler implements OnDestroy {
    /**
     * watches the action$ subscriptions and logs any unexpected
     * termination.
     */
    private readonly obs: Observer<any> = {
        next: _ => void 0,
        error: err => {
            this.logger.error('Observable errored and terminated unexpectedly', err);
            this._unitTestIsTerminated = true;
        },
        complete: () => {
            this.logger.error('Observable completed unexpectedly');
            this._unitTestIsTerminated = true;
        }
    };

    /**
     * Only used in unittests to ensure the
     * action$ does not get terminated unexpectedly
     */
    public _unitTestIsTerminated = false;

    private _killer$$ = new Subject<void>();

    private logger: ILogger;

    private actions$: Actions;

    constructor(
        private store: Store,
        private revisionService: RevisionService,
        actionsInternal$: Actions,
        logService: LogService
    ) {
        this.logger = logService.getLogger('MetadataHandler');
        this.actions$ = actionsInternal$.pipe(takeUntil(this._killer$$));

        this.handleLoadRevisions();
        this.handleLoadDeliveryInfo();

        this.logger.debug('ctor');
    }

    ngOnDestroy() {
        this._killer$$.next();
        this._killer$$.complete();
    }

    private handleLoadRevisions() {
        this.actions$
            .pipe(
                ofActionDispatched(MsLoadRevisions),
                tap(() => this.revisionService.loadRevisions())
            )
            .subscribe(this.obs);
    }

    private handleLoadDeliveryInfo() {
        this.actions$
            .pipe(
                ofActionDispatched(MsLoadDeliveryInfo),
                concatMap((action: MsLoadDeliveryInfo) => {
                    return this.revisionService.loadDeliveryInfo(action.docId).pipe(
                        tap(result => this.logger.debug('received', result)),
                        tap((result: DeliveryInfoContainer) =>
                            this.store.dispatch(new MsLoadDeliveryInfoSuccess(result))
                        ),
                        this.catchErrorAndDispatchFailure()
                    );
                })
            )
            .subscribe(this.obs);
    }

    private catchErrorAndDispatchFailure = () => (source: Observable<any>) => {
        return source.pipe(
            catchError(err => {
                this.logBackendError(err);
                // this.store.dispatch(new CmCollectionUpdateFailure(err));
                return EMPTY;
            })
        );
    };

    private logBackendError(err: any) {
        this.logger.error(`Error while talking to backend API`, err);
    }
}
