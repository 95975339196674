import {
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ColumnPane } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { DatatableScrollerSyncService } from 'insightui.table/components/datatable/shared/services/datatable-scroller-sync.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'datatable-scroller',
    template: `
        <div
            class="scrollable-content"
            #scrollContent
            id="content-{{ pane.pos }}"
            [ngStyle]="getScrollerStyle()"
            (scroll)="onScroll($event)"
        >
            <ng-content></ng-content>
        </div>
    `,
    styles: [
        `
            .scrollable-content {
                transform: translateZ(0);
                -webkit-transform: translateZ(0);
                overflow-x: hidden;
            }
        `
    ],
    encapsulation: ViewEncapsulation.None
})
export class ScrollerComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    pane: ColumnPane;

    @Input()
    innerWidth: number;

    @Input()
    scrollHeight: number;

    scrollWidth: string;
    offsetX = 0;
    offsetY = 0;

    @ViewChild('scrollContent', { static: true })
    scrollContent: ElementRef;
    scrollingSubscription: Subscription;

    constructor(private scrollerService$: DatatableScrollerSyncService) {}

    private subscribeToChanges() {
        this.scrollingSubscription = this.scrollerService$
            .scope(this.pane.pos)
            .subscribe((e: MouseEvent) => {
                this.offsetY = (e.currentTarget as Element).scrollTop;
                this.scrollContent.nativeElement.scrollTop = this.offsetY;
            });
    }

    ngOnInit(): void {
        this.subscribeToChanges();
        this.updateScrollWidth();
        // Angular right now does not support passivated event listeners, remove this as soon as it does and use
        // a normal host binding
        this.scrollContent.nativeElement.addEventListener(
            'wheel',
            $event => this.onScroll($event),
            { passive: true }
        );
        this.scrollContent.nativeElement.addEventListener(
            'scroll',
            $event => this.onScroll($event),
            { passive: true }
        );
    }

    ngOnDestroy(): void {
        if (this.scrollingSubscription) {
            this.scrollingSubscription.unsubscribe();
        }
    }

    public getScrollerStyle() {
        let styles = {
            overflowY: this.pane.pos === 'main' ? 'auto' : 'hidden',
            width: this.scrollWidth,
            height: this.scrollHeight + 'px'
        };

        return styles;
    }

    public setOffset(offsetX: number): void {
        const offset = offsetX || 0;
        this.offsetX = offset;
        this.updateScrollWidth();
    }

    private updateScrollWidth() {
        this.scrollWidth = (this.innerWidth + this.offsetX).toString() + 'px';
    }

    onWheel(e) {
        this.onScroll(e);
    }

    onScroll(e) {
        let scrollTarget = e.currentTarget;
        if (
            !scrollTarget ||
            scrollTarget.scrollTop === this.offsetY ||
            !scrollTarget.classList.contains('scrollable-content')
        ) {
            return;
        }
        this.offsetY = scrollTarget.scrollTop;
        this.scrollerService$.scrollUpdate(this.pane.pos, e);

        e.stopPropagation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['innerWidth']) {
            this.setOffset(0);
        }
        this.updateScrollWidth();
    }
}
