import actionCreatorFactory from 'typescript-fsa';
import { ActionCreator } from 'typescript-fsa/src';
import { PageIndex, Page, Label } from './page-definition.types';
import { PageDefinition, KpiProperties } from 'insightui.data/types';

const factory = actionCreatorFactory('PAGE_DEFINITION');

export const PAGE_INDEX_LOADED: ActionCreator<{
    pageIndices: ReadonlyArray<PageIndex>;
    pages: { [key: string]: Page };
}> = factory('PAGE_INDEX_LOADED');
export const LABELS_LOADED: ActionCreator<{
    [key: string]: ReadonlyArray<Label>;
}> = factory('LABELS_LOADED');
export const PAGE_LOADED: ActionCreator<PageDefinition> = factory('PAGE_LOADED');
export const KPIS_LOADED: ActionCreator<ReadonlyArray<KpiProperties>> = factory(
    'KPIS_LOADED'
);
