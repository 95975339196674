import { Injectable } from '@angular/core';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

import { PowerpointExportTypes } from 'insightui.export/types';

@Injectable()
export class ChartDetectionService {
    constructor(private visualizationContextService: VisualizationContextService) {}

    getPowerpointExportType(): keyof PowerpointExportTypes {
        return this.visualizationContextService.get('report.powerpointExportType');
    }
}
