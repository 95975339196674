<div class="rf-dropdown">
    <span title="Sort by">Sort by:</span>
    <span
        class="rf-dropdown__title rf-dropdown-toggle"
        (click)="toggleDropDown()"
        [attr.title]="selectedSortingTitle()"
    >
        <strong>{{ selectedSortingTitle() }}</strong>
        <i class="fa fa-chevron-down"></i>
    </span>
    <div
        class="rf-dropdown__content"
        [ngClass]="{ 'rf-dropdown__content--hidden': !isVisible }"
    >
        <ul class="[ rf-dropdown-list ]  [ list ]">
            <li class="rf-dropdown-list__item" *ngFor="let option of selectableOptions()">
                <a
                    class="rf-dropdown-list__link rf-dropdown-list__link--with-icon"
                    href="#"
                    (click)="handleSelectSorting(option)"
                    [ngClass]="{
                        'rf-dropdown-list__link--active': option.selected
                    }"
                >
                    {{ option.title }}
                    <i
                        class="rf-dropdown-icon fa"
                        [ngClass]="cssClassForSortingOption(option)"
                    ></i>
                </a>
            </li>
        </ul>
    </div>
</div>
