import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SadCloudComponent } from './sad-cloud.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [SadCloudComponent],
    imports: [CommonModule, RouterModule],
    exports: [SadCloudComponent]
})
export class SadCloudModule {}
