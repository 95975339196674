import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter,
    Output,
    HostListener
} from '@angular/core';

@Component({
    selector: 'gfk-announcements-bell',
    templateUrl: './announcements-bell.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementsBellComponent {
    @Output()
    bellClick = new EventEmitter<void>();

    @Input()
    hasError: boolean;

    @HostListener('click')
    componentClicked() {
        if (!this.hasError) {
            this.bellClick.emit();
        }
    }
}
