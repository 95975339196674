import { AnyAction } from 'redux';
import { Epic } from 'redux-observable';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { AppStateEpic } from 'projects/insightui/src/app/state/state.types';
import {
    FairShareHierarchies,
    FairShareState
} from 'insightui.fair-share/fair-share.types';
import { DrillTarget } from 'insightui.data/query/queryservice/query-service-request.interface';
import { DEPRECATED_FAIR_SHARE_DRILLING_PROPAGATE_TO_VISUALIZATION_CONTEXT } from 'insightui.fair-share/deprecated/deprecated.actions';
import { FairShareHierarchySelector } from 'insightui.fair-share/fair-share-hierarchy.selector';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { distinctUntilChanged } from 'rxjs/internal/operators';

@Injectable({
    providedIn: 'root'
})
export class DeprecatedDrillingEpic implements AppStateEpic<'fairShare'> {
    key: 'fairShare' = 'fairShare';

    constructor(
        private fairShareHierarchySelector: FairShareHierarchySelector,
        private visualizationContextService: VisualizationContextService
    ) {}

    getEpic(): Epic<AnyAction> {
        return (action$, state$: Observable<FairShareState>) => {
            const hierarchy$ = state$.pipe(map(state => state.hierarchy));
            const selectedTarget$ = state$.pipe(
                map(state => state.selectedTarget),
                filter(drillTarget => !!drillTarget),
                distinctUntilChanged()
            );

            return selectedTarget$.pipe(
                // do not propagate the change when there is nothing to change in the context
                // e.g. in case of the drill level switch which sets the context directly
                filter(drillTarget => this.isDifferentToCurrentTarget(drillTarget)),
                withLatestFrom(hierarchy$),
                map(([drillTarget, hierarchies]) => {
                    const hierarchyName = this.hierarchyNameForDrillTarget(
                        drillTarget,
                        hierarchies
                    );
                    return DEPRECATED_FAIR_SHARE_DRILLING_PROPAGATE_TO_VISUALIZATION_CONTEXT(
                        {
                            drillTarget: drillTarget,
                            hierarchyName: hierarchyName
                        }
                    );
                })
            );
        };
    }

    private isDifferentToCurrentTarget(otherTarget: DrillTarget): boolean {
        const currentTarget: DrillTarget =
            this.visualizationContextService.get('drillTarget') || {};

        return !this.isSameDrillTarget(otherTarget, currentTarget);
    }

    private isSameDrillTarget(
        drillTargetA: DrillTarget,
        drillTargetB: DrillTarget
    ): boolean {
        return (
            drillTargetA.dimensionId === drillTargetB.dimensionId &&
            drillTargetA.drillLevel === drillTargetB.drillLevel
        );
    }

    private hierarchyNameForDrillTarget(
        drillTarget: DrillTarget,
        hierarchies: FairShareHierarchies
    ): string {
        const hierarchy = this.fairShareHierarchySelector.getHierarchy(
            drillTarget,
            hierarchies
        );

        return this.fairShareHierarchySelector.hierarchyName(hierarchy);
    }
}
