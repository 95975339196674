import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { ReportingDataEntry } from 'insightui.data/query/queryservice/query-service-request.interface';
import { Injectable } from '@angular/core';

const ID_FIELD = '$id';
const KPI_SYMBOL = 'kpi';
const KPI_MATCHER = /^KPI/i;
const FEATURE_MATCHER = /^\$/i;
// TODO: also resolve brands by their id, even if it's from the dataset
const REMAP_ID = { BR: 'BID' };

/**
 * Data field values starting this identifier will be included in the {@link ID_FIELD}.
 */
const EXPLICIT_KEY_IDENTIFIER = '$';

const ROWID_EXPLICIT_KEY_IDENTIFIER = '$rowId';

/**
 * Mapper for transforming cell based data into rows that might contain multiple dimension attributes like channels.
 *
 * The aggregated values can be identified by the $id field, which has the special property to contain every cell attribute
 * prefixed with '$'. This is required in order to support results that where unique only due to the structure of the
 * data (e.g. row index) in the original result, but not in the data (two Tradebrands cannot be separated by their identifiers
 * as they contain the same id for both the Brand and maybe also the feature (#Sup#)).
 */
@Injectable()
export class CrossTabMapper implements ResponseMapper {
    private sideHeader: string[];
    private topHeader: string[];

    configure(sideHeader: string[], topHeader: string[]) {
        this.sideHeader = sideHeader.filter(e => e !== 'element');
        this.topHeader = topHeader.filter(e => e !== 'element');
    }

    /**
     * Map the {@link ReportingDataEntry} rows by using the sideHeaders as the column id and the topHeader as the values.
     *
     * @param reportingData             The cell bases reporting data to aggregate to a row format.
     * @returns {ReportingDataEntry[]}  The aggregated row values with an $id field that contains all identifying values of the row
     */
    map(reportingData: ReportingDataEntry[]): ReportingDataEntry[] {
        let result = {};
        let featureKeys = this.getFeatureKeys(reportingData);
        reportingData.forEach(row => {
            const sideHeaderKeys = this.getFlattenedKeysForHeader(this.sideHeader, row);
            const rowId = this.determineRowId(sideHeaderKeys, row);

            if (!result[rowId]) {
                result[rowId] = {};
                result[rowId][ID_FIELD] = rowId;
                sideHeaderKeys.forEach(key => (result[rowId][key] = row[key]));
                Object.keys(row)
                    .filter(key => FEATURE_MATCHER.test(key))
                    .forEach(key => {
                        result[rowId][key] = row[key];
                    });
                featureKeys.forEach(key => {
                    if (row[key]) {
                        result[rowId][key] = row[key];
                    }
                });
            }

            this.mapTopHeaders(result[rowId], row);
        });
        return Object.keys(result).map(key => result[key]);
    }

    private determineRowId(sideHeaderKeys: string[], row) {
        const rowId = sideHeaderKeys
            .map(key => REMAP_ID[key] || key)
            .map(key => row[key])
            .filter(e => e !== null)
            .map(key => String(key).replace(/[^A-z0-9]/g, ''))
            .join('_')
            .replace('missing_', '');

        const explicitKeys = Object.keys(row).filter(key =>
            key.startsWith(EXPLICIT_KEY_IDENTIFIER)
        );
        const rowIdIndex = explicitKeys.indexOf(ROWID_EXPLICIT_KEY_IDENTIFIER);
        if (rowIdIndex >= 0 && explicitKeys.length > 1) {
            explicitKeys.splice(rowIdIndex, 1);
            explicitKeys.push(ROWID_EXPLICIT_KEY_IDENTIFIER);
        }

        const explicitIdFields = explicitKeys.map(key => row[key]);
        return [rowId, ...explicitIdFields].join('_');
    }

    private getFlattenedKeysForHeader(
        header: string[],
        row: ReportingDataEntry
    ): string[] {
        let result = [];
        header.forEach(headerValue => {
            if (headerValue === KPI_SYMBOL) {
                Object.keys(row)
                    .filter(key => KPI_MATCHER.test(key))
                    .forEach(matchedKey => result.push(matchedKey));
            } else {
                result.push(headerValue);
            }
        });
        return result;
    }

    /**
     * Top Headers are mapped using all attributes that are not KPIs and then appending the kpis.
     * Afterwards, an ID Reordering is performed to properly have the KPI id in the correct position.
     * @param resultItem
     * @param row
     */
    private mapTopHeaders(resultItem: ReportingDataEntry, row: ReportingDataEntry) {
        const topHeaderKeys = this.topHeader;

        let prefix = topHeaderKeys
            .filter(key => key !== KPI_SYMBOL && row.hasOwnProperty(key))
            .map(key => row[key].toString().replace(/[\-_ ]/g, ''))
            .join('_');

        Object.keys(row)
            .filter(key => KPI_MATCHER.test(key))
            .forEach(kpi => (resultItem[prefix + '_' + kpi] = row[kpi]));
    }

    /**
     * Extracts feature keys
     */
    private getFeatureKeys(reportingData: ReportingDataEntry[]) {
        const keys = [];
        if (reportingData.length > 0) {
            Object.keys(reportingData[0]).forEach(key =>
                /^FT/.test(key) ? keys.push(key) : null
            );
        }
        return keys;
    }
}
