import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { ReportingDataSet } from 'insightui.data/query/queryservice/query-service-request.interface';
import { Injectable } from '@angular/core';

export interface AuxiliaryRowMapperServiceOptions {
    ignore?: string[];
}

const TAG = 'TG';
const WHITE_LIST = ['missing'];

/**
 * Service for remapping special responses like totals, others, etc.
 */
@Injectable()
export class AuxiliaryRowMapper implements ResponseMapper {
    private options: AuxiliaryRowMapperServiceOptions = {};

    configure(options: AuxiliaryRowMapperServiceOptions) {
        this.options.ignore = options.ignore || [];
    }

    map(reportingData: ReportingDataSet): ReportingDataSet {
        let excludedRows = {};

        const result: ReportingDataSet = reportingData.filter(row => {
            const matchedTag = String(row[TAG]).toLowerCase();
            if (
                !row[TAG] ||
                WHITE_LIST.indexOf(matchedTag) > -1 ||
                this.options.ignore.indexOf(matchedTag) > -1
            ) {
                return true;
            }
            excludedRows[matchedTag] = excludedRows[matchedTag] || [];
            excludedRows[matchedTag].push(row);
            return false;
        });
        result.additional = Object.assign(reportingData.additional || {}, excludedRows);
        return result;
    }
}
