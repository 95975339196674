<a
    href="#"
    class=" rf-dropdown-list__link show-whitespace"
    [ngClass]="{
        'rf-dropdown-list__link--inactive': !(isActive$ | async),
        'rf-dropdown-list__link--error': hasError$ | async,
        'rf-dropdown-list__link--success': wasSaved$ | async
    }"
    (click)="handleClick()"
    >{{ collection.name }}</a
>
