import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'datatable-footer',
    templateUrl: 'footer.component.html',
    host: {
        class: 'datatable-footer'
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableFooterComponent {
    @Input()
    footerHeight: number;

    @Input()
    rowCount: number;

    @Input()
    offset: number;
}
