import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'gfk-chart-view',
    templateUrl: 'chart-view.component.html'
})
export class ChartViewComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
