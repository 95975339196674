/**
 * Ng1 Module for the table implementation.
 */
import { NgModule } from '@angular/core';
import { NotificationNg1Service } from 'insightui.table/ng1services/notifications/notification-ng1.service';
import { DrillLevelSettingService } from 'insightui.table/ng1services/drilling-level-setting.service';
import { TopBottomModelService } from 'insightui.table/ng1services/topbottom-model.service';
import { ReportContextNotificationService } from 'insightui.table/ng1services/report-context-notification.service';
import { HeaderRemovedNotificationService } from 'insightui.table/ng1services/header-removed-notification.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        NotificationNg1Service,
        DrillLevelSettingService,
        TopBottomModelService,
        ReportContextNotificationService,
        HeaderRemovedNotificationService
    ]
})
export class Ng1Module {}
