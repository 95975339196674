<div
    class="tree-node"
    [ngClass]="'level' + nodeLevel"
    [class.leaf]="node.isLeaf"
    (click)="toggle()"
>
    <div class="expand-button" *ngIf="node.nodes && node.nodes.length > 0">
        <i
            class="fa"
            [class.fa-chevron-right]="node.isCollapsed"
            [class.fa-chevron-down]="!node.isCollapsed"
            aria-hidden="true"
        ></i>
    </div>
    <span [innerHtml]="node.labelTitle | highlight: searchText"></span>
    <span class="status">
        <span *ngIf="node.loadStatus === 1"
            ><strong>{{ period.short }}</strong></span
        >
        <span *ngIf="node.loadStatus === 2"
            ><i class="fa fa-clock-o" aria-hidden="true"></i
        ></span>
        <span *ngIf="node.loadStatus === 4"
            ><i class="fa fa-times" aria-hidden="true"></i
        ></span>
    </span>
</div>
<ol class="node-list" [class.hidden]="node.isCollapsed">
    <li *ngFor="let child of node.nodes | visibleNodes">
        <gfk-load-status-entry
            [node]="child"
            [period]="period"
            [searchText]="searchText"
            [nodeLevel]="nodeLevel + 1"
            *ngIf="child.isVisible"
        ></gfk-load-status-entry>
    </li>
</ol>
