import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { VisualizationContextState } from './visualization-context.state';
import { VcRankComparison } from './visualization-context.types';
import { Injectable } from '@angular/core';

@Injectable()
export class VisualizationContextSelectors {
    constructor() {}

    @Select(VisualizationContextState.focusDimension)
    public focusDimension$: Observable<string>;

    @Select(VisualizationContextState.segmentationDimension)
    public segmentationDimension$: Observable<string>;

    @Select(VisualizationContextState.channelList)
    public channelList$: Observable<string[]>;

    @Select(VisualizationContextState.rankComparison)
    public rankComparison$: Observable<VcRankComparison>;
}
