import { InjectionToken, Type } from '@angular/core';
import { Observable } from 'rxjs';
/**
 * HTML as a string
 */
export type HtmlString = string;

/**
 * The width of the image to render.
 */
export type ImageWidth = number;

/**
 * The height of the image to render.
 */
export type ImageHeight = number;

/**
 * The headline to insert into the presentation.
 */
export type PowerpointHeadline = string;

/**
 * Describes the type of a single powerpoint sheet
 */
export type PowerpointExportSheet = {
    readonly html: HtmlString;
    readonly headline: PowerpointHeadline;
    readonly subheadline: PowerpointHeadline;
    readonly width: ImageWidth;
    readonly height: ImageHeight;
};

/**
 * Describes a complete powerpoint export
 */
export type PowerpointExportSheets = ReadonlyArray<PowerpointExportSheet>;

/**
 * Describes a complete powerpoint collection export
 */
export type PowerpointExportCollection = {
    readonly title: string;
    readonly subtitle: string;
    readonly sheets: ReadonlyArray<PowerpointExportSheet>;
};

/**
 * Provides the data for the powerpoint export.
 */
export interface PowerpointExport {
    getPowerpointExportSheets(): Observable<PowerpointExportSheets>;
}

/**
 * Type mapping of report type (as defined in the author service) to the powerpoint export strategy
 */
export interface PowerpointExportTypes {
    readonly verticalReport: Type<PowerpointExport>;
    readonly barChart: Type<PowerpointExport>;
    readonly fixed: Type<PowerpointExport>;
    readonly table: Type<PowerpointExport>;
    readonly fairShare: Type<PowerpointExport>;
}

export const POWERPOINT_EXPORT_TYPES = new InjectionToken<PowerpointExportTypes>(
    'POWERPOINT_EXPORT_TYPES'
);

export type NumberOfChartRows = number;
export type NumberOfChartRowsPerPage = NumberOfChartRows[];
