import { Inject, Injectable } from '@angular/core';
import {
    LoadDataParams,
    LoadDataParamsFactory
} from '../../services/report/loadDataParamsFactory.service';
import { ChartDataMergeService } from '../../services/report/chartDataMerge.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import * as _ from 'lodash';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

@Injectable()
export class ChartDataService {
    private queryExecutor;

    private totalQueryService;

    private filterOptionsCreatorService;

    private $rootScope;

    private logger: ILogger;

    constructor(
        private loadDataParamsFactory: LoadDataParamsFactory,
        private chartDataMergeService: ChartDataMergeService,
        private visualizationContext: VisualizationContextService,

        logService: LogService,
        @Inject('$injector') private $injector
    ) {
        this.queryExecutor = $injector.get('QueryExecutor');
        this.totalQueryService = $injector.get('TotalQueryService');
        this.filterOptionsCreatorService = $injector.get('FilterOptionsCreatorService');
        this.$rootScope = $injector.get('$rootScope');
        this.logger = logService.getLogger('ChartDataService');
    }

    loadDataSetWithParams(loadDataParams: LoadDataParams): Promise<object> {
        const chartDataMergeReducer = this.chartDataMergeService.mergeData.bind(
            this.chartDataMergeService
        );

        return this.queryExecutor
            .createSerialQueue()
            .withReducer(chartDataMergeReducer)
            .add(loadDataParams)
            .execute();
    }

    loadData(
        totals?: boolean,
        splitBy?: string[],
        includeOthers?: boolean
    ): Promise<object> {
        const chartDataMergeReducer = this.chartDataMergeService.mergeData.bind(
            this.chartDataMergeService
        );
        const querySet = this.queryExecutor
            .createSerialQueue()
            .withReducer(chartDataMergeReducer);
        const parentQueue = this.queryExecutor
            .createParallelQueue()
            .withReducer(chartDataMergeReducer);

        const loadDataParams = this.loadDataParamsFactory.fromCurrentVisualizationContext(
            includeOthers,
            splitBy
        );
        const showComparisonPeriod = _.has(
            this.visualizationContext.get('comparisonPeriod'),
            'previous'
        );

        if (totals) {
            parentQueue.add(this.totalQueryService.getTotalsForParentDimension(splitBy));
            if (showComparisonPeriod) {
                parentQueue.add(
                    this.totalQueryService.getTotalsForParentDimension(
                        splitBy,
                        showComparisonPeriod
                    )
                );
            }
        }

        if (totals !== true) {
            querySet.add(loadDataParams);
            if (showComparisonPeriod) {
                querySet.beforeContinue(this.preparePreviousPeriod.bind(this));
                querySet.add(loadDataParams);
            }
        }
        return this.queryExecutor
            .createParallelQueue()
            .add(parentQueue, querySet)
            .withReducer((lastResult, currentResult) => {
                return lastResult.concat(currentResult.values);
            })
            .execute()
            .finally(() => {
                const selectedFilterOptions = this.filterOptionsCreatorService.create();
                if (_.isObject(selectedFilterOptions)) {
                    this.$rootScope.$broadcast(
                        'showFilterOptions',
                        selectedFilterOptions
                    );
                }
            });
    }

    /**
     * Apply the changed context.
     *
     * Returns a promise that will resovle, when a reload is required, otherwise rejected.
     *
     * @param contextSetting
     * @returns {*}
     */
    applyContextChange(contextSetting): Promise<void> {
        this.logger.debug('applyContextChange', contextSetting);

        return new Promise<void>((resolve, reject) => {
            this.visualizationContext.update(contextSetting).then(result => {
                if (!result.hasChanges) {
                    reject();
                    return;
                }
                // filter Options menu $broadcast
                resolve();
            });
        });
    }

    private ensureSameElementsAreSelected(loadDataParams, dataSet, accessor) {
        if (loadDataParams.elementList && loadDataParams.elementList.length) {
            return _.clone(loadDataParams.elementList);
        } else {
            return _(dataSet.values)
                .map(accessor.id)
                .filter((d: string) => {
                    return _.startsWith(d, accessor.filter);
                })
                .uniq()
                .value();
        }
    }

    private preparePreviousPeriod(loadDataParams, dataSet) {
        const accessor = this.getAccessors(loadDataParams.drillLevel);

        const elementList = this.ensureSameElementsAreSelected(
            loadDataParams,
            dataSet,
            accessor
        );
        if (elementList.length === 0) {
            return;
        }
        loadDataParams.setPeriodFromPrevious(
            this.visualizationContext.get('comparisonPeriod')
        );
        if (!this.visualizationContext.get('itemInBrand')) {
            loadDataParams.setElementList(elementList);
            const contextFilter = _.clone(this.visualizationContext.get('contextFilter'));
            contextFilter.filter = 'all';
            contextFilter.number = null;
            contextFilter.value = null;
            loadDataParams.setContextFilter(contextFilter);
        }
    }

    private getAccessors(level: string): { id: string; filter: string } {
        if (level === 'sector') {
            return { id: 'SG_ID', filter: 'SG' };
        }
        if (level === 'category') {
            return { id: 'CY_ID', filter: 'CY' };
        }
        if (level === 'productGroup') {
            return { id: 'PG_ID', filter: 'PG' };
        }
        return { id: 'BID_ID', filter: 'BR' };
    }
}
