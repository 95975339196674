import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { SortingModel, SortSetting } from 'insightui.core/models/sorting.model';
import { ColumnHeader } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { BsModalRef } from 'ngx-bootstrap';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';

/**
 * Model to operate with sorting column and
 * store in visualization context
 */
@Component({
    selector: 'custom-sort-modal',
    styleUrls: ['./custom-sort.component.scss'],
    templateUrl: './custom-sort.component.html'
})
export class CustomSortComponent implements OnInit {
    sortingColumns: SortSetting[] = [];
    sortingSelectableColumns: SortSetting[][] = [];
    allAvailableColumns: SortSetting[];
    sortingDirection = ['descending', 'ascending'];

    selected = [];

    private logger: ILogger;

    constructor(
        private bsModalRef: BsModalRef,
        private sortModel: SortingModel,
        private datatableConfigurationService: DatatableConfigurationService,
        private metadataService: MetadataService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('CustomSortComponent');
        const datatableConfig = this.datatableConfigurationService.getTableConfiguration();
        const headerSettings = this.datatableConfigurationService.getHeaderSettings(
            datatableConfig
        );
        let headerConfig = this.datatableConfigurationService.getColumnHeaderConfiguration(
            datatableConfig,
            headerSettings.topHeaderVerticalDimensions
        );

        const hasSplit = headerConfig.find(item => item.field === 'PF');
        if (hasSplit) {
            headerConfig = headerConfig.filter(item => item.children);
        }

        this.allAvailableColumns = this.flatten(
            this.createAllSortingColumns(headerConfig)
        );
    }

    ngOnInit() {
        this.sortingColumns = this.sortModel
            .getSortingAll()
            .filter(item => item.columnId);
        this.updateSelectableColumns();
        this.logger.debug(`Initialized`, this);
    }

    addLevel() {
        const alreadySetIds = this.sortingColumns.map(item => item.columnId);
        const filteredColumns = this.allAvailableColumns.filter(
            item => alreadySetIds.indexOf(item.columnId) < 0
        );
        const nextCol =
            filteredColumns.length > 0 ? Object.assign({}, filteredColumns[0]) : null;
        if (nextCol) {
            this.sortingColumns.push(nextCol);
            this.updateSelectableColumns();
        }
    }

    apply() {
        if (this.sortingColumns.length === 0) {
            this.sortModel.reset();
        }
        const newData = this.sortingColumns.slice();
        this.sortModel.readData(newData);
        this.sortModel.saveAndNotifyWithoutReload(true);
        const data = this.sortModel.clone();
        this.logger.debug(`apply`, { comp: this, clonedSortModel: data, newData });
        this.bsModalRef.hide();
    }

    removeColumn(index: number) {
        this.sortingColumns.splice(index, 1);
        this.updateSelectableColumns();
    }

    hideModal() {
        this.bsModalRef.hide();
    }

    onChangeColumn(selectedId: string, row: number) {
        const selected = this.allAvailableColumns.filter(
            item => item.columnId === selectedId
        );
        this.sortingColumns[row].columnId = selected[0].columnId;
        this.sortingColumns[row].columnKey = selected[0].columnKey;
        this.sortingColumns[row].columnTitle = selected[0].columnTitle;
        this.sortingColumns[row].fields = selected[0].fields;
        this.updateSelectableColumns();
    }

    onChangeDirection(direction: any, row: number) {
        this.sortingColumns[row].direction = Number(direction);
    }

    updateSelectableColumns() {
        const alreadySetIds = this.sortingColumns.map(item => item.columnId);
        const result = this.sortingColumns.map(item => {
            const filteredIds = alreadySetIds.filter(id => id !== item.columnId);
            return this.allAvailableColumns
                .slice()
                .filter(column => filteredIds.indexOf(column.columnId) < 0);
        });

        this.sortingSelectableColumns = result;
    }

    onDropped(evt: CdkDragDrop<any>) {
        moveItemInArray(this.sortingColumns, evt.previousIndex, evt.currentIndex);
        this.updateSelectableColumns();
        this.logger.debug(`onDroppped`, this);
    }

    private createAllSortingColumns(headerConfig: ColumnHeader[]) {
        return headerConfig.map(item => this.getAllColumsRec(item, '', ''));
    }

    private getAllColumsRec(
        headerItem: ColumnHeader,
        aggregatedName: string,
        aggregatedKey: string
    ) {
        const name = !!aggregatedName
            ? aggregatedName + ' - ' + headerItem.name
            : headerItem.name;

        const key = !!aggregatedKey
            ? aggregatedKey + '-' + this.getColumnKey(headerItem)
            : this.getColumnKey(headerItem);

        if (headerItem.children && headerItem.children.length > 0) {
            return headerItem.children.map(child =>
                this.getAllColumsRec(child, name, key)
            );
        } else {
            return {
                columnId: headerItem.field,
                fields: headerItem.field.split('_'),
                columnTitle: name,
                columnKey: key,
                direction: 0
            } as SortSetting;
        }
    }

    private getColumnKey(headerItem: ColumnHeader) {
        return headerItem.dimensionType || headerItem.name;
    }

    private flatten(arr) {
        return arr.reduce((flat, toFlatten) => {
            return flat.concat(
                Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten
            );
        }, []);
    }
}
