import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

export class StaticVisualizationContextService {
    private static instance: VisualizationContextService;

    static setInstance(instance: VisualizationContextService) {
        this.instance = instance;
    }

    static getInstance(): VisualizationContextService {
        return this.instance;
    }

    static __unitTestReset(): void {
        this.instance = undefined;
    }
}
