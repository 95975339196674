import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    FAIR_SHARE_DEACTIVATED,
    FAIR_SHARE_DRILL_DOWN,
    FAIR_SHARE_DRILL_UP,
    FAIR_SHARE_GAP_VALUE_ENTER,
    FAIR_SHARE_GAP_VALUE_LEAVE,
    FAIR_SHARE_GROWTH_VALUE_ENTER,
    FAIR_SHARE_GROWTH_VALUE_LEAVE
} from 'insightui.fair-share/fair-share.actions';
import { FairShareSelector } from 'insightui.fair-share/fair-share.selector';
import {
    FairShareDrill,
    FairShareDrillDirection,
    FairShareReportRow,
    FairShareReportRows,
    FairShareRowHover,
    FairShareState,
    ValueGrowthRangeBounds,
    ValueGrowthRangeBoundsStatus
} from 'insightui.fair-share/fair-share.types';
import { FILTER_REPORT_ACTIVATED } from 'insightui.report-filter/report-filter.actions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRootState } from 'insightui.state/app-root.state.interface';

@Component({
    selector: 'fair-share-view',
    templateUrl: './fair-share-view.component.html',
    styleUrls: ['./fair-share-view.component.scss']
})
export class FairShareViewComponent implements OnInit, OnDestroy {
    public rows$: Observable<FairShareReportRows>;

    public totalRow$: Observable<FairShareReportRow>;

    public maxFairShareValue$: Observable<number>;

    public shareLineValue$: Observable<number>;

    public drillTargetName$: Observable<string>;

    public hasNoDataLoaded$: Observable<boolean>;

    public valueGrowthRangeBounds$: Observable<ValueGrowthRangeBounds>;

    public hasReachedValueGrowthRangeBounds$: Observable<ValueGrowthRangeBoundsStatus>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private ngRedux: NgRedux<AppRootState>,
        private fairShareSelector: FairShareSelector
    ) {}

    ngOnInit(): void {
        const docId: string = this.activatedRoute.snapshot.queryParamMap.get('docId');
        this.ngRedux.dispatch(
            FILTER_REPORT_ACTIVATED({
                reportName: 'fair-share',
                deliveryId: parseInt(docId, 10)
            })
        );

        const state$: Observable<FairShareState> = this.ngRedux.select(
            state => state.fairShare
        );

        this.rows$ = state$.pipe(map(state => this.fairShareSelector.reportRows(state)));
        this.maxFairShareValue$ = state$.pipe(
            map(state => this.fairShareSelector.maxFairShareValue(state))
        );
        this.shareLineValue$ = state$.pipe(
            map(state => this.fairShareSelector.totalFairShareValue(state))
        );
        this.totalRow$ = state$.pipe(
            map(state => this.fairShareSelector.totalRow(state))
        );
        this.drillTargetName$ = state$.pipe(
            map(state => this.fairShareSelector.drillTargetName(state))
        );
        this.hasNoDataLoaded$ = state$.pipe(
            map(state => this.fairShareSelector.hasNoDataLoaded(state))
        );
        this.valueGrowthRangeBounds$ = state$.pipe(
            map(state => this.fairShareSelector.valueGrowthRangeBounds(state))
        );
        this.hasReachedValueGrowthRangeBounds$ = state$.pipe(
            map(state => this.fairShareSelector.valueGrowthRangeBoundsStatus(state))
        );
    }

    ngOnDestroy(): void {
        this.ngRedux.dispatch(FAIR_SHARE_DEACTIVATED());
    }

    handleFairShareHovering(hovering: FairShareRowHover): void {
        if (hovering.hover === 'enter') {
            this.ngRedux.dispatch(FAIR_SHARE_GAP_VALUE_ENTER(hovering.row.$rowId));
        } else {
            this.ngRedux.dispatch(FAIR_SHARE_GAP_VALUE_LEAVE(hovering.row.$rowId));
        }
    }

    handleGrowthHovering(hovering: FairShareRowHover): void {
        if (hovering.hover === 'enter') {
            this.ngRedux.dispatch(FAIR_SHARE_GROWTH_VALUE_ENTER(hovering.row.$rowId));
        } else {
            this.ngRedux.dispatch(FAIR_SHARE_GROWTH_VALUE_LEAVE(hovering.row.$rowId));
        }
    }

    handleDrilling(drill: FairShareDrill): void {
        if (drill.direction === FairShareDrillDirection.Up) {
            this.ngRedux.dispatch(FAIR_SHARE_DRILL_UP());
        } else if (drill.direction === FairShareDrillDirection.Down) {
            this.ngRedux.dispatch(FAIR_SHARE_DRILL_DOWN(drill.rowId));
        }
    }
}
