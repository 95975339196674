/**
 * Angular 2 component the Performance Indicator KPI base selection.
 **/

import {
    Component,
    ElementRef,
    Inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    ChannelComparisonModel,
    ChannelComparisonOption
} from 'insightui.core/models/reportSetting/channel-comparison.model';
import {
    ThresholdModel,
    ThresholdModelValue
} from 'insightui.core/models/reportSetting/threshold.model';
import { ColumnHeader } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { BaseTablePluginOptions } from 'insightui.table/components/plugins/shared/table-plugin.models';
import { BsDropdownDirective } from 'ngx-bootstrap';
import { WINDOW, UUID } from 'insightui.global/global.module';

const TABLE_PADDING_RIGHT = 20;

@Component({
    selector: 'performance-indicator-header',
    templateUrl: './performance-indicator-header.component.html'
})
export class PerformanceIndicatorHeaderComponent implements OnInit, OnChanges {
    @Input()
    titleTemplate = '${selection}';

    @Input()
    header: string;
    @Input()
    requireThreshold?: string;
    @Input()
    columnHeader?: ColumnHeader;

    model: ChannelComparisonModel;
    dropDownVisible = false;
    headerVisible = true;

    name: string;
    title: string;

    status: { autoClose: boolean } = { autoClose: true };

    threshold: ThresholdModelValue;

    @ViewChild('piDropdown')
    piDropdown: BsDropdownDirective;

    @ViewChild('piDropdownContent')
    set piDropdownContent(dropdownContentRef: ElementRef) {
        // exploiting the fact that the reference to the content gets resolved when the dropdown gets visible
        if (dropdownContentRef) {
            this.afterDropdownShown(dropdownContentRef);
        }
    }

    constructor(
        private channelComparisonModel: ChannelComparisonModel,
        private thresholdModel: ThresholdModel,
        @Inject(WINDOW) private window: Window,
        @Inject(UUID) private uuid: Function
    ) {
        // This name is used for the id and name, so it must be unique
        this.name = this.uuid();
    }

    public static select(
        cell: DatatableHeaderCellComponent,
        configuration: BaseTablePluginOptions
    ) {
        if (!cell.columnHeader) {
            return false;
        }
        let matches = true;
        if (configuration.kpis) {
            matches =
                matches &&
                PerformanceIndicatorHeaderComponent.kpiMatches(
                    configuration.kpis,
                    cell.columnHeader
                );
        }
        return matches;
    }

    private static kpiMatches(kpis: string[], cell: ColumnHeader) {
        if (cell.kpi) {
            return kpis.indexOf(cell.kpi) >= 0;
        }
        if (cell.children) {
            return cell.children.some(childHeader =>
                PerformanceIndicatorHeaderComponent.kpiMatches(kpis, childHeader)
            );
        }
        return false;
    }

    ngOnInit(): void {
        this.channelComparisonModel.readData();
        this.thresholdModel.readData();
        this.update();
        if (this.requireThreshold) {
            this.thresholdModel.filterNotification$.subscribe(() => this.update());
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.save();
    }

    onChange(option: ChannelComparisonOption) {
        this.model.data.forEach(modelOption => (modelOption.selected = false));
        option.selected = true;
        this.save();
    }

    save() {
        this.model.save().then(() => {
            this.update();
            this.dropDownVisible = false;
        });
    }

    extendExport(exportData) {
        return exportData;
    }

    private afterDropdownShown(dropdownContentRef: ElementRef) {
        const nativeElement: HTMLElement = dropdownContentRef.nativeElement;
        let existingLeftOffset = parseFloat(nativeElement.style.left || '0');
        if (isNaN(existingLeftOffset)) {
            existingLeftOffset = 0;
        }
        const rect: ClientRect = nativeElement.getBoundingClientRect();
        const overflowRight =
            rect.right -
            this.window.innerWidth +
            TABLE_PADDING_RIGHT -
            existingLeftOffset;

        let newLeft = 'auto';

        if (overflowRight > 0) {
            newLeft = `-${overflowRight}px`;
        }

        nativeElement.style.left = newLeft;
    }

    private updateTitle() {
        const selected = this.model.findOne('selected', true);
        if (!selected) {
            this.title = '';
        } else {
            this.title = this.titleTemplate.replace('${selection}', selected.title);
        }
    }

    private update() {
        this.model = this.channelComparisonModel.clone();
        if (this.requireThreshold) {
            this.threshold = this.thresholdModel
                .clone()
                .findOne(threshold => threshold.id === this.requireThreshold);
            this.headerVisible = this.threshold && this.threshold.active;
        }
        this.updateTitle();
        this.headerVisible = this.headerVisible && this.columnIsActiveComparisonBase();
    }

    public onShown() {
        this.update();
    }

    private columnIsActiveComparisonBase() {
        return (
            this.model.data.find(
                comparison =>
                    comparison.base === this.columnHeader.id && comparison.selected
            ) !== undefined
        );
    }
}
