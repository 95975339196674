import { Component, OnInit } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { Observable } from 'rxjs';
import {
    AnnouncementState,
    ReleaseNote
} from 'insightui.announcements/announcements.types';
import {
    ACTIVATE_RELEASE_NOTE_BY_IDX,
    BEGIN_CLOSE_MODAL,
    SHOW_FORCED_RELEASE_NOTES_IF_NEEDED,
    SHOW_RELEASE_NOTES_VIA_BELL
} from 'insightui.announcements/announcements.actions';
import { map, take, tap } from 'rxjs/operators';

@Component({
    selector: 'gfk-announcements-container',
    templateUrl: './announcements-container.html'
})
export class AnnouncementsContainerComponent implements OnInit {
    modalIsOpen$: Observable<boolean>;
    hasError$: Observable<boolean>;
    activeReleaseNote$: Observable<ReleaseNote>;
    numberOfPages$: Observable<number>;
    currentPageNumber$: Observable<number>;

    constructor(private ngRedux: NgRedux<AppRootState>) {}

    ngOnInit(): void {
        const announcements$: Observable<AnnouncementState> = this.ngRedux.select(
            state => state.announcements
        );

        this.modalIsOpen$ = announcements$.pipe(
            map(announcements => announcements.modalIsOpen)
        );
        this.hasError$ = announcements$.pipe(
            map(announcements => announcements.hasError)
        );

        this.activeReleaseNote$ = announcements$.pipe(
            map(announcements =>
                announcements.releaseNotes.find(
                    releaseNote =>
                        releaseNote.pageId === announcements.activeReleaseNoteId
                )
            )
        );

        this.numberOfPages$ = announcements$.pipe(
            map(announcements => announcements.releaseNotes.length)
        );
        this.currentPageNumber$ = announcements$.pipe(
            map(announcements =>
                announcements.releaseNotes.findIndex(
                    releaseNote =>
                        releaseNote.pageId === announcements.activeReleaseNoteId
                )
            )
        );

        this.ngRedux.dispatch(SHOW_FORCED_RELEASE_NOTES_IF_NEEDED());
    }

    closeModal(): void {
        this.ngRedux.dispatch(BEGIN_CLOSE_MODAL());
    }

    showReleaseNotesViaBell(): void {
        this.hasError$
            .pipe(
                take(1),
                tap(isError => {
                    if (!isError) {
                        this.ngRedux.dispatch(SHOW_RELEASE_NOTES_VIA_BELL());
                    }
                })
            )
            .subscribe();
    }

    gotoPage(pageNumber: number): void {
        this.ngRedux.dispatch(ACTIVATE_RELEASE_NOTE_BY_IDX(pageNumber));
    }
}
