import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';

export interface UnavailableReportInfo {
    numberOfUnavailableReports: number;
    unavailableCountryDisplayNames: string[];
    unavailableCountryCodes: string[];
}

/**
 * If all given reports are allowed in the given countries
 * returns a string in the format
 * `x` where x is the total number of records.
 *
 * If not all given reports are allowed in the given countries
 * returns a string in the format
 * `y/x` where y is the number of allowed records and x the total number of records.
 */
export function getNumberOfAvailableReportsForDisplay(
    reports: ReportSnapshotDto[] = [],
    allowedCountryCodes: string[] = []
): string {
    const totalReports = reports.length;
    let result = reports.length;
    reports.forEach(report => {
        if (!allowedCountryCodes.includes(report.countryCode)) {
            result--;
        }
    });

    if (result === totalReports) {
        return result.toString();
    }

    return `${result}/${totalReports}`;
}

/**
 * Returns true if the given reports contain at least on report which has a country which
 * is not contained in the given allowedCoutnryCodes.
 */
export function hasOnlyUnavailableReports(
    reports: ReportSnapshotDto[] = [],
    allowedCountryCodes: string[] = []
): boolean {
    if (reports.length === 0) {
        return false;
    }
    const result = reports.every(
        report => !allowedCountryCodes.includes(report.countryCode)
    );
    return result;
}

/**
 * Returns true if the given reports contain at least one report which has a country which
 * is not contained in the given allowedCountryCodes.
 */
export function hasAtLeastOneUnavailableReport(
    reports: ReportSnapshotDto[] = [],
    allowedCountryCodes: string[] = []
): boolean {
    if (reports.length === 0) {
        return false;
    }
    const result = reports.some(
        report => !allowedCountryCodes.includes(report.countryCode)
    );
    return result;
}

/**
 * Gets information if the given reports contain reports which are for another country
 * than the given countryCodes.
 *
 * Returns an object which contains the number of unavailable reports
 * together with the information which countries are not available.
 */
export function getUnavailableReportInfo(
    reports: ReportSnapshotDto[] = [],
    allowedCountryCodes: string[] = []
): UnavailableReportInfo {
    const unavailableCountryDisplayNamesSet = new Set<string>();
    const unavailableCountryIdsSet = new Set<string>();
    let numberOfUnavailableReports = 0;
    reports.forEach(report => {
        if (!allowedCountryCodes.includes(report.countryCode)) {
            numberOfUnavailableReports++;
            unavailableCountryDisplayNamesSet.add(report.countryDisplayName);
            unavailableCountryIdsSet.add(report.countryCode);
        }
    });
    const unavailableCountryDisplayNames = Array.from(
        unavailableCountryDisplayNamesSet
    ).sort();
    const unavailableCountryCodes = Array.from(unavailableCountryIdsSet).sort();

    return {
        numberOfUnavailableReports,
        unavailableCountryDisplayNames,
        unavailableCountryCodes
    };
}

@Injectable()
export class CountryService {
    constructor(private store: Store) {}

    /**
     * Get the country codes the current user has access to.
     */
    getCurrentCountryCodesOfUser(): string[] {
        const countries =
            this.store.selectSnapshot(state => state.metadata.countries) || [];
        return countries.map(country => country.countryCode);
    }
}
