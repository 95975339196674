import { Directive, HostListener, Input } from '@angular/core';
import { LegendRow, LegendRowIndex } from '../types';
import { DimensionId } from 'insightui.data/types';

declare const d3;

const $ = (selector: string) => document.querySelectorAll(selector);

@Directive({
    selector: '[chartLegendCellItemForRow]'
})
export class ChartLegendCellItemForRowDirective {
    @Input()
    chartLegendCellItemForRow: LegendRow = null;

    @Input()
    cellItemEnabled = true;

    private static renderAsLastChild(element: HTMLElement): void {
        const parent = element.parentNode;
        parent.removeChild(element);
        parent.appendChild(element);
    }

    @HostListener('mouseenter')
    handleMouseEnter(): void {
        if (!this.cellItemEnabled) {
            return;
        }

        const counter: LegendRowIndex = this.chartLegendCellItemForRow.index;
        if (counter) {
            d3.selectAll($('.chart-body rect')).attr('opacity', 0.2);
            d3.selectAll($('text.stacked-chart-label.others')).attr('opacity', 0.2);
            d3.selectAll($('.chart-body .' + counter + ' rect')).attr('opacity', 1);
            d3.selectAll($('.chart-body .' + counter + ' text')).attr('opacity', 1);

            const stack = $('.chart-body .stack.' + counter);
            d3.selectAll(stack)
                .classed('highlight', true)
                .each(function() {
                    ChartLegendCellItemForRowDirective.renderAsLastChild(this);
                });
        }

        // activate line chart labels
        const rowId: DimensionId = this.chartLegendCellItemForRow.id;
        if (rowId) {
            d3.selectAll($('.dimension-' + rowId + ' .line-value-label')).classed(
                'hidden',
                false
            );
        }
    }

    @HostListener('mouseleave')
    handleMouseLeave(): void {
        if (!this.cellItemEnabled) {
            return;
        }

        d3.selectAll($('.chart-body rect')).attr('opacity', 1);
        d3.selectAll($('text.stacked-chart-label.others')).attr('opacity', 1);
        d3.selectAll($('.chart-body .stack.highlight')).classed('highlight', false);

        const rowId: DimensionId = this.chartLegendCellItemForRow.id;
        if (rowId) {
            d3.selectAll($('.dimension-' + rowId + ' .line-value-label')).classed(
                'hidden',
                true
            );
        }
    }
}
