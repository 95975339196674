import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SessionService } from 'insightui.session/session.service';
import { Observable } from 'rxjs';
import { SessionUserDetails } from 'insightui.session/session.types';

@Component({
    selector: 'gfk-sad-cloud',
    templateUrl: './sad-cloud.component.html',
    styleUrls: ['./sad-cloud.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SadCloudComponent implements OnInit {
    constructor(private sessionService: SessionService) {}

    @Input()
    headline: string;

    @Input() description: string;

    @Input() showHomeButton = false;

    @Input() errorCategory: string;

    readonly dateTimeString = new Date().toISOString();

    userDetails$: Observable<SessionUserDetails>;

    ngOnInit() {
        this.userDetails$ = this.sessionService.currentUserDetails();
    }
}
