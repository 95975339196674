import { NgModule } from '@angular/core';
import { DashboardComponent } from 'insightui.dashboard/dashboard.component';
import { DashboardService } from 'insightui.dashboard/dashboard.service';
import { CommonModule } from '@angular/common';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';

@NgModule({
    declarations: [DashboardComponent],
    exports: [DashboardComponent],
    providers: [
        DashboardService,
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?status\/?$/,
            multi: true
        }
    ],
    imports: [CommonModule]
})
export class DashboardModule {}
