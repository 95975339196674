import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
/**
 * Factory for retrieving the ng1 HeaderRemovedNotificationService.
 */
@Injectable()
export class HeaderRemovedNotificationService {
    private ng1HeaderRemovedNotificationService;

    constructor(injector: AngularInjectorResolver) {
        injector.get().subscribe($injector => {
            this.ng1HeaderRemovedNotificationService = $injector.get(
                'HeaderRemovedNotificationService'
            );
        });
    }

    public notify(name) {
        return this.ng1HeaderRemovedNotificationService.notify.call(this, name);
    }
}
