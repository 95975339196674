export * from './announcement-controller.service';
import { AnnouncementControllerService } from './announcement-controller.service';
export * from './collection-controller.service';
import { CollectionControllerService } from './collection-controller.service';
export * from './collection-info-controller.service';
import { CollectionInfoControllerService } from './collection-info-controller.service';
export * from './favorites-controller.service';
import { FavoritesControllerService } from './favorites-controller.service';
export * from './schedule-controller.service';
import { ScheduleControllerService } from './schedule-controller.service';
export * from './status-controller.service';
import { StatusControllerService } from './status-controller.service';
export * from './user-event-controller.service';
import { UserEventControllerService } from './user-event-controller.service';
export const APIS = [
    AnnouncementControllerService,
    CollectionControllerService,
    CollectionInfoControllerService,
    FavoritesControllerService,
    ScheduleControllerService,
    StatusControllerService,
    UserEventControllerService
];
