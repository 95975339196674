import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { Injector } from '@angular/core';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { MetadataSelectors } from 'insightui.metadata/state/metadata.selectors';

export const PLUGIN_FEATURE_MARKER = 'data-feature-drilling-cell';
export const PLUGIN_CSS_CLASS = 'plugin-drilling-cell';

const BLACK_LIST = ['Others', 'SubTotal'];

export class DrillingCellPlugin extends TableBodyPlugin {
    name = 'DrillingCellPlugin';
    drillingService: DrillingService;
    metadataSelectors: MetadataSelectors;

    constructor(injector: Injector, configuration: BaseTablePluginOptions) {
        super(injector, configuration);
        this.metadataSelectors = this.injector.get(MetadataSelectors);
        this.drillingService = this.injector.get(DrillingService);
    }

    executeDrilling(cell: Cell) {
        if (this.isTotal(cell)) {
            return this.drillingService.drillUp();
        }
        return this.drillingService.drillDown(cell.row.$id.split('_')[0], cell.value);
    }

    select(cell: Cell): boolean {
        return (
            super.select(cell) &&
            this.isSupportedTag(cell) &&
            this.drillingService.supports(this.isTotal(cell) ? 'up' : 'down')
        );
    }

    isSupportedTag(cell: Cell): boolean {
        if (!cell.row.TG) {
            return true;
        }
        // right now, only Total supports drilling (up)
        // TODO: We have to find a better way to identify the content of a cell
        const id = String(cell.row.$id || '').split('_')[0];
        const dimension =
            cell.row.TG === 'missing' && this.metadataSelectors.getHierarchyDimension(id);
        const supportedTag =
            cell.row.TG === 'Total' || (dimension && dimension.hasAvailableData);
        if (!supportedTag && BLACK_LIST.indexOf(cell.row.TG) < 0) {
            cell.element.nativeElement.classList.add('not-drillable');
        }
        return supportedTag;
    }

    protected isTotal(cell: Cell): boolean {
        return cell.row.TG === 'Total';
    }

    protected doEnable(cell: Cell) {
        const rootNode: HTMLElement = cell.element.nativeElement;
        if (!rootNode) {
            console.error(`${this.name} executed on cell without element`);
            return;
        }
        if (rootNode.hasAttribute(PLUGIN_FEATURE_MARKER)) {
            // plugin already enabled
            return;
        }
        this.addDrillDownHandler(cell);
        rootNode.setAttribute(PLUGIN_FEATURE_MARKER, 'true');
    }

    protected doDisable(cell: Cell) {
        const rootNode: HTMLElement = cell.element.nativeElement;
        if (!rootNode) {
            console.error(`${this.name} executed on cell without element`);
            return;
        }
        if (!rootNode.hasAttribute(PLUGIN_FEATURE_MARKER)) {
            // plugin not applied
            return;
        }
        this.removeDrillDownHandler(cell);
        rootNode.removeAttribute(PLUGIN_FEATURE_MARKER);
    }

    private removeDrillDownHandler(cell: Cell) {
        const label = cell.element.nativeElement;
        label.classList.remove(PLUGIN_CSS_CLASS);
        if (label.$drillingPluginHandler) {
            label.removeEventListener('click', label.$drillingPluginHandler);
        }
    }

    private addDrillDownHandler(cell: Cell) {
        const label = cell.element.nativeElement;

        label.classList.add(PLUGIN_CSS_CLASS);
        label.$drillingPluginHandler = () => {
            this.executeDrilling(cell);
            label.removeEventListener('click', label.$drillingPluginHandler);
        };
        label.addEventListener('click', label.$drillingPluginHandler);
    }

    extendExport(exportData) {
        return exportData;
    }
}
