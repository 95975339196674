import { LandingPageComponent, LandingPageDirective } from './landing-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    declarations: [LandingPageComponent, LandingPageDirective],
    exports: [LandingPageComponent]
})
export class LandingPageModule {}
