<div
    [ngClass]="{ 'top-metabar-icon--inactive': hasError$ | async }"
    [attr.title]="
        (hasError$ | async)
            ? 'Favorites Manager is currently not available. We are very sorry for the inconvenience this may cause. Please try again later.'
            : null
    "
    gfkFavouriteSelectByReportAction
    [hasError]="hasError$ | async"
></div>
