import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SortingModel } from 'insightui.core/models/sorting.model';
import { StaticVisualizationContextService } from 'insightui.data/shared/static-visualization-context.service';
import { CustomSortComponent } from 'insightui.table/components/modal/custom-sort.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { merge } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
    selector: 'custom-sort-icon',
    styles: [
        `
            .interactive-header-icon-custom-sort {
                display: inline-block;
                margin-top: 3px;
                margin-right: 3px;
                cursor: pointer;
            }

            .interactive-header-icon-custom-sort::before {
                content: url(assets/images/custom_sort.png);
            }

            .interactive-header-icon-custom-sort:hover::before {
                content: url(assets/images/custom_sort_hover.png);
            }

            .interactive-header-icon-custom-sort.active::before {
                content: url(assets/images/custom_sort_hover.png);
            }

            .interactive-header-icon-custom-sort.deactivated::before {
                content: url(assets/images/custom_sort.png);
                opacity: 0.3;
                cursor: default;
            }
        `
    ],
    template: `
        <div
            (click)="openModal()"
            class="uitest-custom-sort header-container header-container-rightContainerSetting"
        >
            <span
                class="interactive-header-icon-custom-sort uitest-custom-sort-icon"
                title="Custom Sort"
                [ngClass]="{ deactivated: disabled, active: active }"
            ></span>
        </div>
    `
})
export class CustomSortIconComponent implements OnInit, OnDestroy {
    @Input() disabledDimensions: string;

    bsModalRef: BsModalRef;

    active = false;
    disabled = false;

    private destroyed = false;

    constructor(
        private sortingModel: SortingModel,
        private modalService: BsModalService
    ) {}

    ngOnInit() {
        merge(
            this.sortingModel.filterNotification$,
            this.sortingModel.resetNotification$,
            this.sortingModel.sortingChanged$
        )
            .pipe(takeWhile(() => !this.destroyed))
            .subscribe(() => this.updateState());
        this.updateState();
    }

    openModal() {
        if (!this.disabled) {
            this.bsModalRef = this.modalService.show(CustomSortComponent);
        }
    }

    updateState() {
        this.disabled =
            this.disabledDimensions
                .split(',')
                .map(item => StaticVisualizationContextService.getInstance().get(item))
                .filter(item => item).length > 0;

        this.active =
            this.sortingModel.clone().data.find(item => item.columnId) !== undefined;
    }

    ngOnDestroy() {
        this.destroyed = true;
    }
}
