// tslint:disable no-console

import { Injectable } from '@angular/core';
import { JL } from 'jsnlog';
import { environment } from '../../../../environments/environment';

export interface ILogger {
    getName(): string;
    debug(...params: any[]): void;
    info(...params: any[]): void;
    warn(...params: any[]): void;
    error(...params: any[]): void;
}

class NoopLogger implements ILogger {
    getName(): string {
        return 'NoopLogger';
    }
    debug(...params: any[]): void {}
    info(...params: any[]): void {}
    warn(...params: any[]): void {}
    error(...params: any[]): void {
        console.error(...params);
    }
}

/**
 * This is a simple implementation which adds logger name to each message.
 * Is used when running in a browser.
 */
class NiceConsoleLogger implements ILogger {
    constructor(private readonly loggerName: string) {}

    getName(): string {
        return this.loggerName;
    }

    debug(...params: any[]): void {
        this._debug(...params);
    }

    info(...params: any[]): void {
        this._info(...params);
    }

    warn(...params: any[]): void {
        this._warn(...params);
    }

    error(...params: any[]): void {
        this._error(...params);
    }

    private _debug(message?: any, ...optionalParams: any[]): void {
        console.debug(this.getMessage(message), 'color: grey', '', ...optionalParams);
    }

    private _error(message?: any, ...optionalParams: any[]): void {
        console.error(this.getMessage(message), 'color: grey', '', ...optionalParams);
    }

    private _info(message?: any, ...optionalParams: any[]): void {
        console.info(this.getMessage(message), 'color: grey', '', ...optionalParams);
    }

    private _warn(message?: any, ...optionalParams: any[]): void {
        console.warn(this.getMessage(message), 'color: grey', '', ...optionalParams);
    }

    private getMessage(message?: string): string {
        // note: the %c enhable css formatting for the console log messages.
        return `%c${this.loggerName}: %c${message}`;
    }
}

/**
 * This is a very simple implementation used during running unit tests with karma.
 */
class SimpleConsoleLogger implements ILogger {
    constructor(private readonly loggerName: string) {}

    getName(): string {
        return this.loggerName;
    }

    debug(...params: any[]): void {
        console.debug(...params);
    }

    info(...params: any[]): void {
        console.info(...params);
    }

    warn(...params: any[]): void {
        console.warn(...params);
    }

    error(...params: any[]): void {
        console.error(...params);
    }
}

class DummyLogger implements ILogger {
    constructor(private loggerName: string) {}
    getName(): string {
        return this.loggerName;
    }

    enter(...params: any[]): void {}
    exit(...params: any[]): void {}
    debug(...params: any[]): void {}
    info(...params: any[]): void {}
    warn(...params: any[]): void {}
    error(...params: any[]): void {}
}

function addBearerToken(xhr: XMLHttpRequest) {
    const token = window.sessionStorage.getItem('authToken');
    if (token) {
        xhr.setRequestHeader('authorization', `Bearer ${token}`);
    }
}

const appender = JL.createAjaxAppender('server');
appender.setOptions({
    batchSize: 9,
    batchTimeout: 2000,
    level: JL.getInfoLevel(),
    beforeSend: addBearerToken,
    url: '/logs/log'
});
JL().setOptions({
    appenders: [appender]
});

const MAX_CLIENT_MESSAGE_SIZE = 1024 * 10; // Do never send more than 10kb per message

class ServerLogger implements ILogger {
    constructor(private loggerName: string = 'default') {}
    /**
     * This makes it possible to stringify objects which have circular dependencies.
     * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
     */
    private getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

    getName(): string {
        return this.loggerName;
    }

    debug(...params: any[]): void {
        // do nothing on debug
    }

    info(...params: any): void {
        // do nothing on info
    }

    warn(...params: any): void {
        JL(this.loggerName).warn(this.prepareMessage(...params));
    }

    error(...params: any): void {
        JL(this.loggerName).error(this.prepareMessage(...params));
    }

    private prepareMessage(...params: any[]): string {
        return JSON.stringify(params, this.getCircularReplacer()).substring(
            0,
            MAX_CLIENT_MESSAGE_SIZE
        );
    }
}

class ClientAndServerLogger implements ILogger {
    private internalLoggers: ILogger[] = [];

    constructor(private loggerName: string = 'default') {
        this.internalLoggers.push(new NiceConsoleLogger(loggerName));
        this.internalLoggers.push(new ServerLogger(loggerName));
    }

    getName(): string {
        return this.internalLoggers[0].getName();
    }

    debug(...params: any[]): void {
        this.internalLoggers.forEach(logger => {
            logger.debug(...params);
        });
    }

    info(...params: any[]): void {
        this.internalLoggers.forEach(logger => {
            logger.info(...params);
        });
    }

    warn(...params: any[]): void {
        this.internalLoggers.forEach(logger => {
            logger.warn(...params);
        });
    }

    error(...params: any[]): void {
        this.internalLoggers.forEach(logger => {
            logger.error(...params);
        });
    }
}

/**
 * Angular service for logging
 */
@Injectable({
    providedIn: 'root'
})
export class LogService {
    private isDebug: boolean;
    private isProd = environment.production;

    constructor() {
        this.isDebug = !!(window.localStorage.getItem('logger.debug') || false);
    }

    getLogger(loggerName: string): ILogger {
        if (this.isProd && !this.isDebug) {
            return new ServerLogger(loggerName);
        }

        if (!(window as any).__karma__) {
            return new ClientAndServerLogger(loggerName);
        } else {
            return new SimpleConsoleLogger(loggerName);
        }
    }
}

@Injectable()
export class MockLogService extends LogService {
    getLogger(loggerName: string): ILogger {
        return new DummyLogger(loggerName);
    }
}
