import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';

import { LoadStatusDialogComponent } from 'insightui.top-meta-bar/load-status/load-status-dialog/load-status-dialog.component';
import { NewLoadStatusUserService } from 'insightui.core/services/newLoadStatusUserService';
import { NewLoadStatusUpdatesService } from 'insightui.core/services/newLoadStatusUpdatesService';

@Component({
    selector: 'gfk-load-status',
    templateUrl: './new-load-status.component.html',
    styleUrls: ['./new-load-status.component.scss']
})
export class NewLoadStatusComponent implements OnInit {

    @Input()
    newlyLoadedUpdates: any;

    newlyLoadedDimensions = [
        { key: 'sector', value: 'Sector' },
        { key: 'category', value: 'Categories' },
        { key: 'productGroup', value: 'Product Groups' }
    ];

    @ViewChild('headerContainer')
    headerContainer;

    constructor(
        private matDialog: MatDialog,
        private newLoadStatusUserService: NewLoadStatusUserService,
        private newLoadStatusUpdatesService: NewLoadStatusUpdatesService) {
    }

    /**
     * OnInit angular life cycle hook.
     * Load the parameters from route.
     */
    ngOnInit() {
        console.log(this.newlyLoadedUpdates);
    }

    newlyLoadedToggle() {
        this.newlyLoadedUpdates.show = true;
        this.newLoadStatusUpdatesService.loadStatusOpened.emit(this.newlyLoadedUpdates);
    };

    newlyLoadedOnBlur() {
        this.newlyLoadedUpdates.show = false;
    };

    openLoadStatusDialog() {
        this.matDialog.open(LoadStatusDialogComponent, {
            data: {},
            width: '600px',
            autoFocus: false
        });
    };

    newlyLoadedSetChecked() {
        const countryCode = _.get(this.newlyLoadedUpdates, 'countryCode');
        const period = _.get(this.newlyLoadedUpdates, 'period');

        const self = this;
        this.newLoadStatusUserService.setLastLoadStatusCheck(countryCode, period).toPromise().then(
            () => {
                _.set(self.newlyLoadedUpdates, 'result.sector', []);
                _.set(self.newlyLoadedUpdates, 'result.category', []);
                _.set(self.newlyLoadedUpdates, 'result.productGroup', []);
            }
        );

        this.newlyLoadedUpdates.show = false;
    };

    getTotalChanged() {
        return (
            _.get(this.newlyLoadedUpdates, 'result.sector', []).length +
            _.get(this.newlyLoadedUpdates, 'result.category', []).length +
            _.get(this.newlyLoadedUpdates, 'result.productGroup', []).length
        );
    };
}
