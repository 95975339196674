import { InjectionToken } from '@angular/core';

/**
 * This file is for upgrading legacy AngularJS services to be usable within
 * Angular.
 *
 * See https://angular.io/guide/upgrade#making-angularjs-dependencies-injectable-to-angular
 *
 * TODO: find a way to get rid of those legacy services completely.
 */

export interface Ng1NotificationServicesInterface {
    subscribeService(callback: () => void);
}

export const legacyDrillingNotificationService = new InjectionToken<
    Ng1NotificationServicesInterface
>('legacyDrillingNotificationService');
export const legacyFilterNotificationService = new InjectionToken<
    Ng1NotificationServicesInterface
>('legacyFilterNotificationService');
export const legacySegmentationNotificationService = new InjectionToken<
    Ng1NotificationServicesInterface
>('legacySegmentationNotificationService');

export function drillingNotificationServiceFactory(i: any) {
    return i.get('DrillingNotificationService');
}

export function filterNotificationServiceFactory(i: any) {
    return i.get('FilterNotificationService');
}

export function segmentationNotificationServiceFactory(i: any) {
    return i.get('SegmentationNotificationService');
}

export const legacyDrillingNotificationServiceProvider = {
    provide: legacyDrillingNotificationService,
    useFactory: drillingNotificationServiceFactory,
    deps: ['$injector']
};

export const legacyFilterNotificationServiceProvider = {
    provide: legacyFilterNotificationService,
    useFactory: filterNotificationServiceFactory,
    deps: ['$injector']
};

export const legacySegmentationNotificationServiceProvider = {
    provide: legacySegmentationNotificationService,
    useFactory: segmentationNotificationServiceFactory,
    deps: ['$injector']
};
