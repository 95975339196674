<div
    class="u-text-h2"
    [ngClass]="{ 'top-metabar-icon--inactive': hasError$ | async }"
    title="{{ sharedCollectionNotificationText$ | async }}"
    data-uitest="collection-manager-shortcut"
    (click)="openCollectionManager()"
>
    <i class="fa  fa fa-bar-chart"></i>

    <div
        class="new-shared-collections-count"
        data-uitest="new-shared-collections-count"
        *ngIf="!!(newSharedCollectionsCount$ | async)"
    >
        {{ newSharedCollectionsCount$ | async }}
    </div>
</div>
