import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalDialogModule } from 'insightui.common-components/modal-dialog/modal-dialog.module';
import { SearchBoxModule } from 'insightui.common-components/search-box/search-box.module';
import { LoadStatusDialogComponent } from './load-status-dialog/load-status-dialog.component';
import { LoadStatusButtonComponent } from './load-status-button/load-status-button.component';
import { LoadStatusEntryComponent } from './load-status-entry/load-status-entry.component';
import { VisibleNodesPipe } from './pipes/visible-nodes.pipe';

@NgModule({
    declarations: [
        LoadStatusDialogComponent,
        LoadStatusButtonComponent,
        LoadStatusEntryComponent,
        VisibleNodesPipe
    ],
    imports: [
        CommonModule,
        SearchBoxModule,
        ModalDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule
    ],
    exports: [LoadStatusDialogComponent, LoadStatusButtonComponent]
})
export class LoadStatusModule {}
