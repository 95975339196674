import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'gfk-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalFooterComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
