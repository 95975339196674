import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class NewReportingDimensionInfoService {

    REPORTING_DIMENSION_INFO_OLD = [
        {
            dim: 'area',
            prefix: 'AR',
            id: 10401,
            name: 'Reporting Area'
        },
        {
            dim: 'sector',
            prefix: 'SG',
            id: 10301,
            name: 'Reporting Sector'
        },
        {
            dim: 'category',
            prefix: 'CY',
            id: 10201,
            name: 'Reporting Category'
        },
        {
            dim: 'productGroup',
            prefix: 'PG',
            id: 10101,
            name: 'Reporting Product Group'
        }
    ];

    constructor() {

    }

    get(id) {
        return _.find(this.REPORTING_DIMENSION_INFO_OLD, (info) => {
            return info.id === id || info.dim === id || info.prefix === id;
        });
    };

    getDimensions() {
        return _.map(this.REPORTING_DIMENSION_INFO_OLD, (info) => {
            return _.get(info, 'dim');
        });
    };
}
