import { CollectionDto, ReportSnapshotDto } from 'insightui.openapi/userprofile';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Adds a new (empty) collection to the personal collections. Does not save it
 * to the server yet.
 */
export class CmPrepareNewCollection {
    static readonly type = '[CM] PrepareNewCollection';
}

export class CmCreateCollection {
    static readonly type = '[CM] CreateCollection';

    constructor(
        public readonly newName: string,
        public readonly precedingCollectionId: string,

        public readonly copyOfId?: string
    ) {}
}

export class CmCreateCollectionSuccess {
    static readonly type = '[CM] CreateCollectionSuccess';
    constructor(public readonly collection: CollectionDto) {}
}

export class CmDuplicateCollection {
    static readonly type = '[CM] DuplicateCollection';

    constructor(public readonly collectionId: string) {}
}

export class CmSaveAsUserCollection {
    static readonly type = '[CM] SaveAsUserCollection';

    constructor(public readonly collectionId: string, public readonly newName: string) {}
}

export class CmReorderCollection {
    static readonly type = '[CM] ReorderCollection';

    constructor(
        public readonly movedCollectionId: string,
        public readonly precedingCollectionId: string | null
    ) {}
}

export class CmUpdateCollection {
    static readonly type = '[CM] UpdateCollection';

    constructor(public readonly collectionId: string, public readonly newName: string) {}
}

export class CmUpdateCollectionSuccess {
    static readonly type = '[CM] UpdateCollectionSuccess';

    constructor(public readonly collection: CollectionDto) {}
}

export class CmDeleteCollection {
    static readonly type = '[CM] DeleteCollection';

    constructor(public readonly collectionId: string) {}
}

export class CmRestoreCollection {
    static readonly type = '[CM] RestoreCollection';

    constructor(public readonly collectionId: string, public readonly newName: string) {}
}

export class CmDeleteCollectionSuccess {
    static readonly type = '[CM] DeleteCollectionSuccess';

    constructor(public readonly collection: CollectionDto) {}
}

export class CmRestoreCollectionSuccess {
    static readonly type = '[CM] RestoreCollectionSuccess';

    constructor(public readonly collection: CollectionDto) {}
}
export class CmRenameReport {
    static readonly type = '[CM] RenameReport';

    constructor(
        public readonly reportSnapshotId: string,
        public readonly newName: string
    ) {}
}

export class CmRenameReportSuccess {
    static readonly type = '[CM] RenameReportSuccess';

    constructor(public readonly report: ReportSnapshotDto) {}
}

export class CmDeleteReport {
    static readonly type = '[CM] DeleteReport';

    constructor(public readonly reportSnapshotId: string) {}
}

export class CmDeleteReportSuccess {
    static readonly type = '[CM] DeleteReportSuccess';

    constructor(public readonly reportSnapshotId: string) {}
}

export class CmSelectCollection {
    static readonly type = '[CM] SelectCollection';

    constructor(public readonly selectedCollection: CollectionDto) {}
}

export class CmLoadCollections {
    static readonly type = '[CM] LoadCollections';
}

export class CmLoadCollectionsSuccess {
    static readonly type = '[CM] LoadCollectionsSuccess';
    constructor(
        public readonly tempCollection: CollectionDto,
        public readonly userCollections: CollectionDto[],
        public readonly sharedCollections: CollectionDto[],
        public readonly recycledCollections: CollectionDto[]
    ) {}
}

export class CmLoadCollectionsFailure {
    static readonly type = '[CM] LoadCollectionsFailure';

    constructor(public readonly error: HttpErrorResponse) {}
}

export class CmLoadNewSharedCollections {
    static readonly type = '[CM] LoadNewSharedCollections';
    constructor(public readonly countryCodes: string[]) {}
}

export class CmLoadNewSharedCollectionsSuccess {
    static readonly type = '[CM] LoadNewSharedCollectionsSuccess';
    constructor(public readonly newSharedCollectionIds: string[]) {}
}

export class CmLoadNewSharedCollectionsFailure {
    static readonly type = '[CM] LoadNewSharedCollectionsFailure';

    constructor(public readonly error: HttpErrorResponse) {}
}

export class CmMoveOrCopyReports {
    static readonly type = '[CM] MoveReports';
    constructor(
        public readonly reportIds: ReadonlyArray<string>,
        public readonly sourceCollectionId: string,
        public readonly targetCollectionId: string,
        public readonly action: 'copy' | 'move'
    ) {}
}

export class CmStartEditReport {
    static readonly type = '[CM] StartEditReport';
}

export class CmEndEditReport {
    static readonly type = '[CM] EndEditReport';
}

export class CmStartEditCollection {
    static readonly type = '[CM] StartEditCollection';
}

export class CmEndEditCollection {
    static readonly type = '[CM] EndEditCollection';
}

export class CmSelectReport {
    static readonly type = '[CM] SelectReport';
    constructor(public reportId: string, public allowedCountryCodes: string[]) {}
}

export class CmUnselectAllReports {
    static readonly type = '[CM] UnselectAllReports';
}

export class CmSelectAllReports {
    static readonly type = '[CM] SelectAllReports';
    constructor(public allowedCountryCodes: string[]) {}
}

export class CmToggleReport {
    static readonly type = '[CM] ToggleReport';
    constructor(public reportId: string, public allowedCountryCodes: string[]) {}
}

export class CmRangeReport {
    static readonly type = '[CM] RangeReport';
    constructor(public reportId: string, public allowedCountryCodes: string[]) {}
}

export class CmShareCollection {
    static readonly type = '[CM] ShareCollection';

    constructor(public readonly collection: CollectionDto) {}
}

export class CmReorderReports {
    static readonly type = '[CM] ReorderReports';

    constructor(
        public readonly collection: CollectionDto,
        public readonly selectedReports: ReadonlyArray<ReportSnapshotDto>,
        public readonly precedingReport: ReportSnapshotDto
    ) {}
}

export class CmCollectionUpdateFailure {
    static readonly type = '[CM] UpdateFailure';

    constructor(public readonly error: HttpErrorResponse | string) {}
}
