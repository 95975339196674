import { Injectable } from '@angular/core';
import {
    QueryServiceResponse,
    QueryServiceResponseHeader,
    QueryServiceResponseRow,
    ReportingDataEntry
} from './queryservice/query-service-request.interface';

const KEY_VALUE_FIELDS = ['PF'];
/**
 * Service for parsing and normalising Query Responses
 */
@Injectable()
export class QueryResponseParserService {
    public parseResponse(rawResponse: QueryServiceResponse): ReportingDataEntry[] {
        const index = this.createIndexList(rawResponse);
        const result: ReportingDataEntry[] = [];
        rawResponse.data.forEach((row, rowId) =>
            this.splitToCells(row, rowId, index)
                .map(cell => this.splitKeyValueFields(cell))
                .forEach(cell => result.push(cell))
        );
        return result;
    }

    /**
     * Convert the nested header into a flat list of header elements that are valid for the consecutive datapoints.
     *
     * It is very hard to work with the nested header data structure directly, as data is provided in a flat list, which
     * must be mapped to the nested structure (e.g. datapoint 5 is not header index 5, but maybe header index 1.2.
     */
    public createIndexList(rawResponse: QueryServiceResponse) {
        const result: QueryServiceResponseHeader[][] = [];
        rawResponse.header.forEach(header => this.parseHeader(result, header));
        return result;
    }

    private parseHeader(
        result: QueryServiceResponseHeader[][],
        header: QueryServiceResponseHeader,
        parents: QueryServiceResponseHeader[] = []
    ) {
        const extendedParents = parents.concat([header]);
        if (header.nestedCols.length) {
            header.nestedCols.forEach(nested =>
                this.parseHeader(result, nested, extendedParents)
            );
            return;
        }
        result.push(extendedParents);
    }

    private splitToCells(
        row: QueryServiceResponseRow,
        rowId: number,
        index
    ): ReportingDataEntry[] {
        const result: { [key: string]: ReportingDataEntry } = {};
        this.convertRowsToCells(row, rowId, index, result);
        this.setSideHeader(row, index, result);

        return Object.keys(result).map(key => result[key]);
    }

    private convertRowsToCells(row: QueryServiceResponseRow, rowId, index, result: {}) {
        row.forEach((dataPoint, position) => {
            const headerContext = index[position];
            const kpiValue = headerContext.find(header => header.dim === 'KPI');
            if (!kpiValue) {
                return;
            }
            const cellId = headerContext
                .filter(header => header.dim !== 'KPI')
                .map(header => header.dim + '=' + header.key)
                .join(';');
            if (!result[cellId]) {
                result[cellId] = {};
                headerContext
                    .filter(header => header.dim !== 'KPI')
                    .forEach(header => (result[cellId][header.dim] = header.key));
            }
            const cell = result[cellId];
            cell['$rowId'] = rowId;
            cell[kpiValue.key] = dataPoint;
        });
    }

    private setSideHeader(row: QueryServiceResponseRow, index, result: {}) {
        row.forEach((dataPoint, position) => {
            const headerContext = index[position];
            const sideHeader = headerContext.filter(
                header => header.type === 'SH' && header.dim
            );

            if (!sideHeader.length) {
                return;
            }
            Object.keys(result)
                .map(key => result[key])
                .forEach(resultValue => {
                    sideHeader.forEach(header => (resultValue[header.key] = dataPoint));
                });
        });
    }

    private splitKeyValueFields(cell: ReportingDataEntry) {
        KEY_VALUE_FIELDS.forEach(field => {
            if (cell[field] === undefined) {
                return;
            }
            if (String(cell[field]).indexOf('=') > -1) {
                const splitted = String(cell[field]).split('=', 2);
                cell['$' + splitted[0]] = splitted[1];
            } else {
                cell['$' + cell[field]] = cell[field];
            }
        });
        return cell;
    }
}
