import { filter } from 'rxjs/operators';
import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { BrandHighlightService } from 'insightui.table/services/brand-highlighting.service';
import { EventEmitter, Injector } from '@angular/core';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import { Observable } from 'rxjs';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

export class BrandHighLightPlugin extends TableBodyPlugin {
    name = 'BrandHighLightPlugin';

    onBrandHighlightChange$ = new EventEmitter<string>();

    @NotificationSubscription(NotificationNamespace.Filter)
    onDrillTargetChange$: Observable<NotificationEvent>;

    visualizationContext;
    brandHighlightService: BrandHighlightService;

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions
    ) {
        super(injector, configuration);
        const visualizationContextService = this.injector.get(
            VisualizationContextService
        );
        this.brandHighlightService = this.injector.get(BrandHighlightService);
        this.visualizationContext = visualizationContextService.getVisualizationContext();

        this.onDrillTargetChange$
            .pipe(
                filter(event =>
                    event.data.find(events => events.indexOf('ProductModel') >= 0)
                )
            )
            .subscribe(() => {
                this.brandHighlightService.brandName = null;
            });
    }

    select(cell): boolean {
        return (
            this.visualizationContext.drillTarget.drillLevel === 'itemInPg' &&
            cell.row &&
            !cell.row.TG &&
            (super.select(cell) || cell.column.freezeMode !== 'side')
        );
    }

    protected doEnable(cell: Cell) {
        const rootEl = cell.element.nativeElement;
        const rootParent = this.getRow(cell);
        if (!rootParent || this.isActive(rootParent)) {
            return;
        }
        rootParent.highlightSubscription = this.onBrandHighlightChange$.subscribe(() => {
            this.updateHighlightClassBy(rootParent, cell);
        });
        // Only on Brand column with click support
        if (super.select(cell)) {
            rootEl.toogleListener = () => {
                this.toggleHightLight(cell);
            };
            rootEl.addEventListener('click', rootEl.toogleListener);
        }
        // Reset all highlighted brand when data reload
        if (!!this.brandHighlightService.brandName) {
            this.updateHighlightClassBy(rootParent, cell);
        }
        this.markAsActive(rootParent);
    }

    private updateHighlightClassBy(rootParent, cell) {
        if (this.hasActiveSelection(cell)) {
            rootParent.classList.add('highlight');
        } else {
            rootParent.classList.remove('highlight');
        }
    }

    private hasActiveSelection(cell) {
        const brandName = cell.row.BR;
        return this.brandHighlightService.brandName === brandName;
    }

    protected doDisable(cell: Cell) {
        const rootEl = cell.element.nativeElement;
        const rootParent = this.getRow(cell);
        if (!rootParent || !this.isActive(rootParent)) {
            return;
        }
        if (rootParent.highlightSubscription) {
            rootParent.highlightSubscription.unsubscribe();
        }
        if (rootEl.toogleListener) {
            rootEl.removeEventListener('click', rootEl.toogleListener);
        }
        this.markAsInactive(rootParent);
    }

    private toggleHightLight(cell) {
        const brandName = cell.row.BR;
        this.brandHighlightService.toggleBrandName(brandName);
        this.onBrandHighlightChange$.emit(brandName);
    }

    extendExport(exportData) {
        return exportData;
    }
}
