import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlobalModule } from 'insightui.global/global.module';
import { CountryService } from '../services/country.service';
import { CollectionInsightNavigationComponent } from './collectionInsightNavigation.component';
import { CollectionInsightNavigationCloserComponent } from './collectionInsightNavigationCloser.component';
import { CONTEXT_SERIALIZATION_INTERCEPTORS } from 'insightui.core/shared/contextSerialization.service';
import { CurrentPresenterModeService } from './current-presenter-mode.service';

@NgModule({
    imports: [CommonModule, HttpClientModule, RouterModule.forChild([]), GlobalModule],
    declarations: [
        CollectionInsightNavigationComponent,
        CollectionInsightNavigationCloserComponent
    ],
    exports: [CollectionInsightNavigationComponent],
    providers: [
        CurrentPresenterModeService,
        CountryService,
        {
            provide: CONTEXT_SERIALIZATION_INTERCEPTORS,
            useFactory: (
                currentPresenterModeService: CurrentPresenterModeService
            ) => context => ({
                ...context,
                presenterMode: currentPresenterModeService.getAsObject()
            }),
            deps: [CurrentPresenterModeService],
            multi: true
        }
    ]
})
export class CollectionInsightNavigationModule {}
