<div class="c-page-header__logo" (click)="goHome()">
    <img src="assets/images/gfk-48x48.png" alt="Logo" />
</div>
<h3 class="c-page-header__title">{{ productName }}</h3>
<div *ngIf="isLoggedIn$ | async" class="c-page-header__clientId">
    <gfk-client-info></gfk-client-info>
</div>
<div class="c-page-header__actions">
    <div class="c-page-header-actions">
        <div *ngIf="isLoggedIn$ | async" class="c-page-header-actions_item">
            <gfk-announcements-container></gfk-announcements-container>
        </div>
        <div class="c-page-header-actions_item">
            <gfk-collection-manager-shortcut></gfk-collection-manager-shortcut>
        </div>
        <div class="c-page-header-actions_item">
            <gfk-favourite-select-by-report-component
                class="favourite-select-by-report"
            ></gfk-favourite-select-by-report-component>
        </div>
        <div class="c-page-header-actions_separator"></div>
        <div
            class="c-page-header-actions_item u-hover-brand"
            (click)="toggle()"
            *ngIf="isLoggedIn$ | async"
        >
            {{ userName$ | async }} <span class="caret"></span>
            <div
                class="header-settings-menu uitest-Div-Us-Container"
                [class.show]="isHeaderOpen"
            >
                <ul class="dropdown-menu app-ui-dropdown icon-list pull-right">
                    <li
                        class="header-settings-menu-home icon uitest-Li-TM-topnav-home"
                        (click)="goHome()"
                    >
                        <a href="#">Home</a>
                    </li>
                    <li>
                        <gfk-client-switch-button> </gfk-client-switch-button>
                    </li>
                    <li>
                        <gfk-load-status-button> </gfk-load-status-button>
                    </li>
                    <li>
                        <gfk-feed-notification-button> </gfk-feed-notification-button>
                    </li>
                    <li
                        (click)="openUserFeedback()"
                        class="header-settings-menu-feedback icon uitest-Li-TM-topnav-feedback"
                    >
                        <a href="#">Feedback</a>
                    </li>
                    <li
                        class="header-settings-menu-logout icon uitest-Li-TM-topnav-logout"
                        (click)="logout()"
                    >
                        <a href="#">Logout</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
