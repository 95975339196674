import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from 'insightui.global/global.module';
import { ChartCleanupService } from 'insightui.export/powerpoint/services/chart-cleanup.service';
import { PowerpointInfoRowService } from 'insightui.export/powerpoint/services/powerpoint-info-row.service';

const AUTHORING_CHART_SELECTOR = '.js-powerpoint-export-chart';
const FILTER_PLACEMENT_ELEMENT_SELECTOR = '.chart-viewport:last-child';

@Injectable()
export class ChartElementService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private chartCleanupService: ChartCleanupService,
        private powerpointInfoRowService: PowerpointInfoRowService
    ) {}

    /**
     * Detect and get the chart element from the DOM
     *
     * @returns {HTMLElement}
     * @memberof ChartElementService
     */
    getCleanedUpChartElement(): HTMLElement {
        let chartElement = this.getChartElementFromDom();

        chartElement = this.chartCleanupService.cleanupElementForExport(chartElement);
        this.powerpointInfoRowService.appendFilterInfoToElement(
            chartElement,
            FILTER_PLACEMENT_ELEMENT_SELECTOR
        );

        return chartElement;
    }

    getChartElementFromDom(): HTMLElement {
        const chartElement = this.document.querySelector(
            AUTHORING_CHART_SELECTOR
        ) as HTMLElement;
        if (!chartElement) {
            throw new Error('ChartElementService: chart not found in the document');
        }
        return chartElement;
    }
}
