import { distinctUntilChanged, startWith, map, filter } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import * as _ from 'lodash';

const KPI_TITLE_NOT_AVAILABLE = 'N/A';

@Injectable()
export class KpiTitleService {
    @NotificationSubscription(NotificationNamespace.Filter)
    filterNotification$: Observable<NotificationEvent>;

    private kpiSelectionModel;

    constructor(@Inject('$injector') $injector) {
        this.kpiSelectionModel = $injector.get('KpiSelectionModel');
    }

    titleForSelectedKpi(): Observable<string> {
        return this.filterNotification$.pipe(
            filter((event: NotificationEvent) => this.isKpiSelectionModelEvent(event)),
            map(() => this.getCurrentKpiTitle()),
            startWith(this.getCurrentKpiTitle()),
            distinctUntilChanged()
        );
    }

    private getCurrentKpiTitle(): string {
        const kpi = this.kpiSelectionModel.findOne('selected', true);

        return kpi ? kpi.title : KPI_TITLE_NOT_AVAILABLE;
    }

    private isKpiSelectionModelEvent(event: NotificationEvent): boolean {
        return _(event.data)
            .flatten()
            .some((name: any) => name === 'KpiSelectionModel');
    }
}
