import { Injectable } from '@angular/core';
import { NotificationType } from './notification.model';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

@Injectable()
export class NotificationNg1Service {
    constructor(private injector: AngularInjectorResolver) {}

    subscribeService(
        notificationServiceName: string,
        notificationType: NotificationType,
        callback: Function
    ) {
        this.injector.get().subscribe($injector => {
            if ($injector.has(notificationServiceName)) {
                $injector
                    .get(notificationServiceName)
                    .subscribeService(NotificationType[notificationType], callback);
            }
        });
    }
}
