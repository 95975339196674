import { Inject, Injectable } from '@angular/core';
import { PPT_FILE_EXTENSION } from 'insightui.export/powerpoint/constants';
import { PowerpointExport, PowerpointExportSheets } from 'insightui.export/types';
import { PowerpointExportService } from './powerpoint-export.service';
import { SaveAsFn, SAVE_AS } from 'insightui.global/global.module';
import { ExportHeadlineService } from 'insightui.export/services/export-headline.service';

/**
 * Saves a powerpoint file from the current report.
 */
@Injectable({
    providedIn: 'root'
})
export class SavePowerpointService {
    constructor(
        private powerpointExportService: PowerpointExportService,
        private exportHeadlineService: ExportHeadlineService,
        @Inject(SAVE_AS) private saveAs: SaveAsFn
    ) {}

    /**
     * Saves a powerpoint file from the current report.
     *
     * @param  powerPointExportData - the data to be exported
     * @returns a promise which will be resolved when the save action was completed successfully.
     */
    async saveCurrentReport(powerpointExport: PowerpointExport): Promise<void> {
        const powerpointSheets: PowerpointExportSheets = await powerpointExport
            .getPowerpointExportSheets()
            .toPromise();

        const powerpointBlob = await this.powerpointExportService.exportReportWith(
            powerpointSheets
        );

        const filename = this.exportHeadlineService.getReportExportFilename();
        return this.saveAs(powerpointBlob, `${filename}.${PPT_FILE_EXTENSION}`);
    }

    /**
     * Saves a powerpoint file from the current report.
     *
     * @param  powerPointExportData - the data to be exported
     * @param  title - collectionName to be exported
     * @param  subtitle - subtitle to be exported
     * @returns   - a promise which will be resolved when the save action was completed successfully.
     */
    async saveCurrentCollection(
        powerpointExport: PowerpointExport,
        collectionName: string,
        subtitle: string
    ): Promise<void> {
        const powerpointSheets: PowerpointExportSheets = await powerpointExport
            .getPowerpointExportSheets()
            .toPromise();

        const powerpointBlob = await this.powerpointExportService.exportCollectionWith(
            powerpointSheets,
            collectionName,
            subtitle
        );

        const filename = this.exportHeadlineService.getCollectionExportFilename(
            collectionName
        );
        return this.saveAs(powerpointBlob, `${filename}.${PPT_FILE_EXTENSION}`);
    }
}
