import { Injector } from '@angular/core';
import { ChannelComparisonModel } from 'insightui.core/models/reportSetting/channel-comparison.model';
import { ThresholdModel } from 'insightui.core/models/reportSetting/threshold.model';
import { ReportingDataSet } from 'insightui.data/query/queryservice/query-service-request.interface';
import { ReportDataRemoteService } from 'insightui.data/remote/report-data-remote.service';
import { KPIDefinition } from 'insightui.data/shared/reporting-data';
import { LoadingIndicatorService } from 'insightui.loading-indicator/loading-indicator.service';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { RankComparisonFilter } from 'insightui.table/components/plugins/supplementaryKpi/rank-comparison.filter';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { DatatableConfig } from 'insightui.table/services/table.data';
import { from as observableFrom, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, mergeMap, tap, throttleTime } from 'rxjs/operators';

const ID_FIELD = '$id';
const KPI_ID = 'KPI';

export interface SupplementaryKpiOptions extends BaseTablePluginOptions {
    kpi: string;
    // Require an active comparison in order to display this supplementary KPI.
    requireComparison?: boolean;
    requireThreshold?: string;
    filter?;
}
const SUPPORTED_FILTER = {
    rankComparison: RankComparisonFilter
};

export class SupplementaryKpiPlugin extends TableBodyPlugin {
    name = 'SupplementaryKPI';
    kpiState$: { [key: string]: ReplaySubject<ReportingDataSet> } = {};

    private filterChain = [];
    private remoteDataService: ReportDataRemoteService;
    private dataTableConfigurationService: DatatableConfigurationService;
    private: DatatableConfig;
    private comparisonModel: ChannelComparisonModel;
    private thresholdModel: ThresholdModel;
    private loadingIndicatorService: LoadingIndicatorService;

    @VisualizationContextBinding('kpiList')
    kpiList: string[];

    constructor(
        protected injector: Injector,
        protected configuration: SupplementaryKpiOptions,
        protected table: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        this.dataTableConfigurationService = injector.get(DatatableConfigurationService);
        this.remoteDataService = injector.get(ReportDataRemoteService);
        this.loadingIndicatorService = injector.get(LoadingIndicatorService);
        this.comparisonModel = injector.get(ChannelComparisonModel);
        this.thresholdModel = injector.get(ThresholdModel);

        if (configuration.filter) {
            this.filterChain = this.configuration.filter.map(f => {
                return new SUPPORTED_FILTER[f.id](this.injector, f);
            });
        }

        // Currently the state of the comparison model does depend on the rendering of a component
        // e.g. PerformanceIndicatorHeaderComponent (ngOnInit calls readData). Since we do not have any guarantee
        // that the component was displayed and therefore the model was initialized we have to call readData here.
        // By not doing so the upcoming queryservice-request will may be incomplete (missing kpiAttributes).
        // FIXME: It should be possible to send the request even without a call to readData -
        // without this chain: Component > readData > VisContext > QueryService-Request.
        this.comparisonModel.readData();

        // load the kpis on reload to run in parallel with the table-data
        this.table.reloaded$
            .pipe(
                // throttling with time 0 to squash multiple reloaded$ events into just one
                throttleTime(0)
            )
            .subscribe(config => {
                Object.keys(this.kpiState$).forEach(key => {
                    this.kpiState$[key].unsubscribe();
                    this.kpiState$[key] = null;
                });
                (this.kpis || []).forEach(
                    kpi => (this.kpiState$[kpi] = new ReplaySubject(1))
                );
                this.loadKPIs(config);
            });
    }

    protected doEnable(cell: Cell) {
        const rootElement = cell.element.nativeElement;
        if (!rootElement || this.isActive(rootElement)) {
            return;
        }
        rootElement.__id = cell.column.id + cell.row.$id;
        rootElement.classList.add('supplementary-kpi-spacer');
        const expectedField = this.getExpectedFieldID(cell);
        this.markAsActive(rootElement);

        rootElement[expectedField + 'auxSubscription'] = this.kpiState$[
            this.getBaseKPI(cell)
        ]
            .pipe(
                mergeMap(x => observableFrom(x)),
                filter(data => expectedField in data && this.isCurrentRow(data, cell))
            )
            .subscribe(data => {
                this.removeExistingChildren(rootElement);
                rootElement.classList.remove('supplementary-kpi-spacer');
                let el = document.createElement('span');
                if (this.filterChain.length) {
                    this.filterChain.forEach(
                        f => (el = f.transform(data[expectedField], cell))
                    );
                } else {
                    el.innerText = `(${data[expectedField]})`;
                }
                if (el) {
                    el.classList.add('supplementary-' + this.configuration.kpi);
                    if (rootElement.querySelector('.filtered-total')) {
                        const filteredTotalEl = rootElement.querySelector(
                            '.filtered-total'
                        ) as HTMLElement;
                        rootElement.insertBefore(el, filteredTotalEl);
                        const indicatorWidth = el.getBoundingClientRect().width;
                        filteredTotalEl.style.marginRight = indicatorWidth + 'px';
                    } else {
                        rootElement.appendChild(el);
                    }
                }
            });
    }

    public doDisable(cell: Cell) {
        const rootElement = cell.element.nativeElement;
        if (!rootElement || !this.isActive(rootElement)) {
            return;
        }
        const expectedField = cell.column.field.replace(
            /KPI[^_]+/,
            this.configuration.kpi
        );
        const subscription: Subscription = rootElement[expectedField + 'auxSubscription'];
        if (subscription) {
            subscription.unsubscribe();
        }
        this.markAsInactive(rootElement);
    }

    private isCurrentRow(data, cell: Cell) {
        const dataId = this.trailRowNumber(data[ID_FIELD]);
        const cellId = this.trailRowNumber(cell.row.$id);
        return dataId === cellId || dataId === cell.row.TG;
    }

    /**
     * Remove the appended number of the result row that was introduced in the id field
     * due to items not having an id. This is just a workaround, ideally our datacells are just comparable by default.
     *
     * @param id        The id to remove the trailing row number from
     * @return string   A comparable id.
     */
    private trailRowNumber(id): string {
        const parts = (id || '').split('_');
        parts.pop();
        if (parts.length === 0) {
            return id;
        }
        return parts.join('_');
    }

    private removeExistingChildren(rootElement: Element) {
        if (rootElement.children.length > 0) {
            const existingNodes = rootElement.querySelectorAll(
                '.supplementary-' + this.configuration.kpi
            );
            for (let i = 0; i < existingNodes.length; i++) {
                const node = existingNodes.item(i);
                node.parentElement.removeChild(node);
            }
        }
    }

    private getExpectedFieldID(cell: Cell) {
        return cell.column.field.replace(/KPI[^_]+/, this.configuration.kpi);
    }

    private getBaseKPI(cell: Cell) {
        return cell.column.field.split('_').find(id => id.startsWith(KPI_ID));
    }

    public disable(cell: Cell) {
        this.doDisable(cell);
    }

    private loadKPIs(config: DatatableConfig) {
        this.loadingIndicatorService.toggleOn();
        const kpi: KPIDefinition = {
            id: this.configuration.kpi,
            dimensionContext: this.dataTableConfigurationService.getDimensionContext(
                config
            )
        };

        const requestableKPIs = this.kpis.filter(
            baseKpi => this.kpiList.indexOf(baseKpi) >= 0
        );

        // This is rather ugly: We have to request every relative kpi once and then merge the result
        // but remember, which base applies for every KPI. TODO: The query service must be able to return
        // the same kpi multiple times with different configurations, this would boil down the whole buffer/collect
        // phase.
        observableFrom(requestableKPIs)
            .pipe(
                // request kpis
                mergeMap(baseKpi => this.requestKPI(kpi, baseKpi, config))
            )
            // flatten and notify
            .subscribe({
                next: kpiResponse => {
                    const kpiBase = kpiResponse['kpiBase'];
                    if (this.kpiState$[kpiBase]) {
                        this.kpiState$[kpiBase].next(kpiResponse);
                    } else {
                        console.warn(
                            `Got KPI for supplementary KPI ${kpiBase} which is not in the configured kpis. This KPI will be ignored`
                        );
                    }
                },
                error: () => this.loadingIndicatorService.toggleOff(),
                complete: () => this.loadingIndicatorService.toggleOff()
            });
    }

    private requestKPI(kpi: KPIDefinition, baseKpi, config: DatatableConfig) {
        const request = this.remoteDataService.buildRequest([kpi]);
        request.kpiAttributes = request.kpiAttributes || { [kpi.id]: {} };
        request.kpiAttributes[kpi.id] = request.kpiAttributes[kpi.id] || {};
        request.kpiAttributes[kpi.id]['kpiBase'] = baseKpi;
        const sideHeaders = this.dataTableConfigurationService.getSideHeaders();
        return this.remoteDataService.executeRequest(request).pipe(
            map(result => result.data),
            map(
                this.remoteDataService.responseMapper.crossTab(
                    sideHeaders,
                    config.dimensionContext
                )
            ),
            // remember the baseKpi
            tap(result => (result['kpiBase'] = baseKpi))
        );
    }

    extendExport(exportData) {
        const header = this.flipHeader(exportData.sideHeader).concat(
            this.flipHeader(exportData.topHeader)
        );
        if (!this.checkPrerequisites()) {
            return exportData;
        }
        exportData.value.forEach(row => {
            row.data.forEach((cell, idx) => {
                const currentHeader = header[idx];

                if (
                    !this.selectExportRow(cell, currentHeader) ||
                    currentHeader.key.indexOf(KPI_ID) < 0
                ) {
                    return;
                }
                const baseKpi = currentHeader.key
                    .split('_')
                    .find(id => id.startsWith(KPI_ID));
                const expectedField = currentHeader.key.replace(
                    /KPI[^_]+/,
                    this.configuration.kpi
                );
                this.kpiState$[baseKpi]
                    .pipe(
                        mergeMap(data => observableFrom(data)),
                        filter(data => expectedField in data),
                        filter(
                            data =>
                                this.trailRowNumber(data[ID_FIELD]) ===
                                this.trailRowNumber(row.data.$id)
                        )
                    )
                    .subscribe(data => {
                        if (this.filterChain.length) {
                            this.filterChain.forEach(f =>
                                f.transformExport(data[expectedField], cell)
                            );
                        } else {
                            cell[this.configuration.kpi] = data[expectedField];
                        }
                    });
            });
        });
        return exportData;
    }

    select(cell: Cell): boolean {
        if (!super.select(cell)) {
            return false;
        }
        return this.checkPrerequisites(cell);
    }

    private checkPrerequisites(cell?: Cell) {
        if (this.configuration.requireComparison) {
            if (!this.comparisonModel.data) {
                return false;
            }
            const comparison = this.comparisonModel.data.find(
                availableComparison => availableComparison.selected
            );
            let columnMatchesComparison = true;
            if (cell) {
                columnMatchesComparison = Boolean(
                    cell.column.field
                        .split('_')
                        .find(fieldPart => comparison.base === fieldPart)
                );
            }
            if (!comparison || !comparison.selected || !columnMatchesComparison) {
                return false;
            }
        }

        if (this.configuration.requireThreshold) {
            const threshold = this.thresholdModel
                .clone()
                .findOne(value => value.id === this.configuration.requireThreshold);
            return threshold && threshold.active;
        }

        return true;
    }
}
