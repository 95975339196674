<div class="[ c-modal ]">
    <div class="[ c-modal__content ]">
        <div class="[ c-modal-header ]">
            <h3 class="[ c-modal-header__title ]">
                Notifications
            </h3>
            <div
                class="[ c-modal-header__close ]  [ js-modal-close ]"
                (click)="closeModal()"
            >
                <i class="[ c-icon	c-icon--close ]"></i>
            </div>
        </div>
        <div class="[ c-modal-body ]">
            <div class="[ c-modal-body__scrollarea ]">
                <div>
                    <div
                        class="[ o-layout__item  o-layout__item--auto ]  [ u-text-bold ]"
                    >
                        {{releaseNote.headline}}
                    </div>
                    <div
                        class="[ o-layout__item  o-layout__item--auto ]"
                        [innerHtml]="releaseNote.content"
                    ></div>
                </div>
            </div>
        </div>
        <div class="[ c-modal-footer ]">
            <div class="[ o-layout  o-layout--horizontal ]">
                <div class="[ o-layout__item   o-layout__item--pull-left ]">
                    <div class="[ c-pagination ]" *ngIf="hasPagination()">
                        <div class="[ c-pagination__item ]" (click)="goToPreviousPage()">
                            <i class="[ c-icon  c-icon--chevron ]"></i>
                        </div>

                        <div
                            *ngFor="let pageNumber of numberOfPages | gfkNumberRange"
                            class="[ c-pagination__item ]"
                            [ngClass]="{'c-pagination__item--active': currentPageNumber === pageNumber}"
                            (click)="gotoPage(pageNumber)"
                        >
                            {{pageNumber + 1}}
                        </div>

                        <div class="[ c-pagination__item ]" (click)="goToNextPage()">
                            <i
                                class="[ c-icon  c-icon--chevron  c-icon--rotate-180 ]"
                            ></i>
                        </div>
                    </div>
                </div>
                <div
                    class="[ o-layout__item  o-layout__item--pull-right ]  [ js-modal-close ]"
                >
                    <button type="button" class="[ o-button ]" (click)="closeModal()">
                        Okay
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="[ o-overlay ]"></div>
</div>
