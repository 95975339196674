import { Injectable } from '@angular/core';
import {
    NumberOfChartRowsPerPage,
    PowerpointExport,
    PowerpointExportSheets
} from 'insightui.export/types';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { VerticalChartStatisticService } from 'insightui.export/powerpoint/charts/vertical-report/vertical-chart-statistic.service';
import { ChartElementService } from 'insightui.export/powerpoint/services/chart-element.service';
import { EXPORT_MATRIX } from 'insightui.export/powerpoint/charts/vertical-report/export-matrix';
import { Observable, of as observableOf } from 'rxjs';

const HEIGHT_OF_HEADER = 86;
@Injectable()
export class VerticalReport implements PowerpointExport {
    constructor(
        private chartToSheetService: ChartToSheetService,
        private chartElementService: ChartElementService,
        private verticalChartStatisticService: VerticalChartStatisticService
    ) {}

    getPowerpointExportSheets(): Observable<PowerpointExportSheets> {
        const chartElement: HTMLElement = this.chartElementService.getCleanedUpChartElement();
        const chartRowHeight: number = this.verticalChartStatisticService.getHeightOfChartRow();
        const numberOfTotalRows = this.verticalChartStatisticService.getNumberOfTotalRows();
        const numberOfChartRowsPerPage: NumberOfChartRowsPerPage = this.getNumberOfChartRowsPerPage();

        const processResult = numberOfChartRowsPerPage.reduce(
            ({ offset, sheets }, numberOfChartRowsOnPage) => {
                const sheetElement: HTMLElement = chartElement.cloneNode(
                    true
                ) as HTMLElement;
                const rowElements = Array.from(
                    sheetElement.querySelectorAll('.chart-ctrl-chart-row')
                ).slice(numberOfTotalRows);
                const calculatedNumberOfChartRowsOnPage =
                    numberOfChartRowsOnPage + numberOfTotalRows;

                for (let i = 0; i < offset; i++) {
                    rowElements[i].parentElement.removeChild(rowElements[i]);
                }

                for (
                    let i = offset + numberOfChartRowsOnPage;
                    i < rowElements.length;
                    i++
                ) {
                    rowElements[i].parentElement.removeChild(rowElements[i]);
                }

                const sheet = this.chartToSheetService.getFixedHeightSheet(
                    sheetElement,
                    calculatedNumberOfChartRowsOnPage * chartRowHeight + HEIGHT_OF_HEADER
                );
                const newSheets = sheets.concat(sheet);

                return {
                    offset: offset + numberOfChartRowsOnPage,
                    sheets: newSheets
                };
            },
            { offset: 0, sheets: [] }
        );

        return observableOf(processResult.sheets);
    }

    private getNumberOfChartRowsPerPage(): NumberOfChartRowsPerPage {
        const numberOfLegendItems = this.verticalChartStatisticService.getNumberOfLegendItems();
        const numberOfChartRows = this.verticalChartStatisticService.getNumberOfRows();

        return EXPORT_MATRIX[numberOfChartRows - 1][numberOfLegendItems - 1];
    }
}
