import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { STATE_EPIC } from 'projects/insightui/src/app/state/state.module';
import { CommonModule } from '@angular/common';
import { InteractiveHeaderDirective } from './interactive-header.directive';
import { ReportWithFilterViewComponent } from './report-with-filter-view.component';
import { Ng1NotificationServiceAdapterEpic } from './ng1-notification-service-adapter.epic';
import { FairSharePageComponent } from './report-pages/fair-share-page.component';
import { FairShareModule } from 'insightui.fair-share/fair-share.module';
import { CollectionInsightNavigationModule } from 'insightui.collectionmanager/collectionInsightNavigation/collectionInsightNavigation.module';

@NgModule({
    declarations: [
        ReportWithFilterViewComponent,
        FairSharePageComponent,
        InteractiveHeaderDirective
    ],
    imports: [
        CommonModule,
        RouterModule,
        CollectionInsightNavigationModule,
        FairShareModule
    ],
    providers: [
        {
            provide: STATE_EPIC,
            useClass: Ng1NotificationServiceAdapterEpic,
            multi: true
        }
    ]
})
export class ReportFilterModule {}
