import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Injector } from '@angular/core';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { TooltipUtilService } from 'insightui.table/components/datatable/shared/services/tooltip-util.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

const TOTAL_MATCHER = /-1/i; // e.g. FV-1
const SUPPORTED_PRODUCT_DIMENSIONS = ['BR', 'CY', 'PG', 'SG'];

export class FilteredTotalPlugin extends TableBodyPlugin {
    name = 'FilteredTotalPlugin';
    tooltipUtilService: TooltipUtilService;
    metadataService: MetadataService;
    visualizationContextService: VisualizationContextService;
    timeout;
    matcher: RegExp;
    POPUP_DELAY = 250;

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions,
        protected table: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        this.metadataService = this.injector.get(MetadataService);
        this.tooltipUtilService = this.injector.get(TooltipUtilService);
        this.visualizationContextService = this.injector.get(VisualizationContextService);

        // this will turn 'KPIC5, KPIC2' into '_KPIC5$|_KPIC2$'
        const pattern = this.configuration.kpis
            ? this.configuration.kpis.map(k => `_${k}$`).join('|')
            : '*';
        this.matcher = new RegExp(pattern);
    }

    select(cell): boolean {
        // super.select(cell) will be called in isFilteredKPI
        return (
            cell.row &&
            cell.row.TG === 'Total' &&
            typeof this.table.reportingFilteredTotal === 'object'
        );
    }

    protected doEnable(cell: Cell) {
        // Brand, Category, ProductGroup and Sector columns need special treatment,
        // e.g no tool tips and Label is set to "(Filtered)"
        if (this.isProductCell(cell)) {
            // show label only if there is at least one supported KPI displayed
            if (Object.keys(cell.row).some(r => this.matcher.test(r))) {
                this.addFilteredLabel(cell, 'Filtered');
            }
        } else if (this.isFilteredKPI(cell)) {
            const filteredTotalVal = this.table.reportingFilteredTotal[cell.column.field];
            const totalChild = this.addFilteredLabel(cell, filteredTotalVal);
            this.enableTooltip(totalChild, cell);
        } else {
            // nothing to do
        }
    }

    private isFilteredKPI(cell: Cell) {
        return super.select(cell);
    }

    private isProductCell(cell: Cell) {
        return SUPPORTED_PRODUCT_DIMENSIONS.find(
            dimension => cell.column.field === dimension
        );
    }

    private addFilteredLabel(cell: Cell, label: string | number): HTMLSpanElement {
        const rootNode: HTMLElement = cell.element.nativeElement;
        const totalChild = document.createElement('span');
        totalChild.classList.add('filtered-total');
        totalChild.textContent = '(' + label + ')';

        if (rootNode.querySelector('.performance-indicator')) {
            const performanceIndicatorEl = rootNode.querySelector(
                '.performance-indicator'
            ) as HTMLElement;
            const indicatorWidth = performanceIndicatorEl.getBoundingClientRect().width;
            totalChild.style.marginRight = indicatorWidth + 'px';
        }

        rootNode.appendChild(totalChild);

        return totalChild;
    }

    private enableTooltip(totalChild: HTMLSpanElement, cell: Cell) {
        const domNode = cell.element.nativeElement;
        domNode.mouseOverListener = event => {
            this.timeout = setTimeout(() => {
                this.showToolTip(event, cell);
            }, this.POPUP_DELAY);
        };

        domNode.mouseOutListener = () => {
            clearTimeout(this.timeout);
            this.removeToolTip();
        };

        totalChild.addEventListener('mouseout', domNode.mouseOutListener);
        totalChild.addEventListener('mouseover', domNode.mouseOverListener);
    }

    protected doDisable(cell: Cell) {
        const rootNode = cell.element.nativeElement;
        const filteredTotalNode = rootNode.querySelector(
            '.filtered-total'
        ) as HTMLSpanElement;

        if (filteredTotalNode) {
            filteredTotalNode.removeEventListener(
                'mouseover',
                rootNode.mouseOverListener
            );
            filteredTotalNode.removeEventListener('mouseout', rootNode.mouseOutListener);
            rootNode.removeChild(filteredTotalNode);
        }
    }

    showToolTip(event, cell) {
        const toolTip = document.body.querySelector('.tooltip');
        if (!toolTip) {
            const features = this.getSelectedFeatures();
            const channelName = this.getChannelName(cell.column.field);
            const filteredTotalVal = this.table.reportingFilteredTotal[cell.column.field];
            const contentHtml = this.tooltipUtilService.getTotalLineTooltip(
                cell.row.BR,
                features,
                channelName,
                filteredTotalVal
            );
            document.body.appendChild(contentHtml);

            // reposition item
            const position = this.tooltipUtilService.getTooltipAnchor(
                event,
                contentHtml.clientWidth,
                contentHtml.clientHeight,
                5
            );
            contentHtml.style.top = position.top + 'px';
            contentHtml.style.left = position.left + 'px';
        }
    }

    removeToolTip() {
        const toolTip = document.body.querySelector('.tooltip');
        if (toolTip) {
            document.body.removeChild(toolTip);
        }
    }

    getChannelName(field) {
        const channelId = field.split('_')[0];
        return this.metadataService.getChannelById(channelId).title;
    }

    getSelectedFeatures() {
        const resultArr = [];
        const featureFilterList =
            this.visualizationContextService
                .get('featureFilterList')
                .filter(feature => !TOTAL_MATCHER.test(feature.value)) || []; // FV-1
        featureFilterList.forEach(selection => {
            const feature = this.metadataService.getFeatureById(selection.type);
            resultArr.push(feature.title + ': ' + feature.values[selection.value].title);
        });
        return resultArr.join(' + ');
    }

    extendExport(exportData) {
        return exportData;
    }
}
