import { Injectable } from '@angular/core';
import { NavigationService } from 'insightui.core/services/navigation.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

export interface PresenterMode {
    isActive: boolean;
    docId: string;
    reportId: string;
}

@Injectable()
export class CurrentPresenterModeService {
    constructor(
        private visualizationContext: VisualizationContextService,
        private navigationService: NavigationService
    ) {}

    isActive(): boolean {
        return this.getFromVisualizationContext(
            'isActive',
            () => !!this.navigationService.getQueryParam('collectionId')
        );
    }

    getDocId(): string {
        return this.getFromVisualizationContext('docId', () =>
            this.navigationService.getQueryParam('docId')
        );
    }

    getReportId(): string {
        return this.getFromVisualizationContext('reportId', () =>
            this.navigationService.getParam('reportSnapshotId')
        );
    }

    getAsObject(): PresenterMode {
        return {
            isActive: this.isActive(),
            reportId: this.getReportId(),
            docId: this.getDocId()
        };
    }

    private getFromVisualizationContext<T>(path: string, orDefault: () => T): T {
        const fromContext = this.visualizationContext.get('presenterMode.' + path, null);
        if (fromContext === null) {
            return orDefault();
        }

        return fromContext;
    }
}
