import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';
import NotificationStateChangesService from 'insightui.table/services/notification-state-changes.service';
import { ColumnHeader } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { TablePluginRegistry } from 'insightui.table/services/table-plugin-registry.service';
import { DragulaService } from 'ng2-dragula';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';

@Component({
    selector: 'datatable-header-cell',
    templateUrl: 'header-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class DatatableHeaderCellComponent implements AfterViewInit, OnDestroy {
    @Input()
    columnHeader: ColumnHeader;

    @Input()
    headerRowHeight: number;

    @Input()
    state: number;

    @Output()
    cellResizing = new EventEmitter();

    @Output()
    resizeStart = new EventEmitter();

    @Output()
    resizeEnd = new EventEmitter();

    @ViewChild('extensionContainer', { read: ViewContainerRef })
    extensionRoot: ViewContainerRef;

    @ViewChild('cellContainer')
    element: ElementRef;

    destroyed = false;
    private logger: ILogger;
    constructor(
        private plugins: TablePluginRegistry,
        private dragulaService: DragulaService,
        private changeDetector: ChangeDetectorRef,
        logService: LogService
    ) {
        this.logger = logService.getLogger('dtHeaderCellComponent');
    }

    trackByColumnHeaderFn(idx: number, columnHeader: ColumnHeader) {
        return columnHeader.uuid;
    }
    ngAfterViewInit(): void {
        this.plugins.getRegistered().forEach(plugin => plugin.enable(this));
        this.plugins.pluginRegistered$.subscribe(plugin => plugin.enable(this));
        this.plugins.pluginDeregistered$.subscribe(plugin => plugin.disable(this));
    }

    ngOnDestroy(): void {
        this.destroyed = true;
        this.plugins.getRegistered().forEach(plugin => plugin.disable(this));
    }

    get name(): string {
        return this.columnHeader.name;
    }

    get kpi(): string {
        return this.columnHeader.kpi;
    }

    get headerKey(): string {
        return this.columnHeader.level >= 0 && !!this.columnHeader.dimensionType
            ? `level${this.columnHeader.level}-${this.columnHeader.dimensionType}`
            : this.columnHeader.name;
    }

    onCellResizing(event, header) {
        event.header = header;
        event.type = 'resizing';
        NotificationStateChangesService.notify(NotificationType.ResizeColumns, event);

        // disable drake while resizing
        const drakeChildren = this.dragulaService.find('header-children-drag-container')
            .drake;
        drakeChildren.cancel();
    }

    onCellResizeStart(event, header) {
        event.header = header;
        event.type = 'resizeStart';
        NotificationStateChangesService.notify(NotificationType.ResizeColumns, event);
    }

    onCellResizeEnd(event, header) {
        event.header = header;
        event.type = 'resizeEnd';
        NotificationStateChangesService.notify(NotificationType.ResizeColumns, event);
    }

    update() {
        if (!this.destroyed) {
            this.changeDetector.markForCheck();
            this.changeDetector.detectChanges();
        }
    }
}
