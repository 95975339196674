import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { WINDOW } from 'insightui.global/global.module';
import {
    CollectionControllerService,
    CollectionDto,
    ReportSnapshotDto
} from 'insightui.openapi/userprofile';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import { map, publishReplay, refCount, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CollectionReportService } from '../services/collection-report.service';
import { CountryService } from '../services/country.service';
import { KEY_SKIPPED_REPORT_NOTIFICATION } from './constants';
import { CurrentPresenterModeService } from './current-presenter-mode.service';

interface ReportIdAndNumberOfSkippedReports {
    reportId: string | null;
    numberOfSkippedReports: number;
}

@Component({
    selector: 'gfk-collection-insight-navigation',
    templateUrl: './collectionInsightNavigation.component.html'
})
export class CollectionInsightNavigationComponent implements OnInit, OnDestroy {
    currentReport$: Observable<ReportSnapshotDto> = EMPTY;

    currentCollection$: Observable<CollectionDto> = EMPTY;

    hasNext$: Observable<boolean> = EMPTY;
    hasPrevious$: Observable<boolean> = EMPTY;

    private componentDestroyed$: Subject<void> = new Subject<void>();
    private previousReportResult$: Observable<ReportIdAndNumberOfSkippedReports> = EMPTY;
    private nextReportResult$: Observable<ReportIdAndNumberOfSkippedReports> = EMPTY;
    private userCountryIds: string[] = [];

    constructor(
        @Inject(WINDOW) private window: Window,
        private collectionControllerService: CollectionControllerService,
        private collectionReportService: CollectionReportService,
        private countryService: CountryService,
        private toastr: ToastrService,
        private currentPresenterModeService: CurrentPresenterModeService
    ) {}

    ngOnInit(): void {
        if (this.shouldShowPresenterMode()) {
            this.handleSkippedReportsNotification();

            const reportId = this.currentPresenterModeService.getReportId();

            this.userCountryIds = this.countryService.getCurrentCountryCodesOfUser();
            this.currentReport$ = this.collectionControllerService
                .getReportById(reportId)
                .pipe(
                    takeUntil(this.componentDestroyed$),
                    publishReplay(1),
                    refCount()
                );

            this.currentCollection$ = this.currentReport$.pipe(
                switchMap(report =>
                    this.collectionControllerService.getCollectionById(
                        report.parentCollectionId
                    )
                ),
                takeUntil(this.componentDestroyed$),
                publishReplay(1),
                refCount()
            );

            this.previousReportResult$ = combineLatest([
                this.currentReport$,
                this.currentCollection$
            ]).pipe(
                map(([currentReport, collection]) => {
                    let idx =
                        collection.reportSnapshots.findIndex(
                            r => r.id === currentReport.id
                        ) - 1;

                    let numberOfSkippedReports = 0;
                    let previousReportId = null;
                    while (idx > -1) {
                        const prevReport = collection.reportSnapshots[idx];
                        if (this.userCountryIds.includes(prevReport.countryCode)) {
                            previousReportId = prevReport.id;
                            break;
                        }
                        idx--;
                        numberOfSkippedReports++;
                    }

                    return { reportId: previousReportId, numberOfSkippedReports };
                })
            );

            this.nextReportResult$ = combineLatest([
                this.currentReport$,
                this.currentCollection$
            ]).pipe(
                map(([currentReport, collection]) => {
                    let idx =
                        collection.reportSnapshots.findIndex(
                            r => r.id === currentReport.id
                        ) + 1;

                    let numberOfSkippedReports = 0;
                    let nextReportId = null;
                    while (idx < collection.reportSnapshots.length) {
                        const nextReport = collection.reportSnapshots[idx];
                        if (this.userCountryIds.includes(nextReport.countryCode)) {
                            nextReportId = nextReport.id;
                            break;
                        }
                        idx++;
                        numberOfSkippedReports++;
                    }
                    return { reportId: nextReportId, numberOfSkippedReports };
                })
            );
        }

        this.hasPrevious$ = this.previousReportResult$.pipe(
            map(result => result.reportId !== null)
        );
        this.hasNext$ = this.nextReportResult$.pipe(
            map(result => result.reportId !== null)
        );
    }

    ngOnDestroy(): void {
        this.componentDestroyed$.next();
    }

    shouldShowPresenterMode(): boolean {
        return this.currentPresenterModeService.isActive();
    }

    @HostListener('document:keydown.arrowleft')
    goToPreviousReport(): void {
        this.goToReport(this.previousReportResult$);
    }

    @HostListener('document:keydown.arrowright')
    goToNextReport(): void {
        this.goToReport(this.nextReportResult$);
    }

    private resetSkippedReportsNotification() {
        this.window.sessionStorage.removeItem(KEY_SKIPPED_REPORT_NOTIFICATION);
    }

    private goToReport(reportId$: Observable<ReportIdAndNumberOfSkippedReports>): void {
        this.resetSkippedReportsNotification();
        reportId$
            .pipe(
                tap(result =>
                    this.updateSkippedReportCounter(result.numberOfSkippedReports)
                ),
                switchMap(result =>
                    this.collectionControllerService.getReportById(result.reportId)
                ),
                switchMap(report => this.collectionReportService.getReportLink(report))
            )
            .subscribe(reportLink => {
                this.window.location.href = reportLink;
            });
    }

    private updateSkippedReportCounter(skippedReports: number) {
        if (skippedReports > 0) {
            this.window.sessionStorage.setItem(
                KEY_SKIPPED_REPORT_NOTIFICATION,
                skippedReports.toString()
            );
        } else {
            this.resetSkippedReportsNotification();
        }
    }
    private handleSkippedReportsNotification() {
        const skippedReportsString = this.window.sessionStorage.getItem(
            KEY_SKIPPED_REPORT_NOTIFICATION
        );
        if (skippedReportsString) {
            this.toastr.info(
                `${skippedReportsString} reports in this collection 
                have been skipped as they contain data from countries which you do not
                have permissions to view.`,
                'Report(s) skipped'
            );
        }
        this.resetSkippedReportsNotification();
    }
}
