import { Injectable } from '@angular/core';
import { ExportCollectionState } from 'insightui.export-collection/export-collection.types';

@Injectable({
    providedIn: 'root'
})
export class ExportCollectionSelector {
    numberOfStartedExports(state: ExportCollectionState): number {
        return Math.min(
            state.totalReports - state.pendingReports + 1,
            state.totalReports
        );
    }

    isModalVisible(state: ExportCollectionState): boolean {
        return state.presentation !== 'hidden';
    }
}
