import { EventEmitter, Injector } from '@angular/core';
import {
    SortDirection,
    SortSetting,
    SortingModel
} from 'insightui.core/models/sorting.model';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { KpiProperties } from 'insightui.data/types';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { NotificationEmitter } from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import {
    AdditionalColumnConfiguration,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';

const SORT_UP_CLASS = 'fa-sort-up';
const SORT_DOWN_CLASS = 'fa-sort-down';
const HEADER_LABEL_CLASS = '.datatable-header-label-text';

/**
 * Sortable header for tables, allows tristate (default, asc, desc).
 */
export class SortableHeaderPlugin extends TableHeaderPlugin {
    name = 'sortableHeader';
    kpiInfo: { [key: string]: KpiProperties };
    sortModel: SortingModel;

    @NotificationEmitter(NotificationType.SortingChanged)
    private onSortingChanged$: EventEmitter<boolean>;

    constructor(
        protected injector: Injector,
        protected configuration: AdditionalColumnConfiguration,
        protected table?: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
    }

    select(cell: DatatableHeaderCellComponent): boolean {
        if (!this.kpiInfo) {
            this.kpiInfo = this.injector.get(PageDefinitionService).getKpiProperties();
            this.sortModel = this.injector.get(SortingModel);
            this.sortModel.readData();
        }
        if (!super.select(cell)) {
            return false;
        }
        if (cell.columnHeader.children && cell.columnHeader.children.length) {
            return false;
        }
        const kpi = cell.columnHeader.kpi;
        if (kpi) {
            return this.kpiInfo[kpi] && this.kpiInfo[kpi].sortable;
        } else if (this.configuration.additionalColumns) {
            return this.configuration.additionalColumns.some(expression =>
                new RegExp(expression).test(cell.columnHeader.field)
            );
        }
        return false;
    }

    extendExport(exportData) {
        return exportData;
    }

    protected doEnable(cell: DatatableHeaderCellComponent) {
        const rootEl = cell.element.nativeElement;
        const label = rootEl.querySelector(HEADER_LABEL_CLASS);
        if (!rootEl || !label || this.isActive(rootEl)) {
            return;
        }
        this.updateSortIndicator(cell);
        rootEl.sortSubscription = this.sortModel.filterNotification$.subscribe(() =>
            this.updateSortIndicator(cell)
        );
        rootEl.sortableListener = () => this.updateSort(cell);
        label.classList.add('clickable');
        label.addEventListener('click', rootEl.sortableListener);
        this.markAsActive(rootEl);
    }

    protected doDisable(cell: DatatableHeaderCellComponent) {
        const rootEl = cell.element.nativeElement;
        const label = rootEl.querySelector(HEADER_LABEL_CLASS);

        if (!rootEl || !label || !this.isActive(rootEl)) {
            return;
        }
        if (rootEl.sortSubscription) {
            rootEl.sortSubscription.unsubscribe();
        }
        label.classList.remove('clickable');
        label.removeEventListener('click', rootEl.sortableListener);
        this.markAsInactive(rootEl);
    }

    private updateSort(cell: DatatableHeaderCellComponent) {
        // use store model instead of cloned model because no revert required
        const updated = this.sortModel;
        if (this.isActivelySortedCell(cell) && this.isReset(cell)) {
            updated.reset();
        } else {
            updated.sortBy(
                cell.columnHeader.field.split('_'),
                this.getNextSortDirection(cell)
            );
        }
        this.onSortingChanged$.emit(true);
        updated.saveAndNotifyWithoutReload();
    }

    private getNextSortDirection(cell: DatatableHeaderCellComponent) {
        let direction = this.sortModel.clone().getSorting().direction;
        if (this.isActivelySortedCell(cell)) {
            if (direction === SortDirection.ASC) {
                direction = SortDirection.DESC;
            } else {
                direction = SortDirection.ASC;
            }
        }
        return direction;
    }

    private updateSortIndicator(cell: DatatableHeaderCellComponent) {
        const sortingCols = this.sortModel.getSortingAll();
        if (this.isActivelySortedCell(cell) && sortingCols.length === 1) {
            this.createSortIndicator(cell);
        } else {
            this.removeMarks(cell);
        }
    }

    private isActivelySortedCell(cell: DatatableHeaderCellComponent) {
        const sorting: SortSetting = this.sortModel.getSorting();
        if (sorting.fields.length === null) {
            return;
        }
        const cellFields = cell.columnHeader.field.split('_');
        return sorting.fields.every(
            sortingField => cellFields.indexOf(sortingField.replace('-', '')) >= 0
        );
    }

    private removeMarks(cell: DatatableHeaderCellComponent) {
        const existingWrapper: HTMLElement = cell.element.nativeElement.querySelector(
            '.plugin-sortable-wrapper'
        );
        if (existingWrapper) {
            existingWrapper.parentElement.removeChild(existingWrapper);
            cell.element.nativeElement.parentElement.classList.remove('sortable-active');
            // reset label width
            const headerLabelTextElement = cell.element.nativeElement.querySelector(
                '.datatable-header-label-text'
            );
            headerLabelTextElement.style.width = null;
        }
    }

    private isReset(cell: DatatableHeaderCellComponent) {
        const wrapper: HTMLElement = cell.element.nativeElement.querySelector(
            HEADER_LABEL_CLASS
        );

        const mark = wrapper.querySelector('.plugin-sortable-wrapper');
        if (mark) {
            return (parseInt(mark.getAttribute('sort-state'), 10) + 1) % 3 === 0;
        } else {
            return false;
        }
    }

    private createSortIndicator(cell: DatatableHeaderCellComponent) {
        const sorting = this.sortModel.getSorting();
        const wrapper: HTMLElement = cell.element.nativeElement.querySelector(
            HEADER_LABEL_CLASS
        );
        const mark = this.getOrCreateMark(wrapper);
        cell.element.nativeElement.parentElement.classList.add('sortable-active');
        if (sorting.direction === SortDirection.ASC) {
            mark.classList.add(SORT_UP_CLASS);
        } else {
            mark.classList.add(SORT_DOWN_CLASS);
        }

        wrapper.appendChild(mark);
        return mark;
    }

    private getOrCreateMark(wrapper: Element) {
        let mark = wrapper.querySelector('.plugin-sortable-wrapper');
        let sortState = 1;
        if (!mark) {
            mark = document.createElement('span');
            mark.setAttribute('sort-state', String(sortState));
            mark.classList.add('fa');
            mark.classList.add('plugin-sortable-wrapper');
        } else {
            sortState = (parseInt(mark.getAttribute('sort-state'), 10) + 1) % 3;
            mark.setAttribute('sort-state', String(sortState));
            mark.classList.remove(SORT_UP_CLASS);
            mark.classList.remove(SORT_DOWN_CLASS);
        }
        return mark;
    }
}
