import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import * as _ from 'lodash';

export type ContextSerializationInterceptor = (Object) => Object;

export const CONTEXT_SERIALIZATION_INTERCEPTORS = new InjectionToken<
    ContextSerializationInterceptor[]
>('CONTEXT_SERIALIZATION_INTERCEPTORS');

/**
 * Service transforms the visualization context into an URL representation
 *
 */
@Injectable()
export class ContextSerializationService {
    private interceptors: ContextSerializationInterceptor[];

    constructor(
        @Optional()
        @Inject(CONTEXT_SERIALIZATION_INTERCEPTORS)
        interceptors: ContextSerializationInterceptor[]
    ) {
        this.interceptors = interceptors || [];
    }

    serialize(context) {
        const ctxToSerialize = this.interceptors.reduce(
            (ctx, intercept) => intercept(ctx),
            context
        );

        return encodeURIComponent(JSON.stringify(ctxToSerialize));
    }

    deserializeParsedVc(vc: string) {
        if (vc) {
            const obj = JSON.parse(decodeURIComponent(vc));
            delete obj.docId;
            delete obj.pageId;
            return obj;
        }
    }

    deserialize(str: string) {
        const params = str.split('&');
        const vc = _.filter(params, function(param) {
            return _.startsWith(param, 'vc=');
        });
        if (vc.length > 0) {
            const obj = JSON.parse(decodeURIComponent(vc[0].replace('vc=', '')));
            delete obj.docId;
            delete obj.pageId;
            return obj;
        }
        return null;
    }
}
