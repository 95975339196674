import actionCreatorFactory, { ActionCreator } from 'typescript-fsa';
import { catchError, mergeMap, startWith } from 'rxjs/operators';
import { AnyAction } from 'redux';
import { Observable, of as observableOf, throwError } from 'rxjs';

const factory = actionCreatorFactory('LOADING_INDICATOR');

export const LOADING_STARTED: ActionCreator<void> = factory('LOADING_STARTED');
export const LOADING_STARTED_WITH_CUSTOM_MESSAGE: ActionCreator<string> = factory(
    'LOADING_STARTED_WITH_CUSTOM_MESSAGE'
);
export const LOADING_COMPLETED: ActionCreator<void> = factory('LOADING_COMPLETED');
export const LOADING_ERROR: ActionCreator<void> = factory('LOADING_ERROR');

/**
 * Adds the loading indicator actions to an observable
 */
export const withLoadingIndicator = (observable: Observable<AnyAction>) => {
    return observable.pipe(
        mergeMap(result => observableOf(LOADING_COMPLETED(), result)),
        startWith(LOADING_STARTED()),
        catchError(err => throwError(err).pipe(startWith(LOADING_COMPLETED())))
    );
};
