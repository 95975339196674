import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { Injector } from '@angular/core';
import { ExcelExport } from 'insightui.table/components/datatable/shared/services/table-export.service';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

declare var require: any;

const KPI_FILTER = /.*KPI.*/i;

export interface HighlightOptions extends BaseTablePluginOptions {
    matcher: string;
}

/**
 * Highlighting plugins for cells that are not represented by the given channel.
 */
export class NotRepresentedHighlightCellPlugin extends TableBodyPlugin {
    constructor(
        protected injector: Injector,
        protected configuration: HighlightOptions,
        protected table?: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        this.matcher = new RegExp(this.configuration.matcher, 'i');
    }
    static readonly tooltipId: string = 'notRepresentedTooltipId';
    name = 'notRepresentedPlugin';
    matcher: RegExp;

    @VisualizationContextBinding('rankContext.highlighting')
    highlightSettings;

    protected doEnable(cell: Cell) {
        const rootElement: HTMLElement = cell.element.nativeElement;

        if (this.isActive(rootElement)) {
            return;
        }
        rootElement.parentElement.style.background = this.highlightSettings.selected;
        if (cell.column.field !== 'IT') {
            this.enableTooltip(rootElement, cell);
        }
        this.markAsActive(rootElement);
    }

    private enableTooltip(dom: Element, cell: Cell) {
        const box: HTMLElement = document.createElement('div');
        box.id = NotRepresentedHighlightCellPlugin.tooltipId;
        box.setAttribute('style', 'position:absolute; width: 350px');
        if (!cell.row.$id) {
            return;
        }
        const id = cell.row.$id.replace(/^([A-Z]{1,3}).*$/gi, '$1');

        dom.addEventListener('mouseenter', (mouseEvent: MouseEvent) => {
            let html = require('raw-loader!./not-represented-tooltip.html');
            let element = cell.row[id];
            if (cell.row['IT']) {
                element += ' ' + cell.row['IT'];
            }
            html = html.replace(
                /\$color/,
                this.highlightSettings.selected.replace('#', '').toLowerCase()
            );
            box.innerHTML = html.replace(/\$element/, element);

            if (window.innerWidth <= mouseEvent.pageX + 15 + 350) {
                box.style.left = mouseEvent.pageX - 15 - 250 + 'px';
            } else {
                box.style.left = mouseEvent.pageX + 15 + 'px';
            }

            if (window.innerHeight <= mouseEvent.pageY + 15 + 150) {
                box.style.top = mouseEvent.pageY - 15 - 80 + 'px';
            } else {
                box.style.top = mouseEvent.pageY + 15 + 'px';
            }

            // #180434 appendChild should remove the old tooltip on its own, but it doesn't work in FF, so we do it manually
            this.removeOldTooltip();
            document.body.appendChild(box);
        });

        dom.addEventListener('mouseleave', () => {
            document.body.removeChild(box);
        });
    }

    protected doDisable(cell: Cell) {
        const rootElement: HTMLElement = cell.element.nativeElement;
        if (!this.isActive(rootElement) || !rootElement.parentElement) {
            return;
        }
        delete rootElement.parentElement.style.background;

        this.markAsInactive(rootElement);
    }

    private notRepresentedApplies(cell: Cell) {
        if (cell.row.TG === 'Total') {
            return false;
        }
        const kpiKeys = Object.keys(cell.row).filter(
            el => KPI_FILTER.test(el) && this.matcher.test(el)
        );
        return !kpiKeys.some(
            key => cell.row[key] !== null && cell.row[key] !== undefined
        );
    }

    select(cell: Cell): boolean {
        return (
            this.highlightSettings &&
            this.highlightSettings.checked &&
            super.select(cell) &&
            this.notRepresentedApplies(cell)
        );
    }

    extendExport(exportData: ExcelExport) {
        if (!this.highlightSettings || !this.highlightSettings.checked) {
            return exportData;
        }
        const sideHeader = this.flipHeader(exportData.sideHeader);
        const header = this.flipHeader(exportData.topHeader);

        exportData.value.forEach(row => {
            const highlightRow = row.data
                .filter((cell, idx) => idx >= sideHeader.length)
                .some((cell, idx) => {
                    const currentHeader = header[idx];
                    if (
                        this.matcher.test(currentHeader.getFieldId()) &&
                        this.selectExportRow(cell, currentHeader)
                    ) {
                        return cell.value !== null;
                    }
                    return false;
                });
            if (!highlightRow) {
                row.type = 'unrepresentedHighlight';
            }
        });

        exportData.metadata.push({
            type: 'unrepresentedHighlight',
            value: this.highlightSettings.selected
        });
        return exportData;
    }

    private removeOldTooltip() {
        const old = document.getElementById(NotRepresentedHighlightCellPlugin.tooltipId);
        if (old) {
            document.body.removeChild(old);
        }
    }
}
