/**
 * UserProfileService API Documentation
 * User Profile Service stores Favorites, Collections, Announcements and user-events.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FavoriteRequest } from '../model/favorite-request';
import { FavoriteSnapshotDto } from '../model/favorite-snapshot-dto';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class FavoritesControllerService {
    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Create a new favorite
     * @param favoriteRequest favoriteRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createFavorite(
        favoriteRequest: FavoriteRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FavoriteSnapshotDto>;
    public createFavorite(
        favoriteRequest: FavoriteRequest,
        clientId?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FavoriteSnapshotDto>>;
    public createFavorite(
        favoriteRequest: FavoriteRequest,
        clientId?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FavoriteSnapshotDto>>;
    public createFavorite(
        favoriteRequest: FavoriteRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (favoriteRequest === null || favoriteRequest === undefined) {
            throw new Error(
                'Required parameter favoriteRequest was null or undefined when calling createFavorite.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', <any>clientId);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FavoriteSnapshotDto>(
            `${this.configuration.basePath}/api/v2/favorites`,
            favoriteRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the favorite with the given id
     * @param reportSnapshotId reportSnapshotId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFavoriteById(
        reportSnapshotId: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FavoriteSnapshotDto>;
    public deleteFavoriteById(
        reportSnapshotId: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FavoriteSnapshotDto>>;
    public deleteFavoriteById(
        reportSnapshotId: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FavoriteSnapshotDto>>;
    public deleteFavoriteById(
        reportSnapshotId: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling deleteFavoriteById.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<FavoriteSnapshotDto>(
            `${this.configuration.basePath}/api/v2/favorites/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the favorite with the given id
     * @param reportSnapshotId reportSnapshotId
     * @param clientId clientId
     * @param keys keys
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavoriteById(
        reportSnapshotId: string,
        clientId?: string,
        keys?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FavoriteSnapshotDto>;
    public getFavoriteById(
        reportSnapshotId: string,
        clientId?: string,
        keys?: string,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FavoriteSnapshotDto>>;
    public getFavoriteById(
        reportSnapshotId: string,
        clientId?: string,
        keys?: string,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FavoriteSnapshotDto>>;
    public getFavoriteById(
        reportSnapshotId: string,
        clientId?: string,
        keys?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling getFavoriteById.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', <any>clientId);
        }
        if (keys !== undefined && keys !== null) {
            queryParameters = queryParameters.set('keys', <any>keys);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<FavoriteSnapshotDto>(
            `${this.configuration.basePath}/api/v2/favorites/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the favorites
     * @param clientId clientId
     * @param keys keys
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFavorites(
        clientId?: string,
        keys?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<Array<FavoriteSnapshotDto>>;
    public getFavorites(
        clientId?: string,
        keys?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<Array<FavoriteSnapshotDto>>>;
    public getFavorites(
        clientId?: string,
        keys?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<Array<FavoriteSnapshotDto>>>;
    public getFavorites(
        clientId?: string,
        keys?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', <any>clientId);
        }
        if (keys !== undefined && keys !== null) {
            queryParameters = queryParameters.set('keys', <any>keys);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<FavoriteSnapshotDto>>(
            `${this.configuration.basePath}/api/v2/favorites`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Empty the recycle bin
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public purgeDeleted(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public purgeDeleted(
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public purgeDeleted(
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public purgeDeleted(
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<any>(
            `${this.configuration.basePath}/api/v2/favorites`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the favorite
     * @param reportSnapshotId reportSnapshotId
     * @param favoriteRequest favoriteRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFavoriteById(
        reportSnapshotId: string,
        favoriteRequest: FavoriteRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FavoriteSnapshotDto>;
    public updateFavoriteById(
        reportSnapshotId: string,
        favoriteRequest: FavoriteRequest,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FavoriteSnapshotDto>>;
    public updateFavoriteById(
        reportSnapshotId: string,
        favoriteRequest: FavoriteRequest,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FavoriteSnapshotDto>>;
    public updateFavoriteById(
        reportSnapshotId: string,
        favoriteRequest: FavoriteRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling updateFavoriteById.'
            );
        }
        if (favoriteRequest === null || favoriteRequest === undefined) {
            throw new Error(
                'Required parameter favoriteRequest was null or undefined when calling updateFavoriteById.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FavoriteSnapshotDto>(
            `${this.configuration.basePath}/api/v2/favorites/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            favoriteRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
