import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';

class ContextStateChangesFactory {
    private subscriptions = {};

    notify(notificationType: NotificationType, eventPayload) {
        const eventName = this.getEventNameBy(notificationType);
        if (!this.subscriptions[eventName]) {
            return;
        }
        Object.keys(this.subscriptions[eventName]).forEach(key =>
            this.subscriptions[eventName][key].onContextStateChanges(
                notificationType,
                eventPayload
            )
        );
    }

    register(
        notificationType: NotificationType,
        component: string,
        onContextStateChangesFunc: (
            notificationType: NotificationType,
            eventPayload: any
        ) => void
    ) {
        const eventName = this.getEventNameBy(notificationType);

        if (!this.subscriptions[eventName]) {
            this.subscriptions[eventName] = {};
        }

        this.subscriptions[eventName][component] = {
            onContextStateChanges: onContextStateChangesFunc
        };
    }

    deregister(notificationType: NotificationType, component: string) {
        const eventName = this.getEventNameBy(notificationType);
        delete this.subscriptions[eventName][component];
    }

    private getEventNameBy(notificationType: NotificationType) {
        return NotificationType[notificationType];
    }
}

const NotificationStateChangesService = new ContextStateChangesFactory();

export default NotificationStateChangesService;
