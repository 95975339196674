import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter,
    Output
} from '@angular/core';

export interface BusinessQuestionSubEntry {
    businessQuestion: string;

    pageId: string;
}

@Component({
    selector: 'gfk-business-question',
    templateUrl: './business-question.component.html',
    styleUrls: ['./business-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessQuestionComponent {
    @Input() reportName: string | undefined;

    @Input() businessQuestion: string | undefined;

    @Input() subEntries: BusinessQuestionSubEntry[] = [];

    @Output() subEntryClick = new EventEmitter<BusinessQuestionSubEntry>();
}
