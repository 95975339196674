import { Injectable } from '@angular/core';

@Injectable()
export class EmptySearchService {
    private isActive: boolean;

    setIsActive(boolean) {
        this.isActive = boolean;
    }

    getIsActive(): boolean {
        return this.isActive;
    }
}
