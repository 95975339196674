import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
    INSIGHT_CONFIG,
    InsightConfig
} from 'insightui.global/global.module';

@Injectable({
    providedIn: 'root'
})
export class NewLoadStatusUserService {
    serviceUrl: string;

    constructor(private http: HttpClient, @Inject(INSIGHT_CONFIG) private insightUiConfig: InsightConfig) {
        this.serviceUrl = `${insightUiConfig.services.USERPROFILE_SERVICE.url}/api/v2/events/lastLoadStatusCheck`
    }

    setLastLoadStatusCheck(countryCode, period) {

        return this.http.post(this.serviceUrl, {
            countryCode: countryCode,
            period: period
        });
    }

    getLastLoadStatusCheck(countryCode, period) {
        return this.http.get(`${this.serviceUrl}?countryCode=${countryCode}&period=${period}`);
    }
}
