import { filter } from 'rxjs/operators';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BaseModel } from '../base.model';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import {
    MultiNotificationSubscription,
    NotificationEmitter,
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';

import { Subject } from 'rxjs';

export interface ChannelComparisonOption {
    id: string;
    title: string;
    selected: boolean;
    base: string;
    baseTitle: string;
    disabled: boolean;
}

export interface ChannelComparisonConfiguration {
    sourceChannel: string;
    targetChannels: string[];
    targetKpis: string[];
    defaultTargetChannel: string;
}

/**
 * Data model for the KPI ChannelComparison Setting.
 */
@Injectable()
export class ChannelComparisonModel extends BaseModel<ChannelComparisonOption> {
    private static KPI_PROPERTY_KEY = 'kpiComparisonChannel';

    name = ChannelComparisonModel;

    @VisualizationContextBinding('kpiProperties')
    private kpiProperties;

    @MultiNotificationSubscription([
        NotificationNamespace.Filter,
        NotificationNamespace.SplitBy,
        NotificationNamespace.DistributionUnit
    ])
    distributionUnitUpdate$: EventEmitter<NotificationEvent>;

    @NotificationEmitter(NotificationNamespace.Settings)
    filterNotification$: Subject<void>;

    @NotificationSubscription(NotificationNamespace.Settings)
    onSettingsUpdate$: Subject<void>;

    @VisualizationContextBinding('rankContext.rankComparison')
    rankComparison;

    private distributionUnitFilterModel;

    constructor(
        private pageDefinitionService: PageDefinitionService,
        @Inject('$injector') $injector
    ) {
        super();
        this.distributionUnitFilterModel = $injector.get('DistributionUnitFilterModel');
        this.distributionUnitUpdate$.subscribe(this.updateSelection.bind(this));
        this.subscribeToLegacyRankSetting();
    }

    onRestore(items: ChannelComparisonOption[]): BaseModel<ChannelComparisonOption> {
        return null;
    }

    onReadData(items?: ChannelComparisonOption[]): BaseModel<ChannelComparisonOption> {
        if (items) {
            this.data = items.concat([]);
            return this;
        }
        if (this.data && this.data.length) {
            return;
        }

        this.data = this.readFromPageConfiguration(this.rankComparison?.targetChannel);

        if (this.isRoot()) {
            this.save();
        }
        return this;
    }

    applyChanges(promise: { resolve; reject }): BaseModel<ChannelComparisonOption> {
        if (this.parent.isRoot()) {
            this.writeToVisualizationContext();
        }
        if (!this.isRoot() && this.hash(this.data) !== this.hash(this.parent.data)) {
            promise.resolve(this);
        } else {
            return promise.reject(this);
        }
    }

    onSave(promise: { resolve; reject }): BaseModel<ChannelComparisonOption> {
        return null;
    }

    updateSelection() {
        if (!this.data) {
            return;
        }
        this.data.forEach(selectionOption => {
            const distributionUnit = this.distributionUnitFilterModel.findOne(
                'id',
                selectionOption.id
            );
            selectionOption.disabled = !distributionUnit || !distributionUnit.checked();
        });

        const selected = this.data.find(option => option.selected);
        if (selected && selected.disabled) {
            this.data = this.readFromPageConfiguration();
            if (this.isRoot()) {
                this.save();
            }
        }
    }

    private readFromPageConfiguration(targetChannel?: string) {
        const comparison = this.getPageComparisonConfiguration();
        if (!comparison) {
            return [];
        }
        const baseTitle = this.distributionUnitFilterModel.findOne(
            'id',
            comparison.sourceChannel
        ).title;

        targetChannel = targetChannel || comparison.defaultTargetChannel;

        return this.distributionUnitFilterModel
            .clone()
            .filter(
                distributionUnit =>
                    comparison.targetChannels.indexOf(distributionUnit.id) >= 0
            )
            .map(distributionUnit => ({
                id: distributionUnit.id,
                title: distributionUnit.title,
                selected: targetChannel === distributionUnit.id,
                disabled: !distributionUnit.checked(),
                base: comparison.sourceChannel,
                baseTitle
            }));
    }

    public reset() {
        this.data = this.readFromPageConfiguration();
    }

    private getPageComparisonConfiguration(): ChannelComparisonConfiguration {
        const currentPage = this.pageDefinitionService.getCurrentPage();
        return currentPage.channelComparison;
    }

    private hash(data) {
        return data.map(el => el.id + el.selected + el.disabled).join(';');
    }

    private writeToVisualizationContext() {
        this.kpiProperties = this.kpiProperties || {};
        const kpis = this.getPageComparisonConfiguration().targetKpis;
        const selected = this.data.find(option => option.selected);
        kpis.forEach(kpi => {
            this.kpiProperties[kpi] = this.kpiProperties[kpi] || {};
            this.kpiProperties[kpi][ChannelComparisonModel.KPI_PROPERTY_KEY] =
                selected.id;
        });
        if (
            this.rankComparison &&
            this.rankComparison.checked &&
            this.rankComparison.targetChannel !== selected.id
        ) {
            this.rankComparison = {
                ...this.rankComparison,
                targetChannel: selected.id
            };
        }
    }

    private subscribeToLegacyRankSetting() {
        this.onSettingsUpdate$
            .pipe(filter(() => this.rankComparison && this.rankComparison.checked))
            .subscribe(() => {
                this.data.forEach(channel => {
                    channel.selected = channel.id === this.rankComparison.targetChannel;
                });
                if (this.isRoot()) {
                    this.save();
                }
            });
    }
}
