import { debounceTime } from 'rxjs/operators';
import {
    Component,
    ElementRef,
    EventEmitter,
    OnInit,
    Output,
    OnDestroy
} from '@angular/core';

import {
    NotificationEmitter,
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import {
    NotificationNamespace,
    NotificationType
} from 'insightui.table/ng1services/notifications/notification.model';
import { Subject, Observable } from 'rxjs';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'search-datatable',
    template: `
        <div
            class="search-datatable"
            style="position:relative;float:right;display:inline-block;"
        >
            <div *ngIf="searchActive">
                <input
                    type="text"
                    [(ngModel)]="searchText"
                    (ngModelChange)="bindModelChanged()"
                    placeholder="Filter Table"
                    maxlength="100"
                    class="search-input uitest-DSearch-searchText"
                    [class.focused]="searchText.length > 0"
                    (click)="$event.stopPropagation()"
                />
                <label
                    class="clear-text-icon"
                    *ngIf="searchText.length > 0"
                    (click)="clearText(); $event.stopPropagation()"
                    tabindex="1"
                ></label>
            </div>
            <label
                class="search-icon"
                (click)="toggleSearch(); $event.stopPropagation()"
                title="Filter Table"
            ></label>
        </div>
    `,
    styles: [
        `
            .search-datatable .search-icon::before {
                content: url(assets/images/search_table.png);
            }

            .search-datatable .search-icon:hover::before {
                content: url(assets/images/search_table_hover.png);
            }
        `
    ]
})
export class SearchDataTableComponent implements OnInit, OnDestroy {
    @Output() bindModelChange: EventEmitter<string> = new EventEmitter<string>();
    searchText: string;

    @NotificationEmitter(NotificationType.TableSearch)
    public tableSearch$: EventEmitter<string>;

    @NotificationSubscription(NotificationNamespace.SplitBy)
    onSplitByChange$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationNamespace.Filter)
    onFilterNotification$;

    @NotificationSubscription(NotificationNamespace.Drilling)
    onDrillingNotification$: Observable<NotificationEvent>;

    searchActive = false;
    searchTableElement;

    inputSubject = new Subject();

    constructor(private elementRef: ElementRef) {
        this.searchText = '';
    }

    ngOnInit() {
        this.searchTableElement = this.elementRef.nativeElement.querySelector(
            '.search-datatable'
        );
        this.searchTableElement.closeListener = () => {
            if (this.searchActive && this.searchText === '') {
                this.clearText();
                this.searchActive = false;
                this.searchTableElement.classList.remove('active');
            }
        };
        document.body.addEventListener('click', this.searchTableElement.closeListener);

        // debounce user input
        this.inputSubject.pipe(debounceTime(400)).subscribe(() => {
            this.tableSearch$.emit(this.searchText.trim());
        });

        this.onDrillingNotification$.subscribe(() => this.resetAfterReportChanged());
        this.onSplitByChange$.subscribe(() => this.resetAfterReportChanged());
        this.onFilterNotification$.subscribe(() => this.resetAfterReportChanged());
    }

    bindModelChanged() {
        this.inputSubject.next();
    }

    toggleSearch() {
        this.searchActive = !this.searchActive;
        if (this.searchActive) {
            this.searchTableElement.classList.add('active');
            setTimeout(() => {
                this.setFocusOnInput();
            }, 10);
        } else {
            this.searchTableElement.classList.remove('active');
        }
    }

    clearText() {
        this.searchText = '';
        this.tableSearch$.emit('');
        this.setFocusOnInput();
    }

    private resetAfterReportChanged() {
        if (this.searchActive) {
            this.clearText();
            this.toggleSearch();
        }
    }

    private setFocusOnInput() {
        const searchTextElement = this.searchTableElement.querySelector('.search-input');
        searchTextElement.focus();
    }

    ngOnDestroy() {
        document.body.removeEventListener('click', this.searchTableElement.closeListener);
        this.inputSubject.unsubscribe();
    }
}
