import { Inject, Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import {
    LOADING_COMPLETED,
    LOADING_STARTED_WITH_CUSTOM_MESSAGE
} from 'insightui.loading-indicator/loading-indicator.actions';

@Injectable({
    providedIn: 'root'
})
export class LoadingIndicatorService {
    private rootScope;

    /**
     * LoadingIndicatorService
     */
    constructor(@Inject('$injector') $injector, private ngRedux: NgRedux<AppRootState>) {
        this.rootScope = $injector.get('$rootScope');
    }

    /**
     * Toggle LoadingIndicator off
     */
    toggleOff() {
        this.rootScope.$broadcast('loadingComplete');
    }

    /**
     * Toggle LoadingIndicator on
     */
    toggleOn() {
        this.rootScope.$broadcast('loadingStarted');
    }

    /**
     * Runs an async process with a loading indicator.
     * The loading indicator will show as long as the process is running.
     */
    runWithLoadingIndicator<T, ARGS>(
        asyncFn: (...args: ARGS[]) => Promise<T>
    ): (...args: ARGS[]) => Promise<T> {
        const self = this;
        return async (...args) => {
            self.toggleOn();
            try {
                return await asyncFn.apply(null, args);
            } finally {
                self.toggleOff();
            }
        };
    }

    async runWithLoadingIndicatorAndMessage<T>(
        process: Promise<T>,
        message: string
    ): Promise<T> {
        this.ngRedux.dispatch(LOADING_STARTED_WITH_CUSTOM_MESSAGE(message));
        try {
            return await process;
        } finally {
            this.ngRedux.dispatch(LOADING_COMPLETED());
        }
    }
}
