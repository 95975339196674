import {
    Component,
    Inject,
    Optional,
    ViewChild,
    ElementRef,
    AfterViewInit,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    FormGroup,
    FormControl,
    Validators,
    AbstractControl,
    ValidationErrors,
    ValidatorFn
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    MAX_COLLECTION_REPORT_NAME_LENGTH,
    MAX_COLLECTION_NAME_LENGTH,
    ValidationService,
    Validation
} from '../../services/validation';

export interface AddToCollectionDialogResult {
    reportName: string;
    collectionName: string;
}

@Component({
    selector: 'gfk-add-to-collection-dialog',
    templateUrl: './add-to-collection-dialog.component.html',
    styleUrls: ['./add-to-collection-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddToCollectionDialogComponent implements AfterViewInit {
    public collectionNameReadonly = false;
    public headline = '';
    public nameForm: FormGroup;
    public readonly maxCollectionReportNameLength: number = MAX_COLLECTION_REPORT_NAME_LENGTH;
    public readonly maxCollectionNameLength: number = MAX_COLLECTION_NAME_LENGTH;

    @ViewChild('reportNameRef', { read: ElementRef, static: true })
    private reportNameInputField: ElementRef | undefined;

    constructor(
        private validationService: ValidationService,
        private dialogRef: MatDialogRef<AddToCollectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        @Optional()
        initialNames: {
            reportName: string;
            collectionName: string;
            headline: string;
        }
    ) {
        const initialReportName = initialNames ? initialNames.reportName : '';
        const initialCollectionName = initialNames ? initialNames.collectionName : '';
        if (initialCollectionName) {
            this.collectionNameReadonly = true;
        }

        this.headline =
            initialNames && initialNames.headline
                ? initialNames.headline
                : 'Add to new collection';

        this.nameForm = new FormGroup({
            reportName: new FormControl(initialReportName, [
                Validators.required,
                this.forbiddenReportNameValidator()
            ]),
            collectionName: new FormControl(initialCollectionName, [
                Validators.required,
                this.forbiddenCollectionNameValidator()
            ])
        });
    }

    ngAfterViewInit() {
        // this sets the cursor to the report name input field and pre-selects all text which might be in there.
        if (this.reportNameInputField) {
            this.reportNameInputField.nativeElement.focus();
            this.reportNameInputField.nativeElement.select();
        }
    }

    submitDialog() {
        if (!this.nameForm.invalid) {
            this.dialogRef.close({
                reportName: this.nameForm.value.reportName,
                collectionName: this.nameForm.value.collectionName
            });
        }
    }

    cancelDialog() {
        this.dialogRef.close();
    }

    getErrorMessage(fieldName: string): string | undefined {
        const formControl = this.nameForm.get(fieldName);
        if (
            formControl &&
            formControl.invalid &&
            formControl.errors &&
            formControl.errors.forbiddenName
        ) {
            return formControl.errors.forbiddenName.message;
        }
        return undefined;
    }

    private forbiddenReportNameValidator(): ValidatorFn {
        return this.controlValueValidator(
            this.validationService.isValidCollectionReportName
        );
    }

    private forbiddenCollectionNameValidator(): ValidatorFn {
        return this.controlValueValidator(this.validationService.isValidCollectionName);
    }

    private controlValueValidator(validation: Validation<string>): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value) {
                return null;
            }

            const validationResult = validation.validate(control.value);
            if (validationResult.hasFailed()) {
                return {
                    forbiddenName: {
                        value: control.value,
                        message: validationResult.getMessages()[0]
                    }
                };
            } else {
                return null;
            }
        };
    }
}
