import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { ReportingDataEntry } from 'insightui.data/query/queryservice/query-service-request.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class MissingTotalMapper implements ResponseMapper {
    map(reportingData: ReportingDataEntry[]): ReportingDataEntry[] {
        if (reportingData.length > 0 && !reportingData['additional'].total) {
            reportingData['additional']['total'] = [
                this.defaultTotalItem(reportingData[0])
            ];
        }
        return reportingData;
    }

    private defaultTotalItem(item: ReportingDataEntry): ReportingDataEntry {
        const newItem = {};
        Object.keys(item).forEach(key => {
            if (/^CH/i.test(key) || /^RT/i.test(key)) {
                newItem[key] = null;
            } else {
                newItem[key] = 'Total';
            }
        });
        return newItem;
    }
}
