import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'camelCase',
    pure: false
})
export class DeCamelCasePipe implements PipeTransform {
    transform(value: string): string {
        // Replace special characters with a space
        return value
            .replace(/([A-Z])/g, match => ` ${match}`)
            .replace(/^./, match => match.toUpperCase());
    }
}
