import { Component, Inject } from '@angular/core';
import {
    APP_BUILD_VERSION,
    INSIGHT_CONFIG,
    InsightConfig
} from 'insightui.global/global.module';

@Component({
    selector: 'page-footer',
    templateUrl: './page-footer.component.html'
})
export class PageFooterComponent {
    get year(): number {
        return new Date().getFullYear();
    }

    get name(): string {
        return this.insightConfig.app.name;
    }

    get version(): string {
        return this.appBuildVersion;
    }

    constructor(
        @Inject(APP_BUILD_VERSION) private appBuildVersion: string,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig
    ) {}
}
