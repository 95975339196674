import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    ExportExcelData,
    TableValue,
    ValueWithClass
} from 'insightui.export/excel/excel-export.types';
import { FairShareReportRow } from 'insightui.fair-share/fair-share.types';

export interface FairShareReportExcelExportDataSelectorExtraInfo {
    productName: string;
    title: string;
    country: string;
    products: string;
    distributionUnit: string;
    period: string;
    currency: string;
    optionBar?: string;
    filterBy: string;

    drillLevel: string;
}

export class FairShareReportExcelExportDataSelector {
    private logger: ILogger;

    constructor(logService: LogService) {
        this.logger = logService.getLogger('FairShareReportExcelExportDataSelector');
        this.logger.debug(`ctor`);
    }

    getExportDataSelector(
        extraInfo: FairShareReportExcelExportDataSelectorExtraInfo,
        totalRow: FairShareReportRow,
        dataRows: ReadonlyArray<FairShareReportRow>
    ): ExportExcelData {
        const tableValues: TableValue[] = this.exportTableValues(totalRow, dataRows);

        const exportData: ExportExcelData = {
            $productName: { type: 'text', value: extraInfo.productName },
            $title: { type: 'text', value: extraInfo.title },
            $filter: {
                type: 'filter',
                value: [
                    {
                        data: ['Country', extraInfo.country]
                    },
                    {
                        data: ['Products', extraInfo.products]
                    },
                    {
                        data: ['Distribution Unit', extraInfo.distributionUnit]
                    },
                    {
                        data: ['Period', extraInfo.period]
                    },
                    {
                        data: ['Currency', extraInfo.currency]
                    },
                    {
                        data: ['Filter by', extraInfo.filterBy]
                    }
                ]
            },
            $meta: {
                type: 'meta',
                value: [
                    {
                        type: 'template',
                        value: 'gfk_default.tpl.xlsx'
                    }
                ]
            },
            $table: {
                type: 'table',
                sideHeader: [
                    {
                        key: 'SH',
                        label: null,
                        nestedCols: [
                            {
                                key: 'SG',
                                label: extraInfo.drillLevel,
                                nestedCols: [],
                                format: {
                                    type: 'alphanumeric',
                                    digits: 0
                                }
                            }
                        ]
                    }
                ],
                topHeader: [
                    {
                        key: 'whatever',
                        label: 'Sales (Value)',
                        nestedCols: [
                            {
                                key: 'whatever',
                                label: '',
                                nestedCols: [],
                                format: {
                                    type: 'numerical',
                                    digits: 0
                                }
                            }
                        ],
                        format: {
                            type: 'alphanumeric',
                            digits: 0
                        }
                    },
                    {
                        key: 'whatever',
                        label: 'Fair Share Value',
                        nestedCols: [
                            {
                                key: 'whatever',
                                label: '',
                                nestedCols: [],
                                format: {
                                    type: 'percentage',
                                    digits: 1
                                }
                            }
                        ],
                        format: {
                            type: 'alphanumeric',
                            digits: 0
                        }
                    },
                    {
                        key: 'whatever',
                        label: 'Gap Value',
                        nestedCols: [
                            {
                                key: 'whatever',
                                label: '',
                                nestedCols: [],
                                format: {
                                    type: 'numerical',
                                    digits: 0
                                }
                            }
                        ],
                        format: {
                            type: 'alphanumeric',
                            digits: 0
                        }
                    },
                    {
                        key: 'whatever',
                        label: 'Value Growth Rate (vs. Value)',
                        nestedCols: [
                            {
                                key: 'whatever',
                                label: 'Retailer',
                                nestedCols: [],
                                format: {
                                    type: 'percentage',
                                    digits: 1
                                }
                            },
                            {
                                key: 'whatever',
                                label: 'Market',
                                nestedCols: [],
                                format: {
                                    type: 'percentage',
                                    digits: 1
                                }
                            }
                        ],
                        format: {
                            type: 'alphanumeric',
                            digits: 0
                        }
                    },
                    {
                        key: 'whatever',
                        label: 'Gap Value',
                        nestedCols: [
                            {
                                key: 'whatever',
                                label: '',
                                nestedCols: [],
                                format: {
                                    type: 'numerical',
                                    digits: 0
                                }
                            }
                        ],
                        format: {
                            type: 'alphanumeric',
                            digits: 0
                        }
                    }
                ],
                value: tableValues
            }
        };

        if (extraInfo.optionBar) {
            exportData.$filter.value.push({
                data: ['Option Bar', extraInfo.optionBar]
            });
        }
        this.logger.debug(`selected excel export data spec`, exportData);
        return exportData;
    }

    private getExportDataPoint(
        row: FairShareReportRow,
        isTotalRow: boolean
    ): ValueWithClass[] {
        const dataPoints: ValueWithClass[] = [
            {
                value: row.title
            },
            {
                value: row.marketSizeValue
            },
            {
                value: row.fairShareValue
            },
            {
                value: (row.marketSizeGapValue === 0 || isTotalRow) ? null : row.marketSizeGapValue,
                class: row.marketSizeGapValue > 0 ? 'aboveThreshold' : undefined
            },
            {
                value: row.valueGrowthValueRetailer,
                class: this.getExportDataPointClassName(
                    row.valueGrowthValueRetailer,
                    isTotalRow
                )
            },
            {
                value: row.valueGrowthValuePanelMarket,
                class: this.getExportDataPointClassName(
                    row.valueGrowthValuePanelMarket,
                    isTotalRow
                )
            },
            {
                value: row.valueGrowthGapValue,
                class: row.valueGrowthGapValue > 0 ? 'aboveThreshold' : undefined
            }
        ];

        dataPoints.forEach(point => {
            if (point.class === undefined) {
                delete point.class;
            }
        });

        return dataPoints;
    }

    private getExportDataPointClassName(
        value: number,
        isTotalRow: boolean
    ): string | undefined {
        let className = value >= 0 ? 'good' : 'bad';
        if (className && isTotalRow) {
            className += 'Total';
        }
        return className;
    }

    private exportTableValues(
        totalRow: FairShareReportRow,
        dataRows: ReadonlyArray<FairShareReportRow>
    ): TableValue[] {
        const result: TableValue[] = [];
        this.logger.debug('TotalRow', totalRow);
        result.push({
            type: 'totals',
            data: this.getExportDataPoint(totalRow, true)
        });

        dataRows.forEach(row => {
            result.push({
                data: this.getExportDataPoint(row, false)
            });
        });
        this.logger.debug('Rows', dataRows);
        return result;
    }
}
