import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

export interface LoadDataParams {
    kpiList;
    channelList;
    posTypeList;
    priceClassList;
    featureMap;
    elementList;
    showFeatures;
    showPriceClasses;
    showFirstActivities;
    calculateOthers;
    sideHeaders;
    comparisonHistory;
    rankContext;
    parameterSettingsSort;
    parameterSettingsThreshold;
    parameterSettingsColOrderHeaders;
    currentColOrder;
    periodFilterTitle;
    contextFilterTitle;
    subTotalTitle;
    brandName;
    businessQuestion;
    countryTitle;
    visualizationType;
    selectedFeatureList;
    useBrandAsBase;
    rebaseDimension;
    contextFilter;
    calculateSegmentedOthers;

    setVisualizationContext(visualizationContext);
    setPeriod(selectedPeriods, comparisonPeriod);
    setPeriodFromPrevious(comparisonPeriod);
    addKpi(kpi);
    setKpiList(kpiList);
    addChannel(channel);
    setChannelList(channelList);
    addPosType(posType);
    setPosType(posType);
    setPosTypeList(posTypeList);
    setPriceClassList(priceClassList);
    addFeature(featureType, featureValue);
    setFeatureMap(featureMap);
    setFeatureFilterList(featureFilterList);
    addElement(brand);
    setElementList(elementList);
    setShowFeatures(showFeatures);
    setShowPriceClasses(showPriceClasses);
    setFeatureShowList(value);
    getFeatureShowList();
    setCalculateOthers(calculateOthers);
    addSideHeader(sideHeader);
    setComparisonHistory(comparisonHistory);
    setRankContext(rankContext);
    setParameterSettingsSort(parameterSettingsSort);
    setParameterSettingsThreshold(parameterSettingsThreshold);
    setParameterSettingsColOrderHeaders(parameterSettingsColOrderHeaders);
    setVisualizationType(visualizationType);
    setBrandName(brandName);
    setContextFilter(contextFilter);
    getServiceParams();
}

@Injectable()
export class LoadDataParamsFactory {
    constructor(
        @Inject('$injector') private $injector,
        private visualizationContextService: VisualizationContextService
    ) {}

    fromCurrentVisualizationContext(
        includeOthers: boolean,
        splitBy?: string[]
    ): LoadDataParams {
        const loadDataParams = this.newLoadParams();

        loadDataParams.setVisualizationContext(
            this.visualizationContextService.getVisualizationContext()
        );

        loadDataParams.channelList = _.clone(
            this.visualizationContextService.get('channelList')
        );

        // Ensure that the base is always queried, even when it's not displayed.
        // Otherwise filtering by it's values does not occur
        if (
            !_.includes(
                loadDataParams.channelList,
                this.visualizationContextService.get('contextFilter.base')
            )
        ) {
            loadDataParams.channelList.push(
                this.visualizationContextService.get('contextFilter.base')
            );
        }

        // when having an item segmentation others won't have any data
        if (
            this.visualizationContextService.get('chart.segmentationDimension') === 'item'
        ) {
            includeOthers = false;
        }
        if (includeOthers) {
            if (this.requiresSegmentedOthers()) {
                loadDataParams.calculateSegmentedOthers = true;
            } else {
                loadDataParams.calculateOthers = includeOthers;
            }
        }

        if (
            this.visualizationContextService.get('contextFilter.element.id') ===
            'elementSelection'
        ) {
            loadDataParams.setElementList(
                this.visualizationContextService.get('elementList')
            );
        } else {
            if (this.visualizationContextService.get('itemInBrand')) {
                loadDataParams.setElementList([]);
                loadDataParams.addElement(
                    this.visualizationContextService.get('itemInBrand')
                );
                loadDataParams.useBrandAsBase = true;
            }
        }

        if (splitBy) {
            splitBy.forEach(type => {
                loadDataParams['show' + _.upperFirst(type)] = true;
            });
        }

        return loadDataParams;
    }

    private newLoadParams() {
        const LoadDataParams = this.$injector.get('LoadDataParams'); // tslint:disable-line

        return new LoadDataParams();
    }

    private requiresSegmentedOthers(): boolean {
        if (this.visualizationContextService.get('chart.focusDimension') !== 'item') {
            return false;
        }
        return (
            ['priceClass', 'feature'].indexOf(
                this.visualizationContextService.get('chart.segmentationDimension')
            ) >= 0
        );
    }
}
