import {
    BaseTablePluginOptions,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { Injector } from '@angular/core';

export class DrillingHeaderPlugin extends TableHeaderPlugin {
    name = 'DrillingHeaderPlugin';
    drillingService: DrillingService;

    cssClass = 'plugin-drilling-header-handle';

    constructor(injector: Injector, configuration: BaseTablePluginOptions) {
        super(injector, configuration);
        this.drillingService = this.injector.get(DrillingService);
        this.executeDrilling = this.executeDrilling.bind(this);
    }

    executeDrilling() {
        this.drillingService.drillUp();
    }

    protected doEnable(cell: DatatableHeaderCellComponent) {
        const rootNode: HTMLElement = cell.element.nativeElement.querySelector(
            '.datatable-header-label'
        );
        if (!rootNode) {
            console.error(`${this.name} executed on cell without element`);
            return;
        }
        if (this.isActive(rootNode)) {
            return;
        }
        this.addDrillUpIndicator(rootNode);
        this.markAsActive(rootNode);
    }

    protected doDisable(cell: DatatableHeaderCellComponent) {
        const rootNode: HTMLElement = cell.element.nativeElement.querySelector(
            '.datatable-header-label'
        );

        if (!rootNode) {
            console.error(`${this.name} executed on cell without element`);
            return;
        }
        if (!this.isActive(rootNode)) {
            return;
        }
        this.removeDrillUpIndicator(rootNode);
        this.markAsInactive(rootNode);
    }

    private removeDrillUpIndicator(rootNode: Element) {
        let drillButton = rootNode.querySelector(`.${this.cssClass}`);
        drillButton.removeEventListener('click', this.executeDrilling);
        rootNode.removeChild(drillButton);
    }

    private addDrillUpIndicator(rootNode: Element) {
        const drillButton = document.createElement('span');
        drillButton.classList.add(this.cssClass);
        drillButton.classList.add('fa');
        drillButton.classList.add('fa-level-up');
        drillButton.classList.add('fa-flip-horizontal');
        drillButton.addEventListener('click', this.executeDrilling);
        rootNode.insertBefore(drillButton, rootNode.firstChild);
    }

    select(cell): boolean {
        if (!this.drillingService.supports('up')) {
            return false;
        }
        return super.select(cell);
    }

    extendExport(exportData) {
        return exportData;
    }
}
