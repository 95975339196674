import { Observable } from 'rxjs';
import { HierarchyInfo } from 'insightui.metadata/metadata.types';
import { Store } from '@ngxs/store';
import { MetadataState } from './metadata.state';
import { filter, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class MetadataSelectors {
    constructor(private store: Store) {}

    public getAllHierarchyDimensions$(): Observable<HierarchyInfo[]> {
        return this.store.select(MetadataState.allHierarchyDimensions()).pipe(
            filter(di => !!di),
            take(1)
        );
    }

    public getHierarchyDimension$(id: string): Observable<HierarchyInfo> {
        return this.store.select(MetadataState.hierarchyDimensions(id)).pipe(
            filter(di => !!di),
            take(1)
        );
    }

    public getHierarchyChildDimensions$(parentId: string): Observable<HierarchyInfo[]> {
        return this.store.select(MetadataState.hierarchyChildDimensions(parentId)).pipe(
            filter(di => !!di),
            take(1)
        );
    }

    public getHierarchyDimensionInAllPeriods$(
        id: string
    ): Observable<{ period: string; dimension: HierarchyInfo }[]> {
        return this.store.select(MetadataState.hierarchyDimensionsInAllPeriods(id)).pipe(
            filter(di => !!di),
            take(1)
        );
    }

    public getHierarchyDimension(id: string): HierarchyInfo {
        return this.store.selectSnapshot(MetadataState.hierarchyDimensions(id));
    }

    public getHierarchyChildDimensions(parentId: string): HierarchyInfo[] {
        return (
            this.store.selectSnapshot(MetadataState.hierarchyChildDimensions(parentId)) ||
            []
        );
    }
}
