import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { Angulartics2Module } from 'angulartics2';
import { DropDownModule } from 'insightui.common-components/drop-down/drop-down.module';
import { ModalDialogModule } from 'insightui.common-components/modal-dialog/modal-dialog.module';
import { SorryModule } from 'insightui.common-components/sorry/sorry.module';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';
import { FeedNotificationButtonComponent } from './feed-notification-button/feed-notification-button.component';
import { FeedNotificationDialogComponent } from './feed-notification-dialog/feed-notification-dialog.component';

@NgModule({
    declarations: [FeedNotificationButtonComponent, FeedNotificationDialogComponent],
    imports: [
        CommonModule,
        ModalDialogModule,
        FormsModule,
        DropDownModule,
        ReactiveFormsModule,
        MatDialogModule,
        Angulartics2Module,
        SorryModule
    ],
    providers: [
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?feed-settings\/?/,
            multi: true
        }
    ],
    exports: [FeedNotificationButtonComponent]
})
export class FeedNotificationModule {}
