import { NgRedux } from '@angular-redux/store';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { InsightConfig, INSIGHT_CONFIG } from 'insightui.global/global.module';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { PageDefinition } from 'insightui.data/types';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import * as _ from 'lodash';
import { map, take, filter } from 'rxjs/operators';
import { DimensionResolverService } from './dimension-resolver.service';
import {
    KPIS_LOADED,
    LABELS_LOADED,
    PAGE_INDEX_LOADED,
    PAGE_LOADED
} from '../page-index.actions';

declare var require: any;

const REPORT_CONTEXT = require('../resources/reportContext.json');
const PAGE_INDEX = require('../resources/pageIndex.json');
const PAGES = require('../resources/pages.json');
const LABELS = require('../resources/labels.json');

/**
 * Service for loading documents and page definitions.
 */
@Injectable({ providedIn: 'root' })
export class PageDefinitionLoaderService {
    private logger: ILogger;

    private reportEntryPointService;

    constructor(
        logService: LogService,
        private httpClient: HttpClient,
        private metadataService: MetadataService,
        private dimensionResolverService: DimensionResolverService,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig,
        private ngRedux: NgRedux<AppRootState>,
        injector: AngularInjectorResolver
    ) {
        this.logger = logService.getLogger('PageDefinitionLoaderService');
        this.logger.debug('ctor');

        injector
            .get()
            .pipe(take(1))
            .subscribe($injector => {
                this.reportEntryPointService = $injector.get('ReportEntryPointService');
                this.logger.debug('Resolved Ng1 services');
            });
    }

    async loadPage(pageId): Promise<PageDefinition> {
        if (pageId === 'fair-share') {
            pageId = 'fairShare';
        }

        const uri = `./assets/pages/${pageId}.json?v=${this.insightConfig?.app?.version}`;
        this.logger.debug('load page from assets:', uri);

        let page = (await this.httpClient.get(uri).toPromise()) as PageDefinition;

        this.logger.debug('loaded from assets:', page);

        const reportKpis = page.report.kpiDefinition['@kpi'];
        if (reportKpis) {
            const allKpis = await this.ngRedux
                .select(state => state.pageDefinition.kpis)
                .pipe(
                    filter(kpiProperties => kpiProperties && kpiProperties.length > 0),
                    take(1)
                )
                .toPromise();

            // filter out unmatched kpis (those that are not available on QueryServices)
            const availableKpis = reportKpis
                .map(kpi => allKpis.find(k => k.id === kpi))
                .filter(kpi => kpi);

            if (availableKpis.length !== reportKpis.length) {
                this.logger.warn(
                    'Some reports KPIs are not globally available',
                    reportKpis.filter(rk => !availableKpis.some(ak => ak.id === rk))
                );
            }

            page = {
                ...page,
                report: {
                    ...page.report,
                    kpiDefinition: {
                        ...page.report.kpiDefinition,
                        kpi: availableKpis
                    }
                }
            };
        }

        // To resolve those custom expressions "$value":"channel:retailer" etc.
        const deliveryInfo = this.metadataService.getDeliveryInfo();
        if (deliveryInfo) {
            const dimensions = deliveryInfo.dimensions;

            // To resolve those custom expressions "$value":"channel:retailer" etc.
            this.dimensionResolverService.resolveDimensions(page, dimensions);
            this.logger.debug('after resolving dimensions:', page);
        }

        const entryPoint = await this.reportEntryPointService.determineEntryPoint(page);
        if (entryPoint === null) {
            return Promise.reject();
        }

        // Overwrite the page again with the new drill target
        const drilledPage = Object.assign({}, page, {
            drillTarget: entryPoint
        });

        this.ngRedux.dispatch(PAGE_LOADED(drilledPage));

        return drilledPage;
    }

    public loadReportContext() {
        this.logger.debug('loaded report context');
        // TODO: only called from ReportContextBase - could be simplified
        return _.cloneDeep(REPORT_CONTEXT);
    }

    public loadLabels() {
        const labels = _.cloneDeep(LABELS);

        this.ngRedux.dispatch(LABELS_LOADED(labels));
    }

    public loadIndex() {
        const pageIndex = _.cloneDeep(PAGE_INDEX);
        const pages = _.cloneDeep(PAGES);

        this.ngRedux.dispatch(
            PAGE_INDEX_LOADED({
                pageIndices: pageIndex,
                pages
            })
        );
    }

    public loadKpis(): void {
        const uri = this.insightConfig.services.QUERY_SERVICE.url + 'api/v1/kpis';
        this.httpClient
            .get(uri)
            .pipe(map(kpis => Object.values(kpis)))
            .subscribe(kpis => {
                this.ngRedux.dispatch(KPIS_LOADED(kpis));
            });
    }
}
