import { BarChartStrategy } from 'insightui.export/powerpoint/charts/bar-chart/bar-chart-strategy';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { PowerpointExportSheet, PowerpointExportSheets } from 'insightui.export/types';

export class BarChartStrategyComparisonPeriod extends BarChartStrategy {
    constructor(chart: HTMLElement, chartToSheetService: ChartToSheetService) {
        super(chart, chartToSheetService);
    }

    getEdgeCase(): number {
        return 8;
    }

    getMaxItemsPerPage(): number {
        return 5;
    }

    getLastPages(lastPageIndex, remainingItems): PowerpointExportSheets {
        let sheets: PowerpointExportSheet[] = [];

        switch (remainingItems) {
            case 8:
            case 7:
                sheets.push(this.createSheetFromRange(4, lastPageIndex));
                sheets.push(this.createSheetFromRange(4, lastPageIndex + 4));
                break;
            case 6:
                sheets.push(this.createSheetFromRange(3, lastPageIndex));
                sheets.push(this.createSheetFromRange(3, lastPageIndex + 3));
                break;

            default:
                sheets.push(
                    this.createSheetFromRange(this.getMaxItemsPerPage(), lastPageIndex)
                );
                break;
        }

        return sheets;
    }
}
