import { Injectable } from '@angular/core';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { DeliveryInfo } from '../metadata.types';
import { Page } from 'insightui.page-definition/page-definition.types';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class AccessControlService {
    private logger: ILogger;

    /**
     *  Valid restriction types in metadata file are same as implemented
     *  validation function, e.g.: function posTypeRequired() { ... }
     *
     *  pages: {
     *      "101a" : {
     *          "restrictions" : [ "posTypeRequired", .... ]
     *      }}
     */
    constructor(logService: LogService) {
        this.logger = logService.getLogger('AccessControlService');
    }

    public hasPageAccess(deliveryInfo: DeliveryInfo, page: Page): boolean {
        const restrictions = page.restrictions || [];

        const hasAccess = restrictions.every(r => this.meetsRequirement(r, deliveryInfo));

        return hasAccess;
    }

    private meetsRequirement(restriction: string, deliveryInfo: DeliveryInfo): boolean {
        if (!_.isFunction(this[restriction])) {
            this.logger.warn(
                `Page restriction ${restriction} cannot be verified. It's assumed to be unfulfilled.`
            );
            return false;
        }

        return this[restriction](deliveryInfo);
    }

    /**
     * Checks whether posTypeRequired restriction is met
     */
    posTypeRequired(deliveryInfo: DeliveryInfo): boolean {
        const posTypes = deliveryInfo.dimensions.posType;
        const hasPosTypes = Object.keys(posTypes).some(
            pt => posTypes[pt].type !== 'total'
        );
        return hasPosTypes;
    }
}
