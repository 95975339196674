import { NgModule } from '@angular/core';
import { DashIfEmptyPipe } from 'insightui.number-format/dash-if-empty.pipe';
import { WithSuffixPipe } from 'insightui.number-format/withSuffix.pipe';
import { NumberRangePipe } from 'insightui.number-format/number-range.pipe';
import { AbsPipe } from 'insightui.number-format/abs.pipe';
import { ClampPipe } from 'insightui.number-format/clamp.pipe';
import { NumberSuffixPipe } from 'insightui.number-format/number-suffix.pipe';
import { ClampWithPrefixPipe } from 'insightui.number-format/clamp-with-prefix.pipe';

@NgModule({
    declarations: [
        DashIfEmptyPipe,
        WithSuffixPipe,
        NumberRangePipe,
        AbsPipe,
        ClampPipe,
        NumberSuffixPipe,
        ClampWithPrefixPipe
    ],
    exports: [
        DashIfEmptyPipe,
        WithSuffixPipe,
        NumberRangePipe,
        NumberSuffixPipe,
        AbsPipe,
        ClampPipe,
        ClampWithPrefixPipe
    ]
})
export class NumberFormatModule {}
