import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { preserveClientId } from 'insightui.core/services/navigation-utils';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor() {

    }

    getUrlParam(name) {
        const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);

        if (results === null) {
            return null;
        } else {
            return results[1] || '';
        }
    };

    validateRouteName(name) {
        return (
            new RegExp('/' + name + '/').test(window.location.pathname) ||
            new RegExp('#?/' + name + '/?$').test(window.location.pathname)
        );
    };

    validateParamName(name) {
        return new RegExp('[?&](?:' + name + ')(?:[=&]|$)').test(
            window.location.href
        );
    };

    openPage(countryCode, period) {
        const url = _.head(window.location.href.split('?'));
        window.location.href = preserveClientId(url + '?country=' + countryCode + '&period=' + period);
    };
}
