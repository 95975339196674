import {
    Revision,
    CountryInfo,
    DeliveryInfo,
    LoadStatusInfo,
    HierarchyTree,
    DeliveryInfoContainer
} from 'insightui.metadata/metadata.types';

export class MsLoadRevisions {
    static readonly type = '[MS] LoadRevisions';

    constructor() {}
}

export class MsLoadRevisionsSuccess {
    static readonly type = '[MS] LoadRevisionsSuccess';

    constructor(public readonly revisions: Revision[]) {}
}

export class MsLoadDeliveryInfo {
    static readonly type = '[MS] LoadDeliveryInfo';

    constructor(public readonly docId: number) {}
}

// TODO: not yet handled - would replace MsDeliveryInfoChanged
export class MsLoadDeliveryInfoSuccess {
    static readonly type = '[MS] LoadDeliveryInfoSuccess';

    constructor(public readonly deliveryInfoContainer: DeliveryInfoContainer) {}
}

export class MsCountriesLoaded {
    static readonly type = '[MS] CountriesLoaded';

    constructor(public readonly countries: CountryInfo[]) {}
}

export class MsCurrentPeriodIdChanged {
    static readonly type = '[MS] CurrentPeriodIdChanged';

    constructor(public readonly periodId: number | string) {}
}

export class MsCurrentRevisionIdChanged {
    static readonly type = '[MS] CurrentRevisionIdChanged';

    constructor(public readonly revisionId: number) {}
}

export class MsCurrentCountryChanged {
    static readonly type = '[MS] CurrentCountryChanged';

    constructor(public readonly country: string) {}
}

export class MsDeliveryInfoChanged {
    static readonly type = '[MS] DeliveryInfoChanged';

    constructor(public readonly deliveryInfo: DeliveryInfo) {}
}

export class MsDeliveryInfosOfCurrentCountryChanged {
    static readonly type = '[MS] DeliveryInfosOfCurrentCountryChanged';

    constructor(public readonly deliveryInfos: DeliveryInfo[]) {}
}

export class MsLoadStatusChanged {
    static readonly type = '[MS] LoadStatusChanged';

    constructor(public readonly loadStatusInfos: LoadStatusInfo[]) {}
}

export class MsHierarchyChanged {
    static readonly type = '[MS] HierarchyChanged';

    constructor(public readonly hierarchyTrees: HierarchyTree[]) {}
}
