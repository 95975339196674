import {
    legacyDrillingNotificationService,
    Ng1NotificationServicesInterface,
    legacyFilterNotificationService,
    legacySegmentationNotificationService
} from './ajs-upgraded-providers';
import { Injectable, Inject } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/**
 * This service triggers the observable 'somethingChanged$' every time a
 * relevant filter changed in the angularJS code.
 */
@Injectable()
export class LegacyNotificationService {
    private notifier$$ = new Subject<void>();

    get somethingChanged$(): Observable<void> {
        return this.notifier$$.asObservable();
    }

    constructor(
        @Inject(legacyDrillingNotificationService)
        drillingNotificationService: Ng1NotificationServicesInterface,
        @Inject(legacyFilterNotificationService)
        filterNotificationService: Ng1NotificationServicesInterface,
        @Inject(legacySegmentationNotificationService)
        segmentationNotificationService: Ng1NotificationServicesInterface
    ) {
        drillingNotificationService.subscribeService(() => this.notify());
        filterNotificationService.subscribeService(() => this.notify());
        segmentationNotificationService.subscribeService(() => this.notify());
    }

    private notify() {
        this.notifier$$.next();
    }
}
