import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    CollectionControllerService,
    CollectionDto,
    CollectionInfoControllerService
} from 'insightui.openapi/userprofile';
import { EMPTY, Observable, Observer, Subject } from 'rxjs';
import {
    catchError,
    concatMap,
    filter,
    map,
    mergeMap,
    takeUntil,
    tap
} from 'rxjs/operators';
import {
    CmCreateCollection,
    CmDeleteCollection,
    CmDeleteCollectionSuccess,
    CmDeleteReport,
    CmDeleteReportSuccess,
    CmLoadCollections,
    CmLoadCollectionsSuccess,
    CmLoadNewSharedCollections,
    CmLoadNewSharedCollectionsSuccess,
    CmMoveOrCopyReports,
    CmRenameReport,
    CmReorderCollection,
    CmReorderReports,
    CmRestoreCollection,
    CmRestoreCollectionSuccess,
    CmSaveAsUserCollection,
    CmShareCollection,
    CmUpdateCollection,
    CmUpdateCollectionSuccess,
    CmLoadCollectionsFailure,
    CmLoadNewSharedCollectionsFailure,
    CmCollectionUpdateFailure
} from './collection-manager.actions';
import {
    CollectionManagerState,
    CollectionManagerStateModel
} from './collection-manager.state';

export function mapCollectionDtoArrayToSuccessAction(
    collections: CollectionDto[]
): CmLoadCollectionsSuccess {
    // dateTime values are transmitted as strings, convert them to
    // real date objects
    collections = collections.map(c => ({
        ...c,
        createdTime: new Date(c.createdTime),
        modifiedTime: new Date(c.modifiedTime),
        lastAccessTime: new Date(c.lastAccessTime)
    }));

    const activeCollections = collections.filter(c => c.status === 'ACTIVE');

    const userCollections = activeCollections.filter(c => c.collectionType === 'USER');

    const sharedCollections = activeCollections.filter(
        c => c.collectionType === 'SHARED'
    );

    const temporaryCollection = activeCollections.filter(
        c => c.collectionType === 'TEMPORARY'
    )[0];

    const recycledCollections = collections.filter(
        c => c.status === 'DELETED' && c.collectionType === 'USER'
    );

    return new CmLoadCollectionsSuccess(
        temporaryCollection,
        userCollections,
        sharedCollections,
        recycledCollections
    );
}

@Injectable()
export class CollectionManagerHandler implements OnDestroy {
    /**
     * watches the action$ subscriptions and logs any unexpected
     * termination.
     */
    private readonly obs: Observer<any> = {
        next: _ => void 0,
        error: err => {
            this.logger.error('Observable errored and terminated unexpectedly', err);
            this._unitTestIsTerminated = true;
        },
        complete: () => {
            this.logger.error('Observable completed unexpectedly');
            this._unitTestIsTerminated = true;
        }
    };

    /**
     * Only used in unittests to ensure the
     * action$ does not get terminated unexpectedly
     */
    public _unitTestIsTerminated = false;

    private _killer$$ = new Subject<void>();

    private logger: ILogger;

    private actions$: Actions;

    private catchErrorAndDispatchFailure = () => (source: Observable<any>) => {
        return source.pipe(
            catchError(err => {
                this.logBackendError(err);
                this.store.dispatch(new CmCollectionUpdateFailure(err));
                return EMPTY;
            })
        );
    };

    constructor(
        private store: Store,
        actionsInternal$: Actions,
        private collectionControllerService: CollectionControllerService,

        private collectionInfoControllerService: CollectionInfoControllerService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('CollectionManagerHandler');
        this.actions$ = actionsInternal$.pipe(takeUntil(this._killer$$));

        this.handleCmLoadCollections();
        this.handleCmCreateCollection();
        this.handleCmSaveAsUserCollection();

        this.handleCmUpdateCollection();
        this.handleCmDeleteCollection();
        this.handleCmRestoreCollection();
        this.handleCmReorderCollection();
        this.handleCmShareCollection();

        this.handleCmDeleteReport();
        this.handleCmRenameReport();
        this.handleCmMoveOrCopyReports();
        this.handleCmReorderReports();

        this.handleCmLoadNewSharedCollections();
    }

    ngOnDestroy() {
        this._killer$$.next();
        this._killer$$.complete();
    }

    private handleCmCreateCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmCreateCollection),
                concatMap((action: CmCreateCollection) => {
                    // this only works if the new collection is already in the store, with empty id
                    // which means it has to be inserted by CmPrepareNewCollection first
                    let collectionToCreate: CollectionDto = this.store
                        .selectSnapshot(CollectionManagerState)
                        .userCollections.find(c => c.id === '');

                    if (!collectionToCreate) {
                        this.logger
                            .error(`Cannot create a new collection. There was no collection with an empty id in the store,
                        this usually means CmCreateCollection was dispatched before CmPrepareNewCollection`);
                        return EMPTY;
                    }

                    collectionToCreate = {
                        ...collectionToCreate,
                        name: action.newName
                    };
                    const backendExecution$: Observable<CollectionDto> = action.copyOfId
                        ? this.collectionControllerService.duplicateCollectionById(
                              action.copyOfId,
                              { name: collectionToCreate.name }
                          )
                        : this.collectionControllerService.createCollection({
                              name: collectionToCreate.name,
                              precedingCollectionId: action.precedingCollectionId
                          });
                    return backendExecution$.pipe(
                        tap(_ => this.store.dispatch(new CmLoadCollections())),
                        this.catchErrorAndDispatchFailure()
                    );
                })
            )
            .subscribe(this.obs);
    }

    private handleCmSaveAsUserCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmSaveAsUserCollection),
                concatMap((action: CmSaveAsUserCollection) => {
                    const collectionToCopy: CollectionDto = this.store
                        .selectSnapshot(CollectionManagerState.sharedCollections)
                        .find(c => c.id === action.collectionId);

                    if (!collectionToCopy) {
                        this.logger.error(`Cannot save as personal collection.
                            There was no shared collection with id ${action.collectionId} in the store.`);
                        return EMPTY;
                    }

                    return this.collectionControllerService
                        .duplicateCollectionById(action.collectionId, {
                            name: action.newName
                        })
                        .pipe(
                            tap(_ => this.store.dispatch(new CmLoadCollections())),
                            this.catchErrorAndDispatchFailure()
                        );
                })
            )
            .subscribe(this.obs);
    }

    private handleCmDeleteCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmDeleteCollection),
                concatMap((action: CmDeleteCollection) => {
                    const state: CollectionManagerStateModel = this.store.selectSnapshot(
                        CollectionManagerState
                    );
                    const collectionToDelete: CollectionDto =
                        state.userCollections.find(c => c.id === action.collectionId) ||
                        state.sharedCollections.find(c => c.id === action.collectionId);

                    if (collectionToDelete) {
                        return this.collectionControllerService
                            .deleteCollectionById(collectionToDelete.id)
                            .pipe(
                                tap(deletedCollection =>
                                    this.store.dispatch(
                                        new CmDeleteCollectionSuccess(deletedCollection)
                                    )
                                ),
                                this.catchErrorAndDispatchFailure()
                            );
                    }
                    return EMPTY;
                })
            )
            .subscribe(this.obs);
    }

    private handleCmRestoreCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmRestoreCollection),
                concatMap((action: CmRestoreCollection) => {
                    const collectionToRestore: CollectionDto = this.store
                        .selectSnapshot(CollectionManagerState)
                        .recycledCollections.find(c => c.id === action.collectionId);
                    if (collectionToRestore) {
                        return this.collectionControllerService
                            .restoreCollection(collectionToRestore.id, {
                                name: action.newName
                            })
                            .pipe(
                                tap(restoredCollection =>
                                    this.store.dispatch(
                                        new CmRestoreCollectionSuccess(restoredCollection)
                                    )
                                ),
                                this.catchErrorAndDispatchFailure()
                            );
                    }
                    return EMPTY;
                })
            )
            .subscribe(this.obs);
    }

    private handleCmReorderCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmReorderCollection),
                concatMap((action: CmReorderCollection) => {
                    const state = this.store.selectSnapshot(CollectionManagerState);
                    const movedCollection: CollectionDto = state.userCollections.find(
                        (c: CollectionDto) => c.id === action.movedCollectionId
                    );
                    const precedingCollection =
                        action.precedingCollectionId === null
                            ? null
                            : state.userCollections.find(
                                  (c: CollectionDto) =>
                                      c.id === action.precedingCollectionId
                              );
                    if (movedCollection) {
                        return this.collectionControllerService
                            .reorderCollections({
                                collectionIds: [movedCollection.id],
                                precedingCollectionId: precedingCollection
                                    ? precedingCollection.id
                                    : null
                            })
                            .pipe(
                                tap(_ => this.store.dispatch(new CmLoadCollections())),
                                this.catchErrorAndDispatchFailure()
                            );
                    } else {
                        return EMPTY;
                    }
                })
            )
            .subscribe(this.obs);
    }

    private handleCmRenameReport() {
        this.actions$
            .pipe(
                ofActionDispatched(CmRenameReport),
                concatMap((renameAction: CmRenameReport) => {
                    const currentCollection = this.store.selectSnapshot(
                        CollectionManagerState.selectedCollection
                    );
                    if (currentCollection) {
                        const report = currentCollection.reportSnapshots.find(
                            r => r.id === renameAction.reportSnapshotId
                        );
                        if (!report) {
                            return EMPTY;
                        }
                        return this.collectionControllerService
                            .updateReportById(report.id, { name: renameAction.newName })
                            .pipe(
                                tap(_ => this.store.dispatch(new CmLoadCollections())),
                                this.catchErrorAndDispatchFailure()
                            );
                    } else {
                        return EMPTY;
                    }
                })
            )
            .subscribe(this.obs);
    }

    private handleCmDeleteReport() {
        this.actions$
            .pipe(
                ofActionDispatched(CmDeleteReport),
                concatMap((deleteReportAction: CmDeleteReport) =>
                    this.collectionControllerService
                        .deleteReportById(deleteReportAction.reportSnapshotId)
                        .pipe(
                            map(_ => deleteReportAction.reportSnapshotId),
                            this.catchErrorAndDispatchFailure()
                        )
                ),
                tap(deletedReportid =>
                    this.store.dispatch([
                        new CmDeleteReportSuccess(deletedReportid),
                        new CmLoadCollections()
                    ])
                )
            )
            .subscribe(this.obs);
    }

    private handleCmMoveOrCopyReports() {
        this.actions$
            .pipe(
                ofActionDispatched(CmMoveOrCopyReports),
                concatMap((action: CmMoveOrCopyReports) => {
                    const backendObs$ =
                        action.action === 'move'
                            ? this.collectionControllerService.moveReports(
                                  action.sourceCollectionId,
                                  {
                                      reportSnapshotIds: action.reportIds as string[],
                                      collectionId: action.targetCollectionId
                                  }
                              )
                            : this.collectionControllerService.duplicateReports(
                                  action.sourceCollectionId,
                                  {
                                      reportSnapshotIds: action.reportIds as string[],
                                      collectionId: action.targetCollectionId
                                  }
                              );

                    return backendObs$.pipe(
                        tap(_ => this.store.dispatch(new CmLoadCollections())),
                        this.catchErrorAndDispatchFailure()
                    );
                })
            )
            .subscribe(this.obs);
    }

    private handleCmLoadCollections() {
        this.actions$
            .pipe(
                ofActionDispatched(CmLoadCollections),
                mergeMap(_ =>
                    this.collectionControllerService.getCollections().pipe(
                        catchError(err => {
                            this.logBackendError(err);
                            this.store.dispatch(new CmLoadCollectionsFailure(err));
                            return EMPTY;
                        })
                    )
                ),
                map(collectionDtos =>
                    mapCollectionDtoArrayToSuccessAction(collectionDtos)
                ),
                tap(loadCollectionsSuccessAction =>
                    this.store.dispatch(loadCollectionsSuccessAction)
                )
            )
            .subscribe(this.obs);
    }

    private handleCmLoadNewSharedCollections() {
        this.actions$
            .pipe(
                ofActionDispatched(CmLoadNewSharedCollections),
                mergeMap((action: CmLoadNewSharedCollections) =>
                    this.collectionInfoControllerService
                        .getNewlySharedCollectionIds(action.countryCodes)
                        .pipe(
                            catchError(err => {
                                this.logBackendError(err);
                                this.store.dispatch(
                                    new CmLoadNewSharedCollectionsFailure(err)
                                );
                                return EMPTY;
                            })
                        )
                ),
                tap(collectionIds =>
                    this.store.dispatch(
                        new CmLoadNewSharedCollectionsSuccess(collectionIds)
                    )
                )
            )
            .subscribe(this.obs);
    }

    private handleCmUpdateCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmUpdateCollection),
                map((action: CmUpdateCollection) => {
                    const userCollections = this.store.selectSnapshot(
                        CollectionManagerState.userCollections
                    );
                    let collectionToUpdate = userCollections.find(
                        c => c.id === action.collectionId
                    );
                    if (collectionToUpdate) {
                        collectionToUpdate = {
                            ...collectionToUpdate,
                            name: action.newName
                        };
                    }
                    return collectionToUpdate;
                }),
                filter(collectionToUpdate => !!collectionToUpdate),
                concatMap(collectionToUpdate =>
                    this.collectionControllerService
                        .updateCollectionById(collectionToUpdate.id, {
                            name: collectionToUpdate.name
                        })
                        .pipe(
                            tap(updatedCollection =>
                                this.store.dispatch(
                                    new CmUpdateCollectionSuccess(updatedCollection)
                                )
                            ),
                            this.catchErrorAndDispatchFailure()
                        )
                )
            )
            .subscribe(this.obs);
    }

    private handleCmShareCollection() {
        this.actions$
            .pipe(
                ofActionDispatched(CmShareCollection),
                concatMap((action: CmShareCollection) => {
                    return this.collectionControllerService
                        .shareCollection(action.collection.id, {
                            name: action.collection.name
                        })
                        .pipe(
                            tap(_ => this.store.dispatch(new CmLoadCollections())),
                            this.catchErrorAndDispatchFailure()
                        );
                })
            )
            .subscribe();
    }

    private handleCmReorderReports() {
        this.actions$
            .pipe(
                ofActionDispatched(CmReorderReports),
                concatMap((action: CmReorderReports) => {
                    return this.collectionControllerService
                        .reorderReports(action.collection.id, {
                            reportSnapshotIds: action.selectedReports.map(r => r.id),
                            precedingReportSnapshotId: action.precedingReport
                                ? action.precedingReport.id
                                : null
                        })
                        .pipe(
                            tap(_ => this.store.dispatch(new CmLoadCollections())),
                            this.catchErrorAndDispatchFailure()
                        );
                })
            )
            .subscribe(this.obs);
    }

    private logBackendError(err: any) {
        this.logger.error(`Error while talking to backend API`, err);
    }
}
