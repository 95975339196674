/**
 * Settings extension for the performance indicator
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    ChannelComparisonModel,
    ChannelComparisonOption
} from '../../../models/reportSetting/channel-comparison.model';
import { DecimalPipe } from '@angular/common';
import {
    ThresholdModel,
    ThresholdModelValue
} from '../../../models/reportSetting/threshold.model';

@Component({
    selector: 'performance-indicator-settings',
    templateUrl: './performance-indicator-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerformanceIndicatorSettingsComponent implements OnInit, AfterViewInit {
    @Output()
    applyClicked: EventEmitter<void> = new EventEmitter<void>();

    comparisonOptions: ChannelComparisonModel;
    thresholdOptions: ThresholdModel;

    collapsed: boolean = true;
    supported: boolean = true;

    performanceHigherFormat: { transform: { (string): string } };
    performanceLowerFormat: { transform: { (string): string } };

    positiveThreshold: ThresholdModelValue;
    negativeThreshold: ThresholdModelValue;

    @ViewChild('outerContainer')
    element: ElementRef;

    @ViewChild('footerContainer')
    footerElement: ElementRef;

    constructor(
        private channelComparisonModel: ChannelComparisonModel,
        private thresholdModel: ThresholdModel,
        private decimalPipe: DecimalPipe
    ) {
        this.performanceHigherFormat = {
            transform: number => ' ≥ ' + this.decimalPipe.transform(number, '1.2-2') + '%'
        };
        this.performanceLowerFormat = {
            transform: number => ' ≤ ' + this.decimalPipe.transform(number, '1.2-2') + '%'
        };
    }

    ngOnInit(): void {
        this.channelComparisonModel.readData();
        this.thresholdModel.readData();
        this.update();
    }

    ngAfterViewInit(): void {
        this.supported =
            this.channelComparisonModel.data &&
            this.channelComparisonModel.data.length > 0 &&
            this.thresholdModel.data &&
            this.thresholdModel.data.find(el => el.id === 'good') !== undefined;
        this.update();
    }

    reset() {
        this.comparisonOptions.reset();
        this.thresholdOptions.reset();
        this.positiveThreshold = this.thresholdOptions.findOne('id', 'good');
        this.negativeThreshold = this.thresholdOptions.findOne('id', 'bad');
    }

    apply() {
        Promise.all([this.comparisonOptions.save(), this.thresholdOptions.save()]).then(
            () => this.update()
        );
        this.applyClicked.emit();
    }

    onCollapse() {
        this.collapsed = !this.collapsed;
        if (!this.collapsed) {
            this.update();
        }
    }

    getPanelHeight() {
        if (this.footerElement) {
            return (
                this.footerElement.nativeElement.offsetTop -
                this.element.nativeElement.querySelector('.panel-body').offsetTop
            );
        }
        return 0;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (this.collapsed) {
            return;
        }
        if (!this.element.nativeElement.contains(event.target)) {
            this.collapsed = true;
        }
    }

    public selectChannel(option: ChannelComparisonOption) {
        this.comparisonOptions.data.forEach(
            data => (data.selected = data.id === option.id)
        );
    }

    private update() {
        this.comparisonOptions = this.channelComparisonModel.clone();
        this.thresholdOptions = this.thresholdModel.clone();
        // TODO: rename these constants to something more generic
        this.positiveThreshold = this.thresholdOptions.findOne('id', 'good');
        this.negativeThreshold = this.thresholdOptions.findOne('id', 'bad');
    }
}
