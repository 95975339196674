<div
    dropdown
    *ngIf="headerVisible"
    #piDropdown="bs-dropdown"
    class="append-to-bottom"
    (onShown)="onShown()"
    (mouseover)="status.autoClose = false"
    (mouseout)="status.autoClose = true"
    [autoClose]="status.autoClose"
    [attr.title]="title"
>
    <span class="dropdownToggle sub-header-text clickable" dropdownToggle>{{
        title
    }}</span>

    <ul
        class="dropdown-menu insightui-dropdown"
        *dropdownMenu
        role="menu"
        #piDropdownContent
    >
        <li class="dropdown-header" *ngIf="header">{{ header }}</li>
        <li class="radio dropdown-item" *ngFor="let option of model.data">
            <input
                class="dropdown-item"
                type="radio"
                name="{{ name }}"
                id="{{ option.id + name }}"
                value="{{ option.id }}"
                [checked]="option.selected"
                (change)="onChange(option); piDropdown.hide()"
                [disabled]="option.disabled"
            />
            <label class="dropdown-item" for="{{ option.id + name }}">{{
                option.title
            }}</label>
        </li>
    </ul>
</div>
