import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { ReportingDataSet } from 'insightui.data/query/queryservice/query-service-request.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class ConditionalTotalRemover implements ResponseMapper {
    private ignoreRemove;
    private key;
    private elementKey;
    private match;
    private removedTitle;

    configure(ignoreRemove: boolean, fieldInfoMapper: any) {
        this.ignoreRemove = ignoreRemove;
        this.key = fieldInfoMapper.key;
        this.elementKey = fieldInfoMapper.elementKey;
        this.match = fieldInfoMapper.match;
        this.removedTitle = fieldInfoMapper.removedTitle;
    }

    map(reportingData: ReportingDataSet): ReportingDataSet {
        if (this.ignoreRemove) {
            return reportingData;
        }

        let totalByKey = {};

        reportingData.forEach(datarow => {
            const key = datarow[this.key];
            const element = datarow[this.elementKey];
            if (!totalByKey[key]) {
                totalByKey[key] = 0;
            }
            if (this.match.test(element)) {
                totalByKey[key] += 1;
            }
        });

        const result: ReportingDataSet = reportingData.filter(datarow => {
            return !(
                totalByKey[datarow[this.key]] > 0 &&
                datarow[this.elementKey] === this.removedTitle
            );
        });
        result.additional = reportingData.additional;
        return result;
    }
}
