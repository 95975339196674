import { EventEmitter, Injectable } from '@angular/core';
import { TablePlugin } from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Subject } from 'rxjs';

/**
 * Service for registering table and row plugins on the table.
 */
@Injectable()
export class TablePluginRegistry {
    private pluginRegistrationEmitter$: EventEmitter<TablePlugin> = new EventEmitter();
    private pluginDeregistrationEmitter$: EventEmitter<TablePlugin> = new EventEmitter();

    private registeredPlugins: TablePlugin[] = [];

    registerPlugin(plugin: TablePlugin) {
        if (this.registeredPlugins.indexOf(plugin) >= 0) {
            return;
        }
        this.registeredPlugins.push(plugin);
        this.pluginRegistrationEmitter$.emit(plugin);
    }

    deregisterPlugin(plugin: TablePlugin) {
        if (this.registeredPlugins.indexOf(plugin) < 0) {
            return;
        }
        this.registeredPlugins = this.registeredPlugins.filter(el => el !== plugin);
        this.pluginDeregistrationEmitter$.emit(plugin);
    }

    get pluginRegistered$(): Subject<TablePlugin> {
        return this.pluginRegistrationEmitter$;
    }

    get pluginDeregistered$(): Subject<TablePlugin> {
        return this.pluginDeregistrationEmitter$;
    }

    getRegistered() {
        return this.registeredPlugins.concat([]);
    }
}
