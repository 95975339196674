import { combineEpics, Epic } from 'redux-observable';
import {
    BEGIN_CLOSE_MODAL,
    ERROR_FETCHING_RELEASE_NOTES,
    FINISH_CLOSE_MODAL,
    OPEN_MODAL,
    SHOW_FORCED_RELEASE_NOTES_IF_NEEDED,
    SHOW_RELEASE_NOTES_VIA_BELL
} from 'insightui.announcements/announcements.actions';
import {
    catchError,
    filter,
    map,
    mapTo,
    startWith,
    switchMap,
    withLatestFrom,
    tap
} from 'rxjs/operators';
import { AnnouncementsService } from 'insightui.announcements/announcements.service';
import { Injectable } from '@angular/core';
import { AppStateEpic } from 'projects/insightui/src/app/state/state.types';
import {
    AnnouncementState,
    ReleaseNotes
} from 'insightui.announcements/announcements.types';
import { AnyAction } from 'redux';
import { pipe, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

const ignoreAllErrorHandler = catchError((err, source$) =>
    source$.pipe(startWith(ERROR_FETCHING_RELEASE_NOTES()))
);

const openReleaseNotes = pipe(
    filter((releaseNotes: ReleaseNotes) => releaseNotes.length > 0),
    map((releaseNotes: ReleaseNotes) => OPEN_MODAL(releaseNotes))
);

@Injectable()
export class AnnouncementsEpic implements AppStateEpic<'announcements'> {
    key: 'announcements' = 'announcements';

    private showToastrErrorHandler = catchError((err, source$) => {
        this.toastr.error(
            `Our Release Notes are currently not available. We are very sorry for the inconvenience this may cause. Please try again later.`,
            `Service unavailable`
        );
        return source$.pipe(startWith(ERROR_FETCHING_RELEASE_NOTES()));
    });

    constructor(
        private announcementsService: AnnouncementsService,
        private toastr: ToastrService
    ) {}

    getEpic(): Epic {
        const forcedReleaseNotes: Epic = action$ =>
            action$.pipe(
                filter(SHOW_FORCED_RELEASE_NOTES_IF_NEEDED.match),
                switchMap(() => this.announcementsService.getForcedReleaseNotes()),
                openReleaseNotes,
                ignoreAllErrorHandler
            );

        const showReleaseNotes: Epic = action$ => {
            return action$.pipe(
                filter(SHOW_RELEASE_NOTES_VIA_BELL.match),
                switchMap(() => this.announcementsService.getReleaseNotes()),
                openReleaseNotes,
                this.showToastrErrorHandler
            );
        };

        const closeModal: Epic = (
            action$: Observable<AnyAction>,
            state$: Observable<AnnouncementState>
        ) =>
            action$.pipe(
                filter(BEGIN_CLOSE_MODAL.match),
                withLatestFrom(state$),
                switchMap(([action, state]) =>
                    this.announcementsService.saveLastSeenReleaseNote(state.releaseNotes)
                ),
                mapTo(FINISH_CLOSE_MODAL())
            );

        return combineEpics(forcedReleaseNotes, showReleaseNotes, closeModal);
    }
}
