import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { Store } from '@ngxs/store';

/**
 * Angular 1 Wrapper Service Wrapper for NgRedux
 */
@Injectable({ providedIn: 'root' })
export class Ng1ReduxService {
    constructor(public store: NgRedux<AppRootState>, public ngxsStore: Store) {}
}
