import { combineLatest } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ChartConfiguration, ChartModel, LegendRow } from '../types';
import { DimensionId, DimensionName } from 'insightui.data/types';
import { ActiveChartModel } from './activeChartModel.service';
import { SortingOptionsService } from './sortingOptions.service';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

@Injectable()
export class ChartLegendBarChartService {
    private elementSelectionModel;

    private elementListModel;

    constructor(
        private activeChartModel: ActiveChartModel,
        private sortingOptionsService: SortingOptionsService
    ) {
        AngularInjectorResolver.waitForInjector().then(injector => {
            this.elementSelectionModel = injector.get('ElementSelectionModel');
            this.elementListModel = injector.get('ElementListModel');
        });
    }

    legendRows(configuration: ChartConfiguration): Observable<LegendRow[]> {
        const notSortable$ = this.sortingOptionsService.notSortableDimensionsForConfiguration(
            configuration
        );
        const chartModel$ = this.activeChartModel.chartModel(configuration);
        const canSortManually$ = this.sortingOptionsService.canSortManually(
            configuration
        );
        return chartModel$.pipe(
            combineLatest(
                notSortable$,
                canSortManually$,
                (model, notSortable, canSortManually) => {
                    return this.rowsFromModel(model).map(row =>
                        this.markRow(notSortable, canSortManually, row)
                    );
                }
            )
        );
    }

    private markRow(
        notSortable: ReadonlyArray<DimensionId>,
        canSortManually: boolean,
        row: LegendRow
    ): LegendRow {
        const isSortable = !notSortable.some(id => row.id === id);
        return {
            ...row,
            sortable: isSortable,
            draggable: isSortable && canSortManually
        };
    }

    private rowsFromModel(model: ChartModel): LegendRow[] {
        let updatedRows: LegendRow[];

        if (!model.getDimension()) {
            return [];
        }

        const lastDimension: DimensionName = _.last(model.getDimensions());
        const order: DimensionId[] = model.getOrder();

        updatedRows = _.map(order, function(item) {
            const elem = _.find(model.groupLastDimension(), function(row) {
                return row.key.id === item;
            });
            return _.get(elem, 'key') || { id: item };
        });

        const elementSelection = _.find(this.elementSelectionModel.clone(), function(
            item
        ) {
            return item.selected;
        });

        if (
            _.get(elementSelection, 'id') === 'elementSelection' &&
            lastDimension === 'item'
        ) {
            updatedRows = this.addMissingBrands(updatedRows);
        }

        this.setStackIndex(updatedRows);

        return updatedRows.map(row => ({
            ...row,
            lastDimension
        }));
    }

    /**
     * Service does not return brands with no values. This method adds
     * missing brands to the legend with default color white.
     * @param rows
     * @returns {*}
     */
    private addMissingBrands(rows: LegendRow[]): LegendRow[] {
        const allBrands = [];
        _.forEach(this.elementListModel.clone(), function(item) {
            allBrands[_.get(item, ['ids', 0])] = _.get(item, 'name');
        });
        const elementList = _.filter(this.elementListModel.clone(), function(item) {
            return item.selected;
        });
        const requestedIds = _.map(elementList, function(item) {
            return _.get(item, ['ids', 0]);
        });
        const responseItems = _.map(rows, function(row) {
            return row.id;
        });
        const responseIds = _.filter(responseItems, function(id) {
            return id !== 'Others';
        });

        const missingIds = _.difference(requestedIds, responseIds);
        const missing = _.map(missingIds, function(id) {
            return {
                color: '#fff',
                id: id,
                title: _.get(allBrands, id),
                hideIndex: true
            } as any;
        });

        const brands = _.filter(rows, function(item) {
            return _.get(item, 'id') !== 'Others';
        });
        const others = _.filter(rows, function(item) {
            return _.get(item, 'id') === 'Others';
        });

        return brands.concat(missing).concat(others);
    }

    /**
     *  Set corresponding index id for label elements
     */
    private setStackIndex(rows: LegendRow[]): void {
        let index = 0;

        // not using reverse() here (would sort in-place)
        for (let i = rows.length - 1; i >= 0; --i) {
            const row = rows[i];
            if (row) {
                if (!row.hideIndex) {
                    _.set(row, 'index', '_' + index);
                    index++;
                } else {
                    _.set(row, 'index', 'noindex');
                }
            }
        }
    }
}
