import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AnnouncementControllerService } from './api/announcement-controller.service';
import { CollectionControllerService } from './api/collection-controller.service';
import { CollectionInfoControllerService } from './api/collection-info-controller.service';
import { FavoritesControllerService } from './api/favorites-controller.service';
import { ScheduleControllerService } from './api/schedule-controller.service';
import { StatusControllerService } from './api/status-controller.service';
import { UserEventControllerService } from './api/user-event-controller.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        AnnouncementControllerService,
        CollectionControllerService,
        CollectionInfoControllerService,
        FavoritesControllerService,
        ScheduleControllerService,
        StatusControllerService,
        UserEventControllerService
    ]
})
export class ApiModule {
    public static forRoot(
        configurationFactory: () => Configuration
    ): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(
        @Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient
    ) {
        if (parentModule) {
            throw new Error(
                'ApiModule is already loaded. Import in your base AppModule only.'
            );
        }
        if (!http) {
            throw new Error(
                'You need to import the HttpClientModule in your AppModule! \n' +
                    'See also https://github.com/angular/angular/issues/20575'
            );
        }
    }
}
