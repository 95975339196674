import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
    ChangeDetectionStrategy,
    Component,
    OnInit
} from '@angular/core';

/**
 * This is just a wrapper component for the ComponentPortal content. Used only internally.
 */
@Component({
    template: '<ng-container *ngTemplateOutlet="template"></ng-container>',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrapperComponent {
    @Input() template: TemplateRef<any> | undefined;
}

/**
 * This directive allows you to display any template as an overlay when clicking on the element
 * with the gfkDropDown directive. It uses the Angular Material Component Development Kit (CDK)
 * Overlay functionality under the hood to do the heavy lifting of positioning.
 *
 * Example:
 * ```
 * <div [gfkDropDown]="tooltipValue"
 *      [gfkDropDownPosition]="customPositionFromComponentClass"
 *      #theDropDown1="gfkDropDown">
 *  A button or whatever
 * </div>
 *
 * <ng-template #tooltipValue>
 *       <h3>
 *           Some content
 *       </h3>
 *       <button (click)="theDropDown.close()">close</button>
 *  </ng-template>
 * ```
 */
@Directive({
    selector: '[gfkDropDown]',
    exportAs: 'gfkDropDown'
})
export class DropDownDirective implements OnInit, OnDestroy {
    /**
     * Specify the template which should be displayed as a dropdown.
     * Template may contain other components...
     */
    @Input() gfkDropDown: TemplateRef<any> | undefined;

    /**
     * When false, the dropdown cannot be toggled any more.
     * Default: true.
     */
    @Input() gfkDropDownEnabled = true;

    /**
     * You can specify preferred positions (an array of ConnectedPositionPair)
     * for the FlexibleConnectedPositionStrategy here.
     * See https://material.angular.io/cdk/overlay/overview#position-strategies
     * https://material.angular.io/cdk/overlay/api#ConnectionPositionPair
     *
     * If nothing is specified, the drop-down will be placed below the triggering element
     * with an offset of 15px to the left and 15px to the bottom.
     */
    @Input() gfkDropDownPosition: ConnectionPositionPair[] | undefined;

    @Input() gfkDropDownCloseTimeout: number | undefined;

    private overlayRef: OverlayRef | undefined;

    private portal: ComponentPortal<WrapperComponent> | undefined;

    private isOpenInternal = false;

    private readonly cssClassNameHover = 'drop-down--hover';

    get isOpen(): boolean {
        return this.isOpenInternal;
    }

    constructor(
        private elRef: ElementRef,
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef
    ) {}

    ngOnInit() {
        if (!this.gfkDropDownPosition || this.gfkDropDownPosition.length < 1) {
            this.setupDefaultPosition();
        }
    }

    ngOnDestroy() {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }

    public open() {
        this.detach();

        const overlayRef = this.createOverlay();

        this.portal =
            this.portal || new ComponentPortal(WrapperComponent, this.viewContainerRef);

        const wrapperInstance: WrapperComponent = overlayRef.attach(this.portal).instance;
        wrapperInstance.template = this.gfkDropDown;
        this.isOpenInternal = true;

        if (!!this.gfkDropDownCloseTimeout) {
            setTimeout(() => {
                this.close();
            }, this.gfkDropDownCloseTimeout);
        }
    }

    public close() {
        this.detach();
        this.isOpenInternal = false;
    }

    @HostListener('mouseenter')
    public onMouseEnter() {
        this.elRef.nativeElement.classList.add(this.cssClassNameHover);
    }

    @HostListener('mouseleave')
    public onMouseLeave() {
        this.elRef.nativeElement.classList.remove(this.cssClassNameHover);
    }

    @HostListener('click')
    public onClick() {
        if (!this.gfkDropDownEnabled) {
            return;
        }
        this.isOpenInternal ? this.close() : this.open();
    }

    @HostListener('document:click', ['$event'])
    public onOutsideClick(evt: MouseEvent) {
        if (!this.gfkDropDownEnabled) {
            return;
        }

        if (evt.target !== this.elRef.nativeElement) {
            this.close();
        }
    }

    private setupDefaultPosition() {
        this.gfkDropDownPosition = [
            new ConnectionPositionPair(
                { originX: 'start', originY: 'bottom' },
                { overlayX: 'start', overlayY: 'top' }
            ),
            new ConnectionPositionPair(
                { originX: 'start', originY: 'top' },
                { overlayX: 'start', overlayY: 'bottom' }
            )
        ];

        this.gfkDropDownPosition[0].offsetX = -15;
        this.gfkDropDownPosition[0].offsetY = 15;
    }

    private detach() {
        if (this.overlayRef && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
    }

    private createOverlay(): OverlayRef {
        if (this.overlayRef) {
            return this.overlayRef;
        }

        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elRef)
            .withPositions(this.gfkDropDownPosition);

        // we don't use backdrop to close the dropdown because this would
        // prevent the elements behind to receive clicks
        this.overlayRef = this.overlay.create({
            positionStrategy,
            hasBackdrop: false
        });

        return this.overlayRef;
    }
}
