import { BaseModel } from '../base.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
    NotificationEmitter,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationNamespace } from 'insightui.table/ng1services/notifications/notification.model';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';

export interface ThresholdModelValue {
    id: string;
    min: number;
    max: number;
    value: number;
    ticks: number;
    active: boolean;
}

/**
 * Data model for the KPI ChannelComparison Setting.
 */
@Injectable()
export class ThresholdModel extends BaseModel<ThresholdModelValue> {
    name = ThresholdModel;

    @NotificationEmitter(NotificationNamespace.Settings)
    filterNotification$: Subject<void>;

    @NotificationSubscription(NotificationNamespace.Settings)
    onFilterChanged: Subject<void>;

    @VisualizationContextBinding('thresholdSetting')
    thresholdSetting;

    @VisualizationContextBinding('rankContext.rankComparison')
    rankComparison;

    constructor(private pageDefinitionService: PageDefinitionService) {
        super();
        this.subscribeToLegacyRankSetting();
    }

    onRestore(items: ThresholdModelValue[]): BaseModel<ThresholdModelValue> {
        return this;
    }

    onReadData(items?: ThresholdModelValue[]): BaseModel<ThresholdModelValue> {
        if (items) {
            this.data = items;
            return this;
        }
        if (this.data.length) {
            return this;
        }
        this.loadPageConfigurationSettings();
        this.loadImplicitRankThresholds();
        return this;
    }

    private loadPageConfigurationSettings() {
        const indicatorSettings = this.pageDefinitionService.getCurrentPage().report
            .performanceIndicator;
        if (indicatorSettings) {
            this.data = indicatorSettings.map(setting => {
                return {
                    id: setting.id || setting.mode,
                    min: setting.min,
                    max: setting.max,
                    ticks: setting.ticks,
                    value: parseFloat(setting.value),
                    active: true
                };
            });
        }
    }

    applyChanges(promise: { resolve; reject }): BaseModel<ThresholdModelValue> {
        if (this.isRoot() || this.parent.isRoot()) {
            this.updateVisualizationContext();
        }
        if (this.hash(this.data) !== this.hash(this.parent.data)) {
            promise.resolve(this);
        }
        return this;
    }

    onSave(promise: { resolve; reject }): BaseModel<ThresholdModelValue> {
        return this;
    }

    private updateVisualizationContext() {
        this.thresholdSetting = this.thresholdSetting || {};
        this.data.forEach(
            threshold => (this.thresholdSetting[threshold.id] = threshold.value)
        );
    }

    private hash(data: ThresholdModelValue[]) {
        return data.map(threshold => threshold.id + threshold.value).join(';');
    }

    reset() {
        this.loadPageConfigurationSettings();
    }

    /**
     * TODO: Remove and replace as soon as thresholds are defined clearly.
     */
    private loadImplicitRankThresholds() {
        const rankHighlight = this.pageDefinitionService.getCurrentPage().report
            .rankHighlightingDefinition;
        if (!rankHighlight) {
            return;
        }
        this.data.push({
            id: 'rank',
            min: 0,
            max: 9,
            value: this.rankComparison ? this.rankComparison.rankPosition : 1,
            ticks: 1,
            active: this.rankComparison ? this.rankComparison.checked : false
        });
    }

    private subscribeToLegacyRankSetting() {
        this.onFilterChanged.subscribe(() => {
            const rank = this.findOne('id', 'rank');
            if (!rank) {
                return;
            }
            rank.active = this.rankComparison.checked;
            rank.value = this.rankComparison.rankPosition;
            if (this.isRoot()) {
                this.save();
            }
        });
    }
}
