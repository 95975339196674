import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gfkNumberRange'
})
export class NumberRangePipe implements PipeTransform {
    transform(value: any): number[] {
        if (typeof value !== 'number') {
            return [];
        }

        const result = [];

        for (let i = 0; i < value; i++) {
            result.push(i);
        }

        return result;
    }
}
