import {
    BaseTablePluginOptions,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { Injector } from '@angular/core';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import { HeaderRemovedNotificationService } from 'insightui.table/ng1services/header-removed-notification.service';
import { ChannelComparisonModel } from 'insightui.core/models/reportSetting/channel-comparison.model';

/**
 * Removable Header Plugin is for removing column from table
 * This will be applied based on all enabled dimensions including
 * kpis, channels, posTypes and splitBy
 */
export class RemovableHeaderPlugin extends TableHeaderPlugin {
    name = 'RemovableHeaderPlugin';

    headerRemovedNotificationService: HeaderRemovedNotificationService;
    private pageDefinitionService: PageDefinitionService;
    private drillingService: DrillingService;
    private channelComparisonModel: ChannelComparisonModel;

    @VisualizationContextBinding('report')
    report;

    @VisualizationContextBinding('headerRemoved')
    headerRemoved;

    model: ChannelComparisonModel;

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions,
        protected table?: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        this.pageDefinitionService = this.injector.get(PageDefinitionService);
        this.drillingService = this.injector.get(DrillingService);
        this.headerRemovedNotificationService = this.injector.get(
            HeaderRemovedNotificationService
        );

        this.channelComparisonModel = this.injector.get(ChannelComparisonModel);
    }

    select(cell: DatatableHeaderCellComponent): boolean {
        return (
            cell instanceof DatatableHeaderCellComponent &&
            (this.isRemovableHeaderOf('kpi', cell) ||
                this.isRemovableHeaderOf('channel', cell) ||
                this.isRemovableHeaderOf('posType', cell) ||
                this.isRemovableSideHeader(cell))
        );
    }

    protected doEnable(cell: DatatableHeaderCellComponent) {
        const rootEl = cell.element.nativeElement;
        if (this.isActive(rootEl)) {
            return;
        }
        const headerLabel = rootEl.querySelector('.datatable-header-cell-label-wrapper');
        const removableHeaderElement: any = document.createElement('span');
        removableHeaderElement.title = 'Remove column';
        removableHeaderElement.classList.add('plugin-removable-header');
        removableHeaderElement.classList.add(
            'uitest-removable-header-' + (cell.columnHeader.field || cell.columnHeader.id)
        );
        headerLabel.appendChild(removableHeaderElement);

        removableHeaderElement.clickListener = this.clickListener.call(
            this,
            this.getType(cell),
            cell.columnHeader.id || cell.columnHeader.field
        );
        removableHeaderElement.addEventListener(
            'click',
            removableHeaderElement.clickListener.bind(this)
        );

        rootEl.mouseOverListener = () => {
            removableHeaderElement.classList.add('fa');
            removableHeaderElement.classList.add('fa-times');
            removableHeaderElement.classList.add('fade-header-icons');
            removableHeaderElement.style.opacity = 1;
        };
        rootEl.addEventListener('mouseenter', rootEl.mouseOverListener);

        rootEl.mouseOutListener = () => {
            removableHeaderElement.classList.remove('fa');
            removableHeaderElement.classList.remove('fa-times');
            removableHeaderElement.classList.remove('fade-header-icons');
            removableHeaderElement.style.opacity = 0;
        };
        rootEl.addEventListener('mouseleave', rootEl.mouseOutListener);

        this.markAsActive(rootEl);
    }

    protected doDisable(cell: DatatableHeaderCellComponent) {
        const rootEl = cell.element.nativeElement;
        const removableHeaderElement = rootEl.querySelector(
            '.datatable-header-cell-label-wrapper .plugin-removable-header'
        );

        if (!rootEl || !removableHeaderElement || !this.isActive(rootEl)) {
            return;
        }
        removableHeaderElement.removeEventListener(
            'click',
            removableHeaderElement.clickListener
        );

        rootEl.removeEventListener('mouseenter', rootEl.mouseOverListener);
        rootEl.removeEventListener('mouseleave', rootEl.mouseOutListener);

        this.markAsInactive(rootEl);
    }

    private getType(cell: DatatableHeaderCellComponent) {
        return (cell.columnHeader.dimension || 'side') + 'RemovableHeader';
    }

    private isRemovableHeaderOf(dimension: string, cell: DatatableHeaderCellComponent) {
        return (
            cell.columnHeader.dimension === dimension &&
            this.disabledDimensions[dimension].indexOf(cell.columnHeader.id) < 0
        );
    }

    private isRemovableSideHeader(cell: DatatableHeaderCellComponent) {
        return (
            !cell.columnHeader.dimension &&
            this.drillingService
                .getDrillLevel()
                .sideHeaders.indexOf(cell.columnHeader.field) < 0
        );
    }

    private clickListener(removableHeaderType, id) {
        return function(e) {
            e.stopPropagation();
            this.updateComparisonModel(id);
            this.headerRemoved = id;
            this.headerRemovedNotificationService.notify(removableHeaderType);
            // Since reload is required after postype remove so repopulateview can be skipped
            if (removableHeaderType !== 'posTypeRemovableHeader') {
                this.table.repopulateViewWithCurrentDataset();
            }
        };
    }

    private updateComparisonModel(id) {
        this.model = this.channelComparisonModel.clone();
        const selected = this.model.data.find(item => item.selected);
        if (selected.id === id) {
            const page = this.pageDefinitionService.getCurrentPage();
            const defaultTargetChannel = page.channelComparison.defaultTargetChannel;
            this.model.data.forEach(item =>
                item.id === defaultTargetChannel
                    ? (item.selected = true)
                    : (item.selected = false)
            );
            this.model.save();
        }
    }

    private get disabledDimensions() {
        const result = {};
        if (!this.report) {
            return result;
        }
        let rankKpis = [];
        if (this.report.rankingOptions) {
            rankKpis = this.report.rankingOptions.attributes.map(r => r.name);
        }
        return Object.assign(result, {
            kpi: this.report.kpiDefinition.disabled.concat(rankKpis),
            channel: this.report.channels.disabled,
            posType: this.report.posTypes.disabled
        });
    }

    extendExport(exportData) {
        return exportData;
    }
}
