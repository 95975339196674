import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';

import { FavouriteManagerState } from './state/favourite-manager.state';

@NgModule({
    imports: [NgxsModule.forFeature([FavouriteManagerState])]
})
export class FavouriteManagerRootModule {}
