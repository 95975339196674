import { Injectable } from '@angular/core';
import { ListSelection } from 'insightui.collectionmanager/models/list-selection';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';
import { Observable, of } from 'rxjs';
import { combineAll, distinct, flatMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ExportCollectionHeadlineService {
    constructor(private revisionService: RevisionService) {}

    /**
     * Returns Headline.
     *
     * @param headline headline to be directly returned
     * @returns a promise which will be resolved when the save action was completed successfully.
     */
    getHeadline(headline: string): string {
        return headline;
    }

    getSubHeadline$(
        selectedReports: ListSelection<ReportSnapshotDto>
    ): Observable<string> {
        return of(selectedReports.elements).pipe(
            flatMap(report => report),
            map(report => report.countryCode),
            distinct(),
            map(countryCode => this.getPeriodDisplayText$(countryCode)),
            combineAll(),
            map(subHeaderPart => subHeaderPart.join(', '))
        );
    }

    private getPeriodDisplayText$(countryCode: string): Observable<string> {
        return this.revisionService
            .getPeriodDisplayTextOfCurrentPeriod$(countryCode)
            .pipe(
                map(period => {
                    let entry = 'unknown';
                    if (countryCode) {
                        entry = countryCode;
                    }
                    if (period) {
                        entry = entry + '(' + period + ')';
                    }
                    return entry;
                })
            );
    }
}
