<gfk-fair-share-report
    *ngIf="!(hasNoDataLoaded$ | async); else noDataLoaded"
    [drillTargetName]="drillTargetName$ | async"
    [maxFairShareValue]="maxFairShareValue$ | async"
    [shareLineValue]="shareLineValue$ | async"
    [rows]="rows$ | async"
    [totalRow]="totalRow$ | async"
    [valueGrowthRangeBounds]="valueGrowthRangeBounds$ | async"
    [hasReachedValueGrowthRangeBounds]="hasReachedValueGrowthRangeBounds$ | async"
    (fairShareGapValueHoverRow)="handleFairShareHovering($event)"
    (growthGapValueHoverRow)="handleGrowthHovering($event)"
    (drill)="handleDrilling($event)"
>
</gfk-fair-share-report>

<ng-template #noDataLoaded>
    <gfk-no-data></gfk-no-data>
</ng-template>
