import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { InsightConfig, INSIGHT_CONFIG } from 'insightui.global/global.module';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { ExportHeadlineService } from 'insightui.export/services/export-headline.service';
import { ToastrService } from 'ngx-toastr';
import { ExportExcelData } from './excel-export.types';
import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';

@Injectable({ providedIn: 'root' })
export class ExcelExportService {
    private logger: ILogger;
    private _callback?: () => any;
    constructor(
        logService: LogService,
        private httpClient: HttpClient,
        private exportHeadlineService: ExportHeadlineService,
        @Inject(INSIGHT_CONFIG) private config: InsightConfig,
        private toastr: ToastrService,
        private analyticsService: AnalyticsService,
        private visualizationContextService: VisualizationContextService
    ) {
        this.logger = logService.getLogger('ExcelExportService');
        this.logger.debug(`ctor`);
    }

    triggerExport() {
        this.logger.debug(`triggering Excel Export`);
        if (this._callback) {
            const exportJson = this._callback();
            this.doExport(exportJson);
        } else {
            this.logger.warn(
                `could not export excel data because no ExcelExportDataGeneratorCallback is registered`
            );
        }
    }

    registerExcelExportDataGeneratorCallback(callback: () => ExportExcelData) {
        this._callback = callback;
        this.logger.debug(`registered data generator callback`, callback);
    }

    unregisterExcelExportDataGeneratorCallback(): void {
        this._callback = undefined;
        this.logger.debug(`unregistered data generator callback`);
    }

    private doExport(exportSpecification: ExportExcelData) {
        this.logger.debug(`starting export with`, exportSpecification);
        const url = this.config.services.FILE_EXPORT_SERVICE.url + 'api/v1/excel';

        const headers = new HttpHeaders()
            .set('Content-type', 'application/json; charset=UTF-8')
            .set(
                'Accept',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            );
        const response = this.httpClient.post(url, exportSpecification, {
            headers,
            responseType: 'blob',
            observe: 'body'
        });

        response.subscribe(
            (xlsData: any) => {
                this.analyticsService.trackEvent(
                    'Export',
                    'Powerpoint',
                    this.visualizationContextService.get('reportId')
                );
                const blob = new Blob([xlsData], {
                    type:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                });

                const filename = this.exportHeadlineService.getReportExportFilename();
                saveAs(blob, `${filename}.xlsx`);
            },
            error => {
                this.toastr.error(
                    'There was a problem while exporting to Excel. Please try again later.',
                    'Service unavailable'
                );
                this.logger.error(`Error while exporting to excel: ${error.error}`);
            }
        );
    }
}
