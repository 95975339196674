import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class ChartDataMergeService {
    mergeData(result, dataSet) {
        const fields = ['BID_ID', 'PG_ID', 'CY_ID', 'SG_ID', 'AR_ID', 'PF'];

        ['values', 'totals.total', 'totals.subTotal'].forEach(field => {
            const data = _.get(dataSet, field, []);
            _.forEach(data, d => {
                const re = _.find(result, r => {
                    return this.compareValue(d, r, fields);
                });
                if (!re) {
                    result.push(d);
                } else {
                    Object.keys(d).forEach(key => {
                        if (!re.hasOwnProperty(key)) {
                            re[key] = d[key];
                        }
                    });
                }
            });
        });
        return result;
    }

    private compareValue(a, b, fields) {
        for (let i = 0; i < fields.length; i++) {
            if (a[fields[i]] !== b[fields[i]]) {
                return false;
            }
        }
        return true;
    }
}
