import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import {
    INSIGHT_CONFIG,
    InsightConfig,
    APP_BUILD_VERSION
} from 'insightui.global/global.module';

class Page {
    id: string;
}

class DrillTarget {
    drillLevel: string;
    dimensionId: string;
}

class VisualizationContext {
    countryCode: string;
    drillTarget: DrillTarget;
    page: Page;
    reportId: string;
}

class ReportSettings {
    visualizationContext: VisualizationContext
}

class FavouriteResource {
    createdTime: string;
    id: string;
    lastAccessTime: string;
    modifiedTime: string;
    name: string;
    reportSettings: ReportSettings;
    status: string;

    isActive() {
        return !this.status || this.status === 'ACTIVE';
    };
}

@Injectable({
  providedIn: 'root'
})
export class FavouriteResourceApiService {

    constructor(private http: HttpClient, @Inject(INSIGHT_CONFIG) private insightUiConfig: InsightConfig) {
    }

    private static unescapeReportSettings(value: string): string {
        return new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
    }

    query(params: any) {
        const query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        const serviceUrl = `${this.insightUiConfig.services.USERPROFILE_SERVICE.url}/api/v2/favorites?${query}`;
        return this.http.get(serviceUrl).pipe(
            map((response: Response) => response),
            map((data: Response) => {
                const result: any[] = [];

                _.forEach(data, (favourite) => {
                    const favouriteResource = new FavouriteResource();
                    favouriteResource.createdTime = _.get(favourite, 'createdTime');
                    favouriteResource.id = _.get(favourite, 'id');
                    favouriteResource.lastAccessTime = _.get(favourite, 'lastAccessTime');
                    favouriteResource.modifiedTime = _.get(favourite, 'modifiedTime');
                    favouriteResource.name = _.get(favourite, 'name');
                    favouriteResource.status = _.get(favourite, 'status');

                    if (_.get(favourite, 'reportSettings')) {
                        const reportSettings = new ReportSettings();
                        const visualizationContext = new VisualizationContext();
                        reportSettings.visualizationContext = visualizationContext;
                        visualizationContext.countryCode = FavouriteResourceApiService.unescapeReportSettings(
                            favourite['reportSettings']['visualizationContext.countryCode']
                        );
                        visualizationContext.reportId = FavouriteResourceApiService.unescapeReportSettings(
                            favourite['reportSettings']['visualizationContext.reportId']
                        );

                        const page = new Page();
                        if (favourite['reportSettings']['visualizationContext.page']) {
                            const pageData = JSON.parse(FavouriteResourceApiService.unescapeReportSettings(
                                favourite['reportSettings']['visualizationContext.page']
                            ));

                            page.id = _.get(pageData, 'id');
                            visualizationContext.page = page;
                        }

                        const drillTarget = new DrillTarget();
                        if (favourite['reportSettings']['visualizationContext.drillTarget']) {
                            const drillTargetData = JSON.parse(FavouriteResourceApiService.unescapeReportSettings(
                                favourite['reportSettings']['visualizationContext.drillTarget']
                            ));

                            drillTarget.dimensionId = _.get(drillTargetData, 'dimensionId');
                            drillTarget.drillLevel = _.get(drillTargetData, 'drillLevel');

                            visualizationContext.drillTarget = drillTarget;
                        }

                        favouriteResource.reportSettings = reportSettings;
                    }

                    result.push(favouriteResource);
                });

                return result;
            })
        );
    }

   /* private createResource(transformedData, reformatString) {
        if (_.get(transformedData, 'reportSettings')) {
            transformedData.reportSettings = this.fromKeyValueStringObject(
                transformedData.reportSettings,
                reformatString
            );
        }

        return transformedData;
    }*/

    /*private fromKeyValueStringObject(keyValueFavourite, reformatString) {
        let keyValueObj = {};

        Object.keys(keyValueFavourite).forEach(function(key) {
            let value = keyValueFavourite[key];
            if (/^(true|false)$/.test(value)) {
                value = JSON.parse(value);
            }

            _.set(keyValueObj, key, /^[\[\{]/.test(value) ? angular.fromJson(value) : value);
        });

        if (reformatString) {
            if (_.get(keyValueObj, 'reportContext.product')) {
                let values = _.get(keyValueObj, 'reportContext.product').split(' > ');
                _.set(keyValueObj, 'reportContext.product',
                    values.length <= 4 ? values[values.length - 1] : values[3].concat(' (', values[4], ')')
                );
            }

            _.forEach(_.get(keyValueObj, 'reportContext'), (ct, key) => {
                _.set(keyValueObj.reportContext, key, ct.replace(/\n/g, ', '));
            });
        }

        keyValueObj.reportContext = new ($injector.get('ReportContext'))(
            keyValueObj.reportContext || {}
        );

        return keyValueObj;
    }*/
}
