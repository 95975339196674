import {
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    CollectionControllerService,
    CollectionDto,
    CollectionDtoCollectionType,
    CollectionDtoStatus,
    ReportSnapshotDto
} from 'insightui.openapi/userprofile';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
    filter,
    map,
    mapTo,
    mergeMap,
    share,
    switchMap,
    take,
    tap
} from 'rxjs/operators';
import { mapCollectionDtoArrayToSuccessAction } from '../state/collection-manager.handler';
import {
    AddToCollectionDialogComponent,
    AddToCollectionDialogResult
} from './add-to-collection-dialog/add-to-collection-dialog.component';
import { BackendProgress, IDLE } from './backendProgress';
import { FavouriteResourceService } from './favouriteResourceService/favouriteResource.service';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

type ReportAndCollection = {
    report: ReportSnapshotDto;
    collection: CollectionDto;
};

@Component({
    selector: 'add-to-collections-button',
    templateUrl: './addToCollectionsButton.component.html',
    styleUrls: ['./addToCollectionsButton.component.scss']
})
export class AddToCollectionsButtonComponent implements OnInit, OnDestroy {
    isDropdownVisible = false;

    isModalVisible = false;

    collections$ = new BehaviorSubject<CollectionDto[]>([]);

    temporaryCollection$ = new BehaviorSubject<CollectionDto>(null);

    lastProgress$: Observable<BackendProgress>;

    private _isAddToCollectionsNotAvailable$: BehaviorSubject<
        boolean
    > = new BehaviorSubject(false);
    get isAddToCollectionsNotAvailable$(): Observable<boolean> {
        return this._isAddToCollectionsNotAvailable$.asObservable();
    }

    @Input() newreporttitle = ''; // note: for some reason, it does not work to use camelCase when this input is set from an angularJS component.
    private componentDestroyed$ = new Subject<void>();
    private lastProgress: Subject<BackendProgress> = new BehaviorSubject<BackendProgress>(
        IDLE
    );
    private readonly MODAL_DIALOG_WIDTH = '600px';

    private readonly logger: ILogger;
    constructor(
        private matDialog: MatDialog,
        private favouriteResourceService: FavouriteResourceService,
        private collectionControllerService: CollectionControllerService,
        private store: Store,
        private elementRef: ElementRef,
        private toastr: ToastrService,
        logService: LogService
    ) {
        this.logger = logService.getLogger(`AddToCollegtionsButtonComponent`);
        this.lastProgress$ = this.lastProgress.asObservable();
        this.store
            .select(state => state.favouriteManager.loadingError)
            .subscribe(loadingError =>
                this._isAddToCollectionsNotAvailable$.next(loadingError)
            );
    }

    collectionTrackByFn(index: number, collection: CollectionDto) {
        return collection.id;
    }

    /**
     * Listen for all ClickEvents: When clicked outside of the Dropdown,
     * while opened, close the dropdown
     *
     */
    @HostListener('document:click', ['$event'])
    onOutsideClick($event: MouseEvent) {
        if (
            !this.elementRef.nativeElement.contains($event.target) &&
            this.isDropdownVisible &&
            !this.isModalVisible
        ) {
            this.toggleDropdown();
        }
    }

    /**
     * Listen for the ESC-Key press and close the dropdown
     *
     */
    @HostListener('document:keyup.esc')
    handleKeyboardEvent() {
        if (this.isDropdownVisible && !this.isModalVisible) {
            this.toggleDropdown();
        }
    }

    ngOnInit() {
        this.reloadCollections().subscribe();
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
    }

    /**
     * Click Callback of a collectionListItem
     *
     */
    collectionListItemCallback(collectionToAddTo: CollectionDto): void {
        const dialogRef = this.matDialog.open(AddToCollectionDialogComponent, {
            width: this.MODAL_DIALOG_WIDTH,
            autoFocus: false,
            data: {
                collectionName: collectionToAddTo.name,
                reportName: this.newreporttitle,
                headline: `Add to existing collection '${collectionToAddTo.name}'`
            }
        });
        this.isModalVisible = true;

        dialogRef
            .afterClosed()
            .pipe(
                tap(() => (this.isModalVisible = false)),
                filter(x => !!x),
                switchMap(result => {
                    return this.trackProgress(collectionToAddTo, () => {
                        return this.addReportSnapshotToCollection(
                            collectionToAddTo,
                            result.reportName
                        );
                    });
                })
            )
            .subscribe(
                x => {},
                err => {
                    this.logger.error('error while saving report to collection', err);
                    this.toastr.error(
                        'There was a problem while saving the report to the collection. Please try again later.',
                        'Service unavailable'
                    );
                }
            );
    }

    /**
     * Adds a Report to the backend with the help of
     * the reportSnapshotService
     *
     * @param addTo the collection the report will be added
     * @param  reportName the custom name of the report to add
     */
    addReportSnapshotToCollection(
        addTo: CollectionDto,
        reportName: string
    ): Observable<ReportAndCollection> {
        return this.collectionControllerService
            .createNewReport(addTo.id, {
                name: reportName,
                reportSnapshotSettings: this.favouriteResourceService.getFlattenedReportSettings()
            })
            .pipe(
                switchMap((report: ReportSnapshotDto) => {
                    return this.collectionControllerService
                        .getCollectionById(report.parentCollectionId)
                        .pipe(
                            map(collection => {
                                return { report, collection };
                            })
                        );
                })
            );
    }

    showModal() {
        const dialogRef = this.matDialog.open(AddToCollectionDialogComponent, {
            data: {
                reportName: this.newreporttitle
            },
            width: this.MODAL_DIALOG_WIDTH,
            autoFocus: false
        });
        this.isModalVisible = true;

        dialogRef
            .afterClosed()
            .pipe(
                tap(() => (this.isModalVisible = false)),
                filter(result => !!result),
                switchMap(result => this.addReportToNewCollectionCallback(result))
            )
            .subscribe(
                reportAndCollection => {},
                err => {
                    this.logger.error(
                        'Error while saving a report to a new collection',
                        err
                    );
                    this.toastr.error(
                        'There was a problem while saving the report to the new collection. Please try again later.',
                        'Service unavailable'
                    );
                }
            );
    }

    toggleDropdown() {
        if (this._isAddToCollectionsNotAvailable$.getValue() === false) {
            this.isDropdownVisible = !this.isDropdownVisible;
        }
    }

    /**
     * Adds a Report to a new collection
     *
     * @param modalDialogResult the click event
     */

    public addReportToNewCollectionCallback(
        modalDialogResult: AddToCollectionDialogResult
    ): Observable<ReportAndCollection> {
        const newCollection = this.selectCollection(modalDialogResult.collectionName);
        return this.trackProgress(newCollection, () => {
            const isNew = newCollection.id === null;
            let addReportToNewCollection$: Observable<{
                report: ReportSnapshotDto;
                collection: CollectionDto;
            }>;

            if (isNew) {
                addReportToNewCollection$ = this.collectionControllerService
                    .createCollection(newCollection)
                    .pipe(
                        mergeMap((collection: CollectionDto) => {
                            return this.addReportSnapshotToCollection(
                                collection,
                                modalDialogResult.reportName
                            );
                        }),
                        mergeMap(reportCollection => {
                            return this.reloadCollections().pipe(mapTo(reportCollection));
                        }),
                        share()
                    );
            } else {
                addReportToNewCollection$ = this.addReportSnapshotToCollection(
                    newCollection,
                    modalDialogResult.reportName
                ).pipe(share());
            }

            return addReportToNewCollection$;
        });
    }

    private reloadCollections(): Observable<void> {
        return this.collectionControllerService.getCollections().pipe(
            take(1),
            map(collectionDtos => mapCollectionDtoArrayToSuccessAction(collectionDtos)),
            tap(successAction => {
                this.temporaryCollection$.next(successAction.tempCollection);
                this.collections$.next(successAction.userCollections);
            }),
            mapTo(void 0)
        );
    }

    private trackProgress(
        collectionBeingSaved: CollectionDto,
        save: (collection: CollectionDto) => Observable<ReportAndCollection>
    ): Observable<ReportAndCollection> {
        this.lastProgress.next({
            status: 'START',
            collection: collectionBeingSaved
        });

        const saveAction$ = save(collectionBeingSaved);

        return saveAction$.pipe(
            tap(
                ({ collection }) => {
                    this.lastProgress.next({ status: 'SUCCESS', collection });
                },
                e => {
                    this.logger.error(
                        `Failed to save collection with id ${collectionBeingSaved.id} : ${e.message}`
                    );
                    this.lastProgress.next({
                        status: 'FAILED',
                        collection: collectionBeingSaved
                    });
                }
            )
        );
    }

    private selectCollection(collectionName: string): CollectionDto {
        let collection: CollectionDto | undefined = this.collections$.value
            .concat(this.temporaryCollection$.value)
            .find((coll: CollectionDto) => {
                return coll.name.toLowerCase() === collectionName.toLowerCase();
            });

        if (!collection) {
            collection = this.createNewCollectionAtLastPosition();
            collection.name = collectionName;
        }

        return collection;
    }

    /**
     * Creates a new collection which will be placed at the last position.
     */
    private createNewCollectionAtLastPosition(): CollectionDto {
        return {
            id: null,
            name: 'New Collection',
            reportSnapshots: [],
            collectionType: CollectionDtoCollectionType.USER,
            newCollectionName: 'New Collection',
            deletable: true,
            daysToDeletion: -1,
            status: CollectionDtoStatus.ACTIVE,
            createdTime: new Date(),
            lastAccessTime: new Date(),
            modifiedTime: new Date()
        } as CollectionDto;
    }
}
