import { Injector } from '@angular/core';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import {
    AdditionalColumnConfiguration,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';
import { asyncScheduler, Observable } from 'rxjs';
import { observeOn } from 'rxjs/operators';

export class HighLightSearchStringPlugin extends TableBodyPlugin {
    name = 'HighLightSearchStringPlugin';

    @NotificationSubscription(NotificationType.TableSearch)
    onTableSearch$: Observable<NotificationEvent>;

    constructor(
        protected injector: Injector,
        protected configuration: AdditionalColumnConfiguration
    ) {
        super(injector, configuration);
    }

    private static escapeRegexp(queryToEscape) {
        return queryToEscape.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
    }

    private static insertHighlights(cell, searchString) {
        if (
            cell.element &&
            cell.element.nativeElement &&
            cell.element.nativeElement.innerHTML
        ) {
            cell.element.nativeElement.innerHTML = String(cell.value).replace(
                new RegExp(HighLightSearchStringPlugin.escapeRegexp(searchString), 'gi'),
                '<mark>$&</mark>'
            );
        }
    }

    private static deleteAllHighlights(cell) {
        if (
            cell.element &&
            cell.element.nativeElement &&
            cell.element.nativeElement.innerHTML &&
            cell.element.nativeElement.innerHTML.indexOf('<mark>') > -1
        ) {
            cell.element.nativeElement.innerHTML = String(cell.value).replace(
                '<mark>',
                ''
            );
            cell.element.nativeElement.innerHTML = String(cell.value).replace(
                '</mark>',
                ''
            );
        }
    }

    private static isTotal(cell: Cell): boolean {
        return cell.row && cell.row.TG && cell.row.TG.indexOf('Total') >= 0;
    }

    select(cell: Cell): boolean {
        if (cell.column && cell.column.field && this.configuration.additionalColumns) {
            return this.configuration.additionalColumns.some(expression =>
                new RegExp(expression).test(cell.column.field)
            );
        }
        return false;
    }

    extendExport(exportData) {
        return exportData;
    }

    protected doEnable(cell: Cell) {
        this.onTableSearch$.pipe(observeOn(asyncScheduler)).subscribe(searchString => {
            if (
                searchString.data &&
                searchString.data !== '' &&
                !HighLightSearchStringPlugin.isTotal(cell)
            ) {
                HighLightSearchStringPlugin.insertHighlights(cell, searchString.data);
            } else {
                HighLightSearchStringPlugin.deleteAllHighlights(cell);
            }
        });
    }

    protected doDisable(cell: Cell) {
        HighLightSearchStringPlugin.deleteAllHighlights(cell);
    }
}
