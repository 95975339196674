/**
 * UserProfileService API Documentation
 * User Profile Service stores Favorites, Collections, Announcements and user-events.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { UserEventResponse } from '../model/user-event-response';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class UserEventControllerService {
    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Gets a user event by name
     * @param eventName eventName
     * @param pSpecifier free form parameters (not supported by openapi 2)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<UserEventResponse>;
    public getUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<UserEventResponse>>;
    public getUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<UserEventResponse>>;
    public getUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (eventName === null || eventName === undefined) {
            throw new Error(
                'Required parameter eventName was null or undefined when calling getUserEventByName.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (pSpecifier !== undefined && pSpecifier !== null) {
            queryParameters = queryParameters.set('pSpecifier', <any>pSpecifier);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<UserEventResponse>(
            `${this.configuration.basePath}/api/v2/events/${encodeURIComponent(
                String(eventName)
            )}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove a user event by name
     * @param eventName eventName
     * @param pSpecifier free form parusereveameters (not supported by openapi 2)
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<any>;
    public removeUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public removeUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public removeUserEventByName(
        eventName: string,
        pSpecifier?: object,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (eventName === null || eventName === undefined) {
            throw new Error(
                'Required parameter eventName was null or undefined when calling removeUserEventByName.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (pSpecifier !== undefined && pSpecifier !== null) {
            queryParameters = queryParameters.set('pSpecifier', <any>pSpecifier);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<any>(
            `${this.configuration.basePath}/api/v2/events/${encodeURIComponent(
                String(eventName)
            )}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates a user event by name
     * @param eventName eventName
     * @param pSpecifier pSpecifier
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserEventByName(
        eventName: string,
        pSpecifier?: { [key: string]: string },
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<UserEventResponse>;
    public updateUserEventByName(
        eventName: string,
        pSpecifier?: { [key: string]: string },
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<UserEventResponse>>;
    public updateUserEventByName(
        eventName: string,
        pSpecifier?: { [key: string]: string },
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<UserEventResponse>>;
    public updateUserEventByName(
        eventName: string,
        pSpecifier?: { [key: string]: string },
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (eventName === null || eventName === undefined) {
            throw new Error(
                'Required parameter eventName was null or undefined when calling updateUserEventByName.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json;charset=UTF-8'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<UserEventResponse>(
            `${this.configuration.basePath}/api/v2/events/${encodeURIComponent(
                String(eventName)
            )}`,
            pSpecifier,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
