<div class="value-share-line value-share-line--no-spacing">
    <div class="value-share-line__line"></div>
</div>
<div
    class="comparison-bar-chart"
    (mouseenter)="handleGapValueEnter()"
    (mouseleave)="handleGapValueLeave()"
>
    <div
        class="comparison-bar-chart__amount"
        [class.comparison-bar-chart__amount--dark]="useDarkColor"
        [class.comparison-bar-chart__amount--negative]="gapValuePercent < 0"
        [class.comparison-bar-chart__amount--negative-dark]="
            gapValuePercent < 0 && useDarkColor
        "
        [style.width.%]="
            (gapValuePercent / (maxValuePercent - minValuePercent)) * 100
                | gfkClamp: -50:50
                | gfkAbs
        "
    ></div>
    <div
        class="comparison-bar-chart__comparer"
        [class.comparison-bar-chart__comparer--negative]="comparisonPercent < 0"
        [style.width.%]="
            (comparisonPercent / (maxValuePercent - minValuePercent)) * 100
                | gfkClamp: -50:50
                | gfkAbs
        "
    ></div>
    <gfk-fair-share-growth-rate-hover
        *ngIf="showGapValueHover"
        [maxValuePercent]="maxValuePercent"
        [minValuePercent]="minValuePercent"
        [comparisonPercent]="comparisonPercent"
        [gapValuePercent]="gapValuePercent"
        [gapValue]="gapValue"
    >
    </gfk-fair-share-growth-rate-hover>
</div>
