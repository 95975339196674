import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

/**
 * Error Service bridge for Angular
 */
@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    errorService;

    constructor() {
        AngularInjectorResolver.waitForInjector().then(
            $injector => (this.errorService = $injector.get('ErrorService'))
        );
    }

    reportError(scope, reason) {
        this.errorService.reportError(scope, reason);
    }

    clearError(scope): Promise<void> {
        return this.errorService.clearError(scope);
    }
}
