/**
 * UserProfileService API Documentation
 * User Profile Service stores Favorites, Collections, Announcements and user-events.
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CollectionDto } from '../model/collection-dto';
import { CopyCollectionRequest } from '../model/copy-collection-request';
import { CopyMoveReportSnapshotsRequest } from '../model/copy-move-report-snapshots-request';
import { PostCollectionRequest } from '../model/post-collection-request';
import { PostReportSnapshotRequest } from '../model/post-report-snapshot-request';
import { PutCollectionRequest } from '../model/put-collection-request';
import { PutReportSnapshotRequest } from '../model/put-report-snapshot-request';
import { ReorderCollectionRequest } from '../model/reorder-collection-request';
import { ReorderReportSnapshotRequest } from '../model/reorder-report-snapshot-request';
import { ReportSnapshotDto } from '../model/report-snapshot-dto';
import { RestoreCollectionRequest } from '../model/restore-collection-request';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CollectionControllerService {
    protected basePath = 'http://localhost:8080';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Creates a new collection
     * @param putCollectionRequest putCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCollection(
        putCollectionRequest: PostCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public createCollection(
        putCollectionRequest: PostCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (putCollectionRequest === null || putCollectionRequest === undefined) {
            throw new Error(
                'Required parameter putCollectionRequest was null or undefined when calling createCollection.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections`,
            putCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Creates a new reportSnapshot
     * @param collectionId collectionId
     * @param postReportSnapshotRequest postReportSnapshotRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createNewReport(
        collectionId: string,
        postReportSnapshotRequest: PostReportSnapshotRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ReportSnapshotDto>;
    public createNewReport(
        collectionId: string,
        postReportSnapshotRequest: PostReportSnapshotRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling createNewReport.'
            );
        }
        if (
            postReportSnapshotRequest === null ||
            postReportSnapshotRequest === undefined
        ) {
            throw new Error(
                'Required parameter postReportSnapshotRequest was null or undefined when calling createNewReport.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ReportSnapshotDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/report-snapshots`,
            postReportSnapshotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Deletes a collection
     * @param collectionId collectionId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCollectionById(
        collectionId: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public deleteCollectionById(
        collectionId: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling deleteCollectionById.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Deletes a reportSnapshot
     * @param reportSnapshotId reportSnapshotId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReportById(
        reportSnapshotId: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<any>;
    public deleteReportById(
        reportSnapshotId: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling deleteReportById.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<any>(
            `${
                this.configuration.basePath
            }/api/v2/collections/report-snapshots/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Duplicates a collection by id
     * @param collectionId collectionId
     * @param copyCollectionRequest copyCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateCollectionById(
        collectionId: string,
        copyCollectionRequest: CopyCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public duplicateCollectionById(
        collectionId: string,
        copyCollectionRequest: CopyCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling duplicateCollectionById.'
            );
        }
        if (copyCollectionRequest === null || copyCollectionRequest === undefined) {
            throw new Error(
                'Required parameter copyCollectionRequest was null or undefined when calling duplicateCollectionById.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/copy`,
            copyCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Duplicates a reportSnapshot
     * @param collectionId collectionId
     * @param copyReportSnapshotsRequest copyReportSnapshotsRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public duplicateReports(
        collectionId: string,
        copyReportSnapshotsRequest: CopyMoveReportSnapshotsRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public duplicateReports(
        collectionId: string,
        copyReportSnapshotsRequest: CopyMoveReportSnapshotsRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling duplicateReports.'
            );
        }
        if (
            copyReportSnapshotsRequest === null ||
            copyReportSnapshotsRequest === undefined
        ) {
            throw new Error(
                'Required parameter copyReportSnapshotsRequest was null or undefined when calling duplicateReports.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/report-snapshots/copy`,
            copyReportSnapshotsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Gets a collection
     * @param collectionId collectionId
     * @param clientId clientId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCollectionById(
        collectionId: string,
        clientId?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public getCollectionById(
        collectionId: string,
        clientId?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling getCollectionById.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe: 'body',
                reportProgress
            }
        ).pipe(
            tap((collection: CollectionDto) => {
                if (!collection) {
                    return;
                }

                collection.reportSnapshots.forEach((report: ReportSnapshotDto) => this.unescapeReportSettings(report));

                return collection;
            })
        );
    }

    /**
     * Gets all collections
     * @param clientId clientId
     * @param status status
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCollections(
        clientId?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<Array<CollectionDto>>;
    public getCollections(
        clientId?: string,
        status?: 'ACTIVE' | 'DELETED' | '*',
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', status as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<Array<CollectionDto>>(
            `${this.configuration.basePath}/api/v2/collections`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe: 'body',
                reportProgress
            }
        ).pipe(
            tap((collections: Array<CollectionDto>) => {
                if (!collections) {
                    return;
                }

                collections.forEach((collection: CollectionDto) => {
                    collection.reportSnapshots.forEach((report: ReportSnapshotDto) => {
                        this.unescapeReportSettings(report);
                    });
                })

                return collections;
            })
        );
    }

    /**
     * Gets a reportSnapshot
     * @param reportSnapshotId reportSnapshotId
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportById(
        reportSnapshotId: string,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ReportSnapshotDto>;
    public getReportById(
        reportSnapshotId: string,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling getReportById.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<ReportSnapshotDto>(
            `${
                this.configuration.basePath
            }/api/v2/collections/report-snapshots/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe: 'body',
                reportProgress
            }
        ).pipe(
            tap((report: ReportSnapshotDto) => {
                if (!report) {
                    return;
                }

                this.unescapeReportSettings(report);

                return report;
            })
        );
    }

    /**
     * Marks a collection as viewed
     * @param collectionId collectionId
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public markCollectionAsViewed(
        collectionId: string,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<any>;
    public markCollectionAsViewed(
        collectionId: string,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling markCollectionAsViewed.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<any>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/markAsViewed`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Moves reportSnapshots
     * @param collectionId collectionId
     * @param moveReportSnapshotsRequest moveReportSnapshotsRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public moveReports(
        collectionId: string,
        moveReportSnapshotsRequest: CopyMoveReportSnapshotsRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public moveReports(
        collectionId: string,
        moveReportSnapshotsRequest: CopyMoveReportSnapshotsRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling moveReports.'
            );
        }
        if (
            moveReportSnapshotsRequest === null ||
            moveReportSnapshotsRequest === undefined
        ) {
            throw new Error(
                'Required parameter moveReportSnapshotsRequest was null or undefined when calling moveReports.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/report-snapshots/move`,
            moveReportSnapshotsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Reorders all collections
     * @param reorderCollectionRequest reorderCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderCollections(
        reorderCollectionRequest: ReorderCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<Array<CollectionDto>>;
    public reorderCollections(
        reorderCollectionRequest: ReorderCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reorderCollectionRequest === null || reorderCollectionRequest === undefined) {
            throw new Error(
                'Required parameter reorderCollectionRequest was null or undefined when calling reorderCollections.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<CollectionDto>>(
            `${this.configuration.basePath}/api/v2/collections/reorder`,
            reorderCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Reorder all reportSnapshots
     * @param collectionId collectionId
     * @param reorderReportSnapshotRequest reorderReportSnapshotRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reorderReports(
        collectionId: string,
        reorderReportSnapshotRequest: ReorderReportSnapshotRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public reorderReports(
        collectionId: string,
        reorderReportSnapshotRequest: ReorderReportSnapshotRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling reorderReports.'
            );
        }
        if (
            reorderReportSnapshotRequest === null ||
            reorderReportSnapshotRequest === undefined
        ) {
            throw new Error(
                'Required parameter reorderReportSnapshotRequest was null or undefined when calling reorderReports.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/report-snapshots/reorder`,
            reorderReportSnapshotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Restore a collection
     * @param collectionId collectionId
     * @param restoreCollectionRequest restoreCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreCollection(
        collectionId: string,
        restoreCollectionRequest: RestoreCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public restoreCollection(
        collectionId: string,
        restoreCollectionRequest: RestoreCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling restoreCollection.'
            );
        }
        if (restoreCollectionRequest === null || restoreCollectionRequest === undefined) {
            throw new Error(
                'Required parameter restoreCollectionRequest was null or undefined when calling restoreCollection.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/restore`,
            restoreCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Restores a reportSnapshot
     * @param reportSnapshotId reportSnapshotId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public restoreReport(
        reportSnapshotId: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ReportSnapshotDto>;
    public restoreReport(
        reportSnapshotId: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling restoreReport.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.post<ReportSnapshotDto>(
            `${
                this.configuration.basePath
            }/api/v2/collections/report-snapshots/${encodeURIComponent(
                String(reportSnapshotId)
            )}/restore`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Shares a collection
     * @param collectionId collectionId
     * @param copyCollectionRequest copyCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public shareCollection(
        collectionId: string,
        copyCollectionRequest: CopyCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public shareCollection(
        collectionId: string,
        copyCollectionRequest: CopyCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling shareCollection.'
            );
        }
        if (copyCollectionRequest === null || copyCollectionRequest === undefined) {
            throw new Error(
                'Required parameter copyCollectionRequest was null or undefined when calling shareCollection.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}/share`,
            copyCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Updates a collection
     * @param collectionId collectionId
     * @param postCollectionRequest postCollectionRequest
     * @param clientId clientId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCollectionById(
        collectionId: string,
        postCollectionRequest: PutCollectionRequest,
        clientId?: string,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<CollectionDto>;
    public updateCollectionById(
        collectionId: string,
        postCollectionRequest: PutCollectionRequest,
        clientId?: string,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (collectionId === null || collectionId === undefined) {
            throw new Error(
                'Required parameter collectionId was null or undefined when calling updateCollectionById.'
            );
        }
        if (postCollectionRequest === null || postCollectionRequest === undefined) {
            throw new Error(
                'Required parameter postCollectionRequest was null or undefined when calling updateCollectionById.'
            );
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (clientId !== undefined && clientId !== null) {
            queryParameters = queryParameters.set('clientId', clientId as any);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<CollectionDto>(
            `${this.configuration.basePath}/api/v2/collections/${encodeURIComponent(
                String(collectionId)
            )}`,
            postCollectionRequest,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     * Updates a reportSnapshot
     * @param reportSnapshotId reportSnapshotId
     * @param reportSnapshotRequest reportSnapshotRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateReportById(
        reportSnapshotId: string,
        reportSnapshotRequest: PutReportSnapshotRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<ReportSnapshotDto>;
    public updateReportById(
        reportSnapshotId: string,
        reportSnapshotRequest: PutReportSnapshotRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (reportSnapshotId === null || reportSnapshotId === undefined) {
            throw new Error(
                'Required parameter reportSnapshotId was null or undefined when calling updateReportById.'
            );
        }
        if (reportSnapshotRequest === null || reportSnapshotRequest === undefined) {
            throw new Error(
                'Required parameter reportSnapshotRequest was null or undefined when calling updateReportById.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ReportSnapshotDto>(
            `${
                this.configuration.basePath
            }/api/v2/collections/report-snapshots/${encodeURIComponent(
                String(reportSnapshotId)
            )}`,
            reportSnapshotRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    private unescapeReportSettings(dto: ReportSnapshotDto) {
        const escapedReportSettings = new Map(Object.entries(dto.reportSettings));

        escapedReportSettings.forEach((value: string, key: string) => {
            dto.reportSettings[key] = new DOMParser().parseFromString(value, 'text/html').documentElement.textContent;
        });
    }
}
