<div class="col-md-6">
    <h1 (mousemove)="onMouseMove($event)" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
        {{ overview.title }}
    </h1>
    <gfk-tooltip *ngIf="tooltipOpen" [isOpen]="tooltipOpen" [animation]="true" [positionX]="positionX"
                 [positionY]="positionY" [tooltip]="overview.tooltip">
    </gfk-tooltip>
    <gfk-select-favourite class="favourite-select-action uitest-Div-FSelect-Action" [chapter]="overview"
                          *ngIf="hasFavourite()" [favourites]="getFavourites()" [docId]="docId" [favouriteResolved]="favouriteResolved">
    </gfk-select-favourite>
    <ul>
        <li #li *ngFor="let question of overview.questions; let i = index" (mouseover)="setHighlight(i)"
            (mouseleave)="clearHighlight()">
            <a href="{{ addClientIdToLink('/report/' + question.id + '?docId=' + docId) }}"
               (click)="onReportClickFromLandingPage(question.id)" target="_blank"
               class="[ lpBusinessQuestion uitest-Lnk-LP-OpenReport-{{ question.id }} ]">
                {{ question.title }}
            </a>
        </li>
    </ul>
</div>
<div class="report-overview-img col-md-6">
    <a title="click to open '{{ firstItem().title }}'"
       href="{{ addClientIdToLink('/report/' + firstItem().id + '?docId=' + docId) }}"
       (click)="onReportClickFromLandingPage(firstItem().id)" target="_blank"
       class="[ uitest-Lnk-LP-OpenReport-{{ firstItem().id }} ]">
        <img #image width="300" (mouseover)="setHighlight(0)" (mouseleave)="clearHighlight()"
             [src]="imgSrc + overview.image" alt="{{ firstItem().title }}" />
    </a>
</div>
