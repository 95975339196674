import { BarChartStrategy } from 'insightui.export/powerpoint/charts/bar-chart/bar-chart-strategy';
import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { PowerpointExportSheet, PowerpointExportSheets } from 'insightui.export/types';

export class BarChartStrategyWithoutPeriod extends BarChartStrategy {
    constructor(chart: HTMLElement, chartToSheetService: ChartToSheetService) {
        super(chart, chartToSheetService);
    }

    getEdgeCase(): number {
        return 14;
    }

    getMaxItemsPerPage(): number {
        return 10;
    }

    getLastPages(lastPageIndex, remainingItems): PowerpointExportSheets {
        let sheets: PowerpointExportSheet[] = [];
        switch (remainingItems) {
            case 14:
            case 13:
                sheets.push(this.createSheetFromRange(7, lastPageIndex));
                sheets.push(this.createSheetFromRange(7, lastPageIndex + 7));
                break;
            case 11:
            case 12:
                sheets.push(this.createSheetFromRange(6, lastPageIndex));
                sheets.push(this.createSheetFromRange(6, lastPageIndex + 6));
                break;

            default:
                sheets.push(
                    this.createSheetFromRange(this.getMaxItemsPerPage(), lastPageIndex)
                );
                break;
        }

        return sheets;
    }
}
