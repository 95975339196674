import { Component, ElementRef, HostListener, Inject, OnInit } from '@angular/core';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { WINDOW } from 'insightui.global/global.module';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { SessionService } from 'insightui.session/session.service';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'gfk-top-meta-bar',
    templateUrl: './top-meta-bar.component.html',
    styleUrls: ['./top-meta-bar.component.scss'],
    providers: [
        {
            provide: '$modal',
            useFactory: ($injector: any) => $injector.get('$modal'),
            deps: ['$injector']
        }
    ]
})
export class TopMetaBarComponent implements OnInit {
    private logger: ILogger;
    userName$: Observable<string>;

    isLoggedIn$: Observable<boolean>;

    topMetaContainerTitle = 'Settings';

    productName: string = this.pageDefinitionService.getProductName();
    isHeaderOpen = false;
    isUserFeedBackModalOpen = false;
    modalOptions = {
        animation: true,
        templateUrl: 'assets/template/modal/user-feedback.tpl.html',
        controller: 'UserFeedbackModalController',
        controllerAs: 'feedback',
        windowClass: 'feedback-modal uitest-Dlg-SubmitFeedback',
        size: 'lg',
        backdrop: 'static'
    };

    constructor(
        @Inject('$modal') private $modal: any,
        @Inject(WINDOW) private window: Window,
        private pageDefinitionService: PageDefinitionService,
        private toastr: ToastrService,
        private elementRef: ElementRef,
        private sessionService: SessionService,
        private matomoTrackerService: AnalyticsService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('TopMetaBarComponent');
        this.logger.debug('ctor');
    }

    private annoyInternetExplorerUserWithMessage() {
        const isInternetExplorer = /msie\s|trident\//i.test(window.navigator.userAgent);

        if (isInternetExplorer) {
            setTimeout(() => {
                const config: Partial<IndividualConfig> = {
                    enableHtml: true,
                    timeOut: 20000,
                    closeButton: true
                };

                this.toastr.warning(
                    `<p>RetailScout has detected that you are using Internet Explorer.</p>
                            <p><strong>This browser will not be updated anymore</strong>.</p>
                            <p>We strongly recommend to switch to another browser such as Chrome, Firefox or Edge
                            to keep all the functionality we provide, as we will not support Internet Explorer any more starting April 30th.</p>`,
                    'Support of Internet Explorer',
                    config
                );
            }, 2000);
        }
    }

    private closeHeaderContainer() {
        this.isHeaderOpen = false;
    }

    logout() {
        this.matomoTrackerService.trackEvent('Navigation', 'ActiveLogout');
        this.sessionService.logout();
    }

    goHome() {
        let home = this.window.location.protocol + '//' + this.window.location.hostname;

        if (this.window.location.port !== '80' && this.window.location.port !== '443') {
            home = home + ':' + this.window.location.port;
        }

        home = preserveClientId(home);

        this.window.open(home, '_blank');

        this.closeHeaderContainer();
    }

    toggle() {
        this.isHeaderOpen = !this.isHeaderOpen;
    }

    openUserFeedback() {
        this.isUserFeedBackModalOpen = true;
        this.$modal
            .open(this.modalOptions)
            .result.finally(() => (this.isUserFeedBackModalOpen = false));
    }

    @HostListener('document:click', ['$event'])
    onOutSideClick($event: MouseEvent) {
        if (
            !this.isHeaderOpen &&
            !this.elementRef.nativeElement.contains($event.target)
        ) {
            this.closeHeaderContainer();
        }
    }

    @HostListener('document:keyup.esc')
    handleKeyboardEvent() {
        this.closeHeaderContainer();
    }

    ngOnInit(): void {
        this.userName$ = this.sessionService.currentUserDetails().pipe(
            map(userDetails => {
                if (userDetails.fullName && userDetails.fullName.length > 0) {
                    return userDetails.fullName;
                } else if (userDetails.userName) {
                    return userDetails.userName;
                } else {
                    this.logger.error('no username returned');
                    return '';
                }
            })
        );

        this.isLoggedIn$ = this.userName$.pipe(map(name => !!name));

        this.annoyInternetExplorerUserWithMessage();
    }
}
