import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { WINDOW } from 'insightui.global/global.module';
import { BusinessQuestionSubEntry } from '../business-question/business-question.component';
import {
    legacyDrillingNotificationServiceProvider,
    legacyFilterNotificationServiceProvider,
    legacySegmentationNotificationServiceProvider
} from './ajs-upgraded-providers';
import { BusinessQuestionService } from './business-question.service';
import { LegacyNotificationService } from './legacy-notification.service';

@Component({
    selector: 'gfk-business-question-wrapper',
    template: `
        <gfk-business-question
            [reportName]="bcService.reportName$ | async"
            [businessQuestion]="bcService.businessQuestion$ | async"
            [subEntries]="bcService.subEntries$ | async"
            (subEntryClick)="onSelectSubEntry($event)"
        ></gfk-business-question>
    `,
    styles: [
        `
            :host {
                display: inline-block;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [
        BusinessQuestionService,
        legacyDrillingNotificationServiceProvider,
        legacyFilterNotificationServiceProvider,
        legacySegmentationNotificationServiceProvider,
        LegacyNotificationService
    ]
})
export class BusinessQuestionWrapperComponent {
    constructor(
        public bcService: BusinessQuestionService,
        private activatedRoute: ActivatedRoute,
        @Inject(WINDOW) private window: Window
    ) {}

    onSelectSubEntry(entry: BusinessQuestionSubEntry) {
        const docId = this.getDocId();
        // Note: we don't preserve the collectionId here because if we did we would see the
        // little X for closing the view mode but not the arrows for navigating back and forth
        // because we have no information about the current collection any more...
        this.window.location.href = preserveClientId(
            `/report/${entry.pageId}?docId=${docId}`
        );
    }

    private getDocId(): string {
        const docId = this.activatedRoute.snapshot.queryParamMap.get('docId');
        if (!docId) {
            throw new Error(
                `Cannot navigate to new url because no docId was found in the existing url`
            );
        }
        return docId;
    }
}
