import { ILogger } from './log.service';
import { OperatorFunction, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export function log<T>(message?: string, logger?: ILogger): OperatorFunction<T, T> {
    // tslint:disable-next-line: only-arrow-functions
    return function(source$: Observable<T>): Observable<T> {
        // tslint:disable-next-line: no-console
        const logDebugMethod = logger ? logger.debug.bind(logger) : console.debug;
        const logWarnMethod = logger ? logger.warn.bind(logger) : console.warn;

        return source$.pipe(
            tap(
                streamValue => logDebugMethod(`${message}, value:`, streamValue),
                err => logWarnMethod(`${message}, error:`, err),
                () => logDebugMethod(`${message}, COMPLETE`)
            )
        );
    };
}
