import { Injectable, Injector } from '@angular/core';
import { DrillingCellPlugin } from 'insightui.table/components/plugins/drilling/drilling-cell.plugin';
import { TablePlugin } from 'insightui.table/components/plugins/shared/table-plugin.models';
import { DrillingHeaderPlugin } from 'insightui.table/components/plugins/drilling/drilling-header.plugin';
import { SupplementaryKpiPlugin } from 'insightui.table/components/plugins/supplementaryKpi/supplementary-kpi.plugin';
import { FeatureTooltipPlugin } from 'insightui.table/components/plugins/featureTooltip/feature-tooltip.plugin';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { HeaderComponentPlugin } from 'insightui.table/components/plugins/headerDropdown/header-component.plugin';
import { SortableHeaderPlugin } from 'insightui.table/components/plugins/sortableHeader/sortable-header.plugin';
import { NotRepresentedHighlightCellPlugin } from 'insightui.table/components/plugins/notRepresentedHighlight/not-represented-highlight.plugin';
import { SubTotalLabelPlugin } from 'insightui.table/components/plugins/subTotalLabel/subtotal-label.plugin';
import { FilteredTotalPlugin } from 'insightui.table/components/plugins/filteredTotal/filtered-total.plugin';
import { KpiTooltipPlugin } from 'insightui.table/components/plugins/kpiTooltip/kpi-tooltip.plugin';
import { RowSelectionTablePlugin } from 'insightui.table/components/plugins/rowSelection/row-selection.plugin';
import { BrandHighLightPlugin } from 'insightui.table/components/plugins/brandHighlight/brand-highlight.plugin';
import { RowHoverPlugin } from 'insightui.table/components/plugins/rowHover/row-hover.plugin';
import { RemovableHeaderPlugin } from 'insightui.table/components/plugins/removableHeader/removable-header.plugin';
import { HeaderLabelPlugin } from 'insightui.table/components/plugins/headerLabel/header-label.plugin';
import { HighLightSearchStringPlugin } from 'insightui.table/components/plugins/highlightSearchString/highlight-searchstring.plugin';

/**
 * Table Plugin Factory Service for creating plugins.
 */
@Injectable()
export class TablePluginFactory {
    private SUPPORTED_PLUGINS = {
        drilling: [DrillingHeaderPlugin, DrillingCellPlugin],
        supplementaryKpi: [SupplementaryKpiPlugin],
        featureTooltip: [FeatureTooltipPlugin],
        kpiTooltip: [KpiTooltipPlugin],
        component: [HeaderComponentPlugin],
        sortable: [SortableHeaderPlugin],
        notRepresentedHighlight: [NotRepresentedHighlightCellPlugin],
        filteredTotal: [FilteredTotalPlugin],
        subTotalLabel: [SubTotalLabelPlugin],
        rowSelection: [RowSelectionTablePlugin],
        brandHighlight: [BrandHighLightPlugin],
        rowHover: [RowHoverPlugin],
        removableHeader: [RemovableHeaderPlugin, HeaderLabelPlugin],
        highlightSearchString: [HighLightSearchStringPlugin]
    };

    constructor(private injector: Injector) {}

    public createPlugin(
        name: string,
        configuration,
        table?: AuthoringDatatableComponent
    ): TablePlugin[] {
        if (!this.SUPPORTED_PLUGINS[name]) {
            console.warn(
                `Unknown plugin ${name} in table, supported: ${Object.keys(
                    this.SUPPORTED_PLUGINS
                )}}`
            );
            return [new UnknownTablePlugin(this.injector, {})];
        }
        return this.SUPPORTED_PLUGINS[name].map(
            plugin => new plugin(this.injector, configuration || {}, table)
        );
    }
}

class UnknownTablePlugin extends TablePlugin {
    name = 'Unknown Table Plugin';

    protected doEnable(cell) {
        return null;
    }

    protected doDisable(cell) {
        return null;
    }

    select(cell): boolean {
        return false;
    }

    extendExport(exportData) {
        return exportData;
    }
}
