export interface ValidationResult {
    hasFailed(): boolean;
    getMessages(): string[];
}

export class ValidationSuccess<A> implements ValidationResult {
    private value: A;

    private constructor() {}

    static of<B>(b: B): ValidationSuccess<B> {
        const validation = new ValidationSuccess<B>();
        validation.value = b;
        return validation;
    }

    getValue(): A {
        return this.value;
    }

    hasFailed(): boolean {
        return false;
    }

    getMessages(): string[] {
        return [];
    }
}

export class ValidationError implements ValidationResult {
    private messages: string[];

    private constructor() {}

    static of(messages: string[]): ValidationError {
        const validation = new ValidationError();
        validation.messages = messages;
        return validation;
    }

    getMessages(): string[] {
        return this.messages;
    }

    hasFailed(): boolean {
        return true;
    }
}

export class Validation<A> {
    private validateFn: (a: A) => ValidationResult;

    private constructor() {}

    static rule<B>(msg: string, predicate: (b: B) => boolean): Validation<B> {
        const validation = new Validation<B>();
        validation.validateFn = (b: B) => {
            const isValid = predicate(b);
            if (!isValid) {
                return ValidationError.of([msg]);
            } else {
                return ValidationSuccess.of<B>(b);
            }
        };

        return validation;
    }

    static invalid<B>(message: string): Validation<B> {
        const validation = new Validation<B>();
        validation.validateFn = () => ValidationError.of([message]);

        return validation;
    }

    static valid<B>(): Validation<B> {
        const validation = new Validation<B>();
        validation.validateFn = (b: B) => ValidationSuccess.of<B>(b);

        return validation;
    }

    andThen(nextValidation: Validation<A>): Validation<A> {
        const newValidation = new Validation<A>();
        newValidation.validateFn = (a: A) => {
            const nextValidationRes = nextValidation.validate(a);
            const theValidationRes = this.validate(a);
            let messages = [];
            if (theValidationRes.hasFailed()) {
                messages = messages.concat(theValidationRes.getMessages());
            }
            if (nextValidationRes.hasFailed()) {
                messages = messages.concat(nextValidationRes.getMessages());
            }

            if (messages.length > 0) {
                return ValidationError.of(messages);
            }
            return ValidationSuccess.of<A>(a);
        };

        return newValidation;
    }

    changeInput<B>(f: (b: B) => A): Validation<B> {
        const newValidation = new Validation<B>();
        newValidation.validateFn = (b: B) => this.validateFn(f(b));
        return newValidation;
    }

    validate(a: A): ValidationResult {
        return this.validateFn(a);
    }
}
