import { NgRedux } from '@angular-redux/store';
import { Store } from '@ngxs/store';
import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { ContextSerializationService } from 'insightui.core/shared/contextSerialization.service';
import { FairShareViewComponent } from 'insightui.fair-share/fair-share-view/fair-share-view.component';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { ChartViewComponent } from 'insightui.report-performance-chart/app/chart-view/chart-view.component';
import { AppRootState } from 'insightui.state/app-root.state.interface';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { Subject } from 'rxjs';
import { distinctUntilChanged, map, take, takeUntil } from 'rxjs/operators';
import { LoadStartupDataService } from './LoadStartupDataService';
import { FILTER_INIT } from './report-filter.actions';

/**
 * A view for a router wrapping the child route with a report filter.
 */
@Component({
    templateUrl: './report-with-filter-view.component.html'
})
export class ReportWithFilterViewComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('reportViewContainer', { read: ViewContainerRef, static: true })
    reportViewContainer!: ViewContainerRef;

    componentRef: ComponentRef<any>;

    page: any;

    private logger: ILogger;
    private killer = new Subject<void>();

    private loadStartupDataService: LoadStartupDataService;

    constructor(
        private resolver: ComponentFactoryResolver,
        private pageDefinitionService: PageDefinitionService,
        logService: LogService,
        private router: Router,
        private route: ActivatedRoute,
        private contextSerializationService: ContextSerializationService,
        private title: Title,
        private ngRedux: NgRedux<AppRootState>,
        private store: Store,
        ng1InjectorResolver: AngularInjectorResolver,
        private matomoTrackerService: AnalyticsService
    ) {
        this.logger = logService.getLogger('ReportWithFilterViewComponent');
        this.logger.debug('ctor');

        ng1InjectorResolver
            .get()
            .pipe(take(1))
            .subscribe(
                ng1Inj =>
                    (this.loadStartupDataService = ng1Inj.instantiate<
                        LoadStartupDataService
                    >(LoadStartupDataService))
            );
    }

    ngOnInit() {
        this.logger.debug('ngOnInit()');
        this.route.queryParamMap
            .pipe(
                map(pm => pm.get('docId')),
                takeUntil(this.killer),
                distinctUntilChanged()
            )
            .subscribe(docIdFromUrl => {
                this.logger.info('Got new docId from URL', docIdFromUrl);
                this.initPageData(docIdFromUrl);
            });
    }

    ngAfterViewInit() {}

    ngOnDestroy() {
        this.logger.debug('ngOnDestroy()');
        if (this.componentRef) {
            this.componentRef.destroy();
        }
        this.killer.next();
    }

    private initPageData(docId: string) {
        const pageId = this.route.snapshot.paramMap.get('reportId');
        const reportSnapshotId = this.route.snapshot.paramMap.get('reportSnapshotId');
        const visualizationContext = reportSnapshotId
            ? null
            : this.visualizationContextFromUrl();

        this.logger.debug(`pageId: ${pageId}`);
        this.logger.debug(`docId: ${docId}`);
        this.logger.debug(`reportSnapshotId: ${reportSnapshotId}`);
        this.logger.debug(`visualizationContext: ${visualizationContext}`);

        if (!docId) {
            this.reloadWithDocId();
            return;
        }

        let bootstrap: Promise<any>;

        if (visualizationContext) {
            // this is any route including a serialized visualization context
            bootstrap = this.loadStartupDataService.loadReportDataWithContext(
                docId,
                pageId,
                visualizationContext
            );
        } else if (reportSnapshotId) {
            // this is a favorite (pure or part of a collection)
            bootstrap = this.loadStartupDataService.loadReportDataWithFavorite(
                docId,
                reportSnapshotId
            );
        } else {
            // this is an ordinary chart (datatable, chart, fair-share)
            bootstrap = this.loadStartupDataService.loadReportData(docId, pageId);
        }

        bootstrap
            .then(
                visContext => {
                    this.clearVisualizationContextFromUrlAfterNavigation();

                    this.logger.debug('dispatch FILTER_INIT', visContext);
                    const page = visContext.page;
                    if (page) {
                        this.title.setTitle(page.title);
                    } else {
                        this.title.setTitle(this.route.snapshot.data.title);
                    }

                    this.ngRedux.dispatch(FILTER_INIT(visContext));

                    try {
                        // get page again from service because it is wrapped with additional accessors
                        // even though it should be the same as visContext.page
                        this.page = this.pageDefinitionService.getCurrentPage();
                        if (this.page) {
                            const pageType =
                                this.page.visualizationType === 'datatable'
                                    ? 'datatable'
                                    : this.page.id;
                            this.createComponent(pageType);
                            this.matomoTrackerService.pageTrack();
                            this.matomoTrackerService.trackEvent(
                                'Navigation',
                                'ReportView',
                                this.page.id
                            );
                        }
                    } catch (error) {
                        this.logger.error(
                            'initPageData bootstrap finished with error',
                            error
                        );
                    }
                },
                rejected => {
                    if (rejected && rejected.newDocId) {
                        this.logger.debug('need to change the docId', rejected.newDocId);
                        this.navigateWithQueryParams({ docId: rejected.newDocId });
                    } else {
                        this.logger.error(
                            `bootstrap promise was rejected, but no newDocId was given.`,
                            rejected
                        );
                    }
                }
            )
            .catch(error => {
                this.logger.warn('initPageData finished with error:', error);
            });
    }

    private reloadWithDocId() {
        // let's load landing page data to determine the first meaningful period
        this.loadStartupDataService.loadLandingPageData(null, null).then(() => {
            this.store
                .selectOnce(s => s.metadata.currentDocumentRevisionId)
                .subscribe(currentDocId => {
                    this.navigateWithQueryParams({ docId: currentDocId });
                });
        });
    }

    private visualizationContextFromUrl(): object | null {
        const vc = this.route.snapshot.queryParamMap.get('vc');
        if (vc === null) {
            return null;
        }
        const result = this.contextSerializationService.deserializeParsedVc(vc);
        this.logger.debug('deserializeParsedVc', { input: vc, result });
        return result;
    }

    private clearVisualizationContextFromUrlAfterNavigation(): void {
        this.logger.debug('clearVisualizationContextFromUrlAfterNavigation');

        if (this.route.snapshot.queryParamMap.has('vc')) {
            this.navigateWithQueryParams({ vc: null });
        }
    }

    private createComponent(pageType: string) {
        if (this.componentRef) {
            this.logger.debug(
                'Skipped creation of component, re-using old one',
                this.componentRef
            );
            return;
        }

        this.reportViewContainer.clear();

        this.logger.debug('pageType', pageType);

        const factory =
            pageType === 'fair-share'
                ? this.resolver.resolveComponentFactory(FairShareViewComponent)
                : pageType === 'datatable'
                ? this.resolver.resolveComponentFactory(AuthoringDatatableComponent)
                : this.resolver.resolveComponentFactory(ChartViewComponent);

        this.componentRef = this.reportViewContainer.createComponent(factory);
    }

    private navigateWithQueryParams(newParams: Params) {
        this.logger.debug('changing query param:', newParams);
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: newParams,
            queryParamsHandling: 'merge'
        });
    }
}
