import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

import { AccessControlService } from 'insightui.metadata/services/access-control.service';
import { AnalyticsService } from 'insightui.core/services/analytics.service';
import { ErrorService } from 'insightui.error/error.service';
import { ILogger, LogService } from 'insightui.core/services/logging';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { NewLoadStatusUpdatesService } from 'insightui.core/services/newLoadStatusUpdatesService';
import { Ng1ReduxService } from 'insightui.core/services/ng1-redux.service';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { ReportFavouriteModelService } from 'insightui.core/services/reportFavouriteModel.service';
import { UtilService } from 'insightui.core/services/util.service';

@Component({
    selector: 'gfk-landing-page',
    templateUrl: './new-landing-page.component.html',
    styleUrls: ['./new-landing-page.component.scss']
})
export class NewLandingPageComponent implements OnInit, OnDestroy {

    chapters: any;
    countries = [];
    docId: any;
    favourites: any;
    groups: any;
    loadStatusUpdates = [];
    metadataVersions: any;
    minQuestions: any;
    overviews: any;
    pages: any;
    period: number;
    periods: any[];
    selectedCountry = null;
    selectedCountryName = 'Country';
    dropDownCountryVisible: boolean;
    private logger: ILogger;

    applyDisabled: boolean;

    private readonly subs: Subscription;

    constructor(
        private route: ActivatedRoute,
        private store: Store,
        private ngRedux: Ng1ReduxService,
        private metaDataService: MetadataService,
        private pageDefinitionService: PageDefinitionService,
        private errorService: ErrorService,
        private accessControlService: AccessControlService,
        private matomoTrackerService: AnalyticsService,
        private reportFavouriteModelService: ReportFavouriteModelService,
        private logService: LogService,
        private utilService: UtilService,
        private newLoadStatusUpdatesService: NewLoadStatusUpdatesService
    ) {
        this.periods = [];
        this.subs = new Subscription();
        this.logger = logService.getLogger('Landing Page');
    }

    private static isHiddenPage(chapter, id) {
        return (chapter.hidden || []).indexOf(id) > -1;
    }

    private static addBusinessQuestion(pages, pageId) {
        return _.get(pages, [pageId, 'title']);
    }

    ngOnDestroy() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
    }

    ngOnInit() {
        const datePipe = new DatePipe('en-US');
        this.registerOpenFavourite();
        this.registerOpenLoadStatus();

        this.route.queryParams.subscribe(params => {
            this.period = (params['period']) ? +params['period'] : 0;
        });

        const countriesFromStore = this.ngRedux.ngxsStore
            .select(state => state.metadata.countries)
            .pipe(
                filter(countries => countries && countries.length > 0),
                take(1)
            );

        countriesFromStore.subscribe(countries => {
            // AngularJS adds properties, so we have to clone objects from state
            this.countries = _.cloneDeep(countries);
            this.initCountries(this.countries);
        });

        const deliveryInfo = this.metaDataService.getDeliveryInfo();

        if (!deliveryInfo) {
            // if this happens, there is no delivery for the selected country
            // stop here and diplay an error page (with country selector)
            this.periods = [];
            this.errorService.reportError('landingpage-nodelivery', {});
            return;
        }

        this.reportFavouriteModelService.favouritesLoaded.subscribe(() => {
            this.favourites = this.reportFavouriteModelService.groupByPageId();
        });

        const indexState = this.pageDefinitionService.getIndex();

        this.chapters = _.map(indexState.pageIndices, 'chapters');
        this.pages = indexState.pages;
        this.metadataVersions = deliveryInfo.version;
        this.docId = this.metaDataService.getDocRevId();
        this.logger.debug(`docRevId is ${this.docId}`);

        this.groups = this.getGroups(indexState);
        this.overviews = this.getOverviews(indexState);

        this.minQuestions = Math.min(
            _.get(this.overviews, [0, 'questions'], []).length,
            _.get(this.overviews, [1, 'questions'], []).length
        );

        this.ngRedux.ngxsStore
            .select(state => state.metadata.revisions)
            .pipe(
                filter(revisions => revisions && revisions.length > 0),
                take(1)
            )
            .subscribe(revisions => {
                const currentCountry = this.metaDataService.getDeliveryInfo().country;
                let counter = 0;
                this.periods = revisions
                    .filter(rev => rev.regionIsoCode === currentCountry)
                    .sort((first, second) => first.periodId - second.periodId)
                    .map(revision => {
                        const period = revision.periodId.toString();
                        const year = period.substring(0, 4);
                        const month = _.parseInt(period.substring(4, 6)) - 1;
                        return {
                            periodId: counter++,
                            periodStr: datePipe.transform(new Date(year, month), 'MMM yy').toUpperCase(),
                            hasData: true
                        };
                    })
                    .reverse();

                this.logger.debug(`got revisions:`, this.periods);
            });

        this.ngRedux.ngxsStore
            .select(state => state.metadata.loadStatusInfos)
            .pipe(
                filter(loadStatusInfos => loadStatusInfos),
                take(1)
            )
            .subscribe(loadStatusInfos => {
                this.logger.debug(`loadStatusInfos`, loadStatusInfos);

                let counter = 0;
                this.periods = _.map(loadStatusInfos, loadStatusInfo => ({
                    periodId: counter++,
                    periodStr: loadStatusInfo.periodStr,
                    hasData: loadStatusInfo.hasAvailableData
                })).reverse();

                this.logger.debug(`this.periods`, this.periods);
            });

        this.newLoadStatusUpdatesService.getNewlyLoaded().subscribe(updates => {
            Promise.all(updates).then((update) => {
                // special condition: if a delivery exists and is active, but all PGs
                // are on status 'notAvailable' (= status 4), then switch to previous period
                let index = _.findIndex(update, u => u.active);
                if (!update[index].hasAvailableData) {
                    index = _.findIndex(update, u => u.hasAvailableData);
                    if (index < 0) {
                        this.errorService.reportError('landingpage-nodelivery', {});
                    }

                    if (index > -1 && this.utilService.getUrlParam('period') !== index.toString()) {
                        // switch to period
                        this.utilService.openPage(_.get(update, [index, 'countryCode']), index);
                    }
                }

                this.loadStatusUpdates = [...update].reverse();

                _.forEach(this.loadStatusUpdates, (item: any) => {
                    item.show = _.get(item, 'show', false)
                });
            });
        });
    }

    selectPeriod(pPeriod) {
        const per = _.find(this.periods, (p) => {
            return _.get(p, 'periodId') === pPeriod.periodId;
        });

        if (this.period !== per.periodId && pPeriod.hasData) {
            this.matomoTrackerService.trackEvent(
                'Settings',
                'SelectPeriod',
                `${pPeriod.periodId}|${this.selectedCountry}`
            );

            const url = _.head(window.location.href.split('?'));
            window.location.href = preserveClientId(`${url}?country=${this.selectedCountry}&period=${pPeriod.periodId}`);
        }
    }

    getTotalUpdates(period) {
        return (
            _.get(period, 'result.sector', []).length +
            _.get(period, 'result.category', []).length +
            _.get(period, 'result.productGroup', []).length
        );
    };

    selectCountry(country) {
        this.selectedCountry = country.countryCode;
    };

    countryOnBlur() {
        this.dropDownCountryVisible = false;
    }

    countryToggle() {
        this.initCountries(this.countries);
        if (this.countries.length > 1) {
            this.dropDownCountryVisible = !this.dropDownCountryVisible;
        }
    }

    countryOnApply() {
        this.matomoTrackerService.trackEvent(
            'Settings',
            'SelectCountry',
            `LandingPage => ${this.selectedCountry}`
        );

        let url = _.head(window.location.href.split('?')) +
            '?country=' +
            this.selectedCountry;

        if (this.utilService.getUrlParam('period')) {
            url += '&period=' + this.utilService.getUrlParam('period');
        }

        window.location.href = preserveClientId(url);
        this.dropDownCountryVisible = false;
    };

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (!event.target.classList.contains('c-icon--favourite-flyout')) {
            this.resetOpenFavourite();
        }
        if (!event.target.classList.contains('load-status-open')) {
            this.resetOpenLoadStatus();
        }
    }

    private getOverviews(indexState) {
        return _(indexState.pageIndices)
            .filter('teaser')
            .map(group => {
                const chapters = group.chapters;
                chapters.forEach((chapter) => {
                    chapter.groupTitle = group.title;
                });
                return chapters;
            })
            .flatten()
            .map(chapter => {
                return {
                    title: _.get(chapter, 'groupTitle'),
                    image: _.get(chapter, 'image'),
                    tooltip: _.get(chapter, 'tooltip'),
                    show: _.get(chapter, 'show', false),
                    questions: _.compact(
                        _.get(chapter, 'pageIds', []).map(pageId => {
                            const page = _.get(indexState.pages, [pageId]);
                            if (!this.accessControlService.hasPageAccess(this.metaDataService.getDeliveryInfo(), page)
                                || NewLandingPageComponent.isHiddenPage(chapter, pageId)) {
                                return null;
                            }

                            return {
                                id: pageId,
                                title: page.title,
                                titleHtml: page.title
                            };
                        })
                    )
                };
            })
            .value();
    }

    private getGroups(indexState) {
        const result = [];
        const groups = _.filter(indexState.pageIndices, (group) => {
            return _.get(group, 'chapters').length && !group.teaser;
        });

        _.forEach(groups, (group) => {
            const chapters = _.get(group, 'chapters', []);
            _.forEach(chapters, (chapter) => {
                const businessQuestion = NewLandingPageComponent.addBusinessQuestion(indexState.pages, _.get(chapter, ['pageIds', 0]));
                _.set(chapter, 'question', businessQuestion);
                _.set(chapter, 'show', false);
            });

            if (chapters.length) {
                result.push({
                    id: _.get(group, 'id'),
                    title: _.get(group, 'title'),
                    chapters
                });
            }
        });

        return result;
    }

    private initCountries(countries) {
        const selected = countries.find(c => c.isDefault);
        if (selected) {
            this.selectedCountry = selected.countryCode;
            this.selectedCountryName = selected.countryName;
        }
    }

    private registerOpenFavourite() {
        this.subs.add(
            this.reportFavouriteModelService.favouritesOpened.subscribe((chapterItem) => {
                this.resetOpenFavourite(chapterItem);
            })
        );
    }

    private resetOpenFavourite(chapterItem = null) {
        _.forEach(this.overviews, (overview) => {
            if ((chapterItem && chapterItem.title !== overview.title) || (!chapterItem)) {
                overview.show = false;
            }
        });

        _.forEach(this.groups, (group) => {
            const chapters = _.get(group, 'chapters', []);
            if (chapters.length) {
                _.forEach(chapters, (chapter) => {
                    if ((chapterItem && chapterItem.id !== chapter.id) || (!chapterItem)) {
                        chapter.show = false;
                    }
                });
            }
        });
    }

    private registerOpenLoadStatus() {
        this.subs.add(
            this.newLoadStatusUpdatesService.loadStatusOpened.subscribe((item) => {
                this.resetOpenLoadStatus(item);
            })
        );
    }

    private resetOpenLoadStatus(item = null) {
        _.forEach(this.loadStatusUpdates, (loadStatus) => {
            if ((loadStatus && loadStatus.periodId !== item?.periodId) || (!item)) {
                loadStatus.show = false;
            }
        });
    }
}
