import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { DatatableComponent } from './datatable.component';
import { DatatableHeaderComponent } from './header/header.component';
import { DatatableBodyComponent } from './body/body.component';
import { DatatableFooterComponent } from './footer/footer.component';
import { DatatableHeaderCellComponent } from './header/header-cell.component';
import { DatatableBodyRowComponent } from './body/body-row.component';
import { ScrollerComponent } from './body/scroller.component';
import { ColumnUtilService } from './shared/services/column-util.service';
import { CamelCasePipe } from './shared/pipes/camel-case.pipe';
import { DeCamelCasePipe } from './shared/pipes/de-camel-case.pipe';
import { RoundEvenPipe } from './shared/pipes/round-even.pipe';
import { CssTransformService } from './shared/services/css-transform.service';
import { HeaderUtilService } from './shared/services/header-util.service';
import { HeaderDragService } from './shared/services/header-drag.service';
import { ColumnResizableDirective } from 'insightui.table/components/datatable/header/columnresizable.directive';
import { TooltipUtilService } from 'insightui.table/components/datatable/shared/services/tooltip-util.service';
import { TableRowSorterService } from 'insightui.table/services/table-row-sorter.service';
import { DatatableScrollerSyncService } from 'insightui.table/components/datatable/shared/services/datatable-scroller-sync.service';
import { EnablePluginsDirective } from 'insightui.table/components/plugins/enable-plugins.directive';
import { EmptySearchComponent } from 'insightui.table/components/datatable/body/empty-search.component';
import { Angulartics2Module } from 'angulartics2';
import { DragulaModule } from 'ng2-dragula';
import { PerformanceIndicatorComponent } from 'insightui.table/components/datatable/body/performanceIndicator/performance-indicator.component';

@NgModule({
    imports: [CommonModule, FlexLayoutModule, DragulaModule, Angulartics2Module],
    declarations: [
        ColumnResizableDirective,
        ScrollerComponent,
        DatatableComponent,
        DatatableHeaderComponent,
        DatatableHeaderCellComponent,
        DatatableBodyComponent,
        DatatableFooterComponent,
        DatatableBodyRowComponent,
        CamelCasePipe,
        DeCamelCasePipe,
        RoundEvenPipe,
        EnablePluginsDirective,
        EmptySearchComponent,
        PerformanceIndicatorComponent
    ],
    exports: [DatatableComponent],
    providers: [
        ColumnUtilService,
        CssTransformService,
        HeaderUtilService,
        HeaderDragService,
        TableRowSorterService,
        TooltipUtilService,
        DatatableScrollerSyncService
    ]
})
export class DatatableModule {}
