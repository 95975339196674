import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from 'insightui.global/global.module';
import { TableElementCleanupService } from './table-element-cleanup.service';
import { PowerpointInfoRowService } from '../services/powerpoint-info-row.service';

const AUTHORING_TABLE_SELECTOR = 'authoring-datatable';
const FILTER_PLACEMENT_ELEMENT_SELECTOR = 'datatable:last-child';

@Injectable()
export class TableElementService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        private tableElementCleanupService: TableElementCleanupService,
        private powerpointInfoRowService: PowerpointInfoRowService
    ) {}

    getCleanedUpTableElement(): HTMLElement {
        let tableElement = this.getTableElementFromDom();
        tableElement = this.tableElementCleanupService.cleanupElementForExport(
            tableElement
        );
        this.powerpointInfoRowService.appendFilterInfoToElement(
            tableElement,
            FILTER_PLACEMENT_ELEMENT_SELECTOR
        );

        return tableElement;
    }

    getTableElementFromDom(): HTMLElement {
        const tableElement = this.document.querySelector(
            AUTHORING_TABLE_SELECTOR
        ) as HTMLElement;
        if (!tableElement) {
            throw new Error(
                'TableElementService: table report not found in the document'
            );
        }
        return tableElement;
    }
}
