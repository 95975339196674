import {
    BaseTablePluginOptions,
    TableBodyPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';
import { Injector } from '@angular/core';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { TopBottomModelService } from 'insightui.table/ng1services/topbottom-model.service';
import { HttpHeaderType } from 'insightui.data/query/queryservice/query-service-request.interface';
import { ExcelExport } from 'insightui.table/components/datatable/shared/services/table-export.service';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

export class SubTotalLabelPlugin extends TableBodyPlugin {
    name = 'SubTotalLabelPlugin';
    visualizationContextService;
    topBottomModelService;
    textContent;

    private readonly SUBTOTAL_ROW_TAG = 'SubTotal';
    private readonly SUBTOTAL_ROW_TYPE = 'subtotals';
    private readonly SUBTOTAL_ROW_DATA_VALUE = 'Subtotal';

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions,
        protected table: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);
        this.visualizationContextService = this.injector.get(VisualizationContextService);
        this.topBottomModelService = this.injector.get(TopBottomModelService);
    }

    select(cell): boolean {
        return super.select(cell) && cell.row.TG === this.SUBTOTAL_ROW_TAG;
    }

    extendExport(exportData: ExcelExport) {
        if (!this.textContent) {
            return exportData;
        }
        exportData.value.forEach(row => {
            if (row.type === this.SUBTOTAL_ROW_TYPE) {
                row.data.forEach(rowData => {
                    if (rowData.value === this.SUBTOTAL_ROW_DATA_VALUE) {
                        rowData.value = this.textContent;
                    }
                });
            }
        });
        return exportData;
    }

    protected doEnable(cell: Cell) {
        const rootNode: HTMLElement = cell.element.nativeElement;
        const topBottomFilters = this.topBottomModelService.readData();
        const selectedFilter = topBottomFilters.filter(item => item.selected)[0];

        if (rootNode && selectedFilter.type !== '#' && this.getUncutTotalCount()) {
            const maxItem = this.topBottomModelService.getMaxItem();
            this.textContent = 'Subtotal of Top ' + maxItem + '#';
            rootNode.textContent = this.textContent;
            rootNode.setAttribute('title', this.textContent);
        } else if (rootNode && selectedFilter.type === 'all') {
            this.textContent = 'Subtotal of ' + selectedFilter.title;
            rootNode.textContent = this.textContent;
            rootNode.setAttribute('title', this.textContent);
        } else {
            this.textContent =
                'Subtotal of ' +
                selectedFilter.title +
                ' ' +
                selectedFilter.value +
                selectedFilter.type;
            rootNode.textContent = this.textContent;
            rootNode.setAttribute('title', this.textContent);
        }
    }

    protected doDisable(cell: Cell) {
        // Nothing to do here
    }

    private getUncutTotalCount() {
        const item = this.table.reportingAttributes.find(
            attr => Number(attr.key) === HttpHeaderType.UNCUT_TOTAL_COUNT
        );
        return item ? Number(item.value) : null;
    }
}
