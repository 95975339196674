<ng-template [ngIf]="columnHeader.children">
    <div fxFlex="100" class="datatable-header-group" #cellContainer>
        <div
            class="datatable-header-cell-label-wrapper"
            [attr.data-uitest]="'columnHeader-' + headerKey"
        >
            <span class="datatable-header-label" [attr.title]="name">
                <span class="datatable-header-label-text">{{ name }}</span>
            </span>
            <div #extensionContainer></div>
        </div>
    </div>
    <div
        fxLayout="row"
        fxFlex="100"
        [dragula]="'header-children-drag-container'"
        [dragulaModel]="columnHeader.children"
    >
        <datatable-header-cell
            *ngFor="
                let header of columnHeader.children;
                let i = index;
                trackBy: trackByColumnHeaderFn
            "
            [headerRowHeight]="headerRowHeight"
            [columnHeader]="header"
            [ngClass]="{ 'fixed-header': header?.attributes?.isFixedHeader }"
            fxFlex="{{ header.flexWidth }}"
            fxLayout="row {{ header.children ? 'wrap' : '' }}"
            mwlResizable
            [state]="state"
            [resizeCursorPrecision]="5"
            [resizeEdges]="{ right: true }"
            (resizing)="onCellResizing($event, header)"
            (resizeStart)="onCellResizeStart($event, header)"
            (resizeEnd)="onCellResizeEnd($event, header)"
        >
        </datatable-header-cell>
    </div>
</ng-template>
<ng-template [ngIf]="!columnHeader.children">
    <div
        class="datatable-header-cell"
        [ngClass]="{ 'fixed-header': columnHeader?.attributes?.isFixedHeader }"
        #cellContainer
    >
        <div
            class="datatable-header-cell-label-wrapper"
            [attr.data-uitest]="'columnHeader-' + headerKey"
        >
            <div
                class="datatable-header-label datatable-header-label-ellipsis"
                [attr.title]="name"
                [ngStyle]="{ 'maxWidth.px': columnHeader?.width - 10 }"
            >
                <span class="datatable-header-label-text">{{ name }}</span>
            </div>
            <div #extensionContainer></div>
        </div>
    </div>
</ng-template>
