<div class="notification-box alert alert-error">
    <div class="notification-box-image">
        <img src="assets/images/cloud.png" alt="" />
    </div>
    <div class="notification-box-title">
        <span class="notification-box-title-text ng-binding"
            >There is no data to display.</span
        >
    </div>
    <div class="notification-box-content">
        Your current filter selections don't provide any results - probably due to a lack
        of sales. Perhaps adjust the period, or remove any active product filters to
        analyze whether this is a wider issue.
    </div>
</div>
