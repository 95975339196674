import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';

/**
 * @classdesc Contains utility code related to the removal of headers (columns) in the data table.
 */
@Injectable()
export class HeaderRemovedService {
    private visualizationContextService: any;
    private vcProperty: any;

    constructor(@Inject('$injector') $injector) {
        this.visualizationContextService = $injector.get('VisualizationContextService');
        this.vcProperty = $injector.get('VC_PROPERTY');
    }

    /**
     * Removes from the VisualizationContext the kpi corresponding to the
     * last removed table header (column).
     *
     * @returns The new kpi list from the VisualizationContext
     */
    removeHeaderFromVisualizationContext(): string[] {
        const removedKpi: string = this.visualizationContextService.get('headerRemoved');
        const newKpis = this.visualizationContextService
            .get(this.vcProperty.KpiList)
            .slice();
        _.pull(newKpis, removedKpi);
        this.visualizationContextService.set(this.vcProperty.KpiList, newKpis);
        return newKpis;
    }
}
