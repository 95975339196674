import { Injectable } from '@angular/core';

@Injectable()
export class BrandHighlightService {
    brandName: string;

    constructor() {}

    public toggleBrandName(pBrandName): void {
        if (this.brandName === pBrandName) {
            this.brandName = null;
        } else {
            this.brandName = pBrandName;
        }
    }
}
