<div class="chart-legend">
    <div class="chart-legend__order-by-dropdown" *ngIf="isSelectable$ | async">
        <legend-order-by-drop-down
            [options]="sortingOptions$ | async"
            (onSelect)="handleChangeSorting($event)"
        ></legend-order-by-drop-down>
    </div>
    <div class="chart-legend__cells" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <!-- Don't add the cdkDrag directive to lines whose value is 'Others' because
            they should not be sortable. 
        -->
        <ng-container
            *ngFor="let row of legendRows$ | async as rows; trackBy: legendRowId"
        >
            <div
                *ngIf="!isOthersValue(row); else nonMovableItem"
                [chartLegendCellItemForRow]="row"
                [cellItemEnabled]="!isDraggingAnyRow"
                class="chart-cell"
                [ngClass]="cssClassesForRow(row)"
                cdkDrag
                (cdkDragStarted)="onDragStart()"
                (cdkDragEnded)="onDragEnd()"
                [cdkDragData]="{ row: row, rows: rows }"
                cdkDragBoundary=".chart-legend__cells"
                cdkDragLockAxis="y"
                [cdkDragDisabled]="!row.sortable"
            >
                <span cdkDragHandle class="chart-cell-drag-handle">
                    <i class="fa fa-ellipsis-v"></i>
                </span>
                <legend-label
                    [label]="row.title"
                    [color]="row.color"
                    [active]="!isOthersValue(row)"
                    (onLabelClick)="openPage(row)"
                ></legend-label>
                <div class="legend-action" *ngIf="row.index === 'noindex'">N/A</div>
            </div>

            <ng-template #nonMovableItem>
                <div
                    [chartLegendCellItemForRow]="row"
                    [cellItemEnabled]="!isDraggingAnyRow"
                    class="chart-cell"
                    [ngClass]="cssClassesForRow(row)"
                >
                    <span class="chart-cell-drag-handle">
                        <i class="fa fa-ellipsis-v"></i>
                    </span>
                    <legend-label
                        [label]="row.title"
                        [color]="row.color"
                        [active]="!isOthersValue(row)"
                        (onLabelClick)="openPage(row)"
                    ></legend-label>
                    <div class="legend-action" *ngIf="row.index === 'noindex'">N/A</div>
                </div>
            </ng-template>
        </ng-container>
    </div>
</div>
