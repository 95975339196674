import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { SortingModel } from 'insightui.core/models/sorting.model';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { AdditionalInfo } from 'insightui.data/query/queryservice/query-service-request.interface';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { LoadingIndicatorService } from 'insightui.loading-indicator/loading-indicator.service';
import {
    MultiNotificationSubscription,
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { CellCacheService } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { SplitByStateRestoreService } from 'insightui.table/components/datatable/shared/services/splitby-state-restore.service';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';
import {
    TableData,
    TableDataService
} from 'insightui.table/components/datatable/shared/services/table-data.service';
import {
    ExportableTable,
    TableExportService
} from 'insightui.table/components/datatable/shared/services/table-export.service';
import { SearchDatatableService } from 'insightui.table/components/menu/search-datatable.service';
import { TablePluginFactory } from 'insightui.table/components/plugins/table-plugin-factory.service';
import {
    NotificationNamespace,
    NotificationType
} from 'insightui.table/ng1services/notifications/notification.model';
import { ReportContextNotificationService } from 'insightui.table/ng1services/report-context-notification.service';
import {
    TopBottomFilterStatus,
    TopBottomModelService
} from 'insightui.table/ng1services/topbottom-model.service';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { TablePluginRegistry } from 'insightui.table/services/table-plugin-registry.service';
import { TableRowSorterService } from 'insightui.table/services/table-row-sorter.service';
import { DatatableConfig } from 'insightui.table/services/table.data';
import * as _ from 'lodash';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, mergeMap, tap } from 'rxjs/operators';
import {
    Column,
    ColumnHeader,
    DataRow
} from './datatable/shared/models/datatable.models';

const TG = 'TG';
const specialRowsTgs = ['Tradebrand & Exclusive', 'Others'];
const specialRowsPropertyNames = ['tradebrandAndExclusive', 'others'];

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'authoring-datatable',
    styles: ['* {font-size: 12px;} :host { display: block}'],
    template: `
        <div class="panel-body" style="overflow: hidden">
            <datatable
                *ngIf="datatableConfig && isDataAvailable"
                class="datatable striped"
                [rows]="reportingDataSet"
                [totalRow]="reportingTotalData"
                [subTotalRow]="reportingSubTotalData"
                [headers]="headerConfig"
                [columns]="columnConfig"
                [headerRowHeight]="40"
                [footerHeight]="30"
                [rowHeight]="30"
            >
            </datatable>
            <gfk-no-data *ngIf="!isDataLoading && !isDataAvailable"></gfk-no-data>
        </div>
    `
})
export class AuthoringDatatableComponent implements OnInit, OnDestroy, ExportableTable {
    public isDataAvailable = false;
    public isDataLoading = true;

    @MultiNotificationSubscription([
        NotificationNamespace.Filter,
        NotificationNamespace.Settings,
        NotificationNamespace.SplitBy
    ])
    public onFilterUpdate$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationNamespace.Drilling)
    public onDrillingUpdate$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationNamespace.Sorting)
    public onSortingUpdate$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationNamespace.HeaderReorder)
    onHeaderReorderUpdate$: Observable<NotificationEvent>;

    public onDataAvailable$: Observable<TableData>;

    @Output()
    public reloaded$: EventEmitter<DatatableConfig> = new EventEmitter();

    datatableConfig: DatatableConfig;
    reportingDataSet: DataRow[] = [];
    reportingTotalData: DataRow;
    reportingSubTotalData: DataRow;
    reportingFilteredTotal: DataRow;
    reportingTradebrandAndExclusive: DataRow;
    reportingAttributes: { key: string; value: string }[] = [];
    reportingAdditionalInfo: AdditionalInfo;

    headerConfig: ColumnHeader[];
    columnConfig: Column[];

    private logger: ILogger;

    @NotificationSubscription(NotificationType.TableSearch)
    private onTableSearch$: Observable<NotificationEvent>;

    @VisualizationContextBinding('posTypeList')
    private posTypeList;

    // TODO: Find a more generic solution for hiding specific rows when
    // certain filters are set. This binding is used to determine whether
    // we have a top/bottom filter and should display others accordingly
    @VisualizationContextBinding('contextFilter.type')
    private contextFilterType;

    private reportContextNotificationService: ReportContextNotificationService;

    constructor(
        private datatableConfigurationService: DatatableConfigurationService,
        private dataService: TableDataService,
        private pluginFactory: TablePluginFactory,
        private pluginRegistry: TablePluginRegistry,
        private ng1Resolver: AngularInjectorResolver,
        private splitByStateRestoreService: SplitByStateRestoreService,
        private tableExportService: TableExportService,
        private tableSortService: TableRowSorterService,
        private cellCache: CellCacheService,
        private sortModel: SortingModel,
        private visualizationContextService: VisualizationContextService,
        private searchDatatableService: SearchDatatableService,
        private topBottomModelService: TopBottomModelService,
        private loadingIndicator: LoadingIndicatorService,
        private store: Store,
        logService: LogService
    ) {
        this.logger = logService.getLogger('AuthoringDatatableComponent');
    }

    ngOnInit() {
        this.datatableConfig = this.datatableConfigurationService.getTableConfiguration();
        // Remove this as soon as we have a route with resolve
        // delay: someone thought it is a good idea to let the view manage the models therefore
        // we have to wait until the filter got rendered before we can render this chart
        this.ng1Resolver
            .get()
            .pipe(delay(0))
            .subscribe(() => {
                this.onDataAvailable$ = this.dataService.onDataAvailable(
                    this.datatableConfig
                );

                this.onSortingUpdate$.subscribe(() => this.sortData(true));
                this.updateConfiguration();
                this.registerPlugins();
                this.registerForUpdates();
                this.reload();
            });

        this.onFilterUpdate$.subscribe(this.reload.bind(this));
        this.onDrillingUpdate$.subscribe(() => {
            this.splitByStateRestoreService.restore();
            this.reload();
        });
        this.tableExportService.registerTable(this);

        this.onTableSearch$.subscribe(notification => {
            const datatableSearchResult = this.searchDatatableService.searchData(
                notification.data,
                this.headerConfig
            );
            this.reportingDataSet = datatableSearchResult.reportingDataSet;
            this.reportingSubTotalData = datatableSearchResult.reportingSubTotalData;
            this.sortData(true);
        });
    }

    getHeaderConfig() {
        return this.headerConfig;
    }

    getColumnConfig() {
        return this.columnConfig;
    }

    getReportingDataSet() {
        return this.reportingDataSet;
    }

    getReportingTotalData() {
        return this.reportingTotalData;
    }

    getReportingSubTotalData() {
        return this.reportingSubTotalData;
    }

    getCellCache() {
        return this.cellCache;
    }

    ngOnDestroy(): void {
        from(this.datatableConfig.plugins)
            .pipe(
                mergeMap((plugin: { type; options }) =>
                    from(
                        this.pluginFactory.createPlugin(plugin.type, plugin.options, this)
                    )
                )
            )
            .forEach(plugin => this.pluginRegistry.deregisterPlugin(plugin));
        this.tableExportService.unregisterTable();
    }

    updateConfiguration() {
        const headerSettings = this.datatableConfigurationService.getHeaderSettings(
            this.datatableConfig
        );
        this.headerConfig = this.datatableConfigurationService.getColumnHeaderConfiguration(
            this.datatableConfig,
            headerSettings.topHeaderVerticalDimensions
        );
        this.columnConfig = this.datatableConfigurationService.getColumnConfiguration(
            this.headerConfig
        );
    }

    repopulateViewWithCurrentDataset() {
        this.repopulateView({
            data: this.reportingDataSet,
            filteredTotal: this.reportingFilteredTotal,
            others: null,
            subTotal: this.reportingSubTotalData,
            tradebrandAndExclusive: this.reportingTradebrandAndExclusive,
            total: this.reportingTotalData,
            attributes: this.reportingAttributes,
            info: this.reportingAdditionalInfo
        });
    }

    /**
     * This is a really dirty hack and will be removed as soon as the QU is done along with a larger update
     * on state management.
     */
    getColumnFieldOrder() {
        const headerSettings = this.datatableConfigurationService.getHeaderSettings(
            this.datatableConfig
        );
        this.headerConfig = this.datatableConfigurationService.getColumnHeaderConfiguration(
            this.datatableConfig,
            headerSettings.topHeaderVerticalDimensions
        );
        return this.datatableConfigurationService
            .getColumnConfiguration(this.headerConfig)
            .map(column => column.field);
    }

    /**
     * When we are in dimension 'productGroup' and have feature split on, we don't
     * show the total numbers, because they cannot be displayed correctly in a
     * feature split.
     */
    private shouldHideTotalValues(): boolean {
        const selectedDimension = this.store.selectSnapshot(
            state => state.visualizationContext.selectedDimension
        );
        const showFeatures = this.store.selectSnapshot(
            state => state.visualizationContext.showFeatures
        );

        if ('productGroup' === selectedDimension && showFeatures) {
            return true;
        }
        return false;
    }

    private configureDimensionContextByPosType() {
        // const posTypeList = this.store.selectSnapshot(
        //     state => state.visualizationContext.posTypeList
        // );
        const hasSplitByPosType = this.posTypeList.length > 1;
        // const hasSplitByPosType = posTypeList.length > 1;
        if (hasSplitByPosType) {
            if (this.datatableConfig.dimensionContext.indexOf('posType') < 0) {
                this.datatableConfig.dimensionContext.push('posType');
            }
        } else {
            this.datatableConfig.dimensionContext = this.datatableConfig.dimensionContext.filter(
                context => context !== 'posType'
            );
        }
    }

    private reload() {
        this.configureDimensionContextByPosType();
        this.reloaded$.next(this.datatableConfig);
        this.dataService.forceReload();
    }

    private registerPlugins() {
        // flatMap is required, as multiple plugins might belong to one plugin name (e.g. header and body plugins)
        from(this.datatableConfig.plugins)
            .pipe(
                mergeMap((plugin: { type; options }) =>
                    from(
                        this.pluginFactory.createPlugin(plugin.type, plugin.options, this)
                    )
                )
            )
            .forEach(plugin => this.pluginRegistry.registerPlugin(plugin));
    }

    private hasAnyData(tableData: TableData): boolean {
        const hasSomeEntriesWithoutMissing = tableData.data.length > 0;
        const isTradebrandExclusive = !!tableData.tradebrandAndExclusive;
        const result = hasSomeEntriesWithoutMissing || isTradebrandExclusive;

        this.logger.debug('TableData', {
            tableData,
            hasSomeEntriesWithoutMissing,
            isTradebrandExclusive,
            result
        });
        return result;
    }

    private registerForUpdates() {
        this.onDataAvailable$
            .pipe(
                tap(
                    tableData => {
                        this.isDataAvailable = this.hasAnyData(tableData);
                        this.isDataLoading = true;
                    },
                    err => {
                        this.logger.warn(`onDataAvailable$ error`, err);
                        this.isDataAvailable = false;
                    }
                ),
                catchError(err => {
                    this.logger.error(`caught error while getting data`, err);
                    this.isDataAvailable = false;
                    return of({ data: [] });
                })
            )
            .subscribe(
                (data: TableData) => {
                    this.isDataLoading = false;
                    this.repopulateView(data);
                },
                error => {
                    this.isDataLoading = false;
                    this.logger.warn(`DATA ERROR`, error);
                    this.loadingIndicator.toggleOff();
                }
            );
    }

    private repopulateView(tableData: TableData) {
        this.loadingIndicator.toggleOn();

        // enable browser to display loading spinner before rendering (which could take a while)
        setTimeout(() => {
            this.updateConfiguration();

            if (this.shouldHideTotalValues()) {
                this.removeTotalRowData(tableData.total);
            }

            this.reportingTotalData = tableData.total;
            this.reportingDataSet = tableData.data;
            this.reportingSubTotalData = tableData.subTotal;
            this.reportingFilteredTotal = tableData.filteredTotal;
            this.reportingAttributes = tableData.attributes;
            this.reportingTradebrandAndExclusive = tableData.tradebrandAndExclusive;
            this.reportingAdditionalInfo = tableData.info;

            this.cellCache.evict();
            this.cellCache.push(tableData.total, ...this.columnConfig);
            tableData.data.forEach(row => this.cellCache.push(row, ...this.columnConfig));
            this.cellCache.push(tableData.subTotal, ...this.columnConfig);
            this.cellCache.push(tableData.filteredTotal, ...this.columnConfig);

            this.sortData(false);
            this.addSpecialRows(tableData);
            this.searchDatatableService.setSearchData(
                this.reportingDataSet,
                tableData.subTotal
            );

            this.updateTopBottomFilter(tableData.info);

            this.tryToNotifyReportContextChange();

            this.loadingIndicator.toggleOff();
        }, 100);
    }

    private removeTotalRowData(totalRow: DataRow) {
        Object.keys(totalRow).forEach(key => {
            if (key.startsWith('CH') || key.startsWith('RT')) {
                delete totalRow[key];
            }
        });
    }

    private updateTopBottomFilter(info: AdditionalInfo) {
        if (!info) {
            return;
        }
        if (info.contextFilter === TopBottomFilterStatus.ALL) {
            this.topBottomModelService.setToAll();
        }
        if (info.contextFilter === TopBottomFilterStatus.TOP) {
            this.topBottomModelService.setToTop();
        }
    }

    private addSpecialRows(data) {
        // const contextFilterType = this.store.selectSnapshot(
        //     state => state.visualizationContext.contextFilter.type
        // );
        specialRowsPropertyNames.forEach(propertyName => {
            const row = _.get(data, propertyName);
            // TODO Clarify: this.contextFilterType seems to always be 'undefined' (at least at this point).
            // if (row && contextFilterType !== 'all') {
            if (row && this.contextFilterType !== 'all') {
                this.reportingDataSet.push(row);
                this.cellCache.push(row, ...this.columnConfig);
            }
        });
    }

    private tryToNotifyReportContextChange() {
        // Notify reportContextChanged only after first round of all data loads
        if (this.reportContextNotificationService) {
            this.reportContextNotificationService.notify();
        } else {
            // Create and register reportContextChanged
            this.reportContextNotificationService = new ReportContextNotificationService(
                this.ng1Resolver
            );
            this.onSortingUpdate$.subscribe(
                this.tryToNotifyReportContextChange.bind(this)
            );
            this.onHeaderReorderUpdate$.subscribe(() => {
                this.tryToNotifyReportContextChange();
                const headerSettings = this.datatableConfigurationService.getHeaderSettings(
                    this.datatableConfig
                );
                this.headerConfig = this.datatableConfigurationService.getColumnHeaderConfiguration(
                    this.datatableConfig,
                    headerSettings.topHeaderVerticalDimensions
                );
            });
        }
    }

    private sortData(onSort: boolean) {
        // It seems this if-block is intended as initialization of the sorting?
        // sortData(false) is called only once, when initializing this component
        if (!onSort) {
            const sortFields = [];

            if (!this.sortModel.getSorting().defaultSortingDimensions) {
                this.sortModel.rewriteDefaultSortingAndFields();
            }
            // This call does more or less the same as sortingModel.resetSorting does,
            // in which cases is this really needed?
            this.sortModel
                .getSorting()
                .defaultSortingDimensions.forEach(dimensionName => {
                    sortFields.push(this.getDimensionValue(dimensionName));
                });
            if (sortFields.length > 0) {
                this.sortModel.sortBy(sortFields);
            }
        }

        // sortingModel initializes itself (see resetSorting) with sorting dimensions (channel, kpi, etc.),
        // which the current table may not contain (not all datatables have the same headers levels),
        // so we have to "correct" the sort model here to adapt it to the current table.
        // This method sortData is always called after sortModel.resetSorting, making this correction possible.
        // This is not really a clean solution... -> best thing to do: rewrite all the sorting code from scratch,
        // it is pretty convoluted and confusing.
        this.adaptSortModelToDatatableDimensions();

        const filteredIds = this.reportingDataSet.map(row => row.$id);
        const nonSortableRows = this.extractNonSortableRows();
        this.reportingDataSet = this.tableSortService.sort(this.reportingDataSet);
        this.reportingDataSet = this.reportingDataSet.concat(nonSortableRows);
        this.reportingDataSet = this.reportingDataSet.filter(
            row => filteredIds.indexOf(row.$id) !== -1
        );
    }

    private extractNonSortableRows() {
        const nonSortableRowsTgsReversed = specialRowsTgs.slice(0).reverse();
        const rows = [];
        nonSortableRowsTgsReversed.forEach(tg => {
            if (_.get(this.getLastRow(), TG) === tg) {
                rows.push(this.reportingDataSet.pop());
            }
        });

        return rows.reverse();
    }

    private getLastRow() {
        return this.reportingDataSet[this.reportingDataSet.length - 1];
    }

    private adaptSortModelToDatatableDimensions() {
        const currentSorting = this.sortModel.getSorting();

        if (
            _.isEmpty(currentSorting.defaultSortingDimensions) ||
            _.isEmpty(currentSorting.fields)
        ) {
            return;
        }

        const tableVerticalDimensions: string[] = this.datatableConfigurationService.getHeaderSettings(
            this.datatableConfig
        ).topHeaderVerticalDimensions;

        const adaptedSortingDimensions: string[] = [];
        const adaptedSortingFields: string[] = [];
        let i = 0;

        currentSorting.defaultSortingDimensions.forEach(dimensionName => {
            if (tableVerticalDimensions.includes(dimensionName)) {
                adaptedSortingDimensions.push(dimensionName);
                adaptedSortingFields.push(currentSorting.fields[i]);
            }
            i++;
        });

        this.sortModel.onReadData([
            {
                ...currentSorting,
                defaultSortingDimensions: adaptedSortingDimensions,
                fields: adaptedSortingFields
            }
        ]);
    }

    private getDimensionValue(dimensionName: string): string {
        const contextFilter = this.visualizationContextService.get('contextFilter', {});
        switch (dimensionName) {
            case 'period':
                return this.getFirstPeriod();
            case 'channel':
                return contextFilter['base'];
            default:
                return contextFilter[dimensionName];
        }
    }

    private getFirstPeriod() {
        return _.get(this.visualizationContextService.get('periodList'), [0]);
    }
}
