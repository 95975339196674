import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PeriodInfo } from 'insightui.metadata/metadata.types';
import { LoadStateForDialog } from '../load-status.types';

@Component({
    selector: 'gfk-load-status-entry',
    templateUrl: './load-status-entry.component.html',
    styleUrls: ['./load-status-entry.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default
})
export class LoadStatusEntryComponent {
    @Input() node: LoadStateForDialog;
    @Input() period: PeriodInfo;
    @Input() searchText: string;
    @Input() nodeLevel = 1;

    constructor() {}

    toggle() {
        this.node.isCollapsed = !this.node.isCollapsed;
    }
}
