import { Injectable } from '@angular/core';

import { FavouriteResourceApiService } from 'insightui.core/services/favouriteResourceApi.service';
import { FavouriteManagerStateNg1BridgeService } from 'insightui.favouritemanager/state/favourite-manager-state-ng1-bridge.service';

@Injectable({
  providedIn: 'root'
})
export class FavouriteResourceManagerService {

    COMPACT_MODE_KEYS = [
        'visualizationContext.page',
        'visualizationContext.reportId',
        'visualizationContext.countryCode',
        'visualizationContext.drillTarget'
    ].join(';');

    constructor(private favouriteResourceApiService: FavouriteResourceApiService,
                private favouriteManagerStateNg1BridgeService: FavouriteManagerStateNg1BridgeService) {

    }

    getAll(compactMode): Promise<any> {
        compactMode = compactMode || false;
        const parameter = compactMode === true ? {keys: this.COMPACT_MODE_KEYS} : {status: '*'};

        const self = this;
        const promise = new Promise((resolve, reject) => {
            const favourites = [];
            self.favouriteResourceApiService.query(parameter).subscribe(
                (fav) => {
                    favourites.push(...fav);
                },
                (err) => {
                    reject(err);
                },
                () => {
                    this.favouriteManagerStateNg1BridgeService.notifyLoadSuccess(favourites);
                    resolve(favourites);
                }
            );
        });

        return promise;

    };

}
