import { Pipe, PipeTransform } from '@angular/core';
import { RoundEvenService } from 'insightui.core/services/round-even.service';

@Pipe({
    name: 'roundEven'
})
export class RoundEvenPipe implements PipeTransform {
    name = 'RoundEvenPipe';

    constructor(private roundEvenService: RoundEvenService) {}

    transform(num: number | string, decimalPlaces: number): string {
        if (typeof num === 'number') {
            return this.roundEvenService.transform(num, decimalPlaces);
        }
        return num;
    }
}
