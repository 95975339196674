import { Injectable } from '@angular/core';
import { ReportDataRemoteService } from 'insightui.data/remote/report-data-remote.service';
import { DeliveryId } from 'projects/insightui/src/app/state/state.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const FAIR_SHARE_KPIS = ['KPI21176', 'KPIC11', 'KPIC91', 'KPIC12', 'KPIC92', 'KPIC91_REBASE'];

/**
 * Provides the data for the fair share report.
 */
@Injectable({
    providedIn: 'root'
})
export class FairShareDataService {
    constructor(private reportDataRemoteService: ReportDataRemoteService) { }

    getReportDataForDeliveryId(deliveryId: DeliveryId): Observable<any> {
        const request = this.reportDataRemoteService.buildRequestForDeliveryWithPeriodType(
            deliveryId,
            FAIR_SHARE_KPIS,
            'comparison'
        );

        return this.reportDataRemoteService
            .executeRequest(request)
            .pipe(map(({ data }) => data));
    }
}
