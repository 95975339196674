import { CommonModule, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UpgradeModule } from '@angular/upgrade/static';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { ModalModule } from 'ngx-bootstrap';
import { AccordionModule } from 'ngx-bootstrap/accordion';

import { BusinessQuestionModule } from 'projects/insightui/src/app/report-filter/business-question';
import { CollectionManagerRootModule } from 'insightui.collectionmanager/collection-manager-root.module';
import { ContextSerializationService } from './shared/contextSerialization.service';
import { ChannelComparisonModel } from './models/reportSetting/channel-comparison.model';
import { NewDropdownComponent } from 'insightui.core/components/new-dropdown/new-dropdown.component';
import { environment } from 'projects/insightui/src/environments/environment';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';
import { FavouriteManagerRootModule } from 'insightui.favouritemanager/favourite-manager-root.module';
import { GlobalModule } from 'insightui.global/global.module';
import { HeaderRemovedService } from './services/header-removed.service';
import { InputSpinnerComponent } from './components/inputSpinner/input-spinner.component';
import { KpiSettingsComponent } from './components/kpiSettings/kpiSettings.component';
import { NewLandingPageComponent } from 'insightui.core/components/new-landing-page/new-landing-page.component';
import { NewLoadStatusComponent } from 'insightui.core/components/new-load-status/new-load-status.component';
import { NewTooltipComponent } from 'insightui.core/components/new-tooltip/new-tooltip.component';
import { NoSpacePipe } from './shared/pipes/no-space.pipe';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PerformanceIndicatorSettingsComponent } from './components/reportSetting/performanceIndicator/performance-indicator-settings.component';
import { ReportAccordionComponent } from 'projects/insightui/src/app/core/components/report-accordion/report-accordion.component';
import { ReportOverviewComponent } from './components/report-overview/report-overview.component';
import { ReportTypeSelectorComponent } from './components/report-type-selector/report-type-selector.component';
import { ReportSettingExtensionAreaComponent } from './components/reportSetting/report-setting-extension-area.component';
import { RoundEvenService } from './services/round-even.service';
import { SafePipe } from 'insightui.core/shared/pipes/safe.pipe';
import { SelectFavouriteComponent } from 'insightui.core/components/select-favourite/select-favourite.component';
import { SliderComponent } from './components/slider/slider.component';
import { SortingModel } from './models/sorting.model';
import { ThresholdModel } from './models/reportSetting/threshold.model';
import { VisualizationContextSelectors } from 'insightui.vc/visualization-context-selectors';
import { VisualizationContextState } from 'insightui.vc/visualization-context.state';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
    imports: [
        UpgradeModule,
        CommonModule,
        HttpClientModule,
        CommonModule,
        FormsModule,
        AccordionModule,
        GlobalModule,
        ModalModule.forRoot(),
        NgxsModule.forRoot([VisualizationContextState], {
            // Note: enabling developerMode wraps all objects in store with an immutable
            // wrapper. While this is great for avoiding bugs in new code, this breaks
            // the legacy powerpoint export functionality :-(
            developmentMode: !environment.production, // TODO investigate powerpoint export to make it compatible with this setting
            selectorOptions: {
                suppressErrors: false,
                injectContainerState: false
            }
        }),
        NgxsLoggerPluginModule.forRoot({
            disabled: environment.production,
            collapsed: true
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
            maxAge: 20,
            name: 'GfKNgxs'
        }),
        CollectionManagerRootModule,
        FavouriteManagerRootModule,
        BusinessQuestionModule,
        Angulartics2Module
    ],
    exports: [PageFooterComponent],
    providers: [
        DecimalPipe,
        ChannelComparisonModel,
        ThresholdModel,
        SortingModel,
        ContextSerializationService,
        RoundEvenService,
        VisualizationContextSelectors,
        HeaderRemovedService,
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?userprofileservice.*$/,
            multi: true
        },
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?(powerpoint|excel).*$/,
            multi: true
        },
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*\/login\/ping$/,
            multi: true
        }
    ],
    declarations: [
        PerformanceIndicatorSettingsComponent,
        ReportSettingExtensionAreaComponent,
        InputSpinnerComponent,
        SliderComponent,
        KpiSettingsComponent,
        NewLandingPageComponent,
        NoSpacePipe,
        PageFooterComponent,
        ReportAccordionComponent,
        ReportOverviewComponent,
        ReportTypeSelectorComponent,
        SelectFavouriteComponent,
        NewDropdownComponent,
        NewLoadStatusComponent,
        NewTooltipComponent,
        SafePipe
    ]
})
export class InsightCoreModule { }
