export const CLIENT_ID_QUERY_PARAM = 'clientId';
export const COLLECTION_ID_QUERY_PARAM = 'collectionId';

/**
 * If the current browser location queryParams includes the given paramName, adds this param to the
 * given url.
 */
function addQueryParamIfExists(urlAsString: string, paramName: string): string {
    const paramValue = new URLSearchParams(window.location.search).get(paramName);
    if (paramValue) {
        const url = urlAsString.startsWith('http')
            ? new URL(urlAsString)
            : new URL(
                  urlAsString,
                  window.location.protocol + '//' + window.location.host
              );
        const params = url.searchParams;
        params.set(paramName, paramValue);
        return url.toString();
    } else {
        return urlAsString;
    }
}

export function getClientId(): string {
    if (!('URLSearchParams' in window)) {
        console.warn(
            `Your browser does not support URLSearchParams. You cannot determine the clientId from the URL. Please use another browser.`
        );
        return '';
    }

    return new URLSearchParams(window.location.search).get(CLIENT_ID_QUERY_PARAM);
}

export function preserveQueryParams(
    urlAsString: string,
    ...paramNames: string[]
): string {
    if (!('URLSearchParams' in window)) {
        console.warn(
            `Your browser does not support URLSearchParams. You cannot use the clientId switching feature. Please use another browser.`
        );
        return urlAsString;
    }
    paramNames.forEach(
        paramName => (urlAsString = addQueryParamIfExists(urlAsString, paramName))
    );
    return urlAsString;
}

export function preserveClientId(urlAsString: string): string {
    return preserveQueryParams(urlAsString, CLIENT_ID_QUERY_PARAM);
}

export function preserveClientIdAndCollectionId(urlAsString: string): string {
    return preserveQueryParams(
        urlAsString,
        CLIENT_ID_QUERY_PARAM,
        COLLECTION_ID_QUERY_PARAM
    );
}
