<h2
    *ngIf="reportName"
    class="headline"
    [class.headline--main]="reportName"
    [attr.data-uitest]="'reportName'"
>
    {{ reportName }}
</h2>
<h2
    class="headline"
    [class.headline--main]="!reportName"
    [class.headline--sub]="reportName"
    [class.headline--has-no-sub]="!reportName"
    [class.hasDropdown]="subEntries.length > 0"
    [gfkDropDown]="ddBusinessQuestion"
    [gfkDropDownEnabled]="subEntries.length > 0"
    [attr.data-uitest]="'businessQuestion'"
>
    {{ businessQuestion }}
</h2>

<!-- dropdown content -->
<ng-template #ddBusinessQuestion>
    <gfk-arrow-panel>
        <h3 class="sub-entry-list__headline">
            Business Questions
        </h3>
        <ul [attr.data-uitest]="'businessQuestionDropDown'" class="sub-entry-list__list">
            <li
                [attr.data-uitest]="'businessQuestionSubEntry-' + entry.pageId"
                class="sub-entry-list__list-item"
                *ngFor="let entry of subEntries"
                [class.sub-entry-list__list-item--active]="
                    entry.businessQuestion === businessQuestion
                "
                (click)="subEntryClick.emit(entry)"
            >
                {{ entry.businessQuestion }}
            </li>
        </ul>
    </gfk-arrow-panel>
</ng-template>
<!-- end dropdown content -->
