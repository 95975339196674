import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundViewComponent } from './page-not-found-view.component';
import { SadCloudModule } from 'insightui.common-components/sad-cloud';

@NgModule({
    declarations: [PageNotFoundViewComponent],
    imports: [CommonModule, SadCloudModule],
    providers: []
})
export class PageNotFoundModule {}
