import { StaticVisualizationContextService } from 'insightui.data/shared/static-visualization-context.service';

export function VisualizationContextBinding(path): Function {
    return function(target: object, propertyKey: string | symbol) {
        const message = `StaticVisualizationContextService is not correctly initialized in VisualizationContextBinding`;
        Object.defineProperty(target, propertyKey, {
            enumerable: false,
            get() {
                const instance = StaticVisualizationContextService.getInstance();
                if (!instance) {
                    throw message;
                }
                return instance.get(path);
            },
            set(value) {
                const instance = StaticVisualizationContextService.getInstance();
                if (!instance) {
                    throw message;
                }
                instance.set(path, value);
            }
        });
    };
}
