<div
    fxLayout="row"
    [style.height.px]="height"
    [style.width.px]="innerWidth"
    class="datatable-body-row datatable-row"
    [ngClass]="{
        even: isEvenRow,
        odd: isOddRow,
        total: isTotalRow,
        'end-border': showEndBorder
    }"
    #rowContainer
>
    <div
        [ngStyle]="{
            'width.px': this.visibilityState.leftFiller,
            'minWidth.px': this.visibilityState.leftFiller,
            order: 0
        }"
    ></div>
    <div
        *ngFor="
            let cell of cells;
            let ii = index;
            let last = last;
            trackBy: cellColumnTrackByFn
        "
        class="datatable-body-cell-label datatable-body-cell"
        [title]="cell?.row?.TG === 'Total' && !cell?.column?.dataType ? cell.value : ''"
        [enablePlugins]="cell"
        [ngClass]="[cell?.column?.cssClasses, last ? 'datatable-body-cell-last' : '']"
        [style.order]="ii"
        [style.width.px]="cell?.column?.width"
        [style.flex]="'0 0 auto'"
    >
        {{ cell.value }}
        <gfk-performance-indicator
            *ngIf="cell.indicator"
            [performanceIndicator]="cell.indicator"
        ></gfk-performance-indicator>
    </div>
    <div
        [ngStyle]="{
            'width.px': this.visibilityState.rightFiller,
            'minWidth.px': this.visibilityState.rightFiller,
            order: 9999
        }"
    ></div>
</div>
