export const REPORTING_DIMENSION_INFO = {
    area: {
        id: 10401,
        prefix: 'AR'
        // name: 'Reporting Area'
    },
    sector: {
        id: 10301,
        prefix: 'SG'
        // name: 'Reporting Sector'
    },
    category: {
        id: 10201,
        prefix: 'CY'
        // name: 'Reporting Category'
    },
    productGroup: {
        id: 10101,
        prefix: 'PG'
        // name: 'Reporting Product Group'
    }
};

export interface Revision {
    readonly activeRevisionId: number;
    readonly periodId: number;
    readonly periodDisplayText: string;
    readonly regionDisplayText: string;
    readonly regionIsoCode: string;
}

export interface CountryInfo {
    readonly key: string;
    readonly value: string;
    readonly docIds: number[];
    readonly periods: { period: number; docId: number }[];
    readonly isDefault: boolean;

    readonly countryCode: string;

    readonly countryName: string;
}

export interface ChannelDimension {
    readonly type: string;
    readonly title: string;
}

export interface PosTypeDimension {
    readonly type: string;
    readonly title: string;
}

export interface FeatureValue {
    readonly id: string;
    readonly title: string;
}

export interface FeatureDimension {
    readonly title: string;
    readonly values: FeatureValue[];
}

export interface PriceClassDimension {
    readonly title: string;
    readonly attributes?: PriceClassAttribute;
    readonly types?: string[]; // added while loading
}

export interface PriceClassAttribute {
    readonly dimClassification: string[];
}

// TODO: same as ReportingDimensionHierarchy in FairShareState!
export interface HierarchyInfo {
    readonly id: string;
    readonly parentId?: string;
    readonly title: string;
    readonly typeId: 10401 | 10301 | 10201 | 10101;
    readonly dim: string;
    readonly level: number;
    readonly loadStatus: string;
    readonly isLoaded: boolean;
    readonly isAvailable: boolean;
    readonly hasAvailableData: boolean;
}

export interface HierarchyTree {
    readonly id: string;
    readonly documentId: number;
    readonly period: number;
    readonly root: HierarchyTreeNode;
    readonly isActive: boolean;
}

export interface HierarchyTreeNode {
    readonly $$refId: string;
    readonly id: string;
    readonly parentId: string;
    readonly typeId: number;
    readonly name: string;
    readonly level: number;
    readonly loadStatus: number;
    readonly nodes: HierarchyTreeNode[];
    readonly loadStatusAggregate: number;
    readonly hasAvailableData: boolean;
}

export interface DeliveryInfoContainer {
    deliveryInfo: DeliveryInfo;
}

export interface DeliveryInfo {
    readonly country: string;
    readonly revisionId: number;

    readonly version: {
        schemaVersion: string;
    };
    readonly content: {
        period: string;
        currency: { code: string; symbol: string };
    };
    readonly dimensions: DimensionMap;

    readonly hierarchyDimensions: {
        readonly [key: string]: HierarchyInfo;
    };
}

export interface DimensionMap {
    area: { [key: string]: HierarchyInfo };
    sector: { [key: string]: HierarchyInfo };
    category: { [key: string]: HierarchyInfo };
    productGroup: { [key: string]: HierarchyInfo };
    brand: { [key: string]: {} };
    channel: { [key: string]: ChannelDimension };
    country: { [key: string]: {} };
    feature: { [key: string]: FeatureDimension };
    item: { [key: string]: {} };
    kpi: { [key: string]: {} };
    period: { [key: string]: [] };
    posType: { [key: string]: PosTypeDimension };
    priceClass: { [key: string]: PriceClassDimension };
    tag: { [key: string]: {} };
}

export interface LoadStatusInfo {
    readonly active: boolean;
    readonly countryCode: string;
    readonly docId: number;
    readonly loadState: LoadState[];
    readonly period: PeriodInfo;
    readonly periodStr: string;
    readonly hasAvailableData: boolean;
}

export interface PeriodInfo {
    readonly short: string;
}

export interface LoadState {
    readonly id: string;
    readonly typeId: number;
    readonly name: string;
    readonly labelTitle: string;
    readonly lastChange: number;
    readonly loadStatus: number;
    readonly nodes?: LoadState[];
}
