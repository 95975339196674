import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FavouriteDto } from '../models/favourite-dto';
import {
    FmLoadFavouritesFailure,
    FmLoadFavouritesSuccess,
    FmUpdateFavouritesFailure,
    FmUpdateFavouritesSuccess
} from './favourite-manager.actions';

@Injectable({ providedIn: 'root' })
export class FavouriteManagerStateNg1BridgeService {
    constructor(private store: Store) {}

    public notifyLoadSuccess(favourites: FavouriteDto[]) {
        const clonedFavs = JSON.parse(JSON.stringify(favourites));
        this.store.dispatch(new FmLoadFavouritesSuccess(clonedFavs));
    }

    public notifyLoadFailure(error: HttpErrorResponse) {
        this.store.dispatch(new FmLoadFavouritesFailure(error));
    }

    public notifyUpdateFailure(error: HttpErrorResponse) {
        this.store.dispatch(new FmUpdateFavouritesFailure(error));
    }

    public notifyUpdateSuccess() {
        this.store.dispatch(new FmUpdateFavouritesSuccess());
    }
}
