import { Injectable } from '@angular/core';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { DataRow } from 'insightui.table/components/datatable/shared/models/datatable.models';

@Injectable()
export class FilteredTotalService {
    showFilteredTotal = false;
    private allowedKpis = [];

    constructor(datatableConfigurationService: DatatableConfigurationService) {
        const filteredTotalPlugin = datatableConfigurationService
            .getTableConfiguration()
            .plugins.find(plugin => plugin.type === 'filteredTotal');
        if (filteredTotalPlugin) {
            this.allowedKpis = filteredTotalPlugin.options.kpis;
        }
    }

    getHeightByRow(row: DataRow, table: AuthoringDatatableComponent) {
        if (table.reportingFilteredTotal && row['TG'] === 'Total') {
            for (let column of table.getColumnConfig()) {
                const kpi = column.field.split('_').find(part => /KPI/.test(part));
                const filterTotal = table.reportingFilteredTotal[column.field];
                const cellValue = row[column.field];
                if (
                    this.allowedKpis.indexOf(kpi) >= 0 &&
                    typeof filterTotal === 'number' &&
                    typeof cellValue === 'number' &&
                    cellValue !== filterTotal
                ) {
                    return 20;
                }
            }
        }
        return 0;
    }
}
