import { Injectable } from '@angular/core';
import { AppStateEpic, DeliveryId } from 'projects/insightui/src/app/state/state.types';
import { combineEpics, Epic } from 'redux-observable';
import { filter, map, switchMap, withLatestFrom, tap } from 'rxjs/operators';
import {
    FILTER_CHANGED,
    FILTER_REPORT_ACTIVATED
} from 'insightui.report-filter/report-filter.actions';
import { FairShareState } from 'insightui.fair-share/fair-share.types';
import { AnyAction } from 'redux';
import { Observable } from 'rxjs';
import { FAIR_SHARE_UPDATE_REPORT } from 'insightui.fair-share/fair-share.actions';
import { FairShareDataService } from 'insightui.fair-share/fair-share-data.service';
import {
    withLoadingIndicator,
    LOADING_COMPLETED
} from 'insightui.loading-indicator/loading-indicator.actions';
import { ReportContextNotificationService } from 'insightui.table/ng1services/report-context-notification.service';

@Injectable({
    providedIn: 'root'
})
export class FairShareEpic implements AppStateEpic<'fairShare'> {
    key: 'fairShare' = 'fairShare';

    constructor(
        private fairShareDataService: FairShareDataService,
        private reportContextNotificationService: ReportContextNotificationService
    ) {}

    getEpic(): Epic {
        const filterChangedEpic = (
            action$: Observable<AnyAction>,
            state$: Observable<FairShareState>
        ) =>
            action$.pipe(
                filter(FILTER_CHANGED.match),
                withLatestFrom(state$),
                map(([, state]) => state.deliveryId),
                filter(deliveryId => deliveryId != null),
                switchMap(deliveryId => this.updateFairShare(deliveryId)),
                tap(action => {
                    // after loading finished, notify about report context change
                    if (LOADING_COMPLETED.match(action)) {
                        this.notifyReportContextChanged();
                    }
                })
            );

        const fairShareActivatedEpic = (action$: Observable<AnyAction>) =>
            action$.pipe(
                filter(FILTER_REPORT_ACTIVATED.match),
                filter(action => action.payload.reportName === 'fair-share'),
                map(action => action.payload),
                switchMap(({ deliveryId }) => this.updateFairShare(deliveryId))
            );

        return combineEpics(filterChangedEpic, fairShareActivatedEpic);
    }

    private updateFairShare(deliveryId: DeliveryId): Observable<AnyAction> {
        return withLoadingIndicator(
            this.fairShareDataService
                .getReportDataForDeliveryId(deliveryId)
                .pipe(map(data => FAIR_SHARE_UPDATE_REPORT(data)))
        );
    }

    private notifyReportContextChanged() {
        this.reportContextNotificationService.notify();
    }
}
