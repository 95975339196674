import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {
    ComparisonPeriod,
    ComparisonPeriodType,
    MetadataPeriods,
    Period,
    Periodicity,
    PeriodRange
} from 'insightui.period/types';

@Injectable()
export class PeriodUtilityService {
    /**
     * Converts a period string (e.g. '20140499999030') into a Date object.
     *
     * @param period period string
     * @returns {Date} represented date
     */
    getDateFromPeriod(period: Period): Date {
        const year = parseInt(period.substring(0, 4), 10);
        const month = parseInt(period.substring(4, 6), 10) - 1;
        return new Date(year, month);
    }

    /**
     * Converts a Date object into a period string (e.g. '20140499999030').
     *
     * @param {Date} dateObj
     * @returns {string} period string
     */
    getPeriodFromDate(dateObj: Date): Period {
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const monthStr = month < 10 ? '0' + month : month;
        return '' + year + monthStr + '99999030';
    }

    /**
     * Creates an array of period strings from startPeriod to endPeriod (monthly steps).
     *
     * @param {Date} startPeriod
     * @param {Date} endPeriod
     * @returns {Array.<T>} period string array
     */
    getPeriodList(startPeriod: Date, endPeriod: Date): Period[] {
        const periodList = [];
        // create new Date object to break up object reference
        startPeriod = new Date(startPeriod);

        while (startPeriod >= endPeriod) {
            periodList.push(this.getPeriodFromDate(startPeriod));
            startPeriod.setMonth(startPeriod.getMonth() - 1);
        }
        return periodList.reverse();
    }

    createComparisonPeriod(
        metadataPeriods: MetadataPeriods,
        periodicity: Periodicity,
        comparisonPeriodType: ComparisonPeriodType,
        showComparisonPeriod: boolean
    ): ComparisonPeriod {
        let current: PeriodRange;
        let previous: PeriodRange;

        if (metadataPeriods && metadataPeriods.hasOwnProperty(periodicity)) {
            const metadataPeriod = metadataPeriods[periodicity];
            const startOfCurrentPeriod: Period = metadataPeriod[0];
            let endOfCurrentPeriod: Period;
            if (periodicity === 'monthly') {
                endOfCurrentPeriod =
                    comparisonPeriodType === 'pp'
                        ? metadataPeriod[1]
                        : metadataPeriod[metadataPeriod.length - 1];
            } else {
                endOfCurrentPeriod = metadataPeriod[metadataPeriod.length - 1];
            }
            current = {
                start: startOfCurrentPeriod,
                end: endOfCurrentPeriod,
                periodicity
            };

            if (showComparisonPeriod) {
                const startOfPreviousPeriod = endOfCurrentPeriod;
                const isPreviousMonthPeriod =
                    periodicity === 'monthly' && comparisonPeriodType === 'pp';
                const endOfPreviousPeriod = isPreviousMonthPeriod
                    ? this.getPreviousPeriod(startOfPreviousPeriod)
                    : this.getPreviousYearPeriod(startOfPreviousPeriod);
                previous = {
                    start: startOfPreviousPeriod,
                    end: endOfPreviousPeriod,
                    periodicity
                };
            }
        }

        if (periodicity === 'running_13_month') {
            const startOfPeriod = metadataPeriods.monthly[0];
            const endOfPeriod = this.getPreviousYearPeriod(startOfPeriod);
            current = { start: startOfPeriod, end: endOfPeriod, periodicity };
        }

        if (previous) {
            return { current, previous };
        } else {
            return { current };
        }
    }

    getPreviousPeriodWithOffset(period: Period, offsetMonths: number = 1): Period {
        const year = parseInt(period.substring(0, 4), 10),
            month = parseInt(period.substring(4, 6), 10),
            suffix = period.substr(6),
            d = new Date(year, month - 1, 1);
        d.setHours(0, -d.getTimezoneOffset(), 0, 0);
        d.setMonth(d.getMonth() - offsetMonths);
        return (
            d
                .toISOString()
                .slice(0, 7)
                .replace(/-/g, '') + suffix
        );
    }

    getPreviousYearPeriod(period: Period): Period {
        return this.getPreviousPeriodWithOffset(period, 12);
    }

    getNextYearPeriod(period: Period): Period {
        return this.getPreviousPeriodWithOffset(period, -12);
    }

    private getPreviousPeriod(period: Period): Period {
        return this.getPreviousPeriodWithOffset(period, 1);
    }
}
