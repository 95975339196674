<div *ngIf="getTotalChanged() > 0" class="loadstatus-newlyloaded-container">
    <div
        (click)="newlyLoadedToggle(); $event.preventDefault()"
        class="loadstatus-newlyloaded-box uitest-Div-LP-NewlyLoaded"
    >
        <div class="load-status-open">{{ getTotalChanged() }}</div>
        <div
            #headerContainer
            class="header-container-dropdown"
            *ngIf="newlyLoadedUpdates.show"
            style="display:block; cursor: default;"
        >
            <div class="header-container-filter-directives">
                <div class="uitest-Div-PF-Container dropdown-content" ng-transclude>
                    <div class="header-container-display-element">
                        <div
                            class="header-container-display-header  uitest-Div-LP-NewlyLoaded-header"
                        >
                            <div class="header-container-display-title">
                                Load Status Update
                            </div>
                            <em
                                class="fa fa-times"
                                (click)="newlyLoadedOnBlur(); $event.stopPropagation()"
                            ></em>
                        </div>
                        <div
                            class="header-container-display-body  uitest-Div-LP-NewlyLoaded-body"
                        >
                            <div *ngIf="newlyLoadedUpdates.ratioLoaded < 0.9">
                                <p>
                                    During the last data provision run the following data
                                    for
                                    {{ newlyLoadedUpdates.periodStr }} were provided:
                                </p>
                                <div *ngFor="let dim of newlyLoadedDimensions">
                                    <div
                                        *ngIf="
                                            newlyLoadedUpdates.result[dim.key].length > 0
                                        "
                                    >
                                        <div>
                                            {{ dim.value }}
                                            ({{
                                                newlyLoadedUpdates.result[dim.key].length
                                            }}):
                                        </div>
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let item of newlyLoadedUpdates.result[
                                                        dim.key
                                                    ]
                                                "
                                            >
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    newlyLoadedUpdates.ratioLoaded >= 0.9 &&
                                    newlyLoadedUpdates.inverse.productGroup.length > 0
                                "
                            >
                                <p>
                                    In the {{ newlyLoadedUpdates.periodStr }} delivery the
                                    following data are still missing:
                                </p>
                                <div *ngFor="let dim of newlyLoadedDimensions">
                                    <div
                                        *ngIf="
                                            newlyLoadedUpdates.inverse[dim.key].length > 0
                                        "
                                    >
                                        <div>
                                            {{ dim.value }}
                                            ({{
                                                newlyLoadedUpdates.inverse[dim.key]
                                                    .length
                                            }}):
                                        </div>
                                        <ul>
                                            <li
                                                *ngFor="
                                                    let item of newlyLoadedUpdates
                                                        .inverse[dim.key]
                                                "
                                            >
                                                {{ item }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    newlyLoadedUpdates.ratioLoaded >= 0.9 &&
                                    newlyLoadedUpdates.inverse.productGroup.length == 0
                                "
                            >
                                <p>
                                    During the last data provision run all data for
                                    {{ newlyLoadedUpdates.periodStr }} were provided.
                                </p>
                            </div>
                        </div>
                        <div
                            class="header-container-display-footer uitest-Div-LP-NewlyLoaded-footer"
                        >
                            <a
                                (click)="
                                    newlyLoadedOnBlur();
                                    openLoadStatusDialog();
                                    $event.stopPropagation()
                                "
                            >
                                See Complete Status
                            </a>
                        </div>
                        <div
                            class="header-container-apply uitest-Div-HC-Apply-newlyLoaded"
                            enable-key-nav="enter"
                            (click)="newlyLoadedSetChecked()"
                        >
                            OKAY
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
