import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { DeliveryInfo } from 'insightui.metadata/metadata.types';
import { Observable } from 'rxjs';
import { LoadStatusDialogComponent } from '../load-status-dialog/load-status-dialog.component';

@Component({
    selector: 'gfk-load-status-button',
    templateUrl: './load-status-button.component.html',
    styleUrls: ['./load-status-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadStatusButtonComponent {
    private readonly MODAL_DIALOG_WIDTH = '600px';

    deliveryInfo$: Observable<DeliveryInfo>;

    constructor(private matDialog: MatDialog, private store: Store) {
        this.deliveryInfo$ = this.store.select(state => state.metadata.deliveryInfo);
    }

    showModal() {
        this.matDialog.open(LoadStatusDialogComponent, {
            data: {},
            width: this.MODAL_DIALOG_WIDTH,
            autoFocus: false
        });
    }
}
