<div class="container-fluid">
    <div class="row">
        <div class="col-xs-12 col-md-2 col-md-push-5">
            <h1 id="tableCaption">Status Dashboard</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-md-2 col-md-push-5">
            <table
                class="table table-bordered table-striped"
                aria-describedby="tableCaption"
            >
                <thead>
                    <tr>
                        <th scope="col">Service</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let service of listOfServices | async">
                        <td>{{ service.name }}</td>
                        <td>
                            <span
                                class="fa"
                                [ngClass]="{
                                    'fa-minus': service.status == 'dead',
                                    'fa-check': service.status == 'alive'
                                }"
                                aria-hidden="true"
                            ></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
