import { ComponentFactoryResolver, Inject, Injector } from '@angular/core';
import { UUID } from 'insightui.global/global.module';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { PerformanceIndicatorHeaderComponent } from 'insightui.table/components/plugins/performanceIndicator/performance-indicator-header.component';
import {
    BaseTablePluginOptions,
    TableHeaderPlugin
} from 'insightui.table/components/plugins/shared/table-plugin.models';

export interface HeaderComponentPluginOptions extends BaseTablePluginOptions {
    component: string;
    kpis?: string[];
    restrict?: string[];

    [setting: string]: {};
}

/**
 * Table Header Plugin for displaying advanced components in a header.
 *
 * This is basically nothing more than a factory for components and should be used
 * for implementations that go beyond simple rendering operations and event handlers
 * (e.g. DropDown Dialogs).
 */
export class HeaderComponentPlugin extends TableHeaderPlugin {
    public static CONTENT_COMPONENTS = {
        PerformanceIndicatorHeader: PerformanceIndicatorHeaderComponent
    };

    name;

    private factory;
    private CELL_FIELD = 'columnHeader';

    constructor(
        protected injector: Injector,
        protected configuration: HeaderComponentPluginOptions,
        protected table?: AuthoringDatatableComponent
    ) {
        super(injector, configuration, table);

        const uuid = this.injector.get(UUID);
        this.name = 'HeaderComponent-' + uuid();

        const compiler: ComponentFactoryResolver = this.injector.get(
            ComponentFactoryResolver
        );
        const component =
            HeaderComponentPlugin.CONTENT_COMPONENTS[this.configuration.component];
        if (!component) {
            console.error(
                'Unknown component ' + this.configuration.component + ' requested'
            );
            this.select = () => false;
            return;
        }
        // Overwrite selection if the component offers an own implementation
        if (component.select) {
            this.select = cell => component.select(cell, this.configuration);
        }
        this.factory = compiler.resolveComponentFactory(component);
    }

    extendExport(exportData) {
        return exportData;
    }

    select(cell: DatatableHeaderCellComponent): boolean {
        return super.select(cell);
    }

    protected doEnable(cell: DatatableHeaderCellComponent) {
        const rootNode = this.getRootNode(cell);

        if (!rootNode || this.isActive(rootNode)) {
            return;
        }

        const component = this.createComponent(cell);
        cell.extensionRoot.insert(component.hostView);
        this.markAsActive(rootNode);
        // TODO: Find out how to avoid the timing issue (header is not ready) and remove the
        //       timeout
        if (cell.update) {
            window.setTimeout(() => cell.update(), 1);
        }
    }

    protected doDisable(cell: DatatableHeaderCellComponent) {
        const rootNode = this.getRootNode(cell);
        if (!rootNode || !this.isActive(rootNode)) {
            return;
        }
        this.markAsInactive(rootNode);
    }

    private createComponent(cell: DatatableHeaderCellComponent) {
        const component = cell.extensionRoot.createComponent(this.factory);
        const instance = component.instance;

        if (instance && this.configuration) {
            Object.keys(this.configuration).forEach(key => {
                instance[key] = this.configuration[key];
            });
            instance[this.CELL_FIELD] = cell.columnHeader;
        }
        return component;
    }

    private getRootNode(cell: DatatableHeaderCellComponent): Element {
        const element: Element = cell.element.nativeElement;
        if (element.classList.contains('datatable-header-cell-label-wrapper')) {
            return element;
        }
        return element.querySelector('.datatable-header-cell-label-wrapper');
    }
}
