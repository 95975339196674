import { Inject, Injectable } from '@angular/core';
import { ChartElementService } from 'insightui.export/powerpoint/services/chart-element.service';

@Injectable()
export class VerticalChartStatisticService {
    private chartRowSetService;

    constructor(
        @Inject('$injector') $injector,
        private chartElementService: ChartElementService
    ) {
        this.chartRowSetService = $injector.get('ChartRowSetService');
    }

    getNumberOfRows(): number {
        const activeChartModel = this.getFirstActiveChartModel();
        const chartRows = activeChartModel.getChartRows();

        return chartRows.length - this.getNumberOfTotalRows();
    }

    getNumberOfLegendItems(): number {
        const activeChartModel = this.getFirstActiveChartModel();
        const groupedLastDimension = activeChartModel.groupLastDimension();

        return groupedLastDimension.length;
    }

    getHeightOfChartRow(): number {
        const chartElement: HTMLElement = this.chartElementService.getChartElementFromDom();
        const charRowElements = Array.from(
            chartElement.querySelectorAll('.chart-ctrl-chart-row')
        );

        if (charRowElements.length === 0) {
            return 0;
        }

        return charRowElements[0].getBoundingClientRect().height;
    }

    getNumberOfTotalRows(): number {
        const activeChartModel = this.getFirstActiveChartModel();
        const chartRows = activeChartModel.getChartRows();

        return chartRows[0].id === 'Total' ? 1 : 0;
    }

    private getFirstActiveChartModel() {
        const chartConfig = this.chartRowSetService.getConfiguration();
        const activeCharts = this.chartRowSetService.getActiveCharts(chartConfig);

        return activeCharts[0].model;
    }
}
