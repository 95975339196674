import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FavoriteSelectByReportDirective,
    FavouriteSelectByReportComponent
} from './favourite-select-by-report.component';

@NgModule({
    imports: [CommonModule],
    declarations: [FavouriteSelectByReportComponent, FavoriteSelectByReportDirective],
    exports: [FavouriteSelectByReportComponent],
    providers: []
})
export class FavoriteSelectByReportModule {}
