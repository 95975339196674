import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'gfk-fair-share-drill-button',
    templateUrl: 'fair-share-drill-button.component.html',
    styleUrls: ['fair-share-drill-button.component.scss']
})
export class FairShareDrillButtonComponent {
    @Input() isActive: boolean;

    @Input() bold = false;

    @Output() drill: EventEmitter<void> = new EventEmitter();

    onClick(): void {
        if (this.isActive) {
            this.drill.emit();
        }
    }
}
