<div class="clientInfo" *ngIf="clientId && clientName">
    <span> {{ clientName }} - {{ clientId }}</span>
    <a
        href="#"
        class="closer"
        (click)="goHome()"
        title="Exit Client View"
        angulartics2On
        angularticsCategory="ClientSwitch"
        angularticsAction="ExitClientView"
    ></a>
</div>
