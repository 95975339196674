import { Injectable } from '@angular/core';

/**
 * RoundEvenService
 * This Service should always be used when floating numbers are displayed.
 * It offers a transformation function for those numbers and transforms them based
 * on the current locale: 10.12" becomes "10,12".
 *
 * @export class
 * @class RoundEvenService
 */
@Injectable()
export class RoundEvenService {

    /***
     * the theory used is times the decimal by exponential first, then rounded it.
     * last step will be to divide by exponential and return the result.
     *
     * @param num
     * @param decimalPlaces
     * @private
     */
    private evenRoundImp(num, decimalPlaces) {

        let isNegativeValue = false;
        let processNum = num;

        // there will be different handling for negative value, as it will need to
        // be convert to positive value, in order to be rounded correctly.
        if(num < 0) {
            isNegativeValue = true;
            processNum = Math.abs(num);
        }

        const decimal = decimalPlaces || 0;
        const positiveExponential = Number(processNum + "e" + decimal);
        const roundedPositiveExponential = Math.round(positiveExponential);
        const returnResult = Number(roundedPositiveExponential + "e-" + decimal);

        // if initially it was negative value and the final result is more than zero,
        // we shall append the negative symbol back to the result again
        return isNegativeValue && returnResult > 0 ? -returnResult : returnResult;
    }

    /**
     * Transforms floating numbers into the correct locale representation
     *
     * @param {number} num the to be transformed number
     * @param {number} decimalPlaces how many decimalplaces after the comma
     * @returns {string} string representation of the number
     * @memberof RoundEvenService
     */
    transform(num: number, decimalPlaces: number): string {
        if (!Number.isFinite(num)) {
            return null;
        }
        const locale = window.navigator.language ? window.navigator.language : 'en-EN';

        return new Intl.NumberFormat(locale, {
            minimumFractionDigits: decimalPlaces,
            maximumFractionDigits: decimalPlaces
        }).format(this.evenRoundImp(num, decimalPlaces));
    }
}
