import { EventEmitter, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { map, take } from 'rxjs/operators';
import * as _ from 'lodash';


import { DeliveryInfo } from 'insightui.metadata/metadata.types';
import { ILogger, LogService } from 'insightui.core/services/logging';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { NewLoadStatusUserService } from 'insightui.core/services/newLoadStatusUserService';
import { NewReportingDimensionInfoService } from 'insightui.core/services/newReportingDimensionInfo.service';

@Injectable({
    providedIn: 'root'
})
export class NewLoadStatusUpdatesService {

    private logger: ILogger;

    loadStatusOpened: EventEmitter<boolean> = new EventEmitter();

    constructor(private logService: LogService, private metaDataService: MetadataService,
                private newLoadStatusUserService: NewLoadStatusUserService,
                private newReportingDimensionInfoService: NewReportingDimensionInfoService) {
        this.logger = logService.getLogger('NewLoadStatusUpdatesService');
    }

    static getDateFromPeriod(period) {
        const datePipe = new DatePipe('en-US');
        const year = period.substring(0, 4);
        const month = _.parseInt(period.substring(4, 6)) - 1;

        return datePipe.transform(new Date(year, month), 'MMM yy').toUpperCase();
    }

    getNewlyLoaded() {
        return this.metaDataService.getAllDeliveryInfosOfCurrentCountry$()
            .pipe(
                map(deliveryInfos => {
                    const currentDocRevId = this.metaDataService.getDocRevId();

                    return _.map(deliveryInfos, (deliveryInfo, index) => {
                        this.logger.debug('deliveryInfos', deliveryInfos);

                        return this.newLoadStatusUserService.getLastLoadStatusCheck(
                            deliveryInfo.country,
                            deliveryInfo.content.period
                        ).toPromise().then(
                            (lastCheck) => {
                                const lastCheckDate = _.get(
                                    lastCheck,
                                    'modifiedTime'
                                ).replace('GMT', '+0000');

                                return this.updateList(deliveryInfo, currentDocRevId,
                                    Date.parse(lastCheckDate) / 1000, index
                                );
                            },
                            (err) => {
                                if (_.get(err, 'status') === 404) {
                                    // element could not be found
                                    return this.updateList(deliveryInfo, currentDocRevId, 0, index);
                                } else {
                                    // some other error, e.g. endpoint not reachable
                                    return this.updateList(deliveryInfo, currentDocRevId,null, index);
                                }
                            }
                        );
                    });
                }),
                take(1)
            );
    }

    private updateList(deliveryInfo: DeliveryInfo, currentDocRevId, lastCheck, index) {
        const hierarchyDimensions = Object.values(deliveryInfo.hierarchyDimensions);

        const allAvailable = hierarchyDimensions.filter(
            loadState => loadState.loadStatus === 'loaded' || loadState.loadStatus === 'notLoaded'
        );
        const allLoaded = allAvailable.filter(loadState => loadState.loadStatus === 'loaded');
        const newlyLoaded = allLoaded.filter(loadState => lastCheck < _.get(loadState, 'lastChange'));
        const period = deliveryInfo.content.period;

        const result = this.initResult();
        const inverse = this.initResult();

        const rootDimension = hierarchyDimensions.find(d => d.dim === 'area');
        this.buildUpdateList([rootDimension], hierarchyDimensions, lastCheck, result, inverse);

        return {
            active: deliveryInfo.revisionId === currentDocRevId,
            docId: deliveryInfo.revisionId,
            periodId: index,
            period: period,
            periodStr: NewLoadStatusUpdatesService.getDateFromPeriod(period.toString()).toUpperCase(),
            result: result,
            inverse: inverse,
            ratioLoaded:
                Math.round((newlyLoaded.length / allAvailable.length) * 100) / 100,
            ratioLoadedAll:
                Math.round((allLoaded.length / allAvailable.length) * 100) / 100,
            countryCode: deliveryInfo.country,
            hasAvailableData: rootDimension.hasAvailableData
        };
    }

    private buildUpdateList(dimensions, allDimensions, lastCheck, result, inverse) {
        dimensions.forEach(node => {
            let dimResult;
            const dimensionType = node.dim;

            // result condition
            if (node.loadStatus === 'loaded' && lastCheck !== null
                && lastCheck < _.get(node, 'lastChange', 0)
            ) {
                dimResult = _.get(result, dimensionType);
                this.addUnique(dimResult, node.title);
            }

            // inverse condition
            if (node.loadStatus === 'notLoaded') {
                dimResult = _.get(inverse, dimensionType);
                this.addUnique(dimResult, node.title);
            }

            if (dimensionType === 'productGroup') {
                return;
            }

            const childNodes = allDimensions.filter(d => d.parentId === node.id);
            this.buildUpdateList(childNodes, allDimensions, lastCheck, result, inverse);
        });
    }

    private addUnique(dimResult, nodeName) {
        if (!_.find(dimResult, elem => elem === nodeName)) {
            dimResult.push(nodeName);
        }
    }

    private initResult() {
        const dims = this.newReportingDimensionInfoService.getDimensions();
        return _.zipObject(dims,
            _.map(dims, () => {
                return [];
            })
        );
    }
}
