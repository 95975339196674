import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'insightui.dashboard/dashboard.service';
import { DashboardServiceStatus } from 'insightui.dashboard/dashboard.types';
import { Observable, interval, concat } from 'rxjs';
import { switchMapTo } from 'rxjs/operators';

@Component({
    selector: 'gfk-dashboard',
    templateUrl: 'dashboard.html'
})
export class DashboardComponent implements OnInit {
    public listOfServices: Observable<DashboardServiceStatus[]>;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit(): void {
        const status$ = this.dashboardService.checkStatusOfServices();
        this.listOfServices = concat(status$, interval(10000).pipe(switchMapTo(status$)));
    }
}
