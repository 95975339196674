import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { filter, take } from 'rxjs/operators';
import { DeliveryInfo } from '../metadata.types';
import * as _ from 'lodash';
import { MsCurrentCountryChanged } from 'insightui.metadata/state/metadata.actions';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {
    private logger: ILogger;
    private deliveryInfo: DeliveryInfo;
    private dimensionIndex = {};
    private metadataService = {
        deliveryInfo: null,
        allDeliveryInfosOfSelectedCountry: [],
        revisions: []
    };
    //private deferredPeriodId = $q.defer();
    //private deferredAllDeliveryInfosOfSelectedCountry = $q.defer();
    private revisions: any;

    constructor(private store: Store, logService: LogService) {
        this.logger = logService.getLogger('MetadataService');
        this.logger.debug('ctor');

        const deliveryInfo$ = this.store.select(state => state.metadata.deliveryInfo);
        this.revisions = this.store.select(state => state.metadata.revisions)
            .pipe(
                filter(revisions => revisions && revisions.length > 0),
                take(1)
            );

        deliveryInfo$
            .pipe(filter(deliveryInfo => !!deliveryInfo))
            .subscribe(deliveryInfo => {
                this.deliveryInfo = deliveryInfo;
                this.dimensionIndex = this.createDimensionIndex(deliveryInfo.dimensions);
                this.logger.debug('created dimensionIndex', this.dimensionIndex);
            });
    }

    private createDimensionIndex(dimensions) {
        const index = {};
        Object.keys(dimensions).forEach(dimKey => {
            Object.keys(dimensions[dimKey]).forEach(key => {
                index[key] = dimensions[dimKey][key];
            });
        });
        return index;
    }

    public getAllDeliveryInfosOfCurrentCountry$() {
        return this.store
            .select(state => state.metadata.deliveryInfosOfCurrentCountry)
            .pipe(
                filter(di => di),
                take(1)
            );
    }

    public getCurrentCountryCode(): string | null {
        return this.store.selectSnapshot(state => state.metadata.currentCountry);
    }

    public getCurrentPeriodIndex(): number {
        const metadata = this.store.selectSnapshot(state => state.metadata);
        if (!metadata.currentPeriodId) {
            return null;
        }
        const periodId = metadata.currentPeriodId.toString();
        const periodIndex = metadata.deliveryInfosOfCurrentCountry.findIndex(
            di => di.content.period === periodId
        );
        return periodIndex;
    }

    public getDeliveryInfo() {
        return this.deliveryInfo;
    }

    public getDocRevId() {
        return this.store.selectSnapshot(
            state => state.metadata.currentDocumentRevisionId
        );
    }

    public getRetailerName() {
        const channels = (this.deliveryInfo.dimensions.channel as unknown) as ({
            type: string;
            title: string;
        }[]);
        return Object.values(channels).find(c => c.type === 'retailer').title;
    }

    public getCurrency() {
        return this.deliveryInfo.content.currency;
    }

    public getDimensionById(id: string) {
        if (this.dimensionIndex.hasOwnProperty(id)) {
            return this.dimensionIndex[id];
        }
        return null;
    }

    public getDimensionTitleById(id: string) {
        if (this.dimensionIndex.hasOwnProperty(id)) {
            return this.dimensionIndex[id].title;
        }
        return id;
    }

    public getChannelById(channelId: string) {
        if (this.deliveryInfo) {
            const channels = this.deliveryInfo.dimensions.channel;
            if (channels.hasOwnProperty(channelId)) {
                return channels[channelId];
            }
        }
        return { type: '', title: '' };
    }

    public getPriceClasses() {
        return this.deliveryInfo.dimensions.priceClass;
    }

    public getPriceClassById(priceClassId: string) {
        if (this.deliveryInfo) {
            const priceClasses = this.deliveryInfo.dimensions.priceClass;
            if (priceClasses.hasOwnProperty(priceClassId)) {
                return priceClasses[priceClassId];
            }
        }
        return { title: '' };
    }

    public getFeatures() {
        return this.deliveryInfo.dimensions.feature;
    }

    public getFeatureById(featureId: string) {
        const features = this.deliveryInfo.dimensions.feature;
        if (features.hasOwnProperty(featureId)) {
            return features[featureId];
        }
        return null;
    }

    public getPosTypeById(posTypeId) {
        const posTypes = this.deliveryInfo.dimensions.posType;
        if (posTypes.hasOwnProperty(posTypeId)) {
            return posTypes[posTypeId];
        }
        return '';
    }

    public getSelectedPeriodId(): Promise<any> {
        return null;
    }

    /**
     * Loads the metadata file.
     *
     * @param filterParam filterParam which filters the data. >> 'tools=xxx'
     * @param callback callback to call after the function has done.
     */
    loadMetadata(country, period) {
        //logger.debug('loadMetadata', { country, period });

        //var deferred = $q.defer();

        // if we load metadata a second time, we provide new promises that we can resolve
        if (this.metadataService.deliveryInfo) {
            //this.deferredPeriodId = $q.defer();
            //this.deferredAllDeliveryInfosOfSelectedCountry = $q.defer();
        }

        this.revisions.subscribe(documentData => {
            if (!this.verifyData(documentData)) {
                return;
            }

            var selectedRevision = documentData.find(
                rev => rev.regionIsoCode === country
            );
            var selectedCountry =
                selectedRevision && selectedRevision.regionIsoCode;
            if (!selectedCountry) {
                var sorted = _.sortBy(documentData, 'periodId').reverse();
                selectedCountry = sorted[0].regionIsoCode;
            }

            this.store.dispatch(
                new MsCurrentCountryChanged(selectedCountry)
            );

            const availableRevisionsByCountry = this.getRevisionsGroupedByCountry(
                documentData
            );

            const allRevisionsOfSelectedCountry = this.getAvailableRevisions(
                availableRevisionsByCountry[selectedCountry]
            );
            // logger.debug(
            //     'allRevisionsOfSelectedCountry',
            //     allRevisionsOfSelectedCountry
            // );

            var availablePeriods = this.getAvailablePeriods(documentData);
            //logger.debug('availablePeriods', availablePeriods);

            this.loadDeliveryInfosOfCountry(allRevisionsOfSelectedCountry).then(
                deliveryInfosOfCountry => {
                    // now filter out undefined deliveryInfos here
                    // that could happen, if document is corrupt on the server
                    this.metadataService.allDeliveryInfosOfSelectedCountry = deliveryInfosOfCountry.filter(
                        di => di
                    );
                    // logger.debug(
                    //     'metadataService.allDeliveryInfosOfSelectedCountry',
                    //     metadataService.allDeliveryInfosOfSelectedCountry
                    // );

                    this.extractCountries(
                        availableRevisionsByCountry,
                        availablePeriods,
                        selectedCountry,
                        allRevisionsOfSelectedCountry,
                        this.metadataService.allDeliveryInfosOfSelectedCountry
                    );

                    if (
                        !this.verifySchemaVersions(
                            this.metadataService.allDeliveryInfosOfSelectedCountry
                        )
                    ) {
                        return;
                    }

                    // Calculate initial period
                    if (!_.isFinite(period)) {
                        period = this.getFirstPeriodWithSufficientData(
                            this.metadataService.allDeliveryInfosOfSelectedCountry
                        );
                    }

                    // switch back to current period for country
                    if (
                        period >=
                        this.metadataService.allDeliveryInfosOfSelectedCountry.length
                    ) {
                        period = 0;
                        //$location.search('period', null);
                    }

                    this.metadataService.deliveryInfo =
                        this.metadataService.allDeliveryInfosOfSelectedCountry[period];

                    //logger.debug(`resolving periodId promise:`, period);
                    //this.deferredPeriodId.resolve(period);

                    // this.dispatchAndResolve(
                    //     metadataService,
                    //     deferredAllDeliveryInfosOfSelectedCountry,
                    //     deferred
                    // );
                },
                function (errorResponse) {
                    //$rootScope.$broadcast('loadingError', errorResponse.status);
                    //deferred.reject();
                    // n-common logging
                }
            );
        },
            function () {
                //deferred.reject();
            }
        );

        return null;
        //return deferred.promise;
    }

    verifyData(data) {
        if (!data[0] || !data[0].activeRevisionId) {
            //$rootScope.$broadcast('loadingError');
            //ErrorService.reportError('landingpage-nodelivery', {});
            return false;
        }

        return true;
    }

    getAvailablePeriods(allRevisions) {
        var periodIds = allRevisions.map(rev => rev.periodId);
        return _.uniq(periodIds)
            .sort()
            .reverse();
    }

    /**
     * Returns all revisions grouped by country with only the latest document revision id per period.
     * @param {*} documentData
     */
    getRevisionsGroupedByCountry(documentData) {
        var availableRevisionsByCountry = {};
        var groupByRegion = _.groupBy(documentData, 'regionIsoCode');
        _.forOwn(groupByRegion, function (rValue, rKey) {
            var singlePeriods = [];
            var groupByPeriod = _.groupBy(rValue, 'periodId');

            // if there are more revisions for the same period, take the latest
            _.forOwn(groupByPeriod, function (pValue) {
                var periods = _.sortBy(pValue, 'activeRevisionId').reverse();
                singlePeriods.push(_.head(periods));
            });
            availableRevisionsByCountry[rKey] = singlePeriods;
        });

        //logger.debug('availableRevisionsByCountry', availableRevisionsByCountry);

        return availableRevisionsByCountry;
    }

    getAvailableRevisions(availableMetadata) {
        return availableMetadata.slice(0, Math.min(2, availableMetadata.length));
    }

    loadDeliveryInfosOfCountry(revisions): Promise<any> {
        return null;
        // var queue = _.map(revisions, rev => {
        //     const docId = rev.activeRevisionId;
        //     const cacheKey = `metadata.${docId}`;
        //     const cachedDocument = window.sessionStorage.getItem(cacheKey);

        //     return cachedDocument
        //         ? $q.resolve(JSON.parse(cachedDocument).deliveryInfo)
        //         : DocumentLoaderService.loadDocument(rev)
        //             .toPromise()
        //             .then(
        //                 result => {
        //                     window.sessionStorage.setItem(
        //                         cacheKey,
        //                         JSON.stringify(result)
        //                     );
        //                     return result.deliveryInfo;
        //                 },
        //                 error => {
        //                        logger.warn(
        //                           'error while calling revisions endpoint',
        //                            error
        //                        );
        //                     // this will return undefined
        //                 }
        //             );
        // });
        // return $q.all(queue);
    }

    extractCountries(
        availableRevisionsByCountry,
        availablePeriods,
        selectedCountry,
        allRevisionsOfSelectedCountry,
        allDeliveryInfosOfSelectedCountry
    ) {
        // this creates a list of all countries and their deliveries based on the revisions
        // anyway it could happen, that some of the deliveries could not be loaded
        let countries = this.prepareCountries(
            availableRevisionsByCountry,
            availablePeriods,
            selectedCountry
        );

        // from the countries, remove those docIds and periods that are actually unavailable
        // first get a list of all docIds that cannot be loaded
        const unavailableDocIds = this.determineUnavailableDocIds(
            allRevisionsOfSelectedCountry,
            allDeliveryInfosOfSelectedCountry
        );

        // then filter out all entries from the countries that reference such a docId
        countries = this.removeUnavailableCountries(countries, unavailableDocIds);
        //logger.debug('countries after filtering', countries);

        // now make that list available to others
        //this.store.dispatch(new MsCountriesLoaded(countries));
    }

    prepareCountries(a, b, c) {
        return null;
    }

    determineUnavailableDocIds(a, b) {
        return null;
    }

    removeUnavailableCountries(a, b) {
        return null;
    }

    verifySchemaVersions(a) {
        return false;
    }

    getFirstPeriodWithSufficientData(a) {
        return null;
    }

    dispatchAndResolve(a, b, c) {
        return null;
    }
}
