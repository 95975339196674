import { EventEmitter, Injectable } from '@angular/core';
import { Column, DataRow } from '../models/datatable.models';
import { NotificationEmitter } from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';
import { SortingModel } from 'insightui.core/models/sorting.model';
import { ColumnWidthUtilService } from 'insightui.table/components/datatable/shared/services/column-width-util.service';

/**
 * This service calculates the column width based on the cell content
 *
 * Note: Due to reasons of performance for every column the largest
 * word with respect to the number of characters is selected. The
 * width of this target is then calculated for the column.
 */

@Injectable()
export class AutosizeUtilService {
    @NotificationEmitter(NotificationType.LayoutUpdate)
    public onAutosize$: EventEmitter<void>;

    constructor(
        private sortModel: SortingModel,
        private columnWidthUtilService: ColumnWidthUtilService
    ) {}

    private static rowsEmpty(rows: DataRow[]) {
        return rows.filter(row => !!row).length === 0;
    }

    autoResizeColumns(columns: Column[], rows: DataRow[]) {
        if (AutosizeUtilService.rowsEmpty(rows)) {
            return;
        }
        this.setColumnWidth(columns, rows);
        this.onAutosize$.emit();
    }

    private setColumnWidth(columns: Column[], rows: DataRow[]) {
        columns.forEach((column, columnIndex) => {
            const width = this.columnWidthUtilService.calculateColumnWidth(
                columnIndex,
                columns,
                rows
            );
            column.width = width;
            column.minWidth = width;
        });
    }
}
