/**
 * Main Module for the Multidimensional Chart Implementation
 */
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QueryResponseParserService } from 'insightui.data/query/query-response-parser.service';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { ReportDataRemoteService } from 'insightui.data/remote/report-data-remote.service';
import { ResponseMapperService } from 'insightui.data/operator/response-mapper.service';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { GlobalModule } from 'insightui.global/global.module';

import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { CrossTabMapper } from 'insightui.data/operator/crosstab-mapper.service';
import { MissingTotalMapper } from 'insightui.data/operator/missingtotal-mapper.service';
import { AuxiliaryRowMapper } from 'insightui.data/operator/auxiliary-row-mapper.service';
import { RemoveRowMapper } from 'insightui.data/operator/remove-row-mapper.service';
import { ResponseSorter } from 'insightui.data/operator/response-sorter.service';
import { ConditionalTotalRemover } from 'insightui.data/operator/conditional-total-remover.service';
import { PerformanceIndicatorMapperService } from 'insightui.data/operator/performance-indicator-mapper.service';

@NgModule({
    providers: [
        QueryResponseParserService,
        ReportDataRemoteService,
        VisualizationContextService,
        ResponseMapperService,
        CrossTabMapper,
        ResponseSorter,
        AuxiliaryRowMapper,
        RemoveRowMapper,
        ConditionalTotalRemover,
        MissingTotalMapper,
        DrillingService,
        AngularInjectorResolver,
        MetadataService,
        PerformanceIndicatorMapperService
    ],
    declarations: [],
    imports: [GlobalModule, CommonModule, FormsModule, HttpClientModule]
})
export class DataModule {}
