import { Injectable } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';

/**
 * Factory for retrieving the ng1 BrandHighlightService.
 */

export enum TopBottomFilterStatus {
    TOP = 'top',
    ALL = 'all'
}

@Injectable()
export class TopBottomModelService {
    private ng1TopBottomModelService;

    constructor(injector: AngularInjectorResolver) {
        injector.get().subscribe($injector => {
            this.ng1TopBottomModelService = $injector.get('TopBottomModel');
        });
    }

    public readData() {
        return this.ng1TopBottomModelService.readData();
    }

    public getMaxItem() {
        return this.ng1TopBottomModelService.getMaxItem();
    }

    public select(title, value, type) {
        return this.ng1TopBottomModelService.select(title, value, type);
    }

    public setToAll() {
        if (this.isSetTo(TopBottomFilterStatus.TOP)) {
            this.readData()
                .select('All', 0, 'all')
                .applyChanges();
        }
    }

    public setToTop() {
        if (this.isSetTo(TopBottomFilterStatus.ALL)) {
            this.readData()
                .select('Top', this.getMaxItem(), '#')
                .applyChanges();
        }
    }

    private isSetTo(status: TopBottomFilterStatus) {
        const topBottomFilters = this.readData();
        const selectedFilter = topBottomFilters.filter(item => item.selected)[0];
        const currentStatus =
            selectedFilter.type === '#'
                ? TopBottomFilterStatus.TOP
                : TopBottomFilterStatus.ALL;
        return currentStatus === status;
    }
}
