import { Inject, Injectable, Injector } from '@angular/core';
import {
    POWERPOINT_EXPORT_TYPES,
    PowerpointExport,
    PowerpointExportSheets,
    PowerpointExportTypes
} from 'insightui.export/types';
import { ChartDetectionService } from 'insightui.export/powerpoint/services/chart-detection.service';
import { SavePowerpointService } from 'insightui.export/powerpoint/services/save-powerpoint.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PowerpointExportActiveReportService {
    constructor(
        private chartDetectionService: ChartDetectionService,
        private savePowerpointService: SavePowerpointService,
        @Inject(POWERPOINT_EXPORT_TYPES)
        private powerpointExportTypes: PowerpointExportTypes,
        private injector: Injector
    ) {}

    sheetsOfCurrentReport(): Observable<PowerpointExportSheets> {
        const pptExportInstance = this.getPowerpointExport();

        return pptExportInstance.getPowerpointExportSheets();
    }

    exportCurrentReport(): Promise<void> {
        const pptExportInstance = this.getPowerpointExport();

        return this.savePowerpointService.saveCurrentReport(pptExportInstance);
    }

    private getPowerpointExport(): PowerpointExport {
        const pptExportTypeKey = this.chartDetectionService.getPowerpointExportType();
        const pptExportType = this.powerpointExportTypes[pptExportTypeKey];

        return this.injector.get(pptExportType);
    }
}
