import { Injectable } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import {
    DragulaConfiguration,
    DefaultDragulaServiceConfiguration,
    ChildrenDragulaServiceConfiguration
} from './header-drag-configuration.service';

const SCROLL_PER_MOVE_PIXEL = 18;
const EXTRA_PADDING_X_PIXEL = 20;

@Injectable()
export class HeaderDragService {
    constructor(private dragulaService: DragulaService) {}

    setDragulaServiceOptions(
        dragulaContainerId: string,
        dragulaConfigurationType: DragulaConfiguration = DragulaConfiguration.DEFAULT,
        customConfiguration?
    ): void {
        this.dragulaService.setOptions(
            dragulaContainerId,
            this.getDragulaConfigurationBy(dragulaConfigurationType, customConfiguration)
        );
    }

    getDragulaConfigurationBy(
        dragulaConfigurationType: DragulaConfiguration,
        customConfiguration?
    ) {
        if (dragulaConfigurationType === DragulaConfiguration.DEFAULT) {
            return DefaultDragulaServiceConfiguration;
        } else if (dragulaConfigurationType === DragulaConfiguration.CHILDREN) {
            return ChildrenDragulaServiceConfiguration;
        }
        return customConfiguration;
    }

    onDropModelChange(dropModelEvent) {
        this.dragulaService.dropModel.subscribe(dropModelEvent);
    }

    enableAutoScrollOn(
        autoScrollViewportId: string,
        dragulaContainerIds: string[]
    ): void {
        dragulaContainerIds.forEach(dragulaContainerId =>
            this.enableAutoScrollBy(autoScrollViewportId, dragulaContainerId)
        );
    }

    private enableAutoScrollBy(
        autoScrollViewportId: string,
        dragulaContainerId: string
    ): void {
        const targetViewport = document.querySelector(autoScrollViewportId);
        const drake = this.dragulaService.find(dragulaContainerId).drake;
        if (!targetViewport || !drake) {
            console.warn(
                'unable to enable auto scroll on ' +
                    autoScrollViewportId +
                    ' and ' +
                    dragulaContainerId
            );
            return;
        }

        let onMouseMoveEvent;
        let dragObj = { autoScroll: null };
        drake.on('drag', (el, source) => {
            dragObj = { autoScroll: null };
            onMouseMoveEvent = this.onMouseMove.bind(
                this,
                el.getBoundingClientRect(),
                dragObj,
                targetViewport
            );
            document.addEventListener('mousemove', onMouseMoveEvent);
        });

        drake.on('dragend', el => {
            clearInterval(dragObj.autoScroll);
            document.removeEventListener('mousemove', onMouseMoveEvent);
        });

        drake.on('cloned', (clone, original, type) => {
            if (type === 'copy') {
                return;
            }
            clone.style.setProperty('min-width', original.clientWidth + 'px');
        });
    }

    private enabledHeaderAutoScroll(axisX, dragOffset, targetViewport) {
        if (axisX > dragOffset.total) {
            if (
                !dragOffset.autoScroll &&
                targetViewport.scrollLeft + targetViewport.clientWidth <
                    targetViewport.scrollWidth
            ) {
                targetViewport.scrollLeft += SCROLL_PER_MOVE_PIXEL;
                dragOffset.autoScroll = setInterval(() => {
                    targetViewport.scrollLeft += SCROLL_PER_MOVE_PIXEL;
                }, 70);
            }
            return true;
        }
        if (targetViewport.offsetLeft + 10 > axisX) {
            if (!dragOffset.autoScroll && targetViewport.scrollLeft > 0) {
                targetViewport.scrollLeft -= SCROLL_PER_MOVE_PIXEL;
                dragOffset.autoScroll = setInterval(() => {
                    targetViewport.scrollLeft -= SCROLL_PER_MOVE_PIXEL;
                }, 70);
            }
            return true;
        }
        return false;
    }

    private onMouseMove(currentElementClientRect, dragOffset, targetViewport, event) {
        const axisX = event.clientX;
        if (!dragOffset.right) {
            dragOffset.right =
                currentElementClientRect.width - (axisX - currentElementClientRect.left);
            dragOffset.left =
                currentElementClientRect.width - (currentElementClientRect.right - axisX);
            dragOffset.total =
                targetViewport.clientWidth + targetViewport.offsetLeft - 15;
        }

        if (this.enabledHeaderAutoScroll(axisX, dragOffset, targetViewport)) {
            return;
        } else if (
            axisX > dragOffset.tempClientX &&
            axisX + dragOffset.right + EXTRA_PADDING_X_PIXEL >
                targetViewport.clientWidth + targetViewport.offsetLeft
        ) {
            // Scroll to right
            targetViewport.scrollLeft += SCROLL_PER_MOVE_PIXEL;
        } else if (
            axisX < dragOffset.tempClientX &&
            axisX < dragOffset.left + EXTRA_PADDING_X_PIXEL + targetViewport.offsetLeft
        ) {
            // Scroll to left
            targetViewport.scrollLeft -= SCROLL_PER_MOVE_PIXEL;
        }
        clearInterval(dragOffset.autoScroll);
        dragOffset.autoScroll = null;
        // Use to determine the left or right movement
        dragOffset.tempClientX = axisX;
    }
}
