import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'gfk-sorry',
    templateUrl: './sorry.component.html',
    styleUrls: ['./sorry.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SorryComponent {
    @Input() headline = 'We are sorry!';
}
