<div>
    <div
        fxLayout="row"
        [style.height.px]="headerHeight"
        [dragula]="'header-drag-container-' + pane.pos"
        [dragulaModel]="headerByPane"
    >
        <datatable-header-cell
            *ngFor="let header of headerByPane; trackBy: trackByColumnHeaderFn"
            [headerRowHeight]="headerRowHeight"
            [columnHeader]="header"
            [ngClass]="{ 'fixed-header': header?.attributes?.isFixedHeader }"
            [state]="state"
            fxFlex="{{ header.flexWidth }}"
            fxLayout="row {{ header.children ? 'wrap' : '' }}"
            mwlResizable
            [resizeCursorPrecision]="5"
            [resizeEdges]="{ right: true }"
            (resizing)="onResize($event, header, 0)"
            (resizeStart)="onResizeStart($event, header, 0)"
            (resizeEnd)="onResizeEnd($event)"
        >
        </datatable-header-cell>
    </div>
</div>
