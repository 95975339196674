import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionService } from 'insightui.session/session.service';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { LogService, ILogger } from 'insightui.core/services/logging';

/**
 * Adds the Authorization token from SessionService to each outgoing HTTP request.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private readonly logger: ILogger;
    constructor(public sessionService: SessionService, logService: LogService) {
        this.logger = logService.getLogger('TokenInterceptor');
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.logger.debug(`adding token to ${request.url}`);
        return this.sessionService.currentToken().pipe(
            take(1),
            mergeMap(token => {
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                }
                return next.handle(request);
            })
        );
    }
}
