<div class="drill-switch">
    <span class="drilling-options" *ngIf="visible">
        <span *ngFor="let path of drillingOptions; let isLast = last">
            <span
                [ngClass]="{ active: path && path.active }"
                class="drill-toggle"
                (click)="toggleTo(path)"
                >{{ path && path.name ? path.name : '' }}</span
            >
            <span *ngIf="path && !isLast"> | </span>
        </span>
    </span>
</div>
