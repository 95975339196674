import { Action, Selector, State, StateContext } from '@ngxs/store';
import { insertItem, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    CmDeleteCollectionSuccess,
    CmDeleteReportSuccess,
    CmDuplicateCollection,
    CmEndEditCollection,
    CmEndEditReport,
    CmLoadCollectionsSuccess,
    CmPrepareNewCollection,
    CmRangeReport,
    CmSelectAllReports,
    CmSelectCollection,
    CmSelectReport,
    CmStartEditCollection,
    CmStartEditReport,
    CmToggleReport,
    CmUnselectAllReports,
    CmUpdateCollectionSuccess,
    CmRestoreCollectionSuccess,
    CmLoadNewSharedCollectionsSuccess,
    CmCollectionUpdateFailure
} from './collection-manager.actions';
import {
    CollectionDto,
    ReportSnapshotDto,
    CollectionDtoCollectionType,
    CollectionDtoStatus
} from 'insightui.openapi/userprofile';
import { CollectionDtoWithAdditionalInfo } from 'insightui.collectionmanager/models/models';
import { Injectable } from '@angular/core';

export interface CollectionManagerStateModel {
    readonly temporaryCollection?: CollectionDto;
    readonly userCollections: ReadonlyArray<CollectionDtoWithAdditionalInfo>;
    readonly sharedCollections: ReadonlyArray<CollectionDto>;
    readonly recycledCollections?: ReadonlyArray<CollectionDto>;
    readonly selectedReportIds: ReadonlyArray<string>;
    readonly newSharedCollectionIds: ReadonlyArray<string>;
    readonly sectionId?: string;
    readonly isLoading: boolean;
    readonly loadingError?: string;
    readonly selectedCollection?: CollectionDto;
    readonly isEditingCollection: boolean;
    readonly isEditingReport: boolean;
}

@Injectable()
@State<CollectionManagerStateModel>({
    name: 'collectionManager',
    defaults: {
        temporaryCollection: undefined,
        userCollections: [],
        sharedCollections: [],
        recycledCollections: [],
        isLoading: false,
        isEditingCollection: false,
        isEditingReport: false,
        selectedReportIds: [],
        newSharedCollectionIds: []
    }
})
export class CollectionManagerState {
    private logger: ILogger;

    constructor(logService: LogService) {
        this.logger = logService.getLogger('CollectionManagerState');
    }

    //#region Selectors

    @Selector()
    public static sectionId(state: CollectionManagerStateModel): string | undefined {
        return state.sectionId;
    }

    @Selector()
    public static tempCollection(
        state: CollectionManagerStateModel
    ): CollectionDto | undefined {
        return state.temporaryCollection;
    }

    @Selector()
    public static userCollections(
        state: CollectionManagerStateModel
    ): ReadonlyArray<CollectionDto> {
        return state.userCollections;
    }

    @Selector()
    public static sharedCollections(
        state: CollectionManagerStateModel
    ): ReadonlyArray<CollectionDto> {
        return state.sharedCollections;
    }

    @Selector()
    public static newSharedCollectionIds(
        state: CollectionManagerStateModel
    ): ReadonlyArray<string> {
        return state.newSharedCollectionIds;
    }

    @Selector()
    public static recycledCollections(
        state: CollectionManagerStateModel
    ): ReadonlyArray<CollectionDto> {
        return state.recycledCollections;
    }

    @Selector()
    public static selectedCollection(
        state: CollectionManagerStateModel
    ): CollectionDto | undefined {
        return state.selectedCollection;
    }

    @Selector()
    public static selectedReportIds(
        state: CollectionManagerStateModel
    ): ReadonlyArray<string> {
        return state.selectedReportIds;
    }

    @Selector()
    public static isEditingReport(state: CollectionManagerStateModel): boolean {
        return state.isEditingReport;
    }

    @Selector()
    public static isEditingCollection(state: CollectionManagerStateModel): boolean {
        return state.isEditingCollection;
    }

    //#endregion

    //#region Actions

    @Action(CmCollectionUpdateFailure)
    compensateFailedUpdate(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.setState(
            patch({
                userCollections: (removeItem<CollectionDto>(
                    coll => coll.id === ''
                ) as unknown) as ReadonlyArray<CollectionDto>
            })
        );
    }

    @Action(CmSelectCollection)
    selectCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        { selectedCollection }: CmSelectCollection
    ) {
        const currentSelectedCollection = ctx.getState().selectedCollection;
        if (currentSelectedCollection !== selectedCollection) {
            ctx.patchState({
                selectedCollection,
                selectedReportIds: []
            });
        }
    }

    @Action(CmLoadCollectionsSuccess)
    loadCollections(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmLoadCollectionsSuccess
    ) {
        const sortedSharedCollections = [...action.sharedCollections].sort(
            (a, b) => -1 * this.compareDates(a.createdTime, b.createdTime)
        );

        ctx.patchState({
            temporaryCollection: action.tempCollection,
            userCollections: action.userCollections,
            sharedCollections: sortedSharedCollections,
            recycledCollections: action.recycledCollections
        });
    }

    @Action(CmLoadNewSharedCollectionsSuccess)
    loadNewSharedCollections(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmLoadNewSharedCollectionsSuccess
    ) {
        ctx.patchState({
            newSharedCollectionIds: action.newSharedCollectionIds
        });
    }

    @Action(CmPrepareNewCollection)
    prepareNewCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmPrepareNewCollection
    ) {
        const state = ctx.getState();
        const userCollections = state.userCollections;
        const selectedCollection = state.selectedCollection;

        let precedingCollectionId: string;

        // use currently selected collection, but only if it is a user collection
        if (selectedCollection && selectedCollection.collectionType === 'USER') {
            precedingCollectionId = selectedCollection.id;
        }
        const newPositionIndex =
            userCollections.findIndex(c => c.id === precedingCollectionId) + 1;

        const newCollection: CollectionDto = {
            id: '',
            name: 'New collection',
            collectionType: CollectionDtoCollectionType.USER,
            reportSnapshots: [],
            deletable: true,
            daysToDeletion: -1,
            createdTime: new Date(),
            lastAccessTime: new Date(),
            modifiedTime: new Date(),
            status: CollectionDtoStatus.ACTIVE
        };

        ctx.setState(
            patch({
                userCollections: (insertItem<CollectionDto>(
                    newCollection,
                    newPositionIndex
                ) as unknown) as ReadonlyArray<CollectionDto>
            })
        );
    }

    @Action(CmDuplicateCollection)
    duplicateCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmDuplicateCollection
    ) {
        const userCollections = ctx.getState().userCollections;

        const sourceCollection = userCollections.find(c => c.id === action.collectionId);
        if (!sourceCollection) {
            return;
        }

        const newPositionIndex =
            userCollections.findIndex(c => c.id === sourceCollection.id) + 1;

        const newCollection: CollectionDtoWithAdditionalInfo = {
            id: '',
            name: sourceCollection.name + ' Copy',
            collectionType: CollectionDtoCollectionType.USER,
            reportSnapshots: [...sourceCollection.reportSnapshots],
            deletable: true,
            createdTime: new Date(),
            daysToDeletion: -1,
            lastAccessTime: new Date(),
            modifiedTime: new Date(),
            status: CollectionDtoStatus.ACTIVE,
            copyOfId: action.collectionId
        };

        ctx.setState(
            patch({
                userCollections: (insertItem<CollectionDto>(
                    newCollection,
                    newPositionIndex
                ) as unknown) as ReadonlyArray<CollectionDto>
            })
        );
    }

    @Action(CmUpdateCollectionSuccess)
    updateCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmUpdateCollectionSuccess
    ) {
        const collectionToUpdate = ctx
            .getState()
            .userCollections.find(c => c.id === action.collection.id);

        if (collectionToUpdate) {
            ctx.setState(
                patch({
                    userCollections: (updateItem<CollectionDto>(
                        c => c.id === action.collection.id,
                        action.collection
                    ) as unknown) as ReadonlyArray<CollectionDto>
                })
            );

            // if updated collection is currently selected,
            // select it again to trigger updates on Observers
            const selectedCollection = ctx.getState().selectedCollection;
            if (selectedCollection && selectedCollection.id === action.collection.id) {
                ctx.setState(
                    patch({
                        selectedCollection: action.collection
                    })
                );
            }
        }
    }

    @Action(CmDeleteCollectionSuccess)
    deleteCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmDeleteCollectionSuccess
    ) {
        const deletedCollection = action.collection;

        let selectedCollection = ctx.getState().selectedCollection;
        if (selectedCollection && selectedCollection.id === deletedCollection.id) {
            selectedCollection = undefined;
        }

        if (deletedCollection.collectionType === 'SHARED') {
            ctx.setState(
                patch({
                    sharedCollections: (removeItem<CollectionDto>(
                        c => c.id === deletedCollection.id
                    ) as unknown) as ReadonlyArray<CollectionDto>,
                    selectedCollection
                })
            );
        } else if (deletedCollection.collectionType === 'USER') {
            ctx.setState(
                patch({
                    userCollections: (removeItem<CollectionDto>(
                        c => c.id === deletedCollection.id
                    ) as unknown) as ReadonlyArray<CollectionDto>,
                    recycledCollections: (insertItem<CollectionDto>(
                        deletedCollection
                    ) as unknown) as ReadonlyArray<CollectionDto>,
                    selectedCollection
                })
            );
        }
    }

    @Action(CmRestoreCollectionSuccess)
    restoreCollection(
        ctx: StateContext<CollectionManagerStateModel>,
        action: CmRestoreCollectionSuccess
    ) {
        const restoredCollection = action.collection;

        ctx.setState(
            patch({
                userCollections: (insertItem<CollectionDto>(
                    restoredCollection
                ) as unknown) as ReadonlyArray<CollectionDto>,
                recycledCollections: (removeItem<CollectionDto>(
                    c => c.id === restoredCollection.id
                ) as unknown) as ReadonlyArray<CollectionDto>
            })
        );
    }

    @Action(CmDeleteReportSuccess)
    deleteReport(
        ctx: StateContext<CollectionManagerStateModel>,
        { reportSnapshotId }: CmDeleteReportSuccess
    ) {
        ctx.setState(
            patch({
                selectedReportIds: (removeItem<string>(
                    s => s === reportSnapshotId
                ) as unknown) as ReadonlyArray<string>
            })
        );
    }

    @Action(CmSelectReport)
    selectReport(
        ctx: StateContext<CollectionManagerStateModel>,
        { reportId }: CmSelectReport
    ) {
        let selectedReportIds;
        if (
            ctx.getState().selectedCollection.reportSnapshots.some(r => r.id === reportId)
        ) {
            selectedReportIds = [reportId];
        } else {
            selectedReportIds = [];
        }

        ctx.patchState({ selectedReportIds });
    }

    @Action(CmToggleReport)
    toggleReport(
        ctx: StateContext<CollectionManagerStateModel>,
        { reportId }: CmToggleReport
    ) {
        if (
            ctx.getState().selectedCollection.reportSnapshots.some(r => r.id === reportId)
        ) {
            const currentlySelectedReportIds = ctx.getState().selectedReportIds;

            if (currentlySelectedReportIds.includes(reportId)) {
                ctx.setState(
                    patch({
                        selectedReportIds: (removeItem(
                            i => i === reportId
                        ) as unknown) as ReadonlyArray<string>
                    })
                );
            } else {
                ctx.setState(
                    patch({
                        selectedReportIds: (insertItem(
                            reportId
                        ) as unknown) as ReadonlyArray<string>
                    })
                );
            }
        } else {
            ctx.patchState({ selectedReportIds: [] });
        }
    }

    @Action(CmRangeReport)
    selectRange(
        ctx: StateContext<CollectionManagerStateModel>,
        { reportId, allowedCountryCodes }: CmRangeReport
    ) {
        const selectedCollection = ctx.getState().selectedCollection;
        let selectedReportIds = ctx.getState().selectedReportIds;
        if (selectedCollection) {
            let endIndex = selectedCollection.reportSnapshots.findIndex(
                r => r.id === reportId
            );
            let startIndex = this.findLowestMarkedIndex(
                selectedCollection.reportSnapshots,
                selectedReportIds
            );

            if (endIndex < startIndex) {
                const tmp = endIndex;
                endIndex = startIndex;
                startIndex = tmp;
            }

            const newlySelectedReports = selectedCollection.reportSnapshots.slice(
                startIndex,
                endIndex + 1
            );

            selectedReportIds = this.getReportIdsOfReportsWhichMatchAllowedCountries(
                newlySelectedReports,
                allowedCountryCodes
            );

            this.logger.debug(`selectRange`, {
                startIndex,
                endIndex,
                selectedReportIds
            });
            ctx.patchState({ selectedReportIds });
        }
    }

    @Action(CmSelectAllReports)
    selectAllReports(
        ctx: StateContext<CollectionManagerStateModel>,
        { allowedCountryCodes }: CmSelectAllReports
    ) {
        const selectedCollection = ctx.getState().selectedCollection;
        if (selectedCollection) {
            const selectedReportIds = this.getReportIdsOfReportsWhichMatchAllowedCountries(
                selectedCollection.reportSnapshots,
                allowedCountryCodes
            );

            ctx.patchState({
                selectedReportIds
            });
        }
    }

    @Action(CmUnselectAllReports)
    unselectAllReports(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.patchState({
            selectedReportIds: []
        });
    }

    @Action(CmStartEditReport)
    startEditReport(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.patchState({
            isEditingReport: true
        });
    }

    @Action(CmEndEditReport)
    endEditReport(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.patchState({
            isEditingReport: false
        });
    }

    @Action(CmStartEditCollection)
    startEditCollection(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.patchState({
            isEditingCollection: true
        });
    }

    @Action(CmEndEditCollection)
    endEditCollection(ctx: StateContext<CollectionManagerStateModel>) {
        ctx.patchState({
            isEditingCollection: false
        });
    }
    //#endregion

    private findLowestMarkedIndex(
        reports: { id: string }[],
        selectedReportIds: ReadonlyArray<string>
    ) {
        let lowestIndex = Number.MAX_VALUE;

        selectedReportIds.forEach(id => {
            const idx = reports.findIndex(r => r.id === id);
            if (idx !== -1 && idx < lowestIndex) {
                lowestIndex = idx;
            }
        });
        if (lowestIndex === Number.MAX_VALUE) {
            lowestIndex = 0;
        }
        return lowestIndex;
    }

    private compareDates(a: Date | undefined, b: Date | undefined) {
        if (a === b) {
            return 0;
        }

        // sort null and undefined to the end
        if (!a) {
            return 1;
        }
        if (!b) {
            return -1;
        }

        return a.toISOString().localeCompare(b.toISOString());
    }

    private getReportIdsOfReportsWhichMatchAllowedCountries(
        reports: ReportSnapshotDto[],
        allowedCountryCodes: string[]
    ): string[] {
        const selectedReportIds: string[] = [];
        reports.forEach(r => {
            if (allowedCountryCodes.includes(r.countryCode)) {
                selectedReportIds.push(r.id);
            }
        });
        return selectedReportIds;
    }
}
