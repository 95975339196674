import { ChangeDetectionStrategy, Component, Input, HostBinding } from '@angular/core';

@Component({
    selector: 'gfk-fair-share-growth-rate-hover',
    templateUrl: './fair-share-growth-rate-hover.component.html',
    styleUrls: ['./fair-share-gap-value-hover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareGrowthRateHoverComponent {
    @Input()
    comparisonPercent: number;

    @Input()
    maxValuePercent: number;

    @Input()
    minValuePercent: number;

    @Input()
    gapValuePercent: number;

    @Input()
    gapValue: number;

    @HostBinding('style.width.%') get width() {
        return this.getWidth();
    }

    @HostBinding('style.left.%') get left() {
        return this.getLeftOffset();
    }

    @HostBinding('style.display') get display() {
        return this.gapValueAndComparisonIsOutOfChart() ? 'none' : 'block';
    }

    gapValueAndComparisonIsOutOfChart() {
        return (
            (this.isGapValuePercentTooHigh() && this.isComparisonPercentTooHigh()) ||
            (this.isGapValuePercentTooLow() && this.isComparisonPercentTooLow())
        );
    }

    getWidth() {
        const range = this.maxValuePercent - this.minValuePercent;
        const clampedGapValue = this.minMaxPercentClamp(this.gapValuePercent);
        const clampedComparison = this.minMaxPercentClamp(this.comparisonPercent);
        let width = clampedGapValue - clampedComparison;

        return (Math.abs(width) / range) * 100;
    }

    getLeftOffset() {
        const range = this.maxValuePercent - this.minValuePercent;
        const centerPoint = 50;
        const comparison = this.minMaxPercentClamp(this.comparisonPercent);
        const gapValue = this.minMaxPercentClamp(this.gapValuePercent);
        let leftOffset = 0;
        let leftShift = 0;

        if (comparison < 0) {
            if (comparison < gapValue) {
                leftOffset = comparison;
            } else {
                leftOffset = gapValue;
            }
        } else {
            leftShift = this.width;
            if (comparison > gapValue) {
                leftOffset = comparison;
            } else {
                leftOffset = gapValue;
            }
        }

        return centerPoint - leftShift + (leftOffset / range) * 100;
    }

    isNegative(): boolean {
        return this.gapValue < 0;
    }

    valueIndicatorOutOfChartPositiv() {
        return this.isComparisonPercentTooLow() || this.isGapValuePercentTooLow();
    }

    valueIndicatorOutOfChartNegative() {
        return this.isComparisonPercentTooHigh() || this.isGapValuePercentTooHigh();
    }

    private minMaxPercentClamp(percent: number): number {
        return Math.max(this.minValuePercent, Math.min(this.maxValuePercent, percent));
    }

    private isGapValuePercentTooHigh(): boolean {
        return this.gapValuePercent > this.maxValuePercent;
    }

    private isGapValuePercentTooLow(): boolean {
        return this.gapValuePercent < this.minValuePercent;
    }

    private isComparisonPercentTooHigh(): boolean {
        return this.comparisonPercent > this.maxValuePercent;
    }

    private isComparisonPercentTooLow(): boolean {
        return this.comparisonPercent < this.minValuePercent;
    }
}
