import { Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import * as _ from 'lodash';

import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { AnalyticsService } from 'insightui.core/services/analytics.service';

@Component({
    selector: 'gfk-report-overview',
    templateUrl: './report-overview.component.html',
    styleUrls: ['./report-overview.component.scss']
})
export class ReportOverviewComponent implements OnInit {

    @Input()
    overview: any;

    @Input()
    favourites: any;

    @Input()
    questionMinCount: any;

    @Input()
    docId: any;

    @ViewChild('li')
    li;

    @ViewChild('image')
    img;

    favouriteResolved: any;
    positionX: any;
    positionY: any;
    tooltipOpen = false;
    imgSrc = 'assets/images/overview_';

    private reportIds: any;

    constructor(private matomoTrackerService: AnalyticsService,
        private renderer: Renderer2) {
    }

    ngOnInit() {
        this.reportIds = _.uniq(
            this.overview.questions.map((group) => {
                return group.id.replace(/[a-z]/gi, '');
            })
        );

        if (_.parseInt(_.get(this, ['report', 'questionMinCount'], '0')) > 3) {
            this.img.className = 'margin-top-big';
        }
    }

    firstItem() {
        return _.get(this.overview, ['questions', 0], {});
    }

    setHighlight(idx) {
        if (idx === 0) {
            this.renderer.addClass(this.li.nativeElement, 'highlight');
        }
    }

    clearHighlight() {
        this.renderer.removeClass(this.li.nativeElement, 'highlight');
    }

    hasFavourite() {
        if (this.favouriteResolved !== undefined) {
            return this.favouriteResolved !== '';
        }

        if (this.favourites) {
            const keys = Object.keys(this.favourites);
            this.favouriteResolved = '';

            for (let i = 0; i < keys.length; i++) {
                if (this.reportIds.indexOf(keys[i].replace(/[a-z]/gi, '')) >= 0) {
                    this.favouriteResolved = keys[i].replace(/[a-z]/gi, '');
                    return true;
                }
            }
            return false;
        }

        return undefined;
    };

    getFavourites() {
        if (this.favourites) {
            let self = this;
            return _(this.favourites)
                .filter((fav, key) => {
                    return self.reportIds.indexOf(key.toString().replace(/[a-z]/gi, '')) >= 0;
                })
                .flatten()
                .sortBy('created')
                .value()
                .reverse();
        }

        return [];
    };

    addClientIdToLink(link) {
        return preserveClientId(link);
    };

    onReportClickFromLandingPage(reportId) {
        this.trackOpenReport(this.matomoTrackerService, reportId);
    }

    onMouseOver() {
        this.tooltipOpen = true;
    }

    onMouseOut() {
        this.tooltipOpen = false;
    }

    onMouseMove(event) {
        this.positionX = event.layerX;
        this.positionY = event.layerY;
    }

    private trackOpenReport(matomoTrackerService, reportId) {
        try {
            matomoTrackerService.trackEvent(
                'Navigation',
                'OpenReportFromLandingPage',
                reportId
            );
        } catch (e) {

        }
    }
}
