import { UpgradeComponent } from '@angular/upgrade/static';
import { Directive, ElementRef, Injector } from '@angular/core';

/**
 * Upgraded interactive header directive from angular1.
 * Has to be bootstrapped with the interactive-header-bootstrap-resolver.
 */
@Directive({
    selector: 'gfkAuthoringChart', // tslint:disable-line
    providers: [
        // Need to provide a new scope, because UpgradeComponent does not provide one
        {
            provide: '$scope',
            useFactory: i => i.get('$rootScope').$new(),
            deps: ['$injector']
        }
    ]
})
export class AuthoringChartDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef, injector: Injector) {
        super('authoringChart', elementRef, injector);
    }
}
