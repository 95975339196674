import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    TemplateRef
} from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
    selector: 'gfk-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderComponent {
    @Input() title: string;

    @Input() iconClass: string;

    get showIcon(): boolean {
        return !!this.iconClass;
    }
}
