import { Inject, Injectable } from '@angular/core';
import {
    DashboardServiceStatus,
    DashboardServiceStatusType
} from 'insightui.dashboard/dashboard.types';
import {
    INSIGHT_CONFIG,
    InsightConfig,
    ServiceConfig
} from 'insightui.global/global.module';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, of, Observable } from 'rxjs';
import { catchError, mapTo, map } from 'rxjs/operators';

@Injectable()
export class DashboardService {
    constructor(
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig,
        private httpClient: HttpClient
    ) {}

    checkStatusOfServices(): Observable<DashboardServiceStatus[]> {
        const statusRequests = Object.values(this.insightConfig.services)
            .filter(serviceConfig => serviceConfig.statusUrl != null)
            .map(serviceConfig => {
                return this.getNameAndStatusOfService(serviceConfig);
            });

        return forkJoin(statusRequests);
    }

    private getNameAndStatusOfService(
        serviceConfig: ServiceConfig
    ): Observable<DashboardServiceStatus> {
        return this.httpClient
            .get(serviceConfig.statusUrl, { responseType: 'text' })
            .pipe(
                mapTo('alive'),
                catchError(() => {
                    return of('dead');
                }),
                map((status: DashboardServiceStatusType) => {
                    return { name: serviceConfig.displayName, status: status };
                })
            );
    }
}
