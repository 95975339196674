import { fromEvent as observableFromEvent, Observable } from 'rxjs';

import { takeWhile } from 'rxjs/operators';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { scaleLinear, ScaleLinear } from 'd3-scale';

/**
 * SVG Based Slider Component for allowing an user to display and manipulate numeric values
 * using a slider.
 *
 * The slider is based upon a D3 scale which is used for mapping the [min, max] domain coordinates (see range @Input) to
 * slider track relative coordinates (in the range of 0-100) which will be used to position the slider (or calculate the
 * position in the range via the invert() method).
 *
 * @TODO: Move styling to patternlab
 **/
@Component({
    selector: 'slider-component',
    templateUrl: 'slider.component.html',
    styles: [
        `
            .slider-container {
                overflow: visible;
                padding-left: 1em;
                padding-top: 1em;
            }

            .slider-scale {
            }
            .slider-scale__label {
                font-size: 0.8em;
                font-family: arial;
                text-anchor: start;
            }

            .slider {
            }
            .slider__indicator,
            .slider__dash {
                pointer-events: none;
            }

            .slider__thumb {
                cursor: pointer;
            }
        `
    ]
})
export class SliderComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    /**
     * The current value of the slider, must be in the range or otherwise it will be set equal to minValue.
     * @type {number}   The numeric range between [minValue;maxValue]
     */
    @Input()
    public value: SliderValue = 0;

    /**
     * Emits the new {@link SliderValue} on slider updates.
     */
    @Output()
    public valueChange: EventEmitter<SliderValue> = new EventEmitter();

    /**
     * The Range of this silder as a [min, max] array. If min > max an error will be thrown.
     * @type {[number,number]}  A [min, max] array.
     */
    @Input()
    public range: [MinimumRangeValue, MaximumRangeValue] = [0, 10];

    /**
     * The step width of the slider when dragging the handle (on which values does the slider 'snap').
e     * @type {number}   A numeric step width that should be smaller than  MaximumRangeValue - MinimumRangeValue
     */
    @Input()
    public step: number = 0.5;

    /**
     * The number of steps in the scale between values.
     * @type {number}
     */
    @Input()
    public scaleStep: number = 1;

    /**
     * The color used for filling the selected area.
     * @type {string} A color definition supported by the svg fill attribute
     */
    @Input()
    public color: string = 'green';

    /**
     * Controls, how many digits should be shown on the scale on ranges that start above/below zero (like 0.1 - 10)
     * Oftentimes, scales should still go from 0 - 10 instead of 0.1 - 10
     * @type {number}   The number of digits.
     */
    @Input()
    public scaleDigits: number = 0;

    /**
     * Get the d3 linearScale instance for the given range in the @Input.
     *
     * Originally this was intended to be private-write only, but as D3 Scales are
     * mutable we cannot prevent modification if we don't provide copies on every access.
     *
     * @return {ScaleLinear<MinimumRangeValue, MaximumRangeValue>}
     */
    scale: ScaleLinear<MinimumRangeValue, MaximumRangeValue>;

    /**
     * Internal representation of the numeric scale above the actual slider track.
     * @type {Array}    An array with the numeric label and the position offset in percent.
     */
    labelScale: { label: number; pos: number }[] = [];

    /**
     * The current (relative) offset of the thumb.
     *
     * E.g. 50 means the thumb is in the middle of the scale.
     */
    thumbOffset: number;

    @ViewChild('thumbPosition', { static: true })
    private thumbReference: ElementRef;

    @ViewChild('track', { static: true })
    private trackReference: ElementRef;

    /**
     * State indicator that is set to true when dragging is ongoing.
     *
     * @type {boolean}  True when the user is currently dragging {@link thumbReference}
     */
    private dragActive: boolean = false;

    /**
     * Internal cache of the tracks size, as this is retrieved via getBoundingClientRect() which would
     * cause a layout recalculation on every mouse move during drag.
     */
    private sizeCache: ClientRect;

    ngOnChanges(changes: SimpleChanges): void {
        this.normalizeInputs();
        this.rebuildScale();
    }

    ngOnInit() {
        this.normalizeInputs();
        this.rebuildScale();
    }

    ngOnDestroy(): void {
        this.finishDrag();
    }

    ngAfterViewInit(): void {
        this.cacheTrackSize();
        this.thumbReference.nativeElement.addEventListener(
            'mousedown',
            this.startDrag.bind(this)
        );
        this.trackReference.nativeElement.addEventListener(
            'click',
            this.moveToMousePosition.bind(this)
        );
    }

    updateValue(value: number, quiet = false) {
        value = (value < 0 ? Math.floor : Math.ceil)(value / this.step) * this.step;
        this.value = Math.min(Math.max(value, this.range[0]), this.range[1]);
        this.thumbOffset = this.scale(this.value);
        if (!quiet) {
            this.valueChange.emit(this.value);
        }
    }

    /**
     * Rebuild the labelScale array which contains the positions and labels for the
     * scale indicator above the slider.
     *
     * Also ensures that the scale always has a value in the beginning and the end of the scale.
     */
    rebuildScale() {
        this.scale = scaleLinear()
            .domain([
                this.roundScaleValue(this.range[0]),
                this.roundScaleValue(this.range[1], Math.ceil)
            ])
            .range([0, 100]);
        this.labelScale = [];

        this.labelScale.push({
            label: this.roundScaleValue(this.range[0]),
            pos: this.scale(this.roundScaleValue(this.range[0]))
        });
        for (
            let x = this.range[0] + this.scaleStep;
            x < this.roundScaleValue(this.range[1], Math.ceil);
            x += this.scaleStep
        ) {
            let value = this.roundScaleValue(x);
            this.labelScale.push({ label: value, pos: this.scale(value) });
        }
        this.labelScale.push({
            label: this.roundScaleValue(this.range[1], Math.ceil),
            pos: this.scale(this.roundScaleValue(this.range[1], Math.ceil))
        });
        this.updateValue(this.value, true);
    }

    /**
     * Round a value with the given numbers of digits and the round algorithm provided by roundAlg parameter (default {@link Math.floor}).
     * @param valueToRound             The value that should be rounded.
     * @param roundAlg                 The algorithm for rounding.
     * @return {number}                The rounded number.
     */
    private roundScaleValue(valueToRound: number, roundAlg = Math.floor) {
        const roundingFactor = 10 * this.scaleDigits || 1;
        return roundAlg(valueToRound * roundingFactor) / roundingFactor;
    }

    private startDrag() {
        this.dragActive = true;
        // calculate the size only once during drag start, as normally no resizing or repositioning
        // should occur during a drag operation
        this.cacheTrackSize();

        // TODO: Remove tight coupling to window.document and use the global module
        //       as soon as it is available in the bootstrap module
        observableFromEvent(window.document, 'mousemove')
            .pipe(takeWhile(() => this.dragActive))
            .subscribe(this.moveToMousePosition.bind(this));

        observableFromEvent(window.document, 'mouseup')
            .pipe(takeWhile(() => this.dragActive))
            .subscribe(this.finishDrag.bind(this));
    }

    private moveToMousePosition($event: MouseEvent) {
        if (!this.sizeCache) {
            this.cacheTrackSize();
        }
        const relativePositionInTrack =
            ($event.clientX - this.sizeCache.left) / this.sizeCache.width;
        this.moveTo(relativePositionInTrack);
    }

    private cacheTrackSize() {
        this.sizeCache = this.trackReference.nativeElement.getBoundingClientRect();
    }

    /**
     * Move the slider to the relative position (0: leftmost, 1: rightmost).
     *
     * @param relativePos   A float with the relative position of the thumb.
     */
    public moveTo(relativePos: number) {
        this.updateValue(this.scale.invert(relativePos * 100));
    }

    private finishDrag() {
        this.dragActive = false;
    }

    /**
     * Normalize string inputs so we can be sure that all values are numerical.
     */
    private normalizeInputs() {
        this.value = parseFloat(String(this.value));
        this.scaleStep = parseFloat(String(this.scaleStep));
        this.step = parseFloat(String(this.step));
    }
}

type SliderValue = number;
type MinimumRangeValue = number;
type MaximumRangeValue = number;
