import { Indicator } from 'insightui.table/components/datatable/body/performanceIndicator/performance-indicator.component';
import { Injectable } from '@angular/core';
import { ChannelComparisonModel } from 'insightui.core/models/reportSetting/channel-comparison.model';
import { ThresholdModel } from 'insightui.core/models/reportSetting/threshold.model';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';

@Injectable()
export class PerformanceIndicatorMapperService {
    constructor(
        private channelComparisonModel: ChannelComparisonModel,
        private thresholdModel: ThresholdModel,
        protected datatableConfigurationService: DatatableConfigurationService
    ) {}

    shouldHavePerformanceIndicator(field): boolean {
        return this.shouldHaveIndicatorForType(field, 'component');
    }

    shouldHaveRankIndicator(field): boolean {
        return this.shouldHaveIndicatorForType(field, 'supplementaryKpi');
    }

    getRowLabel(row) {
        const itemOrBrand = row.IT || row.BR;
        if (itemOrBrand) {
            return `for ${itemOrBrand}`;
        }

        return `in ${row.TG || row.PG || row.CY || row.SG || row.AR}`;
    }

    map(row, column): Indicator | undefined {
        if (!this.shouldHavePerformanceIndicator(column.field)) {
            return;
        }

        // Don't show the Performance Indicator if there is a (legacy) Rank indicator present
        if (this.shouldHaveRankIndicator(column.field)) {
            return;
        }

        const selected = this.channelComparisonModel.findOne('selected', true);
        const value = row[column.field];
        const comparisonValue =
            row[
                selected.id +
                    column.field.substr(column.field.indexOf('_'), column.field.length)
            ];

        const positiveThreshold = this.thresholdModel.findOne('id', 'good');
        const negativeThreshold = this.thresholdModel.findOne('id', 'bad');
        let isPositive: boolean;
        let isNeutral = false;
        let gap = 0;

        if (column.dataType === 'numerical' || column.dataType === 'percentage') {
            if (
                value === null ||
                comparisonValue === null ||
                !positiveThreshold ||
                !negativeThreshold
            ) {
                isNeutral = true;
            } else {
                gap = value - comparisonValue;
                if (gap > positiveThreshold.value) {
                    isPositive = true;
                } else if (gap < negativeThreshold.value) {
                    isPositive = false;
                } else {
                    isNeutral = true;
                }
            }
        } else {
            return undefined;
        }

        return {
            isNeutral,
            isPositive,
            targetChannel: selected.title,
            baseChannel: selected.baseTitle,
            rowLabel: this.getRowLabel(row),
            gap: Math.abs(gap).toFixed(2)
        };
    }

    private shouldHaveIndicatorForType(field, elementType: string): boolean {
        const config = this.datatableConfigurationService
            .getTableConfiguration()
            .plugins.find(element => element.type === elementType);

        if (config && config.options) {
            return field.match(
                new RegExp('^RT[^!]+_(' + config.options.kpis.join('|') + ')$')
            );
        } else {
            return false;
        }
    }
}
