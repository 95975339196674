import { VisualizationContextStateModel } from './visualization-context.state';
import { VcCurrency, VcFavorite, VcChart } from './visualization-context.types';

export class VCSetKeyAction {
    public static readonly type = '[VC] Set key';
    constructor(
        public key: keyof VisualizationContextStateModel,
        public value: undefined | string | VcCurrency | VcFavorite | VcChart | any
    ) {}
}
