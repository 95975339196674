import { NgModule } from '@angular/core';
import { TopMetaBarComponent } from './top-meta-bar.component';
import { CommonModule } from '@angular/common';
import { AnnouncementsModule } from 'insightui.announcements/announcements.module';
import { CollectionManagerShortcutModule } from 'insightui.collectionmanager/collection-manager-shortcut/collection-manager-shortcut.module';
import { FeedNotificationModule } from 'insightui.feed-notification/feed-notification.module';
import { FavoriteSelectByReportModule } from 'insightui.favorite-select-by-report/favourite-select-by-report.module';
import { ClientSwitchModule } from 'insightui.client-switch/client-switch.module';
import { LoadStatusModule } from './load-status/load-status.module';

@NgModule({
    declarations: [TopMetaBarComponent],
    imports: [
        CommonModule,
        FavoriteSelectByReportModule,
        AnnouncementsModule,
        CollectionManagerShortcutModule,
        FeedNotificationModule,
        ClientSwitchModule,
        LoadStatusModule
    ],
    providers: [],
    exports: [TopMetaBarComponent]
})
export class TopMetaBarModule {}
