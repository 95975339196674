<div class="datatable datatable--border" fxLayout="row" id="datatableWrapper">
    <div
        *ngFor="let pane of columnsByPane; let i = index; trackBy: trackByColumnsPaneFn"
        class="datatable-pane {{ pane.pos }}"
    >
        <datatable-header
            [ngStyle]="stylesByPane(pane)"
            [pane]="pane"
            [innerWidth]="columnPaneWidths[pane.pos]"
            [columns]="pane.columns"
            [headers]="headers"
            [headerRowHeight]="headerRowHeight"
            (headerReordered)="onHeaderReordered($event)"
            (headerChanged)="onHeaderChanged($event)"
            (columnResized)="onColumnResized($event)"
            (columnResizeStart)="onColumnResizeStart()"
            (columnResizeEnd)="onColumnResizeEnd()"
            (autoResized)="doAutoResize()"
            [enabledRowHeaderReorder]="pane.pos === 'main'"
            [autoScrollOnViewport]="
                pane.pos === 'main' ? '.datatable-pane.main .datatable-body' : null
            "
        >
        </datatable-header>
        <datatable-body
            [style.height.px]="viewportHeight"
            [pane]="pane"
            [rows]="rows"
            [totalRow]="totalRow"
            [subTotalRow]="subTotalRow"
            [loadingIndicator]="loadingIndicator"
            [rowHeight]="rowHeight"
            [headers]="headers"
            [headersOrder]="headersOrder"
            [columns]="pane.columns"
            [innerWidth]="pane.pos === 'main' ? mainWidth : columnPaneWidths[pane.pos]"
            [totalWidth]="columnPaneWidths[pane.pos]"
            [bodyHeight]="bodyHeight"
            [bodyHeightDataBody]="bodyHeightDataBody"
            (bodyScroll)="onBodyScroll($event)"
        >
        </datatable-body>
    </div>
</div>
