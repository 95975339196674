<gfk-modal-header
    [title]="'Switch Client'"
    [iconClass]="'fa fa-database'"
    data-uitest="client-switch-dialog-header"
>
</gfk-modal-header>
<div>
    <p>
        Retail Scout will show you the currently available deliveries for the selected
        client. You will be able to work with all available periods, data and shared
        collections.
    </p>
    <gfk-search-box
        placeholder="Search client name or ID"
        (searchTextChanged)="onSearchTextChanged($event)"
    ></gfk-search-box>
    <table class="clients-table" aria-describedby="info">
        <div id="info">
            List of available clients to pick from.
        </div>
        <thead>
            <tr>
                <th
                    class="firstColumn"
                    scope="col"
                    [class.sortAsc]="sortByField === 'id' && sortAscending"
                    [class.sortDesc]="sortByField === 'id' && !sortAscending"
                    (click)="sortBy('id')"
                >
                    Client ID
                </th>
                <th
                    class="secondColumn"
                    scope="col"
                    [class.sortAsc]="sortByField === 'name' && sortAscending"
                    [class.sortDesc]="sortByField === 'name' && !sortAscending"
                    (click)="sortBy('name')"
                >
                    Client Name
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                *ngFor="let client of clients"
                id="client-{{ client.id }}"
                [class.selected]="client.id == selectedClientId"
                (click)="selectClient(client.id)"
                (dblclick)="doubleClicked(client.id)"
            >
                <td
                    class="firstColumn"
                    [innerHtml]="client.id | highlight: searchText"
                ></td>
                <td
                    class="secondColumn"
                    [innerHtml]="client.name | highlight: searchText"
                ></td>
            </tr>
        </tbody>
    </table>
</div>
<gfk-modal-footer>
    <gfk-modal-button
        buttonType="CANCEL"
        class="exit-view-button"
        (click)="exitClientView()"
        data-uitest="exit-view-button"
        angulartics2On
        angularticsCategory="ClientSwitch"
        angularticsAction="ExitClientView"
    >
        Exit Client View
    </gfk-modal-button>
    <gfk-modal-button
        buttonType="BUTTON"
        (click)="submitDialog()"
        [disabled]="!selectedClientId"
        data-uitest="view-client-button"
        angulartics2On
        angularticsCategory="ClientSwitch"
        angularticsAction="StartClientView"
        [angularticsLabel]="selectedClientId"
    >
        View Selected Client
    </gfk-modal-button>
</gfk-modal-footer>
<gfk-modal-closer
    (click)="closeDialog()"
    data-uitest="close-dialog-icon"
></gfk-modal-closer>
