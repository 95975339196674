import { AppStateEpic } from 'projects/insightui/src/app/state/state.types';
import { Epic } from 'redux-observable';
import { AnyAction } from 'redux';
import { Injectable } from '@angular/core';
import { merge, MonoTypeOperatorFunction, Observable, of as observableOf } from 'rxjs';
import {
    EXPORT_COLLECTION_CONFIRM,
    EXPORT_COLLECTION_DONE,
    EXPORT_COLLECTION_ERROR,
    EXPORT_COLLECTION_PPT_FILE_CREATED,
    EXPORT_COLLECTION_RETRY,
    EXPORT_COLLECTION_SHEETS_FROM_IFRAME,
    EXPORT_COLLECTION_START
} from 'insightui.export-collection/export-collection.actions';
import {
    catchError,
    filter,
    map,
    mapTo,
    reduce,
    startWith,
    switchMap,
    take,
    withLatestFrom,
    shareReplay,
    mergeMap
} from 'rxjs/operators';
import { ExportCollectionService } from 'insightui.export-collection/export-collection.service';
import { ReportSheetsFromIFrame } from 'insightui.export-collection/export-collection.types';
import { SavePowerpointService } from 'insightui.export/powerpoint/services/save-powerpoint.service';
import { ExportCollectionHeadlineService } from './export-collection-headline.service';
import { ListSelection } from 'insightui.collectionmanager/models/list-selection';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';

const catchExportError: MonoTypeOperatorFunction<AnyAction> = catchError((err, caught) =>
    caught.pipe(startWith(EXPORT_COLLECTION_ERROR()))
);

@Injectable({
    providedIn: 'root'
})
export class ExportCollectionEpic implements AppStateEpic<'exportCollection'> {
    key: 'exportCollection' = 'exportCollection';

    constructor(
        private exportCollectionService: ExportCollectionService,
        private savePowerpointService: SavePowerpointService,
        private exportCollectionHeadlineService: ExportCollectionHeadlineService
    ) {}

    getEpic(): Epic<AnyAction> {
        return (action$: Observable<AnyAction>) => {
            const startCollectionAction$: Observable<{
                reports: ListSelection<ReportSnapshotDto>;
                name: string;
            }> = action$.pipe(
                filter(EXPORT_COLLECTION_START.match),
                map(({ payload }) => payload),
                shareReplay(1) // necessary for recovery from an error, otherwise the stream will be empty
            );

            const startCollection$ = startCollectionAction$.pipe(
                map(action => action.reports)
            );

            const collectionName$ = startCollectionAction$.pipe(
                map(action =>
                    this.exportCollectionHeadlineService.getHeadline(action.name)
                )
            );

            const collectionSubtitle$ = startCollection$.pipe(
                mergeMap(reports =>
                    this.exportCollectionHeadlineService.getSubHeadline$(reports)
                )
            );

            const retryCollection$: Observable<
                ListSelection<ReportSnapshotDto>
            > = action$.pipe(
                filter(EXPORT_COLLECTION_RETRY.match),
                withLatestFrom(startCollection$),
                map(([, selectedReports]) => selectedReports)
            );

            const confirmedCollection$: Observable<
                ListSelection<ReportSnapshotDto>
            > = action$.pipe(
                filter(EXPORT_COLLECTION_CONFIRM.match),
                withLatestFrom(startCollection$),
                map(([, selectedReports]) => selectedReports)
            );

            const collectionToExport$ = merge(confirmedCollection$, retryCollection$);

            const createSheets$ = collectionToExport$.pipe(
                switchMap(selectedReports =>
                    this.exportCollectionService.exportCollection(selectedReports)
                ),
                map((reportSheets: ReportSheetsFromIFrame) =>
                    EXPORT_COLLECTION_SHEETS_FROM_IFRAME(reportSheets)
                ),
                catchExportError
            );

            const collectSheets$: Observable<AnyAction> = collectionToExport$.pipe(
                switchMap(selectedReports => {
                    return action$.pipe(
                        filter(EXPORT_COLLECTION_SHEETS_FROM_IFRAME.match),
                        map(({ payload }) => payload.sheets),
                        take(selectedReports.elements.length),
                        reduce((acc, sheets) => acc.concat(sheets), []),
                        map(sheets => EXPORT_COLLECTION_DONE(sheets))
                    );
                })
            );

            const createPptFile$ = action$.pipe(
                filter(EXPORT_COLLECTION_DONE.match),
                withLatestFrom(collectionName$, collectionSubtitle$),
                switchMap(([{ payload }, collectionName, subtitle]) =>
                    this.savePowerpointService.saveCurrentCollection(
                        {
                            getPowerpointExportSheets() {
                                return observableOf(payload);
                            }
                        },
                        collectionName,
                        subtitle
                    )
                ),
                mapTo(EXPORT_COLLECTION_PPT_FILE_CREATED()),
                catchExportError
            );

            return merge(createSheets$, collectSheets$, createPptFile$);
        };
    }
}
