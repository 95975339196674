/**
 * Service for sorting rows with multiple side headers and feature split.
 *
 * Sorts values clustered by the primary data row and subsequently sorts the feature and
 * price class values by their order, so different brands are not intermixed with different
 * features, priceclasses, etc.
 */

import { Injectable } from '@angular/core';
import {
    SortDirection,
    SortingModel,
    SortSetting
} from 'insightui.core/models/sorting.model';
import { DataRow } from 'insightui.table/components/datatable/shared/models/datatable.models';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

export interface SortRow {
    sortValue: number | string;
    rows: SortRow[] | DataRow[];

    $_groupId?: string;
}

const NON_ID_FIELDS = ['$FT', '$PC', 'Others'];
const TOTAL_IDENTIFIER = 'GTwith';

@Injectable()
export class TableRowSorterService {
    private readonly logger: ILogger;

    constructor(private sortModel: SortingModel, logService: LogService) {
        this.logger = logService.getLogger(`TableRowSorterService`);
    }

    /**
     *  Sorting entry point for default and custom sort. SplitBy sorting is
     *  detected implicitly via data set containing "Grand Total" values.
     */
    sort(data: DataRow[]): DataRow[] {
        if (data.length === 0) {
            return data;
        }

        // get sorting definitions
        let sortItems = this.sortModel.getSortingAll();

        // extend dataset for splitBy pc/feature sortings
        sortItems = this.prepareDataSet(data, sortItems);
        const sorted = this.doSorting(data, sortItems);
        return this.flatten(sorted);
    }

    /**
     * Recursive method for default and custom sorting. Groups
     * similar values and calls itself as long as sortItems are
     * available.
     */
    doSorting(data: DataRow[] | SortRow[], sortItems: SortSetting[]): SortRow[] {
        const sortItem = sortItems.shift();
        const sortKey = sortItem.fields.join('_').replace('-', '');
        const valueType = /KPI/.test(sortKey) ? 'number' : 'string';

        const groupedByKey = this.groupByKey(sortKey, data, valueType);

        // Special handling for splitBy sorting, replace element ID
        // by group total KPI value, before being sorted
        if (sortKey === '$_groupId') {
            this.replaceSortValue(groupedByKey);
            this.removeTotalRows(groupedByKey);
        }

        this.sortGroup(groupedByKey, sortItem.direction);
        return groupedByKey.map(group => {
            if (sortItems.length > 0 && group.rows.length > 0) {
                group.rows = this.doSorting(group.rows, sortItems.slice());
            }
            return group;
        });
    }

    /**
     * Extends data set with temporary variables [$_groupId, $_groupTotal,
     * $_splitByValue] to enable grouping and sorting on for spliBy on it.
     */
    prepareDataSet(data: DataRow[], sortItems: SortSetting[]) {
        const groupTotals = {};
        const sortKey = sortItems[0].fields.join('_').replace('-', '');

        data.forEach(row => {
            const id = row['$id'].split('_')[0];
            row['$_groupId'] = id;
            const splitItemId = Object.keys(row).filter(part =>
                NON_ID_FIELDS.find(foundId => part.startsWith(foundId))
            );
            if (splitItemId.length > 0) {
                row['$_splitById'] = splitItemId[0];
                row['$_splitByValue'] = row[splitItemId[0]];
            }
            if (row.$id.indexOf(TOTAL_IDENTIFIER) > -1) {
                groupTotals[id] = row;
            }
        });

        // set total for each item
        if (Object.keys(groupTotals).length > 0) {
            data.forEach(row => {
                const id = row['$id'].split('_')[0];
                row['$_groupTotal'] = groupTotals[id][sortKey];
            });
            sortItems = this.getSplitBySortDefinition(sortItems[0].direction);
        }
        return sortItems;
    }

    groupByKey(groupKey: string, data: DataRow[] | SortRow[], valueType: string) {
        const hash = {};
        data.forEach(item => {
            const value = item[groupKey];
            if (hash[value]) {
                hash[value].push(item);
            } else {
                hash[value] = [item];
            }
        });
        const result = this.toArray(hash, valueType);
        return result;
    }

    toArray(hash: { [x: string]: any }, valueType: string) {
        return Object.keys(hash).map(key => {
            const sortValue =
                key !== 'null' ? (valueType === 'number' ? Number(key) : key) : null;
            return {
                sortValue,
                rows: hash[key]
            } as SortRow;
        });
    }

    /**
     * These settings override the the default/custom sortig setting,
     * cause of the need of special handling of splitBy sortings.
     *
     * 1) groupBy elementId (e.g.: Brand ID)
     * 2) sort by the selected group total KPI value
     * 3) sort by the feature Id
     * 4) sort by the feature value id
     *
     */
    private getSplitBySortDefinition(direction: number) {
        return [
            {
                fields: ['$_groupId'],
                direction
            },
            {
                fields: ['$_splitById'],
                direction: SortDirection.ASC
            },
            {
                fields: ['$_splitByValue'],
                direction: SortDirection.ASC
            }
        ];
    }

    private flatten(arr): DataRow[] {
        return arr.reduce((flat, toFlatten) => {
            return flat.concat(
                Array.isArray(toFlatten.rows) ? this.flatten(toFlatten.rows) : toFlatten
            );
        }, []);
    }

    private replaceSortValue(groupedByKey: SortRow[]) {
        groupedByKey.forEach(
            group => (group.sortValue = group['rows'][0]['$_groupTotal'])
        );
    }

    private removeTotalRows(groupedByKey: SortRow[]) {
        groupedByKey.forEach(group => {
            const rows = group.rows as DataRow[];
            group.rows =
                rows.length > 1 ? rows.filter(row => row['$_splitByValue']) : rows;
        });
    }

    private sortGroup(group: SortRow[], sortDirection?: SortDirection) {
        if (!sortDirection) {
            sortDirection = this.sortModel.getSorting().direction;
        }

        const key = 'sortValue';
        const order = sortDirection === SortDirection.ASC ? 1 : -1;

        group.sort((a, b) => {
            if (a[key] === null) {
                return 1;
            }

            if (b[key] === null) {
                return -1;
            }

            if (a[key] > b[key]) {
                return order;
            } else if (a[key] < b[key]) {
                return -1 * order;
            }
            return 0;
        });
    }
}
