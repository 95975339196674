import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessQuestionComponent } from './business-question/business-question.component';
import { BusinessQuestionWrapperComponent } from './business-question-wrapper/business-question-wrapper.component';
import { DropDownModule } from 'insightui.common-components/drop-down';
import { ArrowPanelModule } from 'insightui.common-components/arrow-panel';

@NgModule({
    declarations: [BusinessQuestionComponent, BusinessQuestionWrapperComponent],
    imports: [CommonModule, DropDownModule, ArrowPanelModule],
    exports: [BusinessQuestionWrapperComponent]
})
export class BusinessQuestionModule {}
