import { HttpClient } from '@angular/common/http';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { Inject, Injectable } from '@angular/core';
import { INSIGHT_CONFIG, InsightConfig } from 'insightui.global/global.module';
import { Observable, of } from 'rxjs';
import { ClientInfoDto } from '../client-switch.types';

@Injectable({ providedIn: 'root' })
export class ClientInfoService {
    private logger: ILogger;

    constructor(
        private httpClient: HttpClient,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig,
        private logService: LogService
    ) {
        this.logger = logService.getLogger('ClientInfoService');
    }

    getClientInfo(clientId: string): Observable<ClientInfoDto> {
        this.logger.debug('started loading of client info');

        if (clientId === null) {
            return of(null);
        }

        const url =
            this.insightConfig.services.QUERY_SERVICE.url + 'api/v1/clients/' + clientId;

        return this.httpClient.get<ClientInfoDto>(url);
    }

    public getClientInfos(): Observable<ClientInfoDto[]> {
        this.logger.debug('started loading of client info');
        const url = this.insightConfig.services.QUERY_SERVICE.url + 'api/v1/clients/';
        return this.httpClient.get<ClientInfoDto[]>(url);
    }
}
