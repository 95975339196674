declare var window: any;

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';

import 'ng-slider/dist/ng-slider.min.js'; // TODO remove?
import { IAngularStatic } from 'angular';

import { initAngularJs } from './app/module/core/src/main/javascript/app/app';

declare var angular: IAngularStatic;
declare var require: any;
declare var navigator: any;

const supportedLocales = ['de', 'en', 'fr'];
const requireLocales = require.context(
    'angular-i18n',
    true,
    /angular-locale_(de|en|fr)\.js$/
);
import { BootstrapModule } from './app/bootstrap/bootstrap.module';

import { LogService } from './app/core/services/logging';

window.addEventListener('error', event => {
    console.error(event);
    new LogService().getLogger('rootLogger').error(event);
});

console.group('maint.ts');

function startApp(insightConfig) {
    'use strict';

    let language = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage;
    if (!language) {
        language = 'en';
    }
    let locale = language.substring(0, 2);
    if (supportedLocales.indexOf(locale) < 0) {
        locale = 'en';
    }
    requireLocales('./angular-locale_' + locale + '.js');
    try {
        // tslint:disable-next-line: no-console
        console.debug('loading module insightui');
        angular.module('insightui');
        // tslint:disable-next-line: no-console
        console.debug('loaded module insightui');
    } catch (ex) {
        console.error('Error loading module insightui', ex);
        const redirectURL =
            insightConfig.services.FEDERATION_SERVICE.url + '/adfs/ls/?wa=wsignout1.0';
        window.location.replace(
            redirectURL + '&wreply=' + location.protocol + '//' + location.host
        );
        return;
    }
}

/**
 * This is just some webpack magic (https://webpack.js.org/guides/dependency-management/)
 * to include all required legacy AngularJS files
 * in the webpack compilation.
 */
function initModule() {
    'use strict';

    const currentContext = require.context('./app/module/core/lib/', true, /\.js$/);
    const chartLibContext = require.context('./app/module/chart/lib', true, /\.js$/);

    const chartContext = require.context('./app/module/chart/src/main', true, /\.js$/);

    const jsAppContext = require.context(
        './app/module/core/src/main/javascript/',
        true,
        /\.[jt]s$/
    );

    currentContext.keys().map(currentContext);
    jsAppContext.keys().map(jsAppContext);
    chartLibContext.keys().map(chartLibContext);
    chartContext.keys().map(chartContext);
}

initAngularJs(window.__insightuiConfig);
initModule();
startApp(window.__insightuiConfig);
if (environment.production) {
    enableProdMode();
}
platformBrowserDynamic().bootstrapModule(BootstrapModule);

console.groupEnd();
