import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { WINDOW } from 'insightui.global/global.module';
import { FeedSettingsControllerService, FeedSettingsResponse } from 'insightui.openapi/notification';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

const ATOM_MIME_TYPE = 'application/atom+xml';
const HELP_PAGE_URL = preserveClientId('help/feedNotification');
const HELP_PAGE_WINDOW_NAME = 'GfK.help.feedNotification';

@Component({
    selector: 'gfk-feed-notification-dialog',
    templateUrl: './feed-notification-dialog.component.html',
    styleUrls: ['./feed-notification-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedNotificationDialogComponent implements OnInit {
    public feedNotificationForm: FormGroup;

    public readonly copiedTooltipPosition: ConnectionPositionPair[];

    private logger: ILogger;

    public hasServerError = false;

    public get hasFeedUrl(): boolean {
        return !!this.feedNotificationForm.value.feedUrl;
    }

    public get hasValidFeedSettings(): boolean {
        return (
            !!this.feedNotificationForm.value.fullDelivery ||
            !!this.feedNotificationForm.value.firstPartOfDelivery
        );
    }

    constructor(
        @Inject(WINDOW) private window: Window,
        @Inject(DOCUMENT) private document: Document,
        private dialogRef: MatDialogRef<FeedNotificationDialogComponent>,
        private feedSettingsControllerService: FeedSettingsControllerService,
        private logService: LogService,
        private cdr: ChangeDetectorRef,
    ) {
        this.logger = this.logService.getLogger('FeedNotificationDialogComponent');

        this.feedNotificationForm = new FormGroup({
            firstPartOfDelivery: new FormControl(false),
            fullDelivery: new FormControl(false),
            feedUrl: new FormControl('')
        });

        this.copiedTooltipPosition = [
            new ConnectionPositionPair(
                { originX: 'end', originY: 'bottom' },
                { overlayX: 'start', overlayY: 'top' }
            )
        ];
    }

    ngOnInit() {
        this.feedSettingsControllerService
            .getSettings()
            .pipe(
                tap(settings => this.updateFormValues(settings)),
                catchError(error => {
                    if (error.status === 404) {
                        // no settings yet
                        this.feedNotificationForm.patchValue({
                            firstPartOfDelivery: false,
                            fullDelivery: false,
                            feedUrl: ''
                        });
                        return EMPTY;
                    } else {
                        this.logger.error(`Error while talking to backend API`, error);
                        this.markAsErrored();
                        return EMPTY;
                    }
                })
            )
            .subscribe();
    }

    saveFeed() {
        this.feedSettingsControllerService
            .saveSettings({
                notifyFirstPartOfDelivery: this.feedNotificationForm.value
                    .firstPartOfDelivery,
                notifyFullDelivery: this.feedNotificationForm.value.fullDelivery,
                resetUrl: false
            })
            .pipe(
                tap(settings => this.updateFormValues(settings)),
                catchError(error => {
                    this.logger.error(`Error while saving feed settings`, error);
                    this.markAsErrored();
                    return EMPTY;
                })
            )
            .subscribe();
    }

    resetFeed() {
        this.feedSettingsControllerService
            .saveSettings({
                notifyFirstPartOfDelivery: this.feedNotificationForm.value
                    .firstPartOfDelivery,
                notifyFullDelivery: this.feedNotificationForm.value.fullDelivery,
                resetUrl: true
            })
            .pipe(
                tap(settings => this.updateFormValues(settings)),
                catchError(error => {
                    this.logger.error(`Error while reseting feed`, error);
                    this.markAsErrored();
                    return EMPTY;
                })
            )
            .subscribe();
    }

    deleteFeed() {
        this.feedSettingsControllerService
            .deleteSettings()
            .pipe(
                tap(() => {
                    this.feedNotificationForm.patchValue({
                        firstPartOfDelivery: false,
                        fullDelivery: false,
                        feedUrl: ''
                    });
                    this.removeFeedLinkFromDocumentHead();
                    this.cdr.markForCheck();
                }),
                catchError(error => {
                    this.logger.error(`Error while deleting feed`, error);
                    this.markAsErrored();
                    return EMPTY;
                })
            )
            .subscribe();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    copyToClipboard(element: HTMLInputElement) {
        element.select();
        this.document.execCommand('copy');
        element.setSelectionRange(0, 0);
    }

    openHelpPage(): void {
        this.window.open(HELP_PAGE_URL, HELP_PAGE_WINDOW_NAME);
    }

    private updateFormValues(settings: FeedSettingsResponse) {
        this.feedNotificationForm.patchValue({
            firstPartOfDelivery: settings.notifyFirstPartOfDelivery,
            fullDelivery: settings.notifyFullDelivery,
            feedUrl: settings.feedUrl
        });
        if (settings.feedUrl) {
            this.insertFeedLinkToDocumentHead(settings.feedUrl);
        }
        this.cdr.markForCheck();
    }

    private insertFeedLinkToDocumentHead(feedUrl?: string): void {
        this.removeFeedLinkFromDocumentHead();
        if (feedUrl) {
            const headElement = this.document.getElementsByTagName('head').item(0);
            const newFeedLink = this.document.createElement('link');
            newFeedLink.rel = 'alternate';
            newFeedLink.type = ATOM_MIME_TYPE;
            newFeedLink.href = feedUrl;
            newFeedLink.title = 'GfK Retail Scout Delivery Notifications';
            headElement.appendChild(newFeedLink);
        }
    }

    private removeFeedLinkFromDocumentHead(): void {
        const headElement = this.document.getElementsByTagName('head').item(0);
        const existingFeedLink = headElement.querySelector(
            `link[rel="alternate"][type="${ATOM_MIME_TYPE}"]`
        );
        if (existingFeedLink) {
            headElement.removeChild(existingFeedLink);
        }
    }

    private markAsErrored() {
        this.hasServerError = true;
        this.cdr.markForCheck();
    }
}
