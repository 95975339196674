import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';
import { ThresholdModel } from 'insightui.core/models/reportSetting/threshold.model';
import { ChannelComparisonModel } from 'insightui.core/models/reportSetting/channel-comparison.model';
import { Injector } from '@angular/core';

declare var require: any;
const template = require('raw-loader!./rank-comparison.tooltip.html');

/**
 * Pipe for displaying differential data as performance indicator, e.g
 * as an arrow pointing up or down depending on the threshold.
 *
 * TODO: This is a little bit hacky right now, especially the excel export portion and the tooltip
 */
export class RankComparisonFilter {
    model: ThresholdModel;
    comparisonModel: ChannelComparisonModel;

    constructor(
        injector: Injector,
        private options: {
            positiveThreshold: string;
            negativeThreshold: string;
        }
    ) {
        this.model = injector.get(ThresholdModel);
        this.comparisonModel = injector.get(ChannelComparisonModel);
    }

    transform(value: number, cell: Cell) {
        const rank = this.model.findOne('id', 'rank');
        if (!rank || !rank.active) {
            return document.createElement('span');
        }

        if (!cell.value) {
            return;
        }
        value = Number(value.toFixed(3));
        const isTotal = cell.row.TG && cell.row.TG.indexOf('Total') >= 0;
        const thresholdValue = this.compareWithThreshold(value);
        let styles = [];
        let enableTooltip = true;
        if (thresholdValue > 0 && !isTotal) {
            styles = ['performance-positive', 'fa', 'fa-chevron-circle-up'];
        } else if (thresholdValue < 0 && !isTotal) {
            styles = ['performance-negative', 'fa', 'fa-chevron-circle-down'];
        } else {
            styles = ['performance-neutral'];
            enableTooltip = false;
        }

        const dom = this.createElement(styles, value);
        if (enableTooltip) {
            this.enableTooltip(dom, value, cell);
        }

        return dom;
    }

    transformExport(value, cell) {
        if (!cell.value) {
            return;
        }

        const thresholdValue = this.compareWithThreshold(value);
        if (thresholdValue > 0) {
            cell.class = 'good';
        } else if (thresholdValue < 0) {
            cell.class = 'bad';
        } else {
            cell.class = 'neutral';
        }
    }

    compareWithThreshold(value: number) {
        const threshold = this.getThreshold('rank', 1.0);
        if (value >= threshold) {
            return -1;
        } else {
            return 0;
        }
    }

    replacePlaceholders(value: number, cell: Cell) {
        const BRAND_IDX = 'BR';
        const ITEM_IDX = 'IT';
        let result = template;
        const selected = this.comparisonModel.findOne('selected', true);
        result = result.replace(/\$baseChannel/gi, selected.baseTitle);
        result = result.replace(/\$targetChannel/gi, selected.title);
        result = result.replace(/\$value/, Math.abs(value));

        result = result.replace(
            /\$rowLabel/,
            cell.row[BRAND_IDX] || '' + ' ' + cell.row[ITEM_IDX] || ''
        );
        return result;
    }

    private createElement(styles, value: number) {
        const dom = document.createElement('span');
        dom.classList.add('performance-indicator');
        dom.style.width = '14px';
        dom.style.display = 'inline-block';
        styles.forEach(style => dom.classList.add(style));
        dom.setAttribute('data-value', String(value));
        return dom;
    }

    private enableTooltip(dom, value, cell) {
        const box: HTMLElement = document.createElement('div');
        box.setAttribute('style', 'position:absolute');

        dom.addEventListener('mouseenter', (mouseEvent: MouseEvent) => {
            box.innerHTML = this.replacePlaceholders(value, cell);

            if (window.innerWidth <= mouseEvent.pageX + 15 + 350) {
                box.style.left = mouseEvent.pageX - 15 - 250 + 'px';
            } else {
                box.style.left = mouseEvent.pageX + 15 + 'px';
            }

            if (window.innerHeight <= mouseEvent.pageY + 15 + 150) {
                box.style.top = mouseEvent.pageY - 15 - 80 + 'px';
            } else {
                box.style.top = mouseEvent.pageY + 15 + 'px';
            }
            document.body.appendChild(box);
        });

        dom.addEventListener('mouseleave', () => {
            document.body.removeChild(box);
        });
    }

    private getThreshold(thresholdId: string, defaultValue) {
        const thresholdSetting = this.model;
        const threshold = thresholdSetting.findOne('id', thresholdId);
        if (threshold) {
            return threshold.value;
        }
        return defaultValue;
    }
}
