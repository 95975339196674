import { Pipe, PipeTransform } from '@angular/core';
import { LoadStateForDialog } from '../load-status.types';

@Pipe({
    name: 'visibleNodes',
    pure: false
})
export class VisibleNodesPipe implements PipeTransform {
    transform(items: LoadStateForDialog[]): any {
        if (!items) {
            return items;
        }
        return items.filter(item => item.isVisible);
    }
}
