import { ClientInfoDto } from '../client-switch.types';

export class CsLoadClientInfos {
    static readonly type = '[CS] CsLoadClientInfos';

    constructor() {}
}

export class CsLoadClientInfosSuccess {
    static readonly type = '[CS] CsLoadClientInfosSuccess';

    constructor(public readonly clientInfos: ClientInfoDto[]) {}
}
