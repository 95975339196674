import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { MetadataState } from './state/metadata.state';
import { MetadataHandler } from './state/metadata.handler';
import { MetadataSelectors } from './state/metadata.selectors';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';

@NgModule({
    imports: [CommonModule, NgxsModule.forFeature([MetadataState])],
    providers: [
        MetadataHandler,
        MetadataSelectors,
        {
            provide: EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX,
            useValue: /.*?revisions\/?/,
            multi: true
        }
    ]
})
export class MetadataModule {
    constructor(private metadataHandler: MetadataHandler) {}
}
