<gfk-modal-header
    [title]="'Load Status for ' + displayPeriod"
    [iconClass]="'fa fa-calendar-check-o'"
    data-uitest="load-status-dialog-header"
>
</gfk-modal-header>
<div>
    <gfk-search-box
        placeholder="Search product groups"
        (searchTextChanged)="onSearchTextChanged($event)"
    ></gfk-search-box>
    <div class="headline">
        {{ dimensionsTree.labelTitle }}
        <span class="status">Status</span>
    </div>
    <ol id="tree-root" class="node-list">
        <li *ngFor="let node of dimensionsTree.nodes | visibleNodes">
            <gfk-load-status-entry
                [node]="node"
                [period]="period"
                [searchText]="searchText"
            ></gfk-load-status-entry>
        </li>
    </ol>
</div>
<gfk-modal-footer>
    <gfk-modal-button
        id="__testCaseSubmitButton"
        buttonType="BUTTON"
        (click)="closeDialog()"
    >
        Close
    </gfk-modal-button>
</gfk-modal-footer>
<gfk-modal-closer
    (click)="closeDialog()"
    data-uitest="close-dialog-icon"
></gfk-modal-closer>
