import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, WINDOW } from 'insightui.global/global.module';
import { ReportInIFrame } from 'insightui.export-collection/export-collection.types';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CollectionReportService } from 'insightui.collectionmanager/services/collection-report.service';

@Injectable({
    providedIn: 'root'
})
export class ReportInIFrameService {
    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private collectionReportService: CollectionReportService
    ) {}

    openReport(report: ReportSnapshotDto): Observable<ReportInIFrame> {
        return this.collectionReportService.getReportLink(report).pipe(
            // take only the first (= current) link to the report
            // otherwise loading of reports would never finish because it would wait for further links to be emitted
            take(1),
            map(reportLink =>
                this.openReportByUrl(report.id, this.absoluteUrl(reportLink))
            )
        );
    }

    private absoluteUrl(relativeUrl: string): string {
        return this.window.location.origin + '/export' + relativeUrl;
    }

    private openReportByUrl(reportId: string, absoluteReportUrl: string): ReportInIFrame {
        const frameElement = this.document.createElement('iframe');
        frameElement.src = absoluteReportUrl;
        frameElement.width = '1900px';
        frameElement.height = '1200px';
        frameElement.style.position = 'absolute';
        frameElement.style.top = '-1000vh';

        this.document.body.appendChild(frameElement);

        return {
            isReport: (otherReportId: string) => otherReportId === reportId,
            dispose: () => this.document.body.removeChild(frameElement)
        };
    }
}
