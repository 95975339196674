import { CsLoadClientInfos, CsLoadClientInfosSuccess } from './client-switch.actions';
import { Injectable, OnDestroy } from '@angular/core';
import { Store, Actions, ofActionDispatched } from '@ngxs/store';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';
import { Subject, Observer, EMPTY } from 'rxjs';
import { takeUntil, tap, mergeMap, catchError, map } from 'rxjs/operators';
import { ClientInfoService } from 'insightui.client-switch/services/client-info.service';

@Injectable()
export class ClientSwitchHandler implements OnDestroy {
    /**
     * watches the action$ subscriptions and logs any unexpected
     * termination.
     */
    private readonly obs: Observer<any> = {
        next: _ => void 0,
        error: err => {
            this.logger.error('Observable errored and terminated unexpectedly', err);
            this._unitTestIsTerminated = true;
        },
        complete: () => {
            this.logger.error('Observable completed unexpectedly');
            this._unitTestIsTerminated = true;
        }
    };

    /**
     * Only used in unittests to ensure the
     * action$ does not get terminated unexpectedly
     */
    public _unitTestIsTerminated = false;

    private _killer$$ = new Subject<void>();

    private logger: ILogger;

    private actions$: Actions;

    constructor(
        private store: Store,
        private clientInfoService: ClientInfoService,
        actionsInternal$: Actions,
        logService: LogService
    ) {
        this.logger = logService.getLogger('ClientSwitchHandler');
        this.actions$ = actionsInternal$.pipe(takeUntil(this._killer$$));

        this.handleLoadClientInfos();

        this.logger.debug('ctor');
    }

    ngOnDestroy() {
        this._killer$$.next();
        this._killer$$.complete();
    }

    private handleLoadClientInfos() {
        this.actions$
            .pipe(
                ofActionDispatched(CsLoadClientInfos),
                mergeMap(_ =>
                    this.clientInfoService.getClientInfos().pipe(
                        catchError(err => {
                            this.logBackendError(err);
                            return EMPTY;
                        })
                    )
                ),
                map(clientInfoDtos => new CsLoadClientInfosSuccess(clientInfoDtos)),
                tap(action => this.store.dispatch(action))
            )
            .subscribe(this.obs);
    }

    private logBackendError(err: any) {
        this.logger.error(`Error while talking to backend API`, err);
    }
}
