import { Injectable } from '@angular/core';
import { Validation } from './validation';
import { MAX_COLLECTION_NAME_LENGTH } from './validationConfig';
import { MAX_COLLECTION_REPORT_NAME_LENGTH } from './validationConfig';
import { CollectionDto } from 'insightui.openapi/userprofile';

const validationMessage = {
    INVALID_COLLECTION_NAME:
        'Invalid name. You can only use letters, numbers, spaces and the following special characters: (){}$^&!#%_~-',
    NONE_UNIQUE_COLLECTION_NAME: 'This name is already used, please change!',
    COLLECTION_NAME_NO_ALPHA_NUM: 'The name does not contain any letter or number.',
    NAME_TOO_LONG: (maxLength: number) =>
        `The name exceeds the maximum length of ${maxLength} characters.`
};

@Injectable({ providedIn: 'root' })
export class ValidationService {
    private static ALPHANUMERIC =
        'a-zA-Z0-9' +
        'äàáâąæëèéêęïìíîıöòóôœüùúûçćğłñńśşÿźżß' +
        'ÄÀÁÂĄÆËÈÉÊĘÏÌÍÎİÖÒÓÔŒÜÙÚÛÇĆĞŁÑŃŚŞŸŹŻẞ';
    private static SPACE = ' ';
    private static SPECIAL = ValidationService.SPACE + '(){}$^&!#%_~-';

    private static VALID_NAME = new RegExp(
        '^[' + ValidationService.ALPHANUMERIC + ValidationService.SPECIAL + ']+$'
    );

    private static DOES_CONTAIN_ALPHA_NUMERIC = new RegExp(
        '[' + ValidationService.ALPHANUMERIC + ']'
    );

    readonly doesContainValidChars: Validation<string>;
    readonly doesContainAlphaNumericChar: Validation<string>;
    readonly isValidCollectionName: Validation<string>;
    readonly isValidCollectionReportName: Validation<string>;
    readonly hasValidName: Validation<CollectionDto>;

    constructor() {
        this.doesContainValidChars = Validation.rule(
            validationMessage.INVALID_COLLECTION_NAME,
            (name: string) => {
                return ValidationService.VALID_NAME.test(name.trim());
            }
        );

        this.doesContainAlphaNumericChar = Validation.rule(
            validationMessage.COLLECTION_NAME_NO_ALPHA_NUM,
            (name: string) => {
                return ValidationService.DOES_CONTAIN_ALPHA_NUMERIC.test(name.trim());
            }
        );

        this.isValidCollectionName = this.doesContainValidChars
            .andThen(this.doesContainAlphaNumericChar)
            .andThen(this.doesNotExceedTheMaximumLength(MAX_COLLECTION_NAME_LENGTH));

        this.isValidCollectionReportName = this.doesContainValidChars
            .andThen(this.doesContainAlphaNumericChar)
            .andThen(
                this.doesNotExceedTheMaximumLength(MAX_COLLECTION_REPORT_NAME_LENGTH)
            );

        this.hasValidName = this.isValidCollectionName.changeInput(
            (collection: CollectionDto) => collection.name
        );
    }

    doesNotExceedTheMaximumLength(maxLength: number): Validation<string> {
        const msg = validationMessage.NAME_TOO_LONG(maxLength);
        return Validation.rule(msg, name => name.length <= maxLength);
    }

    hasUniqueName(collections: CollectionDto[]): Validation<CollectionDto> {
        return Validation.rule(
            validationMessage.NONE_UNIQUE_COLLECTION_NAME,
            (collection: CollectionDto) => {
                return !collections.some((collectionItem: CollectionDto) => {
                    return (
                        collection.name.toLowerCase() ===
                            collectionItem.name.toLowerCase() &&
                        collection.id !== collectionItem.id
                    );
                });
            }
        );
    }
}
