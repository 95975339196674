import { Component, Inject, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { WINDOW_NAME_COLLECTION_MANAGER } from 'insightui.collectionmanager/constants';
import { CmLoadNewSharedCollections } from 'insightui.collectionmanager/state/collection-manager.actions';
import { WINDOW } from 'insightui.global/global.module';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CrossWindowNotification } from '../services';
import { CollectionManagerState } from '../state/collection-manager.state';
import { addMetadataParams } from '../utils';
import { preserveClientId } from 'insightui.core/services/navigation-utils';
import { FavouriteManagerState } from 'insightui.favouritemanager/state/favourite-manager.state';

declare global {
    interface Window {
        chrome: any;
    }
}

@Component({
    selector: 'gfk-collection-manager-shortcut',
    templateUrl: './collection-manager-shortcut.component.html',
    styleUrls: ['./collection-manager-shortcut.component.scss']
})
export class CollectionManagerShortcutComponent implements OnInit {
    @Select(CollectionManagerState.newSharedCollectionIds)
    public newSharedCollectionIds$: Observable<string[]>;

    @Select(FavouriteManagerState.loadError)
    public hasError$: Observable<boolean>;

    public newSharedCollectionsCount$: Observable<number>;

    public sharedCollectionNotificationText$: Observable<string>;

    constructor(
        @Inject(WINDOW) private window: Window,
        private store: Store,
        private ngxsStore: Store,
        private crossWindowNotification: CrossWindowNotification,
        private metadataService: MetadataService
    ) {}

    ngOnInit() {
        this.newSharedCollectionsCount$ = this.newSharedCollectionIds$.pipe(
            map(ids => ids.length)
        );

        this.sharedCollectionNotificationText$ = combineLatest([
            this.newSharedCollectionsCount$,
            this.hasError$
        ]).pipe(
            map(([cnt, hasError]) => {
                if (!hasError) {
                    return this.getSharedCollectionNotificationText(cnt);
                }
                return 'Collections Manager is currently not available. We are very sorry for the inconvenience this may cause. Please try again later.';
            })
        );

        this.store
            .select(state => state.metadata.countries)
            .pipe(
                filter(countries => !!countries && countries.length > 0),
                map(countries => countries.map(c => c.countryCode)),
                take(1)
            )
            .subscribe(countryCodes =>
                this.ngxsStore.dispatch(new CmLoadNewSharedCollections(countryCodes))
            );
    }

    openCollectionManager(): void {
        this.hasError$
            .pipe(
                take(1),
                tap(hasError => {
                    if (!hasError) {
                        let url = '/collectionsManager';

                        const windowName = WINDOW_NAME_COLLECTION_MANAGER;
                        if (this.window.name === windowName) {
                            this.window.name = '';
                        }

                        url = addMetadataParams(url, this.metadataService);
                        url = preserveClientId(url);

                        if (!this.window.chrome) {
                            this.crossWindowNotification.notifyCollectionManagerClose();
                            setTimeout(() => this.window.open(url, '_blank'), 10);
                        } else {
                            this.window.open(url, windowName);
                        }
                    }
                })
            )
            .subscribe();
    }

    private getSharedCollectionNotificationText(
        newSharedCollectionsCount: number
    ): string {
        if (newSharedCollectionsCount === 1) {
            return `There is ${newSharedCollectionsCount} new collection available.`;
        } else if (newSharedCollectionsCount > 1) {
            return `There are ${newSharedCollectionsCount} new collections available.`;
        }
        return '';
    }
}
