import { Injectable } from '@angular/core';
import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';

@Injectable()
export class HybridUrlHandlingStrategy implements UrlHandlingStrategy {
    private logger: ILogger;
    constructor(logService: LogService) {
        this.logger = logService.getLogger('HybridUrlHandlingStrategy');
    }
    shouldProcessUrl(url: UrlTree) {
        if (url.queryParamMap.has('ssoAction')) {
            this.logger.debug('HybridUrlHandlingStrategy: ssoAction present, ignoring');
            return false;
        }
        return true;
    }
    extract(url: UrlTree) {
        return url;
    }
    merge(url: UrlTree, whole: UrlTree) {
        return url;
    }
}
