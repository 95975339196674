import { Inject, Injectable } from '@angular/core';
import { WINDOW } from 'insightui.global/global.module';
import {
    EXPORT_COLLECTION_SHEETS_FROM_IFRAME,
    EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR
} from 'insightui.export-collection/export-collection.actions';
import {
    ReportInIFrame,
    ReportSheetsFromIFrame
} from 'insightui.export-collection/export-collection.types';
import { ReportInIFrameService } from 'insightui.export-collection/report-in-iframe.service';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';
import { concat, fromEvent, Observable, race, throwError } from 'rxjs';
import { finalize } from 'rxjs/internal/operators';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ListSelection } from 'insightui.collectionmanager/models/list-selection';

@Injectable({
    providedIn: 'root'
})
export class ExportCollectionService {
    constructor(
        private reportInIFrameService: ReportInIFrameService,
        @Inject(WINDOW) private window: Window
    ) {}

    exportCollection(
        selectedReports: ListSelection<ReportSnapshotDto>
    ): Observable<ReportSheetsFromIFrame> {
        const sheets$: Observable<
            ReportSheetsFromIFrame
        >[] = selectedReports.elements.map(report => this.getSheetsForReport(report));

        return concat(...sheets$);
    }

    private getSheetsForReport(
        report: ReportSnapshotDto
    ): Observable<ReportSheetsFromIFrame> {
        return this.reportInIFrameService
            .openReport(report)
            .pipe(switchMap(reportInIFrame => this.getSheetsFromIFrame(reportInIFrame)));
    }

    private getSheetsFromIFrame(
        reportInIFrame: ReportInIFrame
    ): Observable<ReportSheetsFromIFrame> {
        return this.waitForSheets(reportInIFrame).pipe(
            finalize(() => reportInIFrame.dispose())
        );
    }

    private waitForSheets(
        reportInIFrame: ReportInIFrame
    ): Observable<ReportSheetsFromIFrame> {
        const messageEventData$ = fromEvent(this.window, 'message').pipe(
            map((messageEvent: MessageEvent) => messageEvent.data)
        );

        const sheetsFromIFrame$: Observable<
            ReportSheetsFromIFrame
        > = messageEventData$.pipe(
            filter(EXPORT_COLLECTION_SHEETS_FROM_IFRAME.match),
            filter(({ payload }) => reportInIFrame.isReport(payload.reportId)),
            map(({ payload }) => payload)
        );

        const errorFromIFrame$: Observable<
            ReportSheetsFromIFrame
        > = messageEventData$.pipe(
            filter(EXPORT_COLLECTION_SHEETS_IN_IFRAME_ERROR.match),
            filter(({ payload }) => reportInIFrame.isReport(payload.reportId)),
            switchMap(({ payload }) => throwError(payload.message))
        );

        return race(errorFromIFrame$, sheetsFromIFrame$).pipe(take(1));
    }
}
