import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartViewComponent } from './chart-view/chart-view.component';
import { AuthoringChartDirective } from './authoring-chart/authoring-chart.directive';

@NgModule({
    declarations: [ChartViewComponent, AuthoringChartDirective],
    imports: [CommonModule],
    providers: []
})
export class ReportPerformanceChartModule {}
