<span
    class="performanceIndicator fa"
    [ngClass]="{
        'fa-chevron-circle-up performanceIndicator_positive':
            performanceIndicator.isPositive === true,
        'fa-chevron-circle-down performanceIndicator_negative':
            performanceIndicator.isPositive === false
    }"
>
    <div
        class="performanceIndicator_tooltip tooltip cursor tooltip-overlay fade in"
        [ngClass]="{
            'performanceIndicator_tooltip-invertedLeft': invertToolTipLeftPosition,
            'performanceIndicator_tooltip-invertedTop': invertToolTipTopPosition
        }"
    >
        <div class="tooltip-inner">
            <h3
                [ngClass]="{
                    'ranking-good': performanceIndicator.isPositive,
                    'ranking-bad': !performanceIndicator.isPositive
                }"
            >
                {{ performanceIndicator.baseChannel }} vs.
                {{ performanceIndicator.targetChannel }}
            </h3>
            <div>
                {{ performanceIndicator.baseChannel }} performance
                {{ performanceIndicator.rowLabel }} is {{ performanceIndicator.gap }}
                {{ performanceIndicator.isPositive ? 'better than' : 'worse than' }}
                {{ performanceIndicator.targetChannel }}
            </div>
        </div>
    </div>
</span>
