<div class="value-share-line">
    <div
        class="value-share-line__line"
        [style.left.%]="(lineValuePercent / maxValuePercent) * 100"
    ></div>
</div>

<div
    class="bar-chart bar-chart--horizontal"
    (mouseenter)="handleGapValueEnter()"
    (mouseleave)="handleGapValueLeave()"
>
    <div
        class="bar-chart__amount"
        [class.bar-chart__amount--dark]="useDarkColor"
        [style.width.%]="(currentValuePercent / maxValuePercent) * 100"
    ></div>
    <gfk-fair-share-gap-value-hover
        *ngIf="showGapValueHover"
        [maxValuePercent]="maxValuePercent"
        [lineValuePercent]="lineValuePercent"
        [gapValuePercent]="currentValuePercent"
        [gapValue]="gapValue"
    >
    </gfk-fair-share-gap-value-hover>
</div>
