import { HtmlElementUnionService } from './powerpoint/services/html-element-union.service';
import { FixedSizeChart } from './powerpoint/charts/fixed-size-chart/fixed-size-chart';
import { PowerpointExportButtonComponent } from './powerpoint/powerpoint-export-button.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ChartDetectionService } from './powerpoint/services/chart-detection.service';
import { PowerpointExportService } from './powerpoint/services/powerpoint-export.service';
import { SavePowerpointService } from './powerpoint/services/save-powerpoint.service';
import { BarChart } from './powerpoint/charts/bar-chart/bar-chart';
import { PowerpointInfoRowService } from './powerpoint/services/powerpoint-info-row.service';
import { ChartCleanupService } from './powerpoint/services/chart-cleanup.service';
import { ChartElementService } from './powerpoint/services/chart-element.service';
import { ChartToSheetService } from './powerpoint/services/chart-to-sheet.service';
import { POWERPOINT_EXPORT_TYPES, PowerpointExportTypes } from './types';
import { VerticalReport } from './powerpoint/charts/vertical-report/vertical-report';
import { VerticalChartStatisticService } from './powerpoint/charts/vertical-report/vertical-chart-statistic.service';
import { PowerpointExportActiveReportService } from './powerpoint/services/powerpoint-export-active-report.service';
import { HeadElementRetrieverService } from './powerpoint/services/head-element-retriever.service';
import { TableReport } from './powerpoint/tables/table-report';
import { TableElementService } from './powerpoint/tables/table-element.service';
import { TableElementCleanupService } from './powerpoint/tables/table-element-cleanup.service';
import { TableToSheetService } from './powerpoint/tables/table-to-sheet.service';
import { FairShare } from './powerpoint/charts/fair-share/fair-share.service';
import { ExportHeadlineService } from './services/export-headline.service';
import { ExcelExportButtonComponent } from './excel/excel-export-button.component';
import { ExportExtraInfoService } from './services/export-extra-info.service';

const powerpointExportTypes: PowerpointExportTypes = {
    verticalReport: VerticalReport,
    barChart: BarChart,
    fixed: FixedSizeChart,
    table: TableReport,
    fairShare: FairShare
};

const components = [PowerpointExportButtonComponent, ExcelExportButtonComponent];

@NgModule({
    imports: [CommonModule],
    exports: components,
    declarations: components,
    providers: [
        { provide: POWERPOINT_EXPORT_TYPES, useValue: powerpointExportTypes },
        PowerpointExportService,
        PowerpointExportActiveReportService,
        ChartCleanupService,
        ChartElementService,
        ChartToSheetService,
        FixedSizeChart,
        BarChart,
        VerticalReport,
        VerticalChartStatisticService,
        SavePowerpointService,
        HtmlElementUnionService,
        ChartDetectionService,
        PowerpointInfoRowService,
        ExportExtraInfoService,
        HeadElementRetrieverService,
        TableReport,
        TableElementService,
        TableElementCleanupService,
        TableToSheetService,
        FairShare,
        ExportHeadlineService
    ]
})
export class ExportModule {}
