export enum Keys {
    UP = 38,
    DOWN = 40,
    ENTER = 13,
    ESCAPE = 27,
    LEFT = 37,
    RIGHT = 39
}

export const ColumnFreezeMode = Object.freeze({
    SIDE: 'side' as ColumnPosition,
    MAIN: 'main' as ColumnPosition
});

export enum ColumnType {
    SIDE_HEADER,
    TOP_HEADER
}

export interface Column {
    id?: string;
    field: string;
    flexWidth?: string;
    flexGrow?: number;
    minWidth?: number;
    width: number;
    maxWidth?: number;
    oldWidth?: number;
    autoResize?: boolean;
    freezeMode?: ColumnPosition;
    cssClasses?: string;
    dataType?: string;
    fractionalDigits?: number;
    $pipe?: { (string): string };
}

export interface ColumnHeader {
    uuid: string;
    id?: string;
    name: string;
    flexWidth?: string;
    width?: number;
    level?: number;
    dimension?: string;
    dimensionType?: string;
    field?: string;
    cssClasses?: string;
    type?: ColumnType;
    kpi?: string;
    attributes?: { [key: string]: any };
    children?: ColumnHeader[];
}

export interface ColumnEvent {
    column: Column;
    newValue: number;
    prevValue?: number;
}

export interface CellValue {
    key: string;
    value: string | number;
}

export interface ScrollPos {
    readonly offsetX: number;
    readonly pane: ColumnPane;
}

export class Index {
    first = 0;
    last = 0;
}

export type ColumnPosition = 'side' | 'main';

export class ColumnPane {
    pos: ColumnPosition;
    columns: Column[];

    constructor(pos: ColumnPosition, columns: Column[]) {
        this.pos = pos;
        this.columns = columns;
    }
}

export class ColumnPaneWidths {
    side = 0;
    main = 0;
    total = 0;
}

export interface DataRow {
    $$index?: number;
    $id?: string;

    $_groupId?: string;
    TG?: string;
    [key: string]: string | number;
}
