import { ReportingDataEntry } from 'insightui.data/query/queryservice/query-service-request.interface';
import { ResponseMapper } from 'insightui.data/operator/response-mapper.service';
import { Injectable } from '@angular/core';

export enum SortDirection {
    ASC,
    DESC
}

@Injectable()
export class ResponseSorter implements ResponseMapper {
    private sortDirectionValue = 1;
    private sortBy: string | string[];

    configure(sortBy: string | string[], sortDirection: SortDirection) {
        this.sortBy =
            typeof sortBy === 'string'
                ? (this.sortBy = [sortBy])
                : (this.sortBy = sortBy);

        if (sortDirection === SortDirection.DESC) {
            this.sortDirectionValue = -1;
        }
    }

    map(rows: ReportingDataEntry[]): ReportingDataEntry[] {
        return rows.sort((a, b) => {
            for (let i = 0; i < this.sortBy.length; i++) {
                const left = a[this.sortBy[i]],
                    right = b[this.sortBy[i]];
                if (left > right) {
                    return this.sortDirectionValue;
                } else if (left < right) {
                    return -1 * this.sortDirectionValue;
                }
            }
            return 0;
        });
    }
}
