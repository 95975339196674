import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import { ActiveChartSorting } from '../../services/activeChartSorting.service';
import { ChartLegendBarChartService } from '../../services/chartLegendBarChart.service';
import { ChartConfiguration, LegendRow } from '../../types';
import { SortingOption } from 'insightui.data/types';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'chart-legend-bar-chart',
    templateUrl: './chartLegendBarChart.component.html',
    styleUrls: ['./chartLegendBarChart.component.scss']
})
export class ChartLegendBarChartComponent {
    legendRows$: Observable<LegendRow[]>;

    isSelectable$: Observable<boolean>;

    sortingOptions$: Observable<SortingOption[]> = EMPTY;

    private chartDrillThroughService;

    private _configuration: ChartConfiguration = null;

    private _isDragging = false;

    @Input()
    set configuration(newConfig: ChartConfiguration) {
        this._configuration = newConfig;
        this.setupLegendWithConfig(newConfig);
    }

    get configuration(): ChartConfiguration {
        return this._configuration;
    }

    constructor(
        private chartLegendBarChartService: ChartLegendBarChartService,
        private activeChartSorting: ActiveChartSorting
    ) {
        AngularInjectorResolver.waitForInjector().then(injector => {
            this.chartDrillThroughService = injector.get('ChartDrillThroughService');
        });
    }

    onDragStart() {
        this._isDragging = true;
    }

    onDragEnd() {
        this._isDragging = false;
    }

    legendRowId(index: number, legendRow: LegendRow): string {
        return legendRow.id;
    }

    openPage(row: LegendRow): void {
        if (
            row.lastDimension === 'item' &&
            row.id !== 'Others' &&
            row.id !== 'FV_Others'
        ) {
            this.chartDrillThroughService.openPage('116a', row.id, row.title);
        }
    }

    handleChangeSorting(option: SortingOption): void {
        this.activeChartSorting.changeSorting(this.configuration, option.sorting);
    }

    get isDraggingAnyRow(): boolean {
        return this._isDragging;
    }

    cssClassesForRow(row: LegendRow): { [cssClass: string]: boolean } {
        return {
            [row.index]: true,
            [row.lastDimension]: true,
            ['dimension-' + row.id]: true,
            'chart-legend__cell--is-draggable': row.draggable
        };
    }

    onDrop(event: CdkDragDrop<any>) {
        const rowIds = event.item.data.rows.map(r => r.id);
        moveItemInArray(rowIds, event.previousIndex, event.currentIndex);
        this.activeChartSorting.changeSortingManually(this.configuration, rowIds);
    }

    isOthersValue(row: LegendRow): boolean {
        return row.id === 'Others' || row.id === 'FV_Others';
    }

    private setupLegendWithConfig(configuration: ChartConfiguration): void {
        this.legendRows$ = this.chartLegendBarChartService.legendRows(configuration).pipe(
            map(legendRows => {
                return legendRows.filter(row => !!row.title);
            })
        );

        this.sortingOptions$ = this.activeChartSorting.getSelectedSortingOptions(
            configuration
        );
        this.isSelectable$ = this.activeChartSorting.isSelectable(configuration);
    }
}
