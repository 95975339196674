import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlHandlingStrategy } from '@angular/router';
import { UpgradeModule } from '@angular/upgrade/static';
import { Angulartics2Segment } from 'angulartics2/segment';
import { Angulartics2RouterlessModule } from 'angulartics2/routerlessmodule';
import { AnnouncementsModule } from 'insightui.announcements/announcements.module';
import { RootComponent } from './root/root.component';
import { InsightUiRoutingModule } from './routing/routing.module';
import { HybridUrlHandlingStrategy } from './routing/hybrid-url-handling.strategy';
import { ChartModule } from 'insightui.chart/chart.module';
import { LandingPageModule } from 'insightui.core/components/landing-page/landing-page.module';
import { InsightCoreModule } from 'projects/insightui/src/app/core/core.module';
import { DashboardModule } from 'insightui.dashboard/dashboard.module';
import { DataModule } from 'insightui.data/data.module';
import { ErrorModule } from 'insightui.error/error.module';
import { ExportCollectionModule } from 'insightui.export-collection/export-collection.module';
import { ExportModule } from 'insightui.export/export.module';
import { FairShareModule } from 'insightui.fair-share/fair-share.module';
import { LoadingIndicatorModule } from 'insightui.loading-indicator/loading-indicator.module';
import { MetadataModule } from 'insightui.metadata/metadata.module';
import { NumberFormatModule } from 'insightui.number-format/number-format.module';
import { BaseUrlModule } from 'insightui.openapi/base-url.module';
import { PageDefinitionModule } from 'insightui.page-definition/page-definition.module';
import { PeriodModule } from 'insightui.period/period.module';
import { ReportFilterModule } from 'insightui.report-filter/report-filter.module';
import { ReportPerformanceChartModule } from 'insightui.report-performance-chart/app/report-performance-chart.module';
import { SessionModule } from 'insightui.session/session.module';
import { StateModule } from 'projects/insightui/src/app/state/state.module';
import { TableModule } from 'insightui.table/table.module';
import { ToastrModule } from 'ngx-toastr';
import { Md5 } from 'ts-md5/dist/md5';
import { PageNotFoundModule } from 'insightui.page-not-found/page-not-found.module';
import { FeedNotificationModule } from 'insightui.feed-notification/feed-notification.module';
import { ClientSwitchModule } from 'insightui.client-switch/client-switch.module';
import { TopMetaBarModule } from 'insightui.top-meta-bar/top-meta-bar.module';
import { FavoriteSelectByReportModule } from 'insightui.favorite-select-by-report/favourite-select-by-report.module';

const supportedLocales = [
    'de',
    'de-DE',
    'it',
    'fr',
    'es',
    'en',
    'ca',
    'no',
    'fi',
    'sv',
    'tr',
    'en-GB',
    'pt',
    'el',
    'bg'
];

const DEFAULT_LOCALE = 'en';
let LOCALE = DEFAULT_LOCALE;
if (supportedLocales.includes(navigator.language)) {
    LOCALE = navigator.language;
} else {
    const safeNavigatorLanguage = navigator.language || '';
    const languageCode = safeNavigatorLanguage.split('-')[0];
    if (languageCode && supportedLocales.includes(languageCode)) {
        LOCALE = languageCode;
    }
}

@NgModule({
    imports: [
        BrowserModule,
        UpgradeModule,
        BaseUrlModule.forRoot(),
        HttpClientModule,
        ErrorModule.forRoot(),
        PeriodModule,
        DragDropModule,
        InsightCoreModule,
        ChartModule,
        DataModule,
        TableModule,
        ExportModule,
        ExportCollectionModule,
        BrowserAnimationsModule,
        InsightUiRoutingModule,
        StateModule,
        Angulartics2RouterlessModule.forRoot({
            pageTracking: { autoTrackVirtualPages: false, clearHash: true }
        }),
        FavoriteSelectByReportModule,
        DashboardModule,
        AnnouncementsModule,
        ReportFilterModule,
        FairShareModule,
        ReportPerformanceChartModule,
        LoadingIndicatorModule,
        NumberFormatModule,
        SessionModule,
        PageNotFoundModule,
        PageDefinitionModule,
        MetadataModule,
        LandingPageModule,
        ClientSwitchModule,
        FeedNotificationModule,
        TopMetaBarModule,
        ToastrModule.forRoot()
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                return () => {
                    return import(
                        /* webpackInclude: /(de|de-DE|it|fr|es|en|ca|no|fi|sv|tr|en-GB|pt|el|bg)\.js$/ */
                        /* webpackChunkName: "locales/[request]" */
                        `@angular/common/locales/${LOCALE}.js`
                    )
                        .catch(e => {
                            console.warn(`Error during import of locales`, e);
                            // some locales like de-DE are not reachable under de-DE.js instead we have to use de.js
                            const languageCode = LOCALE.split('-')[0];
                            return import(
                                /* webpackInclude: /(de|de-DE|it|fr|es|en|ca|no|fi|sv|tr|en-GB|pt|el|bg)\.js$/ */
                                /* webpackChunkName: "locales/[request]" */
                                `@angular/common/locales/${languageCode}.js`
                            );
                        })
                        .then(loc => registerLocaleData(loc.default))
                        .catch(e => {
                            console.warn(`Error during registering of localeData`, e);
                        });
                };
            },
            multi: true
        },
        { provide: UrlHandlingStrategy, useClass: HybridUrlHandlingStrategy },
        { provide: LOCALE_ID, useValue: LOCALE },
        Md5
    ],
    declarations: [RootComponent]
})
export class BootstrapModule {
    constructor(
        private upgradeModule: UpgradeModule,
        angulartics2Segment: Angulartics2Segment
    ) {
        angulartics2Segment.startTracking();
    }
    ngDoBootstrap() {
        console.group('BootstrapModule.ngDoBootstrap()');
        console.debug('bootstrap start for AngularJS');

        // combine the already started AngularJS part with the Angular part.
        this.upgradeModule.bootstrap(document.body, ['insightui'], {
            strictDi: false
        });
        console.debug('bootstrap finished for AngularJS');
        console.groupEnd();
    }
}
