import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RevisionService } from 'insightui.metadata/services/revision.service';
import { tap, take } from 'rxjs/operators';

@Component({
    selector: 'gfk-collection-insight-navigation-closer',
    templateUrl: './collectionInsightNavigationCloser.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionInsightNavigationCloserComponent implements OnInit {
    collectionId: string;

    constructor(private revisionService: RevisionService, private router: Router) {}

    ngOnInit() {
        this.collectionId = this.router.routerState.snapshot.root.queryParams.collectionId;
    }

    onGotoParentCollection() {
        this.revisionService
            .getIndexOfCurrentPeriod$()
            .pipe(
                take(1),
                tap(period => {
                    this.router.navigate(
                        ['/collectionsManager/collection', this.collectionId],
                        {
                            queryParams: {
                                period,
                                collectionId: undefined
                            },
                            queryParamsHandling: 'merge'
                        }
                    );
                })
            )
            .subscribe();
    }
}
