import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter
} from '@angular/core';

@Component({
    selector: 'gfk-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxComponent {
    @Input() placeholder: 'Search';
    @Output() searchTextChanged = new EventEmitter<string>();

    searchText: string;

    constructor() {}
}
