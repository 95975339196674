import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from 'insightui.core/components/landing-page/landing-page.component';
import { DashboardComponent } from 'insightui.dashboard/dashboard.component';
import { ReportWithFilterViewComponent } from 'insightui.report-filter/report-with-filter-view.component';
import { PageNotFoundViewComponent } from 'insightui.page-not-found/page-not-found-view.component';
import { GeneralPageResolver } from '../resolvers/general-page.resolver';
import { LandingPageResolver } from '../resolvers/landing-page.resolver';
import { AngularInjectorResolver } from '../resolvers/angular-injector.resolver';
import { NewLandingPageComponent } from 'insightui.core/components/new-landing-page/new-landing-page.component';

/**
 * Main Routing Module for the InsightUI Angular (2+) application routes.
 *
 * Routes defined here will be explcitly handled by the UrlHandlingStrategy, so the angular 1 router
 * does not interfere with them. Please note that you might have to add routes to the web.xml configuration
 * in order to successfully deploy new routes .
 */
export const INSIGHT_TOP_LEVEL_ROUTES: Routes = [
    {
        path: 'favoritesManager',
        loadChildren: () =>
            import('insightui.favouritemanager/favourite-manager.module').then(
                m => m.FavouriteManagerModule
            ),
        data: {
            title: 'Favorites Manager'
        },
        resolve: {
            ng1Injector: AngularInjectorResolver,
            pageResolver: LandingPageResolver
        }
    },
    {
        path: 'collectionsManager',
        loadChildren: () =>
            import('insightui.collectionmanager/collection-manager.module').then(
                m => m.CollectionManagerModule
            ),
        data: {
            title: 'Collections Manager'
        },
        resolve: {
            ng1Injector: AngularInjectorResolver,
            pageResolver: LandingPageResolver
        }
    },
    {
        path: 'favorite/:reportSnapshotId',
        component: ReportWithFilterViewComponent,
        resolve: {
            ng1Injector: AngularInjectorResolver,
            pageResolver: GeneralPageResolver
        }
    },
    {
        path: 'export/favorite/:reportSnapshotId',
        component: ReportWithFilterViewComponent,
        resolve: {
            ng1Injector: AngularInjectorResolver,
            pageResolver: GeneralPageResolver
        }
    },
    {
        path: 'report/:reportId',
        component: ReportWithFilterViewComponent,
        resolve: {
            ng1Injector: AngularInjectorResolver,
            pageResolver: GeneralPageResolver
        }
    },
    {
        path: '',
        component: NewLandingPageComponent,
        resolve: {
            pageResolver: LandingPageResolver
        },
        data: {
            title: 'GfK Retail Scout'
        }
    },
    {
        path: 'old',
        component: LandingPageComponent,
        resolve: {
            pageResolver: LandingPageResolver
        },
        data: {
            title: 'GfK Retail Scout'
        }
    },
    {
        path: 'statusDashboard',
        component: DashboardComponent
    },
    {
        path: 'help',
        loadChildren: () => import('insightui.help/help.module').then(m => m.HelpModule),
        data: {
            title: 'Help Page'
        },
        resolve: {
            // this loads client ids to enable the client switch
            pageResolver: GeneralPageResolver
        }
    },
    {
        path: 'no-data-for-user',
        loadChildren: () =>
            import('insightui.no-data-for-user/no-data-for-user.module').then(
                m => m.NoDataForUserModule
            ),
        data: {
            title: 'No data for user'
        },
        resolve: {
            // this loads client ids to enable the client switch
            pageResolver: GeneralPageResolver
        }
    },
    {
        path: 'home',
        redirectTo: ''
    },
    {
        path: '**',
        component: PageNotFoundViewComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(INSIGHT_TOP_LEVEL_ROUTES, {
            enableTracing: false,
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule]
})
export class InsightUiRoutingModule { }
