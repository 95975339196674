import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsModule } from '@ngxs/store';
import { ClientSwitchButtonComponent } from './client-switch-button/client-switch-button.component';
import { ClientSwitchDialogComponent } from './client-switch-dialog/client-switch-dialog.component';
import { ModalDialogModule } from 'insightui.common-components/modal-dialog/modal-dialog.module';
import { ClientSwitchState } from './state/client-switch.state';
import { ClientInfoComponent } from './client-info/client-info.component';
import { ClientSwitchHandler } from './state/client-switch.handler';
import { SearchBoxModule } from 'insightui.common-components/search-box/search-box.module';
import { Angulartics2Module } from 'angulartics2';

@NgModule({
    declarations: [
        ClientSwitchDialogComponent,
        ClientSwitchButtonComponent,
        ClientInfoComponent
    ],
    imports: [
        Angulartics2Module,
        CommonModule,
        SearchBoxModule,
        ModalDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        NgxsModule.forFeature([ClientSwitchState])
    ],
    exports: [ClientSwitchButtonComponent, ClientInfoComponent],
    providers: [ClientSwitchHandler]
})
export class ClientSwitchModule {
    constructor(clientSwitchHandler: ClientSwitchHandler) {}
}
