import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    ViewEncapsulation,
    OnDestroy
} from '@angular/core';
import { ColumnUtilService } from '../shared/services/column-util.service';
import {
    Column,
    ColumnFreezeMode,
    ColumnHeader,
    ColumnPane,
    ColumnPaneWidths,
    ColumnType
} from '../shared/models/datatable.models';
import { HeaderDragService } from '../shared/services/header-drag.service';
import { HeaderUtilService } from '../shared/services/header-util.service';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { Observable } from 'rxjs';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { VisualizationContextBinding } from '../shared/decorators/visualization-context.decorator';
import { DragulaService } from 'ng2-dragula';
import {
    NotificationNamespace,
    NotificationType
} from 'insightui.table/ng1services/notifications/notification.model';
import NotificationStateChangesService from 'insightui.table/services/notification-state-changes.service';
import { DragulaConfiguration } from 'insightui.table/components/datatable/shared/services/header-drag-configuration.service';
import { ReportContextNotificationService } from '../../../ng1services/report-context-notification.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datatable-header',
    templateUrl: 'header.component.html',
    encapsulation: ViewEncapsulation.None
})
export class DatatableHeaderComponent
    implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @NotificationSubscription(NotificationNamespace.AutosizeAll)
    onAutosizeAllUpdate$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationNamespace.HeaderReorder)
    onHeaderReorderUpdate$: Observable<NotificationEvent>;

    columnsByPane: ColumnPane[];
    columnPaneWidths: ColumnPaneWidths;

    headerHeight: number;
    headersDiffer;

    @Input() pane: ColumnPane;
    @Input() columns: Column[];
    @Input() headerRowHeight: number;
    @Input() headers: ColumnHeader[];
    @Input() innerWidth: number;
    @Input() offsetX: number;
    @Input() autoScrollOnViewport: string;
    @Input() enabledRowHeaderReorder: boolean;
    @Output() headerReordered = new EventEmitter();
    @Output() columnResized = new EventEmitter();
    @Output() columnResizeStart = new EventEmitter();
    @Output() columnResizeEnd = new EventEmitter();
    @Output() autoResized = new EventEmitter();
    @Output() headerChanged: EventEmitter<number> = new EventEmitter();

    /**
     * This state indicator is used as an input for children cells that update via ON_PUSH
     */
    state = 0;

    /**
     * number: 0 ... parent
     *         1 ... child
     */
    columnHeaderByPane: ColumnHeader[] = [];

    get headerByPane(): ColumnHeader[] {
        if (this.columnHeaderByPane.length === 0) {
            this.headers.forEach(header => {
                if (
                    (this.pane.pos === ColumnFreezeMode.SIDE &&
                        header.type === ColumnType.SIDE_HEADER) ||
                    (this.pane.pos === ColumnFreezeMode.MAIN &&
                        header.type === ColumnType.TOP_HEADER)
                ) {
                    this.columnHeaderByPane.push(header);
                }
            });
        }
        return this.columnHeaderByPane;
    }

    @HostBinding('class.datatable-header')
    readonly hostClass: boolean = true;

    @HostBinding('style.width.px')
    get headerWidth(): number {
        return this.innerWidth;
    }

    @HostBinding('attr.id')
    get headerId(): string {
        return this.id;
    }

    @VisualizationContextBinding('filterSettings.headerReorderSetting.headers')
    headerReorderSetting;

    @VisualizationContextBinding('parameterSettings.colOrderHeaders')
    colOrderHeaders;

    @VisualizationContextBinding('parameterSettings.colOrderDim')
    colOrderDim;

    private id: string;
    private logger: ILogger;
    constructor(
        public elm: ElementRef,
        private columnUtilService: ColumnUtilService,
        private headerDragService: HeaderDragService,
        private headerUtilService: HeaderUtilService,
        private dragulaService: DragulaService,
        private datatableConfigurationService: DatatableConfigurationService,
        private reportContextNotificationService: ReportContextNotificationService,
        logService: LogService
    ) {
        this.logger = logService.getLogger('dtHeaderComponent');
        if (!this.dragulaService.find('header-drag-container-main')) {
            this.headerDragService.setDragulaServiceOptions(
                'header-drag-container-side',
                DragulaConfiguration.CUSTOM,
                {
                    moves: () => false,
                    accepts: () => false
                }
            );
            this.headerDragService.setDragulaServiceOptions(
                'header-drag-container-main',
                DragulaConfiguration.DEFAULT
            );
            this.headerDragService.setDragulaServiceOptions(
                'header-children-drag-container',
                DragulaConfiguration.CHILDREN
            );
        }
    }

    ngOnInit() {
        this.headersDiffer = this.headerUtilService.createHeadersDiffer(
            this.headerByPane
        );

        this.headerDragService.onDropModelChange(
            this.onHeaderDropModelChanged.bind(this)
        );

        this.onAutosizeAllUpdate$.subscribe(() => {
            this.logger.debug(`onAutosizeAllUpdate$ emitted`);
            this.onAutosizeAllColumns();
        });

        this.onHeaderReorderUpdate$.subscribe(() => {
            this.logger.debug(`onHeaderReorderUpdate$ emitted`);
            this.onHeaderOrderChanged();
        });

        NotificationStateChangesService.register(
            NotificationType.ResizeColumns,
            this.constructor.name,
            this.onResizeColumns.bind(this)
        );
    }

    ngAfterViewInit() {
        if (this.autoScrollOnViewport) {
            this.headerDragService.enableAutoScrollOn(this.autoScrollOnViewport, [
                'header-drag-container-main',
                'header-children-drag-container'
            ]);
        }
    }

    ngOnChanges(changes: { [property: string]: SimpleChange }) {
        if (changes.hasOwnProperty('columns')) {
            this.columnsByPane = this.columnUtilService.columnPaneList(this.columns);
            this.columnPaneWidths = this.columnUtilService.columnPaneWidths(this.columns);
        }

        if (changes.hasOwnProperty('headers')) {
            const maxLevel = this.columnUtilService.getDepth(this.headers);
            this.headerHeight = maxLevel * this.headerRowHeight;

            this.headersDiffer = this.headerUtilService.createHeadersDiffer(this.headers);
            this.headerUtilService.extendIsFixedHeaderAttributes(this.headers);
            this.headerReordered.emit({
                newHeaders: this.headers,
                columnHeaderByPane: this.columnHeaderByPane,
                overwriteVC: false,
                pane: this.pane
            });
            this.headerChanged.emit(this.headerHeight);
            this.logger.debug(`noOnChanges forced cell update`, changes);
        }

        if (changes.hasOwnProperty('pane')) {
            this.id = 'header-' + this.pane.pos;
        }
    }

    ngOnDestroy(): void {
        NotificationStateChangesService.deregister(
            NotificationType.ResizeColumns,
            this.constructor.name
        );
    }

    trackByColumnHeaderFn(idx: number, columnHeader: ColumnHeader) {
        return columnHeader.uuid;
    }

    onHeaderOrderChanged() {
        const sortedHeaderDims = this.headerReorderSetting.map(f => f.dim);
        if (!this.enabledRowHeaderReorder) {
            this.logger.debug(
                `onHeaderOrderChanged, but ignored because enabledRowHeaderReorder is false`
            );
            return;
        }

        const userDefinedDimensions = [];
        sortedHeaderDims.forEach((dim, index) => {
            userDefinedDimensions[index] = this.headerUtilService.getHeadersByDimension(
                this.headers,
                dim
            );
        });

        const config = this.datatableConfigurationService.getTableConfiguration();
        this.headers = this.datatableConfigurationService.getColumnHeaderConfiguration(
            config,
            sortedHeaderDims,
            userDefinedDimensions
        );
        this.headerUtilService.extendIsFixedHeaderAttributes(this.headers);

        const headerOrder = this.headerReorderSetting.map(f => f.dim);
        this.colOrderHeaders = headerOrder.join(',');
        this.colOrderDim = headerOrder[0];
        // Reset columnHeaderByPane with new orders
        this.columnHeaderByPane = [];
        this.headerReordered.emit({
            newHeaders: this.headers,
            columnHeaderByPane: this.headerByPane,
            overwriteVC: true,
            pane: this.pane
        });
        this.logger.debug(`onHeaderOrderChanged`);
    }

    onAutosizeAllColumns() {
        // resize columns (excluding headers)
        this.autoResized.emit();

        // resize headers according to columns
        this.onResizeColumns(NotificationType.ResizeColumns, {});

        // force change detection on header cells
        this.forceCellUpdate();
    }

    /**
     * Resize events called from child header elements
     */
    onResizeColumns(notificationType: NotificationType, eventPayload: any) {
        if (notificationType === NotificationType.ResizeColumns) {
            if (eventPayload.type === 'resizing') {
                this.onResize(eventPayload, eventPayload.header, 1);
            } else if (eventPayload.type === 'resizeStart') {
                this.onResizeStart(eventPayload, eventPayload.header, 1);
            } else if (eventPayload.type === 'resizeEnd') {
                this.onResizeEnd(eventPayload);
            } else {
                this.onResizeEnd(eventPayload);
            }
        }
    }

    /**
     * Resize event called from header top header cells
     */
    onResize(event, headerCell, level) {
        this.doResizeHeader(event, headerCell);
        this.logger.debug(`onResize`, { event, headerCell, level });

        // disable drake while resizing
        const drakeParentMain = this.dragulaService.find('header-drag-container-main');
        if (drakeParentMain) {
            drakeParentMain.drake.cancel();
        }

        const drakeParentSide = this.dragulaService.find('header-drag-container-side');
        if (drakeParentSide) {
            drakeParentSide.drake.cancel();
        }
    }

    onResizeStart(event, headerCell, level) {
        this.doResizeHeaderHeaderSideToMinWidth();
        this.columnResizeStart.emit(event);
    }

    onResizeEnd(event) {
        this.columnPaneWidths = this.headerUtilService.headerPaneWidths(this.headers);
        this.columnResizeEnd.emit(event);
    }

    /**
     * Resizes header cell according to resize event
     */
    private doResizeHeader(event, headerCell) {
        const newWidth = event.rectangle.width;
        if (newWidth === headerCell.width) {
            return;
        }

        // get min width
        const minWidth = this.headerUtilService.headerMinWidth(headerCell, this.columns);
        headerCell.width = minWidth < event.rectangle.width ? newWidth : minWidth;

        // resize headers
        this.headerUtilService.resizeHeaderCells(this.headers, headerCell);
        this.columnPaneWidths = this.headerUtilService.headerPaneWidths(this.headers);
    }

    /**
     * Resizes header cell with id "header-side" to minWidth 220px of headerCell so if the resizing is currently happening you
     * can also see if the side header column is getting smaller. If this won´t be done, you can just see if the header column
     * would be expanded.
     */
    private doResizeHeaderHeaderSideToMinWidth() {
        const resizeElement = this.elm.nativeElement.parentElement.querySelector(
            '#header-side'
        );
        if (resizeElement) {
            resizeElement.style.width = '1px';
        }
    }

    private onHeaderDropModelChanged(value) {
        this.logger.debug(`onHeaderDropModelChanged`, value);

        const container = value[0];
        let changes =
            container === 'header-drag-container-main' &&
            this.headersDiffer.level0.diff(this.getColumnHeaderNames());

        if (container === 'header-children-drag-container') {
            const sortedChildren = [];
            let level: number;
            for (const child of value[1].parentNode.children) {
                sortedChildren.push(
                    child.children[0]
                        .querySelector('.datatable-header-label')
                        .innerText.replace(/(\n|\s+)$/g, '')
                );

                if (!Number.isFinite(level)) {
                    level = this.headerUtilService.getHeaderLevelByElement(child);
                }
            }
            if (
                this.headersDiffer['level' + level] &&
                this.headersDiffer['level' + level].diff(sortedChildren)
            ) {
                this.headerUtilService.reorderChildren(
                    this.columnHeaderByPane,
                    sortedChildren,
                    level
                );
                changes = true;
            }
        }

        if (changes) {
            this.reportContextNotificationService.notify();
            this.headerReordered.emit({
                newHeaders: this.headers,
                columnHeaderByPane: this.columnHeaderByPane,
                overwriteVC: true,
                pane: this.pane
            });
        }
    }

    private getColumnHeaderNames() {
        const headerNames: string[] = [];
        this.columnHeaderByPane.forEach(header => {
            headerNames.push(header.name);
        });

        return headerNames;
    }

    private forceCellUpdate() {
        this.logger.debug(`forceCellUpdate`, { previousState: this.state });
        this.state++;
    }
}
