import { AppStateReducer } from 'projects/insightui/src/app/state/state.types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Injectable } from '@angular/core';
import { AnyAction } from 'redux';
import { PageDefinitionState } from './page-definition.types';
import {
    PAGE_INDEX_LOADED,
    PAGE_LOADED,
    KPIS_LOADED,
    LABELS_LOADED
} from './page-index.actions';

export const initState: PageDefinitionState = {
    pageIndices: [],
    kpis: [],
    pages: {},
    labels: {},
    currentPage: null
};

@Injectable()
export class PageIndexReducer implements AppStateReducer<'pageDefinition', AnyAction> {
    key: 'pageDefinition' = 'pageDefinition';
    initState = initState;

    getReducer() {
        return reducerWithInitialState(initState)
            .case(PAGE_INDEX_LOADED, (state, pageIndex) => {
                return {
                    ...state,
                    pageIndices: pageIndex.pageIndices,
                    pages: pageIndex.pages
                };
            })
            .case(PAGE_LOADED, (state, page) => {
                return {
                    ...state,
                    currentPage: page
                };
            })
            .case(LABELS_LOADED, (state, labels) => {
                return {
                    ...state,
                    labels
                };
            })
            .case(KPIS_LOADED, (state, kpis) => {
                return {
                    ...state,
                    kpis
                };
            })
            .build();
    }
}
