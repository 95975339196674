import { Inject, Injectable } from '@angular/core';
import {
    ReleaseNote,
    ReleaseNoteRaw,
    ReleaseNotes,
    ReleaseNoteSnapshot,
    ReleaseNotesRaw
} from 'insightui.announcements/announcements.types';
import {
    INSIGHT_CONFIG,
    InsightConfig,
    APP_BUILD_VERSION
} from 'insightui.global/global.module';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';

@Injectable()
export class AnnouncementsService {
    private readonly CACHE_DURATION_IN_MS = 1000 * 60 * 60 * 4; // ms * s * min * h => 4h
    private LAST_SEEN_ENDPOINT: string =
        this.insightuiConfig.services.USERPROFILE_SERVICE.url +
        '/api/v2/announcements/last-seen';

    private logger: ILogger;

    constructor(
        @Inject(INSIGHT_CONFIG) private insightuiConfig: InsightConfig,
        @Inject(APP_BUILD_VERSION) private appBuildVersion: string,
        private httpClient: HttpClient,
        private logService: LogService
    ) {
        this.logger = this.logService.getLogger('AnnouncementsService');
    }

    getReleaseNotes(useCache = false): Observable<ReleaseNotes> {
        let cacheBuster = new Date().getTime().toString();
        if (useCache) {
            cacheBuster =
                this.appBuildVersion +
                '_' +
                Math.floor(new Date().getTime() / this.CACHE_DURATION_IN_MS);
        }
        const url =
            this.insightuiConfig.services.CONFLUENCE_RELEASE_NOTES.url +
            `?v=${encodeURIComponent(cacheBuster)}`;

        return this.httpClient.get<ReleaseNotesRaw>(url).pipe(
            map((releaseNotesRaw: ReleaseNotesRaw) => {
                return releaseNotesRaw.results
                    .slice(-this.insightuiConfig.releaseNotes.count)
                    .map((releaseNoteRaw: ReleaseNoteRaw) => {
                        return {
                            pageId: parseInt(releaseNoteRaw.id, 10),
                            version: parseInt(releaseNoteRaw.version.number, 10),
                            headline: releaseNoteRaw.title,
                            content: releaseNoteRaw.body.view.value
                        };
                    });
            })
        );
    }

    getForcedReleaseNotes(): Observable<ReleaseNotes> {
        return this.getLastSeenReleaseNoteSnapshot().pipe(
            switchMap(this.getReleaseNotesNewerThan.bind(this))
        );
    }

    saveLastSeenReleaseNote(notes: ReleaseNotes): Observable<void> {
        const newestNote = this.findNewestNote(notes);
        const snapshot: ReleaseNoteSnapshot = this.toNoteSnapshot(newestNote);
        return this.httpClient.post<void>(this.LAST_SEEN_ENDPOINT, snapshot).pipe(
            catchError(error => {
                this.logger.error(`Error while talking to backend API`, error);
                return of(void 0);
            })
        );
    }

    private getLastSeenReleaseNoteSnapshot(): Observable<ReleaseNoteSnapshot> {
        return this.httpClient.get<ReleaseNoteSnapshot>(this.LAST_SEEN_ENDPOINT);
    }

    private getReleaseNotesNewerThan(
        snapshot: ReleaseNoteSnapshot
    ): Observable<ReleaseNotes> {
        return this.getReleaseNotes(true).pipe(
            map((allNotes: ReleaseNotes) => {
                return allNotes.filter((note: ReleaseNote) =>
                    this.isSnapshotNewer(note, snapshot)
                );
            })
        );
    }

    private isSnapshotNewer(
        snapshotA: ReleaseNoteSnapshot,
        snapshotB: ReleaseNoteSnapshot
    ): boolean {
        if (snapshotA.pageId > snapshotB.pageId) {
            return true;
        }

        if (snapshotA.pageId === snapshotB.pageId) {
            return snapshotA.version > snapshotB.version;
        }

        return false;
    }

    private toNoteSnapshot(note: ReleaseNote): ReleaseNoteSnapshot {
        return {
            pageId: note.pageId,
            version: note.version
        };
    }

    private findNewestNote(notes: ReleaseNotes): ReleaseNote {
        return notes.reduce((noteA, noteB) =>
            this.isSnapshotNewer(noteA, noteB) ? noteA : noteB
        );
    }
}
