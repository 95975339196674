import { Injector } from '@angular/core';
import { DatatableHeaderCellComponent } from 'insightui.table/components/datatable/header/header-cell.component';
import { AuthoringDatatableComponent } from 'insightui.table/components/authoring-datatable.component';
import {
    ExcelExport,
    NestedHeader
} from 'insightui.table/components/datatable/shared/services/table-export.service';
import { Cell } from 'insightui.table/components/datatable/shared/services/cell-cache.service';

export interface BaseTablePluginOptions {
    kpis?: string[];
    restrict?: string[];
    columns?: { [key: string]: string };
}

export interface AdditionalColumnConfiguration extends BaseTablePluginOptions {
    additionalColumns?: string[];
}

export class FlippedHeader implements NestedHeader {
    key: string;
    label: string;
    nestedCols?: NestedHeader[];
    format?: { [key: string]: number | string };
    parent?: FlippedHeader;

    getFieldId() {
        let level: FlippedHeader = this;
        const field = [];
        while (level) {
            field.push(level.key);
            level = level.parent;
        }
        return field.join('_');
    }
}
/**
 * Cell plugins for adding the drilling features to cells.
 */
export abstract class TablePlugin {
    abstract get name();

    protected restrictionMatcher: RegExp[] = [];
    protected kpis: string[] = [];

    constructor(
        protected injector: Injector,
        protected configuration: BaseTablePluginOptions,
        protected table?: AuthoringDatatableComponent
    ) {
        this.setupMatcher();
    }

    enable(cell: DatatableHeaderCellComponent | Cell) {
        if (this.select(cell)) {
            this.doEnable(cell);
        }
    }

    disable(cell: DatatableHeaderCellComponent | Cell) {
        if (this.select(cell)) {
            this.doDisable(cell);
        }
    }

    isActive(rootNode: Element) {
        return rootNode.hasAttribute('data-' + this.name);
    }

    markAsActive(rootNode: Element) {
        rootNode.setAttribute('data-' + this.name, 'true');
    }

    markAsInactive(rootNode: Element) {
        rootNode.removeAttribute('data-' + this.name);
    }

    /**
     * Convert configuration options in Regular Expressions for matching
     * against rows/columns.
     */
    private setupMatcher() {
        const configuration = this.configuration;
        if (configuration.restrict) {
            this.restrictionMatcher = configuration.restrict.map(
                regExp => new RegExp(regExp, 'i')
            );
        }
        if (configuration.kpis) {
            this.kpis = configuration.kpis;
        }
    }

    /**
     * Check whether the given cell qualifies for the given plugin instance.
     *
     * The default operation checks whether the given kpis are valid and if any restrict rule exists.
     * Normally this is extended by child implementations to enable additional checks for columns.
     *
     * @param cell  The header or body cell to test against the plugins configuration
     */
    select(cell: DatatableHeaderCellComponent | Cell): boolean {
        let column;
        let kpi;
        if (cell instanceof DatatableHeaderCellComponent) {
            if (!cell.columnHeader) {
                return false;
            }

            kpi = cell.columnHeader.kpi;
            column = cell.columnHeader.field || kpi + '_' + cell.columnHeader.id;
        } else {
            column = cell.column.field;
        }
        if (!column) {
            return false;
        }
        return this.checkColumn(column, kpi);
    }

    private checkColumn(column, kpi?) {
        kpi = kpi || (column || '').split('_').find(part => /KPI/.test(part));

        const kpiQualifies = this.kpis.length === 0 || this.kpis.indexOf(kpi) >= 0;
        const restQualifies =
            this.restrictionMatcher.length === 0 ||
            this.restrictionMatcher.every(matcher => matcher.test(column));
        return kpiQualifies && restQualifies;
    }

    selectExportRow(cell: { value: string | number }, header: FlippedHeader): boolean {
        return this.checkColumn(header.getFieldId());
    }

    flipHeader(header: NestedHeader[], parents = []): FlippedHeader[] {
        let result: FlippedHeader[] = [];
        header.forEach(headerColumn => {
            if (headerColumn.nestedCols.length) {
                const parent = parents.concat(headerColumn);
                result = result.concat(this.flipHeader(headerColumn.nestedCols, parent));
            } else {
                result.push(
                    Object.assign(
                        new FlippedHeader(),
                        { parent: parents[0] },
                        headerColumn
                    )
                );
            }
        });
        return result;
    }

    /**
     * Execute the plugin on the given header or cell, executed after the select returned true.
     */
    protected abstract doEnable(cell: DatatableHeaderCellComponent | Cell);

    /**
     * Disable the plugin on the given header or cell, executed after the select returned true.
     */
    protected abstract doDisable(cell: DatatableHeaderCellComponent | Cell);

    /**
     * Extend an export (e.g. Excel) with the enhancements implemented in this plugin.
     */
    public abstract extendExport(exportData: ExcelExport);
}

export abstract class TableBodyPlugin extends TablePlugin {
    select(cell: DatatableHeaderCellComponent | Cell): boolean {
        if (!super.select(cell)) {
            return false;
        }
        if (cell instanceof DatatableHeaderCellComponent) {
            return false;
        }
        const column = cell.column.field;
        const cellValue = cell.row[column];

        return this.checkColumnPattern(cellValue, column);
    }

    private checkColumnPattern(cellValue, column: string) {
        if (!this.configuration.columns) {
            return true;
        }
        const columnPattern = this.configuration.columns[column];
        if (!columnPattern) {
            return false;
        }
        return cellValue && new RegExp(columnPattern).test(String(cellValue));
    }

    selectExportRow(cell: { value: string | number }, header: FlippedHeader): boolean {
        return (
            super.selectExportRow(cell, header) &&
            this.checkColumnPattern(cell.value, header.key)
        );
    }

    getRow(cell: Cell) {
        let rootNode = cell.element ? cell.element.nativeElement : null;
        while (rootNode && !rootNode.classList.contains('datatable-body-row')) {
            rootNode = rootNode.parentElement;
        }
        return rootNode;
    }
}

export abstract class TableHeaderPlugin extends TablePlugin {
    select(cell: DatatableHeaderCellComponent): boolean {
        if (!super.select(cell)) {
            return false;
        }
        if (cell.columnHeader) {
            if (!this.configuration.columns) {
                return true;
            }
            const column = cell.columnHeader.field;
            return column in this.configuration.columns;
        }
        return false;
    }
}
