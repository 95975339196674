import * as _ from 'lodash';
import { Subject } from 'rxjs';

/**
 * Base class for models, basically compatible to the old core module version.
 */
export abstract class BaseModel<T> {
    public data: T[];
    protected parent?: BaseModel<T>;

    abstract get name();

    abstract get filterNotification$(): Subject<void>;

    constructor() {
        this.parent = this;
        this.data = [];
    }

    isRoot() {
        return this.parent === this;
    }

    find(propertyName, value): BaseModel<T> {
        const result = this.clone(true);
        this.data.forEach(item => {
            if (item instanceof Object && item[propertyName] === value) {
                result.data.push(item);
            }
        });
        return result;
    }

    findOne(propertyName, value): T {
        return this.data.find(
            item => item instanceof Object && item[propertyName] === value
        );
    }

    update(propertyName, value): BaseModel<T> {
        this.data.forEach(item => {
            if (item instanceof Object && item.hasOwnProperty(propertyName)) {
                item[propertyName] = value;
            }
        });
        return this;
    }

    copy(newModel: BaseModel<T>): BaseModel<T> {
        if (newModel !== this) {
            this.data.splice(0, this.data.length);
            newModel.data.forEach(el => this.data.push(_.cloneDeep(el)));
        }
        return this;
    }

    save(): Promise<BaseModel<T>> {
        return new Promise<BaseModel<T>>((resolve, reject) => {
            this.applyChanges({ resolve, reject });
            this.onSave({ resolve, reject });
        })
            .then(model => {
                if (!this.parent) {
                    return this;
                }
                if (this.parent instanceof BaseModel) {
                    this.parent.copy(this);
                }
                this.filterNotification$.next();
                return model;
            })
            .catch(() => {
                return this;
            });
    }

    clear() {
        this.data = [];
    }

    readData(...args) {
        this.data = this.data || [];
        this.onReadData.apply(this, args);
        // TODO: OnRestore
        return this;
    }

    clone(empty: boolean = false) {
        const result = Object.create(this);
        if (empty) {
            result.data = [];
        } else {
            result.data = _.cloneDeep(this.data);
        }
        result.parent = this;
        return result;
    }

    reset() {
        // reset is not implemented, noop for sonar...
        Object.assign({}, {});
    }

    abstract onRestore(items: T[]): BaseModel<T>;

    abstract onReadData(items: T[]): BaseModel<T>;

    abstract applyChanges(promise: { resolve; reject }): BaseModel<T>;

    abstract onSave(promise: { resolve; reject }): BaseModel<T>;
}
