import { filter } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { AngularInjectorResolver } from 'insightui.bootstrap/resolvers/angular-injector.resolver';
import {
    Ng1NotificationServicesMap,
    NotificationType
} from 'insightui.table/ng1services/notifications/notification.model';
import { Subscription } from 'rxjs';

/**
 * This map is used for looking up ng1 notification names and connect them to
 * the angular notification enum. In then notification service, those names where
 * not exposed, so unfortunately they don't follow any pattern.
 */
const NAMESPACE_MAP = {
    HeaderReorderNotificationService: 'HEADER_REORDER',
    FilterNotificationService: 'FILTER_SETTINGS',
    DrillingNotificationService: 'DRILLING',
    SplitByNotificationService: 'SPLIT_BY',
    SettingsNotificationService: 'SETTINGS',
    AutosizeAllNotificationService: 'AUTOSIZE_ALL',
    ContextFilterNotificationService: 'CONTEXT_FILTER_PANEL',
    ReportContextNotificationService: 'REPORT_CONTEXT_CHANGED',
    HeaderRemovedNotificationService: 'HEADER_REMOVED',
    DistributionUnitNotificationService: 'DISTRIBUTION_UNIT_CHANGED'
};

export interface NotificationEvent {
    eventName?: string;
    data?;
}

function resolveNamespace(notification: NotificationType | string) {
    return NAMESPACE_MAP[notification];
}

function deregisterEventOnDestroy(unsubscribe, target) {
    const ngOnDestroy = target.ngOnDestroy;
    target.ngOnDestroy = function() {
        if (ngOnDestroy) {
            ngOnDestroy.apply(this);
        }
    };
}

const eventEmitter: EventEmitter<NotificationEvent> = new EventEmitter<
    NotificationEvent
>();

/**
 * When using this decorator in a component, make sure to implement OnDestroy.
 * Otherwise the lifecycle hook would not get called in AOT build.
 */
export function NotificationEmitter(notification: NotificationType | string) {
    return (target, propertyKey: string | symbol) => {
        target[propertyKey] = new EventEmitter<void>();
        const subscription: Subscription = target[propertyKey].subscribe(data => {
            eventEmitter.next({
                eventName: getEventName(notification),
                data
            });
        });

        const ngOnDestroy = target.ngOnDestroy;
        target.ngOnDestroy = function() {
            if (ngOnDestroy) {
                ngOnDestroy.apply(this);
            }
            subscription.unsubscribe();
        };
    };
}

/**
 * Notification Decorator that allows using EventEmitters for event notification.
 * When using this decorator in a component, make sure to implement OnDestroy.
 * Otherwise the lifecycle hook would not get called in AOT build.
 */
export function NotificationSubscription(
    notification: NotificationType | string
): (target: any, propertyKey: string | symbol) => void {
    const eventNames = [notification].map(getEventName);
    return (target, propertyKey: string | symbol) => {
        target[propertyKey] = eventEmitter.pipe(
            filter(event => {
                const result = !event || eventNames.indexOf(event.eventName) >= 0;
                return result;
            })
        );
        AngularInjectorResolver.getRootScope().subscribe($rootScope => {
            eventNames.forEach(eventName => {
                // Notifications are emitted in the form of namespace:event in the ng1 rootScope
                const unSubscribe = $rootScope.$on(eventName, (...args) => {
                    eventEmitter.next({
                        eventName,
                        // remove event name args(0) and empty values
                        data: args.slice(1).filter(value => value)
                    });
                });
                deregisterEventOnDestroy(unSubscribe, target);
            });
        });
    };
}

/**
 * Annotation that allows to subscribe to multiple notifications.
 * When using this decorator in a component, make sure to implement OnDestroy.
 * Otherwise the lifecycle hook would not get called in AOT build.
 */
export function MultiNotificationSubscription(
    notification: (NotificationType | string)[]
): (target: any, propertyKey: string | symbol) => void {
    const eventNames = notification.map(getEventName);

    return (target, propertyKey: string | symbol) => {
        target[propertyKey] = eventEmitter.pipe(
            filter(event => {
                const result = !event || eventNames.indexOf(event.eventName) >= 0;
                return result;
            })
        );
        AngularInjectorResolver.getRootScope().subscribe($rootScope => {
            eventNames.forEach(eventName => {
                // Notifications are emitted in the form of namespace:event in the ng1 rootScope
                const unSubscribe = $rootScope.$on(eventName, (...args) => {
                    eventEmitter.next({
                        eventName,
                        // remove event name args(0) and empty values
                        data: args.slice(1).filter(value => value)
                    });
                });
                deregisterEventOnDestroy(unSubscribe, target);
            });
        });
    };
}

function getEventName(notification: NotificationType | string) {
    const namespace = Object.keys(Ng1NotificationServicesMap).find(
        key => Ng1NotificationServicesMap[key].indexOf(notification) >= 0
    );
    let eventName = resolveNamespace(notification);
    if (namespace) {
        eventName = [resolveNamespace(namespace), notification].join(':');
    }
    return eventName || notification;
}
