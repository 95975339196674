import { Injectable, Inject } from '@angular/core';
import { VisualizationContextService } from 'insightui.data/shared/visualization-context.service';
import { MetadataService } from 'insightui.metadata/services/metadata.service';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';
import * as _ from 'lodash';

/**
 * Note: this is the Angular implementation of the AngularJS VCReaderService.
 * Still has a dependency on the AngularJS ProductModel :-(
 */
@Injectable()
export class ExportExtraInfoService {
    private productModel;

    constructor(
        private vcService: VisualizationContextService,
        private metadataService: MetadataService,
        private pds: PageDefinitionService,

        @Inject('$injector') $injector
    ) {
        this.productModel = $injector.get('ProductModel');
    }

    getBusinessQuestion(): string {
        return this.vcService.get('businessQuestion') || 'unknown';
    }

    getCountry(): string {
        return this.vcService.get('countryTitle') || 'unknown';
    }

    getProducts(): string {
        return this.productModel
            .clone()
            .map(pm => pm.title)
            .join(' > ');
    }

    getProductName(): string {
        return this.pds.getProductName();
    }

    getDistributionUnit(): string {
        const channelList: string[] = this.vcService.get('channelList');
        const channelTitles = channelList.map(
            ch => this.metadataService.getChannelById(ch).title
        );
        return channelTitles.join(', ');
    }

    getPeriod(): string {
        return this.vcService.get('periodFilterTitle') || 'unknown';
    }

    getCurrency(): string {
        const currencyCode = this.vcService.get('currency.code');
        const currencySymbol = this.vcService.get('currency.symbol');
        return `${currencyCode} (${currencySymbol})`;
    }

    getFilterBy(): string {
        return this.vcService.get('contextFilterTitle');
    }

    getOptionBar(): string {
        const options = [];
        const deliveryInfo = this.metadataService.getDeliveryInfo();

        this.vcService.get('featureFilterList').map((f: any) => {
            const feature = _.get<any, any>(deliveryInfo, [
                'dimensions',
                'feature',
                f.type
            ]);
            if (feature && _.get(feature, ['values', f.value, 'title']) !== 'Total') {
                options.push(
                    feature.title + ': ' + _.get(feature, ['values', f.value, 'title'])
                );
            }
        });

        const priceClass = this.vcService.get('priceClass');
        if (priceClass !== 'PC-1') {
            options.push(
                'Price Class: ' +
                    _.get(deliveryInfo, ['dimensions', 'priceClass', priceClass, 'title'])
            );
        }

        const posType = this.vcService.get('posTypeList');
        if (posType.length < 2) {
            const pt = _.get<any, any>(deliveryInfo, [
                'dimensions',
                'posType',
                posType[0]
            ]);
            if (pt && pt.type !== 'total') {
                options.push('POS Type: ' + pt.title);
            }
        }

        return options.join('\n');
    }

    getDrillLevelDisplayName(): string {
        const drillLevel = this.vcService.get('drillTarget.drillLevel');
        switch (drillLevel) {
            case 'productGroup':
                return 'Product Group';
            case 'itemInBrand':
                return 'Item';
            case 'category':
                return 'Category';
            case 'sector':
                return 'Sector';
            case 'brandInPg':
                return (this.vcService.get('chart.focusDimension') === 'priceClass') ? 'Price Class' : 'Brand';
            case 'brandInCat':
                return 'Brand';
            default:
                return 'Unknown';
        }
    }
}
