import { AppStateReducer } from 'projects/insightui/src/app/state/state.types';
import { ExportCollectionState } from 'insightui.export-collection/export-collection.types';
import { Reducer } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Injectable } from '@angular/core';
import {
    EXPORT_COLLECTION_CANCEL,
    EXPORT_COLLECTION_CONFIRM,
    EXPORT_COLLECTION_ERROR,
    EXPORT_COLLECTION_PPT_FILE_CREATED,
    EXPORT_COLLECTION_RETRY,
    EXPORT_COLLECTION_SHEETS_FROM_IFRAME,
    EXPORT_COLLECTION_START
} from 'insightui.export-collection/export-collection.actions';
import { ListSelection } from 'insightui.collectionmanager/models/list-selection';
import { ReportSnapshotDto } from 'insightui.openapi/userprofile';

export const INIT_STATE: ExportCollectionState = {
    presentation: 'hidden',
    collectionName: 'NichtNullString',
    totalReports: 0,
    pendingReports: 0
};

@Injectable({
    providedIn: 'root'
})
export class ExportCollectionReducer implements AppStateReducer<'exportCollection'> {
    key: 'exportCollection' = 'exportCollection';
    initState: ExportCollectionState = INIT_STATE;

    getReducer(): Reducer<ExportCollectionState> {
        return reducerWithInitialState(INIT_STATE)
            .case(
                EXPORT_COLLECTION_START,
                (
                    state,
                    selectedCollection: {
                        reports: ListSelection<ReportSnapshotDto>;
                        name: string;
                    }
                ) => ({
                    ...state,
                    presentation: 'ask-user',
                    pendingReports: selectedCollection.reports.elements.length,
                    totalReports: selectedCollection.reports.elements.length
                })
            )
            .case(EXPORT_COLLECTION_CONFIRM, state => ({
                ...state,
                presentation: 'wait-for-sheets'
            }))
            .case(EXPORT_COLLECTION_CANCEL, () => INIT_STATE)
            .case(EXPORT_COLLECTION_SHEETS_FROM_IFRAME, state => ({
                ...state,
                pendingReports: state.pendingReports - 1
            }))
            .case(EXPORT_COLLECTION_PPT_FILE_CREATED, state => ({
                ...INIT_STATE,
                presentation: 'done'
            }))
            .case(EXPORT_COLLECTION_ERROR, state => ({
                ...state,
                presentation: 'retry'
            }))
            .case(EXPORT_COLLECTION_RETRY, state => ({
                ...state,
                presentation: 'wait-for-sheets',
                pendingReports: state.totalReports
            }))
            .build();
    }
}
