import { FavouriteDto } from '../models/favourite-dto';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    FmLoadFavouritesSuccess,
    FmLoadFavourites,
    FmLoadFavouritesFailure
} from './favourite-manager.actions';
import { Injectable } from '@angular/core';

export interface FavouriteManagerStateModel {
    readonly favourites: ReadonlyArray<FavouriteDto>;
    readonly favouritesLoading: boolean;
    readonly loadingError: boolean;
}

@Injectable()
@State<FavouriteManagerStateModel>({
    name: 'favouriteManager',
    defaults: {
        favourites: [],
        favouritesLoading: false,
        loadingError: false
    }
})
export class FavouriteManagerState {
    private logger: ILogger;

    constructor(logService: LogService) {
        this.logger = logService.getLogger('FavouriteManagerState');
    }

    @Selector()
    public static loadError(state: FavouriteManagerStateModel): boolean {
        return state.loadingError;
    }

    @Action(FmLoadFavourites)
    loadFavourites(ctx: StateContext<FavouriteManagerStateModel>) {
        ctx.patchState({
            favouritesLoading: true,
            loadingError: false
        });
    }

    @Action(FmLoadFavouritesSuccess)
    loadFavouritesSuccess(
        ctx: StateContext<FavouriteManagerStateModel>,
        action: FmLoadFavouritesSuccess
    ) {
        ctx.patchState({
            favourites: action.favourites,
            favouritesLoading: false,
            loadingError: false
        });
    }

    @Action(FmLoadFavouritesFailure)
    loadFavouritesFailure(ctx: StateContext<FavouriteManagerStateModel>) {
        ctx.patchState({
            favouritesLoading: false,
            loadingError: true
        });
    }
}
