import { ChartToSheetService } from 'insightui.export/powerpoint/services/chart-to-sheet.service';
import { PowerpointExportSheet, PowerpointExportSheets } from 'insightui.export/types';

export const EXPORT_PPT_HIDE_CHART_CLASS = 'export-ppt-chart--hidden';
export const EXPORT_PPT_CHART_ELEMENT =
    'chart-row-set .chart-ctrl-chart-row:not(.total-row)';
const LEGEND_WIDTH = 230;
const NUMBER_OF_STACKED_BAR_CHARTS = 6;
const STACKED_BAR_CHART_WIDTH = 200;
const MARGIN_OF_TOTAL_ROW = 32;
const MAX_WIDTH_OF_SHEET =
    LEGEND_WIDTH +
    NUMBER_OF_STACKED_BAR_CHARTS * STACKED_BAR_CHART_WIDTH +
    MARGIN_OF_TOTAL_ROW;

export abstract class BarChartStrategy {
    private chart: HTMLElement;

    constructor(chart: HTMLElement, private chartToSheetService: ChartToSheetService) {
        this.chart = chart;
    }

    abstract getMaxItemsPerPage(): number;

    abstract getLastPages(
        lastPageIndex: number,
        remainingItems: number
    ): PowerpointExportSheets;

    abstract getEdgeCase(): number;

    public getSheets(): PowerpointExportSheets {
        let sheets: PowerpointExportSheet[] = [];
        let currentPageIndex = 1;
        let remainingItems = this.getNumberOfBarCharts();

        // as long as we're not in the edge case (< 8 remaining bar charts)
        // we will show those charts that are in range
        const maxItemsPerPage = this.getMaxItemsPerPage();
        while (remainingItems > this.getEdgeCase()) {
            sheets.push(
                this.createSheetFromRange(
                    maxItemsPerPage,
                    this.getStartIndex(currentPageIndex, maxItemsPerPage)
                )
            );
            remainingItems -= maxItemsPerPage;
            currentPageIndex++;
        }

        let lastPageIndex = this.getStartIndex(currentPageIndex, maxItemsPerPage);

        return sheets.concat(this.getLastPages(lastPageIndex, remainingItems));
    }

    protected createSheetFromRange(
        numberOfItemsPerPage: number,
        startIndex: number
    ): PowerpointExportSheet {
        this.hideAllChartElements();
        const numberOfBarCharts = this.getNumberOfBarCharts();
        const endIndex = this.getEndIndex(
            startIndex,
            numberOfItemsPerPage,
            numberOfBarCharts
        );

        for (let i = startIndex - 1; i < endIndex; i++) {
            // show the chart of the first row
            this.showChartElement(i);
            // show the charts of the second row
            this.showChartElement(i + numberOfBarCharts);
        }

        return this.chartToSheetService.getFixedWidthSheet(
            this.chart,
            MAX_WIDTH_OF_SHEET
        );
    }

    protected getNumberOfBarCharts(): number {
        // you need to divide by two
        // because the total row has the same selectors
        return this.chart.querySelectorAll(EXPORT_PPT_CHART_ELEMENT).length / 2;
    }

    protected hideAllChartElements() {
        Array.from(this.chart.querySelectorAll(EXPORT_PPT_CHART_ELEMENT)).forEach(
            chartElement => {
                chartElement.classList.add(EXPORT_PPT_HIDE_CHART_CLASS);
            }
        );
    }

    protected showChartElement(position: number) {
        Array.from(this.chart.querySelectorAll(EXPORT_PPT_CHART_ELEMENT))
            .find((chartElement, index) => index === position)
            .classList.remove(EXPORT_PPT_HIDE_CHART_CLASS);
    }

    protected getStartIndex(currentPage: number, numberOfItemsPerPage: number) {
        return currentPage * numberOfItemsPerPage - (numberOfItemsPerPage - 1);
    }

    protected getEndIndex(
        startIndex: number,
        numberOfItemsPerPage: number,
        totalItems: number
    ) {
        return Math.min(startIndex + numberOfItemsPerPage - 1, totalItems);
    }
}
