import { Injectable } from '@angular/core';
import { LogService, ILogger } from 'insightui.core/services/logging/log.service';

@Injectable({ providedIn: 'root' })
export class DimensionResolverService {
    private logger: ILogger;

    constructor(logService: LogService) {
        this.logger = logService.getLogger('DimensionResolverService');
    }

    public resolveDimensions(document, dimensions) {
        Object.keys(document).forEach(key => {
            let value = document[key];
            if (!key.startsWith('$')) {
                if (value !== null && typeof value === 'object') {
                    this.resolveDimensions(value, dimensions);
                }
                return;
            }

            if (Array.isArray(value)) {
                const resolved = value.map(v =>
                    this.resolveDimensionValue(v, dimensions)
                );

                // flatten array of arrays
                value = [].concat.apply([], resolved);
            } else if (typeof value === 'string') {
                const possibleValues = this.resolveDimensionValue(value, dimensions);
                value = possibleValues.length === 1 ? possibleValues[0] : possibleValues;
            } else {
                throw new Error(
                    'Objects are not allowed to be dimension references (' + key + ')'
                );
            }

            document[key.substr(1)] = value;
            delete document[key];
        });

        return document;
    }

    private resolveDimensionValue(value: string, dimensions): string[] {
        const dimensionIdentifier = value.split(':', 2);
        const dimensionType = dimensionIdentifier[0];
        const dimensionValueType = dimensionIdentifier[1];

        if (!dimensions.hasOwnProperty(dimensionType)) {
            throw new Error('Unknown dimension ' + dimensionType + ' referenced');
        }

        const dimensionsForType = dimensions[dimensionType];

        return Object.keys(dimensionsForType)
            .map(key => {
                return {
                    id: key,
                    type: dimensionsForType[key].type
                };
            })
            .filter(
                dimension =>
                    dimensionIdentifier.length < 2 ||
                    dimension.type.toUpperCase() === dimensionValueType.toUpperCase()
            )
            .map(dimension => dimension.id);
    }
}
