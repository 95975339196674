import { Injectable } from '@angular/core';
import { DrillLevelSettingService } from 'insightui.table/ng1services/drilling-level-setting.service';
import { DrillingService } from 'insightui.data/shared/drilling.service';
import { VisualizationContextBinding } from 'insightui.table/components/datatable/shared/decorators/visualization-context.decorator';

@Injectable()
export class SplitByStateRestoreService {
    @VisualizationContextBinding('featureFilterList')
    private featureFilterList;
    @VisualizationContextBinding('drillTarget.drillLevel')
    private drillLevel;
    @VisualizationContextBinding('priceClass')
    private priceClass;
    @VisualizationContextBinding('showFeatures')
    private showFeatures;
    @VisualizationContextBinding('showPriceClasses')
    private showPriceClasses;
    @VisualizationContextBinding('splitByFeatureList')
    private splitByFeatureList;
    @VisualizationContextBinding('featureShowList')
    private featureShowList;
    @VisualizationContextBinding('showFirstActivities')
    private showFirstActivities;
    @VisualizationContextBinding('splitByPriceClassList')
    private splitByPriceClassList;
    @VisualizationContextBinding('drillTarget.dimensionId')
    private dimensionId;

    constructor(
        private drillLevelSettingService: DrillLevelSettingService,
        private drillingService: DrillingService
    ) {}

    restore() {
        this.drillLevelSettingService.removeSplitByElement(
            this.drillingService.getDrillLevel(),
            this.featureFilterList,
            this.drillLevel,
            this.priceClass
        );

        this.showFeatures = this.getRestoreValue('splitByFeatures').length > 0;

        this.showPriceClasses = this.getRestoreValue('splitByPriceClasses').length > 0;

        const splitByFeatureList = this.getRestoreValue('splitByFeatures_');
        this.splitByFeatureList = splitByFeatureList.map(elem =>
            elem.replace('splitByFeatures_', '')
        );

        const featureShowList = this.getRestoreValue('showFeatures_');
        // Show features based on selected dimension only
        const pattern = new RegExp(this.dimensionId + '$');
        this.featureShowList = featureShowList
            .filter(feature => pattern.test(feature))
            .map(elem => elem.replace('showFeatures_', ''));

        this.showFirstActivities = this.getRestoreValue('showFirstActivities').length > 0;
        this.splitByPriceClassList = this.showPriceClasses ? [this.dimensionId] : [];
    }

    private getRestoreValue(key) {
        return this.drillLevelSettingService.getSplitByKey(this.drillLevel, key);
    }
}
