import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { ScrollerComponent } from './scroller.component';
import {
    Column,
    ColumnFreezeMode,
    ColumnHeader,
    ColumnPane,
    DataRow,
    ScrollPos
} from '../shared/models/datatable.models';
import { IDictionary } from '../shared/models/generic.models';
import {
    NotificationEvent,
    NotificationSubscription
} from 'insightui.table/components/datatable/shared/decorators/notification-subscription.decorator';
import { NotificationType } from 'insightui.table/ng1services/notifications/notification.model';
import { Observable, Subscription } from 'rxjs';
import { DatatableScrollerSyncService } from 'insightui.table/components/datatable/shared/services/datatable-scroller-sync.service';

const MAX_VISIBLE_ROWS = 60;

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'datatable-body',
    templateUrl: './body.component.html',
    styleUrls: ['./body.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DatatableBodyComponent implements OnChanges, OnInit, OnDestroy {
    @Input() readonly pane: ColumnPane;
    @Input() readonly rows: DataRow[];
    @Input() readonly totalRow: DataRow;
    @Input() readonly subTotalRow: DataRow;
    @Input() readonly columns: Column[];
    @Input() readonly rowHeight: number = 35;
    @Input() readonly headersOrder: IDictionary<number>;
    @Input() readonly headers: ColumnHeader[];
    @Input() readonly bodyHeight: number;
    @Input() readonly bodyHeightDataBody: number;
    @Input() readonly loadingIndicator: boolean;
    @Input() readonly innerWidth: number;
    @Input() readonly totalWidth: number;

    @Output() bodyScroll: EventEmitter<ScrollPos> = new EventEmitter();

    @HostBinding('class.datatable-body')
    readonly hostClass: boolean = true;

    @HostBinding('class.requiresScrolling')
    requiresScrolling = false;

    @HostBinding('class.main')
    main = false;

    @HostBinding('style.width.px')
    get width(): number {
        return this.bodyWidth;
    }

    @ViewChild(ScrollerComponent, { static: true })
    scroller: ScrollerComponent;

    offsetX = 0;

    visibleRows: DataRow[];

    visibilityState = {
        topFiller: 0,
        bottomFiller: 0,
        skipRows: 0,
        rows: 0,
        leftFiller: 0,
        rightFiller: 0,
        columnsPerRow: 0,
        skipColumns: 0
    };

    private element: HTMLElement;
    private bodyWidth: number;
    private subscriptions: Subscription[] = [];

    @NotificationSubscription(NotificationType.LayoutUpdate)
    private onLayoutChanged$: Observable<NotificationEvent>;

    @NotificationSubscription(NotificationType.SortingChanged)
    private onSortingChanged$: Observable<NotificationEvent>;

    constructor(
        elementRef: ElementRef,
        private scrollerService: DatatableScrollerSyncService
    ) {
        this.element = elementRef.nativeElement;
    }

    @HostListener('window:resize')
    onWindowResize() {
        this.resetView(true);
    }

    @HostListener('window:focus')
    onFocus() {
        this.resetView(true);
    }

    ngOnChanges(changes: { [property: string]: SimpleChange }) {
        const redrawProperties: (keyof DatatableBodyComponent)[] = [
            'rows',
            'totalRow',
            'bodyHeight',
            'innerWidth'
        ];

        if (changes.hasOwnProperty('pane')) {
            if (this.pane.pos === ColumnFreezeMode.MAIN) {
                this.main = true;
            }
        }

        if (changes.hasOwnProperty('innerWidth')) {
            this.recalcBodyWidth();
            this.offsetX = 0;
            this.element.scrollLeft = 0;
            // require to recalcBodyWidth after offsetX change to populate right bodyWidth
            this.recalcBodyWidth();
        }
        if (redrawProperties.some(property => changes.hasOwnProperty(property))) {
            this.setOffsetX(this.offsetX);
        }
    }

    ngOnInit(): void {
        this.updateVisibilityState();

        this.subscriptions.push(
            this.onLayoutChanged$.subscribe(() => this.resetView(false))
        );
        this.subscriptions.push(
            this.onSortingChanged$.subscribe(() => this.resetView(true))
        );
        this.subscriptions.push(
            this.scrollerService.scope('table-' + this.pane.pos).subscribe(ev => {
                this.scroller.offsetY = (ev.currentTarget as Element).scrollTop;
                this.updateVisibilityState();
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    @HostListener('scroll', ['$event'])
    onScroll(e) {
        const scrollXPos = e.currentTarget.scrollLeft;
        this.setOffsetX(scrollXPos);
    }

    onYScroll() {
        this.updateVisibilityState();
    }

    rowTrackByFn(index: number, row: DataRow) {
        return row.$id;
    }

    public recalcBodyWidth(): void {
        if (this.pane.pos === ColumnFreezeMode.MAIN) {
            const datatableWrapper = document.getElementById('datatableWrapper');
            const { width, left } = datatableWrapper.getBoundingClientRect();
            const mainBodyLeft = this.element.getBoundingClientRect().left;
            this.bodyWidth = width - mainBodyLeft + left + 3;
            setTimeout(() => {
                this.bodyWidth =
                    width - this.element.getBoundingClientRect().left + left + 3;
            });
        } else {
            this.bodyWidth = this.innerWidth;
        }
    }

    private setOffsetX(offsetX: number) {
        this.offsetX = offsetX;

        if (this.element.scrollLeft !== offsetX) {
            this.element.scrollLeft = offsetX;
        }

        this.bodyScroll.emit({
            offsetX,
            pane: this.pane
        });

        if (this.scroller) {
            this.scroller.setOffset(this.offsetX);
        }

        this.updateVisibilityState();
    }

    private resetView(noReset: boolean = false) {
        if (!noReset) {
            this.setOffsetX(0);
        } else {
            const resetState = {
                offsetX: this.offsetX,
                scrollLeft: this.element.scrollLeft
            };
            this.offsetX = this.offsetX - 1;
            // @todo: better solution needed  to update grid on layout change
            this.element.scrollLeft = this.element.scrollLeft - 1;
            this.updateVisibilityState();
            this.element.scrollLeft = resetState.scrollLeft;
            this.updateVisibilityState();
            this.offsetX = resetState.offsetX;
            this.updateVisibilityState();
        }
    }

    private updateVisibilityState() {
        this.syncHeaderAndColumns();
        this.resetVisibiliyState();
        this.updateRowVisibility();
        this.updateColumnVisiblity();
    }

    private updateColumnVisiblity() {
        const maxWidth = this.innerWidth;
        let offsetX = this.scroller.offsetX;
        let currentPosition = 0;
        let maxReached = false;

        this.columns.forEach(column => {
            if (offsetX > 0) {
                offsetX -= column.width;
                if (offsetX > 0) {
                    this.visibilityState.leftFiller += column.width;
                    this.visibilityState.skipColumns++;
                    return;
                }
            }

            if (!maxReached) {
                this.visibilityState.columnsPerRow++;
                currentPosition += column.width;
                if (currentPosition >= maxWidth) {
                    maxReached = true;
                }
            } else {
                this.visibilityState.rightFiller += column.width;
            }
        });
    }

    private resetVisibiliyState() {
        this.visibilityState = {
            topFiller: 0,
            bottomFiller: 0,
            rows: 0,
            skipRows: 0,
            leftFiller: 0,
            rightFiller: 0,
            columnsPerRow: 0,
            skipColumns: 0
        };
    }

    private updateRowVisibility() {
        const maxHeight = this.bodyHeight;
        this.requiresScrolling = false;

        if (this.rows.length) {
            const width = this.columns
                .filter(column => column.freezeMode === 'main')
                .reduce((result, col) => result + (col.width || 0), 0);

            this.requiresScrolling =
                this.rows.length * this.rowHeight > maxHeight && width > this.bodyWidth;
        }

        if (this.rows.length <= MAX_VISIBLE_ROWS) {
            this.visibleRows = this.rows;
            return;
        }
        const offsetY = this.scroller.offsetY;
        let remainingRows = this.rows.length;

        this.visibilityState.skipRows = Math.min(
            Math.floor(offsetY / this.rowHeight),
            remainingRows
        );
        remainingRows -= this.visibilityState.skipRows;

        this.visibilityState.rows = Math.min(
            Math.ceil(maxHeight / this.rowHeight),
            remainingRows
        );
        remainingRows -= this.visibilityState.rows;

        this.visibilityState.topFiller = this.visibilityState.skipRows * this.rowHeight;
        this.visibilityState.bottomFiller = remainingRows * this.rowHeight;

        this.visibleRows = this.rows.slice(
            this.visibilityState.skipRows,
            this.visibilityState.skipRows + this.visibilityState.rows
        );
    }

    /**
     * Utility function to always make sure the header and column widths are synced.
     *
     * Actually we should not need this, but right now it's safer to have it than getting into
     * shifted row scenarios
     */
    private syncHeaderAndColumns() {
        this.flattenHeader(this.headers)
            .map(header => [
                header,
                this.columns.find(column => column.field === header.field)
            ])
            .filter(headerColumnTuple => headerColumnTuple[1] !== undefined)
            .forEach(headerColumnTuple => {
                headerColumnTuple[0].width = headerColumnTuple[1].width;
            });
    }

    private flattenHeader(parent: ColumnHeader[] = this.headers) {
        let headerList = [];
        parent.forEach(header => {
            if (!header.children || header.children.length === 0) {
                headerList.push(header);
            } else {
                headerList = headerList.concat(this.flattenHeader(header.children));
            }
        });
        return headerList;
    }
}
