import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { ErrorService } from 'insightui.error/error.service';
import { Injectable, Injector } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX } from 'insightui.error/error.constants';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private errorService: ErrorService, private injector: Injector) {}

    intercept<R>(req: HttpRequest<R>, next: HttpHandler): Observable<HttpEvent<R>> {
        const isExcludedFromErrorInterceptorRegex: RegExp[] = this.injector.get(
            EXCLUDE_FROM_ERROR_INTERCEPTOR_REGEX
        );
        return next.handle(req).pipe(
            tap({
                error: error => {
                    const isExcludedFromErrorInterceptor = isExcludedFromErrorInterceptorRegex.some(
                        regex => regex.test(req.url)
                    );

                    if (
                        !isExcludedFromErrorInterceptor &&
                        error instanceof HttpErrorResponse
                    ) {
                        this.errorService.reportError('reporting-http', error.error);
                    }
                }
            })
        );
    }
}
