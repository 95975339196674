<svg
    class="[ slider-container ]"
    width="250"
    height="40"
    xmlns="http://www.w3.org/2000/svg"
>
    <g class="[ slider-scale ] [ ui-test-slider-scale ]" transform="translate(4 0)">
        <text
            *ngFor="let scalePoint of labelScale"
            class="[ slider-scale__label ] [ ui-test-slider-scale-label ]"
            [attr.x]="scalePoint.pos + '%'"
        >
            {{ scalePoint.label }}
        </text>
    </g>

    <svg x="5" y="8" width="240" class="[ slider ]">
        <rect
            x="0"
            width="100%"
            y="6"
            height="8"
            class="[ slider__track ] [ ui-test-slider-track ] "
            fill="#dedede"
            #track
        />

        <rect
            *ngIf="range[0] >= 0; else negative_range"
            [attr.x]="0"
            [attr.width]="thumbOffset + '%'"
            y="6"
            height="8"
            class="[ slider__indicator ] positive"
            [attr.fill]="color"
        />
        <ng-template #negative_range>
            <rect
                [attr.x]="thumbOffset + '%'"
                [attr.width]="100 - thumbOffset + '%'"
                y="6"
                height="8"
                class="[ slider__indicator ] negative"
                [attr.fill]="color"
            />
        </ng-template>

        <rect
            *ngFor="let scalePoint of labelScale"
            class="[ slider__dash ] "
            [attr.x]="scalePoint.pos + '%'"
            width="1"
            y="6"
            height="8"
            fill="white"
        />
    </svg>

    <svg
        #thumbPosition
        class="[ ui-test-slider-position ]"
        [attr.x]="(240 * thumbOffset) / 100"
        y="8"
    >
        <g class="[ slider__thumb ]" fill="#dedede" stroke="black" stroke-width="1">
            <polygon points="0,20, 0,5, 5,0, 10,5, 10,20" />
        </g>
    </svg>
</svg>
