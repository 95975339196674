import { Injectable } from '@angular/core';
import { RevisionService } from './revision.service';
import { tap, catchError, map } from 'rxjs/operators';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import {
    DeliveryInfo,
    DeliveryInfoContainer,
    Revision,
    HierarchyInfo,
    DimensionMap
} from 'insightui.metadata/metadata.types';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class DocumentLoaderService {
    private logger: ILogger;

    constructor(private revisionService: RevisionService, logService: LogService) {
        this.logger = logService.getLogger('DocumentLoaderService');
    }

    public loadDocument(revision: Revision): Observable<DeliveryInfoContainer> {
        return this.revisionService.loadDeliveryInfo(revision.activeRevisionId).pipe(
            tap(result => this.logger.debug('received', result)),
            // tap((result: DeliveryInfo) =>
            //     this.store.dispatch(new MsLoadDeliveryInfoSuccess(result))
            // ),
            map(result => this.enrichData(result, revision)),
            this.catchErrorAndDispatchFailure()
        );
    }

    private enrichData(deliveryInfoContainer: DeliveryInfoContainer, revision: Revision) {
        let deliveryInfo = deliveryInfoContainer.deliveryInfo;
        this.logger.debug('start enriching', deliveryInfo);

        deliveryInfo = this.removeInvalidData(deliveryInfo);
        deliveryInfo = this.addDimensionInfo(deliveryInfo);
        deliveryInfo = this.addRevisionData(deliveryInfo, revision);
        deliveryInfo = this.generateHierarchyDimensions(deliveryInfo);
        // deliveryInfo = this.addPriceClassTypes(deliveryInfo);

        return { deliveryInfo };
    }

    /**
     * Removes invalid areas from the backend's data.
     * TODO: Can be removed after this is fixed.
     */
    private removeInvalidData(deliveryInfo: DeliveryInfo): DeliveryInfo {
        const mainArea = Object.keys(deliveryInfo.dimensions.area).find(
            key => deliveryInfo.dimensions.area[key].typeId === 10401
        );

        return {
            ...deliveryInfo,
            dimensions: {
                ...deliveryInfo.dimensions,
                area: { [mainArea]: deliveryInfo.dimensions.area[mainArea] }
            }
        };
    }

    /**
     * Adds dim property to each dimension value.
     */
    private addDimensionInfo(deliveryInfo: DeliveryInfo): DeliveryInfo {
        const dimensions: DimensionMap = {} as DimensionMap;

        Object.keys(deliveryInfo.dimensions).forEach(dimKey => {
            const dimensionValues = {};

            Object.keys(deliveryInfo.dimensions[dimKey]).forEach(dimValueKey => {
                const dimensionMap = deliveryInfo.dimensions[dimKey][dimValueKey];

                if (Array.isArray(dimensionMap)) {
                    dimensionValues[dimValueKey] = dimensionMap;
                } else {
                    dimensionValues[dimValueKey] = {
                        ...deliveryInfo.dimensions[dimKey][dimValueKey],
                        dim: dimKey
                    };
                }
            });
            dimensions[dimKey] = dimensionValues;
        });

        return { ...deliveryInfo, dimensions };
    }

    private addRevisionData(
        deliveryInfo: DeliveryInfo,
        revision: Revision
    ): DeliveryInfo {
        return {
            ...deliveryInfo,
            country: revision.regionIsoCode,
            revisionId: revision.activeRevisionId
        };
    }

    private extractDimensions(dimensionMap: any, dimensionType: string): HierarchyInfo[] {
        if (!dimensionMap[dimensionType]) {
            return [];
        }

        return Object.keys(dimensionMap[dimensionType]).map(key => ({
            ...dimensionMap[dimensionType][key],
            id: key
        }));
    }

    private generateHierarchyDimensions(deliveryInfo: DeliveryInfo): DeliveryInfo {
        const areas = this.extractDimensions(deliveryInfo.dimensions, 'area');
        const sectors = this.extractDimensions(deliveryInfo.dimensions, 'sector');
        const categories = this.extractDimensions(deliveryInfo.dimensions, 'category');
        const productGroups = this.extractDimensions(
            deliveryInfo.dimensions,
            'productGroup'
        );

        let allDimensions: HierarchyInfo[] = [].concat(
            areas,
            sectors,
            categories,
            productGroups
        );

        allDimensions = allDimensions.map(d =>
            this.enrichHierarchyInfo(allDimensions, d)
        );

        // put hierarchy infos into map
        const hierarchyDimensions = {};
        allDimensions.forEach(d => (hierarchyDimensions[d.id] = d));

        this.logger.debug('hierarchyDimensions', hierarchyDimensions);
        return { ...deliveryInfo, hierarchyDimensions };
    }

    private enrichHierarchyInfo(
        allDimensions: HierarchyInfo[],
        hierarchyInfo: HierarchyInfo
    ) {
        return {
            ...hierarchyInfo,
            isLoaded: hierarchyInfo.loadStatus !== 'notLoaded',
            isAvailable: this.isAvailable(allDimensions, hierarchyInfo),
            hasAvailableData: this.hasAvailableData(allDimensions, hierarchyInfo)
        };
    }

    private isAvailable(allDimensions: HierarchyInfo[], hierarchyInfo: HierarchyInfo) {
        const childNodes = allDimensions.filter(
            dimension => dimension.parentId === hierarchyInfo.id
        );
        if (childNodes.length > 0) {
            return childNodes.some(child => this.isAvailable(allDimensions, child));
        } else {
            return hierarchyInfo.loadStatus !== 'notAvailable';
        }
    }

    private hasAvailableData(
        allDimensions: HierarchyInfo[],
        hierarchyInfo: HierarchyInfo
    ) {
        const childNodes = allDimensions.filter(
            dimension => dimension.parentId === hierarchyInfo.id
        );
        if (childNodes.length > 0) {
            return childNodes.some(child => this.hasAvailableData(allDimensions, child));
        } else {
            return hierarchyInfo.loadStatus === 'loaded';
        }
    }

    private addPriceClassTypes(deliveryInfo: DeliveryInfo): DeliveryInfo {
        if (!deliveryInfo.dimensions.priceClass) {
            return deliveryInfo;
        }

        // TODO: create copy, don't mutate input

        // the old version from MetadataService using lodash
        // _.forEach(deliveryInfo.dimensions.priceClass, priceClass => {
        //     priceClass.types = _.map(
        //         _.get(priceClass, 'attributes.dimClassification', ['default']),
        //         key => _.camelCase(key)
        //     );
        // });

        // a mixed version using lodash

        Object.keys(deliveryInfo.dimensions.priceClass).forEach(key => {
            const types = _.map(
                _.get(
                    deliveryInfo.dimensions.priceClass[key],
                    'attributes.dimClassification',
                    ['default']
                ),
                dim => _.camelCase(dim)
            );

            deliveryInfo.dimensions.priceClass[key] = {
                ...deliveryInfo.dimensions.priceClass[key],
                types
            };
        });

        // TODO: this version doesn't work because attributes might be null
        // Object.keys(deliveryInfo.dimensions.priceClass).forEach(key => {
        //     let dimClassifications =
        //         deliveryInfo.dimensions.priceClass[key].attributes.dimClassification;
        //     if (!dimClassifications || dimClassifications.length === 0) {
        //         dimClassifications = ['default'];
        //     }

        //     const types = dimClassifications.map(dim => _.camelCase(dim));

        //     deliveryInfo.dimensions.priceClass[key] = {
        //         ...deliveryInfo.dimensions.priceClass[key],
        //         types
        //     };
        // });

        return deliveryInfo;
    }

    private catchErrorAndDispatchFailure = () => (source: Observable<any>) => {
        return source.pipe(
            catchError(err => {
                this.logBackendError(err);
                // this.store.dispatch(new CmCollectionUpdateFailure(err));
                return throwError(err);
            })
        );
    };

    private logBackendError(err: any) {
        this.logger.error(`Error while talking to backend API`, err);
    }
}
