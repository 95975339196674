import { IDictionary } from '../models/generic.models';
import {
    Column,
    ColumnHeader,
    ColumnPaneWidths,
    ColumnType
} from '../models/datatable.models';
import { Injectable, KeyValueDiffers } from '@angular/core';
import * as _ from 'lodash';
import { PageDefinitionService } from 'insightui.page-definition/services/page-definition.service';

@Injectable()
export class HeaderUtilService {
    constructor(
        private pageDefinitionService: PageDefinitionService,
        private differs: KeyValueDiffers
    ) {}

    getHeadersOrderBy(headers: ColumnHeader[], columnFields = []): IDictionary<number> {
        const headerFieldOrder = {};
        if (columnFields.length > 0) {
            this.appendHeaderFieldOrderTo(headers, headerFieldOrder, columnFields);
        } else {
            this.appendAllHeaderFieldOrderTo(headers, headerFieldOrder);
        }
        return headerFieldOrder;
    }

    extendHeaderAttributesWith(
        headers: ColumnHeader[],
        attributeName,
        valueAssignFunc
    ): void {
        headers = headers || [];
        headers.forEach(header => {
            header.attributes = header.attributes || {};
            header.attributes[attributeName] = valueAssignFunc(header);
            if (header.children) {
                this.extendHeaderAttributesWith(
                    header.children,
                    attributeName,
                    valueAssignFunc
                );
            }
        });
    }

    getDisabledKpis(): Array<string> {
        return this.pageDefinitionService.getCurrentPage().report.kpiDefinition.disabled;
    }

    createHeadersDiffer(headers, headersDiffer?, level = 0) {
        headersDiffer = headersDiffer || {};
        let newChildren;
        const names = [];

        headers.forEach(child => {
            if (!newChildren && child.children && child.children.length > 0) {
                newChildren = child.children;
            }
            names.push(child.name);
        });
        headersDiffer['level' + level] = this.differs.find({}).create();
        headersDiffer['level' + level].diff(names);

        if (newChildren) {
            this.createHeadersDiffer(newChildren, headersDiffer, level + 1);
        }
        return headersDiffer;
    }

    getHeaderLevelByElement(tempParent) {
        let index = 0;
        while (!tempParent.classList.contains('datatable-header')) {
            tempParent = tempParent.parentNode;
            index++;
        }
        return Math.floor((index - 2) / 2);
    }

    reorderChildren(headers, sortedChildren, level, currentLevel?) {
        currentLevel = currentLevel || 0;
        ++currentLevel;

        headers.forEach(header => {
            if (!header.children) {
                return;
            }
            if (level !== currentLevel) {
                this.reorderChildren(
                    header.children,
                    sortedChildren,
                    level,
                    currentLevel
                );
            } else {
                header.children = this.getNewHeaderChildrenBy(header, sortedChildren);
            }
        });
    }

    getHeadersByDimension(headers, dimension, result = []) {
        headers.forEach(header => {
            if (header.dimension === dimension && result.indexOf(header.id) < 0) {
                result.push(header.id);
            }
            if (header.children) {
                this.getHeadersByDimension(header.children, dimension, result);
            }
        });
        return result;
    }

    getHeaderFirstLevelOrder(headers) {
        const result = [];
        headers.forEach(header => {
            result.push(header.id || 'SH');
        });
        return result;
    }

    getHeaderOrderByDimension(headers, result = {}) {
        let rankKpis = [];
        const report = this.pageDefinitionService.getCurrentPage().report;
        if (report.rankingOptions) {
            rankKpis = report.rankingOptions.attributes.map(r => r.name);
        }

        headers.forEach(header => {
            if (!header.dimension) {
                return;
            }
            if (!_.has(result, [header.dimension, header.id])) {
                _.set(
                    result,
                    [header.dimension, header.id],
                    _.keys(result[header.dimension]).length
                );
            }

            // avoid recursion for rank KPIs
            if (header.children && rankKpis.indexOf(header.kpi) === -1) {
                this.getHeaderOrderByDimension(header.children, result);
            }
        });
        return result;
    }

    extendIsFixedHeaderAttributes(headers) {
        this.extendHeaderAttributesWith(headers, 'isFixedHeader', header => {
            return (
                header.type === ColumnType.SIDE_HEADER ||
                this.getDisabledKpis().indexOf(header.id) >= 0
            );
        });
    }

    updateHeaderWidth(headers: ColumnHeader[], column: Column[]) {
        return this.updateHeaders(headers, column, 0);
    }

    headerMinWidth(header, columns) {
        const flatHeader = [];
        if (header.children) {
            this.flattenHeader(header.children, flatHeader);
        } else {
            flatHeader.push(header);
        }

        const minWidths = flatHeader.map(localHeader => {
            const column = columns.find(col => {
                return col.field === localHeader.field;
            });
            if (column) {
                return column.minWidth;
            }
            return 0;
        });

        return minWidths.reduce((a, b) => a + b, 0);
    }

    /**
     * Resizes header cells according to headerResized's new widths
     */
    resizeHeaderCells(headers, headerResized) {
        this.resizeHeaderWidths(headers, headerResized, null);
        this.resizeFlexWidths(headers, 0);
    }

    /**
     * Calculates pane widths based on column header widths.
     */
    headerPaneWidths(headers) {
        const sideSize = headers
            .filter(h => h.type === 0)
            .map(header => header.width)
            .reduce((a, b) => a + b, 0);
        const mainSize = headers
            .filter(h => h.type === 1)
            .map(header => header.width)
            .reduce((a, b) => a + b, 0);

        const paneWidths = new ColumnPaneWidths();
        paneWidths.side = sideSize;
        paneWidths.main = mainSize;
        paneWidths.total = sideSize + mainSize;
        return paneWidths;
    }

    resizeFlexWidths(headers, level) {
        headers.map(header => {
            if (header.children) {
                const childLevel = level + 1;
                this.resizeFlexWidths(header.children, childLevel);

                const childWidths = header.children.map(child => {
                    return child.width;
                });
                const totalWidth = childWidths.reduce((a, b) => a + b, 0);
                header.flexWidth = '1 ' + level + ' ' + totalWidth + 'px';
                header.width = totalWidth;
            } else {
                header.flexWidth = '1 ' + level + ' ' + header.width + 'px';
            }
        });
    }

    reorderHeaderBy(newHeaders, columnHeaderByPane, panePos) {
        const columnHeaderByPaneMap = columnHeaderByPane.map(header => {
            return header.id || header.field;
        });
        if (columnHeaderByPaneMap.length < 0) {
            return;
        }
        columnHeaderByPaneMap.forEach(headerKey => {
            const index = newHeaders.findIndex(header => {
                return (header.id || header.field) === headerKey;
            });
            if (index >= 0) {
                newHeaders.splice(index, 1);
            }
        });

        const len = panePos === 'side' ? 0 : newHeaders.length;
        columnHeaderByPane.forEach((header, index) => {
            newHeaders.splice(index + len, 0, header);
        });
    }

    getTotalWidthByColumns(columns, freezeMode) {
        const childWidths = columns.map(child => {
            return child.freezeMode === freezeMode ? child.width : 0;
        });
        return childWidths.reduce((a, b) => a + b, 0);
    }

    private flattenHeader(headers, aggregate) {
        headers.forEach(header => {
            if (header.children) {
                this.flattenHeader(header.children, aggregate);
            }
            if (!header.children || header.children.length === 0) {
                aggregate.push(header);
            }
        });
    }

    private getNewHeaderChildrenBy(header, sortedChildren) {
        const newChildren = [];
        sortedChildren.forEach(field => {
            const foundChild = _.find(header.children, child => {
                return child.name.trim() === field.trim();
            });
            if (foundChild) {
                newChildren.push(foundChild);
            }
        });
        return newChildren.concat(_.difference(header.children || [], newChildren));
    }

    private appendAllHeaderFieldOrderTo(
        headers: ColumnHeader[],
        headerFieldOrder: IDictionary<number>
    ): void {
        headers = headers || [];
        headers.forEach(header => {
            if (header.field) {
                headerFieldOrder[header.field] = Object.keys(headerFieldOrder).length;
            }
            if (header.children) {
                this.appendAllHeaderFieldOrderTo(header.children, headerFieldOrder);
            }
        });
    }

    private appendHeaderFieldOrderTo(
        headers: ColumnHeader[],
        headerFieldOrder: IDictionary<number>,
        columnFields
    ): void {
        headers = headers || [];
        headers.forEach(header => {
            if (header.field && columnFields.indexOf(header.field) >= 0) {
                headerFieldOrder[header.field] = Object.keys(headerFieldOrder).length;
            }
            if (header.children) {
                this.appendHeaderFieldOrderTo(
                    header.children,
                    headerFieldOrder,
                    columnFields
                );
            }
        });
    }

    /**
     * Recursivly set header cell width based on column width
     */
    private updateHeaders(headers: ColumnHeader[], columns: Column[], level) {
        return headers.map(header => {
            if (header.children) {
                this.updateHeaders(header.children, columns, (level += 1));
            }

            const column = columns.find(col => {
                header.field = header.field
                    ? header.field.replace('-', '')
                    : header.field;
                return col.field === header.field;
            });

            if (!column) {
                const childWidths = (header.children || []).map(child => {
                    if (child) {
                        return child.width;
                    } else {
                        return 0;
                    }
                });
                const totalWidth = childWidths.reduce((a, b) => a + b, 0);
                header.flexWidth = '1 0 ' + totalWidth + 'px';
                header.width = totalWidth;
            } else {
                header.flexWidth = '1 ' + level + ' ' + column.width + 'px';
                header.width = column.width;
            }
        });
    }

    private resizeHeaderWidths(headers, headerResized, width) {
        headers.forEach(header => {
            let updateWidth = width;
            if (header.uuid === headerResized.uuid) {
                updateWidth = headerResized.width;
            }
            header.width = updateWidth || header.width;
            if (header.children) {
                const childWidth =
                    updateWidth > 0
                        ? Math.floor(updateWidth / header.children.length)
                        : null;
                this.resizeHeaderWidths(header.children, headerResized, childWidth);
            }
        });
    }
}
