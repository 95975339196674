import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClientSwitchDialogComponent } from '../client-switch-dialog/client-switch-dialog.component';
import { getClientId } from 'insightui.core/services/navigation-utils';
import { WINDOW } from 'insightui.global/global.module';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ClientInfo } from 'insightui.client-switch/client-switch.types';
import { ClientSwitchState } from 'insightui.client-switch/state/client-switch.state';

@Component({
    selector: 'gfk-client-switch-button',
    templateUrl: './client-switch-button.component.html',
    styleUrls: ['./client-switch-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientSwitchButtonComponent {
    private readonly MODAL_DIALOG_WIDTH = '600px';

    clients$: Observable<ClientInfo[]>;

    constructor(
        store: Store,
        private matDialog: MatDialog,

        @Inject(WINDOW) private window: Window
    ) {
        this.clients$ = store.select(ClientSwitchState.clients);
    }

    showModal() {
        // get clientId from the URL
        const currentClientId = getClientId() || '';

        const dialogRef = this.matDialog.open(ClientSwitchDialogComponent, {
            data: { clientId: currentClientId },
            width: this.MODAL_DIALOG_WIDTH,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.clientId !== currentClientId) {
                // move to landing page without keeping query parameters
                // they would not fit with the new client anyway

                this.window.location.href = result.clientId
                    ? `/?clientId=${result.clientId}`
                    : '/';
            }
        });
    }
}
