<div class="content">
    <img src="assets/images/cloud.png" alt="sad cloud" />

    <p class="title">
        {{ headline }}
    </p>
    <p>
        {{ description }}
    </p>
    <button routerLink="/" *ngIf="showHomeButton" class="button o-button">
        Go to landing page
    </button>

    <div class="table">
        <p class="table__headline">
            Additional Info
        </p>
        <div class="table__row">
            <div class="table__key">Time</div>
            <div class="table__value">{{ dateTimeString }}</div>
        </div>
        <div class="table__row">
            <div class="table__key" *ngIf="errorCategory">ErrorCategory</div>
            <div class="table__value">{{ errorCategory }}</div>
        </div>
        <ng-container *ngIf="userDetails$ | async as user">
            <div class="table__row">
                <div class="table__key">UserName</div>
                <div class="table__value">{{ user.userName }}</div>
            </div>
            <div class="table__row">
                <div class="table__key">UserId</div>
                <div class="table__value">{{ user.userId }}</div>
            </div>
            <div class="table__row">
                <div class="table__key">Email</div>
                <div class="table__value">{{ user.email }}</div>
            </div>
        </ng-container>
    </div>
</div>
