import { AppStateReducer } from 'projects/insightui/src/app/state/state.types';
import { LoadingIndicatorState } from 'insightui.loading-indicator/loading-indicator.types';
import { Reducer } from 'redux';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { Injectable } from '@angular/core';
import {
    LOADING_COMPLETED,
    LOADING_ERROR,
    LOADING_STARTED,
    LOADING_STARTED_WITH_CUSTOM_MESSAGE
} from 'insightui.loading-indicator/loading-indicator.actions';

export const INIT_STATE: LoadingIndicatorState = {
    pendingCount: 0,
    message: 'Loading Your Insights ...'
};

@Injectable({
    providedIn: 'root'
})
export class LoadingIndicatorReducer implements AppStateReducer<'loadingIndicator'> {
    key: 'loadingIndicator' = 'loadingIndicator';
    initState: LoadingIndicatorState = INIT_STATE;

    getReducer(): Reducer<LoadingIndicatorState> {
        return reducerWithInitialState(INIT_STATE)
            .case(LOADING_STARTED, state => this.handleLoadingStarted(state))
            .case(LOADING_STARTED_WITH_CUSTOM_MESSAGE, (state, message) =>
                this.handleLoadingStarted(state, message)
            )
            .case(LOADING_COMPLETED, state => ({
                ...state,
                pendingCount: Math.max(0, state.pendingCount - 1)
            }))
            .case(LOADING_ERROR, () => INIT_STATE);
    }

    private handleLoadingStarted(
        state: LoadingIndicatorState,
        message: string = 'Loading Your Insights ...'
    ) {
        return { ...state, pendingCount: state.pendingCount + 1, message };
    }
}
