enum MEDIA_TYPES {
    PPT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    JSON = 'application/json'
}
export const ACCEPTED_MEDIA_TYPES = `${MEDIA_TYPES.PPT}, ${MEDIA_TYPES.JSON}`;

export const PPT_FILE_EXTENSION = 'pptx';

export const REPORT_FILE_NAME = 'report';

export const DEFAULT_LOCALE = 'en-US';

export const DATE_FORMAT = 'yyyyMMdd';
