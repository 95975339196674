import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CollectionManagerShortcutComponent } from './collection-manager-shortcut.component';
import { GlobalModule } from 'insightui.global/global.module';

@NgModule({
    declarations: [CollectionManagerShortcutComponent],
    imports: [CommonModule, GlobalModule],
    exports: [CollectionManagerShortcutComponent]
})
export class CollectionManagerShortcutModule {}
