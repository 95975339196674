import { Pipe, PipeTransform } from '@angular/core';
/**
 * Pipe that removes spaces from strings.
 */
@Pipe({ name: 'noSpace' })
export class NoSpacePipe implements PipeTransform {
    transform(value: string): string {
        return value ? value.replace(/( |\W)/g, '') : '';
    }
}
