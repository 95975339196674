import { Inject, Injectable } from '@angular/core';
import {
    Column,
    DataRow
} from 'insightui.table/components/datatable/shared/models/datatable.models';
import { DatatableConfigurationService } from 'insightui.table/services/datatable-configuration.service';
import { DecimalPipe } from '@angular/common';
import { DOCUMENT } from 'insightui.global/global.module';
import { SortingModel, SortSetting } from 'insightui.core/models/sorting.model';
import { PerformanceIndicatorMapperService } from 'insightui.data/operator/performance-indicator-mapper.service';

@Injectable()
export class ColumnWidthUtilService {
    // width with correction factor
    private static BODY_FONT = Math.ceil(12 * 1.1) + 'px arial';
    private static BODY_PADDING = 4;

    private static COLUMN_MIN_WIDTH = 60;
    private static SPACE_FOR_SCROLLBAR = 45;
    private static SPACE_FOR_SORT_ICON = 23;
    private static SPACE_FOR_PERFORMANCE_INDICATOR = 14;

    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        private sortingModel: SortingModel,
        private dataTableConfigurationService: DatatableConfigurationService,
        private decimalPipe: DecimalPipe,
        private performanceIndicatorMapperService: PerformanceIndicatorMapperService
    ) {}

    private static sortByLength(words) {
        return words.sort((a, b) => {
            if (a === null) {
                return 1;
            } else if (b === null) {
                return -1;
            }
            if (!a || !b) {
                return 0;
            }
            return b.length - a.length;
        });
    }

    calculateColumnWidth(columnIndex: number, columns: Column[], rows: DataRow[]) {
        let columnWidth = this.calculateWidthOfLongestWordInColumn(
            rows,
            columns[columnIndex]
        );
        columnWidth += this.getSpaceForScrollbar(columnIndex, columns);
        columnWidth += this.getSpaceForSortIcon(columns[columnIndex]);
        columnWidth += this.getSpaceForPerformanceIndicator(columns[columnIndex]);

        return Math.max(columnWidth, ColumnWidthUtilService.COLUMN_MIN_WIDTH);
    }

    private calculateWidthOfLongestWordInColumn(rows: DataRow[], column) {
        const columnWords = this.getColumnWords(rows, column);
        const columnWord = ColumnWidthUtilService.sortByLength(columnWords)[0];
        return this.getWidthForWord(columnWord);
    }

    private getSpaceForScrollbar(index, columns: Column[]) {
        let spaceForScrollbar = 0;
        if (index === columns.length - 1) {
            const datatable = this.document.getElementById('content-main');
            const hasHorizontalScrollbar = datatable.scrollWidth > datatable.clientWidth;
            const hasVerticalScrollbar = datatable.scrollHeight > datatable.clientHeight;
            if (hasVerticalScrollbar && hasHorizontalScrollbar) {
                spaceForScrollbar = ColumnWidthUtilService.SPACE_FOR_SCROLLBAR;
            }
        }
        return spaceForScrollbar;
    }

    private getSpaceForPerformanceIndicator(column) {
        const hasPerformanceIndicator = this.performanceIndicatorMapperService.shouldHavePerformanceIndicator(
            column.field
        );
        // Rank indicator is some legacy plugin which manipulates the DOM directly
        const hasRankIndicator = this.performanceIndicatorMapperService.shouldHaveRankIndicator(
            column.field
        );

        return hasPerformanceIndicator || hasRankIndicator
            ? ColumnWidthUtilService.SPACE_FOR_PERFORMANCE_INDICATOR
            : 0;
    }

    private getSpaceForSortIcon(column) {
        return this.sortingModel.isTheOnlySortedColumn(column)
            ? ColumnWidthUtilService.SPACE_FOR_SORT_ICON
            : 0;
    }

    private getColumnWords(rows, col) {
        return rows.map(row => {
            const word = row[col.field];
            if (typeof word === 'string') {
                return word;
            }
            if (col.hasOwnProperty('fractionalDigits')) {
                const digitInfo = `1.${col.fractionalDigits}-${col.fractionalDigits}`;
                return this.decimalPipe.transform(word, digitInfo);
            }
            return ((Math.round(word) * 10) / 10).toString();
        });
    }

    private getWidthForWord(word) {
        const element = this.document.createElement('canvas');
        const context = element.getContext('2d');
        context.font = ColumnWidthUtilService.BODY_FONT;

        return (
            Math.ceil(context.measureText(word).width) +
            ColumnWidthUtilService.BODY_PADDING
        );
    }
}
