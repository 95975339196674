import { NgModule } from '@angular/core';
import { ReportInIFrameService } from 'insightui.export-collection/report-in-iframe.service';
import {
    STATE_EPIC,
    STATE_MIDDLEWARE,
    STATE_REDUCER
} from 'projects/insightui/src/app/state/state.module';
import { ExportReportInIframeEpic } from 'insightui.export-collection/export-report-in-iframe.epic';
import { ExportCollectionService } from 'insightui.export-collection/export-collection.service';
import { ReportInIFramePostMessageMiddleware } from 'insightui.export-collection/export-collection.middleware';
import { ExportCollectionModalComponent } from 'insightui.export-collection/export-collection-modal/export-collection-modal.component';
import { ExportCollectionReducer } from 'insightui.export-collection/export-collection.reducer';
import { ExportCollectionSelector } from 'insightui.export-collection/export-collection.selector';
import { ExportCollectionEpic } from 'insightui.export-collection/export-collection.epic';
import { ExportCollectionModalViewComponent } from 'insightui.export-collection/export-collection-modal/export-collection-modal-view.component';
import { CommonModule } from '@angular/common';
import { ExportCollectionModalAskUserComponent } from 'insightui.export-collection/export-collection-modal/body/export-collection-modal-ask-user.component';
import { ExportCollectionModalControlsComponent } from 'insightui.export-collection/export-collection-modal/controls/export-collection-modal-controls.component';
import { LoadingIndicatorModule } from 'insightui.loading-indicator/loading-indicator.module';
import { ExportCollectionModalWaitingComponent } from 'insightui.export-collection/export-collection-modal/body/export-collection-modal-waiting.component';
import { ExportCollectionModalRetryComponent } from 'insightui.export-collection/export-collection-modal/body/export-collection-modal-retry.component';
import { ExportCollectionModalDoneComponent } from 'insightui.export-collection/export-collection-modal/body/export-collection-modal-done.component';
import { ExportCollectionModalBodyWithTitleComponent } from 'insightui.export-collection/export-collection-modal/body/export-collection-modal-body-with-title.component';
import { ExportCollectionModalHeaderComponent } from 'insightui.export-collection/export-collection-modal/header/export-collection-modal-header.component';
import { ExportCollectionHeadlineService } from './export-collection-headline.service';
import { Angulartics2Module } from 'angulartics2';
import { SorryModule } from 'insightui.common-components/sorry/sorry.module';

@NgModule({
    imports: [CommonModule, LoadingIndicatorModule, Angulartics2Module, SorryModule],
    providers: [
        ReportInIFrameService,
        ExportCollectionService,
        ExportCollectionSelector,
        ExportCollectionHeadlineService,
        {
            provide: STATE_EPIC,
            useClass: ExportReportInIframeEpic,
            multi: true
        },
        { provide: STATE_EPIC, useClass: ExportCollectionEpic, multi: true },
        {
            provide: STATE_MIDDLEWARE,
            useClass: ReportInIFramePostMessageMiddleware,
            multi: true
        },
        {
            provide: STATE_REDUCER,
            useClass: ExportCollectionReducer,
            multi: true
        }
    ],
    declarations: [
        ExportCollectionModalComponent,
        ExportCollectionModalAskUserComponent,
        ExportCollectionModalControlsComponent,
        ExportCollectionModalWaitingComponent,
        ExportCollectionModalViewComponent,
        ExportCollectionModalRetryComponent,
        ExportCollectionModalDoneComponent,
        ExportCollectionModalBodyWithTitleComponent,
        ExportCollectionModalHeaderComponent
    ],
    exports: [ExportCollectionModalViewComponent]
})
export class ExportCollectionModule {}
