import { Injectable } from '@angular/core';

const TOOLTIP_INIT_POSTION = { top: 0, left: 0 };
const TOOLTIP_POSTION_PADDING = 30;

@Injectable()
export class TooltipUtilService {
    /**
     *  Return the relative coordination where to put the tooltip
     *
     * @param event
     * @param anchor
     */
    getTooltipAnchor(event, tooltipWidth, tooltipHeight, padding) {
        let offSetX =
            event.pageX + tooltipWidth + TOOLTIP_POSTION_PADDING > window.innerWidth
                ? tooltipWidth * -1 - padding
                : padding;
        let offSetY =
            event.pageY + tooltipHeight + TOOLTIP_POSTION_PADDING > window.innerHeight
                ? tooltipHeight * -1 - padding
                : padding;

        return {
            top: offSetY + event.clientY,
            left: offSetX + event.clientX
        };
    }

    /**
     * Calculates the tooltip width class based on number of viewable characters
     *
     * @param content
     * @returns {number}
     */
    getKpiTooltipWidth(content) {
        let numberOfChars = this.htmlToPlaintext(content).length;
        let width = 300;
        switch (true) {
            case numberOfChars <= 300:
                width = 250;
                break;
            case numberOfChars > 300 && numberOfChars <= 600:
                width = 300;
                break;
            case numberOfChars > 600:
                width = 400;
                break;
        }
        return width;
    }

    /**
     * Filters html tags from content string
     * @param text
     * @returns {string}
     */
    htmlToPlaintext(text) {
        return text ? String(text).replace(/<[^>]+>/gm, '') : '';
    }

    /**
     * Outer tooltip container
     * @param position
     * @returns {{container: HTMLDivElement, containerInner: HTMLDivElement}}
     */
    getContainer(position, width = null) {
        const container = document.createElement('div');
        ['tooltip', 'cursor', 'tooltip-overlay', 'fade', 'in'].forEach(tooltipClass => {
            container.classList.add(tooltipClass);
        });

        if (width) {
            container.style.width = width + 'px';
        }
        container.style.top = position.top;
        container.style.left = position.left;

        const toolTipInner = document.createElement('div');
        toolTipInner.classList.add('tooltip-inner');
        container.appendChild(toolTipInner);

        const containerInner = document.createElement('div');
        containerInner.classList.add('ui-tooltip-container', 'ui-test-tooltip-container');
        toolTipInner.appendChild(containerInner);

        return {
            container: container,
            containerInner: containerInner
        };
    }

    getFeatureTooltip(title, content) {
        let domNodes = this.getContainer(TOOLTIP_INIT_POSTION);

        const headLine = document.createElement('h3');
        headLine.innerText = title;
        domNodes.containerInner.appendChild(headLine);

        const contentContainer = document.createElement('div');
        contentContainer.classList.add('ui-tooltip-content', 'ui-test-tooltip-content');
        contentContainer.innerHTML = this.buildFeatureToolTipContent(content);
        domNodes.containerInner.appendChild(contentContainer);

        return domNodes.container;
    }

    getTotalLineTooltip(parent, feature, channel, value) {
        let domNodes = this.getContainer(TOOLTIP_INIT_POSTION, 250);

        const contentContainer = document.createElement('div');
        contentContainer.classList.add(
            'ui-totalline-tooltip-content',
            'ui-test-totalline-tooltip-content'
        );
        contentContainer.innerHTML =
            'The share of ' +
            parent +
            ' with ' +
            feature +
            ' is ' +
            value +
            '% out of total ' +
            parent +
            ' in ' +
            channel;
        domNodes.containerInner.appendChild(contentContainer);

        return domNodes.container;
    }

    getKpiTooltip(title, body, width) {
        const domNodes = this.getContainer(TOOLTIP_INIT_POSTION, width);

        const strongElem = document.createElement('strong');
        strongElem.innerText = 'x';
        const closeButton = document.createElement('span');
        closeButton.classList.add('kpi-header-tooltip-close-button');
        closeButton.appendChild(strongElem);
        domNodes.containerInner.appendChild(closeButton);

        const headLine = document.createElement('h3');
        headLine.classList.add(
            'kpi-header-tooltip-title',
            'ui-test-kpi-header-tooltip-title'
        );
        headLine.innerText = title;
        domNodes.containerInner.appendChild(headLine);

        const contentBody = document.createElement('div');
        contentBody.classList.add('kpi-header-tooltip-body');
        contentBody.innerHTML = body;
        domNodes.containerInner.appendChild(contentBody);

        const button = document.createElement('button');
        button.classList.add('ui-test-kpi-header-tooltip-close-button-bottom');
        button.innerHTML = 'Ok';
        domNodes.containerInner.appendChild(button);

        return domNodes.container;
    }

    buildFeatureToolTipContent(content) {
        let leftCol = '<div class="left-column">';
        let rightCol = '<div class="right-column">';
        content.forEach(c => {
            leftCol += c.key + '</br>';
            rightCol += c.value + '</br>';
        });
        leftCol += '</div>';
        rightCol += '</div>';
        return leftCol + rightCol;
    }
}
