import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output
} from '@angular/core';
import { FairShareHover } from 'insightui.fair-share/fair-share.types';

@Component({
    selector: 'gfk-fair-share-comparison-bar-chart',
    templateUrl: './fair-share-comparison-bar-chart.component.html',
    styleUrls: ['./fair-share-comparison-bar-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FairShareComparisonBarChartComponent {
    @Input()
    gapValuePercent: number;

    @Input()
    comparisonPercent: number;

    @Input()
    minValuePercent: number;

    @Input()
    maxValuePercent: number;

    @Input()
    showGapValueHover: boolean = false;

    @Input()
    gapValue: number;

    @Input()
    useDarkColor = false;

    @Output()
    gapValueHover: EventEmitter<FairShareHover> = new EventEmitter<FairShareHover>();

    handleGapValueEnter(): void {
        this.gapValueHover.emit('enter');
    }

    handleGapValueLeave(): void {
        this.gapValueHover.emit('leave');
    }
}
