import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SearchBoxComponent } from './search-box.component';
import { HighlightPipe } from './pipes/highlight.pipe';

@NgModule({
    declarations: [SearchBoxComponent, HighlightPipe],
    imports: [CommonModule, FormsModule],
    exports: [SearchBoxComponent, HighlightPipe]
})
export class SearchBoxModule {}
