import { AppStateReducer } from 'projects/insightui/src/app/state/state.types';
import { Reducer } from 'redux';
import {
    FairShareChannelToId,
    FairShareHierarchies,
    FairShareRawReportData,
    FairShareReportDataByChannelId,
    FairShareState,
    NEVER_LOADED,
    FairShareRowId,
    FairShareReportData,
    hasLoaded
} from 'insightui.fair-share/fair-share.types';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
    FAIR_SHARE_DEACTIVATED,
    FAIR_SHARE_UPDATE_REPORT,
    FAIR_SHARE_GAP_VALUE_ENTER,
    FAIR_SHARE_GAP_VALUE_LEAVE,
    FAIR_SHARE_GROWTH_VALUE_ENTER,
    FAIR_SHARE_GROWTH_VALUE_LEAVE,
    FAIR_SHARE_DRILL_DOWN,
    FAIR_SHARE_DRILL_UP
} from 'insightui.fair-share/fair-share.actions';
import { Injectable } from '@angular/core';
import {
    FILTER_CHANGED,
    FILTER_HIERARCHY_DIMENSIONS_LOADED,
    FILTER_INIT,
    FILTER_METADATA_LOADED,
    FILTER_REPORT_ACTIVATED
} from 'insightui.report-filter/report-filter.actions';
import { DrillTarget } from 'insightui.data/query/queryservice/query-service-request.interface';
import { FairShareHierarchySelector } from 'insightui.fair-share/fair-share-hierarchy.selector';
import { FairShareSelector } from 'insightui.fair-share/fair-share.selector';
import { REPORTING_DIMENSION_INFO } from 'insightui.metadata/metadata.types';
import { VisualizationContextService } from "insightui.data/shared/visualization-context.service";

export const INIT_STATE: FairShareState = {
    deliveryId: null,
    reportData: NEVER_LOADED,
    selectedTarget: null,
    viewedTarget: null,
    hierarchy: null,
    channels: null,
    showGapValueForRowId: null,
    showGrowthValueForRowId: null
};

const REPORTING_DIMENSION_LOWER_MAP = {
    AR: 'sector',
    SG: 'category',
    CY: 'productGroup',
    PG: 'brandInPg'
};

@Injectable({
    providedIn: 'root'
})
export class FairShareReducer implements AppStateReducer<'fairShare'> {
    key: 'fairShare' = 'fairShare';
    initState: FairShareState = INIT_STATE;

    constructor(
        private fairShareHierarchySelector: FairShareHierarchySelector,
        private fairShareSelector: FairShareSelector,
        private visualizationContext: VisualizationContextService
    ) {}

    getReducer(): Reducer<FairShareState> {
        return reducerWithInitialState(INIT_STATE)
            .case(FILTER_REPORT_ACTIVATED, (state, { deliveryId }) => ({
                ...state,
                deliveryId,
                viewedTarget: null,
                reportData: NEVER_LOADED
            }))
            .case(FAIR_SHARE_DEACTIVATED, () => INIT_STATE)
            .case(FAIR_SHARE_UPDATE_REPORT, (state, reportData) => ({
                ...state,
                reportData: this.groupReportDataByChannelId(reportData),
                viewedTarget: state.selectedTarget
            }))
            .case(FILTER_CHANGED, (state, visualizationContext) => ({
                ...state,
                selectedTarget: { ...visualizationContext.drillTarget }
            }))
            .case(FILTER_INIT, (state, visualizationContext) => ({
                ...state,
                selectedTarget: { ...visualizationContext.drillTarget }
            }))
            .case(FILTER_HIERARCHY_DIMENSIONS_LOADED, (state, hierarchy) => ({
                ...state,
                hierarchy
            }))
            .case(FILTER_METADATA_LOADED, (state, deliveryInfo) => ({
                ...state,
                channels: this.convertToChannelTypeToIdMapping(
                    deliveryInfo.dimensions.channel
                )
            }))
            .case(FAIR_SHARE_GAP_VALUE_ENTER, (state, rowId) => ({
                ...state,
                showGapValueForRowId: rowId
            }))
            .case(FAIR_SHARE_GAP_VALUE_LEAVE, state => ({
                ...state,
                showGapValueForRowId: null
            }))
            .case(FAIR_SHARE_GROWTH_VALUE_ENTER, (state, rowId) => ({
                ...state,
                showGrowthValueForRowId: rowId
            }))
            .case(FAIR_SHARE_GROWTH_VALUE_LEAVE, state => ({
                ...state,
                showGrowthValueForRowId: null
            }))
            .case(FAIR_SHARE_DRILL_UP, state => ({
                ...state,
                selectedTarget: this.getUpperSelectedTarget(
                    state.viewedTarget,
                    state.hierarchy
                )
            }))
            .case(FAIR_SHARE_DRILL_DOWN, (state, rowId) => ({
                ...state,
                selectedTarget: this.getLowerSelectedTarget(
                    rowId,
                    state.channels.retailer,
                    state.reportData
                )
            }));
    }

    private getUpperSelectedTarget(
        drillTarget: DrillTarget,
        hierarchies: FairShareHierarchies
    ): DrillTarget {
        const parentHierarchy = this.fairShareHierarchySelector.getParentHierarchy(
            drillTarget,
            hierarchies
        );

        const currentHierarchy = this.fairShareHierarchySelector.getHierarchy(
            drillTarget,
            hierarchies
        );

        const drillLevel = Object.keys(REPORTING_DIMENSION_INFO).find(
            dimension =>
                REPORTING_DIMENSION_INFO[dimension].id ===
                hierarchies[drillTarget.dimensionId].typeId
        );

        let drillTargetItem;

        // Check if current action is drilling up from selected brand level (brandInPg),
        // eg : from DYSON -> Hair Styler
        if (this.visualizationContext.get('itemInBrand')
                 && this.visualizationContext.get('itemInBrand') !== '') {

            this.visualizationContext.set('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare', true);

            // Set dimension ID to current PG level, otherwise it will get category dimension key
            drillTargetItem = {
                dimensionId: currentHierarchy.id,
                drillLevel
            };
        } else {
            // Reset the flag
            this.visualizationContext.set('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare', false);

            // Set dimension ID to parent's hierarchy
            drillTargetItem = {
                dimensionId: parentHierarchy.id,
                drillLevel
            };
        }

        return drillTargetItem;
    }

    private getLowerSelectedTarget(
        rowId: FairShareRowId,
        retailerId: string,
        reportData: FairShareReportData
    ): DrillTarget {
        // Reset the flag
        this.visualizationContext.set('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare', false);
        if (!hasLoaded(reportData)) {
            return {
                dimensionId: null,
                drillLevel: null
            };
        }

        const rawReportDataRow: FairShareReportDataByChannelId = reportData.find(
            report => {
                return report[retailerId].$rowId === rowId;
            }
        );
        const productId = this.fairShareSelector.getProductId(
            rawReportDataRow[retailerId]
        );
        const twoLetterId = this.fairShareSelector.getTwoLetterId(
            rawReportDataRow[retailerId]
        );
        const drillLevel = REPORTING_DIMENSION_LOWER_MAP[twoLetterId];

        return {
            dimensionId: productId,
            drillLevel
        };
    }

    private groupReportDataByChannelId(
        reportData: ReadonlyArray<FairShareRawReportData>
    ): ReadonlyArray<FairShareReportDataByChannelId> {
        // Reset the flag
        this.visualizationContext.set('isDrillingUpFromBrandLevelFilterInPriceClassForFairShare', false);
        const rowIdToReportData: {
            [rowId: number]: FairShareReportDataByChannelId;
        } = reportData.reduce((byChannelId, reportDataRow) => {
            const existingRow = byChannelId[reportDataRow.$rowId] || {};
            return {
                ...byChannelId,
                [reportDataRow.$rowId]: {
                    ...existingRow,
                    [reportDataRow.channel]: reportDataRow
                }
            };
        }, {});

        return Object.values(rowIdToReportData);
    }

    private convertToChannelTypeToIdMapping(channels: {
        [channelId: string]: any;
    }): FairShareChannelToId {
        return Object.keys(channels).reduce((typeToChannelId, channelId) => {
            return { ...typeToChannelId, [channels[channelId].type]: channelId };
        }, {}) as FairShareChannelToId;
    }
}
