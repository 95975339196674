import { CollectionDto } from 'insightui.openapi/userprofile';

export type BackendProgressStatus = 'IDLE' | 'START' | 'SUCCESS' | 'FAILED';

export interface BackendProgress {
    readonly status: BackendProgressStatus;
    readonly collection: CollectionDto;
}

export const IDLE: BackendProgress = { status: 'IDLE', collection: null };
