import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output
} from '@angular/core';
import { SortingOption } from 'insightui.data/types';
import * as _ from 'lodash';

const CSS_CLASS_MAPPING = {
    Alphabetically: {
        DESC: 'fa-sort-alpha-desc',
        ASC: 'fa-sort-alpha-asc'
    },
    ById: {
        DESC: 'fa-sort-amount-desc',
        ASC: 'fa-sort-amount-asc'
    }
};

@Component({
    selector: 'legend-order-by-drop-down',
    templateUrl: './legendOrderByDropDown.component.html'
})
export class LegendOrderByDropDownComponent {
    @Output()
    onSelect: EventEmitter<SortingOption> = new EventEmitter();

    @Input()
    options: SortingOption[] = [];

    isVisible: boolean = false;

    constructor(private elementRef: ElementRef) {}

    handleSelectSorting(option: SortingOption): void {
        if (!option.selected) {
            this.onSelect.emit(option);
            this.isVisible = false;
        }
    }

    toggleDropDown(): void {
        this.isVisible = !this.isVisible;
    }

    selectedSortingTitle(): string {
        const found: SortingOption = _.find(
            this.options,
            (option: SortingOption) => option.selected
        );
        if (!found) {
            return 'N/A';
        }

        return found.title;
    }

    @HostListener('document:click', ['$event'])
    handleDocumentClick($event: MouseEvent): void {
        if (this.isVisible && !this.elementRef.nativeElement.contains($event.target)) {
            this.isVisible = false;
        }
    }

    cssClassForSortingOption(sortingOption: SortingOption): string {
        const sorting = sortingOption.sorting;

        return CSS_CLASS_MAPPING[sorting.target][sorting.direction];
    }

    selectableOptions(): SortingOption[] {
        return (this.options || []).filter((option: SortingOption) => option.selectable);
    }
}
