import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable, of as observableOf } from 'rxjs';
import { delay, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { BackendProgress } from '../backendProgress';
import { CollectionDto } from 'insightui.openapi/userprofile';

const FLIP_BACK_TIME = 500;

@Component({
    selector: 'collection-list-item',
    templateUrl: 'collectionListItem.component.html'
})
export class CollectionListItemComponent implements OnInit {
    @Input()
    collection: CollectionDto;

    @Input()
    progress: Observable<BackendProgress>;

    @Output()
    onClicked = new EventEmitter<CollectionDto>();

    isActive$: Observable<boolean>;
    hasError$: Observable<boolean>;
    wasSaved$: Observable<boolean>;

    handleClick(): void {
        this.onClicked.emit(this.collection);
    }

    ngOnInit(): void {
        this.isActive$ = this.isActive(this.collection);
        this.hasError$ = this.hasError(this.collection);
        this.wasSaved$ = this.wasSaved(this.collection);
    }

    private isActive(theCollection: CollectionDto): Observable<boolean> {
        return this.progress.pipe(
            map(
                ({ collection, status }) =>
                    !collection ||
                    collection.id !== theCollection.id ||
                    status !== 'START'
            ),
            distinctUntilChanged()
        );
    }

    private hasError(theCollection: CollectionDto): Observable<boolean> {
        const hasError$ = this.progress.pipe(
            map(
                ({ collection, status }) =>
                    status === 'FAILED' && collection.id === theCollection.id
            ),
            distinctUntilChanged()
        );

        return this.delayedFlipBack(hasError$);
    }

    private wasSaved(theCollection: CollectionDto): Observable<boolean> {
        const wasSaved$ = this.progress.pipe(
            map(
                ({ collection, status }) =>
                    status === 'SUCCESS' && collection.id === theCollection.id
            ),
            distinctUntilChanged()
        );

        return this.delayedFlipBack(wasSaved$);
    }

    private delayedFlipBack(observable: Observable<boolean>): Observable<boolean> {
        return observable.pipe(
            switchMap(current => {
                if (current) {
                    return observableOf(false).pipe(
                        delay(FLIP_BACK_TIME),
                        startWith(true)
                    );
                } else {
                    return observableOf(false);
                }
            })
        );
    }
}
