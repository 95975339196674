<ng-container *ngIf="shouldShowPresenterMode()">
    <div class="presenter-controls">
        <div
            class="presenter-controls__left"
            *ngIf="hasPrevious$ | async"
            (click)="goToPreviousReport()"
            angulartics2On
            angularticsCategory="CollectionManager"
            angularticsAction="PresentationModePrevious"
        >
            <i class="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        <div
            class="presenter-controls__right"
            *ngIf="hasNext$ | async"
            (click)="goToNextReport()"
            angulartics2On
            angularticsCategory="CollectionManager"
            angularticsAction="PresentationModeNext"
        >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
        </div>
    </div>
</ng-container>
