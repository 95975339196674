import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gfkAbs'
})
export class AbsPipe implements PipeTransform {
    transform(value: any): any {
        if (value == null || typeof value !== 'number') {
            return value;
        }

        return Math.abs(value);
    }
}
