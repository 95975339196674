import { DeliveryId, HumanReadableLabel } from 'projects/insightui/src/app/state/state.types';
import { DrillTarget } from 'insightui.data/query/queryservice/query-service-request.interface';
import { ReportingDimensionHierarchy } from 'insightui.report-filter/report-filter.types';

export enum FairShareDrillDirection {
    Up,
    Down
}

export interface FairShareDrill {
    readonly direction: FairShareDrillDirection;
    readonly rowId?: FairShareRowId;
}

export type FairShareHover = 'enter' | 'leave';

export type FairShareRowId = number;

export interface FairShareRowHover {
    readonly row: FairShareReportRow;
    readonly hover: FairShareHover;
}

export interface FairShareHierarchies {
    readonly [key: string]: ReportingDimensionHierarchy;
}

/**
 * The unaltered fair share report data.
 */
export interface FairShareRawReportData {
    readonly channel: string;
    /**
     *  the brand name
     */
    readonly BR?: string | null;
    /**
     * category id
     */
    readonly CY?: string;
    /**
     * sector id
     */
    readonly SG?: string;
    /**
     * area id
     */
    readonly AR?: string;
    /**
     * product group id
     */
    readonly PG?: string;
    /**
     * Price Class id
     */
    readonly PF?: string;
    readonly KPI21176: number | null;
    readonly KPIC11: number | null;
    readonly KPIC91: number | null;
    readonly KPIC12: number | null;
    readonly KPIC92: number | null;
    readonly KPIC91_REBASE: number | null;
    /**
     * a "TAG" e.g. will contain "Total", "SubTotal" etc.
     */
    readonly TG: string | null;
    readonly $rowId: FairShareRowId;
}

export interface FairShareChannelToId {
    readonly retailer: string;
    readonly panelMarket: string;
    readonly channel: string;
}

export interface FairShareReportDataByChannelId {
    readonly [key: string]: FairShareRawReportData;
}

/**
 * The fair share report data can either be NEVER_LOADED (e.g. init state) or there is some data
 */
export type FairShareReportData =
    | 'NEVER_LOADED'
    | ReadonlyArray<FairShareReportDataByChannelId>;
export const NEVER_LOADED: FairShareReportData = 'NEVER_LOADED';
export function hasLoaded(
    reportData: FairShareReportData
): reportData is ReadonlyArray<FairShareReportDataByChannelId> {
    return reportData !== NEVER_LOADED;
}

export type ValueGrowthValueRetailer = number;

export interface FairShareState {
    readonly deliveryId: DeliveryId | null;
    readonly selectedTarget: DrillTarget | null;
    readonly viewedTarget: DrillTarget | null;
    readonly hierarchy: FairShareHierarchies;
    readonly reportData: FairShareReportData;
    readonly channels: FairShareChannelToId | null;
    readonly showGapValueForRowId: number | null;
    readonly showGrowthValueForRowId: number | null;
}

export interface FairShareReportRow {
    readonly $rowId: FairShareRowId;
    readonly title: HumanReadableLabel;
    readonly marketSizeValue: number;
    readonly fairShareValue: number;
    readonly marketSizeGapValue: number;
    readonly valueGrowthValueRetailer: ValueGrowthValueRetailer;
    readonly valueGrowthValuePanelMarket: number;
    readonly valueGrowthGapValue: number;
    readonly valueGrowthRangeBoundsStatus: ValueGrowthRangeBoundsStatus;
    readonly isGapValueHovering: boolean;
    readonly isGrowthValueHovering: boolean;
    readonly isDrillable: boolean;
}

export type FairShareReportRows = ReadonlyArray<FairShareReportRow>;

export interface ValueGrowthRangeBounds {
    readonly min: number;
    readonly max: number;
}

export interface ValueGrowthRangeBoundsStatus {
    readonly min: boolean;
    readonly max: boolean;
}
