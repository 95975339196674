import { Injectable } from '@angular/core';

const TABLE_BODY_SELECTOR = '.datatable-body.main';
const SCROLLABLE_CONTENT_SELECTOR = '.scrollable-content';

@Injectable()
export class TableElementCleanupService {
    constructor() {}

    cleanupElementForExport(authoringChartElement: HTMLElement): HTMLElement {
        const clone = authoringChartElement.cloneNode(true) as HTMLElement;

        this.imitateScrollToPosition(clone, authoringChartElement);

        // we have to clean the table its to big to send to server
        return clone;
    }

    private imitateScrollToPosition(
        clone: HTMLElement,
        authoringChartElement: HTMLElement
    ) {
        this.imitateScrollLeft(clone, authoringChartElement);
        this.imitateScrollTop(clone, authoringChartElement);
    }

    private imitateScrollLeft(clone: HTMLElement, authoringChartElement: HTMLElement) {
        const tableBody = authoringChartElement.querySelector(TABLE_BODY_SELECTOR);
        const scrollLeft = tableBody.scrollLeft;

        if (scrollLeft) {
            const tableBodyClone = clone.querySelector(
                TABLE_BODY_SELECTOR
            ) as HTMLElement;

            tableBodyClone.style.marginLeft = -scrollLeft + 'px';
            tableBodyClone.style.width = tableBody.clientWidth + scrollLeft + 'px';
        }
    }

    private imitateScrollTop(clone: HTMLElement, authoringChartElement: HTMLElement) {
        const scrollableContents = authoringChartElement.querySelectorAll(
            SCROLLABLE_CONTENT_SELECTOR
        );

        for (let i = 0; i < scrollableContents.length; i++) {
            const scrollableContent = scrollableContents[i];
            const scrollTop = scrollableContent.scrollTop;

            if (scrollTop) {
                const scrollableContentClone = clone.querySelectorAll(
                    SCROLLABLE_CONTENT_SELECTOR
                )[i] as HTMLElement;
                scrollableContentClone.style.marginTop = -scrollTop + 'px';
                scrollableContentClone.style.height =
                    scrollableContent.clientHeight + scrollTop + 'px';
            }
        }
    }
}
