import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InsightConfig, INSIGHT_CONFIG } from 'insightui.global/global.module';
import { ILogger, LogService } from 'insightui.core/services/logging/log.service';
import { Revision, DeliveryInfoContainer } from '../metadata.types';
import { MsLoadRevisionsSuccess } from '../state/metadata.actions';
import { MetadataStateModel } from '../state/metadata.state';
import { getClientId } from 'insightui.core/services/navigation-utils';

export interface RevisionMeta {
    periodLongName: string;
    periodId: string;
    reportingSystemId: string;
    deliveryId: string;
    clientId: string;
    clientName: string;
    regionId: string;
    regionName: string;
    periodName: string;
    reportingSystem: string;
    regionIso: string;
}
export interface RevisionMetadata {
    document_id: string;
    active_revision_id: string;
    meta: RevisionMeta;
}

@Injectable({
    providedIn: 'root'
})
export class RevisionService {
    private logger: ILogger;

    private loaded = false;

    constructor(
        private httpClient: HttpClient,
        private store: Store,
        private router: Router,
        @Inject(INSIGHT_CONFIG) private insightConfig: InsightConfig,
        logService: LogService
    ) {
        this.logger = logService.getLogger('RevisionService');
    }

    getDocIdOfCurrentPeriod$(countryCode: string): Observable<number> {
        return this.store
            .select(state => state.metadata)
            .pipe(
                map(this.revisionOfCurrentPeriodContainingCountry(countryCode)),
                filter(revision => !!revision),
                map(revision => revision.activeRevisionId)
            );
    }

    getPeriodDisplayTextOfCurrentPeriod$(countryCode: string): Observable<string> {
        return this.store
            .select(state => state.metadata)
            .pipe(
                map(this.revisionOfCurrentPeriodContainingCountry(countryCode)),
                filter(revision => !!revision),
                map(revision => revision.periodDisplayText)
            );
    }

    getIndexOfCurrentPeriod$(): Observable<number> {
        return this.store
            .select(state => state.metadata)
            .pipe(map(this.indexOfCurrentPeriod()));
    }

    loadRevisions() {
        if (!this.loaded) {
            this.logger.debug('start loading revisions');

            const url =
                this.insightConfig.services.QUERY_SERVICE.url + 'api/v2/documents';

            this.httpClient.get(url).subscribe((data: RevisionMetadata[]) => {
                if (data.length === 0) {
                    this.logger.warn(
                        `It seems like the current user has no revisions! ${url} did not return any results.`
                    );

                    let extras;
                    const clientId = getClientId();
                    if (clientId) {
                        extras = {
                            queryParams: {
                                clientId
                            }
                        };
                    }
                    this.router.navigate(['no-data-for-user'], extras);
                    return;
                }

                const revisions = data
                    .map((rm: RevisionMetadata) => {
                        return {
                            periodId: parseInt(rm.meta.periodId, 10),
                            activeRevisionId: parseInt(rm.active_revision_id, 10),
                            periodDisplayText: rm.meta.periodName,
                            regionDisplayText: rm.meta.regionName,
                            regionIsoCode: rm.meta.regionIso
                        };
                    })
                    .sort(this.sortRevisionDescending);

                this.logger.debug('dispatch MsLoadRevisionsSuccess', revisions);
                this.store.dispatch(new MsLoadRevisionsSuccess(revisions));
            });

            this.loaded = true;
        }
    }

    loadDeliveryInfo(docId: number): Observable<DeliveryInfoContainer> {
        this.logger.debug('start loading document', docId);

        const url =
            this.insightConfig.services.QUERY_SERVICE.url + 'api/v2/revisions/' + docId;

        return this.httpClient.get<DeliveryInfoContainer>(url);
    }

    private revisionOfCurrentPeriodContainingCountry(
        desiredCountryCode: string
    ): (metadata: MetadataStateModel) => Revision {
        // Logic taken from MetadataService
        return metadata => {
            const revisionsOfCountry = metadata.revisions.filter(
                rev => rev.regionIsoCode === desiredCountryCode
            );
            if (revisionsOfCountry.length === 0) {
                // use the latest period instead
                return metadata.revisions[0];
            }

            let revisionOfCountry = revisionsOfCountry.find(
                rev => rev.periodId === metadata.currentPeriodId
            );
            if (!revisionOfCountry) {
                // use the latest available instead
                revisionOfCountry = revisionsOfCountry[0];
            }

            return revisionOfCountry;
        };
    }

    private indexOfCurrentPeriod(): (metadata: MetadataStateModel) => number {
        return metadata => {
            const periods = metadata.revisions
                .map(revision => revision.periodId)
                // filter for unique values
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort(this.sortNumberAscending);

            return periods.indexOf(metadata.currentPeriodId);
        };
    }

    private sortRevisionDescending(revision1: Revision, revision2: Revision) {
        return revision2.periodId - revision1.periodId;
    }

    private sortNumberAscending(number1: number, number2: number) {
        return number1 - number2;
    }
}
