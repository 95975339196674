/**
 * NotificationServices SwaggerUI
 * Add some description
 *
 * The version of the OpenAPI document: 2.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
    HttpEvent,
    HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { FeedSettingsRequest } from '../model/feed-settings-request';
import { FeedSettingsResponse } from '../model/feed-settings-response';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
    providedIn: 'root'
})
export class FeedSettingsControllerService {
    protected basePath =
        'http://notificationservices-dev.gfk.st2.com/notificationservices/api/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath =
                configuration.basePath || basePath || this.basePath;
        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * Delete the feed settings for the current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteSettings(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteSettings(
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<any>>;
    public deleteSettings(
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<any>>;
    public deleteSettings(
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.delete<any>(
            `${this.configuration.basePath}/feed-settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get the feed settings for the current user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSettings(
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FeedSettingsResponse>;
    public getSettings(
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FeedSettingsResponse>>;
    public getSettings(
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FeedSettingsResponse>>;
    public getSettings(
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.get<FeedSettingsResponse>(
            `${this.configuration.basePath}/feed-settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save the feed settings for the current user
     * @param feedSettingsRequest feedSettingsRequest
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveSettings(
        feedSettingsRequest: FeedSettingsRequest,
        observe?: 'body',
        reportProgress?: boolean
    ): Observable<FeedSettingsResponse>;
    public saveSettings(
        feedSettingsRequest: FeedSettingsRequest,
        observe?: 'response',
        reportProgress?: boolean
    ): Observable<HttpResponse<FeedSettingsResponse>>;
    public saveSettings(
        feedSettingsRequest: FeedSettingsRequest,
        observe?: 'events',
        reportProgress?: boolean
    ): Observable<HttpEvent<FeedSettingsResponse>>;
    public saveSettings(
        feedSettingsRequest: FeedSettingsRequest,
        observe: any = 'body',
        reportProgress: boolean = false
    ): Observable<any> {
        if (feedSettingsRequest === null || feedSettingsRequest === undefined) {
            throw new Error(
                'Required parameter feedSettingsRequest was null or undefined when calling saveSettings.'
            );
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['Authorization']) {
            headers = headers.set(
                'Authorization',
                this.configuration.apiKeys['Authorization']
            );
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = ['application/json'];
        const httpHeaderAcceptSelected:
            | string
            | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = ['application/json'];
        const httpContentTypeSelected:
            | string
            | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<FeedSettingsResponse>(
            `${this.configuration.basePath}/feed-settings`,
            feedSettingsRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
}
