import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'gfk-modal-button',
    templateUrl: './modal-button.component.html',
    styleUrls: ['./modal-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalButtonComponent {
    @Input() buttonType: 'SUBMIT' | 'CANCEL' = 'SUBMIT';
    @Input() disabled: boolean;
}
